<h2 class="mb-3 ml-1 mr-2 flex-grow-1">Lösungen</h2>

<ng-template *ngTemplateOutlet="recommendationSelected"></ng-template>
<ng-template *ngTemplateOutlet="recommendationRecommended"></ng-template>

<!-- ------ Individual Tasks and Transitions ------ -->
<div class="my-4 justify-content-center button-row">
  <div class="button-row-content">
    <button
      mat-stroked-button
      color="primary"
      [attr.data-testcafe]="'sideConsultation-button-individualTasks'"
      (click)="openIndividualTasks()"
    >
      <span>Maßnahme</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>

    <button
      mat-stroked-button
      color="primary"
      [attr.data-testcafe]="'sideConsultation-button-individualTransition'"
      (click)="openIndividualTransitions()"
    >
      <span>Expertengespräch</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>
  </div>
</div>
<!--  Compare Accounts  -->
@if (compareAccountsButtonText() !== undefined) {
  <div class="further-solutions-button">
    <button
      mat-stroked-button
      color="primary"
      [attr.data-testcafe]="'screenConsultation-button-compareAccounts'"
      (click)="openCompareAccounts()"
    >
      <span>{{ compareAccountsButtonText() }}</span>
      <kf-icon name="ic_pfeile_links_rechts_24"></kf-icon>
    </button>
  </div>
}

<div class="further-solutions-button">
  @if (!sideOpen && questionGroupData.template === questionTemplateEnum.products) {
    <button mat-stroked-button color="primary" (click)="openExtra()">
      <span>Weitere Lösungen</span>
    </button>
  }
</div>

<!-- RECOMMENDATION SELECTED -->
<ng-template #recommendationSelected>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Ausgewählt</h3>
  @if (isSelectedEmpty) {
    <div class="w-100 mb-2 empty-container">{{ getNoSolutionsMessage('selected') }}</div>
  } @else {
    @for (recSelected of recDataSelected; track recSelected) {
      <div class="recommendation-container">
        <div class="d-flex flex-column w-100">
          <div
            [debugId]="recSelected.compositionId"
            class="composition-title"
            [innerHtml]="recSelected.compositionName | safeHtml"
          ></div>

          <div class="rec-button">
            <mat-checkbox
              kfFocus
              color="grey"
              [attr.aria-label]="'Ausgewählte Lösung: ' + recSelected.name"
              [checked]="true"
              (click)="onRecDeselected(recSelected); $event.preventDefault()"
            >
              @switch (recSelected.type) {
                @case (recommendationTypeEnum.task) {
                  <kf-icon
                    name="ic_person_mit_kreis_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Maßnahme'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.transition) {
                  <kf-icon
                    name="ic_personen_paar_24"
                    class="flex-shrink-0 subtopic-icon ml-2"
                    aria-hidden="true"
                    [kfTooltip]="'Expertengespräch'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.subtopic) {
                  <kf-icon
                    name="ic_sprechblase_fragezeichen_ausrufezeichen_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Unterthema'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @default {
                  <kf-icon
                    name="ic_allgemeine_karte_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Produkt'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
              }
              <span [debugId]="recSelected.id" class="label-text" [innerHtml]="recSelected.name | safeHtml"></span>
            </mat-checkbox>

            <div class="rec-badge">
              @if (recSelected.recommended) {
                <div class="tag-info tag-neutral">
                  <div class="tag-info-text">Empfohlen</div>
                </div>
              }
            </div>
          </div>

          @if (recSelected.type === recommendationTypeEnum.subtopic) {
            <button
              mat-stroked-button
              color="primary"
              class="mx-3 add2agenda-button"
              aria-label="Zur aktuellen Beratung hinzufügen"
              (click)="addSubtopicToAgenda(recSelected)"
            >
              <span>Zur aktuellen Beratung hinzufügen</span>
            </button>
          }
        </div>
        <button
          mat-icon-button
          class="info-button"
          iconName="ic_infozeichen_mit_kreis_24"
          aria-label="Details anzeigen"
          kfTooltipShow="hover"
          kfTooltipPosition="after"
          [kfTooltip]="'Details zu: ' + recSelected.name"
          [kfTooltipDisabled]="isIPad"
          (click)="showRecommendationDetails(recSelected, true)"
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
    }
  }
</ng-template>

<!-- RECOMMENDATION RECOMMENDED -->
<ng-template #recommendationRecommended>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Empfohlen</h3>
  @if (isRecommendedEmpty) {
    <div class="w-100 empty-container">{{ getNoSolutionsMessage('recommended') }}</div>
  } @else {
    @for (rec of recDataRecommended; track rec) {
      <div class="recommendation-container">
        <div class="d-flex flex-column w-100">
          <div
            [debugId]="rec.compositionId"
            class="composition-title"
            [innerHtml]="rec.compositionName | safeHtml"
          ></div>

          <div class="rec-button">
            <mat-checkbox kfFocus [checked]="false" color="grey" (click)="onRecSelected(rec); $event.preventDefault()">
              @switch (rec.type) {
                @case (recommendationTypeEnum.task) {
                  <kf-icon
                    name="ic_person_mit_kreis_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Maßnahme'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.transition) {
                  <kf-icon
                    name="ic_personen_paar_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Expertengespräch'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.subtopic) {
                  <kf-icon
                    name="ic_sprechblase_fragezeichen_ausrufezeichen_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Unterthema'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
                @default {
                  <kf-icon
                    name="ic_allgemeine_karte_24"
                    class="flex-shrink-0 subtopic-icon mr-2"
                    aria-hidden="true"
                    [kfTooltip]="'Produkt'"
                    [kfTooltipDisabled]="isIPad"
                    kfTooltipShow="hover"
                    kfTooltipPosition="after"
                  ></kf-icon>
                }
              }
              <span [debugId]="rec.id" class="label-text" [innerHtml]="rec.name | safeHtml"></span>
            </mat-checkbox>
          </div>

          @if (rec.type === recommendationTypeEnum.subtopic) {
            <button
              mat-stroked-button
              color="primary"
              class="mx-3 add2agenda-button"
              aria-label="Zur aktuellen Beratung hinzufügen"
              (click)="addSubtopicToAgenda(rec)"
            >
              <span>Zur aktuellen Beratung hinzufügen</span>
            </button>
          }
        </div>
        <button
          mat-icon-button
          class="info-button"
          aria-label="Details anzeigen"
          kfTooltipShow="hover"
          kfTooltipPosition="after"
          [kfTooltip]="'Details zu: ' + rec.name"
          [kfTooltipDisabled]="isIPad"
          (click)="showRecommendationDetails(rec, false)"
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
    }
  }
</ng-template>

@if (sideOpen) {
  <button
    mat-icon-button
    class="kf-popup-close"
    aria-label="Panel schließen"
    [kfTooltip]="'Klicken oder ESC drücken, um zu schließen'"
    [kfTooltipDisabled]="isIPad"
    kfTooltipShow="hover"
    kfTooltipPosition="before"
    [attr.data-testcafe]="'sideRecommendation-button-back'"
    (click)="handleClose()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
}
