<marginals-header></marginals-header>

<div class="boxed-content w-header d-flex flex-column flex-scroll flex-grow-1">
  <div class="container">
    <div class="row mb-4 mt-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <h2>Kunden</h2>
          <ng-template *ngTemplateOutlet="header"></ng-template>
        </kf-tile>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <div class="d-flex align-self-start" role="tablist" aria-label="Kundenliste">
            @if (allClients) {
              <div
                class="mr-4 tab-inactive"
                [attr.data-testcafe]="'screenClientList-button-myClients'"
                [ngClass]="{ 'tab-active': currentTab === tab.myClients }"
                (click)="currentTab = tab.myClients"
              >
                @if (favClients) {
                  <div class="ml-1" role="tab" tabindex="0" (keydown)="currentTab = tab.myClients">
                    Meine Kunden ({{ favClients.length }})
                  </div>
                }
              </div>
            }
            @if (origAllClients) {
              <div
                class="mr-4 tab-inactive"
                [attr.data-testcafe]="'screenClientList-button-allClients'"
                [ngClass]="{ 'tab-active': currentTab === tab.allClients }"
                (click)="currentTab = tab.allClients"
              >
                @if (allClients) {
                  <div role="tab" tabindex="0" (keydown)="currentTab = tab.allClients">
                    Alle Kunden ({{ allClients.length }})
                  </div>
                }
              </div>
            }
          </div>

          <!-- ------ CLIENT LIST ------ -->
          @if (allClients) {
            @if (currentTab === tab.myClients) {
              <div class="d-flex align-items-center flex-column w-100 mb-3">
                <div class="w-100 list-item-separator"></div>
                @for (client of favClients; track client) {
                  <ng-template *ngTemplateOutlet="clientContent; context: { $implicit: client }"></ng-template>
                }
              </div>
            }
            @if (currentTab === tab.allClients) {
              <div class="d-flex align-items-center flex-column w-100 mb-3">
                <div class="w-100 list-item-separator"></div>
                @for (client of allClients; track client) {
                  <ng-template *ngTemplateOutlet="clientContent; context: { $implicit: client }"></ng-template>
                }
              </div>
            }
          } @else {
            <mat-progress-spinner
              [color]="'primary'"
              mode="indeterminate"
              class="m-auto"
              [diameter]="90"
            ></mat-progress-spinner>
          }
        </kf-tile>
      </div>
    </div>
    <div class="row" style="height: 64px"></div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #header>
  <div class="d-flex align-items-center w-100">
    <div class="w-100">
      <mat-form-field>
        <mat-label>Suche nach Kundennamen</mat-label>
        <input
          #searchInput
          matInput
          type="search"
          placeholder="Kundenname"
          autocomplete="off"
          [formControl]="searchControl"
          [attr.data-testcafe]="'screenClientList-input-search'"
        />
        @if (searchControl.value) {
          <button
            (click)="searchControl.reset()"
            aria-label="Sucheingaben löschen"
            disableRipple
            mat-icon-button
            matSuffix
          >
            <kf-icon name="ic_clear_24"></kf-icon>
          </button>
        } @else {
          <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
            <kf-icon name="ic_search_24"></kf-icon>
          </button>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex align-items-center w-100 justify-content-end mt-4">
    <div class="button-row">
      <div class="button-row-content">
        <button
          mat-flat-button
          color="primary"
          (click)="onCreateNewCustomer()"
          [attr.data-testcafe]="'screenClientList-button-newCustomer'"
        >
          <span>Neuer Kunde</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- CLIENT CONTENT -->
<ng-template #clientContent let-clientData>
  <div
    class="d-flex align-items-center w-100 list-item-container list-item-separator"
    [attr.data-testcafe]="getTestcafeId(clientData.id, clientData.customerNumber)"
  >
    <div
      class="d-flex flex-column justify-content-center cursor-pointer"
      style="width: 30vw"
      (click)="selectClientOption(clientData)"
    >
      <strong
        [debugId]="clientData.id"
        class="font-weight-bold d-block text-truncate"
        [attr.data-testcafe]="getTestcafeId(clientData.id, clientData.corporationName)"
        [innerHTML]="clientData.corporationName | safeHtml"
      ></strong>
      <span
        class="d-block text-truncate"
        [attr.data-testcafe]="getTestcafeId(clientData.id, clientData.customerNumber)"
        [innerHTML]="'Kunden-Nr. ' + clientData.customerNumber | safeHtml"
      ></span>
      @if (clientData.nextConsultation) {
        <span class="d-block"> Nächste Beratung: {{ handleDateFormat(clientData.nextConsultation) }} </span>
      }
    </div>
    <div class="d-flex flex-grow-1 client-logo">
      <bgzv-image
        class="image-content cursor-pointer"
        [alt]="'logo'"
        [src]="clientData.logo"
        (click)="selectClientOption(clientData)"
      ></bgzv-image>
    </div>

    <div #moreButton>
      <kf-compact-button
        [attr.data-testcafe]="getTestcafeButton(clientData.id, clientData.customerNumber)"
        [matMenuTriggerFor]="menu"
        type="secondary"
        iconName="ic_menue_mehr_vertikal_24"
        label="Kontextmenü öffnen"
      ></kf-compact-button>

      <mat-menu #menu="matMenu">
        <button [attr.data-testcafe]="'dialogContext-button-editCustomer'" (click)="edit(clientData)" mat-menu-item>
          Bearbeiten
        </button>

        @if (!clientData.isExecutiveConsultant) {
          @if (!clientData.isFavourite) {
            <button
              [attr.data-testcafe]="'dialogContext-button-addToFavourites'"
              (click)="setFav(!clientData.isFavourite, clientData)"
              mat-menu-item
            >
              Zu Favoriten hinzufügen
            </button>
          } @else {
            <button
              [attr.data-testcafe]="'dialogContext-button-removeFromFavourites'"
              (click)="setFav(!clientData.isFavourite, clientData)"
              mat-menu-item
            >
              Aus Favoriten entfernen
            </button>
          }
        }

        <button
          mat-menu-item
          (click)="confirmDeletion(clientData)"
          [attr.data-testcafe]="'dialogContext-button-delete'"
        >
          Löschen
        </button>
      </mat-menu>
    </div>
  </div>
</ng-template>
