import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { CheckoutDataFieldItem, OffsetUnit, Validation } from '@domain/app/checkout.domain';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor() {}

  // field error handling
  public getFieldErrorMessage(field: FormControl, name?: string): string {
    if (field.hasError('required')) {
      switch (name.toLowerCase()) {
        case 'email':
          return 'E-Mail-Adresse wird benötigt.';
        case 'firstname':
          return 'Vorname wird benötigt.';
        case 'lastname':
          return 'Nachname wird benötigt.';
        case 'name':
          return 'Titel wird benötigt.';
        case 'corporationname':
          return 'Name des Unternehmens wird benötigt.';
        case 'account':
          return 'Kunden-Nr. wird benötigt.';
        case 'userid':
          return 'Name des Beraters wird benötigt.';
        case 'customercontact':
          return 'Name des Ansprechpartners wird benötigt.';
        default:
          return 'Angabe wird benötigt.';
      }
    } else if (field.hasError('customMessage')) {
      return field.errors.customMessage;
    } else if (field.hasError('pattern')) {
      if (name.toLowerCase() === 'email') {
        return 'Keine gültige E-Mail-Adresse.';
      } else {
        return 'Ungültiges Eingabeformat.';
      }
    }

    return '';
  }

  public validate(validation: Validation): ValidatorFn {
    const { type, validationMessage } = validation;
    return (control: FormControl) => {
      if (!control.value) return null;
      if (type === 'FUTURE') {
        if (!this.checkDateDiff(control.value)) {
          const customMessage = validationMessage || 'Das Datum muss in der Zukunft liegen.';
          return { customMessage };
        }
      }
      if (type === 'PAST_OFFSET_MAX') {
        const { offset, offsetUnit } = validation;
        if (!this.checkDateDiff(control.value, offset, offsetUnit)) {
          const unit = this.mapOffsetUnit(offsetUnit, 'de', offset > 1);
          const diff = [offset, unit].join(' ');
          const customMessage = validationMessage || 'Das Datum darf nicht mehr als ' + diff + ' zurückliegen.';
          return { customMessage };
        }
      }
      return null;
    };
  }

  /** provide min date for date picker if defined in validation */
  public getMinDate(item: CheckoutDataFieldItem): Date {
    if (item.valueValidations) {
      const future = item.valueValidations.find(x => x.type === 'FUTURE');
      const pastOffset = item.valueValidations.find(x => x.type === 'PAST_OFFSET_MAX');
      if (future) {
        return new Date();
      }
      if (pastOffset) {
        const unit = this.mapOffsetUnit(pastOffset.offsetUnit, 'en');
        return moment().subtract(pastOffset.offset, unit).toDate();
      }
    }
    return null;
  }

  /**
   * check whether the date lies within the given range
   * default range is today to future
   */
  private checkDateDiff(value: string | Date, offset: number = 0, offsetUnit: OffsetUnit = OffsetUnit.days): boolean {
    const date = moment(value);
    const today = moment().startOf('day');
    const unit = this.mapOffsetUnit(offsetUnit, 'en');
    return today.diff(date, unit, true) <= offset;
  }

  /** map offset unit to language specific string; consider plural for de only */
  private mapOffsetUnit(offsetUnit: OffsetUnit, lang: 'de' | 'en', plural: boolean = false): any {
    switch (offsetUnit) {
      case OffsetUnit.days:
        return lang === 'en' ? 'days' : plural ? 'Tage' : 'Tag';
      case OffsetUnit.months:
        return lang === 'en' ? 'months' : plural ? 'Monate' : 'Monat';
      case OffsetUnit.years:
        return lang === 'en' ? 'years' : plural ? 'Jahre' : 'Jahr';
    }
  }

  /** convert date of format tt.mm.jjjj to YYYY-MM-DD */
  public formatDate(value: string): string {
    if (!value) return null;
    return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
  }
}
