import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  public action = new Subject<Action>();

  constructor() {}

  setAction(action: Action): Promise<any> {
    // optional promise - for actions after the action
    const promise = new Promise<any | void>((resolve, reject) => {
      this.action.next(action);
      // this.action.next({ target: '', action: '' });
      resolve(null);
    });
    return promise;
  }
}

export interface Action {
  target: string;
  action: string;
  options?: any;
}
