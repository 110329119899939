import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { PanelAction, PanelConfig, Tool } from '@de.fiduciagad.kbm/shared-panel-lib';
import { AppDimensions } from '@domain/app/dimensions.domain';
import { ConsultationCheckoutDocumentsResponse } from '@domain/app/media.domain';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse } from '@domain/app/recommendation.domain';
import { RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { InitService } from '@services/init-service/init.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NetworkService } from '@services/network-service/network.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel/shared-panel.service';
import { LeftSidenavService, RightSidenavService } from '@services/side-service/sidenav.service';
import { StorageService } from '@services/storage-service/storage.service';
import { baseConfig as footerBaseConfig } from '@utils/footer-config';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('leftSidenav', { static: false }) public leftSidenav: MatSidenav;
  @ViewChild('rightSidenav', { static: false }) public rightSidenav: MatSidenav;

  @Input() set consultationModuleData(value: {
    dataInstanceId: string;
    customerNumber: string;
    appointmentId?: string;
  }) {
    this.initService.dataInstanceId = value?.dataInstanceId || null;
    this.initService.customerNumber = value?.customerNumber || null;
    this.initService.appointmentId = value?.appointmentId || null;

    console.log(`%c [bgzv-frontend-main] - consultationModuleData Input`, 'color: #db91db', value);
    this.contextService.measureStartupTime('start');
    this.ignoreInitialNav = true;
    this.contextService.isConsulationModuleContext = true;
    this.rightSidenavService.context = null;
    this.initService.isAdHocConsultation();
  }

  ////// --------------- Shared Footer --------------- //////
  #managedFooter = false;

  @Input() set managedFooter(managed: boolean) {
    this.#managedFooter = managed;
    console.log(`%c [bgzv-frontend-main] - kbm-footer is managedFooter`, 'color: #30dbd8', managed);
  }
  @Output() footerConfigurationChange = new EventEmitter<Record<string, unknown>>();
  get managedFooter() {
    return this.#managedFooter;
  }

  ////// ---------------- Shared Panel --------------- //////
  @Output() panelConfigurationChange = new EventEmitter<Record<string, unknown>>();
  @Output() sidePanelOpenChange = new EventEmitter<void>();
  @Output() focusModeActiveChange = new EventEmitter<boolean>();

  private destroySubs = new Subject<void>();
  private ignoreInitialNav = false;
  private loading = false;

  private mutationObserver: MutationObserver;

  private _isInitialNav = true;

  public dismissOnClick = false;

  public isAWS = environment.platform === 'aws';

  public overlayActive = false;
  public isOffline = false;

  public productDetailInputValues: any;
  public productExtraInputValues: any;
  public transitionInputValues: any;
  public taskInputValues: any;
  public checkoutInputValues: any;
  public sendDocumentsInputValues: ConsultationCheckoutDocumentsResponse[];
  public profileInputValues: any;
  public agendaEditInputValues: any;
  public groupData: {
    recommendationGroupData: QuestionGroupRecommendationResponse;
    questionGroupData: QuestionGroupResponse;
  };

  public footerConfig: FooterConfig = footerBaseConfig;
  public footerPosition: 'float' | 'fix' = 'float';

  public panelConfig = signal<PanelConfig>(null);
  public panelToolConfig = signal<Tool>(null);
  public panelOpened = signal(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public leftSidenavService: LeftSidenavService,
    public rightSidenavService: RightSidenavService,
    private authService: AuthService,
    private actionService: ActionService,
    private contextService: ContextService,
    private clientService: ClientService,
    private configService: ConfigService,
    private queryService: QueryService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private networkService: NetworkService,
    private initService: InitService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.router.initialNavigation();

    this.getConsultationUUID();

    this.contextService.appDimension.pipe(takeUntil(this.destroySubs)).subscribe(dimensionData => {
      if (!!dimensionData && environment.platform === 'vp') {
        let appHeight = dimensionData.height - 1;
        const kbmFooterElement = document.querySelector('kbm-sticky-footer');
        const kbmSharedFooterElement = document.querySelector('kbm-shared-footer');
        if (!!kbmFooterElement || !!kbmSharedFooterElement) {
          // NOT NEEDED ANYMORE - kept for reference

          // if (!!kbmFooterElement.firstElementChild.classList.contains('expanded')) {
          //   appHeight -= kbmFooterElement.firstElementChild.clientHeight;
          // }
          // this.mutationObserver = new MutationObserver(mutations => {
          //   const mutation = mutations[0];
          //   if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          //     if ((mutation.target as HTMLElement).classList.contains('expanded')) {
          //       appHeight -= kbmFooterElement.firstElementChild.clientHeight;
          //     } else {
          //       appHeight += kbmFooterElement.firstElementChild.clientHeight;
          //     }
          //   }
          //   document.documentElement.style.setProperty('--bgzv-app-height', `${appHeight}px`);
          // });

          // this.mutationObserver.observe(kbmFooterElement.firstElementChild, { attributes: true });
          document.documentElement.style.setProperty('--bgzv-app-height', `${appHeight}px`);
        }
      }
    });

    this.contextService.focusModeChange.pipe(takeUntil(this.destroySubs)).subscribe(focusModeState => {
      console.log(`%c [bgzv-frontend-main] - focusModeChange`, 'color: #0066cc', focusModeState);
      this.focusModeActiveChange.emit(focusModeState);
    });

    this.clientService.instanceIdChanged.pipe(takeUntil(this.destroySubs)).subscribe(async instanceId => {
      await this.configService.getAppConfigurationData(true);

      // used to get the instance name for the agenda
      const instances = await lastValueFrom(this.queryService.getInstances());
      this.clientService.instanceName = instances.find(x => x.id === instanceId)?.name || '';
    });

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.handleActions(action);
    });

    this.authService.currentAuthState.pipe(takeUntil(this.destroySubs)).subscribe(async authState => {
      if (authState === null) {
        this.authService.getSession();
        return;
      }

      this.handleInitialRoute();

      if (this.authService.authState === 'signedIn') {
        try {
          await this.configService.getHubConfigurationData(true);
        } catch (e) {
          console.log(`%c [bgzv-frontend-main] - error with signing-in`, 'color: #0066cc', e.message);
        }

        if (environment.platform === 'aws') {
          const result = await lastValueFrom(this.queryService.getBankUser(this.clientService.bankExternalId));
          this.clientService.bankConsultId = result.id;
        }
      }

      if (environment.platform === 'aws') {
        this.handleConfigColors();
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
    });

    this.handleSharedFooterEvents();
    this.handleSharedPanelEvents();

    this.handlePingSettings();

    if (environment.platform === 'vp') {
      const host = `${window.location.protocol}//${window.location.host}`;
      (window as any).pdfWorkerSrc = `${host}${this.contextService.appPath}/pdf-worker.js`;
    }
  }

  ngOnDestroy(): void {
    document.documentElement.style.setProperty('--bgzv-app-height', `unset`);
    this.mutationObserver?.disconnect();
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.leftSidenavService.setSidenav(this.leftSidenav);
    this.rightSidenavService.setSidenav(this.rightSidenav);
    setTimeout(() => {
      this.leftSidenavService.context = 'navigation';
      this.rightSidenavService.context = null;
    });

    const dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.contextService.setAppDimensions(dimensions);

    this.rightSidenavService.instance._animationStarted.pipe(takeUntil(this.destroySubs)).subscribe(animationEnded => {
      if (this.overlayActive && !this.rightSidenavService.forcedClose) {
        this.dismissOnClick = !this.dismissOnClick;
      } else if (this.rightSidenavService.forcedClose) {
        this.dismissOnClick = false;
      }
      this.rightSidenavService.forcedClose = false;
    });

    if (this.isEntryPoint && environment.platform === 'vp') {
      window.scroll(0, 0);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const newDimensions = {
      width: event.target.innerWidth,
      height: event.target.innerHeight,
    } as AppDimensions;
    this.contextService.setAppDimensions(newDimensions);
  }

  @HostListener('window:keydown.escape', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const context = this.rightSidenavService.context;
    if (this.overlayActive) {
      if (context === 'product' || context === 'task' || context === 'transition') {
        this.rightSidenavService.close();
      } else {
        this.contextService.closeOverlay();
      }
    }
  }

  @HostListener('kbmFooterAction', ['$event'])
  onCustomEvent(event: CustomEvent) {
    const receivedAction = event.detail as FooterAction;
    console.log(`%c [bgzv-frontend-main] - received kbm-footer event`, 'color: #30dbd8', receivedAction);

    this.onFooterAction(receivedAction);
  }

  @HostListener('kbmPanelAction', ['$event'])
  onPanelEvent(event: CustomEvent) {
    const receivedAction = event.detail as PanelAction;
    console.log(`%c [bgzv-frontend-main] - received kbm-panel event`, 'color: #30dbf0', receivedAction);

    this.onPanelAction(receivedAction);
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv' || event.owner === 'shared-footer') {
      if (!this.loading) {
        if (this.leftSidenavService.instance.opened) {
          this.leftSidenavService.close();
        }
        if (event.id !== 'open-agenda' && this.rightSidenavService.instance.opened) {
          this.rightSidenavService.forcedClose = true;
          this.rightSidenavService.close();
        }
        this.footerService.handleFooterAction(event);
      }
    } else if (event.id === 'close') {
      if (this.overlayActive) {
        this.contextService.closeOverlay();
      }
      this.contextService.focusModeOff();
      this.contextService.emitFocusModeChange(false);
    }
  }

  public onPanelAction(event: PanelAction): void {
    if (event.owner === 'bgzv') {
      if (event.id === 'bgzv-close-panel') {
        this.panelOpened.set(false);
        this.panelService.panelOpen.next(false);
      } else if (event.id === 'bgzv-edit-panel') {
        if (this.rightSidenavService.instance.opened) {
          this.rightSidenavService.forcedClose = true;
          this.rightSidenavService.close();
        }

        this.doAction('overlay-main', 'agenda-edit');
      } else if (event.id === 'bgzv-subtopic-select') {
        if (this.rightSidenavService.instance.opened) {
          this.rightSidenavService.forcedClose = true;
          this.rightSidenavService.close();
        }

        this.contextService.jumpToSubtopic(event.payload['id']);
      } else if (event.id === 'bgzv-preliminary-send') {
        this.doAction('main', 'toggle');
      }
    } else if (event.owner === '') {
      // this will only ever happen in Consultation Module Mode
      if (event.id === 'toggle-side-panel') {
        const payload = event.payload as any;
        this.panelOpened.set(payload.opened);
        this.panelService.panelOpen.next(false);
      }
    }
  }

  public onSideNavClose() {
    if (
      this.rightSidenavService.context === 'toggle' &&
      this.contextService.prepToggleMode === 'main' &&
      this.contextService.consultationStatusChanged
    ) {
      if (environment.platform === 'vp') {
        this.router.navigate([RoutingPathMain.Agenda]);
      } else {
        this.router.navigate([RoutingPathMain.ClientConsultations]);
      }
    }
  }

  public onAgendaClick() {
    this.router.navigate([`${this.contextService.currentMode}/agenda/${this.clientService.bankHubId}`]);
  }

  private handleActions(action: Action): void {
    if (action && action.target === 'main') {
      if (action.action === 'edit') {
        this.leftSidenavService.useOnce('edit');
        this.leftSidenavService.toggle();
      }
      if (action.action === 'profile') {
        this.profileInputValues = action?.options || null;
        this.rightSidenavService.useOnce('profile');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'chat') {
        this.rightSidenavService.useOnce('chat');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'home') {
        this.router.navigate([RoutingPathMain.Welcome]);
      }
      if (action.action === 'product') {
        this.productDetailInputValues = action.options;
        this.rightSidenavService.useOnce('product');
        if (!action?.options?.keepNavOpen) {
          this.rightSidenavService.toggle();
        }
      }
      if (action.action === 'productExtrasToggle') {
        this.productExtraInputValues = action.options;
        this.rightSidenavService.useOnce('productExtras');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'productExtrasUpdate') {
        this.productExtraInputValues = action.options;
        this.rightSidenavService.useOnce('productExtras');
      }
      if (action.action === 'task') {
        this.taskInputValues = action.options;
        this.rightSidenavService.useOnce('task');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'task-indiv') {
        this.taskInputValues = action.options;
        this.rightSidenavService.useOnce('task-indiv');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'transition') {
        this.transitionInputValues = action.options;
        this.rightSidenavService.useOnce('transition');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'transition-indiv') {
        this.transitionInputValues = action.options;
        this.rightSidenavService.useOnce('transition-indiv');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'checkout') {
        this.checkoutInputValues = action.options;
        this.rightSidenavService.useOnce('checkout');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'recommendations') {
        this.groupData = action.options;
        this.rightSidenavService.useOnce('recommendations');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'toggle') {
        this.rightSidenavService.useOnce('toggle');
        this.rightSidenavService.toggle();
      }
      if (action.action === 'send-documents') {
        this.sendDocumentsInputValues = action.options;
        this.rightSidenavService.useOnce('send-documents');
        this.rightSidenavService.toggle().then();
      }
      if (action.action === 'notes') {
        this.rightSidenavService.useOnce('notes');
        this.rightSidenavService.toggle().then();
      }
      if (action.action === 'error-redirect') {
        this.router.navigate([`${RoutingPathMain.Error}`], {
          state: { reason: 'error' },
        });
      }
      if (action.action === 'error503') {
        this.router.navigate([`${RoutingPathMain.Error}`], {
          state: { reason: 'error503' },
        });
      }
    } else if (action && action.target === 'overlay-main') {
      if (action.action === 'close') {
        this.contextService.closeOverlay();
      } else if (action.action === 'close-logout') {
        this.router.navigateByUrl(RoutingPathMain.Login, {
          state: { reason: 'unauthorized' },
        });
      } else if (action.action === 'close-error') {
        this.router.navigate([`${RoutingPathMain.Error}`], {
          state: { reason: 'invalidUrl' },
        });
      } else {
        // Note: the action property needs to have the same name as the overlay path
        // For example: action.action === 'cart' will open the cart overlay
        // See the RoutingPathOverlay enum for valid values
        this.router.navigate([{ outlets: { overlay: `${action.action}` } }], {
          relativeTo: this.route,
          state: action.options,
        });
      }
    }
  }

  private handleSharedFooterEvents(): void {
    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      if (!!config) {
        this.footerConfig = config;

        const configCopy = () => {
          return JSON.parse(JSON.stringify(this.footerConfig));
        };

        if (this.managedFooter) {
          console.log(`%c [bgzv-frontend-main] - footerConfigChanged`, 'color: #30dbd8', configCopy());
          this.footerConfigurationChange.emit(configCopy() as Record<string, unknown>);
        }
      }
    });

    this.footerService.footerPositionChanged.pipe(takeUntil(this.destroySubs)).subscribe(position => {
      if (!!position) {
        this.footerPosition = position;
      }
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action.owner === 'bgzv') {
        if (action.id === 'open-agenda') {
          if (this.panelOpened()) {
            console.log(`%c [bgzv-frontend-main] - closePanel`, 'color: #30dbf0');
            this.panelOpened.set(false);
            this.panelService.panelOpen.next(false);
          } else if (!this.panelOpened()) {
            console.log(`%c [bgzv-frontend-main] - openPanel`, 'color: #30dbf0');
            this.panelOpened.set(true);
            this.panelService.panelOpen.next(true);
          }
        } else if (action.id === 'force-close-agenda') {
          console.log(`%c [bgzv-frontend-main] - force closePanel`, 'color: #30dbf0');
          //standalone implementation
          if (this.panelOpened()) {
            this.sidePanelOpenChange.emit();
          }
          this.panelOpened.set(false);
          this.panelService.panelOpen.next(false);
        }
      }
    });

    this.footerService.isManagedFooter = this.managedFooter;
    this.contextService.isManagedFooter = this.managedFooter; // to simplify DI in other components
  }

  private handleSharedPanelEvents(): void {
    this.panelService.panelConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      if (!!config) {
        const configCopy = (config: PanelConfig) => {
          return JSON.parse(JSON.stringify(config));
        };

        this.panelConfig = configCopy(config);

        console.log(`%c [bgzv-frontend-main] - panelConfigChanged`, 'color: #30dbf0', configCopy(config));
      }
    });

    this.panelService.panelToolConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      if (!!config) {
        const configCopy = (config: Tool) => {
          return JSON.parse(JSON.stringify(config));
        };
        this.panelToolConfig.set(configCopy(config));
        this.panelConfigurationChange.emit(configCopy(config) as Record<string, unknown>);

        console.log(`%c [bgzv-frontend-main] - panelToolConfigChanged`, 'color: #30dbf0', this.panelToolConfig());
      }
    });
  }

  private handlePingSettings(): void {
    if (!environment.enableOnlinePing) {
      return;
    }
    this.networkService
      .onlineDetection()
      .pipe(takeUntil(this.destroySubs))
      .subscribe(networkState => {
        const wasOffline = this.isOffline;
        this.isOffline = !(networkState.hasInternetAccess && networkState.hasNetworkConnection);
        if (this.isOffline && !wasOffline) {
          this.snackBarService.openSnackBar({ type: SnackbarType.ALERT, message: 'Keine Internetverbindung' });
        } else if (!this.isOffline) {
          this.snackBarService.dismissSnackBar();
        }
      });
  }

  private handleInitialRoute(): void {
    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (context !== null && context !== undefined) {
        this.overlayActive = context.includes('overlay:');

        if (this.ignoreInitialNav) {
          this.initService.loadingActive = true;
          this.ignoreInitialNav = false;
          if (this.overlayActive && this.isInitialNav) {
            this.isInitialNav = false;
            this.contextService.closeOverlay().then(success => {
              this.router.navigate(['init']);
            });
          } else {
            this.isInitialNav = false;
            this.ignoreInitialNav = false;
            this.router.navigate(['init']);
          }
        }

        if (this.isInitialNav) {
          this.rightSidenavService.context = null;
          this.contextService.closeOverlay().then(success => {
            this.isInitialNav = false;

            if (context === '' || context.search('welcome') !== -1) {
              console.log(`%c [bgzv-frontend-main] - initial route check - welcome`, 'color: #0066cc');
              this.router.navigate(['welcome']);
              return;
            }

            const summaryRegex = /main\/result\/summary\/\S+/;
            // const summaryRegex = /main\/result\/summary\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}

            if (summaryRegex.test(context)) {
              console.log(`%c [bgzv-frontend-main] - initial route check - summary`, 'color: #0066cc');
              const ic = context.lastIndexOf('/');
              const id = context.substring(ic + 1);
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([`${RoutingPathMain.ResultSummary}/${id}`], { state: { origin: 'init' } });
              });

              return;
            }

            const topicRegex = /(?:main|prep)\/agenda\/\S+/;
            if (topicRegex.test(context)) {
              console.log(`%c [bgzv-frontend-main] - initial route check - agenda`, 'color: #0066cc');
              const hubId = this.storageService.get('bgzvHubId');
              if (hubId && hubId !== '') {
                this.contextService.currentMode === 'prep'
                  ? this.router.navigate([`${RoutingPathPrep.Agenda}/${hubId}`])
                  : this.router.navigate([`${RoutingPathMain.Agenda}/${hubId}`]);
              } else if (environment.platform === 'aws' && (context === 'login' || context === '')) {
                this.router.navigate([RoutingPathMain.Login]);
              } else {
                this.router.navigate([RoutingPathMain.Error], {
                  state: { reason: 'noHubId' },
                });
              }
              return;
            }
          });
        }
      }
    });
  }

  // TODO: Jan: Check wether this still works or needs adaptation!
  private handleConfigColors() {
    let primary;
    let primaryVariant;
    let secondary;

    const hexToRgb = hex =>
      hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));

    primary = this.configService.getSettingsValue('primaryColorFrontend', 'hub');
    primaryVariant = this.configService.getSettingsValue('primaryVariantColorFrontend', 'hub');
    secondary = this.configService.getSettingsValue('secondaryColorFrontend', 'hub');

    console.log(`%c [bgzv-frontend-main (AWS only)] - handleConfigColors`, 'color: #0066cc');
    if (primary) {
      console.log(`%c [bgzv-frontend-main (AWS only)] - primary color: ${primary}`, 'color: #0066cc');
      document.documentElement.style.setProperty('--color-primary', primary);
    }
    if (primaryVariant) {
      console.log(`%c [bgzv-frontend-main (AWS only)] - primary variant color: ${primary}`, 'color: #0066cc');
      document.documentElement.style.setProperty('--color-primary-variant', primary);
    }
    if (secondary) {
      console.log(`%c [bgzv-frontend-main (AWS only)] - secondary color: ${secondary}`, 'color: #0066cc');
      document.documentElement.style.setProperty('--color-accent', secondary);
    }
    if (!primary && !secondary) {
      console.log(`%c [bgzv-frontend-main (AWS only)] - no primary and secondary colors set`, 'color: #0066cc');
    }
  }

  private getConsultationUUID() {
    const componentItem = sessionStorage.getItem('component');
    const componentObject = JSON.parse(componentItem);
    const uuid = componentObject?.data['kbm-gespraech-durchfuehrung']?.gespraechstermin?.gespraechsterminUuid || '';

    this.clientService.appointmentUUID = uuid || '';

    if (uuid === '' && !!this.clientService.consultationId) {
      this.queryService.getConsultationByConsultant().subscribe(x => {
        this.clientService.appointmentUUID = x.customers
          .flatMap(x => x.consultations)
          .find(x => x.id === this.clientService.consultationId)?.appointmentId;
      });
    }
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get isInitialNav() {
    return this._isInitialNav;
  }
  set isInitialNav(value) {
    this._isInitialNav = value;
  }

  get isEntryPoint() {
    return this.contextService.currentUrl === 'welcome' || this.contextService.currentUrl === '';
  }

  get isInitPoint() {
    return (
      this.contextService?.currentUrl?.search('init') !== -1 &&
      this.contextService?.currentUrl?.search('init') !== undefined
    );
  }

  get platform(): 'aws' | 'vp' {
    return environment.platform as 'aws' | 'vp';
  }

  get showAgendaTab(): boolean {
    return (
      this.contextService.currentMainContext === RoutingPathMain.Consultation ||
      this.contextService.currentMainContext === RoutingPathPrep.Consultation
    );
  }

  get showAppointmentTab(): boolean {
    return (this.clientService.appointmentUUID !== '' && !this.contextService.isConsulationModuleContext) || false;
  }

  get initMessage(): string {
    return this.initService.loadingMessage;
  }

  get initActive(): boolean {
    return this.initService.loadingActive;
  }
}
