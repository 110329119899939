<div class="notes-menu px-3 mt-3">
  <div class="d-flex justify-content-between">
    <div class="ml-3 mb-2 font-weight-bold text-truncate order-0">
      <span *ngIf="allNotes && (allNotes?.notes.length > 0 || allNotes?.notesByPageReference?.length > 0)"
        >Allgemeines</span
      >
    </div>
  </div>
  <ng-container *ngIf="allNotes">
    <!-- custom notes -->
    <ng-container *ngFor="let note of allNotes.notes">
      <select-note-button
        #noteButton
        *ngIf="deletedNote !== note.id"
        [attr.data-testcafe]="note.name"
        [editMode]="noteButton === selectedEditNote"
        [noteName]="note.name"
        [viewProperties]="getViewProperties(note)"
        [isSelected]="selectedNote === note.id"
        [type]="note.type"
        (click)="selectNote(note.id, true, note.type)"
        [depth]="0"
        [disabled]="loading"
        [class.disabled]="loading"
        [isCustomNote]="true"
        [id]="note.id"
      ></select-note-button>
    </ng-container>

    <!-- notes by page reference -->
    <ng-container *ngFor="let note of allNotes.notesByPageReference">
      <select-note-button
        *ngIf="filterPageReference() === noteContextEnum.topicSelection && deletedNote !== note.noteId"
        [noteName]="topicOverviewNote.pageReference | pageReference"
        [isSelected]="selectedNote === note.noteId && note.noteType === presetNoteType"
        [isTopicSelection]="true"
        [viewProperties]="getViewProperties(note)"
        [type]="note.noteType"
        (click)="selectNote(note.noteId, false, note.noteType)"
        [depth]="0"
        [disabled]="loading"
      ></select-note-button>

      <select-note-button
        *ngIf="filterPageReference() !== noteContextEnum.topicSelection && deletedNote !== note.noteId"
        [noteName]="note.pageReference | pageReference"
        [isSelected]="selectedNote === note.noteId"
        [type]="note.noteType"
        [viewProperties]="getViewProperties(note)"
        (click)="selectNote(note.noteId, false, note.noteType)"
        [depth]="0"
        [disabled]="loading"
      >
      </select-note-button>
    </ng-container>

    <!-- notes by topic -->
    <div class="flex-scroll">
      <div *ngFor="let topic of allNotes.notesByTopic" class="mt-3">
        <div class="ml-3 mb-2 font-weight-bold text-truncate">
          {{ topic.topicName }}
        </div>
        <ng-container *ngFor="let note of topic.notes">
          <select-note-button
            *ngIf="deletedNote !== note.noteId"
            [viewProperties]="getViewProperties(note)"
            [isSelected]="selectedNote === note.noteId"
            [questionGroupName]="note.questionGroupName"
            [questionGroupId]="note.questionGroupId"
            [type]="note.noteType"
            (click)="selectNote(note.noteId, false, note.noteType)"
            [depth]="1"
            [disabled]="loading"
          >
          </select-note-button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
