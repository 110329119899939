import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[markNegativePrice]',
  standalone: false,
})
export class MarkNegativePriceDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    const value = this.el.nativeElement.innerHTML.trim();
    if (value && value.length > 1 && value[0] === '-' && /^\d+$/.test(value[1])) {
      this.el.nativeElement.style.color = 'var(--color-warn)';
    }
  }
}
