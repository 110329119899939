import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import {
  ContentElementItem,
  DeselectContentElementItem,
  SelectContentElementItem,
} from '@domain/app/content-element.domain';
import { SubtopicOverviewItem } from '@domain/app/topic.domain';
import { PanelStateEnum, RecommendationTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { QueryService } from '@services/query-service/query.service';
import { getContactName } from '@utils/helpers/formatContact';
import { Subscription } from 'rxjs';

@Component({
  selector: 'side-content-element-overview',
  templateUrl: './side-content-element-overview.component.html',
  styleUrl: './side-content-element-overview.component.scss',
  imports: [MaterialModule, KfThemeModule],
})
export class SideContentElementOverviewComponent {
  @Input() contentElementData: ContentElementItem;
  @Input() subtopicData: SubtopicOverviewItem;

  @Output() changePanelState: EventEmitter<PanelStateEnum> = new EventEmitter<PanelStateEnum>();
  @Output() selectionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subtopicAddedToAgenda: EventEmitter<any> = new EventEmitter<any>();

  public readonly panelStateEnum = PanelStateEnum;
  public readonly recommendationTypeEnum = RecommendationTypeEnum;

  private _dialogSub: Subscription;
  private _dialogRef = null;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService,
    private contentElementService: ContentElementService,
    private dialogService: DialogService,
    private contextService: ContextService
  ) {}

  public selectContentElement(): void {
    const sendObj: SelectContentElementItem = { themaId: this.contentElementData.id, subtopicId: this.subtopicData.id };
    this.queryService.putSelectThema(this.clientService.consultationId, sendObj).subscribe(x => {
      this.selectionChanged.emit();
    });
  }

  public async addSubtopicToAgenda(): Promise<void> {
    await this.contentElementService.addSubtopicToAgenda(this.contentElementData.referenceId);
    this.subtopicAddedToAgenda.emit();
  }

  public async toggleContextMode(): Promise<void> {
    const data = {
      copyText: `Möchten Sie die Themenempfehlung "${this.contentElementData.name}" aus dem Lösungskorb entfernen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this._dialogRef = this.dialogService.openDialogConfirm(data);
    this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.deselectContentElement();
      }
      this._dialogRef = null;
    });
  }

  private deselectContentElement(): void {
    const sendObj: DeselectContentElementItem = {
      themaId: this.contentElementData.id,
      target: this.contentElementData.recommended ? 'RECOMMENDED' : 'OTHER',
    };
    this.queryService.putDeselectThema(this.clientService.consultationId, sendObj).subscribe(x => {
      this.selectionChanged.emit(true);
      this._dialogSub?.unsubscribe();
    });
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get contactName() {
    return getContactName(this.contentElementData?.customerContact);
  }
}
