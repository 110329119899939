import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Action, ActionService } from '@services/action-service/action.service';
import { ConfigService } from '@services/config-service/config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'overlay-debug',
  templateUrl: './overlay-debug.component.html',
  styleUrls: ['./overlay-debug.component.scss'],
  standalone: false,
})
export class OverlayDebugComponent implements OnInit, OnDestroy, AfterViewInit {
  private _actionSub: Subscription;

  constructor(
    private actionService: ActionService,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'overlay-debug') {
      }
    });
  }

  ngOnDestroy(): void {
    if (this._actionSub) {
      this._actionSub.unsubscribe();
    }
  }

  ngAfterViewInit() {}

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }
}
