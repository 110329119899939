export const Settings = {
  noteMaxLength: 4000,
};

export const bgzvDimensions = {
  footerHeightPx: 64,
  headerHeightPx: 57,
};

export const tinyLicenseKey = 'T7LK7464E4DC01DF0F8075464E29EAAFF96DE546A1BF2036375F0840DD163A13';
