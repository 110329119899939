import { Component, Input } from '@angular/core';
import { Profile } from '@domain/app/profile';
import { DataFieldTypeEnum } from '@enums';
import { getValue } from '@marginals/side-profile/side-profile.component';
import ProfileDataFieldGroupPlain = Profile.ProfileDataFieldGroupPlain;

@Component({
  selector: 'profile-data-field-group-plain',
  templateUrl: './profile-data-field-group-plain.component.html',
  styleUrls: ['./profile-data-field-group-plain.component.scss'],
})
export class ProfileDataFieldGroupPlainComponent {
  @Input() dataFieldGroup: ProfileDataFieldGroupPlain;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;

  public getTestcafeId(name = '') {
    return `profileDataField-plain-${name?.replace(/ /g, '')}`;
  }
}
