import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchOverlay } from '@domain/app/search-overlay';
import { QuestionGroupOverviewItem, SelectedTopicItem, SubtopicOverviewItem } from '@domain/app/topic.domain';
import { RoutingPathMain, RoutingPathPrep } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { SearchService } from '@services/search-service/search.service';
import { color } from '@utils/helpers/color';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import QuestionWorldData = SearchOverlay.QuestionWorldData;

@Component({
  selector: 'search-question-world-column',
  templateUrl: './search-question-world-column.component.html',
  styleUrls: ['./search-question-world-column.component.scss'],
  standalone: false,
})
export class SearchQuestionWorldColumnComponent implements OnInit, OnDestroy {
  private _data: QuestionWorldData;
  private _results: SubtopicOverviewItem[] = [];
  private contextSubscription?: Subscription;
  private dataSubscription?: Subscription;
  private context?: string;
  readonly color = color;

  constructor(
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly actionService: ActionService,
    private readonly searchService: SearchService,
    private queryService: QueryService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    this.searchService.searchQuery = '';
    this.contextSubscription = this.contextService.context
      .pipe(filter(context => context !== null && context !== undefined))
      .subscribe(() => (this.context = this.contextService.currentMainContext));

    this.dataSubscription = this.searchService.questionWorldData.subscribe(questionWorldData => {
      this._data = {
        ...questionWorldData,
        topics: [
          ...questionWorldData.topics.filter(x => x.availableForPreliminary),
          ...questionWorldData.topics.filter(x => !x.availableForPreliminary),
        ],
      };

      this._results = questionWorldData.topics
        .flatMap(x => x.subtopics)
        .filter(y => questionWorldData.subtopicHasSearchResults(y));

      // don't show the prep-topics
      this._results = this._results.filter(z => !(z.availableForPreliminary || z.consultantSubtopic));

      if (!this.searchService.searchQueryValue) {
        this._results = this._results.filter(z => z.selected);
      }
    });
  }

  ngOnDestroy(): void {
    this.contextSubscription?.unsubscribe();
    this.dataSubscription?.unsubscribe();
  }

  public onSubtopicSelect(item: SubtopicOverviewItem): void {
    // TODO: Correct handling for subtopic order (new navigation)
    this.handleJump(item);
  }

  public handleJump(item: SubtopicOverviewItem): void {
    if (!item.selected) {
      // get Other selected Topics
      const otherTopics: SelectedTopicItem[] = this._data.topics
        .map(x => {
          if (x.selected) {
            return {
              id: x.id,
              selectedSubtopicIds: x.subtopics
                .map(y => {
                  if (y.selected) {
                    return y.id;
                  }
                  return null;
                })
                .filter(y => y),
            };
          }
          return null;
        })
        .filter(y => y);

      // selectedTopic
      const topic = this._data.topics.find(x => x.subtopics.find(y => y === item));
      const subtopic = topic.subtopics.find(y => y === item);

      if (otherTopics.find(x => x.id === topic.id)) {
        otherTopics.find(x => topic.id === topic.id).selectedSubtopicIds.push(subtopic.id);
      } else {
        const sendObj: SelectedTopicItem = {
          id: topic.id,
          selectedSubtopicIds: topic.subtopics
            .map(x => {
              if (x.selected) {
                return x.id;
              }
              return null;
            })
            .filter(y => y),
        };
        sendObj.selectedSubtopicIds.push(subtopic.id);
        otherTopics.push(sendObj);
      }
      this.queryService.putTopicDataByConsultationId(this.clientService.consultationId, otherTopics).subscribe(x => {
        this.jumpToQuestionGroupSearch(item);
      });
    } else {
      this.jumpToQuestionGroupSearch(item);
    }
  }

  public isFirstMainTopic(subtopic: SubtopicOverviewItem): boolean {
    return this.subtopicResults.find(x => !(x.consultantSubtopic || x.availableForPreliminary))?.id === subtopic.id;
  }

  private jumpToQuestionGroup(eventData: [boolean, QuestionGroupOverviewItem]): void {
    const checked = eventData[0];
    const questionGroupId = eventData[1].id;
    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {
      if (
        this.contextService.currentMode === 'prep' &&
        this.contextService.currentMainContext !== RoutingPathPrep.Consultation
      ) {
        return this.router.navigate([RoutingPathPrep.Consultation], { state: { questionGroupId } });
      } else if (
        this.contextService.currentMode === 'main' &&
        this.contextService.currentMainContext !== RoutingPathMain.Consultation
      ) {
        return this.router.navigate([RoutingPathMain.Consultation], { state: { questionGroupId } });
      } else {
        return this.actionService.setAction({
          target: 'consultation',
          action: 'jump',
          options: { questionGroupId },
        });
      }
    });
  }

  private jumpToQuestionGroupSearch(item: SubtopicOverviewItem): void {
    if (item?.questionGroups.length > 0) {
      this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {
        if (
          this.contextService.currentMode === 'prep' &&
          this.contextService.currentMainContext !== RoutingPathPrep.Consultation
        ) {
          return this.router.navigate([RoutingPathPrep.Consultation], {
            state: { questionGroupId: item.questionGroups[0].id },
          });
        } else if (
          this.contextService.currentMode === 'main' &&
          this.contextService.currentMainContext !== RoutingPathMain.Consultation
        ) {
          return this.router.navigate([RoutingPathMain.Consultation], {
            state: { questionGroupId: item.questionGroups[0].id },
          });
        } else {
          return this.actionService.setAction({
            target: 'consultation',
            action: 'jump',
            options: { questionGroupId: item.questionGroups[0].id },
          });
        }
      });
    }
  }

  get questionWorldData(): QuestionWorldData {
    return this._data;
  }

  get subtopicResults(): SubtopicOverviewItem[] {
    return this._results;
  }

  get searchQuery(): string {
    return this.searchService.searchQueryValue;
  }

  get hasConsultantTopic() {
    return this.questionWorldData.topics.find(x => x.consultantTopic || x.availableForPreliminary);
  }

  get getFirstMainTopic() {
    return this.questionWorldData.topics.indexOf(
      this.questionWorldData.topics.find(x => !x.consultantTopic && !x.availableForPreliminary)
    );
  }

  get consultantTopicCount() {
    return this.subtopicResults.filter(x => x.consultantSubtopic || x.availableForPreliminary).length;
  }
}
