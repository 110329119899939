<mat-expansion-panel
  class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
  *ngFor="let profileGroup of filteredProfileData"
  #profileGroupPanel
  [expanded]="true"
>
  <mat-expansion-panel-header>
    <mat-panel-title [attr.data-testcafe]="'sideProfile-matHeader-'">
      <strong class="font-weight-bold">{{ profileGroup.category | profileGroupName }}</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div *ngIf="profileGroup.category === 'GENERIC'">
    <mat-checkbox
      class="vat-discount"
      [checked]="userProfileData.vatDiscount"
      (change)="onVatCheckboxChange($event.checked)"
      [color]="'grey'"
      >Vorsteuerabzugsberechtigt
    </mat-checkbox>
  </div>
</mat-expansion-panel>
