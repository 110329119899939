<aside class="d-flex flex-column app-height align-items-center kf-bg product-details-container-width">
  <header class="d-flex w-100 justify-content-between side-header">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="d-flex align-items-center">
        @if (productInfo && !productDetails) {
          <button
            [attr.data-testcafe]="'sideProductDetails-button-back'"
            (click)="onDataCancelled()"
            aria-label="Ansicht schließen"
            kfTooltip="Ansicht schließen"
            kfTooltipPosition="before"
            kfTooltipShow="hover"
            class="mr-2"
            mat-icon-button
          >
            <kf-icon name="ic_chevron_links_24"></kf-icon>
          </button>
        }

        <div class="d-flex flex-column">
          @if (dataFieldData?.compositionName) {
            <ng-container>
              <h2
                class="text-truncate mb-0 side-heading"
                [kfTooltip]="dataFieldData?.compositionName.length > 50 ? dataFieldData.compositionName : null"
                kfTooltipPosition="below"
                kfTooltipShow="hover"
              >
                {{ dataFieldData?.compositionName }}
              </h2>
              @if (productData?.name !== dataFieldData?.compositionName) {
                <p class="text-truncate mb-0">{{ productData?.name }}</p>
              }
            </ng-container>
          }
          @if (!dataFieldData?.compositionName) {
            <ng-container>
              <h2
                class="text-truncate mb-0 side-heading"
                [kfTooltip]="productData?.name.length > 50 ? productData.name : null"
                kfTooltipPosition="below"
                kfTooltipShow="hover"
              >
                {{ productData?.name }}
              </h2>
            </ng-container>
          }
        </div>
      </div>
    </div>
  </header>

  @if (productData) {
    @if (state === productState.default) {
      <ng-template *ngTemplateOutlet="default"></ng-template>
    }
    @if (state === productState.customer) {
      <ng-template *ngTemplateOutlet="customer"></ng-template>
    }
  } @else {
    <ng-template *ngTemplateOutlet="loading"></ng-template>
  }
  @if (productDetails) {
    <button
      [attr.data-testcafe]="'sideProductDetails-button-close'"
      class="kf-popup-close"
      mat-icon-button
      (click)="handleClose()"
      aria-label="Panel schließen"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>
  }
</aside>

<!-- --------------------------------------- -->
<!-- -------------- TEMPLATES -------------- -->
<!-- --------------------------------------- -->

<!---HEADER TEMPLATE-->
<ng-template #productHeader let-mediaData>
  @if (documentCount > 0 || image.url) {
    <div class="d-flex w-100">
      <div class="d-flex justify-content-end mr-5 side-container-left">
        @if (image) {
          <div class="d-flex flex-grow-1 align-items-start justify-content-start">
            <bgzv-image
              class="d-flex detail-logo image-content"
              [alt]="image.altText"
              [src]="image.url"
              [width]="'80px'"
            ></bgzv-image>
          </div>
        } @else {
          <div class="d-flex detail-logo image-content"></div>
        }
      </div>
      @if (documentCount > 0) {
        <div class="d-flex side-container-right">
          <div class="mb-5">
            <h3 class="font-weight-bold mb-1">Dokumente</h3>
            <div class="d-flex w-100">
              <div class="d-flex">
                <button
                  class="button-badge"
                  [disabled]="isLoading"
                  [matMenuTriggerFor]="menu"
                  mat-stroked-button
                  color="primary"
                  [matBadge]="documentCount"
                  matBadgeOverlap="true"
                  matBadgePosition="above after"
                  matBadgeColor="primary"
                >
                  <kf-icon name="ic_datei_24"></kf-icon>
                  <span>Dokumente</span>
                </button>
                <mat-menu #menu="matMenu">
                  @for (document of documents; track document) {
                    <button mat-menu-item (click)="showDocument(document, $event)">
                      {{ document.name + ' (' + document.type + ')' }}
                    </button>
                  }
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</ng-template>

<!-- -------- QUANTITY TEMPLATE -------- -->
<ng-template #quantityConfig>
  @if (productData.quantityVisibility) {
    <div class="d-flex divider"></div>
    <div class="d-flex flex-column">
      <h4 class="mat-body-2 font-weight-bold mb-2">{{ productData.quantityDescription || 'Anzahl' }}</h4>
      <ng-container>
        <item-counter
          #productNum
          [id]=""
          [value]="productQuantity"
          [disabled]="isLoading"
          [minValue]="0"
          [maxValue]="productData.quantityMaxAmount ? productData.quantityMaxAmount : 100"
          [testcafeData]="getTestcafeId(productData.name)"
          (valueChange)="setProductQuantity($event)"
        ></item-counter>
      </ng-container>
    </div>
  }
</ng-template>

<!-- PRICING TEMPLATE -->
<ng-template #pricing>
  @if (productData.runningCost || productData.fixCost) {
    <div class="d-flex divider"></div>
    @if (!productData.multiplePrices && productData.fixCost) {
      <ng-template *ngTemplateOutlet="fixCost"></ng-template>
    }
    @if (!productData.multiplePrices && productData.runningCost) {
      <ng-template *ngTemplateOutlet="runningCost"></ng-template>
    }
    @if (productData.multiplePrices) {
      <ng-template *ngTemplateOutlet="multiplePrices"></ng-template>
    }
  }
</ng-template>

<!--MULTIPLE PRICES TEMPLATE-->
<ng-template #multiplePrices>
  <div class="d-flex w-100">
    <ng-template *ngTemplateOutlet="runningCost"></ng-template>
    <ng-template *ngTemplateOutlet="fixCost"></ng-template>
  </div>
</ng-template>

<!--SINGLE PRICE TEMPLATE-->
<ng-template #fixCost>
  <div class="d-flex flex-column w-100" [ngStyle]="{ width: productData.multiplePrices ? '50%' : '100%' }">
    <h4 class="mat-body-2 font-weight-bold d-flex mb-0">
      {{
        productData.fixCost?.paymentInterval === costTypeEnum.customOffer
          ? ''
          : (productData.fixCost?.paymentInterval | payInterval)
      }}
    </h4>
    @if (
      productData.fixCost?.netPrice &&
      productData.fixCost?.netPrice !== 0 &&
      productData.fixCost?.paymentInterval !== costTypeEnum.customOffer
    ) {
      <div class="d-flex">
        <div class="d-flex font-weight-bold" markNegativePrice>
          {{ productData.fixCost?.netPrice.toFixed(2) | currency: 'EUR' : '' : '1.2-2' : 'de' }}
          <div class="ml-1">€</div>
        </div>
        @if (!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)) {
          <div class="d-flex mat-small ml-1">1)</div>
        }
        @if (productData.multiplePrices && productData.runningCost?.footnote && productData.fixCost?.footnote) {
          <div class="d-flex mat-small ml-1">2)</div>
        }
      </div>
      <div class="d-flex mat-small">{{ getTaxes(productData.fixCost?.vat) }}</div>
    }
    @if (
      !productData.fixCost?.netPrice ||
      productData.fixCost?.netPrice === 0 ||
      productData.fixCost?.paymentInterval === costTypeEnum.customOffer
    ) {
      <div class="d-flex">
        <div class="d-flex">
          {{ productData.fixCost?.paymentInterval === costTypeEnum.customOffer ? 'Individuelles Angebot' : '0,00' }}
        </div>
        <div class="d-flex ml-1">
          {{ productData.fixCost?.paymentInterval === costTypeEnum.customOffer ? '' : '€' }}
        </div>
        @if (!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)) {
          <div class="d-flex mat-small ml-1">1)</div>
        }
        @if (productData.multiplePrices && productData.runningCost?.footnote && productData.fixCost?.footnote) {
          <div class="d-flex mat-small ml-1">2)</div>
        }
      </div>
      <div class="d-flex mat-small"></div>
    }
  </div>
</ng-template>

<!--RUNNING COST-->
<ng-template #runningCost>
  @if (productData.fixCost?.paymentInterval !== costTypeEnum.customOffer) {
    <div class="d-flex flex-column w-100" [ngStyle]="{ width: productData.multiplePrices ? '50%' : '100%' }">
      <h4 class="mat-body-2 font-weight-bold d-flex mb-0">
        {{ productData.runningCost?.paymentInterval | payInterval }}
      </h4>
      <ng-container>
        <div class="d-flex">
          <div class="d-flex" markNegativePrice>
            {{
              !productData.runningCost
                ? '0,00'
                : (productData.runningCost.netPrice.toFixed(2) | currency: 'EUR' : '' : '1.2-2' : 'de')
            }}
            <div class="ml-1">€</div>
          </div>
          @if (productData.runningCost?.footnote) {
            <div class="d-flex mat-small ml-1">1)</div>
          }
        </div>
        <div class="d-flex mat-small">{{ getTaxes(productData.runningCost?.vat) }}</div>
      </ng-container>
    </div>
  }
</ng-template>

<!-- --- SOLUTION BASKET BUTTON TEMPLATE --- -->
<ng-template #solutionBasketButton>
  @if (!inputValues.hideButton) {
    <div>
      @if (!productData.selected) {
        <ng-template *ngTemplateOutlet="addToBasket"></ng-template>
      }
      @if (productData.selected) {
        <ng-template *ngTemplateOutlet="isInBasket"></ng-template>
      }
    </div>
  }
</ng-template>

<ng-template #loadingButton>
  @if (requestInProgress) {
    <mat-progress-spinner
      color="color.find('--color-primary-500')"
      mode="indeterminate"
      class="m-auto"
      [diameter]="24"
    ></mat-progress-spinner>
  }
</ng-template>

<!-- BASKET BUTTON COMPONENT TEMPLATES -->
<ng-template #addToBasket>
  <ng-container *ngTemplateOutlet="loadingButton"></ng-container>
  @if (!requestInProgress) {
    <button
      [disabled]="(currentQuantity === 0 && productData.quantityVisibility ? true : false) || isLoading"
      mat-flat-button
      color="primary"
      (click)="emitProductQuantity()"
    >
      <kf-icon name="ic_einkaufswagen_24"></kf-icon>
      <span>Hinzufügen</span>
    </button>
  }
</ng-template>

<ng-template #isInBasket>
  <ng-container *ngTemplateOutlet="loadingButton"></ng-container>
  @if (!requestInProgress) {
    <button [disabled]="isLoading" mat-flat-button color="primary" (click)="toggleContextMode()">
      <kf-icon name="ic_loeschen_24"></kf-icon>
      <span>Entfernen</span>
    </button>
  }
</ng-template>

<!--BENEFIT LIST-->
<ng-template #benefitsList>
  <div class="d-flex flex-column flex-grow-1 align-items-start justify-content-start side-container-right">
    <h3 class="font-weight-bold mb-1">Vorteile</h3>
    @if (productData.benefits.length > 0) {
      @for (benefit of productData.benefits; track benefit) {
        <div>
          <article class="d-flex w-100 align-items-center mt-4">
            <div class="align-self-start">
              <kf-icon class="kf-color-primary" [name]="getBenefitIcon(benefit.type)"></kf-icon>
            </div>
            <div class="ml-3">
              <h4 class="mat-body-2 mb-0 font-weight-bold">{{ benefit.name }}</h4>
              <div [innerHTML]="benefit.description | safeHtml"></div>
            </div>
          </article>
        </div>
      }
    }
  </div>
</ng-template>

<!--ANNOTATION LIST TEMPLATE-->
<ng-template #annotationList>
  <div class="d-flex flex-column w-100 py-4 align-items-start justify-content-start mat-small">
    @if (!productData.multiplePrices && productData.fixCost && productData.fixCost.footnote) {
      <div class="d-flex">
        <div class="mr-1">1)</div>
        <div [innerHTML]="productData.fixCost?.footnote | safeHtml"></div>
      </div>
    }
    @if (!productData.multiplePrices && productData.runningCost && productData.runningCost.footnote) {
      <div class="d-flex">
        <div class="mr-1">1)</div>
        <div [innerHTML]="productData.runningCost?.footnote | safeHtml"></div>
      </div>
    }
    @if (
      productData.multiplePrices &&
      productData.fixCost?.paymentInterval !== costTypeEnum.customOffer &&
      productData.runningCost?.footnote
    ) {
      <div class="d-flex">
        <div class="d-flex mat-small mr-1">1)</div>
        <div [innerHTML]="productData.runningCost?.footnote | safeHtml"></div>
      </div>
    }
    @if (
      productData.multiplePrices &&
      productData.fixCost &&
      productData.fixCost?.paymentInterval !== costTypeEnum.customOffer &&
      productData.fixCost?.footnote
    ) {
      <div class="d-flex">
        @if (productData.multiplePrices && productData.runningCost?.footnote) {
          <div class="d-flex mat-small mr-1">2)</div>
        }
        @if (!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)) {
          <div class="d-flex mat-small mr-1">1)</div>
        }
        <div [innerHTML]="productData.fixCost?.footnote | safeHtml"></div>
      </div>
    }
  </div>
</ng-template>

<!-- -------------------------------------------- -->
<!-- ------------- DEFAULT TEMPLATES ------------ -->
<!-- -------------------------------------------- -->
<ng-template #default>
  <div class="d-flex flex-column w-100 side-container">
    <ng-template *ngTemplateOutlet="productHeader; context: { $implicit: image }"></ng-template>
    <div class="d-flex w-100">
      <!--LEFT CONTENT-->
      <div class="d-flex flex-column align-items-baseline justify-content-start mr-5 side-container-left">
        <h3 class="mat-body-1 font-weight-bold mb-1 sr-only">Produkt-Details</h3>
        <div class="mb-3 detail-description" [innerHTML]="productData.description | safeHtml"></div>

        @if (productData.selected) {
          <div class="tag-info tag-positive">
            <div class="tag-info-text">Im Lösungskorb</div>
          </div>
        }

        <!-- QUANTITY SELECTION -->
        <ng-template *ngTemplateOutlet="quantityConfig"></ng-template>

        <!--REVIEW OF COST-->
        <ng-template *ngTemplateOutlet="pricing"></ng-template>

        <!--ANNOTATIONS-->
        <ng-template *ngTemplateOutlet="annotationList"></ng-template>
      </div>

      <!--RIGHT CONTENT-->
      @if (productData.benefits.length > 0) {
        <ng-template *ngTemplateOutlet="benefitsList"></ng-template>
      }
    </div>
  </div>

  @if (!inputValues.hideButton || productData.selected) {
    <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
      <div class="h-100 button-row">
        <div class="button-row-content">
          <!--ADD CUSTOMER DATA-->
          @if (productData.selected && hasDataFieldData) {
            <item-side-datafield-button (click)="onCustomerDataSelected()"></item-side-datafield-button>
          }
          <!--SOLUTION BASKET BUTTON-->
          <ng-template *ngTemplateOutlet="solutionBasketButton"></ng-template>
        </div>
      </div>
    </footer>
  }
</ng-template>

<!-- -------------------------------------------- -->
<!-- ------------ CUSTOMER TEMPLATES ------------ -->
<!-- -------------------------------------------- -->

<ng-template #customer>
  <div class="d-flex flex-column w-100 side-container">
    <item-datafield-form
      #formItem
      [dataFieldData]="dataFieldData"
      [disableFields]="requestInProgress"
      [productData]="productData"
      [dataFieldElementType]="dataFieldElementTypeEnum.products"
      (valueChanged)="handleDataFormValueChange($event)"
      [showHeader]="false"
    ></item-datafield-form>
  </div>
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isLoading || !groupIsValid"
          [attr.data-testcafe]="'sideProductDetails-button-completeData'"
          (click)="onDataCancelled()"
        >
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
