import { Component, Input, NgModule, Output } from '@angular/core';
import { ToolItem } from '@domain/app/question.domain';

@Component({
  selector: 'kbm-tool-container',
  template:
    '<div>Werkzeuge sind in dieser Umgebung nicht verfügbar.<br /> Titel des Tools: {{ toolConfiguration.title }}</div>',
})
export class KbmToolContainer {
  @Input() hasNavigation: boolean = true;
  @Input() overline: string = '';
  @Input() toolConfiguration: ToolItem = {
    title: '',
    jsLocation: '',
    tagName: '',
  };
  @Output() goBack(): void {}
}

@NgModule({
  declarations: [KbmToolContainer],
  imports: [],
  exports: [KbmToolContainer],
})
export class ToolsModule {}
