import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationService } from '@bws/api';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { ConsultationByConsultantItem, CustomerItem } from '@domain/app/consultation.domain';
import { GetInstanceResponse } from '@domain/app/instances.domain';
import { GetHubConsultationResponseItem } from '@domain/hub/consultation.domain';
import { CustomerResponseItem } from '@domain/hub/customer.domain';
import { ConsultationStatusEnum, RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { QueryService } from '@services/query-service/query.service';
import { isValidUUID, isZeroUUID } from '@utils/helpers/uuidUtils';
import { uniqBy } from 'lodash-es';
import moment from 'moment';
import { Observable, Subject, Subscription, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

enum StatusFilterStatus {
  none = '',
  completed = 'COMPLETED',
  open = 'OPEN',
  preparation = 'PREPARATION',
}

const statusPrep: ConsultationStatusEnum[] = [
  ConsultationStatusEnum.created,
  ConsultationStatusEnum.inPreparation,
  ConsultationStatusEnum.preparationDone,
  ConsultationStatusEnum.preparationSent,
];

const statusCompleted: ConsultationStatusEnum[] = [
  ConsultationStatusEnum.archived,
  ConsultationStatusEnum.checkoutDone,
];

const statusMain: ConsultationStatusEnum[] = [ConsultationStatusEnum.mainConsultation];

const emptyString = '';

interface FilteredData extends CustomerItem {
  consultationMatch?: boolean;
  customerMatch?: boolean;
}

interface PlainCustomerItem {
  customerName: string;
  customerNumber: string;
  consultations: GetHubConsultationResponseItem[];
}

@Component({
  selector: 'consultation-overview',
  templateUrl: './consultation-overview.component.html',
  styleUrls: ['./consultation-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ConsultationOverviewComponent implements OnChanges, OnInit, OnDestroy {
  @Input() readonly consultationData: CustomerItem[] = [];
  @Input() readonly isRequesting: boolean = true;

  @ViewChild('searchInput') searchInput: ElementRef;

  public rawData: CustomerItem[] = [];
  public readonly statusFilterStatus = StatusFilterStatus;

  public currentStatusFilter: StatusFilterStatus = StatusFilterStatus.none;
  public filteredData: FilteredData[] = [];
  public filteredDataAll: FilteredData[] = [];
  public expandedMenuSet = new Set<string>();
  public allCustomerData: CustomerResponseItem[] = [];
  public showAllCustomers: boolean = false;
  public isFiltering: boolean = false;
  public confirm = false;
  public dialogResponse: any;

  public searchControl: FormControl<string | null>;
  public searchQuery = emptyString;

  readonly moment = moment;

  private debounceSubject = new Subject<string>();
  private dialogSub: Subscription;
  private destroySubs = new Subject<void>();
  private subscriptions = new Subscription();

  private dialogRef = null;
  private instances: GetInstanceResponse[] = [];

  constructor(
    private router: Router,
    private queryService: QueryService,
    private dialogService: DialogService,
    private contextService: ContextService,
    private clientService: ClientService,
    private readonly navigationService: NavigationService
  ) {}

  async ngOnInit() {
    this.searchControl = new FormControl<string | null>('');

    /// --- SEARCH FUNCTIONALITY --- ///
    this.searchControl = new FormControl<string | null>('');

    const searchSub = this.searchControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(query => {
        this.searchQuery = query.toLowerCase().trim();
        this.triggerFilter();
      });

    this.subscriptions.add(searchSub);
    this.instances = await lastValueFrom(this.queryService.getInstances());
    await this.getCustomerListData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    this.dialogSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consultationData) {
      this.rawData = [...changes.consultationData.currentValue];
      this.filteredData = [...this.rawData];
      this.expandedMenuSet.clear();
    }
  }

  public setStatusFilter(newStatus: StatusFilterStatus) {
    if (this.currentStatusFilter === newStatus) {
      this.currentStatusFilter = StatusFilterStatus.none;
    } else {
      this.currentStatusFilter = newStatus;
    }
    this.triggerFilter();
  }

  public setExpandedMenuSet(id: string, event: KeyboardEvent = null) {
    if (event && event.key !== ' ') {
      return;
    }
    if (!this.expandedMenuSet.has(id)) {
      this.expandedMenuSet.add(id);
    } else {
      this.expandedMenuSet.delete(id);
    }
  }

  public isExpanded(id: string) {
    return this.expandedMenuSet.has(id);
  }

  public triggerDebounce(element: HTMLInputElement) {
    this.debounceSubject.next(element.value);
  }

  public getStatus(status: ConsultationStatusEnum): string {
    if (statusMain.includes(status)) {
      return 'Beratung';
    }
    if (statusPrep.includes(status)) {
      return 'Vorbereitung';
    }
    if (statusCompleted.includes(status)) {
      return 'Abgeschlossen';
    }

    return '';
  }

  public async jumpToConsultation(consultation: ConsultationByConsultantItem): Promise<void> {
    const data = await lastValueFrom(this.queryService.getConsultationById(consultation.id));
    const appointmentId = consultation.appointmentId;

    if (environment.platform === 'aws' || !isValidUUID(appointmentId) || isZeroUUID(appointmentId)) {
      if (
        consultation.status !== ConsultationStatusEnum.archived &&
        consultation.status !== ConsultationStatusEnum.checkoutDone
      ) {
        this.clientService.instanceId = data.instanceId;
        this.clientService.consultationId = consultation.id;
        this.clientService.bankHubId = '';
        if (consultation.appointmentId) {
          this.clientService.appointmentUUID = consultation.appointmentId;
        }
        this.router.navigate([`${RoutingPathMain.Agenda}/${consultation.id}`]);
      } else {
        this.router.navigate([`${RoutingPathMain.ResultSummary}/${consultation.id}`], {
          state: { origin: 'start' },
        });
      }
    } else if (environment.platform === 'vp' && isValidUUID(appointmentId) && !isZeroUUID(appointmentId)) {
      const kbmPath = `kbm-agenda-appointments-overview`;
      const slug = `gespraechdurchfuehrung/${consultation.appointmentId}`;
      const path = `${kbmPath}/${slug}`;
      this.navigationService.navigateByUrl(path);
    }
  }

  public confirmDeletion(consultation) {
    const data = {
      headingText: 'Beratung löschen',
      copyText: `Möchten Sie wirklich die ausgewählte Beratung löschen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this.dialogRef = this.dialogService.openDialogConfirm(data);

    this.dialogSub = this.dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.queryService.deleteConsultation(consultation.id).subscribe(x => {
          this.deleteConsultation(consultation.id);
        });
      }
      this.dialogRef = null;
    });
  }

  public confirmArchiving(consultation) {
    const data = {
      headingText: 'Beratung archivieren',
      copyText: `Möchten Sie wirklich die ausgewählte Beratung archivieren?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this.dialogRef = this.dialogService.openDialogConfirm(data);
    this.dialogSub = this.dialogRef.afterClosed().subscribe(async result => {
      this.archiveElement(consultation);
      this.confirm = false;
      this.dialogRef = null;
    });
  }

  // context menu methods for matMenu
  public closeDialog(response?): void {
    this.dialogRef?.close(response);
  }

  public editAppointment(consultation: ConsultationByConsultantItem): void {
    if (consultation.appointmentId) {
      const kbmPath = `kbm-agenda-appointments-overview`;
      const slug = `gespraechvorbereitung/${consultation.appointmentId}`;
      const path = `${kbmPath}/${slug}`;
      this.navigationService.navigateByUrl(path);
    }
  }

  public deleteElement(consultation): void {
    this.queryService.deleteConsultation(consultation.id).subscribe(x => {
      this.deleteConsultation(consultation.id);
    });
  }

  public archiveElement(consultation): void {
    this.queryService.postArchiveConsultation(consultation.id).subscribe(x => {
      this.archiveConsultation(consultation.id, ConsultationStatusEnum.archived);

      this.confirm = false;
    });
  }

  public appointmentModState(consultation: ConsultationByConsultantItem) {
    return (
      consultation?.status !== ConsultationStatusEnum.archived &&
      consultation?.status !== ConsultationStatusEnum.checkoutDone &&
      environment.platform === 'vp' &&
      consultation?.appointmentId
    );
  }

  public consultationIsCreated(consultation: ConsultationByConsultantItem) {
    return (
      consultation?.status === ConsultationStatusEnum.created ||
      consultation?.status === ConsultationStatusEnum.inPreparation
    );
  }

  public consultationIsPrepped(consultation: ConsultationByConsultantItem) {
    return consultation?.status === ConsultationStatusEnum.preparationSent;
  }

  public consultationIsDone(consultation: ConsultationByConsultantItem) {
    return (
      consultation?.status === ConsultationStatusEnum.archived ||
      consultation?.status === ConsultationStatusEnum.checkoutDone
    );
  }

  public consultationIsNotArchived(consultation: ConsultationByConsultantItem) {
    return (
      consultation?.status === ConsultationStatusEnum.mainConsultation ||
      consultation?.status === ConsultationStatusEnum.preparationSent ||
      consultation?.status === ConsultationStatusEnum.preparationDone ||
      consultation?.status === ConsultationStatusEnum.checkoutDone
    );
  }

  public getInstanceName(data: ConsultationByConsultantItem): string {
    const a = this.filteredDataAll.flatMap(x => x.consultations).find(x => x.id === data.id);
    const b = this.instances.find(x => x.id === a?.instanceId);
    if (!b || !b?.contentStream || b?.contentStream === 'NONE') {
      return '';
    }

    const stream = b?.contentStream ? `${b.contentStream}` : '';
    const version = b?.contentVersion ? ` ${b.contentVersion}` : '';

    return `(${stream}${version})`;
  }

  /**
   * Triggers the load of the prelim
   *
   * "consultation" contains the data we need to pass over to the prelim
   */
  public startPrelim(consultation) {
    const ref = window.open('about:blank', '_blank');
    ref.location.href = environment.preliminaryTarget;
    this.dialogResponse = { action: 'openPrelim', ref, data: consultation };
    this.closeDialog(this.dialogResponse);
  }

  public showConfirm(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.confirm = true;
  }

  public formatDate(date: string) {
    return moment(date).format('DD.MM.yyyy');
  }

  public getTestcafeId(prefix, name = '', id: any) {
    return `consultationOverview-${prefix}-${name?.replace(/ /g, '')}-${id}`;
  }

  public getConsultationSummary(consultationData: GetHubConsultationResponseItem) {
    this.router.navigate([`${RoutingPathMain.ResultSummary}/${consultationData.id}`], {
      state: { origin: 'start' },
    });
  }

  public getCustomerLogo(customerNumber: string) {
    const customerFound = this.allCustomerData.find(customer => customer.customerNumber === customerNumber);
    return customerFound?.logo || null;
  }

  public toggleAllCustomers() {
    this.showAllCustomers = !this.showAllCustomers;
    this.triggerFilter();
  }

  public getUniqueFilteredData(filteredData: FilteredData[]): FilteredData[] {
    return uniqBy(filteredData, x => x.customerNumber).sort((a, b) => (a.customerNumber > b.customerNumber ? 1 : -1));
  }

  private archiveConsultation(searchId: string, newStatus: ConsultationStatusEnum) {
    this.rawData = [
      ...this.consultationData.map(customerItem => ({
        ...customerItem,
        consultations: customerItem.consultations.map(consultation =>
          consultation.id === searchId ? { ...consultation, status: newStatus } : consultation
        ),
      })),
    ];
    this.triggerFilter();
  }

  private deleteConsultation(searchId: string) {
    this.rawData = [
      ...this.consultationData.map(customerItem => ({
        ...customerItem,
        consultations: customerItem.consultations.filter(consultation => consultation.id !== searchId),
      })),
    ];
    this.triggerFilter();
  }

  private triggerFilter(): void {
    if (this.showAllCustomers === false) {
      this.filteredData = [...this.filter(this.rawData)];
    } else {
      this.filteredData = [...this.filter(this.filteredDataAll)];
    }
  }

  private filter(customerItems: CustomerItem[]): FilteredData[] {
    this.isFiltering = true;

    // SearchFilter
    let filteredData = [...customerItems] as FilteredData[];

    // Show all or own customers
    if (this.showAllCustomers === true) {
      filteredData = [...this.filteredDataAll.map(this.customerMatch)];
    } else {
      filteredData = [...filteredData.map(this.customerMatch)];
    }
    this.isFiltering = false;

    // Status
    filteredData = filteredData.map(customerItem => {
      const filteredConsultations = customerItem.consultations.filter(consultation =>
        this.currentStatusFilter !== this.statusFilterStatus.none
          ? this.statusConditions.includes(consultation.status)
          : consultation
      );

      return {
        ...customerItem,
        consultationMatch:
          this.currentStatusFilter !== this.statusFilterStatus.none
            ? filteredConsultations.length > 0
            : customerItem.consultationMatch,
        consultations: filteredConsultations,
      };
    });

    // sort CustomerMatchFirst
    return filteredData.sort(this.sortByCustomer);
  }

  private customerMatch = (customerItem: CustomerItem) => {
    const customerMatch =
      customerItem.customerName.toLowerCase().includes(this.searchQuery) ||
      customerItem.customerNumber.toLowerCase().includes(this.searchQuery);

    const filteredConsultations = customerItem.consultations.filter(this.consultationMatch);
    const consultationMatch = filteredConsultations.length > 0 && this.searchQuery !== '';
    if (customerMatch) {
      return { ...customerItem, consultationMatch, customerMatch: this.searchQuery !== '' ? customerMatch : false };
    } else {
      const filteredConsultations = customerItem.consultations.filter(this.consultationMatch);
      return {
        ...customerItem,
        consultations: filteredConsultations,
        consultationMatch,
        customerMatch,
      };
    }
  };

  private consultationMatch = (consultation: ConsultationByConsultantItem) => {
    return (
      this.formatDate(consultation.appointmentDate).includes(this.searchQuery) ||
      consultation.appointmentTime.includes(this.searchQuery) ||
      consultation.title.toLowerCase().includes(this.searchQuery)
    );
  };

  private get statusConditions(): ConsultationStatusEnum[] {
    if (this.currentStatusFilter === StatusFilterStatus.open) {
      return statusMain;
    }
    if (this.currentStatusFilter === StatusFilterStatus.preparation) {
      return statusPrep;
    }
    return statusCompleted;
  }

  private sortByCustomer = (a: FilteredData, b: FilteredData) => {
    return a.customerMatch === b.customerMatch ? 0 : b.customerMatch ? 1 : -1;
  };

  private async getCustomerListData() {
    if (!this.contextService.isConsulationModuleContext) {
      this.allCustomerData = await lastValueFrom(this.queryService.getAllCustomers());

      this.getAllData.pipe(takeUntil(this.destroySubs)).subscribe(data => {
        if (data === null) {
          return;
        }
        this.filteredDataAll = [...this.filteredDataAll, data] as FilteredData[];
      });
    }
  }

  private getAllData = new Observable(observer => {
    const observation = this.allCustomerData?.forEach(async entry => {
      const result = (await this.getAllCustomerConsultations(entry.id)) as any[];
      result.forEach(entry => (entry.title = entry.name));

      const customerObj: PlainCustomerItem = {
        customerName: entry.corporationName,
        customerNumber: entry.customerNumber,
        consultations: result,
      };
      observer.next(customerObj);
    });

    return {
      unsubscribe() {
        this.allCustomerData.clearWatch(observation);
      },
    };
  });

  private async getAllCustomerConsultations(id) {
    return await lastValueFrom(this.queryService.getConsultationsByCustomerId(id));
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
