import { Injectable } from '@angular/core';
import { TotalPriceInfo } from '@domain/app/price.domain';
import { PaymentInterval, VatEnum } from '@enums';
import { TaxCalculationPipe } from '@utils/pipes/paymentPipe.pipe';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(private taxPipe: TaxCalculationPipe) {}

  private paymentData: TotalPriceInfo[];

  public disclaimerText = `Entspricht der Bruttopreis dem Nettopreis, so handelt es<br />
  sich um umsatzsteuerbefreite Bankdienstleistungen.`;

  public setPaymentData(data: any) {
    this.paymentData = this.setPriceInfo(data);
  }

  public getGrossPrice(price, tax: VatEnum) {
    return this.taxPipe.transform(price, tax);
  }

  public get hasOneTimePayments(): boolean {
    return this.totalOneTimePayments.length > 0;
  }

  public get totalGrossOnce() {
    return this.totalOneTimePayments.reduce((a, b) => {
      const result = Number(this.taxPipe.transform(b.netPrice * b.selectedQuantity, b.vat, false));
      return a + result;
    }, 0);
  }

  public get totalNetOnce() {
    return this.totalOneTimePayments.reduce((a, b) => {
      return a + (b.netPrice * b.selectedQuantity || 0);
    }, 0);
  }

  public get hasMonthlyPayments(): boolean {
    return this.totalMonthlyPayments.length > 0;
  }

  public get totalGrossMonthly() {
    return this.totalMonthlyPayments.reduce((a, b) => {
      const result = Number(this.taxPipe.transform(b.netPrice * b.selectedQuantity, b.vat, false));
      return a + result;
    }, 0);
  }

  public get totalNetMonthly() {
    return this.totalMonthlyPayments.reduce((a, b) => {
      return a + (b.netPrice * b.selectedQuantity || 0);
    }, 0);
  }

  public get hasAnnualPayments(): boolean {
    return this.totalAnnualPayments.length > 0;
  }

  public get totalGrossAnnually() {
    return this.totalAnnualPayments.reduce((a, b) => {
      const result = Number(this.taxPipe.transform(b.netPrice * b.selectedQuantity, b.vat, false));
      return a + result;
    }, 0);
  }

  public get totalNetAnnually() {
    return this.totalAnnualPayments.reduce((a, b) => {
      return a + (b.netPrice * b.selectedQuantity || 0);
    }, 0);
  }

  private setPriceInfo(obj: any, memo: TotalPriceInfo[] = [], parent = null) {
    const proto = Object.prototype;
    const ts = proto.toString;
    const hasOwn = proto.hasOwnProperty.bind(obj);

    if ('[object Array]' !== ts.call(memo)) {
      memo = [];
    }

    for (const i in obj) {
      if (hasOwn(i)) {
        if (i === 'vat') {
          if (parent.selectedQuantity !== undefined) {
            obj.selectedQuantity = parent.selectedQuantity;
          }
          memo.push(obj);
        } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
          this.setPriceInfo(obj[i], memo, obj);
        }
      }
    }

    return memo;
  }

  private get totalOneTimePayments(): TotalPriceInfo[] {
    if (!this.paymentData) {
      return [];
    }
    return (
      this.paymentData.filter(
        x => x.paymentInterval === PaymentInterval.once || x.paymentInterval === PaymentInterval.fixed
      ) || []
    );
  }
  private get totalMonthlyPayments(): TotalPriceInfo[] {
    if (!this.paymentData) {
      return [];
    }
    return this.paymentData.filter(x => x.paymentInterval === PaymentInterval.monthly) || [];
  }
  private get totalAnnualPayments(): TotalPriceInfo[] {
    if (!this.paymentData) {
      return [];
    }
    return this.paymentData.filter(x => x.paymentInterval === PaymentInterval.annually) || [];
  }
}
