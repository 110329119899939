<div
  class="d-flex align-items-center justify-content-between subtopic-deselect-container pl-3"
  cdkDragLockAxis="y"
  cdkDrag
  tabindex="0"
>
  <mat-checkbox
    [attr.data-testcafe]="'deselect-button-' + getTestcafeId(subtopicItem.title) + '-' + subtopicItem.id"
    *ngIf="!loading"
    [checked]="true"
    tabindex="-1"
    [color]="'grey'"
    (click)="onSubtopicDeselected()"
    class="mb-0 force-center-checkbox"
  >
    <bgzv-image
      class="flex-shrink-0 subtopic-icon mr-2"
      [alt]=""
      [src]="getMediaUrl(subtopicItem.icon?.id)"
      aria-hidden="true"
    ></bgzv-image>
    <span>{{ truncateText(subtopicItem.title, 135) }}</span>
  </mat-checkbox>

  @if (loading) {
    <mat-spinner [diameter]="24"></mat-spinner>
  }

  <div *ngIf="!getMediaUrl(subtopicItem.icon?.id)" class="subtopic-icon mr-2" aria-hidden="true"></div>
  <div class="move-handler" cdkDragHandle tabindex="-1">
    <kf-icon class="drag-icon-margin" name="ic_bewegen_24"></kf-icon>
  </div>
</div>
