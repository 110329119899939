import * as uuid from 'uuid';

export function isZeroUUID(uuid: string): boolean {
  const zeroUUIDPattern = /^0{8}-0{4}-0{4}-0{4}-0{12}$/;
  return zeroUUIDPattern.test(uuid);
}

export function isValidUUID(uuid: string): boolean {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidPattern.test(uuid);
}

export function generateUUID(): string {
  return uuid.v4();
}

export function generateZeroUUID(): string {
  return '00000000-0000-0000-0000-000000000000';
}
