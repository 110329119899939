import { ClientService } from '@services/client-service/client.service';
import { IpadPdfViewerService } from '@services/pdf-viewer-service/ipad-pdf-viewer-service';
import { QueryService } from '@services/query-service/query.service';

import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentLookupService } from '@de.fiduciagad.kundenportal/environment-lookup';
import { CheckoutDataFieldGroupItem } from '@domain/app/checkout.domain';
import {
  ConsultationSummaryProductItem,
  ContractStatusContractItem,
  ContractStatusResponseItem,
  SingleContractStatusRequestItem,
} from '@domain/app/consultation.domain';
import { ProductResponse } from '@domain/app/product.domain';
import { ContractStatusEnum, ProductTypesEnum } from '@enums';
import { environment } from '@environment/environment';
import { ConfigService } from '@services/config-service/config.service';
import * as fileSaver from 'file-saver';
import { BehaviorSubject, Subject, lastValueFrom, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContractService implements OnDestroy {
  private stopPolling = new Subject<void>();

  public pollingResult = new BehaviorSubject<ContractStatusResponseItem[]>([]);
  public pollingSummaryResult = new BehaviorSubject<ContractStatusContractItem[]>([]);
  public putRequested = new BehaviorSubject<any>(undefined);
  public pollingActive = false;
  public pollingMap = new Map();

  constructor(
    private clientService: ClientService,
    private queryService: QueryService,
    private environmentLookupService: EnvironmentLookupService,
    private configService: ConfigService,
    private ipadViewerService: IpadPdfViewerService
  ) {}

  ngOnDestroy(): void {
    this.pollingActive = false;
    this.stopPolling?.unsubscribe();
  }

  public generateContractPreview(sendData: SingleContractStatusRequestItem): void {
    this.queryService.postGenerateContractPreview(this.clientService.consultationId, sendData).subscribe();
  }

  public openContractPDF(documentIds: string[], customerId: number): void {
    // get merged contract PDF
    const documentIdsString: string = documentIds.join(',');

    this.queryService.getMergeDocuments(customerId, documentIdsString).subscribe(data => {
      const blob = new Blob([data], {
        type: 'application/pdf',
      });

      const setting = this.configService.getSettingsValue('downloadPDF');

      if (setting && setting === '1') {
        fileSaver.saveAs(blob, `Vertragsvorschauen_${new Date().getTime()}`);
      } else {
        const isIPad = this.environmentLookupService.isInsideNativeShell();

        if (environment.platform === 'vp' && isIPad) {
          this.ipadViewerService.showIpadPdfFromData(blob, 'Vertragsvorschauen');
        } else {
          const ref = window.open('about:blank', '_blank');
          ref!.location.href = window.URL.createObjectURL(blob);
        }
      }
    });
  }

  public startContractStatusPolling(endConditionStatus: ContractStatusEnum = ContractStatusEnum.generatePreview) {
    this.pollingActive = true;
    // start polling after initial 5 seconds
    setTimeout(() => {
      // poll status every 5 seconds
      timer(0, 5000)
        .pipe(
          switchMap(() => this.queryService.getContractStatus(this.clientService.consultationId)),
          takeUntil(this.stopPolling)
        )
        .subscribe(contractsStatus => {
          if (contractsStatus?.every(y => y.contracts?.every(z => z?.status !== endConditionStatus))) {
            this.stopPolling.next();
            this.pollingActive = false;
          }
          this.pollingResult.next(contractsStatus);
        });
    }, 5000);
  }

  public startSummaryContractStatusPolling(
    products: ConsultationSummaryProductItem[],
    consultationId: string,
    endConditionStatus: ContractStatusEnum = ContractStatusEnum.finalized
  ) {
    this.pollingActive = true;
    // poll status every 5 seconds
    setTimeout(() => {
      timer(0, 5000)
        .pipe(
          switchMap(() => this.queryService.getSummaryContractStatus(consultationId)),
          takeUntil(this.stopPolling)
        )
        .subscribe(contractsStatus => {
          if (!!contractsStatus) {
            const filteredContractStatus = contractsStatus.documents.filter(
              x => !!products?.find(y => y.contractDocumentId === x.documentId)
            );

            if (
              filteredContractStatus?.length > 0 &&
              filteredContractStatus?.every(z =>
                [endConditionStatus, ContractStatusEnum.errorFinalized].includes(z?.status)
              )
            ) {
              this.stopPolling.next();
              this.pollingActive = false;
            }
            this.pollingSummaryResult.next(contractsStatus.documents);
          }
        });
    }, 5000);
  }

  public _stopPolling(): void {
    if (this.pollingActive) {
      this.pollingActive = false;
      this.stopPolling.next();
    }
  }

  /**
   * One product (or task) can have multiple forms assigned
   * e.g. a contract form and a document containing legal terms
   * @param product
   * @param contractStatusResponse
   * @returns
   */
  public getContractStatus(product, contractStatusResponse): ContractStatusEnum {
    return this.getLowestContractStatus(contractStatusResponse?.find(x => x.elementId === product.id)?.contracts);
  }

  /**
   *
   * @param contractItems
   * @returns
   */
  public getLowestContractStatus(contractItems: ContractStatusContractItem[]): ContractStatusEnum | undefined {
    const predicate = (a, b) => {
      const map = {};
      map[ContractStatusEnum.none] = 0;
      map[ContractStatusEnum.error] = 1;
      map[ContractStatusEnum.errorFinalized] = 2;
      map[ContractStatusEnum.generatePreview] = 3;
      map[ContractStatusEnum.preview] = 4;
      map[ContractStatusEnum.generateFinalized] = 5;
      map[ContractStatusEnum.finalized] = 6;
      return map[a] < map[b] ? -1 : map[a] > map[b] ? 1 : 0;
    };
    const contracts = contractItems || [];
    return contracts.length > 0 ? contracts.sort(predicate)[0].status : undefined;
  }

  public generateSingleStatusRequestItem(
    product: ProductResponse,
    datafieldData: CheckoutDataFieldGroupItem
  ): SingleContractStatusRequestItem {
    return {
      elementId: product.id,
      elementType: ProductTypesEnum.products,
      compositionId: product.compositionId,
      ordinal: datafieldData.ordinal,
    };
  }

  public async getSingleProductContractData(
    consultationId: string,
    datafieldData: CheckoutDataFieldGroupItem[],
    product: ProductResponse
  ): Promise<ContractStatusResponseItem[]> {
    let singleProductStatus: ContractStatusResponseItem[] = [];

    datafieldData = datafieldData?.filter(x => x.hasContractForm && x.multiplied && x.contractIncluded);
    const contractArray = new Array<SingleContractStatusRequestItem>();

    if (datafieldData) {
      for (const data of datafieldData) {
        const _product = this.generateSingleStatusRequestItem(product, data);
        if (contractArray.findIndex(x => x.ordinal === _product.ordinal) === -1) {
          contractArray.push(_product);
        }
      }
      for (const contract of contractArray) {
        let _contractData: ContractStatusResponseItem;
        await this.getSingleProductInstanceContractData(consultationId, contract).then(data => (_contractData = data));
        singleProductStatus.push(_contractData);
      }
    }

    return singleProductStatus;
  }

  public async getSingleProductInstanceContractData(
    consultationId: string,
    productInstance: SingleContractStatusRequestItem
  ): Promise<ContractStatusResponseItem> {
    return lastValueFrom(this.queryService.getSingleProductStatus(consultationId, productInstance));
  }
}
