import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DialogConfirmComponent, DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import {
  DialogOtherAnswerData,
  DialogOtherAnswersComponent,
} from '@components/dialog-other-answers/dialog-other-answers.component';
import { OtherAnswers } from '@domain/app/answers.domain';
import { QuestionItem } from '@domain/app/question.domain';
import { ConsultationStatusEnum } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  consultationStatusEnum = ConsultationStatusEnum;
  constructor(private dialog: MatDialog) {}

  /**
   * find default config here: src/app/kf-theme_module.ts:10
   * when providing options, do it like this:
   * let dialogOptions: MatDialogConfig = {};
   * and then merge, e.g.: dialogOptions = { ...dialogOptions, data };
   */

  public openDialogOtherAnswers(
    targetElement,
    data: OtherAnswers,
    item: QuestionItem,
    contextMode: string
  ): MatDialogRef<DialogOtherAnswersComponent, any> {
    const dialogData = {
      otherAnswers: data,
      question: item,
      target: targetElement,
      context: contextMode,
    } as DialogOtherAnswerData;

    let dialogOptions = {
      maxWidth: '41rem',
    } as MatDialogConfig;

    dialogOptions = { ...dialogOptions, data: dialogData };

    return this.dialog.open(DialogOtherAnswersComponent, dialogOptions);
  }

  public openDialogConfirm(data: DialogConfirmData): MatDialogRef<DialogConfirmComponent, any> {
    let dialogOptions: MatDialogConfig = {};
    dialogOptions = { ...dialogOptions, data };

    return this.dialog.open(DialogConfirmComponent, dialogOptions);
  }

  public closeDialog() {
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }
}
