<h2 class="mb-3 ml-1 mr-2 flex-grow-1">Lösungen</h2>

<ng-template *ngTemplateOutlet="recommendationSelected"></ng-template>
<ng-template *ngTemplateOutlet="recommendationRecommended"></ng-template>

<!-- ------ Individual Tasks and Transitions ------ -->
<div class="my-4 justify-content-center button-row">
  <div class="button-row-content">
    <button
      [attr.data-testcafe]="'sideConsultation-button-individualTasks'"
      mat-stroked-button
      color="primary"
      (click)="openIndividualTasks()"
    >
      <span>Maßnahme</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>

    <button
      [attr.data-testcafe]="'sideConsultation-button-individualTransition'"
      mat-stroked-button
      color="primary"
      (click)="openIndividualTransitions()"
    >
      <span>Expertengespräch</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>
  </div>
</div>
<!--  Compare Accounts  -->
<ng-container *ngIf="compareAccountsButtonText() !== undefined">
  <div class="further-solutions-button">
    <button
      [attr.data-testcafe]="'screenConsultation-button-compareAccounts'"
      mat-stroked-button
      color="primary"
      (click)="openCompareAccounts()"
    >
      <span>{{ compareAccountsButtonText() }}</span>
      <kf-icon name="ic_pfeile_links_rechts_24"></kf-icon>
    </button>
  </div>
</ng-container>

<div class="further-solutions-button">
  <button *ngIf="!sideOpen" mat-stroked-button color="primary" (click)="openExtra()">
    <span>Weitere Lösungen</span>
  </button>
</div>

<ng-template #recommendationSelected>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Ausgewählt</h3>
  <div *ngIf="isSelectedEmpty" class="w-100 mb-2 empty-container">Keine ausgewählten Lösungen</div>

  <div *ngFor="let recSelected of recDataSelected">
    <div class="recommendation-container" tabindex="0">
      <div class="d-flex">
        <div class="d-flex flex-column">
          <div class="composition-title">{{ recSelected.compositionName }}</div>
          <div class="rec-button">
            <mat-checkbox
              [checked]="true"
              tabindex="-1"
              [color]="'grey'"
              (click)="onRecDeselected(recSelected); $event.preventDefault()"
              ><span class="label-text">{{ recSelected.name }}</span>

              <kf-icon
                *ngIf="
                  recSelected.type === recommendationTypeEnum.variant ||
                  recSelected.type === recommendationTypeEnum.main ||
                  recSelected.type === recommendationTypeEnum.additional
                "
                class="flex-shrink-0 subtopic-icon ml-2"
                aria-hidden="true"
                kfTooltip="Produkt"
                kfTooltipPosition="after"
                kfTooltipShow="hover"
                name="ic_allgemeine_karte_24"
              ></kf-icon>

              <kf-icon
                *ngIf="recSelected.type === recommendationTypeEnum.task"
                class="flex-shrink-0 subtopic-icon ml-2"
                name="ic_person_mit_kreis_24"
                aria-hidden="true"
                kfTooltip="Maßnahme"
                kfTooltipPosition="after"
                kfTooltipShow="hover"
              ></kf-icon>

              <kf-icon
                *ngIf="recSelected.type === recommendationTypeEnum.transition"
                class="flex-shrink-0 subtopic-icon ml-2"
                name="ic_personen_paar_24"
                aria-hidden="true"
                kfTooltip="Expertengespräch"
                kfTooltipPosition="after"
                kfTooltipShow="hover"
              ></kf-icon>
            </mat-checkbox>
          </div>
        </div>
        <div class="rec-badge">
          <div *ngIf="recSelected.recommended" class="tag-info tag-neutral">
            <div class="tag-info-text">Empfohlen</div>
          </div>
        </div>
      </div>
      <button
        class="info-button"
        iconName="ic_infozeichen_mit_kreis_24"
        (click)="showRecommendationDetails(recSelected, true)"
        aria-label="Details anzeigen"
        [kfTooltip]="'Details zu: ' + recSelected.name"
        kfTooltipPosition="after"
        kfTooltipShow="hover"
        mat-icon-button
      >
        <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #recommendationRecommended>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Empfohlen</h3>
  <div *ngIf="isRecommendedEmpty" class="w-100 empty-container">Keine empfohlenen Lösungen</div>
  <div *ngFor="let rec of recDataRecommended">
    <div class="recommendation-container" tabindex="0">
      <div class="d-flex flex-column">
        <div class="composition-title">{{ rec.compositionName }}</div>
        <div class="rec-button">
          <mat-checkbox
            [checked]="false"
            tabindex="-1"
            [color]="'grey'"
            (click)="onRecSelected(rec); $event.preventDefault()"
            ><span class="label-text">{{ rec.name }}</span>

            <kf-icon
              *ngIf="
                rec.type === recommendationTypeEnum.variant ||
                rec.type === recommendationTypeEnum.main ||
                rec.type === recommendationTypeEnum.additional
              "
              class="flex-shrink-0 subtopic-icon ml-2"
              name="ic_allgemeine_karte_24"
              aria-hidden="true"
              kfTooltip="Produkt"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
            ></kf-icon>

            <kf-icon
              *ngIf="rec.type === recommendationTypeEnum.task"
              class="flex-shrink-0 subtopic-icon ml-2"
              name="ic_person_mit_kreis_24"
              aria-hidden="true"
              kfTooltip="Maßnahme"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
            ></kf-icon>

            <kf-icon
              *ngIf="rec.type === recommendationTypeEnum.transition"
              class="flex-shrink-0 subtopic-icon ml-2"
              name="ic_personen_paar_24"
              aria-hidden="true"
              kfTooltip="Expertengespräch"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
            ></kf-icon>
          </mat-checkbox>
        </div>
      </div>
      <button
        class="info-button"
        (click)="showRecommendationDetails(rec, false)"
        aria-label="Details anzeigen"
        [kfTooltip]="'Details zu: ' + rec.name"
        kfTooltipPosition="after"
        kfTooltipShow="hover"
        mat-icon-button
      >
        <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
      </button>
    </div>
  </div>
</ng-template>

<button
  *ngIf="sideOpen"
  [attr.data-testcafe]="'sideRecommendation-button-back'"
  class="kf-popup-close"
  mat-icon-button
  aria-label="Panel schließen"
  (click)="handleClose()"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>
