@if (taskData?.length > 0) {
  <section class="result-tasks border h-100">
    <!-- outer panel -->
    <mat-expansion-panel
      class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      collapsedHeight="*"
      expandedHeight="*"
      [hideToggle]="false"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [class.is--closed]="!panelOpenState"
      #tasksPanel
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="text-nowrap" [attr.data-testcafe]="'screenResult-panel-taskHeader'">
          <strong class="font-weight-bold">Maßnahmen</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Titel Datum, Berater, Individual Note (Falls existiert), DFGs (falls existent) -->
      @for (task of taskData; track task.name; let i = $index) {
        <mat-expansion-panel
          class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
          [expanded]="activeTaskIndex === i"
        >
          <mat-expansion-panel-header class="align-items-center py-3">
            <div class="d-flex flex-column w-100">
              <mat-panel-title
                class="row justify-content-between mt-2"
                [attr.data-testcafe]="'screenResult-panel-task-' + getTestcafeId(task.name)"
              >
                <div class="col-sm-8 order-2 order-sm-1 mb-2">
                  <strong class="font-weight-bold">{{ task.name }}</strong>
                </div>
                <!-- email sent -->
                @if (task.mailSent) {
                  <div class="col-sm-4 d-flex justify-content-sm-end order-1 order-sm-2">
                    <div class="tag-info tag-positive mr-3 mb-2">
                      <div class="tag-info-text">E-Mail versendet</div>
                    </div>
                  </div>
                }
              </mat-panel-title>

              <!-- appointment -->
              <div class="row align-items-center">
                <div class="col-sm-6 mt-3">
                  <div class="d-flex flex-column">
                    <small>Fälligkeit am:</small>
                    @if (task.appointmentDate) {
                      <span class="d-flex align-items-center">
                        <kf-icon name="ic_kalender_termin_24"></kf-icon>
                        <span
                          class="pl-2"
                          [attr.data-testcafe]="'screenResult-panel-taskAppointmentDate-' + getTestcafeId(task.name)"
                          >{{ task.appointmentDate | date: 'dd. MMMM YYYY' }}</span
                        >
                      </span>
                    } @else {
                      <span>Keine Fälligkeit</span>
                    }
                  </div>
                </div>
                <div class="col-sm-6 mt-3">
                  <div class="d-flex flex-column">
                    <small>Verantwortlich:</small>
                    <span class="d-flex align-items-center">
                      <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
                      <span class="pl-2" [attr.data-testcafe]="'screenResult-div-task-costumerContact'"
                        >{{ task.customerContact.formOfAddress }} {{ task.customerContact.firstName }}
                        {{ task.customerContact.lastName }}</span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
          @if (task.individualNote) {
            <div class="row">
              <div class="col-12" [attr.data-testcafe]="'screenResult-div-taskNote'">
                <h5 class="font-weight-bold mb-1">Notizen zur Maßnahme</h5>
                {{ task.individualNote }}
              </div>
            </div>
          }
          @if (task.dataFieldGroups?.length > 0) {
            <div class="row">
              @for (dataFieldGroup of task.dataFieldGroups; track dataFieldGroup.name) {
                <div class="col-12">
                  <p class="font-weight-bold mb-2">{{ dataFieldGroup.name }}</p>
                  <dl class="list">
                    @for (dataField of dataFieldGroup.dataFields; track dataField.name) {
                      <dt class="font-weight-normal mb-0 dataField-term">{{ dataField.name }}</dt>
                      <dd class="pl-2 mb-0 dataField-value">{{ dataField.value || '-' }}</dd>
                    }
                  </dl>
                </div>
              }
            </div>
          }
        </mat-expansion-panel>
      }
    </mat-expansion-panel>
    <!-- / outer panel -->

    @if (!panelOpenState) {
      <ul class="w-100 list-inline mb-0 pr-4 py-2 pl-4">
        <li *ngFor="let task of taskData; let last = last; index as i" class="list-inline-item">
          <div class="d-flex line-height-1">
            <button mat-button color="primary" (click)="openTask(i); $event.preventDefault()">
              <span>{{ task.name }}</span>
            </button>
          </div>
        </li>
      </ul>
    }
  </section>
} @else {
  <section class="result-tasks border h-100">
    <div class="pl-4 pr-3 pt-3">
      <div class="pl-2">
        <strong>Maßnahmen</strong>
        <p>Für diese Beratung wurden keine Maßnahmen beschlossen.</p>
      </div>
    </div>
  </section>
}
