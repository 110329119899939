import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FgiPdfViewerService {
  constructor() {}

  public showPdfFromUrl(document: string, documentName: string): Observable<any> {
    return of(null);
  }
  public showPdfFromBase64(document: string, documentName: string): Observable<any> {
    return of(null);
  }
}
