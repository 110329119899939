@if (dataFieldForm) {
  <form [formGroup]="dataFieldForm" class="mb-4">
    @for (
      group of dataFieldData?.dataFieldGroups;
      track group.name;
      let i = $index;
      let last = $last;
      let first = $first
    ) {
      <ng-container formArrayName="datafields">
        <!-- NON-MULTIPLIED DATA FIELD GROUPS-->
        @if (!group?.multiplied) {
          <div class="font-weight-bold w-100 datafield-divider" [class.datafield-divider]="!last">
            <h3 class="mat-body-1 font-weight-bold text-nowrap mb-0 mr-4">{{ group.name }}</h3>
            <ng-template *ngTemplateOutlet="fields; context: { data: group, i, last }"></ng-template>
          </div>
          <!-- MULTIPLIED DATA FIELD GROUPS WITHOUT CONTRACTS-->
        } @else if (!getHasContractForm(group)) {
          <ng-template *ngTemplateOutlet="panel; context: { data: group, i, last, first }"></ng-template>
        }
      </ng-container>
    }

    <!-- MULTIPLIED DATA FIELD GROUPS WITH CONTRACTS -->
    @for (group of uniqueContractDatafields; track group.name; let i = $index; let first = $first; let last = $last) {
      <ng-container formArrayName="datafields">
        <ng-template
          *ngTemplateOutlet="
            panelContract;
            context: { data: getContract(group.ordinal), ordinal: group.ordinal, i, last, first }
          "
        ></ng-template>
      </ng-container>
    }
  </form>
}

<!-- TEMPLATES -->
<!-- PANEL FOR DFG WITH CONTRACT -->
<ng-template #panelContract let-group="data" let-i="i" let-ordinal="ordinal" let-last="last">
  <div
    #groupPanel
    class="border group-panel"
    [id]="'groupOrdinal_' + ordinal"
    [class.group-panel-with-contracts]="getHasContractForm(group[0]) && getContractIncluded(group[0])"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="panelOpen['groupOrdinal_' + ordinal] = true"
      (closed)="panelOpen['groupOrdinal_' + ordinal] = false"
      [expanded]="group[0]?.ordinal === 1 || panelOpen['groupOrdinal_' + ordinal]"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title class="text-nowrap">{{ 'Vertrag ' + ordinal }}</mat-panel-title>
          <div class="w-100 d-flex mat-panel-items justify-content-between">
            @if (showGroupError(group[0])) {
              <mat-error class="ml-2"><kf-input-error message="Angaben erforderlich"></kf-input-error></mat-error>
            }
          </div>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        @if (getHasContractForm(group[0]) && getContractIncluded(group[0])) {
          <div #contractButton class="d-flex my-0 p-1 justify-content-start contract-buttons">
            <ng-template
              *ngTemplateOutlet="
                contractButtons;
                context: { data: productStatus, productData, group: group[0], index: i }
              "
            ></ng-template>
          </div>
        }
        @for (item of group; track item.name; let j = $index) {
          <div class="expansion-panel-body">
            <!-- CONTRACT CHECKBOX -->
            @if (getHasContractForm(group[0])) {
              <div class="mt-4 contract-toggle">
                <div class="mb-4">
                  @if (j === 0) {
                    <mat-checkbox
                      kfFocus
                      #contractCheckbox
                      [attr.data-testcafe]="'contractFor-' + item.dataFieldGroupId"
                      [checked]="getContractIncluded(item)"
                      (change)="toggleIncludeExcludeContract(contractCheckbox.checked, item, productData)"
                    >
                      Mit Vertrag?
                    </mat-checkbox>
                  }
                </div>
                <h3 class="mat-body-1 font-weight-bold text-nowrap mb-2">{{ item?.name }}</h3>
              </div>
            }

            <ng-template *ngTemplateOutlet="fields; context: { data: item, i: getIndex(item), last }"></ng-template>
          </div>
        }
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- PANEL FOR DFG WITHOUT CONTRACT -->
<ng-template #panel let-group="data" let-i="i" let-last="last">
  <div #groupPanel class="border group-panel" [id]="'groupOrdinal_' + group?.ordinal">
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="panelOpen['groupOrdinal_' + group?.ordinal] = true"
      (closed)="panelOpen['groupOrdinal_' + group?.ordinal] = false"
      [expanded]="group?.ordinal === 1 || panelOpen['groupOrdinal_' + group?.ordinal]"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title
            ><strong class="font-weight-bold text-nowrap">{{ group?.name }}</strong></mat-panel-title
          >
          <div class="w-100 d-flex mat-panel-items justify-content-between">
            @if (showGroupError(group)) {
              <mat-error class="ml-2"><kf-input-error message="Angaben erforderlich"></kf-input-error></mat-error>
            }
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="expansion-panel-body">
        <ng-template *ngTemplateOutlet="fields; context: { data: group, i, last }"></ng-template>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- DATA FIELDS -->
<ng-template #fields let-group="data" let-i="i" let-last="last">
  <div class="wrapper group-wrapper">
    @for (item of group.dataFields; track item.name) {
      <div class="d-flex flex-column w-50 font-weight-normal pr-3 mb-3 group-content">
        <!-- item-dataField for number-input-->
        @if ([dataFieldType.number, dataFieldType.price].includes(item.dataFieldType)) {
          <div class="d-flex">
            <mat-form-field
              [formGroup]="getFormGroup(i)"
              class="notranslate"
              [attr.data-testcafeData]="getTestcafeLabel(group, item)"
              [attr.data-item]="item"
            >
              <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
              <input
                #numberInput
                [formControlName]="item.dataFieldValueId"
                autocomplete="off"
                matInput
                (change)="handleValueChange(numberInput.value, item)"
                type="number"
              />
              @if (fieldHasError(i, item)) {
                <mat-error>
                  <kf-input-error [message]="getFieldErrorMessage(i, item)"></kf-input-error>
                </mat-error>
              }
            </mat-form-field>

            @if (item.description) {
              <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
            }
          </div>
        }

        @switch (item.dataFieldType) {
          <!-- item-dataField for text-input-->
          @case (dataFieldType.text) {
            <div class="d-flex">
              <mat-form-field
                [formGroup]="getFormGroup(i)"
                class="notranslate"
                [attr.data-testcafeData]="getTestcafeLabel(group, item)"
                [attr.data-item]="item"
              >
                <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                <input
                  #textInput
                  [formControlName]="item.dataFieldValueId"
                  autocomplete="off"
                  matInput
                  (change)="handleValueChange(textInput.value, item)"
                  type="text"
                />
                @if (fieldHasError(i, item)) {
                  <mat-error>
                    <kf-input-error [message]="getFieldErrorMessage(i, item)"></kf-input-error>
                  </mat-error>
                }
              </mat-form-field>

              @if (item.description) {
                <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
              }
            </div>
          }

          <!-- item-dataField for textarea -->
          @case (dataFieldType.longText) {
            <div class="d-flex">
              <mat-form-field
                class="notranslate"
                [formGroup]="getFormGroup(i)"
                [attr.testcafeData]="getTestcafeLabel(group, item)"
                [attr.data-item]="item"
              >
                <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                <div class="kf-field-char-counter" aria-hidden="true">{{ textArea.value?.length || 0 }}/4000</div>
                <textarea
                  #textArea
                  ngDefaultControl
                  [formControlName]="item.dataFieldValueId"
                  matInput
                  maxLength="4000"
                  (change)="handleValueChange(textArea.value, item)"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field>

              @if (item.description) {
                <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
              }
            </div>
          }

          <!-- item-dataField dropdown -->
          @case (dataFieldType.dropdown) {
            <div class="d-flex">
              <mat-form-field [formGroup]="getFormGroup(i)" [attr.data-item]="item">
                <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
                <mat-select
                  #select
                  kfSelectMdc
                  [formControlName]="item.dataFieldValueId"
                  [disabled]="disableFields"
                  (selectionChange)="handleSelectionChange($event, item)"
                >
                  @for (option of optionDataFactory(item); track option) {
                    <mat-option [value]="option">{{ option }}</mat-option>
                  }
                </mat-select>
                @if (fieldHasError(i, item)) {
                  <mat-error>
                    <kf-input-error [message]="getFieldErrorMessage(i, item)"></kf-input-error>
                  </mat-error>
                }
              </mat-form-field>

              @if (item.description) {
                <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
              }
            </div>
          }

          <!-- item-datafield checkbox -->
          @case (dataFieldType.checkbox) {
            <div class="dfCheckBox" [formGroup]="getFormGroup(i)" [attr.item]="item">
              <mat-checkbox
                kfFocus
                #dataFieldCheckbox
                ngDefaultControl
                [attr.data-testcafe]="'dataField-checkbox-' + item.name + '-' + item.dataFieldValueId"
                [disabled]="disableFields"
                [formControlName]="item.dataFieldValueId"
                (change)="handleValueChange(dataFieldCheckbox.checked, item)"
              >
                {{ item.name }}
              </mat-checkbox>
              @if (item.description) {
                <p [innerHtml]="item.description"></p>
              }
            </div>
          }

          <!-- item-datafield radio -->
          @case (dataFieldType.radio) {
            <mat-radio-group class="my-radio-group" [formGroup]="getFormGroup(i)" [attr.item]="item">
              {{ item.name }}
              @for (option of optionDataFactory(item); track option) {
                <mat-radio-button
                  #radioButton
                  kfFocus
                  ngDefaultControl
                  [value]="option"
                  [checked]="item.value === option"
                  [formControlName]="item.dataFieldValueId"
                  (change)="handleValueChange(radioButton.value, item)"
                >
                  {{ option }}
                </mat-radio-button>
              }
            </mat-radio-group>
          }

          <!-- item-datafield date -->
          @case (dataFieldType.date) {
            <mat-form-field [formGroup]="getFormGroup(i)">
              <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
              <input
                #dateField
                [formControlName]="item.dataFieldValueId"
                [matDatepicker]="picker"
                [min]="currentDate"
                [value]="toDate(getFormGroup(i).value[item.dataFieldValueId])"
                matInput
                readonly
                tabindex="-1"
                placeholder="dd.mm.yyyy"
                aria-describedby="date-format-hint"
                color="primary"
                (dateChange)="handleValueChange(dateField.value, item)"
              />
              @if (fieldHasError(i, item)) {
                <mat-error>
                  <kf-input-error [message]="getFieldErrorMessage(i, item)"></kf-input-error>
                </mat-error>
              }

              <mat-datepicker-toggle [for]="picker" matSuffix aria-label="Datum wählen">
                <kf-icon name="ic_calendar_24" matDatepickerToggleIcon></kf-icon>
              </mat-datepicker-toggle>
              <mat-datepicker kfDatepickerPositioning #picker></mat-datepicker>
            </mat-form-field>
          }
        }
      </div>
    }
  </div>
  @if (last) {
    @if (showRemoveDataFieldButton) {
      <button
        class="datafield-button"
        (click)="onRemoveDataField(group)"
        mat-flat-button
        color="primary"
        [disabled]="isLoading"
      >
        <kf-icon name="ic_loeschen_24"></kf-icon>
        <span>Datensatz entfernen</span>
      </button>
    }
  }
</ng-template>

<!-- CONTRACT BUTTONS FOR PANEL HEADER -->
<ng-template #contractButtons let-data="data" let-group="group" let-product="productData" let-index="index">
  @switch (getContractPreviewState(product, group)) {
    <!-- contract preview can be generated -->
    @case ('AVAILABLE') {
      <div class="button-row">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="disabled(group)"
          (click)="generateContractPreview(product, group)"
        >
          <kf-icon name="ic_pdf_24"></kf-icon>
          <span>Vertragsvorschau generieren</span>
        </button>
      </div>
    }

    <!-- contract preview being generated -->
    @case ('IN_PROCESS') {
      <div class="d-flex align-items-center button-row">
        <mat-spinner [diameter]="24" [color]="'primary'" class="mr-1"></mat-spinner>
        <p class="font-weight-bold text-nowrap m-0">Vertragsvorschau wird generiert</p>
      </div>
    }
    <!-- contract preview generated  -->
    @case ('FINISHED') {
      <div class="button-row">
        <div class="button-row-content">
          <button
            mat-stroked-button
            color="primary"
            [disabled]="disabled(group, true)"
            (click)="generateContractPreview(product, group)"
          >
            <kf-icon name="ic_aktualisieren_24"></kf-icon>
            <span>Vertragsvorschau aktualisieren</span>
          </button>

          <button
            mat-stroked-button
            color="primary"
            [disabled]="disabled(group, true)"
            (click)="openContractPreviews(product.id, group)"
          >
            <span>Vertragsvorschau öffnen</span>
          </button>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #infoIcon let-description="description">
  <kf-icon
    [kfTooltip]="description"
    alt="Details"
    class="ml-2 mt-2 icon-is-interactive"
    kfTooltipPosition="after"
    kfTooltipShow="click"
    name="ic_infozeichen-mit-kreis_24"
    role="button"
    tabindex="0"
  ></kf-icon>
</ng-template>

<!-- display of error message -->
<ng-template #errorHint let-i="i" let-item="item">
  <mat-error>
    @if (fieldHasError(i, item)) {
      <kf-input-error [message]="getFieldErrorMessage(i, item)"></kf-input-error>
    }
  </mat-error>
</ng-template>
