<form class="compare-accounts-inputs my-5">
  @for (input of inputs.inputs; track input) {
    @if (input.inputType === 'TOGGLE') {
      <div>
        <label class="d-flex align-items-center mt-3" [style.marginBottom]="'2.7rem'">
          <span class="d-inline-block">{{ input.inputName }}</span>
          <kf-icon
            name="ic_infozeichen_mit_kreis_24"
            class="mx-2 hover-tooltip"
            kfTooltipShow="hover"
            kfTooltipPosition="before"
            [kfTooltip]="input.tooltip"
          ></kf-icon>
        </label>
        <mat-slide-toggle
          color="primary"
          [checked]="inputs.persistedInputs && inputs.persistedInputs[input.id] >= 0"
          (change)="setValue(input.id, $event.checked)"
        ></mat-slide-toggle>
      </div>
    }
    @if (input.inputType === 'SLIDER') {
      <answer-slider
        [textInputLabel]="input.inputName"
        [max]="input.maxValue"
        [defaultValue]="getDefaultValue(input)"
        [showTextInput]="true"
        [showLabels]="true"
        [description]="input.tooltip"
        (valueSelected)="setValue(input.id, $event.value)"
      ></answer-slider>
    }
  }
</form>
