<div class="notes-menu px-3 mt-3">
  <div class="d-flex justify-content-between">
    <div class="ml-3 mb-2 font-weight-bold text-truncate order-0">
      @if (allNotes?.notes.length > 0 || allNotes?.notesByPageReference?.length > 0) {
        <span>Allgemeines</span>
      }
    </div>
  </div>
  @if (allNotes) {
    <!-- custom notes -->
    @for (note of allNotes.notes; track note) {
      @if (deletedNote !== note.id) {
        <select-note-button
          #noteButton
          [attr.data-testcafe]="note.name"
          [editMode]="noteButton === selectedEditNote"
          [noteName]="note.name"
          [viewProperties]="getViewProperties(note)"
          [isSelected]="selectedNote === note.id"
          [type]="note.type"
          (click)="selectNote(note.id, true, note.type)"
          [depth]="0"
          [disabled]="loading"
          [class.disabled]="loading"
          [isCustomNote]="true"
          [id]="note.id"
        ></select-note-button>
      }
    }
    <!-- notes by page reference -->
    @for (note of allNotes.notesByPageReference; track note) {
      @if (filterPageReference() === noteContextEnum.topicSelection && deletedNote !== note.noteId) {
        <select-note-button
          [noteName]="topicOverviewNote.pageReference | pageReference"
          [isSelected]="selectedNote === note.noteId && note.noteType === presetNoteType"
          [isTopicSelection]="true"
          [viewProperties]="getViewProperties(note)"
          [type]="note.noteType"
          (click)="selectNote(note.noteId, false, note.noteType)"
          [depth]="0"
          [disabled]="loading"
        ></select-note-button>
      }
      @if (filterPageReference() !== noteContextEnum.topicSelection && deletedNote !== note.noteId) {
        <select-note-button
          [noteName]="note.pageReference | pageReference"
          [isSelected]="selectedNote === note.noteId"
          [type]="note.noteType"
          [viewProperties]="getViewProperties(note)"
          (click)="selectNote(note.noteId, false, note.noteType)"
          [depth]="0"
          [disabled]="loading"
        >
        </select-note-button>
      }
    }
    <!-- notes by topic -->
    <div class="flex-scroll">
      @for (topic of allNotes.notesByTopic; track topic) {
        <div class="mt-3">
          <div class="ml-3 mb-2 font-weight-bold text-truncate">
            {{ topic.topicName }}
          </div>
          @for (note of topic.notes; track note) {
            @if (deletedNote !== note.noteId) {
              <select-note-button
                [viewProperties]="getViewProperties(note)"
                [isSelected]="selectedNote === note.noteId"
                [questionGroupName]="note.questionGroupName"
                [questionGroupId]="note.questionGroupId"
                [type]="note.noteType"
                (click)="selectNote(note.noteId, false, note.noteType)"
                [depth]="1"
                [disabled]="loading"
              >
              </select-note-button>
            }
          }
        </div>
      }
    </div>
  }
</div>
