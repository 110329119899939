import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { Profile } from '@domain/app/profile';
import { DataFieldGroup, DataFieldGroups, QuestionDataFieldGroup } from '@domain/app/question.domain';
import { DataFieldTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { NumToStrPipe } from '@utils/pipes/numberPipe.pipe';
import moment from 'moment';
import { Subject, interval, lastValueFrom } from 'rxjs';
import { mergeMap, startWith, take, takeUntil } from 'rxjs/operators';

import ProfileUpdateInProgress = Profile.ProfileUpdateInProgress;
import GetProfileResponse = Profile.GetProfileResponse;
import ProfileAnswerDTO = Profile.ProfileAnswerDTO;
import ProfileGroupDTO = Profile.ProfileGroupDTO;
interface InputValues {
  isQuestionDataField?: boolean;
  isQuestionField?: boolean;
  itemId?: string;
}
@Component({
  selector: 'side-profile',
  templateUrl: './side-profile.component.html',
  styleUrls: ['./side-profile.component.scss'],
  standalone: false,
})
export class SideProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() inputValues: InputValues | undefined;

  @ViewChild('profileEditor') profileEditor;
  @ViewChildren('profileGroupPanel') profileGroupPanels;

  public customerLogo = `${this.assetPath}/images/icon-factory.svg`;
  public editModeActive = false;
  public lastUpdated: string;
  public updateInProgress = false;
  public useQuestionDataFields = false;
  public panelOpen: Array<boolean> = [];
  public questionDataFieldGroups: QuestionDataFieldGroup[] = [];
  public userProfile: GetProfileResponse | undefined;

  private destroySubs = new Subject<void>();
  private ival = null;

  constructor(
    private numToString: NumToStrPipe,
    private rightSidenavService: RightSidenavService,
    private queryService: QueryService,
    private contextService: ContextService,
    private clientService: ClientService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  ngOnInit(): void {
    this.useQuestionDataFields = this.inputValues?.isQuestionDataField || false;

    if (this.useQuestionDataFields) {
      const itemId = this.inputValues?.itemId;
      this.queryService
        .getQuestionDataFields(this.clientService.consultationId, itemId)
        .subscribe((data: DataFieldGroups) => {
          this.questionDataFieldGroups = data.questionDataFieldGroups;
        });
    } else {
      this.rightSidenavService.instance.openedStart
        .pipe(
          takeUntil(this.destroySubs),
          mergeMap(() => this.queryService.getProfile(this.clientService.consultationId, this.showConfidentialGroups()))
        )
        .subscribe(data => {
          this.assignUserProfile(data);

          // if there is no data, it should still render a Stammdaten tab for the vorsteuerabzugsberechtigt box
          this.setProfileDefaultFields();
          this.checkUpdateStatus();
        });
    }

    this.rightSidenavService.instance.closedStart
      .pipe(takeUntil(this.destroySubs))
      .subscribe(() => (this.editModeActive = false));
  }

  ngAfterViewInit(): void {
    if (this.profileGroupPanels) {
      this.setPanelOpen('profileGroup0');
    }
  }

  ngOnDestroy(): void {
    this.ival?.unsubscribe();
    this.ival = null;
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onSave() {
    this.profileEditor.saveData();
  }

  public onClose() {
    this.rightSidenavService.close().then();
  }

  public onCloseEditor(data?: GetProfileResponse) {
    if (data) {
      this.assignUserProfile(data);
      this.setProfileDefaultFields();
    }
    this.editModeActive = false;
  }

  public getBooleanText(value: boolean): string {
    return value ? 'Ja' : 'Nein';
  }

  public displayableDataFieldGroups(profileGroup: ProfileGroupDTO): DataFieldGroup[] {
    return profileGroup.dataFieldGroups.filter(group => group);
  }

  public startUpdate() {
    this.updateProfileData();
    if (!this.updateInProgress) {
      this.updateInProgress = true;
      this.snackBarService.openSnackBar({ type: SnackbarType.SUCCESS, message: 'Datenaktualisierung gestartet.' });

      this.checkUpdateStatus();
    } else {
      this.snackBarService.openSnackBar({ type: SnackbarType.ALERT, message: 'Datenaktualisierung läuft gerade...' });
    }
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public scrollIntoView(id: string): void {
    const elem = document.querySelector(id);

    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  public setPanelOpen(id) {
    if (!this.isPanelOpen[id]) {
      this.panelOpen[id] = true;
      this.scrollIntoView(`#${id}`);
    }
  }

  public isPanelOpen(id: string): boolean {
    return this.panelOpen[id];
  }

  public hasVisibleFields(dataFieldGroup: DataFieldGroup): boolean {
    const fields = dataFieldGroup.dataFieldSections.flatMap(x => x.dataFields);
    return !!fields?.find(x => x.type !== DataFieldTypeEnum.hidden);
  }

  public getLength(dataFieldGroup: DataFieldGroup): number {
    const { dataFieldSections } = dataFieldGroup;
    if (dataFieldGroup.template === 'PLAIN') {
      return dataFieldSections.length;
    }
    return dataFieldSections.reduce((count, current) => count + current.dataFields.length, 0);
  }

  /**
   * When the answer is a number, it will be formatted
   * to a string with a comma as decimal separators
   * @param answer - The answer to be formatted
   * @returns formatted answer as a string
   */
  public formatAnswer(answer: string): string {
    const answerNum = Number(answer);
    if (!isNaN(answerNum)) {
      const decimals = answer.split('.')[1]?.length || 0;
      answer = this.numToString.transform(answerNum, decimals);
    }
    return answer;
  }

  private checkUpdateStatus() {
    this.ival = interval(20000)
      .pipe(startWith(0), take(3))
      .subscribe(async () => {
        const result: ProfileUpdateInProgress = await lastValueFrom(
          this.queryService.getProfileUpdateInProgress(this.clientService.consultationId)
        );
        this.updateInProgress = result.updateInProgress;
        if (!this.clientService.isTestConsultation) {
          this.lastUpdated = moment(result.lastUpdated).format('DD.MM.YYYY HH:mm');
        }
        if (this.updateInProgress === false) {
          this.refetchUserProfile();
          this.ival?.unsubscribe();
        }
      });
  }

  private updateProfileData() {
    this.queryService.putUpdateProfileData(this.clientService.consultationId).subscribe();
  }

  private showConfidentialGroups(): boolean {
    return !(this.clientService.consultationStatusType === 'main');
  }

  private refetchUserProfile(): void {
    this.queryService.getProfile(this.clientService.consultationId, this.showConfidentialGroups()).subscribe(data => {
      this.assignUserProfile(data);
      this.setProfileDefaultFields();
    });
  }

  private setProfileDefaultFields(): void {
    if (this.userProfile?.profileGroups) {
      if (this.userProfile.profileGroups.every(x => x.category !== 'GENERIC')) {
        this.userProfile.profileGroups.unshift({
          category: 'GENERIC',
          dataFieldGroups: [],
        } as ProfileGroupDTO);
      }
    }
  }

  private addHttpPrefix(url: string): string {
    if (!!url && !/^http[s]?:\/\//.test(url)) {
      return `http://${url}`;
    }
    return url;
  }

  private assignUserProfile(data: GetProfileResponse): void {
    const website = this.addHttpPrefix(data.website);
    const source = { website } as Pick<GetProfileResponse, 'website'>;
    this.userProfile = Object.assign(data, source);
    this.clientService.hasVatDiscount = this.userProfile.vatDiscount;
  }

  // May be used again

  get hasEditableFields(): boolean {
    if (!this.userProfile.profileGroups) {
      return false;
    }
    return (
      this.userProfile.profileGroups?.filter(profileData =>
        profileData.dataFieldGroups?.find(group =>
          group.dataFieldSections.find(section => section.dataFields?.find(dataField => dataField.editable))
        )
      ).length > 0
    );
  }

  /**
   * Only answers which have a label will be displayed in the profile
   * as it is explained in the configurator for creating the label
   */
  get answers(): ProfileAnswerDTO[] {
    return this.userProfile?.answers.filter(answer => !!answer.label) || [];
  }

  get isTestConsultation(): boolean {
    return this.clientService.isTestConsultation;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get isQuestionField() {
    return this.inputValues?.isQuestionField || false;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get numAnswers(): number {
    return this.answers?.length || 0;
  }
}
