import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContextService } from '@services/context-service/context.service';
import { Subscription, timer } from 'rxjs';

@Injectable()
export class PwaService implements OnDestroy {
  private constextSub: Subscription;
  private intervalSub: Subscription;
  public updateAvailable = false;
  public checked = false;

  private manualCheck = false;

  constructor(
    private contextService: ContextService,
    /*private swUpdate: SwUpdate,*/ private snackBar: MatSnackBar
  ) {
    // Initialize the App, and ask once every hour for an update.
    const intervalRef = timer(0, 60 * 60 * 1000);

    // if (environment.production) {
    //   this.constextSub = this.contextService.context.subscribe(url => {
    //     if (this.contextService.currentMainContext === RoutingPathMain.ClientList) {
    //       this.intervalSub = intervalRef.subscribe(() => {
    //         if (environment.enableDebug) {
    //           console.log(`%c [bgzv-frontend-main] - PWA - update check!`, 'color: #0066cc');
    //         }
    //         this.manualCheck = false;
    //         swUpdate.checkForUpdate();
    //       });
    //     } else {
    //       this.intervalSub?.unsubscribe();
    //     }
    //   });
    // }

    // swUpdate.versionUpdates
    //   .pipe(
    //     filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    //     map(evt => ({
    //       type: 'UPDATE_AVAILABLE',
    //       current: evt.currentVersion,
    //       available: evt.latestVersion,
    //     }))
    //   )
    //   .subscribe(event => {
    //     console.log(`%c [bgzv-frontend-main] - PWA - update check!`, 'color: #0066cc', event);
    //     this.updateAvailable = event.current.hash !== event.available.hash;

    //     if (event.current.hash !== event.available.hash) {
    //       this.snackBar
    //         .open('Ein Update ist verfügbar.', 'Jetzt aktualisieren')
    //         .onAction()
    //         .subscribe(() => this.handleUpdate());
    //     }
    //   });
  }

  ngOnDestroy(): void {
    this.constextSub?.unsubscribe();
    this.intervalSub?.unsubscribe();
  }

  checkUpdate() {
    // if (environment.production) {
    //   this.swUpdate.checkForUpdate().then(x => (this.manualCheck = true));
    // }
  }

  // private async handleUpdate() {
  //   if (environment.production) {
  //     const activated = await this.swUpdate.activateUpdate();
  //     if (activated) {
  //       console.log(`%c [bgzv-frontend-main] - PWA - update activated!`, 'color: #0066cc');
  //       window.location.reload();
  //     }
  //   }
  // }

  resetCheckedStatus() {
    this.checked = false;
  }
}
