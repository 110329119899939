<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="mx-auto my-5 col-6">
        <!-- !503 -->
        <kf-tile [interactive]="false" *ngIf="!error503Page">
          <div class="p-3 d-flex flex-column align-items-center justify-content-center error-box">
            <kf-icon class="error-icon" name="ic_warnschild_96"></kf-icon>
            <h2>Die aufgerufene Seite konnte nicht geladen werden.</h2>
            <div class="mx-4" *ngIf="errorReason">
              {{ errorReason }}
            </div>
          </div>
          <div class="p-3 d-flex flex-column align-items-center justify-content-center">
            <ng-container *ngIf="isVp && hasTerminButton">
              <button class="kf-btn-flexible" mat-flat-button color="primary" (click)="onBackToAppointment()">
                <span>{{ appointmentLabel }}</span>
                <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!isVp">
              <button
                class="kf-btn-flexible"
                [attr.data-testcafe]="'footer-button-next'"
                mat-stroked-button
                color="primary"
                (click)="onSelect()"
              >
                <span>Zur Beratungsübersicht</span>
                <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
              </button>
            </ng-container>
          </div>
        </kf-tile>

        <!-- 503 -->
        <kf-tile [interactive]="false" *ngIf="error503Page">
          <div class="p-3 d-flex flex-column align-items-center justify-content-center error-box">
            <kf-icon class="error-icon" name="ic_warnschild_96"></kf-icon>
            <h2>Es ist ein Fehler aufgetreten.</h2>
            <div class="mx-4">
              Es ist ein Netzwerkfehler aufgetreten. <br />
              Der BankingGuide wird aktuell gewartet.
            </div>
          </div>
          <div class="p-3 d-flex flex-column align-items-center justify-content-center">
            <ng-container *ngIf="isVp">
              <button mat-stroked-button color="primary" (click)="onBackToAppointment()">
                <span>{{ appointmentLabel }}</span>
                <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!isVp">
              <button mat-stroked-button color="primary" (click)="onSelect()">
                <span>Zur Kundenliste</span>
                <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
              </button>
            </ng-container>
          </div>
        </kf-tile>
      </div>
    </div>
  </div>
</div>
