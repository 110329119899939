<aside class="d-flex flex-column app-height align-items-center kf-bg overflow-hidden task-container-width">
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100">
    <header class="d-flex w-100 justify-content-between side-header">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="d-flex align-items-center">
          @if (showBackButton) {
            <button
              [attr.data-testcafe]="'sideTask-button-back'"
              (click)="handleBack()"
              class="mr-2"
              aria-label="Ansicht schließen"
              kfTooltip="Ansicht schließen"
              kfTooltipPosition="before"
              kfTooltipShow="hover"
              mat-icon-button
            >
              <kf-icon name="ic_chevron_links_24"></kf-icon>
            </button>
          }

          <div class="d-flex flex-column">
            <h2
              class="text-truncate mb-0 side-heading"
              [kfTooltip]="taskData?.name.length > 50 ? taskData.name : null"
              kfTooltipPosition="below"
              kfTooltipShow="hover"
            >
              {{ taskData?.name }}
            </h2>
            @if (state === taskState.contact) {
              <p class="text-truncate mb-0">Neuer Ansprechpartner</p>
            }
            @if (state === taskState.datepick) {
              <p class="text-truncate mb-0">Fälligkeit wählen</p>
            }
          </div>
        </div>
      </div>
    </header>

    @if (taskData) {
      @if (!selectionMode) {
        @if (state === taskState.default) {
          <ng-template *ngTemplateOutlet="default"></ng-template>
        }
        @if (state === taskState.contact) {
          <ng-template *ngTemplateOutlet="contact"></ng-template>
        }
        @if (state === taskState.datepick) {
          <ng-template *ngTemplateOutlet="datepick"></ng-template>
        }
        @if (state === taskState.customer) {
          <ng-template *ngTemplateOutlet="customer"></ng-template>
        }
      } @else {
        <div class="d-flex flex-column w-100 side-container">
          <div class="d-flex w-100">
            <div class="d-flex flex-column" [class.side-container-left]="taskData && taskData.benefits.length > 0">
              <div class="mt-4 mr-5 detail-description" [innerHTML]="taskData.description | safeHtml"></div>
              @if (mainData && mainData.id) {
                <ng-container *ngTemplateOutlet="badge; context: badgeData"></ng-container>
              }
              @if (selected) {
                <div class="tag-info tag-positive">
                  <div class="tag-info-text">Im Lösungskorb</div>
                </div>
              }
              @if (selected && taskData.customerContact) {
                <div class="tag-info tag-neutral mt-3">
                  <div class="tag-info-text">
                    Verantwortlich: {{ taskData.customerContact.formOfAddress }}
                    {{ taskData.customerContact.firstName }}
                    {{ taskData.customerContact.lastName }}
                  </div>
                </div>
              }
            </div>
            @if (taskData.benefits.length > 0) {
              <ng-template *ngTemplateOutlet="benefitsList"></ng-template>
            }
          </div>
        </div>
        <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
          <div class="h-100 button-row-inline">
            <div class="button-row-content">
              @if (selected) {
                <ng-template *ngTemplateOutlet="isInBasket"></ng-template>
              }
              @if (selected && hasDataFieldData) {
                <item-side-datafield-button (click)="onCustomerDataSelected(true)"></item-side-datafield-button>
              }
              @if (!selected) {
                <ng-template *ngTemplateOutlet="addToBasket"></ng-template>
              }
              @if (selected && taskData.customerContact) {
                <button
                  [attr.data-testcafe]="'sideTask-button-assigned'"
                  mat-flat-button
                  color="primary"
                  (click)="toggleSelectionMode()"
                >
                  <kf-icon name="ic_personen_paar_gepunktet_24"></kf-icon>
                  <span>Zuweisung ändern</span>
                </button>
              }
              @if (selected && !taskData.customerContact) {
                <button
                  [attr.data-testcafe]="'sideTask-button-assigneTask'"
                  mat-flat-button
                  color="primary"
                  (click)="toggleSelectionMode()"
                >
                  <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
                  <span>Zuweisen</span>
                </button>
              }
            </div>
          </div>
        </footer>
      }
    } @else {
      <div class="loading">
        <mat-progress-spinner
          [color]="'primary'"
          mode="indeterminate"
          class="m-auto"
          [diameter]="90"
        ></mat-progress-spinner>
      </div>
    }
  </div>

  @if (showCloseButton) {
    <button
      aria-label="Panel schließen"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
      [attr.data-testcafe]="'sideTask-button-close'"
      class="kf-popup-close"
      mat-icon-button
      (click)="handleClose()"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>
  }
</aside>

<ng-template #addToBasket>
  <button [attr.data-testcafe]="'sideTask-button-addToCart'" mat-flat-button color="primary" (click)="selectTask()">
    <kf-icon name="ic_einkaufswagen_24"></kf-icon>
    <span>Hinzufügen</span>
  </button>
</ng-template>

<ng-template #isInBasket>
  <button
    [attr.data-testcafe]="'sideTask-button-assigned'"
    mat-stroked-button
    color="primary"
    (click)="toggleContextMode()"
  >
    <kf-icon name="ic_loeschen_24"></kf-icon>
    <span>Entfernen</span>
  </button>
</ng-template>

<!-- ---------------------------------------------- -->
<!-- -------------- DEFAULT TEMPLATE -------------- -->
<!-- ---------------------------------------------- -->
<ng-template #default>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="taskForm" class="w-100 d-flex flex-column">
        @if (mainData && mainData.id) {
          <ng-template *ngTemplateOutlet="badge; context: badgeData"></ng-template>
        }

        <!-- ------ CONTACT SELECTION ------ -->
        <div class="d-flex align-items-center mb-3">
          @if (contactsList.length === 0) {
            <button
              [attr.data-testcafe]="'sideTask-button-addConsultant'"
              mat-stroked-button
              color="primary"
              (click)="state = taskState.contact"
            >
              <kf-icon name="ic_pluszeichen_24"></kf-icon>
              <span>Ansprechpartner</span>
            </button>
          } @else if (contactsList.length > 0) {
            <div class="d-flex flex-column w-100">
              @if (taskSendObj.customerContact) {
                <mat-form-field>
                  <mat-label>Ansprechpartner</mat-label>
                  <mat-select
                    #select
                    ngDefaultControl
                    kfSelectMdc
                    (selectionChange)="onContactSelected($event)"
                    placeholder="Ansprechpartner auswählen"
                    [formControl]="taskFormControls.customerContact"
                  >
                    @for (option of contactsList; track option) {
                      <mat-option [value]="option.value">{{ option.label }}</mat-option>
                    }
                  </mat-select>
                  <mat-error>
                    @if (taskFormControls.customerContact.invalid) {
                      <kf-input-error
                        [message]="getFieldErrorMessage(taskFormControls.customerContact, 'customerContact')"
                      >
                      </kf-input-error>
                    }
                  </mat-error>
                </mat-form-field>
              }
              <div class="d-flex justify-content-end">
                <button
                  [attr.data-testcafe]="'sideTask-button-addConsultant'"
                  mat-stroked-button
                  color="primary"
                  (click)="state = taskState.contact"
                >
                  <kf-icon name="ic_pluszeichen_24"></kf-icon>
                  <span>Ansprechpartner</span>
                </button>
              </div>
            </div>
          }
        </div>

        <!-- ------ COMMENT SELECTION ------ -->
        <mat-form-field class="notranslate" [formGroup]="taskForm">
          <mat-label>Notiz</mat-label>
          <div class="kf-field-char-counter" aria-hidden="true">{{ taskSendObj.individualNote?.length || 0 }}/4000</div>
          <textarea
            #textArea
            ngDefaultControl
            formControlName="individualNote"
            matInput
            maxLength="4000"
            cdkTextareaAutosize
            [ngModel]="taskSendObj.individualNote"
            (ngModelChange)="taskSendObj.individualNote = $event ? $event : null"
            (change)="onCommentAdded(textArea.value)"
          ></textarea>
        </mat-form-field>

        <div class="d-flex flex-column justify-content-between">
          <div>
            <!-- ------ APPOINTMENT SELECTION ------ -->
            <div class="d-flex align-items-center">
              <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
              <div class="font-weight-bold">Fälligkeitsdatum</div>
            </div>
            @if (!!appointmentDate) {
              <ng-template *ngTemplateOutlet="appointmentDateTemplate"></ng-template>
            } @else if (!appointmentDate || appointmentDate === null) {
              <div class="mt-2 w-100 d-flex justify-content-end">
                <button
                  [attr.data-testcafe]="'sideTask-button-appointment'"
                  mat-stroked-button
                  color="primary"
                  (click)="onDatepickSelected()"
                >
                  <kf-icon name="ic_pluszeichen_24"></kf-icon>
                  <span>Fälligkeit festlegen</span>
                </button>
              </div>
            }
          </div>
        </div>

        <!-- ------ EMAIL SELECTION ------ -->
        <div class="d-flex font-weight-bold mt-3 mb-5">
          <mat-checkbox
            kfFocus
            #mailCheckbox
            [checked]="notifyMailStatus"
            [attr.data-testcafe]="'sendMail'"
            (change)="onEmailNotificationSelected(mailCheckbox.checked)"
            >Hinweis per E-Mail versenden
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <!-- ------ FINAL SELECTION ------ -->
  <footer
    class="w-100 border-top align-items-center side-footer"
    [class.side-footer-margin]="useMargin"
    [class.has-error]="hasError"
  >
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          mat-stroked-button
          color="primary"
          [attr.data-testcafe]="testcafeButtonBack"
          (click)="inputValues['useBackToList'] ? handleBack() : handleClose()"
        >
          <span>Abbrechen</span>
        </button>

        <!-- ------ DATAFIELDS SELECTION ------ -->
        <div class="button-error-group">
          @if (hasDataFieldData) {
            <item-side-datafield-button (click)="onCustomerDataSelected(true)"></item-side-datafield-button>
          }

          <ng-template *ngTemplateOutlet="errorHint"></ng-template>
        </div>

        <button
          mat-flat-button
          color="primary"
          [disabled]="buttonDisabled"
          [attr.data-testcafe]="testcafeButtonTask"
          (click)="onFinalizeTask(true)"
        >
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- --------------------------------------------- -->
<!-- ------------- DATEPICK TEMPLATE ------------- -->
<!-- --------------------------------------------- -->
<ng-template #datepick>
  <div class="d-flex flex-column side-container">
    <div class="d-flex flex-column h-100 flex-center">
      <div class="appointment-details border">
        <mat-calendar
          [selected]="appointmentDate"
          [minDate]="minimumDate"
          (selectedChange)="onDateSelect($event)"
          color="primary"
        ></mat-calendar>
        <ng-template #timeCancelButton>
          <button class="time-button" [attr.data-testcafe]="'sideTask-button-cancel'">Abbrechen</button>
        </ng-template>
        <ng-template #timeConfirmButton>
          <button class="time-button" [attr.data-testcafe]="'sideTask-button-confirm'">OK</button>
        </ng-template>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTask-button-abortAppointment'"
          mat-stroked-button
          color="primary"
          (click)="onAbortAppointment()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTask-button-suggestAppointment'"
          mat-flat-button
          color="primary"
          [disabled]="!this.appointmentDate"
          (click)="onAppointmentConfirmed()"
        >
          <span>Fälligkeit festlegen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- ------ NEW CONTACT ------ -->
<ng-template #contact>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="contactForm" class="w-100 d-flex flex-column">
        <mat-form-field class="notranslate" class="w-100">
          <mat-label>Anrede</mat-label>
          <mat-select
            #select
            ngDefaultControl
            kfSelectMdc
            placeholder="Anrede auswählen"
            [formControl]="contactFormControls.formOfAddress"
          >
            @for (option of contactsFormOfAddress; track option) {
              <mat-option [value]="option.value"> {{ option.label }}</mat-option>
            }
          </mat-select>
          <mat-error>
            @if (contactFormControls.formOfAddress.invalid) {
              <kf-input-error [message]="getFieldErrorMessage(contactFormControls.formOfAddress, 'formOfAddress')">
              </kf-input-error>
            }
          </mat-error>
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <div class="w-50 mr-3">
            <mat-form-field class="notranslate">
              <mat-label>Vorname</mat-label>
              <input
                matInput
                ngDefaultControl
                type="text"
                autocomplete="off"
                [formControl]="contactFormControls.firstName"
              />
              <mat-error>
                @if (contactFormControls.firstName.invalid) {
                  <kf-input-error [message]="getFieldErrorMessage(contactFormControls.firstName, 'firstName')">
                  </kf-input-error>
                }
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-50 ml-3">
            <mat-form-field class="notranslate">
              <mat-label>Nachname</mat-label>
              <input
                matInput
                ngDefaultControl
                type="text"
                autocomplete="off"
                [formControl]="contactFormControls.lastName"
              />
              <mat-error>
                @if (contactFormControls.lastName.invalid) {
                  <kf-input-error [message]="getFieldErrorMessage(contactFormControls.lastName, 'lastName')">
                  </kf-input-error>
                }
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-form-field class="notranslate">
            <mat-label>E-Mail-Adresse</mat-label>
            <input
              ngDefaultControl
              matInput
              type="email"
              autocomplete="off"
              placeholder="Gültige E-Mail-Adresse wie z.B. name.vorname@domain.de"
              [formControl]="contactFormControls.email"
            />
            <mat-hint> Die E-Mail-Adresse wird z.B. für den Terminhinweis genutzt. </mat-hint>
            <mat-error>
              @if (contactFormControls.email.invalid) {
                <kf-input-error [message]="getFieldErrorMessage(contactFormControls.email, 'email')"> </kf-input-error>
              }
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTask-button-abort'"
          mat-stroked-button
          color="primary"
          (click)="onContactCancelled()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTask-button-addContact'"
          [disabled]="!contactForm.valid"
          mat-flat-button
          color="primary"
          (click)="onContactAdded()"
        >
          <span>Ansprechpartner hinzufügen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- ------ BADGE TEMPLATE ------ -->
<ng-template #badge let-text="label" let-type="type" let-id="id">
  <div>
    @if (!!mainData?.id) {
      <button
        class="my-4"
        mat-button
        color="primary"
        (click)="onBadgeClicked(type, id, $event)"
        [attr.aria-label]="text === '' ? 'Details anzeigen' : null"
      >
        <span>{{ text }}</span>
        <kf-icon name="ic_weiterleiten_24"></kf-icon>
      </button>
    }
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner
      [color]="'primary'"
      mode="indeterminate"
      class="m-auto"
      [diameter]="90"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #customer>
  <div class="d-flex flex-column side-container">
    <item-datafield-form
      #formItem
      [dataFieldData]="dataFieldData"
      [disableFields]="requestInProgress"
      [dataFieldElementType]="dataFieldElementTypeEnum.tasks"
      (valueChanged)="handleDataFormValueChange($event)"
      (dataFieldAdded)="handleAddedDataField($event)"
      (dataFieldRemoved)="handleRemovedDataField($event)"
      [showHeader]="false"
      [hasError]="inputValues.hasError"
    ></item-datafield-form>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <button
        mat-flat-button
        color="primary"
        [attr.data-testcafe]="'sideTask-button-completeData'"
        (click)="onDataCancelled()"
      >
        <span>Speichern</span>
      </button>
    </div>
  </footer>
</ng-template>

<ng-template #benefitsList>
  <div
    class="d-flex flex-column flex-grow-1 align-items-start justify-content-start flex-scroll ml-5 side-container-right"
    style="max-width: 28%"
  >
    <h3 class="mat-body-1 font-weight-bold mb-1">Vorteile</h3>
    @for (benefit of taskData.benefits; track benefit) {
      <div>
        <article class="d-flex w-100 align-items-center mt-4">
          <div class="align-self-start">
            <kf-icon class="kf-color-primary" [name]="getBenefitIcon(benefit.type)"></kf-icon>
          </div>
          <div class="ml-3">
            <h4 class="mat-body-2 mb-0 font-weight-bold">{{ benefit.name }}</h4>
            <div [innerHTML]="benefit.description | safeHtml"></div>
          </div>
        </article>
      </div>
    }
  </div>
</ng-template>

<ng-template #appointmentDateTemplate>
  <div class="d-flex flex-column mt-2">
    <div class="w-100 d-flex justify-content-between">
      <div class="m-auto">
        <div class="tag-info tag-neutral">
          <div class="tag-info-text">
            {{ appointmentDateString }}
          </div>
        </div>
      </div>
      <div class="button-row-inline">
        <div class="button-row-content">
          <button
            [attr.data-testcafe]="'sideTask-button-close'"
            (click)="onDatepickDeleted()"
            aria-label="Fälligkeitsdatum löschen"
            kfTooltip="Fälligkeitsdatum löschen"
            kfTooltipPosition="after"
            kfTooltipShow="hover"
            mat-icon-button
          >
            <kf-icon name="ic_loeschen_24"></kf-icon>
          </button>

          <button
            [attr.data-testcafe]="'sideTask-button-dateUpdate'"
            (click)="onDatepickUpdate()"
            aria-label="Fälligkeitsdatum ändern"
            kfTooltip="Fälligkeitsdatum ändern"
            kfTooltipPosition="after"
            kfTooltipShow="hover"
            mat-icon-button
          >
            <kf-icon name="ic_edit_24"></kf-icon>
          </button>
        </div>
      </div>
    </div>
    @if (isInvalidDate) {
      <mat-error class="mt-2"><kf-input-error message="Datum muss in der Zukunft liegen"></kf-input-error></mat-error>
    }
  </div>
</ng-template>

<ng-template #errorHint>
  <div class="error-hint mt-1">
    <div class="d-flex">
      <kf-icon class="mr-2" name="ic_warnschild_24"></kf-icon>
      <span class="text-nowrap">Angaben erforderlich</span>
    </div>
  </div>
</ng-template>
