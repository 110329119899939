import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentLookupService {
  constructor() {}

  public isInsideNativeShell(): boolean {
    return false;
  }
}
