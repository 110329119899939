import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { VariantCalculator } from '@domain/app/variant-calculator';
import { color } from 'bgzv-frontend-library';

interface InputWrapper {
  inputs: VariantCalculator.VariantCalculatorInputDTO[];
  persistedInputs: { [inputId: number]: number | boolean };
}

@Component({
  selector: 'compare-accounts-inputs',
  templateUrl: './compare-accounts-inputs.component.html',
  styleUrls: ['./compare-accounts-inputs.component.scss'],
})
export class CompareAccountsInputsComponent {
  @Input() isLoading = false;

  @Input()
  set inputs(value: InputWrapper) {
    this.formValue = {};
    this._inputs = value;
    value.inputs.forEach(input => (this.formValue[input.id] = value.persistedInputs?.[input.id] ?? input.defaultValue));
    this.valueChange.emit(this.formValue);
  }

  @ViewChildren('accounts', { read: ElementRef }) accountsRefs: QueryList<ElementRef>;

  get inputs(): InputWrapper {
    return this._inputs;
  }

  private _inputs: InputWrapper = { inputs: [], persistedInputs: {} };

  formValue: { [id: number]: boolean | number };

  @Output()
  valueChange = new EventEmitter<{ [id: number]: boolean | number }>();

  readonly Color = color;

  setValue(inputId: string, value: number | boolean) {
    this.formValue[inputId] = value;
    this.valueChange.emit(this.formValue);
  }

  getDefaultValue(input: VariantCalculator.VariantCalculatorInputDTO): number {
    if (this.inputs.persistedInputs && typeof this.inputs.persistedInputs[input.id] === 'number') {
      if ((this.inputs.persistedInputs[input.id] as number) > 0) {
        return this.inputs.persistedInputs[input.id] as number;
      }
    }

    return input.defaultValue;
    // (inputs.persistedInputs && inputs.persistedInputs[input.id]) || input.defaultValue
  }
}
