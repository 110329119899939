@if (!deleted) {
  <div
    class="d-flex align-items-center cursor-pointer justify-content-between item"
    [class.item--tall]="!isJumpToQuestionBtnAvailable()"
    [class.item--short]="isJumpToQuestionBtnAvailable()"
    [class.item--selected]="isSelected"
    [class.item--deep]="depth === 1"
    (click)="clicked.emit($event)"
    [class.cursor-default]="disabled || loading || isSelected"
    role="button"
    tabindex="0"
  >
    <div class="d-flex justify-content-start align-items-center item-button" [class.disabled]="disabled">
      @if (type === noteTypeEnum.text) {
        <kf-icon class="mr-2" name="ic_notiz_24"></kf-icon>
      } @else if (type === noteTypeEnum.drawing) {
        <kf-icon class="mr-2" name="ic_annotation_zeichnen_24"></kf-icon>
      }
      <div
        class="text-truncate no-select item-name"
        [class.text-break]="isCustomNote"
        [kfTooltip]="displayName.length > 50 ? displayName : null"
        kfTooltipPosition="after"
        kfTooltipShow="hover"
      >
        {{ displayName }}
      </div>
    </div>
    @if (isJumpToQuestionBtnAvailable()) {
      <button
        iconName="ic_weiterleiten_24"
        (click)="
          isTopicSelection ? jumpToTopicOverview(type) : questionGroupId && jumpToQuestion(questionGroupId, type)
        "
        [disabled]="disabled"
        aria-label="Screenshot überarbeiten"
        kfTooltip="Screenshot im Kontext überarbeiten"
        kfTooltipPosition="after"
        kfTooltipShow="hover"
        mat-icon-button
      >
        <kf-icon name="ic_weiterleiten_24"></kf-icon>
      </button>
    }
  </div>
}
