import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from '@app/material_module';
import { KFInputErrorModule } from '@de.fiduciagad.kundenportal/kf-theme/input-error';
import { CustomerContactRequestItem } from '@domain/app/contact.domain';
import { ContactForm } from '@domain/app/forms.domain';
import { PanelStateEnum } from '@enums';
import { formEmailPattern } from '@marginals/transition-task-utils';
import { ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';

@Component({
  selector: 'side-content-element-consultant',
  templateUrl: './side-content-element-consultant.component.html',
  styleUrl: './side-content-element-consultant.component.scss',
  imports: [ReactiveFormsModule, MaterialModule, KFInputErrorModule],
})
export class SideContentElementConsultantComponent implements OnInit {
  @Output() changePanelState = new EventEmitter<PanelStateEnum>();

  public contactForm: FormGroup<ContactForm>;
  public currentPanelState = PanelStateEnum.overview;
  readonly formEmailPattern = formEmailPattern;

  constructor(
    private formBuilder: FormBuilder,
    private contentElementService: ContentElementService,
    private contextService: ContextService,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.formEmailPattern)])
      ),
    });
  }

  public onContactAdded() {
    const customerContact = {
      firstName: this.controls.firstName.value,
      lastName: this.controls.lastName.value,
      email: this.controls.email.value,
    } as CustomerContactRequestItem;

    this.contentElementService.addCustomerContact(customerContact);
    this.changePanelState.emit(PanelStateEnum.assign);
  }

  public onContactCancelled() {
    this.changePanelState.emit(PanelStateEnum.assign);
  }

  // field error handling
  public getErrorMessage(name: string) {
    const field = this.controls[name];
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  get controls() {
    return this.contactForm.controls;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
