import { Component, OnInit } from '@angular/core';
import { ContextService } from '@services/context-service/context.service';
import { color } from 'bgzv-frontend-library';

@Component({
  selector: 'item-sticky-button',
  templateUrl: './item-sticky-button.component.html',
  styleUrls: ['./item-sticky-button.component.scss'],
})
export class ItemStickyButtonComponent implements OnInit {
  public isFocusMode: boolean;

  public readonly color = color;

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {
    this.isFocusMode = this.contextService.isFocusMode;
  }

  public onStickApp(event: Event): void {
    this.isFocusMode = this.contextService.toggleFocusMode();
  }

  get hasPortalHeader(): boolean {
    return this.contextService.hasPortalHeader;
  }
}
