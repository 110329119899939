@if (taskData) {
  <section
    class="composition-grid {{ showMainDetails ? 'mt-2 pt-4' : !mainData || !showMainDetails ? 'mt-2' : 'pt-4' }}"
  >
    @if (showMainDetails || showIndividualGroupHeading) {
      @if (compositionMedia && compositionMedia.url) {
        <bgzv-image
          class="composition-image"
          [alt]="'illustration'"
          [src]="compositionMedia.url"
          aria-hidden="true"
        ></bgzv-image>
      } @else {
        <bgzv-image
          class="composition-image"
          [alt]="'illustration'"
          [src]="assetPath + '/images/illu_zahnrad_duotone.svg'"
          aria-hidden="true"
        ></bgzv-image>
      }
    }
    @if (!showMainDetails && !showIndividualGroupHeading) {
      <div></div>
    }
    <div>
      @if (showIndividualGroupHeading) {
        <div class="d-flex align-items-center mb-2">
          <h2 class="text-truncate mr-3 mb-0" [style.maxWidth.%]="90">Individuelle Maßnahmen</h2>
        </div>
      }
      <ng-container *ngTemplateOutlet="header; context: { data: taskData }"></ng-container>
      <ng-container *ngTemplateOutlet="content; context: { data: taskData }"></ng-container>
    </div>
  </section>
}

<ng-template #header let-taskData="data">
  @if (showMainDetails) {
    <div class="d-flex align-items-center mb-2">
      @if (!mainData?.name) {
        <h2
          [debugId]="taskData.id"
          class="mr-2 mb-0"
          [style.maxWidth.%]="90"
          [innerHTML]="truncateText(taskData.name, 50) | safeHtml"
          [kfTooltip]="taskData.name.length > 50 ? taskData.name : null"
          [kfTooltipDisabled]="isIPad"
          kfTooltipPosition="below"
          kfTooltipShow="hover"
        ></h2>
      } @else if (!isIndividualItem) {
        <h2
          [debugId]="mainData.mainProduct?.id || mainData.id"
          class="mr-2 mb-0"
          [style.maxWidth.%]="90"
          [innerHTML]="truncateText(mainData.mainProduct?.name || mainData.name, 50) | safeHtml"
          [kfTooltip]="mainData.name.length > 50 ? mainData.name : null"
          [kfTooltipDisabled]="isIPad"
          kfTooltipPosition="below"
          kfTooltipShow="hover"
        ></h2>
      }

      @if (!!mainData?.id) {
        <button mat-button color="primary" (click)="onBadgeClicked('product', mainData.id, $event)">
          <span>{{ mainData.name }}</span>
          <kf-icon name="ic_weiterleiten_24"></kf-icon>
        </button>
      }
    </div>
  }
</ng-template>

<ng-template #content let-taskData="data">
  <div class="composition-task-border">
    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="productItem; context: { data: taskData }"></ng-container>
      <ng-container *ngTemplateOutlet="assignItem; context: { data: taskData }"></ng-container>
      @if (!isIndividualItem) {
        <ng-container *ngTemplateOutlet="infoIcon; context: { data: taskData }"></ng-container>
      }
    </div>
    <ng-container *ngTemplateOutlet="appointmentItem; context: { data: taskData }"></ng-container>
  </div>
</ng-template>

<ng-template #productItem let-data="data">
  <div class="d-flex align-items-center flex-grow-1">
    <mat-checkbox
      #taskCheckbox
      [attr.data-testcafe]="'itemCompositionTask-checkbox-check-' + getTestcafeId(data.name)"
      [disableRipple]="true"
      [disabled]="loading"
      [checked]="taskData.selected || !compositionId"
      (click)="$event.stopPropagation()"
      (change)="selectTask($event, taskData)"
      style="margin: 0 0.5rem 0 0"
      [color]="'grey'"
    >
      <span [innerHTML]="truncateText(data.name, 50) || 'NO TITLE' | safeHtml"></span>
    </mat-checkbox>
    @if (isRecommended(data)) {
      <div class="tag-info tag-neutral mb-1 ml-3">
        <div class="tag-info-text">Empfohlen</div>
      </div>
    }
  </div>
</ng-template>

<ng-template #assignItem let-data="data">
  @if (data.selected && !isIndividualTask) {
    @if (!data.customerContact) {
      <button
        [attr.data-testcafe]="'itemCompositionTask-button-assign-' + getTestcafeId(data.name)"
        [disabled]="loading"
        mat-stroked-button
        color="primary"
        (click)="openTaskAssignment(data.id, true, data.selected)"
      >
        <span>Zuweisen</span>
      </button>
    } @else {
      <button
        [disabled]="loading"
        mat-stroked-button
        color="primary"
        (click)="openTaskAssignment(data.id, true, data.selected)"
      >
        <span>Zuweisung ändern</span>
      </button>
    }
  }

  @if (isIndividualTask) {
    <button
      [disabled]="loading"
      mat-stroked-button
      color="primary"
      (click)="openTaskAssignment(data.id, true, data.selected)"
    >
      <span>Zuweisung ändern</span>
    </button>
  }
</ng-template>

<ng-template #appointmentItem let-data="data">
  @if (!!data.customerContact) {
    <div class="d-flex align-items-center mat-small mt-3">
      <div class="d-flex align-items-center">
        <kf-icon name="ic_person_mit_kreis_24" class="mr-2"></kf-icon>
        <div>{{ getContactExtended(data.customerContact) }}</div>
      </div>
      @if (!!data.appointmentDate) {
        <div class="mx-2">•</div>
        <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
        <div>{{ moment(data.appointmentDate).format('LL') }}</div>
      }
    </div>
  }
</ng-template>

<ng-template #infoIcon let-data="data">
  <button
    class="ml-3"
    [attr.data-testcafe]="'itemComposition-button-details'"
    mat-icon-button
    (click)="openTaskAssignment(taskData.id, false, taskData.selected)"
    aria-label="Details anzeigen"
    [kfTooltip]="'Details zu: ' + data.name"
    [kfTooltipDisabled]="isIPad"
    kfTooltipPosition="below"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
  </button>
</ng-template>
