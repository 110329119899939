import { Component, Input } from '@angular/core';
import { SubtopicOverviewItem } from '@domain/app/topic.domain';
import { TopicService } from '@services/topic-service/topic-service';

@Component({
  selector: 'item-subtopic-selected',
  templateUrl: './item-subtopic-selected.component.html',
  styleUrls: ['./item-subtopic-selected.component.scss'],
  standalone: false,
})
export class ItemSubtopicSelectedComponent {
  @Input() subtopicItem: SubtopicOverviewItem;

  public loading = false;

  constructor(private topicService: TopicService) {}

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  public getMediaUrl(id: string): string {
    return this.topicService.topicMediaIcons.get(id) || '';
  }
}
