import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  ContentElementItem,
  DeselectContentElementItem,
  SelectContentElementItem,
} from '@domain/app/content-element.domain';
import { SubtopicOverviewItem } from '@domain/app/topic.domain';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { TopicService } from '@services/topic-service/topic-service';
import { getContactName } from '@utils/helpers/formatContact';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'item-composition-content-element',
  templateUrl: './item-composition-content-element.component.html',
  styleUrls: ['./item-composition-content-element.component.scss', '../item-cart/item-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ItemCompositionContentElementComponent implements OnInit, OnDestroy {
  @Input() contentElement: ContentElementItem;
  @Output() showDetails = new EventEmitter<ContentElementItem>();
  @Output() assignContentElement = new EventEmitter<ContentElementItem>();
  @Output() contentElementChanged = new EventEmitter<void>();

  public loading = false;

  private destroySubs = new Subject<void>();
  private subtopicData: SubtopicOverviewItem;

  constructor(
    private contextService: ContextService,
    private loadingService: LoadingService,
    private clientService: ClientService,
    private queryService: QueryService,
    private topicService: TopicService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });

    this.getSubtopicData();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public openInfo(): void {
    this.showDetails.emit(this.contentElement);
  }

  public openAssignContentElementPanel(): void {
    this.contentElementChanged.emit();
    this.assignContentElement.emit(this.contentElement);
  }

  public selectContentElement(checkbox: MatCheckboxChange | { checked: boolean }): void {
    if (checkbox.checked) {
      const sendObj = {
        themaId: this.contentElement.id,
        subtopicId: this.subtopicData.id,
      } as SelectContentElementItem;

      this.queryService.putSelectThema(this.clientService.consultationId, sendObj).subscribe(data => {
        this.contentElementChanged.emit();
      });
    } else {
      const sendObj = {
        themaId: this.contentElement.id,
        target: 'RECOMMENDED',
      } as DeselectContentElementItem;

      this.queryService.putDeselectThema(this.clientService.consultationId, sendObj).subscribe(data => {
        this.contentElementChanged.emit();
      });
    }
  }

  private getSubtopicData(): void {
    const availableTopics = this.topicService.subtopicData.available;
    for (let i = 0; i < availableTopics.length; i++) {
      const subtopic = availableTopics[i].subtopics.find(x => x.id === this.contentElement.referenceId);
      if (subtopic) {
        this.subtopicData = subtopic;
        break;
      }
    }
  }

  get truncateName() {
    const length = 50;
    const text = this.contentElement.name;
    if (text?.length <= length) return text;
    return text?.substring(0, length) + '...';
  }

  get truncateSubtopicName() {
    const length = 50;
    const text = this.subtopicData?.title;
    if (text?.length <= length) return text;
    return text?.substring(0, length) + '...';
  }

  get testcafeId() {
    return `${this.contentElement.name?.replace(/ /g, '')}`;
  }

  get assetSource() {
    return this.contextService.assetPath + '/images/icon-task.svg';
  }

  get contactName() {
    return getContactName(this.contentElement.customerContact);
  }

  get appointmentDate() {
    const date = new Date(this.contentElement.appointmentDate);
    return date.toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' });
  }
}
