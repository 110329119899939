import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Action, ActionService } from '@services/action-service/action.service';
import { ConfigService } from '@services/config-service/config.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'overlay-debug',
  templateUrl: './overlay-debug.component.html',
  styleUrls: ['./overlay-debug.component.scss'],
})
export class OverlayDebugComponent implements OnInit, OnDestroy, AfterViewInit {
  private _actionSub: Subscription;

  readonly color = color;
  readonly buttonIcon = libIcons;

  public themeColors = [];

  constructor(
    private actionService: ActionService,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'overlay-debug') {
      }
    });
    this.themeColors = [];
    const style = getComputedStyle(document.body);
    Object.keys(style).forEach(x => {
      if (style[x].includes('--color-')) {
        this.themeColors.push({ prop: style[x], value: style.getPropertyValue(style[x]) });
      }
    });
  }

  ngOnDestroy(): void {
    if (this._actionSub) {
      this._actionSub.unsubscribe();
    }
  }

  ngAfterViewInit() {}

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }
}
