@if (subtopicResults) {
  <div class="d-flex flex-column menu-question-world-column">
    <h3 class="mat-body-1 font-weight-bold mt-5 mb-4">{{ searchQuery ? 'Themen' : 'Favoriten Themen' }}</h3>
    @if (subtopicResults.length > 0) {
      @for (subtopic of subtopicResults; track subtopic) {
        <div class="mb-2">
          @if (consultantTopicCount > 0 && isFirstMainTopic(subtopic)) {
            <div class="mb-3">Beratungsthemen</div>
          }
          <button
            class="d-flex recommendation-container mb-3 border-shadow kf-color-primary kf-bg"
            (click)="onSubtopicSelect(subtopic)"
            [attr.data-title]="subtopic.selected ? 'Schon für die Beratung ausgewählt.' : null"
          >
            @if (!subtopic.selected) {
              <kf-icon class="mr-2" name="ic_pfeil_rechts_24" style="min-width: 24px"></kf-icon>
            } @else if (subtopic.selected) {
              <kf-icon class="mr-2" name="ic_haken_mit_kreis_24" style="min-width: 24px"></kf-icon>
            }
            <div>{{ subtopic.title }}</div>
          </button>
        </div>
      }
    }
  </div>
}
