import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { Notes } from '@domain/notes';
import { NoteContextEnum, NoteOriginEnum, NoteTypeEnum, PanelStates, RoutingPathMain, RoutingPathPrep } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { DrawingInfo, ViewProperties } from '@services/note-service/note';
import { NoteService, UpdateNoteSendObj } from '@services/note-service/note.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import {
  baseConfig,
  baseConfigCollapse,
  baseConfigCollapseWithDrawing,
  baseConfigWithDrawing,
  buttonDrawingDelete,
  buttonDrawingRevert,
  buttonDrawingSave,
  drawingColorOptions,
  drawingSizeOptions,
  drawingToolsNoOpacity,
} from '@utils/footer-config';
import { color } from '@utils/helpers/color';
import emojiRegex from 'emoji-regex';
import html2canvas from 'html2canvas';
import { cloneDeep, isEqual } from 'lodash-es';
import { Subject, lastValueFrom, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

enum DrawingTool {
  Pen,
  Eraser,
}

enum CurrentSizeEnum {
  PenS = 5,
  PenM = 10,
  PenL = 20,
  EraserS = 25,
  EraserM = 50,
  EraserL = 100,
}

@Component({
  selector: 'overlay-draw',
  templateUrl: './overlay-draw.component.html',
  styleUrls: ['./overlay-draw.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class OverlayDrawComponent implements OnInit, OnDestroy, AfterViewInit {
  private TinyMCEConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    language: 'de',
    plugins: ['link', 'lists', 'help'],
    toolbar: 'styles | bold italic | link | outdent indent | bullist numlist | blockquote | undo redo',
    style_formats: [
      { title: 'Absatz', block: 'p' },
      { title: 'Überschrift 1', block: 'h2' },
      { title: 'Überschrift 2', block: 'h3' },
      { title: 'Überschrift 3', block: 'h4' },
    ],
    menubar: false,
    statusbar: false,
  };

  private _sidebarCollapsed = false;
  private context: CanvasRenderingContext2D;
  private destroySubs = new Subject<void>();

  public showCursor: boolean = true;
  public showCustomCursor: boolean = false;
  public tinyEditorConfig: EditorComponent['init'] = this.TinyMCEConfig;
  public currentConsultationMode: NoteOriginEnum = NoteOriginEnum.preparation;

  // this information will be set in the footer
  public currentQuestionGroupId: string = '-1';

  @Input() inNotesOverlay: boolean = false;
  @Input() displayName;
  @Input() currentOpacity: number = 60;
  @Input() isCustomNote: boolean = false;
  @Input()
  set sidebarCollapsed(value: boolean) {
    this._sidebarCollapsed = value;
    const canvas = this.canvas?.nativeElement;
    if (canvas) {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      if (this.drawingInfo) {
        this.draw(true);
      } // canvas resets on resize - so we need to re-draw it
    }
    // Sometimes Angular's change detected fails to update the [class....] bindings here
    this.chg.markForCheck();
  }
  @Output() expandSidebarButtonClick = new EventEmitter<void>();

  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('customCursor') customCursor: ElementRef<any>;
  @ViewChild('input') input;
  @ViewChild('screenshotCanvas') screenshotCanvas: ElementRef;

  public currentColorIndex = 0;
  public currentNote: any = undefined;
  public currentOpacityTitle: string = `Transparenz ändern, aktuell: ${100 - this.currentOpacity}%`;
  public currentSize = CurrentSizeEnum.PenS;
  public currentTool = DrawingTool.Pen;
  public drawingInfo: DrawingInfo | undefined;
  public drawingTool = DrawingTool;
  public maxLength: number = 4000;

  public pageReference: NoteContextEnum | undefined;
  public questionGroupId: number = -1;
  public loading = false;
  public saved: boolean | undefined = true;
  public screenshot: string;
  public text: string = '';
  public type: NoteTypeEnum | undefined = undefined;

  public showColorSelector = false;
  public showSizeSelector = false;
  public showTextLengthError: boolean = false;

  public leftPanel = document.querySelector('#agendaNavigationComponent') as HTMLElement;
  public rightPanel = document.querySelector('#rightContent') as HTMLElement;
  public centerPanel = document.querySelector('#mainContent') as HTMLDivElement;

  public leftPanelScroller = this.leftPanel;
  public rightPanelScroller = this.rightPanel !== null ? (this.rightPanel.firstChild as HTMLElement) : null;
  public centerPanelScroller = this.centerPanel !== null ? this.centerPanel : null;

  public footerCurrentColor = drawingColorOptions[0];
  public footerCurrentSize = parseInt(drawingSizeOptions[2], 10);
  public colorOptions = drawingColorOptions;

  private currentOpacityCSS = Number((this.currentOpacity / 100).toFixed(2));
  private inputType = 'mouse';
  private lastDisplayName: string;
  private lastDrawing = undefined;
  private lastText: string = undefined;
  private paintActive = false;
  private footerConfig: FooterConfig = baseConfig;
  private viewProperties: ViewProperties = {
    bgzvScrollPosition: {
      x: null,
      y: null,
    },
    sideNavPanels: {
      left: {
        open: false,
        scrollPosition: {
          x: null,
          y: null,
        },
      },
      right: {
        open: false,
        scrollPosition: {
          x: null,
          y: null,
        },
      },
    },
    screenDimensions: {
      width: null,
      height: null,
    },
    stickyMode: null,
    transparencySetting: null,
    consultationMode: null,
  };

  readonly color = color;
  readonly noteType = NoteTypeEnum;
  readonly NoteContextEnum = NoteContextEnum;

  constructor(
    private dialogService: DialogService,
    private actionService: ActionService,
    private noteService: NoteService,
    private chg: ChangeDetectorRef,
    private clientService: ClientService,
    private contextService: ContextService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService,
    private snackBarService: SnackBarTemplatesService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  async ngOnInit() {
    this.footerButtonsFactory();

    // close shared agenda panel when opening notes overlay
    // if open, delay fetching data until panel is closed to avoid shift in drawing
    const fetchDelay = this.panelService.panelOpen.getValue() ? 300 : 10;
    this.footerService.handleFooterAction({ owner: 'bgzv', id: 'force-close-agenda' });

    setTimeout(async () => {
      this.currentQuestionGroupId = this.noteService.currentQuestionGroupId;
      this.noteService.getAllNotes();
      const currentNotes = this.noteService.currentNotes.getValue();

      this.type === this.noteService.presetNoteType;

      if (this.contextService.currentUrl.includes('agenda')) {
        this.currentQuestionGroupId = '-1';
      }

      //find note by currentQuestionGroupId or looking into the page reference
      this.currentNote =
        this.currentQuestionGroupId === '-1' ? this.getPageNote(currentNotes) : this.getTopicNote(currentNotes);

      if (this.inNotesOverlay && this.currentNote === undefined) {
        this.currentNote = currentNotes.notes[0] || (await this.noteService.getNoteByContext(this.inNotesOverlay));
      } else if (!this.currentNote) {
        this.currentNote = await this.noteService.getNoteByContext(this.inNotesOverlay);
      } else {
        this.currentNote = await this.noteService.getSingleNote(
          this.currentNote?.noteId || this.currentNote?.id,
          this.inNotesOverlay
        );
      }
    }, fetchDelay);

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.footerButtonsFactory();
      this.chg.detectChanges();
    });

    this.noteService.currentNote.pipe(takeUntil(this.destroySubs)).subscribe(res => {
      if (!res) {
        this.text = '';
        this.lastText = '';
        this.drawingInfo = undefined;
        this.lastDrawing = undefined;
        this.isCustomNote = undefined;
        this.displayName = undefined;
        this.type = undefined;
        return;
      }

      this.noteService.presetNoteType = res.type;

      this.displayName = res.displayName;
      this.lastDisplayName = res.displayName;

      res.pageReference === NoteContextEnum.noteManagement ? (this.isCustomNote = true) : (this.isCustomNote = false);

      if (!!this.type) {
        this.type = res.type;
      }
      if (!!this.presetNoteType) {
        this.presetNoteType = res.type;
      }

      if (
        res.type === NoteTypeEnum.text ||
        this.noteService.presetNoteType === NoteTypeEnum.text ||
        this.type === NoteTypeEnum.text
      ) {
        this.text = res.text || '';
        this.lastText = res.text || '';
        this.type = NoteTypeEnum.text;
        this.drawingInfo = undefined;
        this.lastDrawing = undefined;

        if (this.textLength > this.maxLength) {
          this.showTextLengthError = true;
        } else {
          this.showTextLengthError = false;
        }
      } else if (
        res.type === NoteTypeEnum.drawing ||
        this.noteService.presetNoteType === NoteTypeEnum.drawing ||
        this.type === NoteTypeEnum.drawing
      ) {
        const newDrawing = this.handleNewDrawing(res);
        this.text = '';
        this.lastText = '';
        this.type = NoteTypeEnum.drawing;
        this.drawingInfo = cloneDeep(newDrawing);
        this.lastDrawing = cloneDeep(this.drawingInfo);
        this.screenshot = res.drawingBase64;
        this.draw(true);
        // In some cases when opening the draw overlay from the consultation the drawing won't show up, so we recalc the canvas
        this.calcCanvasSize(10, 10);

        this.viewProperties = res.viewProperties ? JSON.parse(res.viewProperties) : this.viewProperties;
        if (res.viewProperties) {
          this.restoreViewFromProperties();
        }
      }

      this.pageReference = res.pageReference;
      this.questionGroupId = res.questionGroupId;
    });

    this.noteService.requestNoteChange.pipe(takeUntil(this.destroySubs)).subscribe(async x => {
      if (x !== '-1') {
        if (this.unsavedChanges && (!this.isIPad || (this.isIPad && this.presetNoteType === NoteTypeEnum.text))) {
          await this.unsavedChangesDialog();
        } else {
          await this.noteService.getSingleNote(x, this.inNotesOverlay);
        }
        this.footerButtonsFactory();
      }
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });

    this.panelService.panelOpen.pipe(takeUntil(this.destroySubs)).subscribe(open => {
      // need to re-evaluate the canvas size when the agenda panel open state changes
      if (open === false) {
        this.calcCanvasSize(300, 10, false);
      }
    });

    // Initialization for canvas background - opacity can be changed with changeOpacity()
    this.isCustomNote ? this.changeOpacity(100) : this.changeOpacity(this.currentOpacity);
  }

  ngAfterViewInit() {
    // Setting the size of the canvas element dynamically to the size of the parent
    const element = this.canvas.nativeElement;
    this.context = element.getContext('2d');
    this.calcCanvasSize(10, 10, true);

    this.leftPanel = document.querySelector('#agendaNavigationComponent') as HTMLElement;
    this.rightPanel = document.querySelector('#rightContent') as HTMLElement;
    this.centerPanel = document.querySelector('#mainContent') as HTMLDivElement;
    this.leftPanelScroller = this.leftPanel;
    this.rightPanelScroller = this.rightPanel !== null ? (this.rightPanel.firstChild as HTMLElement) : null;
    this.centerPanelScroller = this.centerPanel !== null ? this.centerPanel : null;

    this.updateCustomCursor();
  }

  ngOnDestroy(): void {
    this.noteService.currentNote.next(null);
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  // Preparations for using different cursors for different inputs
  @HostListener('pointerenter', ['$event'])
  getInputType(event: PointerEvent) {
    this.inputType = event.pointerType;
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }

      if (event.id === 'drawing-save') {
        this.saveNote();
      } else if (event.id === 'drawing-revert') {
        this.resetNote();
      } else if (event.id === 'drawing-delete') {
        this.deleteNote();
      } else if (event.id === 'drawing-line-width') {
        this.footerCurrentSize = parseInt(event?.payload.toString(), 10);
        this.setBrushSize();
      } else if (event.id === 'drawing-eraser-activated') {
        this.currentTool = DrawingTool.Eraser;
        this.footerCurrentColor = '#ffffff';
        this.setBrushSize();
      } else if (event.id === 'drawing-color-picker') {
        this.currentTool = DrawingTool.Pen;
        this.footerCurrentColor = event?.payload.toString();
        this.currentColorIndex = drawingColorOptions.indexOf(this.footerCurrentColor) || 0;
        this.setBrushSize();
      } else if (event.id === 'drawing-opacity') {
        this.changeOpacity(parseInt(event?.payload.toString(), 10));
      }
    }
  }

  public editorChanges(editorText: string): void {
    this.text = editorText;
    this.saved = false;
    this.onTextEditorChange();
  }

  get disabledButton(): boolean {
    return this.disabled || this.textLength > this.maxLength || this.loading || this.isScreenshotting;
  }

  get emptyCanvas(): boolean {
    return this.drawingInfo?.length === 0 || this.drawingInfo === undefined;
  }

  get emptyTextBox(): boolean {
    return this.text === '';
  }

  private footerButtonsFactory(): void {
    if (!this.footerConfig) {
      return;
    }

    let currentConfig = cloneDeep(this.footerConfig);
    const rightButtonElements = [];
    const type = this.noteService.presetNoteType;

    buttonDrawingDelete.inputs.config.disabled = this.loading;
    buttonDrawingRevert.inputs.config.disabled = !this.unsavedChanges;
    //we need to differentiate between text and drawing notes, because text is always empty on drawing notes
    buttonDrawingSave.inputs.config.disabled =
      type === NoteTypeEnum.text ? this.emptyTextBox || this.disabledButton : this.emptyCanvas || this.disabledButton;

    if (!this.inNotesOverlay && type === NoteTypeEnum.text) {
      rightButtonElements.push(buttonDrawingDelete);
      rightButtonElements.push(buttonDrawingRevert);
      rightButtonElements.push(buttonDrawingSave);
      currentConfig.right.elements = rightButtonElements;
    } else if (!this.inNotesOverlay && type === NoteTypeEnum.drawing) {
      currentConfig = cloneDeep(baseConfigWithDrawing);

      if (this.isIPad) {
        // remove save button for iPad
        currentConfig.drawing.elements.pop();
      }
    } else if (this.inNotesOverlay) {
      const thisNote = this.noteService.currentNote.getValue();

      if (type === NoteTypeEnum.drawing && thisNote?.type === NoteTypeEnum.drawing) {
        if (thisNote?.pageReference === NoteContextEnum.noteManagement) {
          currentConfig = cloneDeep(baseConfigCollapseWithDrawing);
          currentConfig.drawing.elements.unshift(drawingToolsNoOpacity);

          if (this.isIPad) {
            // remove save button for iPad
            currentConfig.drawing.elements.pop();
          }
        } else {
          currentConfig = cloneDeep(baseConfigCollapse);
        }
      } else {
        currentConfig = cloneDeep(baseConfigCollapseWithDrawing);
      }
    }
    /** update config with current color and size for drawing which are reset by the baseConfigs */
    const children = currentConfig.drawing?.elements[0].inputs.children;
    if (children) {
      const colorPicker = children.find(x => x.config.type === 'color-picker');
      const lineWidth = children.find(x => x.config.type === 'line-width');
      if (colorPicker) {
        colorPicker.config.initialValue = this.footerCurrentColor;
      }
      if (lineWidth) {
        lineWidth.config.initialValue = this.footerCurrentSize;
      }
    }

    this.footerService.submitFooterConfig(currentConfig);

    if (this.inNotesOverlay) {
      this.footerService.setCollapsibleButtonColor();
      this.footerService.setCollapsibleButtonVisibility(true);
    }
  }

  get sidebarCollapsed(): boolean {
    return this._sidebarCollapsed;
  }

  get isTextNote(): boolean {
    return (
      (this.presetNoteType && this.presetNoteType === this.noteType.text) ||
      (this.type && this.type === this.noteType.text) ||
      false
    );
  }

  get textLength(): number {
    return this.text?.length || 0;
  }

  get isDrawingNote(): boolean {
    return (
      (this.presetNoteType && this.presetNoteType === this.noteType.drawing) ||
      (this.type && this.type === this.noteType.drawing) ||
      false
    );
  }

  // --------------------------------------------- //
  // ---------- MOUSE / TOUCH FUNCTIONS ---------- //
  // --------------------------------------------- //

  public mouseUp(event: MouseEvent) {
    this.endPainting();
    event.preventDefault();
  }

  public mouseDown(event: MouseEvent) {
    this.handleBlur();
    event.preventDefault();
    const rect = (<HTMLCanvasElement>this.canvas.nativeElement).getBoundingClientRect();
    const leftPos = event.clientX - rect.left;
    const topPos = event.clientY - rect.top;

    this.paintActive = true;
    this.showColorSelector = false;
    this.showSizeSelector = false;
    this.addClick(leftPos, topPos, false);
    this.draw();
    this.footerButtonsFactory();
  }

  public mouseMove(event: MouseEvent) {
    event.preventDefault();
    const rect = (<HTMLCanvasElement>this.canvas.nativeElement).getBoundingClientRect();

    const leftPos = event.clientX - rect.left;
    const topPos = event.clientY - rect.top;

    if (this.paintActive) {
      this.addClick(leftPos, topPos, true);
      this.draw();
    }

    // offset consists of 1.5rem top-margin + 5rem inputfield height. 6.5rem = 104px
    const notesOffset = this.inNotesOverlay ? 104 : 0;
    const endX = leftPos + this.cursorOffset('x');
    const endY = topPos + this.cursorOffset('y') + notesOffset;

    this.renderer.setStyle(this.customCursor.nativeElement, 'top', `${endY}px`);
    this.renderer.setStyle(this.customCursor.nativeElement, 'left', `${endX}px`);

    this.showCursor = false;
    this.showCustomCursor = !this.showCursor;
  }

  public mouseLeave(event: MouseEvent) {
    this.showCustomCursor = false;
    event.preventDefault();
    this.endPainting();
  }

  public touchStart(event: TouchEvent) {
    this.handleBlur();
    event.preventDefault();
    const rect = this.canvas.nativeElement.getBoundingClientRect();

    this.paintActive = true;
    this.showColorSelector = false;
    this.showSizeSelector = false;
    this.addClick(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top, false);
    this.draw();
    this.footerButtonsFactory();
  }

  public touchMove(event: TouchEvent) {
    this.handleBlur();
    event.preventDefault();
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    if (this.paintActive) {
      this.addClick(event.touches[0].clientX - rect.left, event.touches[0].clientY - rect.top, true);
      this.draw();
    }
  }

  public touchEnd(event: TouchEvent) {
    event.preventDefault();
    this.endPainting();
  }

  public touchCancel(event: TouchEvent) {
    event.preventDefault();
    this.endPainting();
  }

  /**
   * Function for setting the mouse wheel as an event emitter to begin drawing when pressed
   * Movement will still happen via mouseMove()
   * @param event starts the event
   */
  public wheel(event: Event) {
    event.preventDefault();
    this.handleBlur();
  }

  /**
   * Ends drawing process when click or touch event ends
   * Meaning there'll be no further lines until there's a click or touch event
   */
  private endPainting() {
    this.paintActive = false;
  }

  public onResize(event) {
    this.calcCanvasSize(10, 10);
  }

  /**
   * restore the view to match the information from the screenshot
   */
  public restoreViewFromProperties() {
    // resize the window to fit the screenshot if needed
    if (
      window.outerWidth !== this.viewProperties.screenDimensions.width ||
      window.outerHeight !== this.viewProperties.screenDimensions.height
    ) {
      window.resizeTo(this.viewProperties.screenDimensions.width, this.viewProperties.screenDimensions.height);
    }

    if (this.rightPanelScroller) {
      this.rightPanelScroller.scrollTo(
        this.viewProperties.sideNavPanels?.right?.scrollPosition?.x,
        this.viewProperties.sideNavPanels?.right?.scrollPosition?.y
      );
    }

    if (this.leftPanelScroller) {
      this.leftPanelScroller.scrollTo(
        this.viewProperties.sideNavPanels?.left?.scrollPosition?.x,
        this.viewProperties.sideNavPanels?.left?.scrollPosition?.y
      );
    }

    this.centerPanelScroller &&
      this.centerPanelScroller.scrollTo(
        this.viewProperties.bgzvScrollPosition?.x,
        this.viewProperties.bgzvScrollPosition?.y
      );

    if (this.viewProperties.transparencySetting !== this.currentOpacity) {
      this.changeOpacity(this.viewProperties.transparencySetting);
    }

    this.usePanelTrigger();
  }

  public usePanelTrigger() {
    localStorage.setItem(
      'recPanelState',
      this.viewProperties.sideNavPanels?.right?.open === true ? PanelStates.open : PanelStates.closed
    );
  }

  // --------------------------------------------- //
  // ------------- PUBLIC FUNCTIONS -------------- //
  // --------------------------------------------- //

  public async close(afterDelete: boolean = false) {
    const presetNoteType = this.noteService.presetNoteType;
    // disable saving if on iPad upon closing only for drawings
    if (this.unsavedChanges && !afterDelete) {
      let result = false;
      if (!this.isIPad || (this.isIPad && this.presetNoteType === NoteTypeEnum.text)) {
        result = await this.unsavedChangesDialog();
      }
      if (
        (!result &&
          presetNoteType === NoteTypeEnum.drawing &&
          ((this.lastDrawing && this.lastDrawing?.length === 0) || !this.lastDrawing)) ||
        (presetNoteType === NoteTypeEnum.text && ((this.lastText && this.lastText === '') || !this.lastText))
      ) {
        this.deleteNote();
        this.noteService.presetNoteType = null;
      }
    } else {
      if (
        (presetNoteType === NoteTypeEnum.drawing &&
          ((this.drawingInfo && this.drawingInfo.length === 0) || !this.drawingInfo)) ||
        (presetNoteType === NoteTypeEnum.text && ((this.text && this.text === '') || !this.text))
      ) {
        this.deleteNote();
        this.noteService.presetNoteType = null;
      }
    }

    this.actionService.setAction({ target: 'overlay-main', action: 'close' });
  }

  public setViewProperties() {
    this.viewProperties.sideNavPanels.right.open =
      localStorage.getItem('recPanelState') === PanelStates.open ? true : false;

    if (this.rightPanelScroller !== null) {
      this.viewProperties.sideNavPanels.right.scrollPosition = {
        x: this.rightPanelScroller.scrollLeft,
        y: this.rightPanelScroller.scrollTop,
      };
    }
    if (this.centerPanelScroller !== null) {
      this.viewProperties.bgzvScrollPosition.x = this.centerPanelScroller.scrollLeft;
      this.viewProperties.bgzvScrollPosition.y = this.centerPanelScroller.scrollTop;
    }

    this.viewProperties.screenDimensions = {
      width: window.outerWidth,
      height: window.outerHeight,
    };

    this.viewProperties.stickyMode = this.contextService.isFocusMode;
    this.viewProperties.transparencySetting = this.currentOpacity;
    this.viewProperties.consultationMode = this.contextService.currentMode;
  }

  public async saveNote() {
    const type = this.type !== undefined ? this.type : this.presetNoteType;

    const req: UpdateNoteSendObj = { type: type };
    if (this.isCustomNote) {
      if (this.displayName === '') {
        this.displayName = 'Notiz ' + this.noteService.largestNoteNumber;
      }

      req.displayName = this.displayName;
      this.lastDisplayName = this.displayName;
    }
    if (type === NoteTypeEnum.drawing) {
      this.isScreenshotting = true;

      if (!this.drawingsAreEqual(this.lastDrawing, this.drawingInfo)) {
        await this.takeScreenshot();
      }
      this.setViewProperties();

      req.viewProperties = JSON.stringify(this.viewProperties);
      req.drawing = JSON.stringify(this.drawingInfo);
      this.lastDrawing = cloneDeep(this.drawingInfo);
      req.drawingBase64 = this.screenshot;
    } else if (this.type === NoteTypeEnum.text) {
      this.text = this.filterEmoji(this.text);

      req.text = this.text;
      this.lastText = this.text;
    }

    await this.noteService.updateNote(req, this.inNotesOverlay);
    this.saved = true;
    this.isScreenshotting = false;

    this.snackBarService.openSnackBar({
      type: SnackbarType.SUCCESS,
      message: `${type === NoteTypeEnum.drawing ? 'Zeichnung' : 'Notiz'} gespeichert.`,
    });

    this.noteService.getAllNotes();
  }

  public resetNote() {
    if (this.type === NoteTypeEnum.drawing && this.drawingInfo !== undefined) {
      this.drawingInfo = cloneDeep(this.lastDrawing);
      this.draw(true);
    } else if (this.type === NoteTypeEnum.text && this.text !== undefined) {
      this.text = this.lastText;
    }
    this.displayName = this.lastDisplayName;
    this.context.lineWidth = this.footerCurrentSize;
    this.saved = true;
    this.footerButtonsFactory();
  }

  public async deleteNote() {
    this.drawingInfo = null;
    this.currentNote = null;
    this.type = null;
    this.screenshot = null;

    const success = await this.noteService.deleteNote(this.inNotesOverlay);

    if (success && !this.inNotesOverlay) {
      this.close(true);
    }
  }

  public filterEmoji(unfiltered: string): string {
    let filtered = unfiltered;
    const emojiFilterRegex = emojiRegex();

    for (const match of unfiltered?.matchAll(emojiFilterRegex)) {
      const emoji = match[0];
      filtered = filtered.replace(emoji, '');
    }

    return filtered;
  }

  public async takeScreenshot() {
    let elem: HTMLElement;

    if (this.inNotesOverlay) {
      elem = document.getElementById('overlay-notes') as HTMLElement;
    } else {
      elem = document.querySelector('#bgzv-frontend-main') as HTMLElement;
    }

    await html2canvas(elem, { useCORS: true, allowTaint: true, logging: true, removeContainer: true })
      .then(canvas => {
        this.screenshot = canvas.toDataURL();
      })
      .catch(function (error) {
        console.error('html2canvas failed!', error);
      });
  }

  public changeOpacity(value: number) {
    this.currentOpacityCSS = Number((value / 100).toFixed(2));
    this.currentOpacity = Number(value);

    document.documentElement.style.setProperty('--canvas-background', `rgba(255, 255, 255, ${this.currentOpacityCSS})`);
    this.currentOpacityTitle = `Transparenz ändern, aktuell: ${100 - this.currentOpacity}%`;
  }

  public setDisplayName(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target) {
      this.displayName = target.value;
    }
  }

  public onTextEditorChange() {
    if (this.textLength > this.maxLength) {
      this.showTextLengthError = true;
    } else {
      this.showTextLengthError = false;
    }
    this.footerButtonsFactory();
  }

  // Handle clicks on screenshot (for non-custom notes)
  public jumpToQuestion(jumpQuestionGroupId) {
    if (this.isJumpToQuestionDisabled(jumpQuestionGroupId)) {
      return;
    }
    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {
      if (
        this.contextService.currentMode === 'prep' &&
        this.contextService.currentMainContext !== RoutingPathPrep.Consultation
      ) {
        this.router.navigate([RoutingPathPrep.Consultation], {
          state: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      } else if (
        this.contextService.currentMode === 'main' &&
        this.contextService.currentMainContext !== RoutingPathMain.Consultation
      ) {
        this.router.navigate([RoutingPathMain.Consultation], {
          state: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      } else {
        this.actionService.setAction({
          target: 'consultation',
          action: 'jump',
          options: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      }
    });
  }

  public isNoteEditAvailable(): boolean {
    return this.type === NoteTypeEnum.text || this.isCustomNote || !this.inNotesOverlay;
  }

  public jumpToTopicOverview(type: NoteTypeEnum) {
    if (this.disabled || this.contextService.currentMode !== this.viewProperties.consultationMode) {
      return;
    }

    this.noteService.presetNoteType = type;

    this.actionService
      .setAction({ target: 'overlay-main', action: 'close' })
      .then(() =>
        this.router.navigate([`${this.contextService.currentMode}/agenda/${this.clientService.bankHubId}`], {
          state: { openDrawOverlay: true },
        })
      )
      .then(() => this.actionService.setAction({ target: 'overlay-main', action: 'drawing' }));
  }

  // --------------------------------------------- //
  // ------------------ GETTER ------------------- //
  // --------------------------------------------- //

  get unsavedChanges() {
    if (this.type === NoteTypeEnum.text && (this.lastText === undefined || this.lastText === '') && this.text === '') {
      return false;
    }

    return (
      (this.type === NoteTypeEnum.text && this.lastText !== this.text) ||
      (this.type === NoteTypeEnum.drawing && !isEqual(this.drawingInfo, this.lastDrawing))
    );
  }

  get disabled() {
    return this.drawingInfo === undefined && this.text === undefined;
  }

  get drawingBase64() {
    return this.screenshotCanvas.nativeElement.src;
  }

  get cursorBorderWidth() {
    return this.footerCurrentSize <= 10 ? '1px' : '2px';
  }

  get textCounterColor(): string {
    const threshold = this.maxLength * 0.9;

    if (this.textLength > threshold) {
      const s = ((this.textLength - threshold) * 1000) / this.maxLength;
      const l = 50 + Math.round(s / 16.666);
      return `hsla(0, ${s}%, ${this.textLength < this.maxLength ? l : 50}%, 1)`;
    }

    return 'var(--color-grey-600)';
  }

  get presetNoteType() {
    return this.noteService.presetNoteType;
  }

  get isScreenshotting(): boolean {
    return this.noteService.screenshotting;
  }

  get isIPad(): boolean {
    return this.contextService.isIPad;
  }

  // --------------------------------------------- //
  // ------------------ SETTER ------------------- //
  // --------------------------------------------- //

  set isScreenshotting(screenshotting: boolean) {
    this.noteService.screenshotting = screenshotting;
  }

  set presetNoteType(type: NoteTypeEnum) {
    this.noteService.presetNoteType = type;
  }

  // --------------------------------------------- //
  // ------------- PRIVATE FUNCTIONS ------------- //
  // --------------------------------------------- //

  private isJumpToQuestionDisabled(jumpQuestionGroupId): boolean {
    const isDrawNoteJumpDisabled =
      this.type === NoteTypeEnum.drawing &&
      this.viewProperties &&
      this.contextService.currentMode !== this.viewProperties.consultationMode;

    return !jumpQuestionGroupId || this.disabled || isDrawNoteJumpDisabled;
  }

  private updateCustomCursor() {
    const toolBorderColor = this.currentTool === this.drawingTool.Pen ? '#ffffff' : 'var(--color-grey-400)';
    const currentCursorClass = this.getCursorClass();

    this.renderer.setStyle(this.customCursor.nativeElement, 'background-color', this.footerCurrentColor);
    this.renderer.setStyle(this.customCursor.nativeElement, 'border-width', this.cursorBorderWidth);
    this.renderer.setStyle(this.customCursor.nativeElement, 'border-color', toolBorderColor);

    this.customCursor.nativeElement
      .getAttribute('class')
      .split(' ')
      .forEach((x: string) => {
        if (x.includes(`-size-`)) {
          this.renderer.removeClass(this.customCursor.nativeElement, x);
        }
      });
    this.renderer.addClass(this.customCursor.nativeElement, currentCursorClass);
  }

  private calcCanvasSize(delay = 50, maxTries = 10, skipDrawing = false) {
    if (maxTries <= 0) {
      return;
    }
    const element = this.canvas.nativeElement;

    timer(delay)
      .pipe(take(1))
      .subscribe(x => {
        element.style.width = '100%';
        element.style.height = '100%';
        element.width = element.offsetWidth || element.parentElement.offsetWidth;
        element.height = element.offsetHeight || element.parentElement.offsetHeight;

        if (element.width === 0 || element.height === 0) {
          this.calcCanvasSize(50, maxTries - 1);
          return;
        }

        if (Number.isNaN(element.width) || Number.isNaN(element.height)) {
          this.calcCanvasSize(50, maxTries - 1);
          return;
        }
        if (this.drawingInfo && !skipDrawing) {
          this.draw(true);
        }
      });
  }

  private async unsavedChangesDialog(): Promise<boolean> {
    return new Promise(async resolve => {
      let data;
      if (this.noteService?.presetNoteType === NoteTypeEnum.text && this.text === null) {
        data = {
          headingText: 'Notiz löschen?',
          copyText: 'Eine leere Notiz kann nicht gespeichert werden.',
          denyText: 'Verwerfen',
          confirmText: 'Löschen',
          context: 'Notizen',
        } as DialogConfirmData;
      } else if (!this.showTextLengthError) {
        data = {
          headingText: 'Änderungen speichern?',
          copyText: 'Die aktuelle Notiz enthält noch ungesicherte Änderungen.',
          denyText: 'Verwerfen',
          confirmText: 'Speichern',
          context: 'Notizen',
        } as DialogConfirmData;
      } else {
        data = {
          headingText: 'Hoppla, der Text ist zu lang',
          copyText: `Bitte kürzen Sie die aktuelle Notiz auf ${this.maxLength} Zeichen oder weniger ein!`,
          denyText: 'Verwerfen',
          confirmText: 'Einkürzen',
          context: 'Notizen',
        } as DialogConfirmData;
      }
      let dialogRef = this.dialogService.openDialogConfirm(data);
      const result = await lastValueFrom(dialogRef.afterClosed());
      dialogRef = null;

      if (this.noteService?.presetNoteType === NoteTypeEnum.text && this.text === null) {
        if (result?.confirmed) {
          await this.deleteNote();
        } else {
          if (this.noteService.requestNoteChange.getValue() !== '-1') {
            await this.noteService.getSingleNote(this.noteService.requestNoteChange.getValue());
          }
        }
        resolve(result?.confirmed || false);
      } else if (!this.showTextLengthError) {
        if (result?.confirmed) {
          await this.saveNote();
        } else {
          if (this.noteService.requestNoteChange.getValue() !== '-1') {
            await this.noteService.getSingleNote(this.noteService.requestNoteChange.getValue());
          }
        }
        resolve(result?.confirmed || false);
      } else {
        if (result?.confirmed === false) {
          resolve(result?.confirmed);
        }
      }
    });
  }

  private getPageNote(currentNotes: Notes.GetAllNotesResponse): Notes.NoteByPageReference {
    return currentNotes.notesByPageReference.find(
      note =>
        note.pageReference === NoteContextEnum.topicSelection &&
        (note.noteType === this.noteService?.presetNoteType || !this.noteService?.presetNoteType)
    );
  }

  private getTopicNote(currentNotes: Notes.GetAllNotesResponse): Notes.TopicNoteDTO {
    this.currentConsultationMode =
      this.contextService.currentMode === 'main' ? NoteOriginEnum.consultation : NoteOriginEnum.preparation;

    return currentNotes.notesByTopic
      .flatMap(x => x.notes.flatMap(y => y))
      .find(note => {
        return (
          note.questionGroupId === this.currentQuestionGroupId &&
          note.noteOrigin === this.currentConsultationMode &&
          (note.noteType === this.noteService?.presetNoteType || !this.noteService?.presetNoteType)
        );
      });
  }

  private addClick(x: number, y: number, dragging: boolean) {
    const size = this.footerCurrentSize;
    const pen = this.currentTool === DrawingTool.Eraser ? 0 : 1;
    this.drawingInfo.push([x, y, dragging ? 1 : 0, this.currentColorIndex, size, pen]);
    this.saved = false;
  }

  private draw(drawAll = false) {
    if (!this.context) {
      return;
    }

    if (drawAll) {
      this.context?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }

    this.context.lineJoin = 'round';
    this.context.lineWidth = this.footerCurrentSize;

    this.drawingInfo.forEach(([x, y, drag, clickColor, size, pen], index) => {
      if (drawAll || index >= this.drawingInfo.length - 1 || this.drawingInfo.length < 2) {
        this.context.beginPath();
        this.context.lineWidth = size;
        this.context.globalCompositeOperation = pen === 0 ? 'destination-out' : 'source-over';
        if (drag === 1 && index) {
          this.context.moveTo(this.drawingInfo[index - 1][0], this.drawingInfo[index - 1][1]);
        } else {
          this.context.moveTo(x - 1, y - 1);
        }
        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.strokeStyle = this.colorOptions[clickColor];
        this.context.stroke();
      }
    });
  }

  private setBrushSize() {
    if (this.currentTool === DrawingTool.Eraser) {
      if (this.footerCurrentSize <= 20) {
        this.footerCurrentSize = this.footerCurrentSize * 5;
      }
    } else if (this.currentTool === DrawingTool.Pen) {
      if (this.footerCurrentSize > 20) {
        this.footerCurrentSize = this.footerCurrentSize / 5;
      }
    }
    this.updateCustomCursor();
  }

  private handleBlur(): void {
    if (this.input) {
      this.input.nativeElement.blur();
    }
  }

  private handleNewDrawing(res) {
    if (!res) {
      this.resetInfo();
      return;
    }
    return JSON.parse(res.drawing);
  }

  private resetInfo(): void {
    this.drawingInfo = undefined;
    this.text = '';
    this.lastText = '';
    this.lastDrawing = undefined;
    this.type = undefined;
  }

  public getCursorClass(): string {
    if (this.footerCurrentSize === 5) {
      return 'pen-size-s';
    } else if (this.footerCurrentSize === 10) {
      return 'pen-size-m';
    } else if (this.footerCurrentSize === 20) {
      return 'pen-size-l';
    } else if (this.footerCurrentSize === 25) {
      return 'eraser-size-s';
    } else if (this.footerCurrentSize === 50) {
      return 'eraser-size-m';
    } else if (this.footerCurrentSize === 100) {
      return 'eraser-size-l';
    }
    return 'pen-size-s';
  }

  private cursorOffset(type: 'x' | 'y'): number {
    let offsetX = 0;
    let offsetY = 0;
    if (this.footerCurrentSize === CurrentSizeEnum.PenS) {
      offsetX = -4;
      offsetY = -3;
    } else if (this.footerCurrentSize === CurrentSizeEnum.PenM) {
      offsetX = -6;
      offsetY = -5;
    } else if (this.footerCurrentSize === CurrentSizeEnum.PenL) {
      offsetX = -11;
      offsetY = -10;
    } else if (this.footerCurrentSize === CurrentSizeEnum.EraserS) {
      offsetX = -14;
      offsetY = -13;
    } else if (this.footerCurrentSize === CurrentSizeEnum.EraserM) {
      offsetX = -26;
      offsetY = -26;
    } else if (this.footerCurrentSize === CurrentSizeEnum.EraserL) {
      offsetX = -51;
      offsetY = -51;
    }

    return type === 'x' ? offsetX : offsetY;
  }

  // compares the drawing infos
  // prevents subsequent clicks on save-button from taking screenshots if nothing changed
  private drawingsAreEqual(a, b) {
    const flattenedA = a.flat();
    const flattenedB = b.flat();

    // this does not take into account that the actual points might not be in the same order
    // but maybe this isn't a real world use case
    let countA: number = 0;
    for (const item in flattenedA) {
      countA += parseInt(item, 10);
    }
    let countB: number = 0;
    for (const item in flattenedA) {
      countB += parseInt(item, 10);
    }

    return flattenedA.length === flattenedB.length && countA === countB;
  }
}
