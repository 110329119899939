@if (show === recommendationShowEnum.side) {
  <aside class="d-flex overflow-hidden app-height">
    <section class="available-side d-flex flex-column" [class.side-footer-margin]="useMargin">
      <div class="rec-header">
        <h2 class="mt-2 mb-3 ml-1 mr-2 flex-grow-1">Weitere Lösungen</h2>
      </div>
      @for (composition of recCompositions; track composition) {
        <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200 panel-scroll">
          <mat-expansion-panel-header expandedHeight="4rem" collapsedHeight="4rem">
            <mat-panel-title>
              <strong class="font-weight-bold">{{ composition }}</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            @for (rec of recByComposition(composition); track rec) {
              <div class="d-flex align-items-center justify-content-between subtopic-deselect-container">
                <div class="d-flex flex-column w-100">
                  <h4
                    [debugId]="rec.compositionId"
                    class="mat-body-2 font-weight-bold composition-title mb-0"
                    [innerHtml]="rec.compositionName | safeHtml"
                  ></h4>
                  <div class="rec-button">
                    <mat-checkbox kfFocus [checked]="recContains(rec)" (click)="onRecToggle(rec)" color="grey">
                      @switch (rec.type) {
                        @case (recommendationTypeEnum.task) {
                          <kf-icon
                            class="flex-shrink-0 subtopic-icon ml-2"
                            name="ic_person_mit_kreis_24"
                            aria-hidden="true"
                            [kfTooltip]="'Maßnahme'"
                            [kfTooltipDisabled]="isIPad"
                            kfTooltipPosition="after"
                            kfTooltipShow="hover"
                          ></kf-icon>
                        }
                        @case (recommendationTypeEnum.transition) {
                          <kf-icon
                            class="flex-shrink-0 subtopic-icon mr-2"
                            name="ic_personen_paar_24"
                            aria-hidden="true"
                            [kfTooltip]="'Expertengespräch'"
                            [kfTooltipDisabled]="isIPad"
                            kfTooltipPosition="after"
                            kfTooltipShow="hover"
                          ></kf-icon>
                        }
                        @case (recommendationTypeEnum.subtopic) {
                          <kf-icon
                            class="flex-shrink-0 subtopic-icon mr-2"
                            name="ic_sprechblase_fragezeichen_ausrufezeichen_24"
                            aria-hidden="true"
                            [kfTooltip]="'Unterthema'"
                            [kfTooltipDisabled]="isIPad"
                            kfTooltipPosition="after"
                            kfTooltipShow="hover"
                          ></kf-icon>
                        }
                        @default {
                          <kf-icon
                            class="flex-shrink-0 subtopic-icon mr-2"
                            name="ic_allgemeine_karte_24"
                            aria-hidden="true"
                            [kfTooltip]="'Produkt'"
                            [kfTooltipDisabled]="isIPad"
                            kfTooltipPosition="after"
                            kfTooltipShow="hover"
                          ></kf-icon>
                        }
                      }
                      <span [debugId]="rec.id" class="label-text" [innerHtml]="rec.name | safeHtml"></span>
                    </mat-checkbox>
                    <div class="rec-badge">
                      @if (rec.recommended) {
                        <div class="tag-info tag-neutral">
                          <div class="tag-info-text">Empfohlen</div>
                        </div>
                      }
                    </div>
                  </div>

                  @if (rec.type === recommendationTypeEnum.subtopic) {
                    <button
                      mat-stroked-button
                      color="primary"
                      class="mx-3 add2agenda-button"
                      aria-label="Zur aktuellen Beratung hinzufügen"
                      (click)="addSubtopicToAgenda(rec)"
                    >
                      <span>Zur aktuellen Beratung hinzufügen</span>
                    </button>
                  }
                </div>

                <button
                  class="info-button"
                  (click)="showRecommendationDetails(rec, recContains(rec))"
                  aria-label="Details anzeigen"
                  [kfTooltip]="'Details zu: ' + rec.name"
                  [kfTooltipDisabled]="isIPad"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                  mat-icon-button
                >
                  <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
                </button>
              </div>
            }
          </ng-template>
        </mat-expansion-panel>
      }
    </section>

    <div class="line-left overflow-auto" [class.side-footer-margin]="useMargin">
      <side-recommendations
        class="ml-3"
        [subtopicId]="subtopicId"
        [collectionId]="collectionId"
        [recommendationGroupData]="recommendationGroupData"
        [sideOpen]="true"
        (sideRecommendationsUpdated)="refreshRecommendations($event)"
      ></side-recommendations>
    </div>
  </aside>
}

@switch (show) {
  @case (recommendationShowEnum.task) {
    <side-task-indiv [inputValues]="{ taskId: '-2', subtopicId: this.subtopicId, sideOpen: true }"></side-task-indiv>
  }
  @case (recommendationShowEnum.transition) {
    <side-transition-indiv
      [inputValues]="{ transitionId: '-2', subtopicId: this.subtopicId, sideOpen: true }"
    ></side-transition-indiv>
  }
  @case (recommendationShowEnum.infoProduct) {
    <side-product-details [inputValues]="details"></side-product-details>
  }
  @case (recommendationShowEnum.infoTask) {
    <side-task [inputValues]="details"></side-task>
  }
  @case (recommendationShowEnum.infoTransition) {
    <side-transition [inputValues]="details"></side-transition>
  }
  @case (recommendationShowEnum.infoContentElement) {
    <side-content-element [inputValues]="details"></side-content-element>
  }
}
