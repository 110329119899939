/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

import {
  ButtonType,
  ChildrenOptions,
  ComponentType,
  DrawingControlType,
  ElementConfig,
  FooterConfig,
  FooterSectionType,
} from '@de.fiduciagad.kbm/shared-footer-lib';

export const drawingColorOptions = ['#2766ad', '#ff6600', '#cf0c0c', '#659b41', '#ffcf33', '#000000'];
export const drawingSizeOptions = ['20px', '10px', '5px'];

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const buttonDrawingSave: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'drawing-save',
    config: {
      type: ButtonType.FLAT,
      label: 'Speichern',
    },
  },
};

export const buttonDrawingRevert: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'drawing-revert',
    config: {
      type: ButtonType.STROKED,
      label: 'Zurücksetzen',
      icon: 'ic_zuruecksetzen_24',
      iconPosition: 'before',
    },
  },
};

export const buttonDrawingDelete: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'drawing-delete',
    config: {
      type: ButtonType.FLAT,
      label: 'Löschen',
      icon: 'ic_loeschen_24',
      iconPosition: 'before',
    },
  },
};

export const buttonNewNote: ElementConfig = {
  type: ComponentType.MENU_BUTTON,
  inputs: {
    owner: 'bgzv',
    children: [
      {
        action: 'notes-new-drawing',
        config: {
          label: 'als Zeichnung',
          icon: 'ic_annotation_zeichnen_24',
          iconPosition: 'before',
        },
      },
      {
        action: 'notes-new-note',
        config: {
          label: 'als Text',
          icon: 'ic_notiz_24',
          iconPosition: 'before',
        },
      },
    ],
    config: {
      type: ButtonType.FLAT,
      label: 'Neue Notiz',
      icon: 'ic_plus_24',
      iconPosition: 'after',
    },
  },
};

/// ------------------------------------------------ ///
/// --------------- DRAWING BUTTONS ---------------- ///
/// ------------------------------------------------ ///

export const buttonOpacitySlider: ChildrenOptions = {
  action: 'drawing-opacity',
  config: {
    type: DrawingControlType.SLIDER,
    tooltip: 'Transparenz ändern, aktuell',
    initialValue: 40,
    ariaLabel: 'opacity',
    dataAutomationId: 'opacity-slider',
  },
};
export const buttonColorPicker: ChildrenOptions = {
  action: 'drawing-color-picker',
  config: {
    type: DrawingControlType.COLOR_PICKER,
    tooltip: 'Zeichnen',
    options: [...drawingColorOptions],
    initialValue: drawingColorOptions[0],
  },
};
export const buttonEraser: ChildrenOptions = {
  action: 'drawing-eraser-activated',
  config: {
    type: DrawingControlType.ERASER,
    tooltip: 'Radiergummi',
    ariaLabel: 'eraser',
    dataAutomationId: 'eraser',
  },
};
export const buttonSizePicker: ChildrenOptions = {
  action: 'drawing-line-width',
  config: {
    type: DrawingControlType.LINE_WIDTH,
    tooltip: 'Strichstärke wählen',
    ariaLabel: 'line-width-picker',
    dataAutomationId: 'line-width-picker',
    options: [...drawingSizeOptions],
    initialValue: drawingSizeOptions[2],
  },
};

export const drawingTools: ElementConfig = {
  type: ComponentType.DRAWING_TOOLS,
  inputs: {
    owner: 'bgzv',
    children: [buttonOpacitySlider, buttonColorPicker, buttonEraser, buttonSizePicker],
  },
};

export const drawingToolsNoOpacity: ElementConfig = {
  type: ComponentType.DRAWING_TOOLS,
  inputs: {
    owner: 'bgzv',
    children: [buttonColorPicker, buttonEraser, buttonSizePicker],
  },
};

/// ------------------------------------------------ ///
/// ---------------- CONFIGURATIONS ---------------- ///
/// ------------------------------------------------ ///

export const baseConfigWithDrawing: FooterConfig = {
  drawing: {
    isHidden: false,
    type: FooterSectionType.HIDDEN,
    elements: [drawingTools, buttonDrawingDelete, buttonDrawingRevert, buttonDrawingSave],
  },
};

export const baseConfigCollapseWithDrawing: FooterConfig = {
  leftCollapsable: {
    isHidden: false,
    width: '21rem',
    type: FooterSectionType.COLLAPSIBLE,
    elements: [buttonNewNote],
  },
  drawing: {
    isHidden: false,
    type: FooterSectionType.HIDDEN,
    elements: [buttonDrawingDelete, buttonDrawingRevert, buttonDrawingSave],
  },
};

export const baseConfigCollapse: FooterConfig = {
  leftCollapsable: {
    isHidden: false,
    width: '21rem',
    type: FooterSectionType.COLLAPSIBLE,
    elements: [buttonNewNote],
  },
  left: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
  },
  right: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [buttonDrawingDelete],
  },
};

export const baseConfigCollapseNoNote: FooterConfig = {
  leftCollapsable: {
    isHidden: false,
    width: '21rem',
    type: FooterSectionType.COLLAPSIBLE,
    elements: [buttonNewNote],
  },
  left: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
  },
  right: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
};
