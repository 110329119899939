import { Injectable } from '@angular/core';
import { SelectForPreliminaryRequestItem, SubtopicForPreliminaryItem } from '@domain/app/consultation.domain';
import {
  SelectedTopicItem,
  SubtopicOverviewItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { MediaService } from '@services/media-service/media.service';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  constructor(private mediaService: MediaService) {}

  private _firstQuestionGroupId = '-1';
  private _agendaTopicClicked: string = '-1';

  private _selectedTopicsData: TopicOverviewItem[];
  private _alreadySelectedTopicItems: SelectedTopicItem[];

  private _subtopicData: SubtopicSelectionResponse;
  private _selectedSubtopicData: SubtopicOverviewItem[];

  public topicMediaIcons: Map<string, string> = new Map<string, string>();

  public get firstQuestionGroupId() {
    return this._firstQuestionGroupId;
  }

  public set firstQuestionGroupId(value) {
    this._firstQuestionGroupId = value;
  }

  public set agendaTopicClicked(topicId: string) {
    this._agendaTopicClicked = topicId;
  }

  public get agendaTopicClicked(): string {
    return this._agendaTopicClicked;
  }

  public get selectedTopicsData(): TopicOverviewItem[] {
    return this._selectedTopicsData;
  }
  public set selectedTopicsData(selectedTopics: TopicOverviewItem[]) {
    const cmp = (a: any, b: any, direction: 'asc' | 'desc' = 'asc') => {
      const sign = direction === 'asc' ? 1 : -1;
      return a > b ? sign * 1 : a < b ? sign * -1 : 0;
    };

    this._selectedTopicsData = selectedTopics;

    if (selectedTopics.length > 0) {
      // we assume there is at least one questionGroup in here
      this.firstQuestionGroupId = '-1';

      const sortedSubtopics =
        selectedTopics[0].subtopics.filter(x => x.selected).sort((a, b) => cmp(a.orderNumber, b.orderNumber, 'asc')) ||
        [];

      if (sortedSubtopics.length > 0) {
        this.firstQuestionGroupId = sortedSubtopics[0]?.questionGroups[0]?.id || '-1';
      }
    } else if (selectedTopics.length === 0) {
      this.firstQuestionGroupId = '-1';
    }
  }

  public updateTopicsData(selectedSubtopicIds: string[]) {
    this._selectedTopicsData.flatMap(x => x.subtopics).map(z => (z.selected = selectedSubtopicIds.includes(z.id)));
  }

  public get alreadySelectedTopicItems(): SelectedTopicItem[] {
    return this._alreadySelectedTopicItems;
  }
  public set alreadySelectedTopicItems(value: SelectedTopicItem[]) {
    this._alreadySelectedTopicItems = value;
  }

  public get subtopicData(): SubtopicSelectionResponse {
    return this._subtopicData;
  }
  public set subtopicData(value: SubtopicSelectionResponse) {
    this._subtopicData = value;
    this.selectedSubtopicData = value.selected;
  }

  public get selectedSubtopicData(): SubtopicOverviewItem[] {
    return this._selectedSubtopicData;
  }
  public set selectedSubtopicData(value: SubtopicOverviewItem[]) {
    this._selectedSubtopicData = value;
  }

  public get selectedTopicReturnObject(): SelectedTopicItem[] {
    return this.topicReturnObjectFactory(undefined);
  }

  public selectedSubTopicDataById(subtopicId: string): SubtopicOverviewItem | undefined {
    return this._selectedTopicsData.flatMap(x => x.subtopics).find(y => y.id === subtopicId);
  }

  public selectedTopicDataBySubtopicId(subtopicId: string): TopicOverviewItem | undefined {
    return this._selectedTopicsData?.find(x => x.subtopics.find(y => y.id === subtopicId));
  }

  public selectedSubTopicDataByQuestionId(questionId: string): SubtopicOverviewItem | undefined {
    return this._selectedTopicsData
      ?.flatMap(x => x.subtopics)
      .find(y => y.questionGroups.find(z => z.id === questionId));
  }

  public consultationSubtopics(topics: SubtopicSelectionResponse): SubtopicSelectionResponse {
    const a = topics.available.filter(x => !x.consultantTopic && !x.availableForPreliminary);
    const s = topics.selected.filter(x => !x.consultantSubtopic && !x.availableForPreliminary);
    return {
      available: a.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
      selected: s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
    } as SubtopicSelectionResponse;
  }

  public consultantSubtopics(topics: SubtopicSelectionResponse): SubtopicSelectionResponse {
    const a = topics.available.filter(x => x.consultantTopic);
    const s = topics.selected.filter(x => x.consultantSubtopic);
    return {
      available: a.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
      selected: s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
    } as SubtopicSelectionResponse;
  }

  public customerSubtopics(topics: SubtopicSelectionResponse): SubtopicSelectionResponse {
    const a = topics.available.filter(x => x.availableForPreliminary);
    const s = topics.selected.filter(x => x.availableForPreliminary);
    return {
      available: a.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
      selected: s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)),
    } as SubtopicSelectionResponse;
  }

  public consultationSelectedSubtopicIds(topics: SubtopicSelectionResponse = this.subtopicData): string[] {
    const s = topics.selected.filter(x => !x.consultantSubtopic && !x.availableForPreliminary);
    return s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)).map(x => x.id);
  }

  public consultantSelectedSubtopicIds(topics: SubtopicSelectionResponse = this.subtopicData): string[] {
    const s = topics.selected.filter(x => x.consultantSubtopic);
    return s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)).map(x => x.id);
  }

  public customerSelectedSubtopicIds(topics: SubtopicSelectionResponse = this.subtopicData): string[] {
    const s = topics.selected.filter(x => x.availableForPreliminary);
    return s.sort((a, b) => this.compare(a.orderNumber, b.orderNumber)).map(x => x.id);
  }

  public selectedSubtopicsAndTopicsMatch(subtopicIds: string[]): boolean {
    const topicIds = this.selectedTopicsData.flatMap(x => x.subtopics).map(x => x.id);

    // case: new topic
    if (subtopicIds.filter(x => !topicIds.includes(x)).length > 0) {
      return false;
    }

    //case: topic removed
    for (const topic of this.selectedTopicsData) {
      if (!topic.subtopics.some(x => subtopicIds.includes(x.id))) {
        return false;
      }
    }

    return true;
  }

  public async mapTopicIcons(topic: TopicOverviewItem[]): Promise<void> {
    topic.forEach(async x => {
      this.topicMediaIcons.set(x.icon?.id, await this.mediaService.getMediaContent(x.icon?.url));
    });
  }

  public getFirstQuestionGroupIdBySubtopicId(subtopicId: string) {
    return this.selectedSubtopicData.find(x => x.id === subtopicId).questionGroups.find(x => x.hidden === false);
  }

  private topicReturnObjectFactory(dataItem: TopicOverviewItem[] | undefined): SelectedTopicItem[] {
    dataItem = !dataItem ? this.selectedTopicsData : dataItem;
    return dataItem.reduce((x: SelectedTopicItem[], y: TopicOverviewItem) => {
      const data = {
        id: y.id,
        selectedSubtopicIds: y.subtopics.reduce((k, l: SubtopicOverviewItem) => {
          if (l.selected) {
            k.push(l.id);
          }
          return k;
        }, []),
      };

      if (data.selectedSubtopicIds.length > 0) {
        x.push(data);
      }
      return x;
    }, []);
  }

  private topicPrepReturnObjectFactory(dataItem: TopicOverviewItem[] | undefined): SelectForPreliminaryRequestItem[] {
    dataItem = !dataItem ? this.selectedTopicsData : dataItem;

    return dataItem.reduce((x: SelectForPreliminaryRequestItem[], y: TopicOverviewItem) => {
      const data = {
        topicId: y.id,
        subtopics: this.subtopicReduce(y.subtopics),
      };

      if (data.subtopics.length > 0) {
        x.push(data);
      }
      return x;
    }, []);
  }

  private subtopicReduce(subtopics: SubtopicOverviewItem[]) {
    return subtopics.reduce((k: SubtopicForPreliminaryItem[], l: SubtopicOverviewItem) => {
      if (l.selectedForPreliminary) {
        const subtopicData = {
          subtopicId: l.id,
          questionGroupIds: this.questionGroupReduce(l.questionGroups),
        };
        k.push(subtopicData);
      }
      return k;
    }, []);
  }

  private questionGroupReduce(questionGroups) {
    return questionGroups.reduce((k: any[], l: any) => {
      if (l.selectedForPreliminary) {
        k.push(l.id);
      }
      return k;
    }, []);
  }

  private createSelectResponse(
    fil: (d: TopicOverviewItem[]) => TopicOverviewItem[],
    topics: TopicSelectionResponse
  ): TopicSelectionResponse {
    return {
      available: fil(topics.available),
      selected: fil(topics.selected),
    } as TopicSelectionResponse;
  }

  private compare(a: any, b: any): number {
    return a > b ? +1 : a < b ? -1 : 0;
  }
}
