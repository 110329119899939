@if (src) {
  @if (isKfImage) {
    <kf-img
      class="answer-option-image"
      [src]="kfImageUrl"
      [width]="width"
      [height]="height"
      [selfHosted]="environment.isLocal"
    ></kf-img>
  } @else if (isSvgImage) {
    <div class="svg-container w-100 h-100" #svgContainer></div>
  } @else {
    <img
      class="answer-option-image"
      [src]="src"
      [alt]="alt"
      [attr.width]="width || null"
      [attr.height]="height || null"
      (load)="onImageLoad()"
    />
  }
} @else if (imageMulti.length > 0) {
  <div class="w-100" [style.height]="enlarged && 'auto'">
    <div
      class="d-flex caroussel-container position-relative"
      [style.width]="enlarged ? enlargeOptions.endWidth : width"
      [style.height]="enlarged ? enlargeOptions.endHeight : height"
    >
      <button
        #element
        tabindex="{{ enlarged && -1 }}"
        kfTooltip="Vorheriges Bild"
        kfTooltipShow="hover"
        kfTooltipPosition="after"
        aria-label="Vorheriges Bild"
        class="d-flex align-items-center chevron-area justify-content-around left"
        [class.enlarged]="enlarged"
        (click)="changeActiveIndex(false)"
        (keyup)="handleKeyup($event, element)"
      >
        <kf-icon name="ic_chevron_links_24"></kf-icon>
      </button>
      <button
        #element
        class="images-container d-flex justify-content-center position-relative"
        kfTooltipShow="hover"
        kfTooltipPosition="after"
        [kfTooltip]="getResizeText('enlarge')"
        [attr.aria-label]="getResizeText('enlarge')"
        [class.enlarged]="enlarged"
        [class.cursor-zoom-in]="enlargeable && !enlarged"
        [class.cursor-zoom-out]="enlargeable && enlarged"
        (click)="onResizeClick('enlarge')"
        (keyup)="handleKeyup($event, element)"
      >
        @if (enlargeable) {
          <div class="image-logo-container">
            <kf-icon [name]="getIconName(enlarged)"></kf-icon>
          </div>
        }

        <!-- Full-width images with number and caption text -->
        @if (isKfImage) {
          <kf-img
            [@simpleSlideAnimation]="animationIndex"
            [@simpleFadeAnimation]="animationIndex"
            class="mySlides"
            [src]="kfImageUrl"
            [selfHosted]="environment.isLocal"
          ></kf-img>
        } @else {
          <div
            [@simpleSlideAnimation]="animationIndex"
            [@simpleFadeAnimation]="animationIndex"
            class="mySlides"
            [style.backgroundImage]="'url(' + multiImageUrl(slideIndex) + ')'"
          ></div>
        }
        <!-- Next and previous buttons -->
      </button>
      <button
        #element
        tabindex="{{ enlarged && -1 }}"
        [class.enlarged]="enlarged"
        (click)="changeActiveIndex(true)"
        (keyup)="handleKeyup($event, element)"
        class="d-flex align-items-center chevron-area justify-content-around right"
        aria-label="Nächstes Bild"
        kfTooltip="Nächstes Bild"
        kfTooltipPosition="before"
        kfTooltipShow="hover"
      >
        <kf-icon name="ic_chevron_rechts_24"></kf-icon>
      </button>
    </div>
  </div>
} @else if (image?.url && !imageError) {
  <button
    #element
    tabindex="0"
    class="question-logo-small mb-4 position-relative"
    kfTooltipShow="hover"
    kfTooltipPosition="after"
    [kfTooltip]="getResizeText('expand')"
    [disabled]="!resizable"
    [ngStyle]="!expanded && { width: width, height: 'auto' }"
    [attr.aria-label]="getResizeText('expand')"
    [class.question-logo-big]="expanded"
    (click)="onResizeClick('expand')"
    (keyup)="handleKeyup($event, element)"
  >
    @if (imageLoaded && resizable) {
      <div class="image-logo-container">
        <kf-icon [name]="getIconName(expanded)"></kf-icon>
      </div>
    }
    @if (isKfImage) {
      <kf-img class="question-logo-content" [src]="kfImageUrl" [selfHosted]="environment.isLocal"></kf-img>
    } @else if (isSvgImage) {
      <div class="svg-container w-100 h-100" #svgContainer></div>
    } @else {
      <img
        class="question-logo-content"
        [src]="singleImageUrl"
        [alt]="image?.altText || ''"
        (load)="onImageLoad()"
        (error)="onImageError()"
      />
    }
  </button>
} @else {
  <div>Es wurde keine gültige Bild-URL angegeben.</div>
}
