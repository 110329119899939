import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Params } from '@angular/router';
import { EnvironmentLookupService } from '@de.fiduciagad.kundenportal/environment-lookup';
import {
  ConsultationSummarySubtopicItem,
  ConsultationSummarySubtopicQuestionGroupItem,
} from '@domain/app/consultation.domain';
import { color, libIcons } from 'bgzv-frontend-library';

@Component({
  selector: 'result-subtopics',
  templateUrl: './result-subtopics.component.html',
  styleUrls: ['./result-subtopics.component.scss'],
})
export class ResultSubtopicsComponent implements OnInit {
  @Input() subtopicData: ConsultationSummarySubtopicItem[];

  @ViewChild('subtopicsPanel') subtopicsPanel: MatExpansionPanel;

  private consultationSummaryId: string = '';

  public error: string = '';
  public panelOpenState: boolean;
  public questionGroupPanelOpen: Array<boolean> = [];
  public questionGroups: ConsultationSummarySubtopicQuestionGroupItem[];
  public subtopicId: string;

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.consultationSummaryId = params['id'];
    });
    /**
     * the first question group should be expanded per default
     * so the first corresponding button should also be active
     */
    this.questionGroups = this.subtopicData && this.subtopicData[0]?.questionGroups;
  }

  public openQuestions(subtopic: ConsultationSummarySubtopicItem): void {
    this.subtopicId = subtopic.contentId;
    this.questionGroups = subtopic.questionGroups;

    this.subtopicsPanel.open();
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }

  public formatDisplayedValue(value: string): string {
    let formatted = value.replace(/#\/#/gm, ' - ');
    formatted = value.replace(/\<br\>/gm, '\n');

    return formatted;
  }
}
