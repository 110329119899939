import { Component, OnInit } from '@angular/core';
import { ConsultationDocumentsResponse } from '@domain/app/media.domain';
import { DocumentUploadedByEnum } from '@enums';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { baseConfigCollapseNoVisibleDocument, baseConfigCollapseWithDocument } from '@utils/footer-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'documents-menu',
  templateUrl: './documents-menu.component.html',
  styleUrls: ['./documents-menu.component.scss'],
  standalone: false,
})
export class DocumentsMenuComponent implements OnInit {
  private destroySubs = new Subject<void>();

  public consultantDocumentList: ConsultationDocumentsResponse[] = [];
  public customerDocumentList: ConsultationDocumentsResponse[] = [];
  public currentDocument: ConsultationDocumentsResponse;

  public fetchingDocumentsPending: boolean;
  public loading: boolean = false;

  constructor(
    private documentService: DocumentService,
    private footerService: SharedFooterService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.documentService.currentDocuments.pipe(takeUntil(this.destroySubs)).subscribe(currentDocuments => {
      this.consultantDocumentList = currentDocuments.filter(x => x.uploadedBy === DocumentUploadedByEnum.consultant);
      this.customerDocumentList = currentDocuments.filter(x => x.uploadedBy === DocumentUploadedByEnum.customer);
    });

    this.documentService.fetchingDocumentsPending
      .pipe(takeUntil(this.destroySubs))
      .subscribe(fetchingDocumentsPending => {
        this.fetchingDocumentsPending = fetchingDocumentsPending;
      });

    this.documentService.currentDocument.pipe(takeUntil(this.destroySubs)).subscribe(currentDocument => {
      this.currentDocument = currentDocument;
      this.documentFooterFactory();
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      if (this.currentDocument) {
        this.documentFooterFactory();
      }
    });

    this.documentService.fetchDocumentList();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
    this.documentService.currentDocument.next(undefined);
  }

  public setCurrentDocument(document: ConsultationDocumentsResponse) {
    this.documentService.currentDocument.next(document);
  }

  public isPdf(fileName: string) {
    return fileName.includes('.pdf');
  }

  public deselectDocument() {
    this.documentService.currentDocument.next(undefined);
  }

  public getTestcafeId(name = '') {
    return `documentsMenu-div-document-${name?.replace(/ /g, '')}`;
  }

  private documentFooterFactory() {
    if (!this.currentDocument) {
      this.footerService.submitFooterConfig(baseConfigCollapseNoVisibleDocument);
      this.footerService.setCollapsibleButtonVisibility(false);

      return;
    } else {
      this.footerService.submitFooterConfig(baseConfigCollapseWithDocument);
      this.footerService.setCollapsibleButtonVisibility(false);
    }
  }
}
