<div class="number-input-wrapper" [class.disabled]="disabled" [attr.data-testcafe]="testcafeId('div')">
  <form [formGroup]="formGroup" (submit)="onSubmit()">
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input
        #input
        matInput
        [formControlName]="formControlName"
        (keydown)="checkKey($event)"
        (change)="handleChange($event)"
      />
    </mat-form-field>
  </form>

  <div class="unit-button-group">
    <span>{{ unit }}</span>
    @if (stepValue) {
      <div class="btn-group">
        <button
          class="btn btn-primary"
          kfTooltipShow="hover"
          kfTooltipPosition="after"
          [kfTooltip]="showTooltip ? tooltipMinus : ''"
          [tabindex]="0"
          [disabled]="disabledMin"
          [attr.aria-label]="tooltipMinus"
          [attr.aria-disabled]="disabledMin"
          [attr.data-testcafe]="testcafeId('minus')"
          (click)="setStep(-1)"
        >
          <kf-icon name="ic_minuszeichen_24"></kf-icon>
        </button>
        <button
          class="btn btn-primary"
          kfTooltipShow="hover"
          kfTooltipPosition="after"
          [kfTooltip]="showTooltip ? tooltipPlus : ''"
          [tabindex]="0"
          [disabled]="disabledMax"
          [attr.aria-label]="tooltipPlus"
          [attr.aria-disabled]="disabledMax"
          [attr.data-testcafe]="testcafeId('plus')"
          [disabled]="disabledMax"
          (click)="setStep(1)"
        >
          <kf-icon name="ic_pluszeichen_24"></kf-icon>
        </button>
      </div>
    }
  </div>
</div>
