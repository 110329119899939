<div class="overflow-auto vh-100">
  <header class="container d-flex justify-content-between align-items-baseline">
    <kf-headline
      tabindex="-1"
      headline="Beratungsergebnisse"
      [showBackButton]="showResultSummary"
      [backButtonAriaLabel]="'Zurück'"
      (onBackButtonClick)="goBackFromSummary()"
    ></kf-headline>
  </header>

  <div class="container mb-4" style="padding-bottom: 64px">
    @if (summary) {
      <kf-tile [interactive]="false">
        <aside>
          <result-header [customerData]="summary.customer" [consultationData]="summary.consultation"> </result-header>
          <div class="button-row-content button-row mt-4">
            @if (summary.notesAndDocumentsPdfCreated) {
              <!-- notes and docs -->
              <button
                mat-stroked-button
                color="primary"
                [attr.data-testcafe]="'screenResult-button-notes-and-documents-' + getTestcafeId('NotizenUndDokumente')"
                (click)="openNotesAndDocumentsDocument()"
              >
                <span>Notizen & Dokumente</span>
                <kf-icon name="ic_herunterladen_24"></kf-icon>
              </button>
            }
            @if (showResult || showResultSummary) {
              <!-- whole consultation result-->
              <button
                mat-flat-button
                color="primary"
                [attr.data-testcafe]="'screenResult-button-downloadDocuments'"
                (click)="openConfirmationDocument()"
              >
                <span>Beratungsergebnis herunterladen</span>
                <kf-icon name="ic_herunterladen_24"></kf-icon>
              </button>
            }
          </div>
        </aside>
      </kf-tile>

      <div class="mt-4">
        <kf-tile [interactive]="false">
          <h2>Beratungsinhalte</h2>
          <result-subtopics class="mt-4" [subtopicData]="summary.subtopics"></result-subtopics>
        </kf-tile>
      </div>

      <div class="mt-4">
        <kf-tile [interactive]="false">
          <h2>Lösungen</h2>
          <result-products
            [productData]="summary.products"
            [customerData]="summary.customer"
            [contractStatus]="contractStatus"
          ></result-products>
          <result-display type="task" [data]="summary.tasks"></result-display>
          <result-transitions [data]="summary.transitions"></result-transitions>
          <result-display type="thema" [data]="summary.themas"></result-display>
        </kf-tile>
      </div>
    } @else {
      <mat-progress-spinner
        mode="indeterminate"
        class="m-auto"
        [color]="'primary'"
        [diameter]="90"
      ></mat-progress-spinner>
    }
  </div>
</div>
