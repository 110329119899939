<div class="d-flex app-height" style="width: 60vw; max-width: 800px">
  <div class="d-flex flex-column h-100 position-relative">
    <!-- You should not see this -->
    <div class="mt-5 mx-4 mb-3 text-disable-hyphens">Diese Funktion steht derzeit nicht zur Verfügung.</div>
    <!--
    --- DEPRECATED code due to prelim deactivation
    --- Left in for potential reactivation
    <h2 [debugId]="clientService.consultationStatus" [innerHTML]="'Vorbefragung'" class="mx-4 mt-5"></h2>
    @if (clientService.consultationStatus === consultationStatusEnum.inPreparation) {
      <div class="mx-4 mb-3 text-disable-hyphens">
        Bitte wählen Sie unten die Fragen und Themen aus, welche Sie dem Kunden in Vorbereitung auf das
        Beratungsgespräch vorab zusenden wollen.
      </div>
    } @else {
      <div class="mx-4 mb-3 text-disable-hyphens">
        Hier finden Sie die Themen, die für die Vorbereitung ausgewählt wurden.
      </div>
    }
    <div class="d-flex flex-column flex-scroll w-100 mb-4" style="height: 65%">
      @for (item of menuData; track item; let i = $index) {
        @if (
          (!item.consultantTopic && clientService.consultationStatus !== consultationStatusEnum.inPreparation) ||
          clientService.consultationStatus === consultationStatusEnum.inPreparation
        ) {
          <item-menu-structure
            class="w-100 mb-2"
            [data]="item"
            [useIndex]="true"
            [index]="i"
            [displayType]="
              clientService.consultationStatus === consultationStatusEnum.inPreparation ? 'select-prep' : 'check'
            "
            (subItemSelected)="handlePrepSubtopicSelection($event)"
            (groupItemSelected)="handlePrepQuestionGroupSelection($event)"
            (questionItemSelected)="handlePrepQuestionSelection($event)"
          ></item-menu-structure>
        }
      }
    </div>
    <footer class="d-flex flex-column mx-4" [ngClass]="useMargin ? 'footer-margin' : 'mb-5'">
      @if (!prepDataSent) {
        @if (prepToggleMode === 'prep') {
          <mat-form-field class="notranslate">
            <mat-label>Hinweis für den Kunden hinzufügen (optional)</mat-label>
            <input [formControl]="noteControl" matInput type="text" autocomplete="off" />
          </mat-form-field>
        }
        <div class="d-flex justify-content-end">
          <button
            [attr.data-testcafe]="'sideConsultation-button-sendPrelim'"
            [disabled]="sendPrelimDisabledState"
            mat-flat-button
            color="primary"
            (click)="onSendPrelim()"
          >
            <span>Vorbefragung versenden</span>
          </button>
        </div>
      } @else if (prepDataSent && currentMode !== 'main') {
        <div class="tag-info tag-positive ml-3">
          <div class="tag-info-text">Vorbefragung versendet</div>
        </div>
      }
    </footer>
  --></div>
  <button
    [attr.data-testcafe]="'sideConsultation-button-close'"
    class="kf-popup-close"
    mat-icon-button
    (click)="handleClose()"
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</div>
