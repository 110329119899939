<div class="d-flex app-height" style="width: 60vw; max-width: 800px">
  <div class="d-flex flex-column h-100 position-relative">
    <h2 [debugId]="clientService.consultationStatus" [innerHTML]="'Vorbefragung'" class="mx-4 mt-5"></h2>
    <div
      *ngIf="clientService.consultationStatus === consultationStatusEnum.inPreparation; else mainMessage"
      class="mx-4 mb-3 text-disable-hyphens"
    >
      Bitte wählen Sie unten die Fragen und Themen aus, welche Sie dem Kunden in Vorbereitung auf das Beratungsgespräch
      vorab zusenden wollen.
    </div>
    <div class="d-flex flex-column flex-scroll w-100 mb-4" style="height: 65%">
      <ng-container *ngFor="let item of menuData; let i = index">
        <ng-container
          *ngIf="
            (!item.consultantTopic && clientService.consultationStatus !== consultationStatusEnum.inPreparation) ||
            clientService.consultationStatus === consultationStatusEnum.inPreparation
          "
        >
          <item-menu-structure
            class="w-100 mb-2"
            [data]="item"
            [useIndex]="true"
            [index]="i"
            [displayType]="
              clientService.consultationStatus === consultationStatusEnum.inPreparation ? 'select-prep' : 'check'
            "
            [isDisabled]="isRequestPending"
            [options]="itemMenuOptions"
            (subItemSelected)="handlePrepSubtopicSelection($event)"
            (groupItemSelected)="handlePrepQuestionGroupSelection($event)"
            (questionItemSelected)="handlePrepQuestionSelection($event)"
          ></item-menu-structure>
        </ng-container>
      </ng-container>
    </div>
    <footer class="d-flex flex-column mx-4" [ngClass]="useMargin ? 'footer-margin' : 'mb-5'">
      <ng-container *ngIf="!prepDataSent">
        <mat-form-field class="notranslate" *ngIf="prepToggleMode === 'prep'">
          <mat-label>Hinweis für den Kunden hinzufügen</mat-label>
          <input [formControl]="noteControl" matInput type="text" />
        </mat-form-field>
        <div class="d-flex justify-content-end">
          <button
            [attr.data-testcafe]="'sideConsultation-button-sendPrelim'"
            [disabled]="sendPrelimDisabledState"
            mat-flat-button
            color="primary"
            (click)="onSendPrelim()"
          >
            <span>Vorbefragung versenden</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="prepDataSent && currentMode !== 'main'">
        <div class="tag-info tag-positive ml-3">
          <div class="tag-info-text">Vorbefragung versendet</div>
        </div>
      </ng-container>
    </footer>
  </div>
  <button
    [attr.data-testcafe]="'sideConsultation-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="handleClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</div>

<!-- TEMPLATES -->
<ng-template #mainMessage>
  <div class="mx-4 mb-3 text-disable-hyphens">
    Hier finden Sie die Themen, die für die Vorbereitung ausgewählt wurden.
  </div>
</ng-template>
