import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CustomerContactRequestItem, CustomerContactResponseItem, ExpertContactItem } from '@domain/app/contact.domain';
import { ContactForm, OptionData, TransitionFormIndividual } from '@domain/app/forms.domain';
import { AssignIndividualTransitionRequest, TransitionResponse } from '@domain/app/transition.domain';
import { ExpertContactTypeEnum, RoutingPathMain, RoutingPathOverlay } from '@enums';
import {
  Appointment,
  TaskAndTransitionInput,
  TaskAndTransitionStateEnum,
  formEmailPattern,
} from '@marginals/transition-task-utils';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { getContactSuffix } from '@utils/helpers/getContactSuffix';
import { trimDropdownLabel } from '@utils/helpers/trimDropdownLabel';
import { formOfAddress } from '@utils/settings';
import { FormType, color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import { NgxMaterialTimepickerComponent, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'side-transition-indiv',
  templateUrl: './side-transition-indiv.component.html',
  styleUrls: ['./side-transition-indiv.component.scss'],
})
export class SideTransitionIndivComponent implements OnInit {
  @Input() inputValues: TaskAndTransitionInput;
  @Output() closed = new EventEmitter(null);

  private _animationSub: Subscription;
  private _actionSub: Subscription;

  private allExpertsList: Array<ExpertContactItem> = [];

  public selectedExpertContact: { id: string; type: ExpertContactTypeEnum };

  public transitionData: TransitionResponse;
  public transitionSendObj: AssignIndividualTransitionRequest;

  public expertList: OptionData[] = [];
  public contactsList: OptionData[] = [];
  public existingCustomerContacts: CustomerContactResponseItem[];
  public customerContact: CustomerContactRequestItem;

  public transitionState = TaskAndTransitionStateEnum;
  public state = TaskAndTransitionStateEnum.default;

  public appointmentIndex = 0;
  public appointmentArray: Appointment[] = [];
  public tempAppointment: Appointment;

  public contactsFormOfAddress = formOfAddress;

  public moment = moment;
  public isNewTransition = true;
  public minimumDate: Date;
  public sendEmailChecked = false;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly formType = FormType;

  bg2Theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: this.color.find(color.name.Neutral100),
      buttonColor: this.color.find(color.name.BrandPrimary),
    },
    dial: {
      dialBackgroundColor: this.color.find(color.name.BrandPrimary),
    },
    clockFace: {
      clockFaceBackgroundColor: this.color.find(color.name.Neutral200),
      clockHandColor: this.color.find(color.name.BrandPrimary),
      clockFaceTimeInactiveColor: this.color.find(color.name.Information),
    },
  };

  public contactForm: FormGroup<ContactForm>;
  public transitionForm: FormGroup<TransitionFormIndividual>;
  public contactSubmitted = false;
  readonly formEmailPattern = formEmailPattern;

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService,
    private formBuilder: FormBuilder,
    private chg: ChangeDetectorRef,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnInit(): void {
    this.transitionSendObj = {
      name: null,
      id: null,
      subtopicId: null,
      expertContact: {} as ExpertContactItem,
      individualNote: '',
      customerContact: {} as CustomerContactRequestItem,
      description: '',
    } as AssignIndividualTransitionRequest;

    this.minimumDate = moment({}).toDate();
    this.isNewTransition = this.inputValues.transitionId === '-1';

    this.contactForm = this.formBuilder.group({
      formOfAddress: new FormControl<string | null>('', Validators.required),
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.formEmailPattern)])
      ),
    });

    this._animationSub = this.rightSidenavService.instance._animationStarted.subscribe(x => {
      if (x.fromState === 'void' && x.toState === 'open') {
        this.isNewTransition ? this.factorInitialValue() : this.queryTransitionData();
        this._animationSub.unsubscribe();
      }
    });

    if (this.inputValues.transitionId === '-2') {
      //transitionId has to be -1 here, we track where we come from with -2
      this.inputValues.transitionId = '-1';
      this.isNewTransition = true;
      this.factorInitialValue();
    }

    this.transitionForm = this.formBuilder.group({
      name: new FormControl<string | null>('', Validators.required),
      expertContact: new FormControl<string | null>('', Validators.required),
      customerContact: new FormControl<string | null>('', Validators.required),
      individualNote: new FormControl<string | null>(''),
    });
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  public handleClose(): void {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.closed.emit();
      this.rightSidenavService.close();
    }
  }

  public onTransitionName(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target) {
      this.transitionSendObj.name = target.value;
    }
  }

  public onDescriptionAdded(description: string) {
    this.transitionSendObj.description = description;
  }

  public isInvalidDate(appointent: Appointment): boolean {
    return new Date(appointent.appointmentStart) <= new Date();
  }

  /**
   * opens DateTimePicker component and prevents opening to component multiple times
   * @param ref
   * @param event
   */
  public openDatepicker(ref: NgxMaterialTimepickerComponent, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    ref.open();
  }

  get contactFormControls() {
    return this.contactForm.controls;
  }

  get transitionFormControls() {
    return this.transitionForm.controls;
  }

  get hasInvalidAppointment(): boolean {
    if (this.appointmentArray && this.appointmentArray.length > 0) {
      return this.appointmentArray.some(x => new Date(x.appointmentStart) <= new Date());
    } else {
      return false;
    }
  }

  // --------------------------------------------- //
  // ---------- DEFAULT TEMPLATE FUNCTIONS ------- //
  // --------------------------------------------- //

  public onExpertSelected(event: MatSelectChange) {
    const id = event.value;
    const selectedExpert = this.allExpertsList.find(x => x.id === id);
    this.selectedExpertContact = { id: id, type: selectedExpert.contactType };
    this.expertList.forEach(x => {
      x.checked = x.value === id;
    });
  }

  public onContactSelected(event: MatSelectChange) {
    const id = event.value;

    if (!!id === false) {
      return;
    }
    if (id.toString().includes('___')) {
      const name = id.split('___')[1].split('###');
      const contact = this.clientService.sessionContacts.find(x => x.firstName === name[0] && x.lastName === name[1]);
      this.customerContact = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
      } as CustomerContactRequestItem;
    } else {
      const contact = this.existingCustomerContacts.find(x => x.id === id);
      this.customerContact = {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
      } as CustomerContactRequestItem;
    }
    this.contactsList.forEach(x => {
      x.checked = x.value === id;
    });

    this.transitionSendObj.customerContact = this.customerContact;
  }

  public onDatepickSelected(index: number) {
    this.appointmentIndex = index;
    this.tempAppointment = {} as Appointment;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickUpdate(index: number) {
    this.appointmentIndex = index;
    this.tempAppointment = this.appointmentArray[index] as Appointment;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickDeleted(index) {
    this.appointmentArray.splice(index, 1);
  }

  public onTransitionCommentAdded(transitionComment: string) {
    this.transitionSendObj.individualNote = transitionComment;
  }

  public getExpertContact(expertContact): string {
    const contact = expertContact.selected;
    return `${contact.firstName} ${contact.lastName}`;
  }

  public onFinalizeTransition() {
    const sendObj = {} as AssignIndividualTransitionRequest;

    sendObj.appointmentDates = this.appointmentArray.map(a => {
      return { appointmentStart: a.appointmentStart, appointmentEnd: a.appointmentEnd };
    });

    if (this.selectedExpertContact) {
      sendObj.expertContact = this.selectedExpertContact;
    }

    if (this.customerContact) {
      sendObj.customerContact = this.customerContact;

      // no id = new contact -> no need for it in the session contacts
      if (!this.customerContact.id) {
        this.clientService.removeSessionContact(this.customerContact);
      }
    }

    sendObj.individualNote = this.transitionSendObj.individualNote;
    sendObj.name = this.transitionSendObj.name;
    sendObj.description = this.transitionSendObj.description;

    if (this.inputValues.subtopicId != '-1') {
      sendObj.subtopicId = this.inputValues.subtopicId;
    } else {
      sendObj.subtopicId = '';
    }

    if (this.transitionSendObj.id !== '-1') {
      sendObj.id = this.transitionSendObj.id;
    }

    this.queryService.putAssignIndividualTransition(this.clientService.consultationId, sendObj).subscribe(x => {
      this.handleClose();
      this.notifyMainComponents();
    });
  }

  // --------------------------------------------- //
  // -------- CUSTOMER TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onContactAdded() {
    this.customerContact = {
      formOfAddress: this.contactFormControls.formOfAddress.value,
      firstName: this.contactFormControls.firstName.value,
      lastName: this.contactFormControls.lastName.value,
      email: this.contactFormControls.email.value,
    } as CustomerContactRequestItem;

    this.clientService.addSessionContact(this.customerContact);

    this.contactsList.every(x => (x.checked = false));
    const name = `${this.customerContact.formOfAddress} ${this.customerContact.firstName} ${this.customerContact.lastName}`;
    const value = `___${this.customerContact.firstName}###${this.customerContact.lastName}`;
    this.customerContact.newCustomerId = value;

    const listItem = {
      label: trimDropdownLabel(name, 58),
      value: value,
      checked: false,
    } as OptionData;

    const contactExists = this.contactsList.find(x => x.label === listItem.label);

    if (!contactExists) {
      listItem.checked = true;
      this.contactsList.push(listItem);
      this.contactForm.reset();
      this.transitionFormControls.customerContact.patchValue(this.customerContact.newCustomerId);
    }

    this.transitionSendObj.customerContact = this.customerContact;
    this.state = TaskAndTransitionStateEnum.default;
  }

  // --------------------------------------------- //
  // -------- DATEPICK TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onDateSelect(event: Date) {
    this.tempAppointment.timeDateString = event;
  }

  public onStartTimeSelect(setTime) {
    this.tempAppointment.timeStartString = setTime;

    const startTime = this.tempAppointment.timeStartString.split(':').map(Number);
    if (startTime[0] === 23) {
      this.tempAppointment.timeEndString = '23:55';
    } else {
      startTime[0] += 1;
      this.tempAppointment.timeEndString = startTime.map(x => x.toString().padStart(2, '0')).join(':');
    }
  }

  public onEndTimeSelect(setTime): void {
    this.tempAppointment.timeEndString = setTime;
  }

  public onAppointmentDateConfirmed(): void {
    const dayObject = moment(this.tempAppointment.timeDateString);

    const startObject = moment(this.tempAppointment.timeDateString);
    const startTime = this.tempAppointment.timeStartString.split(':').map(Number);
    const startDate = startObject.hour(startTime[0]).minute(startTime[1]);

    const endObject = moment(this.tempAppointment.timeDateString);
    const endTime = this.tempAppointment.timeEndString.split(':').map(Number);
    const endDate = endObject.hour(endTime[0]).minute(endTime[1]);
    this.tempAppointment.appointmentStart = moment(startDate).format('YYYY-MM-DDTHH:mm:SS');
    this.tempAppointment.appointmentEnd = moment(endDate).format('YYYY-MM-DDTHH:mm:SS');
    this.tempAppointment.appointmentString = `${dayObject.format('dddd, DD MMMM YYYY')}, ${
      this.tempAppointment.timeStartString
    } - ${this.tempAppointment.timeEndString} Uhr`;

    // new appointment
    if (this.appointmentIndex === this.appointmentArray.length) {
      this.appointmentArray.push(this.tempAppointment);
    }
    // update existing appointment
    if (this.appointmentIndex < this.appointmentArray.length) {
      this.appointmentArray[this.appointmentIndex] = this.tempAppointment;
    }

    this.state = TaskAndTransitionStateEnum.default;
  }

  public getEndTimeMin(startTime) {
    return moment(startTime, 'HH:mm').add(5, 'minutes').format('HH:mm');
  }

  public handleBack() {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.state = this.transitionState.default;
    }
  }

  get notValidAppointment(): boolean {
    const s = this.tempAppointment.timeStartString;
    const e = this.tempAppointment.timeEndString;
    if (this.tempAppointment.timeDateString && s && e) {
      return moment(s, 'HH:mm').isAfter(moment(e, 'HH:mm'));
    } else {
      return true;
    }
  }

  get customerValue(): string {
    return this.customerContact ? '___' + this.customerContact.firstName + '###' + this.customerContact.lastName : '';
  }

  private async doAction(target: string = '', action: string = '', options?: any): Promise<void> {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    await this.actionService.setAction(data);
  }

  private factorInitialValue() {
    this.transitionSendObj = { name: '', id: '-1', appointmentDates: [] };
    this.selectedExpertContact = undefined;
    this.queryExpertData();
  }

  private notifyMainComponents(): void {
    this.clientService.setSolutionBasketCount();
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Cart) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  private queryExpertData(): void {
    this.queryService.getIndividualTransitionExperts(this.clientService.consultationId).subscribe(x => {
      this.expertDropdownFactory(x.experts);
      this.contactsDropdownFactory(x.customers);
    });
  }

  private queryTransitionData(): void {
    let request;
    const indTransition = this.inputValues.compositionId === '-1' || !this.inputValues.compositionId;

    if (indTransition) {
      request = this.queryService.getIndividualTransitionById(
        this.clientService.consultationId,
        this.inputValues.transitionId
      );
    }
    // DO WE NEED THIS???
    else {
      request = this.queryService.getTransitionById(
        this.clientService.consultationId,
        this.inputValues.compositionId,
        this.inputValues.transitionId
      );
    }

    request.subscribe(data => {
      this.transitionData = data;
      if (indTransition) {
        this.transitionSendObj = { ...data };
        this.selectedExpertContact = data.expertContact;
      }

      this.queryExpertData();
      this.appointmentDateFactory();
    });
  }

  private expertDropdownFactory(expertList: ExpertContactItem[]): void {
    this.expertList = [];
    this.allExpertsList = expertList;

    if (!expertList?.length) {
      return;
    }

    for (const expert of expertList) {
      const listItem: OptionData = { label: '', value: '' };
      const name = `${expert.formOfAddress} ${expert.firstName} ${expert.lastName}`;

      if (expert.contactType === ExpertContactTypeEnum.expertContact) {
        const department = expert.department ? `, ${expert.department}` : '';
        const position = expert.position ? `, ${expert.position}` : '';
        listItem.label = `${name}${position}${department}`;
      } else if (expert.contactType === ExpertContactTypeEnum.customerContact) {
        listItem.label = `${name} (Berater)`;
      }
      listItem.label = trimDropdownLabel(listItem.label, 58);
      listItem.value = `${expert.id}`;
      listItem.checked = this.transitionData?.expertContact.id === expert.id || false;

      if (listItem.checked) {
        this.selectedExpertContact = { id: expert.id, type: expert.contactType };
        this.transitionForm.controls.expertContact.setValue(this.selectedExpertContact.id);
      }

      this.expertList.push(listItem);
    }

    if (!this.selectedExpertContact) {
      this.selectedExpertContact = undefined;
    }
  }

  private contactsDropdownFactory(customerList, newContact = null): void {
    this.existingCustomerContacts = customerList;
    const currentConsultantId = this.existingCustomerContacts
      .find(consultant => consultant.email === this.clientService.bankConsultEmail)
      ?.id.toString();
    const custId = this.transitionData?.customerContact
      ? this.transitionData.customerContact.id
      : currentConsultantId || -1;

    for (const contact of this.existingCustomerContacts) {
      const consultantSuffix = getContactSuffix(contact.customerContactType);
      const contactExists = this.contactsList.find(
        x => x.label.search(`${contact.firstName} ${contact.lastName}`) !== -1
      );

      if (!contactExists) {
        const listItem = {} as OptionData;
        listItem.label = `${contact.formOfAddress ? contact.formOfAddress : ''} ${contact.firstName} ${contact.lastName}${consultantSuffix}`;
        listItem.label = trimDropdownLabel(listItem.label, 58);
        listItem.value = String(contact.id);
        listItem.checked = custId === contact.id;

        this.contactsList.push(listItem);

        if (custId === contact.id) {
          this.customerContact = {} as CustomerContactRequestItem;
          this.customerContact.id = contact.id;
          this.customerContact.formOfAddress = contact.formOfAddress;
          this.customerContact.firstName = contact.firstName;
          this.customerContact.lastName = contact.lastName;
          this.customerContact.email = contact.email;
        }

        if (listItem.checked) {
          this.transitionForm.controls.customerContact.setValue(this.customerContact.id);
        }
      }
    }

    this.clientService.sessionContacts.forEach(contact => {
      const listItem = {} as OptionData;
      listItem.label = contact.firstName + ' ' + contact.lastName;
      listItem.label = trimDropdownLabel(listItem.label, 58);
      listItem.value = '___' + contact.firstName + '###' + contact.lastName;
      listItem.checked = false;

      this.contactsList.push(listItem);
    });
  }

  private appointmentDateFactory(): void {
    for (const index in this.transitionData.appointments) {
      const a = {} as Appointment;
      const d = moment(this.transitionData.appointments[index].appointmentStart).format('dddd, DD MMMM YYYY');

      a.appointmentStart = this.transitionData.appointments[index].appointmentStart;
      a.appointmentEnd = this.transitionData.appointments[index].appointmentEnd;

      a.timeStartString = moment(a.appointmentStart).format('HH:mm');
      a.timeEndString = moment(a.appointmentEnd).format('HH:mm');
      a.timeDateString = moment(a.appointmentStart).toDate();

      a.appointmentString = `${d} ${a.timeStartString} - ${a.timeEndString} Uhr`;

      this.appointmentArray.push(a);
    }
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get buttonDisabled() {
    if (
      !this.selectedExpertContact?.id ||
      !this.customerContact ||
      this.hasInvalidAppointment ||
      !this.transitionSendObj.name
    ) {
      return true;
    } else {
      return false;
    }
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
