<div class="kf-popup">
  <div [class]="data.showWarningIcon ? 'kf-popup-header-icon' : 'kf-popup-header'">
    @if (data.showClose !== false) {
      <button
        matDialogClose
        mat-icon-button
        class="kf-popup-close"
        aria-label="Dialog schließen"
        kfTooltip="Klicken oder ESC drücken, um zu schließen"
        kfTooltipShow="hover"
        kfTooltipPosition="before"
      >
        <kf-icon name="ic_schliessen_24"></kf-icon>
      </button>
    }

    @if (data.showWarningIcon) {
      <kf-icon class="kf-popup-icon" name="ic_error_96"></kf-icon>
    }
  </div>
  <div class="kf-popup-container">
    <h2>{{ data.headingText ? data.headingText : 'Hinweis' }}</h2>
    <div class="mat-body-1">{{ data.copyText }}</div>
    <div class="kf-popup-buttons">
      @if (data.denyText) {
        <button
          mat-stroked-button
          color="primary"
          [attr.data-testcafe]="'dialogConfirm-button-no'"
          (click)="onSelect(false)"
        >
          {{ data.denyText }}
        </button>
      }
      @if (data.confirmText) {
        <button
          mat-flat-button
          color="primary"
          [attr.data-testcafe]="'dialogConfirm-button-yes'"
          (click)="onSelect(true)"
        >
          {{ data.confirmText }}
        </button>
      }
    </div>
  </div>
</div>
