<div class="py-4 pr-4">
  @if (logoReference) {
    <div class="side-profile__customer-logo">
      <img class="image-content" [src]="logoReference" alt="" />
    </div>
  }
  @if (baseData.length > 0) {
    <div class="mt-5">
      <h3 class="font-weight-bold">Beratung</h3>
      <ng-container *ngTemplateOutlet="profileElements; context: { $implicit: baseData }"></ng-container>
    </div>
  }
  @if (extendedData.length > 0) {
    <div class="mt-5">
      <h3 class="font-weight-bold">Weitere Kundendaten</h3>
      <ng-container *ngTemplateOutlet="profileElements; context: { $implicit: extendedData }"></ng-container>
    </div>
  }
</div>

<ng-template #profileElements let-data>
  @for (element of data; track element; let last = $last) {
    <div class="d-flex" [class.mb-3]="!last">
      <div class="w-50">{{ element.label }}</div>
      @if (element?.type !== dataFieldTypeEnum.price) {
        <div class="w-50" [attr.data-testcafe]="getTestcafeId(element.label)" handleENumbers>
          {{ element.value }}
        </div>
      } @else {
        <div class="w-50" [attr.data-testcafe]="getTestcafeId(element.label)" handleENumbers>
          {{ element.value | thousandsDivider }}
        </div>
      }
    </div>
  }
</ng-template>
