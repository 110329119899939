import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { DocumentsViewerComponent } from '@components/documents-viewer/documents-viewer.component';
import { FooterAction } from '@de.fiduciagad.kbm/shared-footer-lib';
import { NoteTypeEnum, NotesModeEnum } from '@enums';
import { OverlayDrawComponent } from '@overlays/overlay-draw/overlay-draw.component';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { AllNotes, NoteIdentifier } from '@services/note-service/note';
import { NoteService } from '@services/note-service/note.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'overlay-notes',
  templateUrl: './overlay-notes.component.html',
  styleUrls: ['./overlay-notes.component.scss'],
})
export class OverlayNotesComponent implements OnDestroy, OnInit {
  public allNotes: AllNotes | undefined;
  public selectedNote: NoteIdentifier | undefined;
  public displayName: String | undefined;
  public isCustomNote = false;
  public loading = false;
  public notesModeEnum = NotesModeEnum;
  private destroySubs = new Subject<void>();

  public currentMode = this.notesModeEnum.Notes;

  private _sidebarExpanded = true;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly nodesMode = this.notesModeEnum;

  @ViewChild('documentViewRef') documentView: DocumentsViewerComponent;
  @ViewChild('overlayDrawRef') overlayDraw: OverlayDrawComponent;

  constructor(
    private documentService: DocumentService,
    private noteService: NoteService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService,
    private contextService: ContextService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
    this.noteService.currentNote.pipe(takeUntil(this.destroySubs)).subscribe(x => {
      this.selectedNote = x?.id;
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    // close shared agenda panel when opening notes overlay
    this.footerService.handleFooterAction({ owner: 'bgzv', id: 'force-close-agenda' });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }
      if (event.id === 'notes-new-drawing') {
        this.createDrawingNote();
      } else if (event.id === 'notes-new-note') {
        this.createTextNote();
      } else if (event.id === 'document-add') {
        this.triggerUpload();
      }
    } else if (event.owner === 'shared-footer') {
      if (event.id === 'toggle-collapsible-section') {
        this.toggleSidebarExpanded();
        this.footerService.setCollapsibleButtonColor();
      }
    }
  }

  public async createTextNote() {
    if (this.overlayDraw.unsavedChanges) {
      if (
        (this.noteService.presetNoteType === NoteTypeEnum.drawing && !this.isIPad) ||
        this.noteService.presetNoteType === NoteTypeEnum.text
      ) {
        await this.unsavedChangesDialog();
      }
    }
    this.noteService.presetNoteType = NoteTypeEnum.text;
    this.noteService.createCustomNote({ text: '', type: NoteTypeEnum.text });
    setTimeout(() => document.getElementById(this.selectedNote)?.scrollIntoView({ behavior: 'smooth' }), 750);
  }

  public async createDrawingNote() {
    if (this.overlayDraw.unsavedChanges) {
      if (
        (this.noteService.presetNoteType === NoteTypeEnum.drawing && !this.isIPad) ||
        this.noteService.presetNoteType === NoteTypeEnum.text
      ) {
        await this.unsavedChangesDialog();
      }
    }
    this.noteService.presetNoteType = NoteTypeEnum.drawing;
    this.noteService.createCustomNote({ drawing: '[]', type: NoteTypeEnum.drawing });
  }

  public toggleSidebarExpanded() {
    this._sidebarExpanded = !this.sidebarExpanded;
  }

  public async changeMode(target: NotesModeEnum): Promise<void> {
    if (this.currentMode === NotesModeEnum.Notes && this.overlayDraw.unsavedChanges) {
      if (
        (this.noteService.presetNoteType === NoteTypeEnum.drawing && !this.isIPad) ||
        this.noteService.presetNoteType === NoteTypeEnum.text
      ) {
        await this.unsavedChangesDialog();
      }
    }

    if (target === this.currentMode) {
      return;
    }

    this.currentMode = target;

    if (target === NotesModeEnum.Documents) {
      this.footerService.hideContents();
    }

    this.selectedNote = undefined;
  }

  public handleKeyup(event: KeyboardEvent, ref: any, data?: any): void {
    if (['Enter', ' '].includes(event.key)) {
      if (ref === document.activeElement || ref?.contains(document.activeElement)) {
        if ([NotesModeEnum.Documents, NotesModeEnum.Notes].includes(data)) {
          this.changeMode(data);
        }
      }
    }
  }

  public triggerUpload() {
    this.documentService.errorOccured.next(false);
    this.documentService.currentDocument.next(undefined);
  }

  get isIPad(): boolean {
    return this.contextService.isIPad;
  }

  get isCurrentModeNote() {
    return this.currentMode === this.notesModeEnum.Notes;
  }

  get hasPreviewDocument() {
    return this.documentService.currentDocument?.getValue() === undefined || undefined;
  }

  get sidebarExpanded(): boolean {
    return this._sidebarExpanded;
  }

  get isScreenshotting(): boolean {
    return this.noteService.screenshotting;
  }

  set isScreenshotting(screenshotting: boolean) {
    this.noteService.screenshotting = screenshotting;
  }

  private async unsavedChangesDialog(): Promise<boolean> {
    return new Promise(async resolve => {
      const data = {
        headingText: 'Änderungen speichern?',
        copyText: 'Die aktuelle Notiz enthält noch ungesicherte Änderungen.',
        denyText: 'Verwerfen',
        confirmText: 'Speichern',
        context: 'Notizen',
      } as DialogConfirmData;

      let dialogRef = this.dialogService.openDialogConfirm(data);
      const result = await lastValueFrom(dialogRef.afterClosed());

      dialogRef = null;
      if (result?.confirmed) {
        await this.overlayDraw.saveNote();
      }
      resolve(result?.confirmed || false);
    });
  }
}
