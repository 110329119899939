import { Pipe, PipeTransform } from '@angular/core';
import prettyNum from 'pretty-num';

@Pipe({
  name: 'numToStr',
  standalone: true,
})
/**
 * convert number to string handling decimal and thousands separator
 * @param value - value as number eg. 1234.56
 * @returns value as string eg. '1.234,56'
 */
export class NumToStrPipe implements PipeTransform {
  public transform(value: number, maxDecimals: number = 2): string {
    return prettyNum(value, {
      precision: maxDecimals,
      thousandsSeparator: '|',
      decimalSeparator: ',',
    }).replaceAll('|', '.');
  }
}

@Pipe({
  name: 'strToNum',
  standalone: true,
})
/**
 * convert string to number handling decimal and thousands separator
 * @param value - value as string eg. '1.234,56'
 * @returns value as number eg. 1234.56
 */
export class StrToNumPipe implements PipeTransform {
  public transform(value: String): number {
    const str = value.toString().replaceAll('.', '');
    return Number(str.replace(',', '.'));
  }
}
