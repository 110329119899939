import { Component, Input } from '@angular/core';
import { SyncedAnswerValues, SyncedQuestions } from '@domain/app/question.domain';
import { GivenAnswerEnum } from '@enums';
import { color } from 'bgzv-frontend-library';

@Component({
  selector: 'synced-questions',
  templateUrl: './synced-questions.component.html',
  styleUrls: ['./synced-questions.component.scss'],
})
export class SyncedQuestionsComponent {
  constructor() {}

  @Input() syncedQuestions: SyncedQuestions[];
  readonly color = color;

  public isAnsweredByMainConsultation(answeredBy: GivenAnswerEnum): boolean {
    return answeredBy === GivenAnswerEnum.consultation;
  }

  splitSyncedQuestionDisplayName(syncedQuestionDisplayName) {
    if (syncedQuestionDisplayName.indexOf('#/#') > 0) {
      syncedQuestionDisplayName = syncedQuestionDisplayName.replace('#/#', ' - ');
      syncedQuestionDisplayName = syncedQuestionDisplayName.substr(0, syncedQuestionDisplayName.indexOf('#/#'));
    }

    return syncedQuestionDisplayName;
  }

  public getAnswerTypeName(answeredBy: GivenAnswerEnum): string {
    switch (answeredBy) {
      case GivenAnswerEnum.consultation:
        return 'Beratungsgespräch';
      case GivenAnswerEnum.consultant:
        return 'Berater-Vorbereitung';
      case GivenAnswerEnum.customer:
        return 'Kunden-Vorbefragung';
      case GivenAnswerEnum.history:
        return 'Vorherige Antwort';
      case GivenAnswerEnum.none:
        return '';
      default:
        return '';
    }
  }

  public displayAnswerValue(answerValue: SyncedAnswerValues): string {
    const note = answerValue.note ? `: [${answerValue.note}]` : '';
    return `${answerValue.value}${note}`;
  }

  public getTestcafeId(answeredBy: GivenAnswerEnum, name = '') {
    switch (answeredBy) {
      case GivenAnswerEnum.consultant:
        return 'syncedQuestion-answer-consultant';
      case GivenAnswerEnum.customer:
        return 'syncedQuestion-answer-customer';
      default:
        return `syncedQuestion-answer-${name?.replace(/ /g, '').slice(0, 12)}`;
    }
  }
}
