<div class="progress-tracker">
  @for (step of steps; track step; let first = $first; let last = $last) {
    <div
      class="progress-step"
      [class.last]="last"
      [class.first]="first"
      [class.active]="step === current"
      [style.width.%]="100 / steps.length"
    ></div>
  }
</div>
