import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PutUpdateStatusResponseItem, SendPreliminaryResponseItem } from '@domain/app/consultation.domain';
import {
  QuestionGroupOverviewItem,
  QuestionOverviewItem,
  SubtopicOverviewItem,
  TopicOverviewItem,
} from '@domain/app/topic.domain';
import { ConsultationStatusEnum, RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { Subscription, concat } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';

@Component({
  selector: 'side-consultation-toggle',
  templateUrl: './side-consultation-toggle.component.html',
  styleUrls: ['./side-consultation-toggle.component.scss'],
  standalone: false,
})
export class SideConsultationToggleComponent implements OnInit, OnDestroy {
  private _contextSub: Subscription;
  private _noteControlSub: Subscription;
  private _dialogSub: Subscription;
  private _dataSub: Subscription;

  private _dialogRef = null;
  public currentMode: 'prep' | 'main' = 'main';

  public context: string;
  public noteControl: FormControl<string | null>;

  public menuData: TopicOverviewItem[] = [];
  public otherMenuData: TopicOverviewItem[] = [];

  public consultationStatusEnum = ConsultationStatusEnum;

  public prepDataSent = false;

  constructor(
    private router: Router,
    private queryService: QueryService,
    private dialogService: DialogService,
    private actionService: ActionService,
    private configService: ConfigService,
    private rightSidenavService: RightSidenavService,
    private contextService: ContextService,
    public clientService: ClientService
  ) {}

  ngOnInit(): void {
    this._contextSub = this.contextService.context.subscribe(context => {
      if (context !== null && context !== undefined) {
        this.contextService.prepToggleMode = this.contextService.currentMode;
        this.context = context;
        this.currentMode = this.contextService.currentMode;
        this.contextService.consultationStatusChanged = this.contextService.prepToggleMode === 'prep';
      }
    });
    this.prepareData();

    this.prepDataSent = this.clientService.prepDataSent;

    this.noteControl = new FormControl<string | null>(this.contextService.customerMessage);
    this._noteControlSub = this.noteControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(query => {
        this.contextService.customerMessage = query;
      });
  }

  ngOnDestroy(): void {
    this._dialogSub?.unsubscribe();
    this._contextSub?.unsubscribe();
    this._noteControlSub?.unsubscribe();
    this._dataSub?.unsubscribe();
  }

  public onSendPrelim(): void {
    this.doAction('agenda', 'prelim-finish');
    !this.sendPrelimDisabledState && this.handleSendPrep();
  }

  public handleSendPrep(): void {
    const consultationId = this.clientService.consultationId;

    const q1 = this.queryService.putSendFinishPreliminary(consultationId, {
      consultantMessage: this.noteControl.value,
    });
    const q2 = this.queryService.putConsultationStatus(consultationId, {
      consultationStatus: ConsultationStatusEnum.preparationSent,
    });

    concat(q1, q2).subscribe(data => {
      if ('subject' in data) {
        const mailContent = data as SendPreliminaryResponseItem;
        const recipient = 'bgzv-test-kunde@bankingguide.com';
        const subject = mailContent.subject;
        const tempBody = mailContent.message.replace(/<br\s*\/?>/gm, '%0D%0A');
        const customerId = this.clientService.customerId;
        const body = tempBody.replace('{{frontend_prelim_url}}', this.getPrelimUrl(customerId, consultationId));
        const win = `mailto:${encodeURIComponent('')}?subject=${encodeURIComponent(subject)}&body=${body}`;
        window.location.href = win;
      }
      if ('consultationStatus' in data) {
        const status = data as PutUpdateStatusResponseItem;
        this.clientService.consultationStatus = status.consultationStatus;
        this.clientService.prepDataSent = status.consultationStatus === ConsultationStatusEnum.preparationSent;
        this.prepDataSent = this.clientService.prepDataSent;
      }
      this.contextService.customerMessage = '';
    });
  }

  public handleClose(): void {
    this.rightSidenavService.close().then(() => {
      if (this.contextService.currentMode !== this.contextService.prepToggleMode) {
        this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.bankHubId}`]);
      }
    });
  }

  public handlePrepSubtopicSelection(event: [boolean, SubtopicOverviewItem, string[]]): void {
    event[0]
      ? this.queryService
          .addSubtopicToPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe()
      : this.queryService
          .removeSubtopicFromPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe();
  }

  public handlePrepQuestionGroupSelection(event: [boolean, QuestionGroupOverviewItem]): void {
    event[0]
      ? this.queryService
          .addQuestionGroupToPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe()
      : this.queryService
          .removeQuestionGroupFromPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe();
  }

  public handlePrepQuestionSelection(event: [boolean, QuestionOverviewItem, { id: string; checked: boolean }]): void {
    event[0]
      ? this.queryService
          .addQuestionToPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe()
      : this.queryService
          .removeQuestionFromPreliminary(this.clientService.consultationId, event[1].id)
          .pipe(
            finalize(() => {
              this.reloadConsultationScreen();
            })
          )
          .subscribe();
  }

  public hasNoSelectedForPrelimElements(): boolean {
    if (this.menuData) {
      return !this.menuData.find(v =>
        v.subtopics.find(x => x.questionGroups.find(y => y.questions.find(z => z.showInPreliminary)))
      );
    }
    return true;
  }

  public doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get sendPrelimDisabledState(): boolean {
    return (
      this.clientService.consultationStatus !== ConsultationStatusEnum.inPreparation ||
      this.hasNoSelectedForPrelimElements()
    );
  }

  get prepToggleMode() {
    return this.contextService.prepToggleMode;
  }

  private prepareData(): void {
    if (this.contextService.currentMode === 'prep') {
      this.queryService.getAvailablePrelimTopics(this.clientService.consultationId).subscribe(x => {
        this.menuData = x.topics;
      });
    } else {
      this.queryService.getTopicDataByConsultationId(this.clientService.consultationId).subscribe(x => {
        this.menuData = x.selected;
        this.otherMenuData = x.available;
      });
    }
  }

  private reloadConsultationScreen(dontSkip = true): void {
    if (dontSkip && this.contextService.currentMainContext === 'prep/consult') {
      this.doAction('consultation', 'reload-prelim-topics');
    }
  }

  private getPrelimUrl(customerId: number, consultationId: string): string {
    if (environment.platform === 'aws') {
      return `${environment.preliminaryTarget}/#/start/${customerId}/${consultationId}`;
    } else if (environment.platform === 'vp') {
      const bankUrl = url => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);

      const url = this.configService.getBankInfoValue('bank-url', 'app');
      const portalPath = `services_cloud/portal/webcomp/bgzv-frontend-prelim#/start`;
      const portalParams = `${customerId}/${consultationId}`;

      return `${bankUrl(url)}/${portalPath}/${portalParams}`;
    }
    return '';
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
