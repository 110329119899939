import { CustomerContactResponseItem, ExpertContactItem } from '@domain/app/contact.domain';
import { getContactSuffix } from './getContactSuffix';

type ContactTypes = CustomerContactResponseItem | ExpertContactItem;

export function formatContact(contact: ContactTypes): string {
  const n = [];
  const ad = contact?.formOfAddress;
  const fn = contact?.firstName || '';
  const ln = contact?.lastName || '';
  const ty = contact?.customerContactType && getContactSuffix(contact.customerContactType);

  ad && n.push(ad);
  fn && n.push(fn);
  ln && n.push(ln);
  ty && n.push(ty);
  return n.join(' ').trim();
}
