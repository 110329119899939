<p *ngFor="let dataField of dataFieldGroup.dataFields" class="dataField">
  <strong class="dataField__key">{{ dataField.name }}: </strong>
  <ng-container *ngIf="dataField.type === dataFieldTypeEnum.price">
    <span class="dataField__val" [attr.data-testcafe]="getTestcafeId(dataField.name)" handleENumbers markNegativePrice>
      {{ getValueMethod(dataField) | thousandsDivider }}
    </span>
  </ng-container>
  <ng-container *ngIf="dataField.type !== dataFieldTypeEnum.price">
    <span class="dataField__val" [attr.data-testcafe]="getTestcafeId(dataField.name)" handleENumbers markNegativePrice>
      {{ getValueMethod(dataField) }}
    </span>
  </ng-container>
</p>
