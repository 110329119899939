<article class="product-variant-card" [class.product-variant-card--recommended]="recommended">
  <span class="product-variant-card__recommended-banner py-2">Empfohlen</span>
  <div class="p-4 product-variant-card__content">
    <h2>{{ product.name }}</h2>
    <ul class="product-variant-card__benefits text-disable-hyphens">
      @for (benefit of product.benefits; track benefit) {
        <li class="d-flex align-items-center">
          <kf-icon name="ic_haken_24" class="mr-2"></kf-icon>
          {{ benefit.name }}
        </li>
      }
    </ul>
    <section class="product-variant-card__prices">
      <p class="d-flex justify-content-between">
        <span>Monatlicher<br />Grundpreis</span
        ><strong>{{
          product.productBasePrice
            | tax: product.runningCost.vat : false
            | currency: 'EUR' : 'symbol' : undefined : 'de-DE'
        }}</strong>
      </p>
      <p class="d-flex justify-content-between">
        <span>Berechneter<br />Preis</span
        ><strong>{{
          calculatedPrice | tax: product.runningCost.vat : false | currency: 'EUR' : 'symbol' : undefined : 'de-DE'
        }}</strong>
      </p>
    </section>
    @if (!inCart) {
      <button
        [attr.data-testcafe]="getTestcafeId(product.name)"
        mat-stroked-button
        color="primary"
        (click)="toggleInCart.emit()"
      >
        <kf-icon name="ic_einkaufswagen_24"></kf-icon>
        <span>Hinzufügen</span>
      </button>
    } @else if (inCart) {
      <button
        [attr.data-testcafe]="getTestcafeId(product.name)"
        mat-flat-button
        color="primary"
        (click)="toggleInCart.emit()"
      >
        <kf-icon name="ic_haken_24"></kf-icon>
        <span>Im Lösungskorb</span>
      </button>
    }
  </div>
</article>
