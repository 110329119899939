import { Component, Input } from '@angular/core';
import { DataField, DataFieldGroup } from '@domain/app/question.domain';
import { DataFieldTypeEnum } from '@enums';
import { ThousandsDividerPipe } from '@utils/pipes/thousandsDivider.pipe';

@Component({
  selector: 'profile-data-field-group',
  templateUrl: './profile-data-field-group.component.html',
  styleUrls: ['./profile-data-field-group.component.scss'],
  standalone: false,
})
export class ProfileDataFieldGroupComponent {
  @Input() group: DataFieldGroup;

  constructor(private pipe: ThousandsDividerPipe) {}

  public getValue = (dataField: DataField) => {
    const { value } = dataField;
    if (dataField.type === DataFieldTypeEnum.price) {
      return this.pipe.transform(value);
    }
    if (value === 'true') {
      return 'Ja';
    }
    if (value === 'false') {
      return 'Nein';
    }
    return value;
  };

  public getTestCafeId(name: string = ''): string {
    const type = this.group.template.toLowerCase();
    return `profileDataField-${type}-${name?.replace(/ /g, '')}`;
  }

  public getTestCafeDetails(dataField: DataField, index?: number): string {
    const { groupName, template } = this.group;
    const type = template.toLowerCase();
    const name = dataField.name.replace(/ /g, '');
    const value = this.getValue(dataField);
    return `profileDataField-${type}-${name}-${groupName}-${value}-${index}`;
  }

  get headerRow(): string[] {
    return this.group.dataFieldSections[0].dataFields.map(field => field.name);
  }
}
