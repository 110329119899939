import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';

@Component({
  selector: 'screen-finish',
  templateUrl: './screen-finish.component.html',
  styleUrls: ['./screen-finish.component.scss'],
  standalone: false,
})
export class ScreenFinishComponent implements OnInit {
  public requestInProgress = false;
  public returnUrlVP = `${window.location.origin}/bws/kbm-agenda-appointments-overview/gespraechdurchfuehrung/`;
  public appointmentID = this.clientService.appointmentUUID;

  constructor(
    private router: Router,
    private clientService: ClientService,
    private contextService: ContextService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService
  ) {}

  ngOnInit(): void {
    if (environment.platform === 'vp') {
      this.contextService.toggleFocusMode('normal');
    }

    this.footerService.hideContents();
    this.panelService.clearBgzvContent();
  }

  public finishConsultation(): void {
    if (!this.redirectToKBM) {
      this.router.navigate([RoutingPathMain.ClientConsultations], { state: { context: 'checkoutFinished' } });
    } else {
      window.location.href = `${this.returnUrlVP}${this.appointmentID}`;
    }
  }

  get redirectToKBM(): boolean {
    return environment.platform === 'vp' ? !this.clientService.contentStream.includes('BGE') : false;
  }

  get isTestConsultation(): boolean {
    return this.clientService.isTestConsultation;
  }

  get platform(): 'aws' | 'vp' {
    return environment.platform as 'aws' | 'vp';
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
