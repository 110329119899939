import { Pipe, PipeTransform } from '@angular/core';
import prettyNum, { PRECISION_SETTING } from 'pretty-num';

@Pipe({
  name: 'thousandsDivider',
})
export class ThousandsDividerPipe implements PipeTransform {
  transform(value: string): string {
    let transformed = prettyNum(value, {
      precision: 2,
      precisionSetting: PRECISION_SETTING.FIXED,
      thousandsSeparator: '|',
      decimalSeparator: ',',
    });
    transformed = transformed.replace(/\|/g, '.');

    return transformed;
  }
}
