import { Component, Input, OnInit } from '@angular/core';
import { ConsultationSummaryConsultation, ConsultationSummaryCustomer } from '@domain/app/consultation.domain';
import { ContextService } from '@services/context-service/context.service';
import moment from 'moment';

@Component({
  selector: 'result-header',
  templateUrl: './result-header.component.html',
  styleUrls: ['./result-header.component.scss'],
  standalone: false,
})
export class ResultHeaderComponent implements OnInit {
  @Input() customerData: ConsultationSummaryCustomer;
  @Input() consultationData: ConsultationSummaryConsultation;

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {}

  public formatAppointmentDate(date: string): string {
    return moment(date).format('DD. MMMM YYYY');
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
