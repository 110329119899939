<div [formGroup]="contactForm" class="w-100 d-flex flex-column side-container">
  <!-- Form of Address -->
  <mat-form-field class="notranslate" class="w-100">
    <mat-label>Anrede</mat-label>
    <mat-select
      #select
      kfSelectMdc
      ngDefaultControl
      placeholder="Anrede auswählen"
      [formControl]="controls.formOfAddress"
    >
      @for (option of formOfAddress; track option.value) {
        <mat-option [value]="option.value"> {{ option.label }}</mat-option>
      }
    </mat-select>
    @if (controls.formOfAddress.invalid) {
      <mat-error>
        <kf-input-error [message]="getErrorMessage('formOfAddress')"></kf-input-error>
      </mat-error>
    }
  </mat-form-field>
  <!-- First Name -->
  <div class="d-flex justify-content-between">
    <div class="w-50 mr-3">
      <mat-form-field class="notranslate">
        <mat-label>Vorname</mat-label>
        <input ngDefaultControl matInput type="text" [formControl]="controls.firstName" autocomplete="off" />
        @if (controls.firstName.invalid) {
          <mat-error>
            <kf-input-error [message]="getErrorMessage('firstName')"></kf-input-error>
          </mat-error>
        }
      </mat-form-field>
    </div>
    <!-- Last Name -->
    <div class="w-50 ml-3">
      <mat-form-field class="notranslate">
        <mat-label>Nachname</mat-label>
        <input ngDefaultControl matInput type="text" autocomplete="off" [formControl]="controls.lastName" />
        @if (controls.lastName.invalid) {
          <mat-error>
            <kf-input-error [message]="getErrorMessage('lastName')"></kf-input-error>
          </mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <!-- E-Mail -->
  <mat-form-field class="notranslate">
    <mat-label>E-Mail-Adresse</mat-label>
    <input
      matInput
      ngDefaultControl
      type="email"
      placeholder="Gültige E-Mail-Adresse wie z.B. name.vorname@domain.de"
      autocomplete="off"
      [formControl]="controls.email"
    />
    <mat-hint> Die E-Mail-Adresse wird z.B. für den Terminhinweis genutzt. </mat-hint>
    @if (controls.email.invalid) {
      <mat-error>
        <kf-input-error [message]="getErrorMessage('email')"></kf-input-error>
      </mat-error>
    }
  </mat-form-field>
</div>
<!-- Footer -->
<footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
  <div class="h-100 button-row-inline">
    <div class="button-row-content">
      <button
        mat-stroked-button
        color="primary"
        [attr.data-testcafe]="'sideTask-button-abort'"
        (click)="onContactCancelled()"
      >
        <span>Abbrechen</span>
      </button>

      <button
        mat-flat-button
        color="primary"
        [disabled]="!contactForm.valid"
        [attr.data-testcafe]="'sideTask-button-addContact'"
        (click)="onContactAdded()"
      >
        <span>Ansprechpartner hinzufügen</span>
      </button>
    </div>
  </div>
</footer>
