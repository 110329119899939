<div class="d-flex align-items-center">
  @if (useButtons) {
    <button
      #buttonRefMinus
      mat-icon-button
      [kfTooltip]="tooltipMinus"
      kfTooltipPosition="below"
      kfTooltipShow="hover_focus"
      class="kf-icon-button-small"
      [disabled]="disabled || disableMinus"
      [attr.aria-label]="'minus-button-' + elementId"
      [attr.data-testcafe]="testcafeIdButtonMinus()"
      (click)="!disabled && !disableMinus && minusClicked()"
      (keyup)="handleKeyup($event, buttonRefMinus)"
    >
      @if (showRemoveIcon && currentNumber - stepValue <= 0) {
        <kf-icon name="ic_loeschen_24"></kf-icon>
      } @else {
        <kf-icon name="ic_minuszeichen_24"></kf-icon>
      }
    </button>
  }

  <!-- TODO: Proper Input field -->
  <div
    class="d-flex justify-content-center align-items-center number-input-box"
    [style.background-color]="!disabled ? 'var(--kf-field-background-color)' : '#eeeeee'"
    [ngStyle]="{ 'min-width': size, height: size }"
    [ngClass]="{ 'product-detail-border': productDetail }"
    role="textbox"
    [attr.data-testcafe]="testcafeId()"
    [attr.aria-label]="'number-input-' + elementId"
  >
    <span
      #inputRef
      class="d-inline-block"
      role="textbox"
      [tabIndex]="!keyboardInput ? -1 : 0"
      [innerHTML]="currentNumber"
      [attr.contenteditable]="keyboardInput"
      [attr.aria-label]="'number-input-field-' + elementId"
      [style.color]="!disabled ? 'var(--kf-field-input-text)' : '#9e9e9e'"
      (keydown)="checkKey($event)"
      (keyup)="handleKey($event)"
      (blur)="emit($event)"
    ></span>
  </div>

  @if (useButtons) {
    <button
      #buttonRefPlus
      mat-icon-button
      [kfTooltip]="'Wert erhöhen'"
      kfTooltipPosition="below"
      kfTooltipShow="hover_focus"
      class="kf-icon-button-small"
      [disabled]="disabled || disablePlus"
      [attr.data-testcafe]="testcafeIdButtonPlus()"
      [attr.aria-label]="'plus-button-' + elementId"
      (click)="!disabled && !disablePlus && plusClicked()"
      (keyup)="handleKeyup($event, buttonRefPlus)"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>
  }
</div>
