<ng-container *ngIf="taskData">
  <section
    class="composition-grid {{ showMainDetails ? 'mt-2 pt-4' : !mainData || !showMainDetails ? 'mt-2' : 'pt-4' }}"
  >
    <ng-container *ngIf="showMainDetails || showIndividualGroupHeading">
      <bgzv-image
        *ngIf="compositionMedia && compositionMedia.url; else defaultImage"
        class="composition-image"
        [alt]="'icon'"
        [src]="compositionMedia.url"
        aria-hidden="true"
      ></bgzv-image>
    </ng-container>
    <ng-container *ngIf="!showMainDetails && !showIndividualGroupHeading">
      <div></div>
    </ng-container>
    <div>
      <div class="d-flex align-items-center mb-2" *ngIf="showIndividualGroupHeading">
        <h2 class="text-truncate mr-3 mb-0" [style.maxWidth.%]="90">Individuelle Maßnahmen</h2>
      </div>
      <ng-container *ngTemplateOutlet="header; context: { data: taskData }"></ng-container>
      <ng-container *ngTemplateOutlet="content; context: { data: taskData }"></ng-container>
    </div>
  </section>
</ng-container>

<ng-template #header let-taskData="data">
  <ng-container *ngIf="showMainDetails">
    <div class="d-flex align-items-center mb-2">
      <h2
        *ngIf="!mainData?.name"
        [debugId]="taskData.id"
        class="mr-2 mb-0"
        [style.maxWidth.%]="90"
        [innerHTML]="truncateText(taskData.name, 50) | safeHtml"
        [kfTooltip]="taskData.name.length > 50 ? taskData.name : null"
        kfTooltipPosition="below"
        kfTooltipShow="hover_focus"
      ></h2>

      <h2
        *ngIf="mainData?.name && !isIndividualItem"
        [debugId]="taskData.id"
        class="mr-2 mb-0"
        [style.maxWidth.%]="90"
        [innerHTML]="truncateText(mainData.name, 50) | safeHtml"
        [kfTooltip]="mainData.name.length > 50 ? mainData.name : null"
        kfTooltipPosition="below"
        kfTooltipShow="hover_focus"
      ></h2>

      <button
        *ngIf="showMainDetails && !!mainData?.id"
        mat-button
        color="primary"
        (click)="onBadgeClicked('product', mainData.id, $event)"
      >
        <span>{{ mainData.name }}</span>
        <kf-icon name="ic_weiterleiten_24"></kf-icon>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #content let-taskData="data">
  <div class="composition-task-border">
    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="productItem; context: { data: taskData }"></ng-container>
      <ng-container *ngTemplateOutlet="assignItem; context: { data: taskData }"></ng-container>
      <ng-container *ngIf="!isIndividualItem">
        <ng-container *ngTemplateOutlet="infoIcon; context: { data: taskData }"></ng-container>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="appointmentItem; context: { data: taskData }"></ng-container>
  </div>
</ng-template>

<ng-template #productItem let-data="data">
  <div class="d-flex align-items-center flex-grow-1">
    <mat-checkbox
      #taskCheckbox
      [attr.data-testcafe]="'itemCompositionTask-checkbox-check-' + getTestcafeId(data.name)"
      [disableRipple]="true"
      [disabled]="loading"
      [checked]="taskData.selected || !compositionId"
      (click)="$event.stopPropagation()"
      (change)="selectTask($event, taskData)"
      style="margin: 0 0.5rem 0 0"
      [color]="'grey'"
    >
      <span [innerHTML]="truncateText(data.name, 50) || 'NO TITLE' | safeHtml"></span>
    </mat-checkbox>
    <div *ngIf="isRecommended(data)" class="tag-info tag-neutral mb-1 ml-3">
      <div class="tag-info-text">Empfohlen</div>
    </div>
  </div>
</ng-template>

<ng-template #assignItem let-data="data">
  <ng-container *ngIf="data.selected && !isIndividualTask">
    <button
      [attr.data-testcafe]="'itemCompositionTask-button-assign-' + getTestcafeId(data.name)"
      *ngIf="!data.customerContact"
      [disabled]="loading"
      mat-stroked-button
      color="primary"
      (click)="openTaskAssignment(data.id, true, data.selected)"
    >
      <span>Zuweisen</span>
    </button>

    <button
      *ngIf="data.customerContact"
      [disabled]="loading"
      mat-stroked-button
      color="primary"
      (click)="openTaskAssignment(data.id, true, data.selected)"
    >
      <span>Zuweisung ändern</span>
    </button>
  </ng-container>

  <button
    *ngIf="isIndividualTask"
    [disabled]="loading"
    mat-stroked-button
    color="primary"
    (click)="openTaskAssignment(data.id, true, data.selected)"
  >
    <span>Zugewiesen</span>
  </button>
</ng-template>

<ng-template #appointmentItem let-data="data">
  <ng-container *ngIf="!!data.customerContact">
    <div class="d-flex align-items-center mat-small mt-3">
      <div class="d-flex align-items-center">
        <kf-icon name="ic_person_mit_kreis_24" class="mr-2"></kf-icon>
        <div>{{ formatContact(data.customerContact) }}</div>
      </div>
      <ng-container *ngIf="!!data.appointmentDate">
        <div class="mx-2">•</div>
        <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
        <div>{{ moment(data.appointmentDate).format('LL') }}</div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #infoIcon let-data="data">
  <button
    class="ml-3"
    [attr.data-testcafe]="'itemComposition-button-details'"
    mat-icon-button
    (click)="openTaskAssignment(taskData.id, false, taskData.selected)"
    [kfTooltip]="'Details zu: ' + data.name"
    kfTooltipPosition="below"
    kfTooltipShow="hover_focus"
    aria-label="Details anzeigen"
  >
    <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
  </button>
</ng-template>

<ng-template #defaultImage>
  <img width="80" height="66" alt="" src="{{ assetPath }}/images/checkout-composition-icon.png" />
</ng-template>
