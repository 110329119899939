import { Component, Input } from '@angular/core';
import { Profile } from '@domain/app/profile';
import { DataFieldTypeEnum } from '@enums';
import { getValue } from '@marginals/side-profile/side-profile.component';
import ProfileDataFieldGroupTable = Profile.ProfileDataFieldGroupTable;

@Component({
  selector: 'profile-data-field-group-table',
  templateUrl: './profile-data-field-group-table.component.html',
  styleUrls: ['./profile-data-field-group-table.component.scss'],
})
export class ProfileDataFieldGroupTableComponent {
  @Input() dataFieldGroup: ProfileDataFieldGroupTable;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;

  get headerRow(): string[] {
    return this.dataFieldGroup.dataFields.map(dataField => dataField.name);
  }

  get dataRowsIndexes(): number[] {
    // TODO: change this to new data-format
    const count =
      this.dataFieldGroup.dataFields.length === 0
        ? 0
        : Math.max(...this.dataFieldGroup.dataFields.map(dataField => dataField.values.length));
    return [...Array(count).keys()];
  }
}
