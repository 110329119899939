import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentsViewerComponent } from '@components/documents-viewer/documents-viewer.component';
import { ConsultationDocumentsResponse } from '@domain/app/media.domain';
import { Notes } from '@domain/notes.d';
import { NoteTypeEnum } from '@enums';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteService } from '@services/note-service/note.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'side-notes',
  templateUrl: './side-notes.component.html',
  styleUrls: ['./side-notes.component.scss'],
})
export class SideNotesComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter(null);

  @ViewChild('documentViewRef') documentView: DocumentsViewerComponent;

  private destroySubs = new Subject<void>();

  public isLoading: boolean = false;
  public numberOfElements: number = 0;
  public numberOfSelectedElements: number = 0;
  public currentNote: Notes.GetNoteResponse;
  public currentDocument: ConsultationDocumentsResponse;
  public type: NoteTypeEnum;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly noteTypeEnum = NoteTypeEnum;

  constructor(
    private noteService: NoteService,
    private documentService: DocumentService,
    private loadingService: LoadingService,
    private rightSideNavService: RightSidenavService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(isLoading => {
      this.isLoading = isLoading;
      this.changeDetector.detectChanges();
    });
    this.type = this.noteTypeEnum.text;
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public handleClose(): void {
    this.closed.emit();
    this.rightSideNavService.close();
  }

  public getNumberOfElements(number): void {
    this.numberOfElements = number;
  }

  public getNumberOfSelectedElements(number): void {
    this.numberOfSelectedElements = number;
  }

  public onOpenNote(note) {
    this.currentDocument = undefined;
    this.currentNote = this.noteService.currentNote.getValue();
    if (note.type !== undefined) {
      this.setType(note.type);
    } else {
      this.setType(note.noteType);
    }
  }

  public onOpenDocument(document) {
    this.currentNote = undefined;
    this.documentService.currentDocument.next(document);
    this.currentDocument = this.documentService.currentDocument.getValue();
  }

  public setType(type) {
    this.type = type;
  }

  get displayName(): string {
    return this.noteService.currentNote.getValue().displayName;
  }

  get drawing(): string {
    if (this.noteService.currentNote?.getValue().drawingBase64) {
      return `<img src="${this.noteService.currentNote?.getValue().drawingBase64}" />`;
    }
    return '';
  }

  get text(): string {
    return this.noteService.currentNote?.getValue()?.text || '';
  }

  get document() {
    return 0;
  }
}
