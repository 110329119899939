@if (syncedQuestions?.length > 0) {
  <div class="sync-question-container">
    @for (syncedQuestion of syncedQuestions; track syncedQuestion) {
      <div>
        {{ splitSyncedQuestionDisplayName(syncedQuestion.questionDisplayName) }}
        @for (answer of syncedQuestion.answers; track answer) {
          <div class="ml-3 mt-2">
            <ng-container *ngTemplateOutlet="answerType; context: { $implicit: answer.answeredBy }"></ng-container>
            <ul>
              @for (answerValue of answer.values; track answerValue) {
                <li [attr.data-testcafe]="getTestcafeId(answer.answeredBy, displayAnswerValue(answerValue))">
                  {{ displayAnswerValue(answerValue) }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
  </div>
}

<ng-template #answerType let-answeredBy>
  <div class="d-flex align-items-center">
    @if (isAnsweredByMainConsultation(answeredBy)) {
      <kf-icon name="ic_personen_paar_24" class="mr-2"></kf-icon>
    } @else {
      <kf-icon name="ic_personen_mit_kreis_24" class="mr-2"></kf-icon>
    }

    <div class="mat-small">
      {{ getAnswerTypeName(answeredBy) }}
    </div>
  </div>
</ng-template>
