import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { AnswerOptionItem } from '@domain/app/answers.domain';
import { QuestionItem } from '@domain/app/question.domain';
import { QuestionEnum } from '@enums';
import { MediaService } from '@services/media-service/media.service';

@Component({
  selector: 'answer-select',
  standalone: true,
  imports: [CommonModule, KfThemeModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './answer-select.component.html',
  styleUrl: './answer-select.component.scss',
})
export class AnswerSelectComponent {
  @Input() question: QuestionItem;
  @Input() selectAnswerData: Map<string, Array<AnswerOptionItem>>;
  @Input() deselectable = true;

  @Output() answerSelected = new EventEmitter<any>();

  public currentlySelectedSingleId = '';
  public multiIdIsSelected = new Map<string, boolean>();
  public answerImages = new Map<string, string>();

  public questionEnum = QuestionEnum;

  public selectFormGroup = new FormGroup({});

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {
    if (this.question.type === QuestionEnum.single || this.question.type === QuestionEnum.singleImage) {
      this.getSelectedAnswerSingle();
    }

    if (this.question.type === QuestionEnum.multi || this.question.type === QuestionEnum.multiImage) {
      this.getSelectedAnswersMulti();
    }

    if (this.question.type === QuestionEnum.singleImage || this.question.type === QuestionEnum.multiImage) {
      this.question.answers.forEach(async answer => {
        let imageData = await this.mediaService.getMediaContent(answer.media.url);
        this.answerImages.set(answer.id, imageData);
      });
    }
  }

  /***** SEND DATA ******/

  public selectAnswerSingle(answerId: string, sentFromNote: boolean = false): void {
    if (this.deselectable && this.currentlySelectedSingleId === answerId && !sentFromNote) {
      this.currentlySelectedSingleId = '';
    } else {
      this.currentlySelectedSingleId = answerId;
    }
    let selected = this.currentlySelectedSingleId === answerId;
    this.answerSelected.emit({
      id: answerId,
      selected: selected,
      note: this.selectFormGroup.get(answerId).value,
    });
  }

  public selectAnswerMulti(answer: AnswerOptionItem, sentFromNote: boolean = false): void {
    if (this.multiIdIsSelected.get(answer.id) && !sentFromNote) {
      this.multiIdIsSelected.set(answer.id, false);
    } else {
      this.multiIdIsSelected.set(answer.id, true);
    }
    this.answerSelected.emit({
      id: answer.id,
      selected: this.multiIdIsSelected.get(answer.id),
      note: this.selectFormGroup.get(answer.id)?.value,
    });
  }

  /***** GET DATA *****/

  private getSelectedAnswerSingle(): void {
    this.currentlySelectedSingleId = this.selectAnswerData?.get(this.question.id)?.at(0).id || '';
    for (let answer of this.question.answers) {
      let note = answer.givenAnswers?.find(x => !!x.note)?.note || '';
      this.selectFormGroup.addControl(answer.id, new FormControl(note || ''));
    }
  }

  private getSelectedAnswersMulti(): void {
    const previousAnswers = this.selectAnswerData?.get(this.question.id) || [];
    let index = 0;
    this.question.answers.forEach(answer => {
      if (previousAnswers.length === 0 || index >= previousAnswers.length) {
        this.multiIdIsSelected.set(answer.id, false);
      } else {
        if (answer.id === previousAnswers[index].id) {
          this.multiIdIsSelected.set(answer.id, true);
          index++;
        } else {
          this.multiIdIsSelected.set(answer.id, false);
        }
      }
      if (answer.hasTextField) {
        let note = answer.givenAnswers?.find(x => !!x.note)?.note || '';
        this.selectFormGroup.addControl(answer.id, new FormControl(note));
      }
    });
  }
}
