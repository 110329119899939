import { Tool } from '@de.fiduciagad.kbm/shared-panel-lib';

export const emptyToolConfig: Tool = {
  id: 'bgzv-tool-panel-empty',
  title: 'Beratungsthemen',
  isOpened: false,
  owner: 'bgzv',
  closeActionId: 'bgzv-close-panel',
  toolSections: [],
};

export const baseToolConfig: Tool = {
  id: 'bgzv-tool-panel-config',
  title: 'Beratungsthemen',
  isOpened: true,
  owner: 'bgzv',
  clickActionId: 'bgzv-panel',
  showEditButton: true,
  editActionId: 'bgzv-edit-panel',
  closeActionId: 'bgzv-close-panel',
  toolSections: [],
};
