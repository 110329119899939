<aside class="position-relative flex-column h-100 bg-white" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="d-flex webkit">
    <div class="container">
      <div class="row mb-4 mt-4">
        <div class="col-12">
          <div class="vh-100 d-flex flex-column outer-container">
            <div class="d-flex pb-3">
              <h2 class="flex-shrink-0">Lösungskorb</h2>
              <ng-template *ngTemplateOutlet="functions"></ng-template>
            </div>
            <div class="d-flex flex-column flex-scroll flex-grow-1 overflow-auto pt-3 mb-3">
              <item-cart [cartType]="'cart'"></item-cart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>

<button
  [attr.data-testcafe]="'overlayCart-button-close'"
  class="kf-popup-close"
  mat-icon-button
  (click)="handleClose()"
  [attr.data-testcafe]="'sideProfile-button-close'"
  aria-label="Dialog schließen"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>

<ng-template #functions>
  <div class="w-100 d-flex justify-content-end">
    <button
      [attr.data-testcafe]="'cart-button-individualTasks'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTask()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Maßnahme</span>
    </button>

    <button
      class="ml-3"
      [attr.data-testcafe]="'cart-button-individualTransitions'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTransition()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Expertengespräch</span>
    </button>

    <button
      mat-stroked-button
      class="ml-3"
      [attr.data-testcafe]="'cart-button-allRecommendations'"
      (click)="selectAllRecommended($event)"
      *ngIf="!allRecommendedChecked"
    >
      <kf-icon name="ic_haken_24"></kf-icon>
      <span>Alle Empfehlungen auswählen</span>
    </button>
  </div>
</ng-template>
