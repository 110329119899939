import { Injectable, OnDestroy } from '@angular/core';
import { ConfigBankInfoItem, ConfigurationResponse } from '@domain/app/configuration.domain';
import { QueryService } from '@services/query-service/query.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements OnDestroy {
  private _hubConfig: ConfigurationResponse;
  private _appConfig: ConfigurationResponse;

  constructor(private queryService: QueryService) {}

  ngOnDestroy(): void {}

  public async getHubConfigurationData(renew = false): Promise<ConfigurationResponse> {
    this.hubConfig = await lastValueFrom(this.queryService.getHubConfiguration(renew));
    return this.hubConfig;
  }

  public async getAppConfigurationData(renew = false): Promise<ConfigurationResponse> {
    this.appConfig = await lastValueFrom(this.queryService.getAppConfiguration(renew));
    return this.appConfig;
  }

  public getSettingsValue(key: string, type: 'app' | 'hub' = 'app'): string {
    const config = type === 'hub' ? this.hubConfig : this.appConfig;
    const result = config?.settings.find(x => x.key === key) || null;
    return result?.value || null;
  }

  public getSettingsDefaultValue(key: string, type: 'app' | 'hub' = 'app'): string {
    const config = type === 'hub' ? this.hubConfig : this.appConfig;
    const result = config?.settings.find(x => x.key === key) || null;
    return result?.defaultValue || null;
  }

  public getBankInfoValue(key: string, type: 'app' | 'hub' = 'app'): string {
    const config = type === 'hub' ? this.hubConfig : this.appConfig;
    const result = config?.bankInformation.find(x => x.key === key) || null;
    return result?.value || null;
  }

  public get hubConfig(): ConfigurationResponse {
    return this._hubConfig;
  }
  public set hubConfig(value: ConfigurationResponse) {
    this._hubConfig = value;
  }

  public get appConfig(): ConfigurationResponse {
    return this._appConfig;
  }
  public set appConfig(value: ConfigurationResponse) {
    this._appConfig = value;
  }

  get isDebugMode(): boolean {
    if (!this.appConfig) {
      return false;
    }
    const setting = this.appConfig.settings.find(x => x.key === 'debugModeFrontend');
    return setting ? setting.value === '1' : false;
  }

  get bankInfo(): ConfigBankInfoItem[] {
    if (!this.hubConfig || !this.hubConfig.bankInformation) {
      return [];
    }
    return this.hubConfig.bankInformation;
  }

  get settings(): ConfigBankInfoItem[] {
    if (!this.hubConfig || !this.hubConfig.settings) {
      return [];
    }
    return this.hubConfig.settings;
  }

  get noPrepMode(): boolean {
    return this.getSettingsValue('disablePreparationProcess') === '1';
  }

  get startingPointMainConsultation(): boolean {
    return this.getSettingsValue('startingPointMainConsultation') === '1';
  }

  get quickstartMode(): boolean {
    return this.getSettingsValue('disablePreparationProcess') === '2' && this.startingPointMainConsultation;
  }

  get skipSubtopicSummary(): boolean {
    return this.getSettingsValue('skipSubtopicSummary') === '1';
  }

  get autoAddRec(): boolean {
    return this.getSettingsValue('autoAddRecSolutionsToCart') === '1';
  }
}
