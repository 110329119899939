import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Base64ConvertService {
  constructor() {}

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.toString());
      };
      reader.onerror = reject;
    });
  }

  convertToFile(string: string): Promise<File> {
    return new Promise(resolve => {
      const blob = this.createBlob(string);
      const file = new File([blob], 'logo.' + blob.type.split('/')[1], { type: blob.type });
      resolve(file);
    });
  }

  createBlob(string: string): Blob {
    const arr = string.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}
