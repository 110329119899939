import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { LoadingService } from '@services/loading-service/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'answer-segmented-radio',
  templateUrl: './answer-segmented-radio.component.html',
  styleUrls: ['./answer-segmented-radio.component.scss'],
  standalone: false,
})
export class AnswerSegmentedRadioComponent implements OnInit, OnDestroy {
  @Input() question;
  @Input() answerData;
  @Input() elements: SegmentData[];

  @Output() selectChange = new EventEmitter<MatRadioChange>();
  @Output() focused = new EventEmitter<any>();

  public loading = false;
  private subscription: Subscription;

  public selectedValue: string;

  constructor(
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.loadingService.isLoading.subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public valueChanged(event: MatRadioChange) {
    if (!this.loading) {
      this.selectChange.emit(event);
    }
  }

  public handleKeyup(event: any, ref: HTMLElement) {
    if (event.key === 'ArrowLeft' || 'ArrowRight' || 'Tab') {
      if (ref === document.activeElement || ref?.contains(document.activeElement)) {
        this.focused.emit(ref);
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  public handleFocus(event: any, radio: SegmentData) {
    if (!this.loading) {
      this.focused.emit({ ref: radio, id: radio.id });
    }
  }
}

export interface SegmentData {
  id: string | number;
  label: string;
  checked?: boolean;
}
