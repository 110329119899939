<ul>
  <ng-container *ngFor="let dataField of dataFieldGroup.dataFields">
    <ng-container *ngIf="dataField.type === dataFieldTypeEnum.price">
      <li markNegativePrice>{{ getValueMethod(dataField) | thousandsDivider }}</li>
    </ng-container>
    <ng-container *ngIf="dataField.type !== dataFieldTypeEnum.price">
      <li markNegativePrice>{{ getValueMethod(dataField) }}</li>
    </ng-container>
  </ng-container>
</ul>
