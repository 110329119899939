import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteService } from '@services/note-service/note.service';
import { PwaService } from '@services/pwa-service/pwa.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * The Header Component
 */

@Component({
  selector: 'marginals-header',
  templateUrl: './marginals-header.component.html',
  styleUrls: ['./marginals-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MarginalsHeaderComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();
  private _dialogSub: Subscription;
  private _dialogRef = null;

  public context: string;
  public numSolutions = 0;

  public loading = false;
  public indicatorVisible = false;
  public hasNotes = false;
  public hasDocuments = false;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private chg: ChangeDetectorRef,
    private pwaService: PwaService,
    private actionService: ActionService,
    private clientService: ClientService,
    private configService: ConfigService,
    private contextService: ContextService,
    private loadingService: LoadingService,
    private noteService: NoteService,
    private documentService: DocumentService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (context !== null && context !== undefined) {
        this.context = context;
        if (this.context === RoutingPathMain.ClientList) {
          this.pwaService.resetCheckedStatus();
        }
      }
      this.chg.detectChanges();
    });

    this.clientService.numInCart.pipe(takeUntil(this.destroySubs)).subscribe(num => {
      this.numSolutions = this.clientService.numSolutions;
      this.chg.detectChanges();
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'header') {
      }
    });

    this.noteService.hasNotes.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      if (notesData) {
        this.indicatorVisible = true;
      }
      if (!notesData && !this.documentService.hasDocuments.getValue()) {
        this.indicatorVisible = false;
      }
    });

    this.documentService.hasDocuments.pipe(takeUntil(this.destroySubs)).subscribe(documentsData => {
      if (documentsData) {
        this.indicatorVisible = true;
      }
      if (!documentsData && !this.noteService.hasNotes.getValue()) {
        this.indicatorVisible = false;
      }
    });

    //needed because of screen-client-list and screen-client-consultation so it won't execute there
    if (
      !this.contextService.isConsulationModuleContext &&
      !!this.clientService.consultationId &&
      this.clientService.consultationStatus !== ConsultationStatusEnum.checkoutDone &&
      this.clientService.consultationStatus !== ConsultationStatusEnum.archived
    ) {
      this.noteService.getAllNotes();
      this.documentService.fetchDocumentList();

      if (this.noteService.hasNotes.getValue() || this.documentService.hasDocuments.getValue()) {
        this.indicatorVisible = true;
      } else {
        this.indicatorVisible = false;
      }
    }
  }

  /**
   * Angular Lifecycle function
   */
  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onOpenChat(): void {
    this.doAction('main', 'chat');
  }

  public onOpenSearch(): void {
    this.doAction('overlay-main', 'search');
  }

  public onOpenNotes(): void {
    this.doAction('overlay-main', 'notes');
  }

  public onOpenProfile(): void {
    this.doAction('main', 'profile');
  }

  public onOpenCart(): void {
    this.doAction('overlay-main', 'cart');
  }

  public onAgendaClick() {
    if (this.quickstartMode || this.startingPointMainConsultation) {
      const data = {
        headingText: 'Aktuelle Beratung verlassen?',
        copyText: `Möchten Sie wirklich die aktuelle Beratung verlassen und auf die Gesamtberatungsübersichtsseite wechseln?`,
        confirmText: 'Ja',
        denyText: 'Nein',
      } as DialogConfirmData;

      this._dialogRef = this.dialogService.openDialogConfirm(data);
      this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
        if (result?.confirmed === true) {
          setTimeout(() => {
            this.contextService.lastQuestionGroupId.next('-1');
            this.contextService.toggleFocusMode('normal');
            this.doAction('main', 'home');
          }, 350);
        }
        this._dialogRef = null;
      });
    } else {
      this.router.navigate([`${this.contextService.currentMode}/agenda/${this.clientService.bankHubId}`]);
    }
  }

  public onClientListClick() {
    this.router.navigate([RoutingPathMain.ClientList]);
  }

  public onClientConsultationsClick() {
    this.router.navigate([RoutingPathMain.Welcome]);
  }

  public onHomeClick() {
    const data = {
      headingText: 'Aktuelle Beratung verlassen?',
      copyText: `Möchten Sie wirklich die aktuelle Beratung verlassen und auf die Gesamtberatungsübersichtsseite wechseln?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this._dialogRef = this.dialogService.openDialogConfirm(data);
    this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        setTimeout(() => {
          this.contextService.lastQuestionGroupId.next('-1');
          this.contextService.toggleFocusMode('normal');
          this.doAction('main', 'home');
        }, 350);
      }
      this._dialogRef = null;
    });
  }

  public onLogout(): void {
    this.actionService
      .setAction({ target: 'overlay-main', action: 'close' })
      .then(() => this.authService.logout())
      .then(() => this.router.navigate([RoutingPathMain.Login], { state: { reason: 'logout' } }));
  }

  /**
   * Communcation function with other Components
   */
  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private checkUpdate() {
    this.pwaService.checkUpdate();
  }

  get showMenuButtons(): boolean {
    return (
      !this.context.startsWith('main/client') &&
      this.context !== RoutingPathPrep.TopicSelection &&
      this.context !== RoutingPathMain.TopicSelection &&
      this.context !== RoutingPathMain.Checkout
    );
  }

  get showLogoutButton(): boolean {
    return this.context === RoutingPathMain.ClientList || this.context === RoutingPathMain.ClientConsultations;
  }

  get showStatusBadge(): boolean {
    return !this.context.startsWith('main/client') && this.context !== RoutingPathMain.Checkout;
  }

  get showBasketButton(): boolean {
    return this.context !== RoutingPathMain.Checkout && this.showMenuButtons;
  }

  get showBottomDivider(): boolean {
    return !(this.context === RoutingPathMain.Login || this.context === RoutingPathMain.Checkout);
  }

  get showStickyButton(): boolean {
    if (!this.contextService.showStickyButton) {
      return false;
    }
    return this.showStatusBadge || this.showLogoutButton || this.context === RoutingPathMain.Checkout;
  }

  get cartNumber(): string {
    return this.clientService.numSolutions >= 0 ? this.clientService.numSolutions.toString() : '0';
  }

  get contextMode(): 'main' | 'prep' {
    return this.contextService.currentMode;
  }

  get platform(): 'aws' | 'vp' {
    return environment.platform as 'aws' | 'vp';
  }

  get quickstartMode(): boolean {
    return this.configService.quickstartMode;
  }

  get startingPointMainConsultation(): boolean {
    return (
      this.configService.startingPointMainConsultation &&
      this.clientService.consultationStatus === ConsultationStatusEnum.mainConsultation
    );
  }

  get showChat(): boolean {
    return false;
    /*
    ** DEPRECATED code due to prelim deactivation
    ** Left in for potential reactivation
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
    );
    */
  }

  get showAgendaButton(): boolean {
    if (this.platform !== 'vp') {
      return this.allowedAgendaButtonRoutes();
    } else {
      return this.quickstartMode ? false : this.allowedAgendaButtonRoutes();
    }
  }

  private allowedAgendaButtonRoutes(): boolean {
    return (
      this.contextService.currentMainContext === RoutingPathMain.Consultation ||
      this.contextService.currentMainContext === RoutingPathPrep.Consultation ||
      this.contextService.currentMainContext === RoutingPathPrep.TopicSummary ||
      this.contextService.currentMainContext === RoutingPathMain.TopicSummary
    );
  }

  get showHomeButton(): boolean {
    const isOnTopicOverview =
      this.context.startsWith(RoutingPathMain.Agenda) || this.context.startsWith(RoutingPathPrep.Agenda);

    return isOnTopicOverview && !this.contextService.isConsulationModuleContext;
  }

  get showClientListButton(): boolean {
    return this.platform === 'aws' && this.contextService.currentMainContext === RoutingPathMain.ClientConsultations;
  }

  get showWelcomeButton(): boolean {
    return this.platform === 'aws' && this.contextService.currentMainContext === RoutingPathMain.ClientList;
  }

  get status(): string {
    if (this.contextMode === 'main') {
      return 'Beratung';
    }
    if (this.contextMode === 'prep') {
      return 'Vorbereitung';
    }
    return '';
  }

  get isIPad(): boolean {
    return this.contextService.isIPad;
  }
}
