import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[handleENumbers]',
  standalone: false,
})
export class HandleENumbersDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    const value = this.el.nativeElement.innerHTML.trim();
    if (value && value.length > 1 && /^[+\-]?(?=.)(?:0|[1-9]\d*)?(?:\.\d*)?(?:\d[eE][+\-]?\d+)?$/.test(value[1])) {
      isNaN(Number(value))
        ? (this.el.nativeElement.innerHTML = value)
        : (this.el.nativeElement.innerHTML = Number(value));
    }
  }
}
