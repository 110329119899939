import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

export interface SnackBarData {
  type: SnackbarType;
  message: string;
  icon?: string;
  config?: MatSnackBarConfig;
}

export enum SnackbarType {
  NEUTRAL = 'Neutral',
  ALERT = 'Alert',
  SUCCESS = 'Success',
  ERROR = 'Error',
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarTemplatesService {
  public snackBarOpened = new Subject<SnackBarData>();
  public snackBarDismissed = new Subject<void>();

  constructor() {}

  public openSnackBar(data: SnackBarData): void {
    this.snackBarOpened.next(data);
  }

  public dismissSnackBar(): void {
    this.snackBarDismissed.next();
  }
}
