import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionService } from '@services/action-service/action.service';
import { ProductWorldData, ProductWorldItem, SearchService } from '@services/search-service/search.service';
import { color } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-task-world-column',
  templateUrl: './search-task-world-column.component.html',
  styleUrls: ['./search-task-world-column.component.scss'],
})
export class SearchTaskWorldColumnComponent implements OnInit, OnDestroy {
  private dataSubscription?: Subscription;
  private _productWorldData: ProductWorldData;
  private _searchQuery = '';

  readonly color = color;

  constructor(
    private readonly actionService: ActionService,
    private readonly searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.dataSubscription = this.searchService.productWorldData.subscribe(data => {
      this._productWorldData = data;
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }

  public compositionElementClicked(compositionElement: ProductWorldData[0]): void {
    if (compositionElement.type === 'product') {
      this.actionService
        .setAction({
          target: 'main',
          action: 'product',
          options: { compositionId: compositionElement.compositionId, productId: compositionElement.id },
        })
        .then();
    }
    if (compositionElement.type === 'task') {
      this.actionService
        .setAction({
          target: 'main',
          action: 'task',
          options: {
            compositionId: compositionElement.compositionId,
            taskId: compositionElement.id,
            compositionTitle: compositionElement.compositionName,
          },
        })
        .then();
    }
    if (compositionElement.type === 'transition') {
      this.actionService
        .setAction({
          target: 'main',
          action: 'transition',
          options: { compositionId: compositionElement.compositionId, transitionId: compositionElement.id },
        })
        .then();
    }
  }

  public handleProductName(item: ProductWorldItem): string {
    return item.mainName === item.name ? item.name : `${item.mainName} (${item.name})`;
  }

  get productWorldData(): ProductWorldData {
    return this._productWorldData?.filter(x => x.type === 'task');
  }

  get searchQuery(): string {
    return this.searchService.searchQueryValue;
  }
}
