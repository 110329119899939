import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment/environment';
import isNil from 'lodash-es/isNil';
import { Observable, Subscription, fromEvent, merge, of, timer } from 'rxjs';
import { debounceTime, delay, map, retryWhen, startWith, switchMap, tap } from 'rxjs/operators';

export interface ConnectionState {
  hasNetworkConnection: boolean;
  hasInternetAccess: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  private _stateEmitter = new EventEmitter<ConnectionState>();
  private _httpSub: Subscription;
  private _onlineSub: Subscription;

  private _onlineOffline: Observable<boolean>;

  private _interval = 15000;

  private currentState: ConnectionState = {
    hasInternetAccess: true,
    hasNetworkConnection: window.navigator.onLine,
  };

  constructor(private http: HttpClient) {
    if (environment?.enableOnlinePing === true) {
      this._onlineOffline = merge(
        fromEvent(window, 'online').pipe(map(() => true)),
        fromEvent(window, 'offline').pipe(map(() => false)),
        of(navigator.onLine)
      );

      this.checkNetworkState();
      this.checkInternetState();
    }
  }

  ngOnDestroy(): void {
    this._httpSub?.unsubscribe();
    this._onlineSub?.unsubscribe();
  }

  private checkInternetState() {
    if (!isNil(this._httpSub)) {
      this._httpSub.unsubscribe();
    }
    this._httpSub = timer(0, this.interval)
      .pipe(
        switchMap(() => this.http.get('/ping')),
        retryWhen(errors =>
          errors.pipe(
            // handle error
            tap(val => {
              this.currentState.hasInternetAccess = true;
              this.emitEvent();
            }),
            // restart after 5 seconds
            delay(5000)
          )
        )
      )
      .subscribe(x => {
        this.currentState.hasInternetAccess = true;
        this.emitEvent();
      });
  }

  private checkNetworkState() {
    if (!isNil(this._onlineSub)) {
      this._onlineSub.unsubscribe();
    }

    this._onlineSub = this._onlineOffline.subscribe(status => {
      this.currentState.hasNetworkConnection = status;
      this.checkInternetState();
      this.emitEvent();
    });
  }

  private emitEvent() {
    this._stateEmitter.emit(this.currentState);
  }

  public onlineDetection(useCurrentState = true): Observable<ConnectionState> {
    return useCurrentState
      ? this._stateEmitter.pipe(debounceTime(250), startWith(this.currentState))
      : this._stateEmitter.pipe(debounceTime(250));
  }

  public get interval() {
    return this._interval;
  }
  public set interval(value) {
    this._interval = value;
  }
}
