import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'answer-number-input',
  imports: [MaterialModule, KfThemeModule, ReactiveFormsModule, MatInputModule, CommonModule],
  templateUrl: './answer-number-input.component.html',
  styleUrls: ['./answer-number-input.component.scss'],
})
export class AnswerNumberInputComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * default value on the counter
   */
  @Input() defaultNumber: number = 20;
  /**
   * minimum value
   */
  @Input() minValue: number = 0;
  /**
   * maximum value
   */
  @Input() maxValue: number = 1000;
  /**
   * step in which the counter changes
   */
  @Input() stepValue: number = 1;
  /**
   * size of the counter on the screen
   */
  @Input() size: string = '2.5rem';
  /**
   * whether the counter accepts input from the keyboard
   */
  @Input() keyboardInput: boolean = false;
  /**
   * whether the counter is disabled
   */
  @Input() disabled: boolean = false;
  /**
   * whether to use buttons for increasing and decreasing the value
   */
  @Input() useButtons: boolean = true;
  @Input() productDetail: boolean = false;
  @Input() emitOnNumberInput: boolean = false;
  @Input() showRemoveIcon: boolean = false;
  @Input() testcafeData: string;

  @Output() currentNumberChanged = new EventEmitter<number>();
  @Output() focused = new EventEmitter<any>();

  @Input() currentNumber: number;

  private newNumberInput = new BehaviorSubject<any>('');
  private newNumberInputSub: Subscription;

  public tempValue: number = 0;
  public disableMinus: boolean;
  public disablePlus: boolean;

  constructor(private readonly viewRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.newNumberInputSub = this.newNumberInput.pipe(debounceTime(200), distinctUntilChanged()).subscribe(ev => {
      if (!!ev && !isNaN(ev) && this.emitOnNumberInput) {
        this.emit(ev);
      }
    });
  }

  ngOnDestroy() {
    this.newNumberInputSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentNumber = this.defaultNumber;
    this.tempValue = this.defaultNumber;
    this.minValue = Math.min(this.minValue, this.maxValue);
    this.maxValue = Math.max(this.minValue, this.maxValue);
    this.disablePlus = this.currentNumber === this.maxValue;

    if (!this.useButtons) {
      this.keyboardInput = true;
    }
    if (this.disabled) {
      this.keyboardInput = false;
    }
  }

  public plusClicked() {
    this.currentNumber = this.tempValue;
    this.currentNumber = Math.min(this.currentNumber + this.stepValue, this.maxValue);
    this.tempValue = this.currentNumber;
    this.currentNumberChanged.emit(this.currentNumber);
    this.disablePlus = this.currentNumber >= this.maxValue;
    this.disableMinus = this.currentNumber <= this.minValue;
  }

  public minusClicked() {
    this.currentNumber = this.tempValue;
    this.currentNumber = Math.max(this.currentNumber - this.stepValue, this.minValue);
    this.tempValue = this.currentNumber;
    this.currentNumberChanged.emit(this.currentNumber);
    this.disableMinus = this.currentNumber <= this.minValue;
    this.disablePlus = this.currentNumber >= this.maxValue;
  }

  public checkKey(ev: any) {
    if (
      (isNaN(parseInt(ev.key, 10)) &&
        ev.key !== 'Delete' &&
        ev.key !== 'ArrowLeft' &&
        ev.key !== 'ArrowRight' &&
        ev.key !== '-' &&
        ev.key !== 'Tab' &&
        ev.key !== 'Backspace') ||
      (ev.target.textContent === '-' && ev.key === '-')
    ) {
      ev.preventDefault();
    }
  }

  public handleKey(ev: any, inputRef: HTMLElement = null) {
    this.handleKeyup(ev, inputRef);

    if (+ev.target.textContent < this.minValue) {
      this.tempValue = this.minValue;
      ev.target.textContent = this.minValue;
    } else if (+ev.target.textContent > this.maxValue) {
      this.tempValue = this.maxValue;
      ev.target.textContent = this.maxValue;
    } else if (ev.target.textContent.match(/\d\-/g)) {
      this.tempValue = this.minValue;
      ev.target.textContent = this.minValue;
    } else {
      this.tempValue = parseInt(ev.target.textContent, 10);
    }

    this.disableMinus = this.tempValue <= this.minValue;
    this.disablePlus = this.tempValue >= this.maxValue;

    if (ev.target.textContent && ev.target.textContent !== '-') {
      this.debounceInput(ev.target.textContent);
    }
  }

  public handleKeyup(event: KeyboardEvent, ref: any = null) {
    if (event.key === 'Tab') {
      if (ref === document.activeElement) {
        this.focused.emit(ref);
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  public emit(ev: any) {
    if (ev.target?.textContent === '') {
      this.tempValue = 0;
      ev.target.textContent = '0';
    }
    if (!isNaN(this.tempValue)) {
      this.currentNumberChanged.emit(this.tempValue);
    }
  }

  public testcafeIdButtonMinus() {
    return `numberInput-minus-${this.testcafeData}`;
  }

  public testcafeId() {
    return `numberInput-div-${this.testcafeData}`;
  }

  public testcafeIdButtonPlus() {
    return `numberInput-plus-${this.testcafeData}`;
  }

  private debounceInput(ev: any) {
    this.newNumberInput?.next(ev);
  }

  get tooltipMinus(): string {
    return this.showRemoveIcon && this.currentNumber - this.stepValue <= 0 ? 'Entfernen' : 'Wert vermindern';
  }

  get elementId(): string {
    return this.viewRef.element.nativeElement.getAttribute('id');
  }
}
