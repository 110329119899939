import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '@environment/environment';

interface VrImageData {
  url: string;
  altText?: string;
  selected?: boolean;
}

enum QuestionImageBehavior {
  small = 'SMALL',
  large = 'LARGE',
  fixedSmall = 'SMALL_FIXED',
  fixedLarge = 'LARGE_FIXED',
}

interface EnlargeOptions {
  endWidth: string;
  endHeight: string;
}

@Component({
  selector: 'bgzv-image',
  templateUrl: './bgzv-image.component.html',
  styleUrls: ['./bgzv-image.component.scss'],
  animations: [
    trigger('simpleSlideAnimation', [
      transition(':decrement', [
        style({ transform: 'translateX(-12%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':increment', [
        style({ transform: 'translateX(12%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),

    trigger('simpleFadeAnimation', [
      transition(':leave', [style({ opacity: 0 })]),
      transition(':decrement', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':increment', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    ]),
  ],
})
export class BgzvImageComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() alt: string = '';

  @Input() image: VrImageData = null;
  @Input() imageMulti: VrImageData[] = [];
  @Input() behavior: QuestionImageBehavior = QuestionImageBehavior.small;
  @Input() showIcon: boolean = true;
  @Input() @HostBinding('style.width') width: string;
  @Input() @HostBinding('style.height') height: string;

  @Input() enlargeable: boolean = true;
  @Input() enlargeOptions: EnlargeOptions = { endHeight: '75vh', endWidth: '100%' };

  @Output() currentValueChanged = new EventEmitter<number>();
  @Output() focused = new EventEmitter<any>();

  public logoExpanded = false;
  public slideIndex = 0;
  public animationIndex = 0;

  public imageLoaded = false;
  public imageError = false;

  public enlarged = false;

  readonly behaviorEnum = QuestionImageBehavior;
  readonly environment = environment;

  public downloadedImages = [];

  constructor() {}

  ngOnInit(): void {
    this.logoExpanded = this.startLarge;
    if (this.isKfImage) {
      this.imageLoaded = true;
    }

    if (!this.width && !this.src) {
      this.width = '354px';
    } else if (!!this.width) {
      this.width = this.width;
    } else {
      this.width = null;
    }

    this.setInitialIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.imageMulti) {
      const arraysEqual = (a1, a2) => a1?.length === a2?.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
      const objectsEqual = (o1, o2) =>
        typeof o1 === 'object' && Object.keys(o1).length > 0
          ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
          : o1 === o2;

      if (!arraysEqual(changes.imageMulti.currentValue, changes.imageMulti.previousValue)) {
        this.setInitialIndex();
      }
    }

    if (changes?.behavior) {
      this.logoExpanded = this.startLarge;
    }
  }

  public changeActiveIndex(increment): void {
    if (increment) {
      this.slideIndex = (this.slideIndex + 1) % this.imageMulti.length;
      this.animationIndex++;
    } else {
      this.slideIndex = (this.slideIndex + this.imageMulti.length - 1) % this.imageMulti.length;
      this.animationIndex--;
    }

    this.currentValueChanged.emit(this.slideIndex);
  }

  public onResizeClick(ev: Event): void {
    this.logoExpanded = this.resizable ? !this.logoExpanded : this.logoExpanded;
  }

  public onImageLoad(ev): void {
    this.imageLoaded = true;
  }

  public onImageError(ev): void {
    this.imageError = true;
  }

  public handleKeyup(event: KeyboardEvent, ref: any): void {
    if (event.key === 'Tab') {
      if (ref === document.activeElement || ref?.contains(document.activeElement)) {
        this.focused.emit({ ref: ref });
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  private setInitialIndex(): void {
    if (this.imageMulti.length > 0) {
      this.slideIndex = Math.max(
        this.imageMulti.findIndex(x => x.selected === true),
        0
      );
    }
  }

  get singleImageUrl(): string {
    return this.image.url;
  }

  public multiImageUrl(slideIndex: number): string {
    if (environment.platform === 'vp' && this.imageMulti[slideIndex].url.includes('bgzv-service-nginx')) {
      const host = `${window.location.protocol}//${window.location.host}`;
      return `${host}/bws/serviceproxy${this.imageMulti[slideIndex].url}`;
    }
    return this.imageMulti[slideIndex].url;
  }

  get isKfImage(): boolean {
    // we can't search for 'assets' as this will be replaced after building on Atruvia
    const searchTerm = 'sets/illus';
    if (!!this.src) {
      return this.src.includes(searchTerm);
    } else if (!!this.image) {
      return this.image?.url.includes(searchTerm) || false;
    } else if (this.imageMulti.length > 0) {
      return this.imageMulti[this.slideIndex]?.url.includes(searchTerm) || false;
    }
    return false;
  }

  get kfImageUrl(): string {
    if (environment.platform === 'vp') {
      // the prefix is only used for local development
      const path = 'https://r8840-e42-e.t1.web.fiducia.de/services_cloud/portal/kf-theme/assets?f=';
      const prefix = environment.isLocal ? path : '';
      if (!!this.src) {
        return `${prefix}${this.src}`;
      } else if (!!this.image) {
        return `${prefix}${this.image.url}`;
      } else if (this.imageMulti.length > 0) {
        return `${prefix}${this.imageMulti[this.slideIndex].url}`;
      }
    }
    return '';
  }

  get resizable(): boolean {
    return this.behavior === this.behaviorEnum.small || this.behavior === this.behaviorEnum.large;
  }

  get startLarge(): boolean {
    return this.behavior === this.behaviorEnum.large || this.behavior === this.behaviorEnum.fixedLarge;
  }
}
