<div class="d-flex flex-column menu-question-world-column" *ngIf="subtopicResults">
  <h3 class="mat-body-1 font-weight-bold mt-5 mb-4">{{ searchQuery ? 'Themen' : 'Favoriten Themen' }}</h3>
  <ng-container *ngIf="subtopicResults && subtopicResults.length > 0">
    <div class="mb-2" *ngFor="let subtopic of subtopicResults; let i = index">
      <div class="mb-3" *ngIf="consultantTopicCount > 0 && isFirstMainTopic(subtopic)">Beratungsthemen</div>
      <button
        class="d-flex recommendation-container mb-3 border-shadow"
        [style.backgroundColor]="color.find(color.name.White)"
        [style.color]="color.find(color.name.BrandPrimary)"
        (click)="onSubtopicSelect(subtopic)"
        [attr.data-title]="subtopic.selected ? 'Schon für die Beratung ausgewählt.' : null"
      >
        <kf-icon *ngIf="!subtopic.selected" class="mr-2" name="ic_pfeil_rechts_24" style="min-width: 24px"></kf-icon>
        <kf-icon *ngIf="subtopic.selected" class="mr-2" name="ic_haken_mit_kreis_24" style="min-width: 24px"></kf-icon>
        <div>{{ subtopic.title }}</div>
      </button>
    </div>
  </ng-container>
</div>
