<div class="number-input-wrapper" [class.disabled]="disabled" [attr.data-testcafe]="testcafeId('div')">
  <div class="input-group" [class.label]="label">
    <label>{{ label }}</label>
    <span>{{ strValue }}</span>
  </div>

  <div class="unit-button-group">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        kfTooltipShow="hover"
        kfTooltipPosition="after"
        [kfTooltip]="showTooltip ? tooltipMinus : ''"
        [kfTooltipDisabled]="isIPad"
        [tabindex]="0"
        [disabled]="disabledMin"
        [attr.aria-label]="tooltipMinus"
        [attr.aria-disabled]="disabledMin"
        [attr.data-testcafe]="testcafeId('minus')"
        (click)="setStep(-1)"
      >
        <kf-icon [name]="iconNameMinus"></kf-icon>
      </button>
      <button
        class="btn btn-primary"
        kfTooltipShow="hover"
        kfTooltipPosition="after"
        [kfTooltip]="showTooltip ? tooltipPlus : ''"
        [kfTooltipDisabled]="isIPad"
        [tabindex]="0"
        [disabled]="disabledMax"
        [attr.aria-label]="tooltipPlus"
        [attr.aria-disabled]="disabledMax"
        [attr.data-testcafe]="testcafeId('plus')"
        (click)="setStep(1)"
      >
        <kf-icon name="ic_pluszeichen_24"></kf-icon>
      </button>
    </div>
  </div>
</div>
