import { Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { KF_THEME_BASE_PATH } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { ENVIRONMENT_DEFAULTS } from './defaults';

export const environment = {
  ...ENVIRONMENT_DEFAULTS,
  name: 'dev',
  apiConsultationAppUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:8001/',
  apiBankHubUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:9001/',

  // bgzv_dev
  cognitoUserPoolId: 'eu-central-1_1R64tmJHl',
  cognitoClientId: '2olr74vgj4ulnfhqs6pt936me0',

  get consultationOrigin() {
    return `https://${this.name}.${ENVIRONMENT_DEFAULTS.consultationOriginSuffix}`;
  },

  get preliminaryTarget() {
    return `https://${this.name}.${ENVIRONMENT_DEFAULTS.preliminaryTargetSuffix}`;
  },
};

export const ENVIRONMENT_SPECIFIC_IMPORTS = [];

export const ENVIRONMENT_SPECIFIC_PROVIDERS = [{ provide: KF_THEME_BASE_PATH, useValue: '/' }];

export function ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(component: any, injector: Injector = null) {
  return createCustomElement(component, { injector: injector });
}
