import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryService } from '@app/services/query-service/query.service';
import { Profile } from '@domain/app/profile';
import { ClientService } from '@services/client-service/client.service';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom } from 'rxjs';

import GetProfileResponse = Profile.GetProfileResponse;
import ProfileGroupDTO = Profile.ProfileGroupDTO;

@Component({
  selector: 'side-profile-editor',
  templateUrl: './side-profile-editor.component.html',
  styleUrls: ['./side-profile-editor.component.scss'],
  standalone: false,
})
export class SideProfileEditorComponent implements OnInit {
  @Input() userProfileData: GetProfileResponse;
  @Output() editorClosed: EventEmitter<GetProfileResponse | null> = new EventEmitter();

  public filteredProfileData: ProfileGroupDTO[];
  private currentVatDiscount: boolean;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    if (this.userProfileData.profileGroups.length > 0) {
      this.filteredProfileData = this.filterEditOnlyElements(this.userProfileData.profileGroups);

      // if there is no data, it should still render a Stammdaten tab for the vorsteuerabzugsberechtigt box
      if (this.filteredProfileData.every(x => x.category !== 'GENERIC')) {
        this.filteredProfileData.unshift({
          category: 'GENERIC',
          dataFieldGroups: [],
        });
      }
    }
    this.currentVatDiscount = this.userProfileData.vatDiscount;
  }

  public async saveData() {
    if (this.userProfileData.vatDiscount !== this.currentVatDiscount) {
      const sendData = { vatDiscount: this.currentVatDiscount };
      const newData = await lastValueFrom(
        this.queryService.patchUpdateProfile(this.clientService.consultationId, sendData)
      );
      this.editorClosed.emit(newData);
    } else {
      this.editorClosed.emit();
    }
  }

  public onVatCheckboxChange(newValue: any): void {
    this.currentVatDiscount = typeof newValue === 'boolean' ? newValue : false;
  }

  private filterEditOnlyElements(data: ProfileGroupDTO[]): ProfileGroupDTO[] {
    const clonedData = cloneDeep(data);
    const filteredData = clonedData?.filter(profileData =>
      profileData.dataFieldGroups?.find(group =>
        group.dataFieldSections?.find(section => section.dataFields?.find(field => field.editable))
      )
    );
    filteredData.forEach(profileData => {
      profileData.dataFieldGroups.forEach(group =>
        group.dataFieldSections.forEach(
          section => (section.dataFields = section.dataFields.filter(field => field.editable))
        )
      );
      profileData.dataFieldGroups.filter(group =>
        group.dataFieldSections.filter(section => section.dataFields.length > 0)
      );
    });
    return filteredData;
  }
}
