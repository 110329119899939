<aside
  #overlay
  id="overlay-notes"
  class="h-100 w-100 align-items-center overlay-notes"
  (keyup)="handleKeyup($event, overlay)"
>
  <nav class="h-100 overlay-notes__side-bar" [class.d-none]="!sidebarExpanded" [attr.data-html2canvas-ignore]="true">
    <div class="d-flex flex-column w-100 h-100 border-right">
      <div class="d-flex flex-column w-100 align-items-baseline">
        <div class="d-flex mode-changer">
          <button
            #notesButton
            (click)="changeMode(nodesMode.Notes)"
            type="button"
            (keyup)="handleKeyup($event, notesButton, nodesMode.Notes)"
            class="tab mr-3"
            tabindex="0"
            [class.selected]="isCurrentModeNote"
            [class.disabled]="loading"
            cdkFocusInitial
          >
            <span [attr.data-testcafe]="'overlayNotes-header-notes'">Notizen</span>
          </button>
          <button
            #documentsButton
            (click)="changeMode(nodesMode.Documents)"
            type="button"
            (keyup)="handleKeyup($event, documentsButton, nodesMode.Documents)"
            class="tab"
            tabindex="0"
            [class.selected]="!isCurrentModeNote"
            [class.disabled]="loading"
            [disabled]="loading"
          >
            <span [attr.data-testcafe]="'overlayNotes-header-documents'">Dokumente</span>
          </button>
        </div>
        <div class="divider"></div>
        <div class="flex-grow-1"></div>
      </div>
      @if (isCurrentModeNote) {
        <notes-menu (isCustomNote)="isCustomNote = $event" (toggleSideBar)="toggleSidebarExpanded()" class="w-100">
        </notes-menu>
        @if (loading) {
          <mat-progress-spinner
            [color]="'primary'"
            mode="indeterminate"
            class="m-auto mt-5"
            [diameter]="45"
          ></mat-progress-spinner>
        }
      } @else {
        <documents-menu class="w-100"></documents-menu>
      }
    </div>
  </nav>
  @if (isCurrentModeNote) {
    <div class="overlay-notes__draw-container">
      <overlay-draw
        #overlayDrawRef
        [currentOpacity]="1"
        [inNotesOverlay]="true"
        [isCustomNote]="isCustomNote"
        [displayName]="displayName"
        [sidebarCollapsed]="!sidebarExpanded"
        (expandSidebarButtonClick)="toggleSidebarExpanded()"
      ></overlay-draw>
    </div>
  } @else {
    <documents-viewer #documentViewRef></documents-viewer>
  }
</aside>
