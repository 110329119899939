import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetConsultantConsultationResponseItem } from '@domain/app/consultation.domain';
import { RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel/shared-panel.service';
import { color } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'screen-entry',
  templateUrl: './screen-entry.component.html',
  styleUrls: ['./screen-entry.component.scss'],
})
export class ScreenEntryComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();
  private authState: string = 'signedOut';

  public consultationData: GetConsultantConsultationResponseItem | { customers: [] } = { customers: [] };
  public isRequesting = false;
  public buttonText = '';

  readonly color = color;

  constructor(
    private router: Router,
    private authService: AuthService,
    private queryService: QueryService,
    private contextService: ContextService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService
  ) {}

  ngOnInit(): void {
    this.authService.currentAuthState.pipe(takeUntil(this.destroySubs)).subscribe(authState => {
      this.authState = authState;
      console.log(
        `%c [bgzv-frontend-main] - AuthState: [${authState}] - platform [${this.platform}]`,
        'color: #0066cc'
      );
      if (this.platform === 'aws' && authState === 'signedIn') {
        this.buttonText = 'Zur Kundenliste';
      } else if (this.platform === 'aws' && authState === 'signedOut') {
        this.buttonText = 'Zum Login';
      } else if (this.platform === 'vp') {
        this.buttonText = 'Zur Terminübersicht';
      }

      if (environment.platform === 'vp' && this.contextService.isFocusMode) {
        this.contextService.toggleFocusMode('normal');
      }

      if (this.showConsultations && this.consultationData.customers.length === 0) {
        this.isRequesting = true;
        this.getConsultations();
      }
    });

    this.footerService.hideContents();
    this.panelService.clearBgzvContent();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onBannerButtonClicked(event: Event): void {
    if (this.platform === 'aws' && this.authState === 'signedIn') {
      this.router.navigate([RoutingPathMain.ClientList]);
    } else if (this.platform === 'aws' && this.authState === 'signedOut') {
      this.router.navigate([RoutingPathMain.Login]);
    } else if (this.platform === 'vp') {
      const kbmPath = `/kbm-agenda-appointments-overview/#/`;
      const host = `${window.location.protocol}//${window.location.host}`;
      window.location.href = `${host}/${kbmPath}`;
    }
  }

  private getConsultations() {
    this.queryService
      .getConsultationByConsultant()
      .pipe(finalize(() => (this.isRequesting = false)))
      .subscribe(x => (this.consultationData = x));
  }

  get platform(): 'aws' | 'vp' {
    return environment.platform as 'aws' | 'vp';
  }

  get showConsultations(): boolean {
    return this.platform === 'vp' || this.authState === 'signedIn';
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
