/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

import {
  ButtonType,
  ComponentType,
  ElementConfig,
  FooterConfig,
  FooterSectionType,
} from '@de.fiduciagad.kbm/shared-footer-lib';

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const buttonDocumentDelete: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'document-delete',
    config: {
      type: ButtonType.FLAT,
      label: 'Löschen',
      icon: 'ic_loeschen_24',
      iconPosition: 'before',
    },
  },
};

export const buttonDocumentNext: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'document-next',
    config: {
      type: ButtonType.STROKED,
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'before',
    },
  },
};

export const buttonDocumentPrev: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'document-prev',
    config: {
      type: ButtonType.STROKED,
      icon: 'ic_pfeil_links_24',
      iconPosition: 'before',
    },
  },
};

export const buttonNewDocument: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'document-add',
    config: {
      type: ButtonType.FLAT,
      label: 'Dokument hinzufügen',
      icon: 'ic_plus_24',
      iconPosition: 'after',
    },
  },
};

/// ------------------------------------------------ ///
/// --------------- DRAWING BUTTONS ---------------- ///
/// ------------------------------------------------ ///

/// ------------------------------------------------ ///
/// ---------------- CONFIGURATIONS ---------------- ///
/// ------------------------------------------------ ///

export const baseConfigCollapseWithDocument: FooterConfig = {
  leftCollapsable: {
    isHidden: false,
    width: '21rem',
    type: FooterSectionType.COLLAPSIBLE,
    elements: [buttonNewDocument],
  },
  drawing: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
  right: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [buttonDocumentPrev, buttonDocumentNext, buttonDocumentDelete],
  },
};

export const baseConfigCollapseNoVisibleDocument: FooterConfig = {
  leftCollapsable: {
    isHidden: false,
    width: '21rem',
    type: FooterSectionType.COLLAPSIBLE,
    elements: [buttonNewDocument],
  },
  drawing: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
  right: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
};
