<div class="d-flex w-100 h-100 position-relative kf-bg">
  @if (!inCheckout) {
    <div class="menu-indent"></div>
  }
  @if (!uploadPending && !fetchingDocumentPending) {
    @if (previewMode) {
      <documents-upload [hidden]="true"></documents-upload>
      <ng-template *ngTemplateOutlet="documentPreview"></ng-template>
    } @else if (!inCheckout) {
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <documents-upload></documents-upload>
      </div>
    }
  } @else {
    <mat-progress-spinner
      [color]="'primary'"
      mode="indeterminate"
      class="m-auto"
      [diameter]="90"
    ></mat-progress-spinner>
  }

  @if (!inCheckout) {
    <button
      [attr.data-testcafe]="'documentsMenu-button-documentClose'"
      class="kf-popup-close"
      mat-icon-button
      aria-label="Dialog schließen"
      (click)="onCloseClick()"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>
  }
</div>

<ng-template #documentPreview>
  <div class="w-100 h-100 documents-preview">
    <div class="position-relative d-flex h-100 w-100 justify-content-center align-items-center">
      @if (imagePreviewSrc) {
        <img [src]="imagePreviewSrc" alt="Vorschau Ihrer Zeichnung" />
      } @else {
        <pdf-viewer [src]="pdfPreview" [render-text]="true" class="pdf-test"></pdf-viewer>
      }
    </div>
  </div>
</ng-template>
