import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

export const buttonAgendaEditCancel: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-edit-cancel',
    config: {
      type: ButtonType.STROKED,
      label: 'Schließen',
    },
  },
};

export const buttonAgendaEditSave: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-edit-save',
    config: {
      type: ButtonType.FLAT,
      label: 'Agenda speichern',
      disabled: true,
      icon: 'ic_speichern_24',
      iconPosition: 'after',
    },
  },
};
