<div class="topic-select-container">
  <div class="topic-select-grid">
    <section class="topic-select-profile">
      <short-profile [profileData]="profileData" [updateInProgress]="updateInProgress"></short-profile>
    </section>
    <div>
      @if (headline) {
        <div class="headline-header-container">
          <h2 class="header-headline">{{ headline }}</h2>
          <div class="step-bubble-container">
            @for (item of stepsBubbles; track item) {
              @if (item < stepsBubbles.length) {
                <ng-container [ngTemplateOutlet]="fullbubble"></ng-container>
              } @else {
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  transform="rotate(180 0 0)"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.99967 13.4163V6.58301C8.1127 6.58301 6.58301 8.1127 6.58301 9.99967C6.58301 11.8866 8.1127 13.4163 9.99967 13.4163ZM15.4163 9.99967C15.4163 12.9912 12.9912 15.4163 9.99967 15.4163C7.00813 15.4163 4.58301 12.9912 4.58301 9.99967C4.58301 7.00813 7.00813 4.58301 9.99967 4.58301C12.9912 4.58301 15.4163 7.00813 15.4163 9.99967Z"
                    [attr.fill]="color.find('--color-primary-500')"
                  />
                </svg>
              }
            }
            @for (_ of emptyStepBubbles; track _) {
              <ng-container [ngTemplateOutlet]="emptyBubble"></ng-container>
            }
          </div>
        </div>
      }
      <!-- div is required for placing the overflow-indicator  -->
      <div>
        <section class="available-side">
          <div class="search-header-container">
            <h3 class="font-weight-bold search-label">Themen Auswahl</h3>
            <div class="mt-2">
              <mat-form-field>
                <mat-label>Suche nach verfügbaren Themen</mat-label>
                <input
                  #searchInput
                  matInput
                  id="screenTopic-input-search"
                  type="search"
                  placeholder="Thema"
                  autocomplete="off"
                  [ngModel]="searchQuery"
                  [attr.data-testcafe]="'screenTopic-input-search'"
                  (ngModelChange)="searchQuery = $event ? $event : ''"
                />
                @if (searchQuery.length > 0) {
                  <button
                    disableRipple
                    mat-icon-button
                    matSuffix
                    aria-label="Sucheingaben löschen"
                    (click)="searchQuery = ''"
                  >
                    <kf-icon name="ic_clear_24"></kf-icon>
                  </button>
                } @else {
                  <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
                    <kf-icon name="ic_search_24"></kf-icon>
                  </button>
                }
              </mat-form-field>
            </div>
          </div>
          <div tabindex="-1">
            @for (topic of searchedData.matching; track topic; let last = $last) {
              <item-topic-available
                [isLast]="last"
                [topicItem]="topic"
                [isTopicExpanded]="getIsExpandedTopic(topic.id)"
                (topicExpanded)="onTopicExpanded($event)"
                (topicSelected)="onTopicSelected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
              ></item-topic-available>
            }
            @if (showDivider) {
              <hr class="horizontal-line my-4" />
            }
            @for (topic of searchedData.remainder; track topic; let last = $last) {
              <item-topic-available
                [grayed]="true"
                [isLast]="last"
                [topicItem]="topic"
                [isTopicExpanded]="getIsExpandedTopic(topic.id)"
                (topicSelected)="onTopicSelected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
              ></item-topic-available>
            }
          </div>
        </section>
        <section class="selected-side">
          <div class="pl-4 pr-4">
            @if (selectedTopicsAmount < 1) {
              <p class="d-flex align-items-center">
                <kf-icon name="ic_infozeichen_mit_kreis_24" class="mr-2" aria-hidden="true"></kf-icon>
                <span class="align-left">Bitte wählen Sie aus den verfügbaren Themen aus!</span>
              </p>
            }

            @if (data?.selected.length) {
              <div cdkDropList role="list" (cdkDropListDropped)="drop($event)">
                @for (subtopic of data?.selected; track subtopic; let i = $index) {
                  @if (i === 0) {
                    <div class="d-flex align-items-center side-label">
                      <h3 class="font-weight-bold m-0">Reihenfolge bestimmen</h3>
                      <kf-icon
                        class="mx-2"
                        name="ic_infozeichen_mit_kreis_24"
                        kfTooltip="Reihenfolge per Drag & Drop anpassen"
                        kfTooltipShow="hover"
                        kfTooltipPosition="after"
                      ></kf-icon>
                    </div>
                  }
                  <item-subtopic-selected [subtopicItem]="subtopic"></item-subtopic-selected>
                }
              </div>
            }
          </div>
        </section>
      </div>
    </div>
  </div>
  <div class="overflow-indicator" aria-live="assertive"></div>
</div>

<ng-template #fullbubble>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find('--color-primary-500')"
      [attr.fill]="color.find('--color-primary-500')"
    />
  </svg>
</ng-template>

<ng-template #emptyBubble>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.99967" cy="9.99967" r="4.41667" stroke-width="2" [attr.stroke]="color.find('--color-grey-500')" />
  </svg>
</ng-template>
