import { Injectable } from '@angular/core';
import { SearchOverlay } from '@domain/app/search-overlay';
import { QuestionGroupOverviewItem, SubtopicOverviewItem, TopicOverviewItem } from '@domain/app/topic.domain';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import CompositionResponseItem = SearchOverlay.CompositionResponseItem;
import ContentElementsResponseItem = SearchOverlay.ContentElementResponseItem;
import QuestionWorldData = SearchOverlay.QuestionWorldData;
import ProductWorldItem = SearchOverlay.ProductWorldItem;
import ContentElementsItem = SearchOverlay.ContentElementsItem;
import CompositionElement = SearchOverlay.CompositionElement;

@Injectable()
export class SearchService {
  private readonly _searchQuery = new BehaviorSubject<string | undefined>(undefined);
  private readonly _getTopicsResponse = new BehaviorSubject<TopicOverviewItem[] | undefined>(undefined);
  private readonly _getContentElementsResponse = new BehaviorSubject<ContentElementsResponseItem[] | undefined>(
    undefined
  );
  private readonly _getCompositionsResponse = new BehaviorSubject<CompositionResponseItem[] | undefined>(undefined);

  public searchQueryValue = '';
  public selectionType: 'selected' | 'available' | 'all' = 'selected';

  constructor(
    private readonly queryService: QueryService,
    private readonly clientService: ClientService
  ) {}

  fetchData(): Observable<TopicOverviewItem[]> {
    return forkJoin([
      this.queryService.getSearchOverlayTopics(this.clientService.consultationId),
      this.queryService.getSearchOverlayCompositions(this.clientService.consultationId),
      this.queryService.getSearchOverlayThemas(this.clientService.consultationId),
    ]).pipe(
      tap(([topics, compositions, contentElements]) => {
        this._getTopicsResponse.next(topics);
        this._getCompositionsResponse.next(compositions);
        this._getContentElementsResponse.next(contentElements);
      }),
      map(x => x[0])
    );
  }

  get questionWorldData(): Observable<QuestionWorldData> {
    return combineLatest([this._getTopicsResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.getTopicsResponseToQuestionWorldData(response, query);
      })
    );
  }

  get productWorldData(): Observable<ProductWorldItem[]> {
    return combineLatest([this._getCompositionsResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.mapCompositionResponseToProductWorldData(response, query);
      })
    );
  }

  get contentElementsData(): Observable<ContentElementsItem[]> {
    return combineLatest([this._getContentElementsResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.mapContentElementsResponseToContentElementsData(response, query);
      })
    );
  }

  set searchQuery(value: string | undefined) {
    this._searchQuery.next(value.trim());
    this.searchQueryValue = value.trim();
  }

  private getTopicsResponseToQuestionWorldData(
    response: TopicOverviewItem[],
    query: string | undefined
  ): QuestionWorldData {
    this.searchQueryValue = query;
    if (!query) {
      return {
        topics: response.filter(topic => {
          if (this.selectionType === 'selected') {
            return topic.selected;
          } else if (this.selectionType === 'available') {
            return !topic.selected;
          }
          return topic;
        }),
        isQuestionGroupInSearchResult: () => true,
        topicHasSearchResults: () => true,
        subtopicHasSearchResults: () => true,
      };
    }

    const isQuestionGroupInSearchResult = (questionGroup: QuestionGroupOverviewItem) =>
      this.questionGroupAppliesToSearchQuery(questionGroup, query);

    const subtopicHasSearchResults = (subtopic: SubtopicOverviewItem) =>
      subtopic.title.toLowerCase().includes(query.toLowerCase()) ||
      subtopic.questionGroups.some(isQuestionGroupInSearchResult);

    const topicHasSearchResults = (topic: TopicOverviewItem) =>
      topic.title.toLowerCase().includes(query.toLowerCase()) || topic.subtopics.some(subtopicHasSearchResults);

    return {
      topics: response,
      isQuestionGroupInSearchResult,
      topicHasSearchResults,
      subtopicHasSearchResults,
    };
  }

  private questionGroupAppliesToSearchQuery(questionGroup: QuestionGroupOverviewItem, query: string): boolean {
    const tags = questionGroup.tags?.some(tag => this.appliesToSearchQuery(tag, query));
    const name = this.appliesToSearchQuery(questionGroup.title, query);
    return tags || name;
  }

  private mapCompositionResponseToProductWorldData(
    response: CompositionResponseItem[],
    query: string | undefined
  ): ProductWorldItem[] {
    const toCompositionElements = (composition: CompositionResponseItem) => {
      const elements = [];
      const { mainProduct, name, id, tags, transitions, tasks } = composition;
      const attr = {
        compositionId: id,
        compositionName: name,
        mainProductName: mainProduct?.name || name,
        tags,
      };

      tasks.forEach(task => elements.push({ ...task, ...attr, type: 'task' }));
      transitions.forEach(transition => elements.push({ ...transition, ...attr, type: 'transition' }));
      mainProduct?.variants.forEach(variant => elements.push({ ...variant, ...attr, type: 'product' }));
      mainProduct?.additionals.forEach(additional => elements.push({ ...additional, ...attr, type: 'product' }));
      if (mainProduct?.variants.length === 0) {
        elements.push({ ...mainProduct, ...attr, type: 'product' });
      }
      return elements;
    };

    const appliesToQuery = (compositionElement: CompositionElement): boolean => {
      const { tags, name, compositionName, mainProductName } = compositionElement;
      if (query) {
        return (
          tags?.some(tag => this.appliesToSearchQuery(tag, query)) ||
          this.appliesToSearchQuery(name, query) ||
          this.appliesToSearchQuery(compositionName, query) ||
          this.appliesToSearchQuery(mainProductName, query)
        );
      } else {
        return tags?.includes('favorite');
      }
    };

    return response
      .map(composition => toCompositionElements(composition))
      .flatMap(compositionElements => compositionElements)
      .filter(compositionElement => appliesToQuery(compositionElement))
      .map(compositionElement => ({
        name: compositionElement.name,
        type: compositionElement.type as ProductWorldItem['type'],
        id: compositionElement.id,
        mainName: compositionElement.mainProductName,
        compositionId: compositionElement.compositionId,
        compositionName: compositionElement.compositionName,
      }));
  }

  private mapContentElementsResponseToContentElementsData(
    response: ContentElementsResponseItem[],
    query: string
  ): ContentElementsItem[] {
    const appliesToQuery = (elem: ContentElementsResponseItem): boolean => {
      const { tags, name } = elem;
      if (query) {
        return this.appliesToSearchQuery(name, query) || tags?.some(tag => this.appliesToSearchQuery(tag, query));
      } else {
        return tags?.includes('favorite');
      }
    };

    return response
      .filter(elem => appliesToQuery(elem))
      .map(elem => ({
        id: elem.id,
        type: 'content-element',
        name: elem.name,
        mainName: elem.name,
        itemReference: elem.itemReference,
      }));
  }

  private appliesToSearchQuery(str: string, query: string) {
    return str.toLowerCase().includes(query.toLowerCase());
  }
}
