import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { color, libIcons } from 'bgzv-frontend-library';

@Component({
  selector: 'item-side-datafield-button',
  templateUrl: './item-side-datafield-button.component.html',
  styleUrls: ['./item-side-datafield-button.component.scss'],
})
export class ItemSideDatafieldButtonComponent implements OnInit {
  @Output() click = new EventEmitter<Event>();

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor() {}

  ngOnInit(): void {}

  public onClick(ev: Event): void {
    this.click.emit(ev);
  }
}
