import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { baseConfig, buttonAgenda, buttonStartOrder } from '@utils/footer-config';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import 'moment/locale/de';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'overlay-cart',
  templateUrl: './overlay-cart.component.html',
  styleUrls: ['./overlay-cart.component.scss'],
  standalone: false,
})
export class OverlayCartComponent implements OnInit, OnDestroy {
  private subAlive = new Subject<void>();
  private loading = false;

  public allRecommendedChecked: boolean = true;

  public footerConfig: FooterConfig = baseConfig;

  readonly moment = moment;

  constructor(
    private router: Router,
    private actionService: ActionService,
    private clientService: ClientService,
    private configService: ConfigService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.footerButtonsFactory();

    this.actionService.action.pipe(takeUntil(this.subAlive)).subscribe(action => {
      if (action.target === 'overlay-cart') {
        this.handleAction(action);
      }
      this.chg.detectChanges();
    });

    this.loadingService.isLoading.pipe(takeUntil(this.subAlive)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
      this.footerButtonsFactory();
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.subAlive)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.subAlive)).subscribe(config => {
      this.footerConfig = config;
    });
  }

  ngOnDestroy(): void {
    this.subAlive.next();
    this.subAlive.complete();
  }

  public async finishConsultation(): Promise<void> {
    this.clientService.setSolutionBasketCount();
    await this.doAction('overlay-main', 'close');
    this.router.navigate([RoutingPathMain.Checkout]);
  }

  public handleClose() {
    this.clientService.setSolutionBasketCount();
    this.doAction('overlay-main', 'close');
    this.doAction('side-recommendation', 'get-recommendation');
  }

  public createIndivTask() {
    this.doAction('cart-item', 'indiv-task-selected');
  }

  public createIndivTransition() {
    this.doAction('cart-item', 'indiv-transition-selected');
  }

  public selectAllRecommended(event: Event) {
    event.preventDefault();
    this.doAction('cart-item', 'all-recommended-selected', true);
  }

  /// ------------------------------------------------ ///
  /// --------------- FOOTER HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }

      if (event.id === 'cart-start-order') {
        this.finishConsultation();
      }
    }
  }

  private footerButtonsFactory(): void {
    if (!this.footerConfig) {
      return;
    }
    const setting = this.configService.getSettingsValue('buttonLabelCheckoutStart');
    const defaultSetting = this.configService.getSettingsDefaultValue('buttonLabelCheckoutStart');
    const label = setting ?? (defaultSetting ? defaultSetting : 'Bestellprozess starten');
    buttonStartOrder.inputs.config.label = label;
    buttonStartOrder.inputs.config.disabled = this.loading;

    const currentConfig = cloneDeep(this.footerConfig);
    const rightButtonElements = [buttonStartOrder];

    currentConfig.right.elements = rightButtonElements;
    if (!this.footerService.isManagedFooter) {
      currentConfig.left.elements = [buttonAgenda];
    }

    this.footerService.submitFooterConfig(currentConfig);
  }

  private handleAction(action: Action): void {
    if (action.action === 'show-all-recommended') {
      this.allRecommendedChecked = action.options.allRecommendedChecked;
    }
  }

  private async doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    await this.actionService.setAction(data);
  }
}
