import { Injectable } from '@angular/core';
import { environment } from '@environment/environment.prod';
import { QueryService } from '@services/query-service/query.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public isVp = environment.platform === 'vp';
  constructor(private queryService: QueryService) {}

  //get the media content via the URL of the media
  public async getMediaContent(url: string, isDocument = false): Promise<string> {
    if (this.isVp && url.includes('./assets')) {
      return url;
    }

    try {
      const mediaData = await lastValueFrom(this.queryService.getMediaContent(url));
      if (mediaData.type.includes('mp4') || mediaData.type.includes('pdf')) {
        if (window.URL) {
          return window.URL.createObjectURL(mediaData);
        } else if (window.webkitURL) {
          return window.webkitURL.createObjectURL(mediaData);
        }
        return (await this.blobToBase64(mediaData)) as string;
      } else {
        const base64 = await this.blobToBase64(mediaData);
        return base64 as string;
      }
    } catch (error) {
      return '';
    }
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      const result = reader.readAsDataURL(blob);

      return result;
    });
  }

  public docImageType(mediaData): boolean {
    return mediaData.type === 'image/jpeg' || mediaData.type === 'image/png';
  }
}
