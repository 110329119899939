@if (!hidden) {
  <div
    class="d-flex no-select w-100 h-100 justify-content-center align-items-center"
    dragAndDropUpload
    (fileDropped)="checkBeforeUpload($event)"
  >
    <label for="bgzv-document-upload" class="sr-only">Datei</label>
    <input
      id="bgzv-document-upload"
      type="file"
      autocomplete="off"
      [accept]="fileAccept"
      (click)="resetPath($event)"
      (change)="extractFileFromEvent($event)"
      tabindex="-1"
    />
    @if (!compact) {
      <div class="d-flex flex-column align-items-center" style="margin: 12rem" [style.text-align]="'center'">
        <img class="mb-4 upload-icon" src="{{ assetPath }}/images/document-upload.svg" alt="" />
        <h3 class="mat-body-1 font-weight-bold mb-4">Dokumente hinzufügen</h3>
        <div class="mb-2" style="text-align: center">
          Dokumente können auch einzeln per Drag & Drop auf diese Fläche hinzugefügt werden.
        </div>
        <div class="mat-small mb-4">Unterstützte Dateiformate: {{ fileAccept }} / Max. Größe: {{ maxFileSize }}</div>

        <button
          [attr.data-testcafe]="'overlayNotes-button-uploadDocument'"
          mat-flat-button
          color="primary"
          (click)="triggerUploadDialog()"
        >
          <span>Dokument hinzufügen</span>
        </button>

        @if (errorMessage) {
          <mat-error class="mt-4"><kf-input-error [message]="errorMessage"></kf-input-error></mat-error>
        }
      </div>
    } @else {
      <div class="d-flex flex-column align-items-center" [style.text-align]="'center'">
        <h3 class="mat-body-1 font-weight-bold mb-4">{{ fileTypeName }} hinzufügen</h3>
        <div class="mb-2" style="text-align: center">
          Dokumente können auch per Drag & Drop auf diese Fläche hinzugefügt werden.
        </div>
        <div class="mat-small mb-4">Unterstützte Dateiformate: {{ fileAccept }} / Max. Größe: {{ maxFileSize }}</div>

        <button
          [attr.data-testcafe]="'overlayNotes-button-uploadDocument'"
          mat-flat-button
          color="primary"
          (click)="triggerUploadDialog()"
        >
          <span>Dokument hinzufügen</span>
        </button>

        @if (errorMessage) {
          <mat-error class="mt-4"><kf-input-error [message]="errorMessage"></kf-input-error></mat-error>
        }
      </div>
    }
  </div>
} @else {
  <input
    style="display: none; width: 0; height: 0"
    id="bgzv-document-upload"
    type="file"
    autocomplete="off"
    [accept]="fileAccept"
    (click)="resetPath($event)"
    (change)="extractFileFromEvent($event)"
    tabindex="-1"
  />
}
