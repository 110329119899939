import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import {
  ExpandedTopic,
  SelectedTopicItem,
  SubtopicOverviewItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathOverlay } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import { SearchService } from '@services/search-service/search.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';
import { TopicService } from '@services/topic-service/topic-service';
import { baseConfig, buttonAgendaEditCancel, buttonAgendaEditSave } from '@utils/footer-config';
import { cloneDeep, uniqBy } from 'lodash-es';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface SelectedTopicIds {
  consultant: string[];
  customer: string[];
  consultation: string[];
}
interface SearchInterface {
  matching: TopicOverviewItem[];
  remainder: TopicOverviewItem[];
}

@Component({
  standalone: false,
  selector: 'overlay-agenda-edit',
  templateUrl: './overlay-agenda-edit.component.html',
  styleUrl: './overlay-agenda-edit.component.scss',
})
export class OverlayAgendaEditComponent {
  @Input() inputValues: TopicOverviewItem[];
  @Input() expandedTopics: ExpandedTopic[];
  @Input() selectedSubtopicIds: SelectedTopicIds = {
    consultant: [],
    customer: [],
    consultation: [],
  };

  @Output() closed = new EventEmitter(null);
  @Output() topicSelected = new EventEmitter<TopicOverviewItem>();
  @Output() subtopicSelected = new EventEmitter<SubtopicOverviewItem>();
  @Output() subtopicDeselected = new EventEmitter<SubtopicOverviewItem>();
  @Output() subtopicsReordered = new EventEmitter<SubtopicOverviewItem[]>();
  @Output() topicDeselect = new EventEmitter<TopicOverviewItem>();
  @Output() expandTopic = new EventEmitter<ExpandedTopic>();

  public searchedData: SearchInterface = {
    matching: [],
    remainder: [],
  };
  public consultationSubtopics: TopicSelectionResponse;
  public consultantSubtopics: TopicSelectionResponse;
  public customerSubtopics: TopicSelectionResponse;
  public searchTopicId: string = '-1';

  readonly consultationStatusEnum = ConsultationStatusEnum;

  private destroySubs = new Subject<void>();
  private searchData: TopicSelectionResponse | SubtopicSelectionResponse = { available: [] };
  private _searchQuery = '';
  private titleAdded: boolean = false;
  private footerConfig: FooterConfig;
  private loading: boolean;

  constructor(
    private readonly searchService: SearchService,
    private queryService: QueryService,
    private clientService: ClientService,
    private topicService: TopicService,
    private contextService: ContextService,
    private actionService: ActionService,
    private loadingService: LoadingService,
    private noteService: NoteService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService,
    private snackBarService: SnackBarTemplatesService,
    private chg: ChangeDetectorRef,
    private router: Router
  ) {}

  async ngOnInit() {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });

    this.searchTopicId = this.topicService.agendaTopicClicked;
    const topics = await lastValueFrom(this.searchService.fetchData().pipe(takeUntil(this.destroySubs)));

    await this.topicService.mapTopicIcons(topics);
    this.expandedTopics = topics
      .filter(top => !top.availableForPreliminary)
      .map(top => ({
        [top.id]: true,
      }));

    this.requestSubtopicData();
    this.searchedData.matching = undefined;

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });

    this.footerRightButtonFactory();
  }

  async ngOnDestroy(): Promise<void> {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onTopicExpanded(topic: ExpandedTopic) {
    const topicId = Object.keys(topic)[0];
    const oldExpanded = [...this.expandedTopics];
    const idxOfTopic = oldExpanded.findIndex(obj => obj.hasOwnProperty(topicId));
    this.expandedTopics = Object.assign(oldExpanded, { [idxOfTopic]: topic });
  }

  public getIsExpandedTopic(topicId: string): boolean {
    const expObj = this.expandedTopics?.find(obj => obj.hasOwnProperty(topicId));
    return expObj ? expObj[topicId] : true;
  }

  public getTestcafeId(id = '') {
    return `${id?.replace(/ /g, '')}`;
  }

  private async putRequestObject(): Promise<void> {
    const requestObject = this.subtopicRequestObjectFactory(this.selectedSubtopicIds);

    await lastValueFrom(
      this.queryService.putSubtopicDataByConsultationId(this.clientService.consultationId, requestObject)
    );

    await this.requestSubtopicData();

    this.doAction('side-recommendation', 'get-recommendation', this.selectedSubtopicIds.consultation);
  }

  private async doAction(target: string = '', action: string = '', options?: any): Promise<void> {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }

    await this.actionService.setAction(data);
  }

  private async requestSubtopicData(): Promise<void> {
    const subtopics = await lastValueFrom(
      this.queryService.getSubtopicDataByConsultationId(this.clientService.consultationId)
    );
    this.topicService.subtopicData = subtopics;

    this.consultationSubtopics = this.topicService.consultationSubtopics(subtopics);
    this.consultantSubtopics = this.topicService.consultantSubtopics(subtopics);
    this.customerSubtopics = this.topicService.customerSubtopics(subtopics);

    this.selectedSubtopicIds.consultation = this.topicService.consultationSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.consultant = this.topicService.consultantSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.customer = this.topicService.customerSelectedSubtopicIds(subtopics);
  }

  private subtopicRequestObjectFactory(data: SelectedTopicIds): SelectedTopicItem {
    return {
      selectedSubtopicIds: [...data.consultant, ...data.customer, ...data.consultation],
    } as SelectedTopicItem;
  }

  private onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }

      if (event.id === 'agenda-edit-save') {
        this.saveAgendaSelection();
      } else if (event.id === 'agenda-edit-cancel') {
        this.doAction('overlay-main', 'close');
      }
    }
  }

  private footerRightButtonFactory(modified: boolean = false): void {
    if (
      !!this.footerConfig?.drawing ||
      this.footerConfig?.right.isHidden ||
      (!!this.footerConfig?.leftCollapsable && !this.footerConfig?.leftCollapsable.isHidden)
    ) {
      this.footerConfig = baseConfig;
    }

    if (!!this.footerConfig?.leftCollapsable) {
      this.footerService.fixCollapsibleSectionDisplay();
    }

    const currentConfig = cloneDeep(this.footerConfig);
    const currentEditSave = cloneDeep(buttonAgendaEditSave);
    currentEditSave.inputs.config.disabled = !modified;
    currentConfig.right.elements = [buttonAgendaEditCancel, currentEditSave];
    this.footerService.submitFooterConfig(currentConfig);
  }

  private async saveAgendaSelection(): Promise<void> {
    this.topicService.firstQuestionGroupId = '-1';
    this.topicService.agendaTopicClicked = '-1';

    this.contextService.lastQuestionGroupId.next('-1');
    await this.putRequestObject();

    const selectedSubtopicIds = [
      ...this.selectedSubtopicIds.consultation,
      ...this.selectedSubtopicIds.consultant,
      ...this.selectedSubtopicIds.customer,
    ];

    if (
      !this.contextService.context.getValue().includes(`/agenda/`) &&
      !this.topicService.selectedSubtopicsAndTopicsMatch(selectedSubtopicIds) &&
      !(this.contextService.currentMainContext === RoutingPathMain.TopicSummary)
    ) {
      this.topicService.updateTopicsData();
    }
    this.noteService.compareNotesToTopics(selectedSubtopicIds);

    const currentSubtopicRemoved = !selectedSubtopicIds.find(x => x === this.panelService.currentSubtopicId);
    if (currentSubtopicRemoved) {
      await this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.bankHubId}`]);
    }

    this.doAction('consultation', 'refresh-question-group');
    if (
      this.contextService.currentOverlayContext === RoutingPathOverlay.Cart ||
      this.contextService.currentMainContext === RoutingPathMain.TopicSummary
    ) {
      this.doAction('cart-item', 'reload-cart');
    }
    if (this.contextService.currentMainContext === RoutingPathMain.Checkout) {
      this.doAction('checkout', 'refresh-checkout');
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('topic-summary', 'update-next-subtopic', this.panelService.currentSubtopicId);
    }

    const selectedSubtopics = [
      ...this.consultationSubtopics.selected,
      ...this.consultantSubtopics.selected,
      ...this.customerSubtopics.selected,
    ];

    const panelToolConfig = this.panelService.panelConfigFromTopicOverviewItems(selectedSubtopics);
    this.panelService.submitPanelToolConfig(panelToolConfig);

    const consultationButtonDisabled =
      selectedSubtopicIds.length === 0 || this.clientService.consultationStatus === ConsultationStatusEnum.archived;

    this.doAction('shared-footer-agenda', 'start-button', {
      disabled: consultationButtonDisabled,
    });

    this.snackBarService.openSnackBar({ type: SnackbarType.SUCCESS, message: 'Auswahl gespeichert.' });
    this.doAction('overlay-main', 'close');

    this.clientService.setSolutionBasketCount();
  }

  //////////////////////////////////////////////////
  //***********************************************/
  //*****************SEARCHING*********************/
  //***********************************************/
  //////////////////////////////////////////////////

  public handleSearchData(): void {
    if (!this.searchService.searchQueryValue) {
      this.searchedData.matching = undefined;
      this.searchedData.remainder = undefined;
      return;
    }

    this.searchData.available = [
      ...this.availableConsultationSubtopics,
      ...this.availableConsultantSubtopics,
      ...this.availableCustomerSubtopics,
    ];

    this.searchService.questionWorldData.pipe(takeUntil(this.destroySubs)).subscribe(questionData => {
      const t = questionData.topics.filter(x =>
        x.title.toLowerCase().includes(this.searchService.searchQueryValue.toLowerCase())
      );
      const s = questionData.topics.flatMap(x => x.subtopics)?.filter(x => questionData.subtopicHasSearchResults(x));
      this.searchedData.matching = this.getMatchingData(t, s);
      this.searchedData.remainder = this.getRemainderData(t, s);
    });
  }

  private getMatchingData(t: TopicOverviewItem[], s: SubtopicOverviewItem[]): TopicOverviewItem[] {
    const topicData = this.searchData.available.filter(x => t.findIndex(y => y.id === x.id) >= 0);

    const subtopicData = this.searchData.available
      .map(topic => {
        const subtopics = topic.subtopics.filter(x => s.findIndex(y => y.id === x.id) >= 0);
        let selectedSubtopics = subtopics;
        return {
          ...topic,
          subtopics: selectedSubtopics,
        };
      })
      .filter(x => x.subtopics.length > 0);

    return uniqBy([...topicData, ...subtopicData], item => {
      return item.id;
    }).sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
  }

  private getRemainderData(t, s): TopicOverviewItem[] {
    return this.searchData.available
      .map(topic => {
        const subtopics = topic.subtopics.filter(x => s.findIndex(y => y.id === x.id) === -1);
        let selectedSubtopics = subtopics;
        return {
          ...topic,
          subtopics: selectedSubtopics,
        };
      })
      .filter(x => x.subtopics.length > 0 && t.findIndex(y => y.id === x.id) === -1);
  }

  //////////////////////////////////////////////////
  //***********************************************/
  //*****************SELECTION*********************/
  //***********************************************/
  //////////////////////////////////////////////////

  public onTopicSelected(topic: TopicOverviewItem) {
    topic.subtopics.map(subtopic => {
      if (!subtopic.selected) {
        if (!topic.availableForPreliminary && !topic.consultantTopic) {
          this.consultationSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['consultation'] = [
            ...new Set([...this.selectedSubtopicIds['consultation'], ...topic.subtopics.map(x => x.id)]),
          ];
        } else if (topic.consultantTopic) {
          this.consultantSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['consultant'] = [
            ...new Set([...this.selectedSubtopicIds['consultant'], ...topic.subtopics.map(x => x.id)]),
          ];
        } else if (topic.availableForPreliminary) {
          this.customerSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['customer'] = [
            ...new Set([...this.selectedSubtopicIds['customer'], ...topic.subtopics.map(x => x.id)]),
          ];
        }
        subtopic.icon = topic.icon;
        subtopic.selected = true;
      }
    });
    this.footerRightButtonFactory(true);
  }

  public onTopicDeselect(topic: TopicOverviewItem) {
    const forDeselectSubtopicsIds = new Set(topic.subtopics.map(obj => obj.id));

    if (!topic.availableForPreliminary && !topic.consultantTopic) {
      this.consultationSubtopics.selected = this.consultationSubtopics.selected.filter(
        x => !forDeselectSubtopicsIds.has(x.id)
      );
      this.selectedSubtopicIds['consultation'] = [
        ...this.selectedSubtopicIds['consultation'].filter(id => !forDeselectSubtopicsIds.has(id)),
      ];
    } else if (topic.availableForPreliminary) {
      this.customerSubtopics.selected = this.customerSubtopics.selected.filter(x => !forDeselectSubtopicsIds.has(x.id));
      this.selectedSubtopicIds['customer'] = [
        ...this.selectedSubtopicIds['customer'].filter(id => !forDeselectSubtopicsIds.has(id)),
      ];
    } else if (topic.consultantTopic) {
      this.consultantSubtopics.selected = this.consultantSubtopics.selected.filter(
        x => !forDeselectSubtopicsIds.has(x.id)
      );
      this.selectedSubtopicIds['consultant'] = [
        ...this.selectedSubtopicIds['consultant'].filter(id => !forDeselectSubtopicsIds.has(id)),
      ];
    }
    topic.subtopics.map(x => (x.selected = false));
    this.footerRightButtonFactory(true);
  }

  public onSubtopicSelected(topic: TopicOverviewItem, subtopic: SubtopicOverviewItem) {
    subtopic.selected = true;
    if (!topic.availableForPreliminary && !topic.consultantTopic) {
      this.consultationSubtopics.selected.push(subtopic);
      this.selectedSubtopicIds['consultation'].push(subtopic.id);
    } else if (topic.consultantTopic) {
      this.consultantSubtopics.selected.push(subtopic);
      this.selectedSubtopicIds['consultant'].push(subtopic.id);
    } else if (topic.availableForPreliminary) {
      this.customerSubtopics.selected.push(subtopic);
      this.selectedSubtopicIds['customer'].push(subtopic.id);
    }
    subtopic.icon = topic.icon;
    this.footerRightButtonFactory(true);
  }

  public onSubtopicDeselected(subtopic: SubtopicOverviewItem) {
    subtopic.selected = false;
    if (!subtopic.availableForPreliminary && !subtopic.consultantSubtopic) {
      this.consultationSubtopics.selected = this.consultationSubtopics.selected.filter(x => x.id !== subtopic.id);
      this.selectedSubtopicIds['consultation'] = this.selectedSubtopicIds['consultation'].filter(
        x => x !== subtopic.id
      );
      this.consultationSubtopics.available.flatMap(x => x.subtopics).find(y => y.id === subtopic.id).selected =
        subtopic.selected;
    } else if (subtopic.consultantSubtopic) {
      this.consultantSubtopics.selected = this.consultantSubtopics.selected.filter(x => x.id !== subtopic.id);
      this.selectedSubtopicIds['consultant'] = this.selectedSubtopicIds['consultant'].filter(x => x !== subtopic.id);
      this.consultantSubtopics.available.flatMap(x => x.subtopics).find(y => y.id === subtopic.id).selected =
        subtopic.selected;
    } else if (subtopic.availableForPreliminary) {
      this.customerSubtopics.selected = this.customerSubtopics.selected.filter(x => x.id !== subtopic.id);
      this.selectedSubtopicIds['customer'] = this.selectedSubtopicIds['customer'].filter(x => x !== subtopic.id);
      this.customerSubtopics.available.flatMap(x => x.subtopics).find(y => y.id === subtopic.id).selected =
        subtopic.selected;
    }
    this.footerRightButtonFactory(true);
  }

  //////////////////////////////////////////////////
  //***********************************************/
  //*****************REORDERING********************/
  //***********************************************/
  //////////////////////////////////////////////////

  public drop(event: CdkDragDrop<TopicOverviewItem[]>, type: string) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.onSelectedSubtopicsReordered(event.container.data, type);
  }

  private onSelectedSubtopicsReordered(subtopics: TopicOverviewItem[], type: string) {
    this.selectedSubtopicIds[type] = subtopics.map(x => x.id);

    switch (type) {
      case 'consultant': {
        this.consultantSubtopics.selected.map(x => (x.orderNumber = this.consultantSubtopics.selected.indexOf(x) + 1));
        break;
      }
      case 'customer': {
        this.customerSubtopics.selected.map(x => (x.orderNumber = this.customerSubtopics.selected.indexOf(x) + 1));
        break;
      }
      default: {
        this.consultationSubtopics.selected.map(
          x => (x.orderNumber = this.consultationSubtopics.selected.indexOf(x) + 1)
        );
      }
    }
    this.footerRightButtonFactory(true);
  }

  //////////////////////////////////////////////////
  //***********************************************/
  //*******************SETTER/GETTER***************/
  //***********************************************/
  //////////////////////////////////////////////////

  set searchQuery(value: string) {
    if (value !== '') {
      this.searchService.searchQuery = value;
    } else {
      this.searchService.searchQueryValue = undefined;
    }

    this._searchQuery = value;
  }

  get searchQuery(): string {
    let title = '';
    if (this.searchTopicId !== '-1' && this.consultantSubtopics) {
      title = this.consultationSubtopics?.available?.find(x => x.id === this.searchTopicId)?.title || 'Default Title';
      if (!this.titleAdded) {
        this.searchQuery = title;
        this.handleSearchData();
      }
      this.titleAdded = true;
    }

    if (this._searchQuery === '') {
      this.searchedData.matching = undefined;
      this.searchedData.remainder = undefined;
    }

    return this._searchQuery;
  }

  get showDivider(): boolean {
    return this.contextService.currentMode === 'prep';
  }

  get availableConsultationSubtopics(): TopicOverviewItem[] {
    return this.consultationSubtopics?.available;
  }
  get availableConsultantSubtopics(): TopicOverviewItem[] {
    return this.consultantSubtopics?.available;
  }
  get availableCustomerSubtopics(): TopicOverviewItem[] {
    return this.customerSubtopics?.available;
  }

  get selectedConsultationSubtopics(): TopicOverviewItem[] {
    return this.consultationSubtopics?.selected;
  }
  get selectedConsultantSubtopics(): TopicOverviewItem[] {
    return this.consultantSubtopics?.selected;
  }
  get selectedCustomerSubtopics(): TopicOverviewItem[] {
    return this.customerSubtopics?.selected;
  }

  get consultationStatus(): ConsultationStatusEnum {
    return this.clientService.consultationStatus;
  }

  get isPrepMode(): boolean {
    return this.clientService.consultationStatusType === 'prep' || this.contextService.currentMode === 'prep';
  }

  get isGrayed(): boolean {
    return !!this.searchedData.matching;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get noSubtopicSelected(): boolean {
    return (
      !this.selectedConsultationSubtopics?.length &&
      !this.selectedConsultantSubtopics?.length &&
      !this.selectedCustomerSubtopics?.length
    );
  }

  get anyConsultationSubtopicsSelected(): boolean {
    return this.selectedConsultationSubtopics?.length > 0;
  }

  get anyConsultantSubtopicsSelected(): boolean {
    return (
      this.consultationStatus === ConsultationStatusEnum.inPreparation ||
      (this.consultationStatus === ConsultationStatusEnum.preparationSent &&
        this.selectedConsultantSubtopics?.length > 0)
    );
  }

  get anyCustomerSubtopicsSelected(): boolean {
    return (
      this.consultationStatus === ConsultationStatusEnum.inPreparation && this.selectedCustomerSubtopics?.length > 0
    );
  }
}
