/**
 * set focus on header initially for keyboard navigation
 * prevent display of outline on focus
 * @param selector the selector of the element to focus
 **/
export function setInitialFocus(selector: string): void {
  const elem = document.querySelector(selector) as HTMLElement;
  if (elem) {
    elem.focus();
    elem.style.outline = 'none';
  }
}
