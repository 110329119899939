@if (viewMode === 'list') {
  <aside class="d-flex flex-column app-height align-items-center kf-bg product-extras-container-width">
    <div class="d-flex flex-column w-100 app-height">
      <header class="d-flex w-100 justify-content-between side-header">
        <div class="d-flex flex-grow-1 align-items-center">
          <div class="d-flex flex-column">
            <h2 class="text-truncate mb-0 side-heading">Weitere Lösungen auswählen</h2>
          </div>
        </div>
      </header>
      <div class="d-flex flex-column side-container" [class.side-footer-margin]="useMargin">
        @if (compositionData) {
          <div class="d-flex flex-column w-100">
            <h2 class="mr-3 mb-2 mt-4">Ergänzende Produkte</h2>
            @for (additional of compositionData.mainProduct.additionals; track additional) {
              @if (!additional.recommended) {
                <ng-template
                  *ngTemplateOutlet="
                    productElement;
                    context: { $implicit: additional, noShadow: true, type: 'product' }
                  "
                >
                </ng-template>
              }
            }
            @if (compositionData.tasks.length > 0) {
              <h2 class="mr-3 mb-2 mt-4">Ergänzende Maßnahmen</h2>
              @for (task of compositionData.tasks; track task) {
                <ng-template
                  *ngTemplateOutlet="productElement; context: { $implicit: task, noShadow: true, type: 'task' }"
                ></ng-template>
              }
            }
            @if (compositionData.transitions.length > 0) {
              <h2 class="mr-3 mb-2 mt-4">Ergänzende Expertengespräche</h2>
              @for (transition of compositionData.transitions; track transition) {
                <ng-template
                  *ngTemplateOutlet="
                    productElement;
                    context: { $implicit: transition, noShadow: true, type: 'transition' }
                  "
                ></ng-template>
              }
            }
          </div>
        }
      </div>
    </div>

    <button
      class="kf-popup-close"
      mat-icon-button
      aria-label="Panel schließen"
      (click)="handleClose()"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>
  </aside>
}

<!-- ------ PRODUCT ELEMENT TEMPLATE ------ -->
<ng-template #productElement let-data let-noShadow="noShadow" let-type="type">
  <div
    class="d-flex flex-column justify-content-center composition-item-border pl-3 mb-2 kf-bg"
    [ngClass]="{
      'composition-no-bottom-border': data.additionals && data.additionals.length > 0,
      'vr-box-shadow-small': !noShadow,
    }"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div
        class="d-flex align-items-center"
        [style.maxWidth.%]="productElementBox.checked && data.quantityVisibility ? 50 : 85"
      >
        <mat-checkbox
          #productElementBox
          [checked]="data.selected"
          [disableRipple]="true"
          (click)="$event.stopPropagation()"
          (change)="setProductSelectedState(data.id, $event, type)"
          style="margin-right: 0.75rem"
          [disabled]="loading"
          [color]="'grey'"
          ><span [innerHTML]="data.name || 'NO TITLE' | safeHtml"></span
        ></mat-checkbox>
        @if (data?.recommended) {
          <div class="tag-info tag-neutral mb-1 ml-3">
            <div class="tag-info-text">Empfohlen</div>
          </div>
        }
      </div>

      <div
        class="d-flex justify-content-between align-items-center"
        [style.paddingLeft.px]="noShadow ? 2 : 0"
        [style.width]="'auto'"
      >
        @if (productElementBox.checked) {
          <ng-template *ngTemplateOutlet="quantityConfig; context: { $implicit: data, id: data.id }"></ng-template>
        }

        <button
          class="ml-3"
          [attr.data-testcafe]="'itemComposition-button-details'"
          (click)="showElementDetails(data.id, type, productElementBox.checked)"
          aria-label="Details anzeigen"
          [kfTooltip]="data.name ? 'Details zu: ' + (data.name | safeHtml) : 'Details'"
          kfTooltipPosition="after"
          kfTooltipShow="hover"
          mat-icon-button
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- ------ QUANTITY TEMPLATE ------ -->
<ng-template #quantityConfig let-data let-id="id">
  <div class="d-flex align-items-center">
    @if (data.quantityVisibility) {
      <item-counter
        #productNum
        class="d-flex mr-4"
        [id]=""
        [value]="data.selected ? data.selectedQuantity : data.quantityDefaultValue"
        [disabled]="loading"
        [minValue]="0"
        [maxValue]="data.quantityMaxAmount ? data.quantityMaxAmount : 100"
        [testcafeData]="getTestcafeId(data.name)"
        (valueChange)="setProductQuantity(id, $event)"
      ></item-counter>
    }
    @if (data?.runningCost && data.fixCost) {
      <div class="mat-small payment" style="white-space: nowrap">
        {{
          (productNum ? productNum.currentNumber : 1) * data.fixCost.price | currency: 'EUR' : 'symbol' : '1.2-2' : 'de'
        }}
        <span class="payment-type">einm.</span>
      </div>
    }
    @if (data.runningCost && data.runningCost.paymentInterval === costType.monthly) {
      <div class="mat-small ml-1 payment" style="white-space: nowrap">
        {{
          (productNum ? productNum.currentNumber : 1) * data.runningCost.price
            | currency: 'EUR' : 'symbol' : '1.2-2' : 'de'
        }}
        <span class="payment-type">mtl.</span>
      </div>
    }
    @if (data.runningCost && data.runningCost.paymentInterval === costType.annually) {
      <div class="mat-small ml-1 payment" style="white-space: nowrap">
        {{
          (productNum ? productNum.currentNumber : 1) * data.runningCost.price
            | currency: 'EUR' : 'symbol' : '1.2-2' : 'de'
        }}
        <span class="payment-type">j.</span>
      </div>
    }
  </div>
</ng-template>

@if (viewMode === 'details' && currentDetailType === 'product') {
  <side-product-details [inputValues]="detailsInputValues" (closed)="viewMode = 'list'"></side-product-details>
}

@if (viewMode === 'details' && currentDetailType === 'task') {
  <side-task [inputValues]="detailsInputValues" (closed)="viewMode = 'list'"> </side-task>
}

@if (viewMode === 'details' && currentDetailType === 'transition') {
  <side-transition [inputValues]="detailsInputValues" (closed)="viewMode = 'list'"> </side-transition>
}
