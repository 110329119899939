@if (!quickstartMode && !this.startingPointMainConsultation) {
  <div
    class="d-flex flex-column app-height align-items-center justify-content-start"
    (window:resize)="onResize($event)"
    [attr.data-html2canvas-ignore]="isScreenshotting || null"
  >
    <marginals-header class="w-100"></marginals-header>

    <div class="d-flex position-relative w-100 h-100 content-container">
      <div class="w-100 ml-5 mr-4 d-flex flex-column align-items-center topic-overview-screen">
        <kf-headline
          [resetTopSpacing]="true"
          [headline]="titleText"
          [showBackButton]="false"
          class="whitespace-preline pt-4"
        ></kf-headline>
        <div class="w-100 d-flex flex-center">
          <ng-template *ngTemplateOutlet="start"></ng-template>
        </div>
      </div>
    </div>
  </div>
} @else {
  <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
}

<!-- All this should be part of config for the topic select -->
<ng-template #start>
  <!-- image in tile -->
  @if (tile) {
    <div class="boxed-content boxed-content-agenda">
      <kf-tile [interactive]="false">
        <div class="d-flex flex-center flex-column w-100 overflow-hidden" [class.invisible]="!imgLoaded && loading">
          <div
            class="position-relative topic-select-image"
            [style.width.px]="imgSize?.width"
            [style.paddingBottom.px]="imgSize?.height"
            [style.marginBottom.px]="imgSize?.height * -1"
          >
            @for (posData of topicPosEnum | keyvalue; track posData.key) {
              @if (compareTopicPos(posData.value, topicPosEnum.logo)) {
                @if (compareTopicPos(posData.value, topicPosEnum.bank)) {
                  <ng-template *ngTemplateOutlet="position; context: { $implicit: posData.value }"></ng-template>
                } @else {
                  <ng-template *ngTemplateOutlet="bank"></ng-template>
                }
              } @else {
                <ng-template *ngTemplateOutlet="logo"></ng-template>
              }
            }
          </div>
          <img #image alt="" class="image-content" [src]="topicSelectImagePath" (load)="onImageLoaded($event)" />
        </div>
      </kf-tile>
    </div>
  }

  <!-- image without tile -->
  @if (!tile) {
    <div class="d-flex flex-center flex-column w-100 overflow-hidden" [class.invisible]="!imgLoaded && loading">
      <div
        class="position-relative topic-select-image"
        [style.width.px]="imgSize?.width"
        [style.paddingBottom.px]="imgSize?.height"
        [style.marginBottom.px]="imgSize?.height * -1"
      >
        @for (posData of topicPosEnum | keyvalue; track posData.key) {
          @if (compareTopicPos(posData.value, topicPosEnum.logo)) {
            @if (compareTopicPos(posData.value, topicPosEnum.bank)) {
              <ng-template *ngTemplateOutlet="position; context: { $implicit: posData.value }"></ng-template>
            } @else {
              <ng-template *ngTemplateOutlet="bank"></ng-template>
            }
          } @else {
            <ng-template *ngTemplateOutlet="logo"></ng-template>
          }
        }
      </div>
      <img #image alt="" class="image-content" [src]="topicSelectImagePath" (load)="onImageLoaded($event)" />
    </div>
  }
</ng-template>

<ng-template #logo>
  <div
    class="d-flex position-absolute logo"
    id="logoContainer"
    [style.top.px]="getTopicPosTop(topicPosEnum.logo)"
    [style.left.px]="getTopicPosLeft(topicPosEnum.logo)"
  >
    @if (positionTopicMap.has(topicPosEnum.logo)) {
      <ng-template *ngTemplateOutlet="logoContent; context: { $implicit: positionTopicMap.get(topicPosEnum.logo) }">
      </ng-template>
    }
  </div>
</ng-template>

<ng-template #bank>
  <div
    class="d-flex position-absolute bank"
    id="bankContainer"
    [style.top.px]="getTopicPosTop(topicPosEnum.bank)"
    [style.left.px]="getTopicPosLeft(topicPosEnum.bank)"
  >
    @if (positionTopicMap.has(topicPosEnum.bank)) {
      <ng-template *ngTemplateOutlet="bankContent; context: { $implicit: positionTopicMap.get(topicPosEnum.bank) }">
      </ng-template>
    }
  </div>
</ng-template>

<ng-template #position let-position>
  @if (positionTopicMap.has(position)) {
    <div
      class="d-flex flex-center position-absolute topic"
      [style.top.px]="getTopicPosTop(position)"
      [style.left.px]="getTopicPosLeft(position)"
      [style.width.px]="getTopicWidth(position)"
      [style.height.px]="getTopicHeight(position)"
    >
      <ng-template *ngTemplateOutlet="topicContent; context: { $implicit: positionTopicMap.get(position) }">
      </ng-template>
    </div>
  }
</ng-template>

<ng-template #logoContent let-logoData>
  <button
    #content
    class="d-flex flex-column flex-center topic-select-box p-2 kf-bg cursor-pointer w-100 h-100"
    (click)="openTopicSelectMenu(logoData.id)"
    tabindex="1"
    kfTooltip="{{ customerName }} ({{ customerNumber }})"
    kfTooltipPosition="below"
    kfTooltipShow="hover_focus"
  >
    <img
      class="customer-logo"
      [style.height.px]="computeLogoHeight(topicPosEnum.logo)"
      [style.max-width.px]="computeLogoMaxWidth(topicPosEnum.logo)"
      [src]="customerLogo"
      alt="Logo der {{ customerName }}"
    />
  </button>
</ng-template>

<ng-template #bankContent let-bankData>
  <button
    #content
    class="d-flex flex-column flex-center topic-select-box p-2 kf-bg cursor-pointer w-100 h-100"
    (click)="openTopicSelectMenu(bankData.id)"
    tabindex="2"
    kfTooltip="{{ bankData.title }}"
    kfTooltipPosition="below"
    kfTooltipShow="hover_focus"
  >
    <img
      class="bank-logo-vr"
      [style.height.px]="computeLogoHeight(topicPosEnum.bank)"
      [style.max-width.px]="computeLogoMaxWidth(topicPosEnum.bank)"
      [src]="bankVrLogo"
      alt="Logo der {{ bankData.title }}"
    />
  </button>
</ng-template>

<ng-template #topicContent let-topicData>
  <button #content mat-stroked-button color="primary" (click)="openTopicSelectMenu(topicData.id)">
    <span>{{ topicData.title }}</span>
  </button>
</ng-template>

<ng-template #loadingTemplate>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
