<div class="d-flex flex-column h-100 align-items-center bg-white">
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100 align-items-center">
    <div class="d-flex w-100 mt-4 px-4 mb-2">
      <div class="d-flex flex-grow-1 align-items-center">
        <h2 class="mr-3">Debug Screen</h2>
        <div class="mr-3">
          Application Version [
          <span>{{ 'applicationVersion' | replacement | async }}</span>
          ]
        </div>
        <div class="mr-3">
          Content Version [
          <span>{{ 'contentVersion' | replacement | async }}</span>
          ]
        </div>
        <div class="mr-3">
          Last Update [
          <span>{{ 'lastUpdate' | replacement | async }}</span>
          ]
        </div>
      </div>
    </div>
    <!-- CONTENT -->
    <div class="align-self-start px-4 mt-5 w-100">
      <h2>Replacement Markers - Settings</h2>
      <div class="grid-wrapper">
        <div class="d-flex flex-column w-100 grid-card p-3" *ngFor="let settingsItem of configService.settings">
          <div class="font-weight-bold">{{ settingsItem.description }}</div>
          <div>key: {{ settingsItem.key }}</div>
          <div>value: {{ settingsItem.value }}</div>
        </div>
      </div>
      <h2 class="mt-5">Replacement Markers - Bank Information</h2>
      <div class="grid-wrapper">
        <div class="d-flex flex-column w-100 grid-card p-3" *ngFor="let settingsItem of configService.bankInfo">
          <div class="font-weight-bold">{{ settingsItem.description }}</div>
          <div>key: {{ settingsItem.key }}</div>
          <div>value: {{ settingsItem.value }}</div>
        </div>
      </div>
    </div>
    <div class="align-self-start px-4 mt-5 mb-4 w-25">
      <h2>Current Theme Colors</h2>
      <div class="d-flex flex-column w-100" *ngIf="themeColors">
        <ng-container *ngFor="let col of themeColors; let i = index">
          <div class="d-flex align-items-center w-100">
            <div class="font-weight-bold flex-grow-1">{{ col.prop }}</div>
            <div class="font-weight-regular">{{ col.value }}</div>
            <div class="ml-1" [style.backgroundColor]="col.value" style="width: 1rem; height: 1rem"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="mt-4">&nbsp;</div>
  </div>
</div>

<button
  class="kf-popup-close"
  mat-icon-button
  (click)="doAction('overlay-main', 'close')"
  aria-label="Dialog schließen"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>
