import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { QueryService } from '@app/services/query-service/query.service';
import { OptionData } from '@domain/app/forms.domain';
import { Profile } from '@domain/app/profile';
import { DataFieldTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { FormType, color } from 'bgzv-frontend-library';
import { cloneDeep } from 'lodash-es';
import { lastValueFrom } from 'rxjs';
import ProfileDataFieldItem = Profile.ProfileDataFieldItem;

@Component({
  selector: 'side-profile-editor',
  templateUrl: './side-profile-editor.component.html',
  styleUrls: ['./side-profile-editor.component.scss'],
})
export class SideProfileEditorComponent implements OnInit {
  @Input() userProfileData: Profile.GetProfileResponse;
  @Output() editorClosed: EventEmitter<Profile.GetProfileResponse | null> = new EventEmitter();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren('datafieldForm') datafieldForms;

  private currentVatDiscount: boolean;
  public filteredProfileData: Profile.ProfileGroupDTO[];
  public formGroups: FormArray<FormGroup> = new FormArray<FormGroup>([]);

  public datafieldSelectionMap: Map<number, OptionData[]> = new Map<number, OptionData[]>();
  public changesMap: Map<number, any> = new Map<number, any>();
  public errorMap: Map<number, boolean> = new Map<number, boolean>();

  readonly color = color;
  readonly dataFieldFormType = FormType;
  readonly dataFieldType = DataFieldTypeEnum;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    if (this.userProfileData.profileGroups.length > 0) {
      this.filteredProfileData = this.filterEditOnlyElements(cloneDeep(this.userProfileData.profileGroups));

      // if there is no data, it should still render a Stammdaten tab for the vorsteuerabzugsberechtigt box
      if (this.filteredProfileData.every(x => x.category !== 'GENERIC')) {
        this.filteredProfileData.unshift({
          category: 'GENERIC',
          dataFieldGroups: [],
        } as Profile.ProfileGroupDTO);
      }

      this.createFormGroups();
    }
    this.currentVatDiscount = this.userProfileData.vatDiscount;
  }

  public async saveData() {
    if (this.userProfileData.vatDiscount !== this.currentVatDiscount || this.changesMap.size > 0) {
      const dataFields = [];
      this.changesMap.forEach((value, key) => {
        dataFields.push({ id: Number(key), value: value });
      });
      const sendData = {
        vatDiscount: this.currentVatDiscount,
        dataFields: dataFields,
      } as Profile.ProfileUpdateRequest;
      const newData = await lastValueFrom(
        this.queryService.patchUpdateProfile(this.clientService.consultationId, sendData)
      );
      this.editorClosed.emit(newData);
    } else {
      this.editorClosed.emit();
    }
  }

  public onVatCheckboxChange(newValue: any): void {
    this.currentVatDiscount = typeof newValue === 'boolean' ? newValue : false;
  }

  public changeValues(newValue: any, dataField: ProfileDataFieldItem) {
    const datafield = dataField.values[0];
    if (newValue !== datafield.id) {
      if (this.changesMap.has(datafield.id) && this.changesMap.get(datafield.id) === newValue) {
        return;
      }
      this.changesMap.set(datafield.id, newValue);
    }
  }

  public updateErrorMap(valid: boolean, dataField: ProfileDataFieldItem): void {
    const dataFieldId = dataField.values[0].id;
    // Workaround until customerNumber is removed
    if (dataField.name === 'Kundennummer' && !valid) {
      this.changesMap.set(dataFieldId, dataField.values[0].value);
      this.formValid.emit(true);
    } else {
      if (this.errorMap.has(dataFieldId) && valid) {
        this.errorMap.delete(dataFieldId);
      } else if (!valid) {
        this.errorMap.set(dataFieldId, valid);
      }
      // You should be able to save all datafields despite not being validated
      this.formValid.emit(true);
    }
  }

  public getRegex(dataField: ProfileDataFieldItem): string {
    if (dataField.type === DataFieldTypeEnum.number) {
      return '[0-9]+$';
    } else if (dataField.type === DataFieldTypeEnum.text && dataField.jsonValidation) {
      return dataField.jsonValidation;
    } else {
      return '';
    }
  }

  private createFormGroups(): void {
    for (let i = 0; i < this.filteredProfileData[0].dataFieldGroups.length; i++) {
      const dataFieldGroup = this.filteredProfileData[0].dataFieldGroups[i];
      this.addFormGroup();
      for (const dataField of dataFieldGroup.dataFields) {
        this.mapSelectionData(dataField);
        this.addFormControl(i, dataField);
      }
    }
  }

  private addFormGroup(): void {
    this.formGroups.push(new FormGroup({}));
  }

  private addFormControl(index: number, dataField: Profile.ProfileDataFieldItem): void {
    const datafieldValue = dataField.values[0];
    const name = `${index}-${datafieldValue.id}`;
    this.formGroups.controls[index].addControl(name, new FormControl<string | null>(datafieldValue.value || ''));
    if (dataField.mandatory && dataField.type !== 'CHECKBOX') {
      this.formGroups.controls[index].controls[name].setValidators([Validators.required]);
    }
  }

  private filterEditOnlyElements(data: Profile.ProfileGroupDTO[]): Profile.ProfileGroupDTO[] {
    const filteredData = data?.filter(profileData =>
      profileData.dataFieldGroups?.find(dataFieldGroups =>
        dataFieldGroups.dataFields?.find(dataField => dataField.editable)
      )
    );
    filteredData.forEach(profileData => {
      profileData.dataFieldGroups.forEach(y => (y.dataFields = y.dataFields.filter(dataField => dataField.editable)));
      profileData.dataFieldGroups.filter(x => x.dataFields.length > 0);
    });
    return filteredData;
  }

  private mapSelectionData(dataField: ProfileDataFieldItem): void {
    const datafield = dataField.values[0];
    if (dataField.type === DataFieldTypeEnum.dropdown || dataField.type === DataFieldTypeEnum.radio) {
      const jsonAnswer = JSON.parse(dataField.jsonAnswerOptions);

      const item = Object.keys(jsonAnswer).map(x => {
        const selectionData = {} as OptionData;
        selectionData.label = jsonAnswer[x];
        selectionData.value = x;
        selectionData.checked = datafield.value === x;
        return selectionData;
      });
      this.datafieldSelectionMap.set(datafield.id, item);
    }
    if (dataField.type === DataFieldTypeEnum.checkbox) {
      const standard = { label: '', value: '', checked: datafield.value === 'true' } as OptionData;
      this.datafieldSelectionMap.set(datafield.id, [standard]);
    }
  }
}
