import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dragAndDropUpload]',
})
export class DndDirective {
  @Output() fileDropped = new EventEmitter<FileList>();

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) ondDrop(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;

    const files = ev.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
  constructor() {}
}
