import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

export const fileAccept = '.pdf,.png,.jpeg,.jpg,.gif';

@Component({
  selector: 'documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
  public errorMessage = '';
  public fileAccept = fileAccept;
  public readonly fileSizeLimit: number = 5000000;

  public snackBarMessage: string;

  private errorMessageSub: Subscription;
  private filteredArray = [];
  private notAllowed = [];

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    private documentService: DocumentService,
    private contextService: ContextService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  ngOnInit(): void {
    this.errorMessageSub = this.documentService.errorMessage.subscribe(
      errorMessage => (this.errorMessage = errorMessage)
    );
  }

  ngOnDestroy(): void {
    this.documentService.errorMessage.next('');
    this.errorMessageSub?.unsubscribe();
  }

  public resetPath(event: any) {
    this.documentService.errorOccured.next(false);
    this.documentService.errorMessage.next('');
    event.target.value = null;
  }

  public onFileSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    this.filteredArray = Array.from(target.files);
    this.checkBeforeUpload(target.files);
  }

  public onFileDrop(files: FileList) {
    this.filteredArray = Array.from(files);
    this.checkBeforeUpload(files);
  }

  private checkBeforeUpload(files: FileList) {
    this.notAllowed = [];
    const allowedTypes = fileAccept.split(',').map(value => {
      return `${value === '.pdf' ? 'application' : 'image'}/${value.substring(1)}`;
    });

    for (let i = 0; i < this.filteredArray.length; i++) {
      if (!allowedTypes.includes(this.filteredArray[i].type)) {
        this.notAllowed.push(this.filteredArray[i].name);

        this.filteredArray.splice(i, 1);
      }
    }

    const tooBigFileExists = this.filteredArray.find(x => x.size > this.fileSizeLimit);
    let tooBigErrorMessage = '';
    if (tooBigFileExists) {
      tooBigErrorMessage = `Die Datei ${tooBigFileExists.name} ist zu groß. Dateien dürfen maximal ${
        this.fileSizeLimit / 1000000
      }MB groß sein.`;
    }

    let notAllowedErrorMessage = '';
    if (this.notAllowed.length > 0) {
      notAllowedErrorMessage = `Die Datei${this.notAllowed.length > 1 ? 'en' : ''} "${this.notAllowed.join(
        ', '
      )}" entspr${
        this.notAllowed.length > 1 ? 'echen' : 'icht'
      } nicht dem erlaubten Format. Bitte beachten Sie, dass nur folgende Dateitypen unterstützt werden: ${fileAccept}`;
    }

    if (tooBigFileExists && !!notAllowedErrorMessage.length) {
      const commonErrorMessage = 'Einige Dateien entsprechen nicht den Bedingungen und werden übersprungen.';
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: commonErrorMessage });
    } else if (tooBigFileExists && !notAllowedErrorMessage.length) {
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: tooBigErrorMessage });
    } else if (!tooBigFileExists && !!notAllowedErrorMessage.length) {
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: notAllowedErrorMessage });
    }

    this.documentService.uploadDocuments(files);
  }

  get maxFileSize() {
    return this.documentService.fileSizeLimit;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
