import { Injectable } from '@angular/core';
import { VariantCalculator } from '@domain/app/variant-calculator';
import { ClientService } from '@services/client-service/client.service';
import { calculateProductPrices } from '@services/product-variant-calculator/calculate-product-prices';
import {
  ProductVariantCalculator,
  VariantCalculatorProductDetails,
} from '@services/product-variant-calculator/product-variant-calculator';
import { QueryService } from '@services/query-service/query.service';
import { BehaviorSubject, Observable, from, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductVariantCalculatorService {
  constructor(
    private readonly queryService: QueryService,
    private readonly clientService: ClientService
  ) {}

  fetch(consultationId: string, accountType: VariantCalculator.CalculatorName): Observable<ProductVariantCalculator> {
    // zip() results in the whole observable chain to not be executed at all in this particular case
    // so - to keep things readable - we're just taking a round-trip with Promises
    return from(
      lastValueFrom(this.queryService.getVariantCalculator(consultationId, accountType)).then(data => {
        const details = data.products.map(product => lastValueFrom(this.fetchProductDetails(product, consultationId)));
        return Promise.all(details).then(productDetails => this.create(consultationId, data, productDetails));
      })
    );
  }

  private persistInputs(
    consultationId: string,
    type: VariantCalculator.CalculatorName,
    inputs: { [inputId: number]: number | boolean }
  ) {
    localStorage.setItem(this.localStorageKey(consultationId, type), JSON.stringify(inputs));
  }

  private restoreInputs(consultationId: string, type: VariantCalculator.CalculatorName) {
    const inLocalStorage = localStorage.getItem(this.localStorageKey(consultationId, type));
    if (!inLocalStorage) {
      return undefined;
    } else {
      return Object.fromEntries(Object.entries(JSON.parse(inLocalStorage)).map(([key, value]) => [Number(key), value]));
    }
  }

  private localStorageKey(consultationId: string, type: VariantCalculator.CalculatorName) {
    return `product-variant-calculator?consultation=${consultationId}&type=${type}`;
  }

  private create(
    consultationId: string,
    data: VariantCalculator.GetVariantCalculatorResponse,
    products: VariantCalculatorProductDetails[]
  ): ProductVariantCalculator {
    const recommendation = new BehaviorSubject<VariantCalculatorProductDetails['productId'] | undefined>(undefined);
    const prices = new BehaviorSubject<{ [productId: string]: number }>({});
    const productsInCart = new BehaviorSubject<VariantCalculatorProductDetails['productId'][] | undefined>(undefined);
    const persistedInputs = this.restoreInputs(consultationId, data.calculatorName) as {
      [inputId: number]: number | boolean;
    };

    const setInputValues = (values: { [inputId: number]: number | boolean }) => {
      const calculatedPrices = calculateProductPrices(products, values);
      prices.next(calculatedPrices);
      const [productWithLowestPrice] = Object.entries(calculatedPrices).sort(
        ([, priceA], [, priceB]) => priceA - priceB
      )[0];
      recommendation.next(productWithLowestPrice);
      this.persistInputs(consultationId, data.calculatorName, values);
    };

    const toggleInCart = (product: VariantCalculatorProductDetails, subtopicId: string) => {
      const isInCart = productsInCart.value && productsInCart.value.indexOf(product.productId) !== -1;
      if (!isInCart) {
        this.queryService
          .putSelectProduct(consultationId, {
            productId: product.productId,
            compositionId: product.compositionId,
            subtopicId: subtopicId,
            quantity: 1,
          })
          .subscribe(() => productsInCart.next([...(productsInCart.value ?? []), product.productId]));
        this.clientService.numSolutions += 1;
      } else {
        this.queryService
          .putDeselectProduct(consultationId, {
            productId: product.productId,
            compositionId: product.compositionId,
            target: 'OTHER',
          })
          .subscribe(() => productsInCart.next(productsInCart.value.filter(it => it !== product.productId)));
        if (this.clientService.numSolutions > 0) {
          this.clientService.numSolutions -= 1;
        }
      }
    };

    this.queryService.getCheckoutData(consultationId).subscribe(checkoutData => {
      const compositionId = products[0].compositionId;
      const composition = checkoutData.compositions.find(x => x.id === compositionId);

      if (composition?.products.length > 0) {
        productsInCart.next(composition.products.map(x => x.id));
      } else {
        productsInCart.next([]);
      }
    });

    return {
      products,
      inputs: data.inputs,
      setInputValues,
      recommendation,
      prices,
      productsInCart,
      toggleInCart,
      persistedInputs,
    };
  }

  private fetchProductDetails(
    pro: VariantCalculator.VariantCalculatorProductDTO,
    consultationId: string
  ): Observable<VariantCalculatorProductDetails> {
    return this.queryService
      .getProductDetailsById(consultationId, pro.compositionId, pro.productId)
      .pipe(map(productResponse => Object.assign({}, productResponse, pro)));
  }
}
