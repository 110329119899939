import { ContactTypeEnum } from '@enums';

export function getContactSuffix(type: ContactTypeEnum): string {
  if (type === ContactTypeEnum.consultant) {
    return ' (Berater)';
  }
  if (type === ContactTypeEnum.expert) {
    return ' (Experte)';
  }
  return '';
}
