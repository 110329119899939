import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

export const buttonConfigProceed: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'topic-select-proceed',
    config: {
      type: ButtonType.FLAT,
      label: 'Weiter',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};

export const buttonConfigFinish: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'topic-select-finish',
    config: {
      type: ButtonType.FLAT,
      label: 'Abschließen',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};

export const buttonConfigBack: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'topic-select-back',
    config: {
      type: ButtonType.STROKED,
      label: 'Zurück',
    },
  },
};

export const buttonConfigSkip: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'topic-select-skip',
    config: {
      type: ButtonType.STROKED,
      label: 'Überspringen',
    },
  },
};
