import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const buttonStartOrder: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'cart-start-order',
    config: {
      type: ButtonType.FLAT,
      label: 'Bestellprozess starten',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};
