import { ContactTypes } from '@domain/app/contact.domain';
import { ContactTypeEnum } from '@enums';

/**
 * create contact string from contact object containing
 * firstName, lastName and customerContactType
 * @param contact - Contact object
 * @returns formatted contact string
 */
export function getContactExtended(contact: ContactTypes): string {
  if (!contact) return '';
  const { firstName, lastName, customerContactType } = contact;
  const contactType =
    customerContactType === ContactTypeEnum.consultant
      ? '(Berater)'
      : customerContactType === ContactTypeEnum.expert
        ? '(Experte)'
        : '';
  return [firstName, lastName, contactType].filter(Boolean).join(' ');
}

/**
 * create contact string from contact object containing
 * firstName and lastName
 * @param contact - Contact object
 * @returns formatted contact string
 */
export function getContactName(contact: ContactTypes): string {
  if (!contact) return '';
  const { firstName, lastName } = contact;
  return [firstName, lastName].filter(Boolean).join(' ');
}

/**
 * create contact id from contact object containing
 * firstName and lastName with prefixes ___ and ###
 * @param contact - Contact object
 * @returns formatted contact string
 */
export function getContactId(contact: ContactTypes): string {
  if (!contact) return '';
  const { firstName, lastName } = contact;
  return ['___', firstName, '###', lastName].join('');
}
