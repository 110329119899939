import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConsultationSummaryDocumentItem, ConsultationSummaryTransitionItem } from '@domain/app/consultation.domain';
import { ExpertContactItem } from '@domain/app/contact.domain';
import { MediaTypeEnum } from '@enums';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { getDocumentIcon } from '@utils/helpers/mediaType';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'result-transitions',
  templateUrl: './result-transitions.component.html',
  styleUrls: ['./result-transitions.component.scss'],
  standalone: false,
})
export class ResultTransitionsComponent implements OnInit {
  @Input() data: ConsultationSummaryTransitionItem[];
  @ViewChild('transitionsPanel') transitionsPanel: MatExpansionPanel;

  readonly moment = moment;

  private destroySubs = new Subject<void>();

  public panelOpenState: boolean;
  public activeTransitionIndex: number = 0;
  public detailPanelsOpened: Array<number> = [0];
  public loading = false;
  public documentTypeAsIcon = getDocumentIcon;

  constructor(
    private mediaService: MediaService,
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public openDetailPanel(index: number): void {
    this.detailPanelsOpened.push(index);
  }

  public closeDetailPanel(index: number): void {
    this.detailPanelsOpened = this.detailPanelsOpened.filter(entry => entry !== index);
  }

  public getDocuments(transition: ConsultationSummaryTransitionItem): ConsultationSummaryDocumentItem[] {
    return (
      transition.documents?.filter(doc => doc.type !== MediaTypeEnum.image && doc.type !== MediaTypeEnum.docImage) || []
    );
  }

  public async openDocument(documentUrl: string): Promise<void> {
    if (documentUrl.includes('http')) {
      window.open(documentUrl, '_blank');
    } else {
      const url = await this.mediaService.getMediaContent(documentUrl);
      window.open(url, '_blank');
    }
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }

  public getName(expertContact: ExpertContactItem) {
    const { formOfAddress, firstName, lastName } = expertContact;
    return [formOfAddress, firstName, lastName].join(' ');
  }
}
