import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '@services/config-service/config.service';

@Pipe({
  name: 'replacement',
  standalone: false,
})
export class ReplacementPipe implements PipeTransform {
  constructor(private configService: ConfigService) {}

  async transform(key: string, type: string = null): Promise<string> {
    const configData = await this.configService.getHubConfigurationData();
    const data = configData.bankInformation.find(x => x.key === key) || configData.settings.find(x => x.key === key);

    if (data) {
      if (!type) {
        return data.value;
      } else if (data[type]) {
        return data[type];
      }
    }
    return '';
  }
}
