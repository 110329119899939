import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { SnackBarTemplatesService, SnackbarType } from './snackbar-templates.service';

@Component({
  selector: 'snackbar-templates',
  templateUrl: './snackbar-templates.component.html',
  styleUrls: ['./snackbar-templates.component.scss'],
  standalone: false,
})
export class SnackbarTemplatesComponent implements OnInit, OnDestroy {
  private subAlive = new Subject<void>();

  public snackBarMessage: string = '';
  public snackBarIcon: string = '__default__';

  @ViewChild('snackBarNeutral', { read: TemplateRef }) templateNeutral: TemplateRef<any>;
  @ViewChild('snackBarAlert', { read: TemplateRef }) tempateAlert: TemplateRef<any>;
  @ViewChild('snackBarSuccess', { read: TemplateRef }) templateSuccess: TemplateRef<any>;
  @ViewChild('snackBarError', { read: TemplateRef }) templateError: TemplateRef<any>;

  constructor(
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarTemplatesService
  ) {}

  ngOnInit(): void {
    this.snackBarService.snackBarOpened.pipe(takeUntil(this.subAlive)).subscribe(data => {
      const type = data.type;
      const message = data.message;
      const icon = data.icon || '__default__';
      const config = data.config || {};

      this.openSnackBar(type, message, icon, config);
    });

    this.snackBarService.snackBarDismissed.pipe(takeUntil(this.subAlive)).subscribe(() => {
      this.snackBar.dismiss();
    });
  }

  ngOnDestroy(): void {
    this.subAlive.next();
    this.subAlive.complete();
  }

  public openSnackBar(
    type: SnackbarType,
    message: string = this.snackBarMessage,
    icon: string = this.snackBarIcon,
    config: MatSnackBarConfig = {}
  ): void {
    this.snackBarMessage = message;

    switch (type) {
      case SnackbarType.NEUTRAL:
        this.snackBarIcon = icon === '__default__' ? 'ic_infozeichen_mit_kreis_24' : icon;
        this.snackBar.openFromTemplate(this.templateNeutral, config);
        break;
      case SnackbarType.ALERT:
        this.snackBarIcon = icon === '__default__' ? 'ic_ausrufezeichen_mit_kreis_24' : icon;
        this.snackBar.openFromTemplate(this.tempateAlert, config);
        break;
      case SnackbarType.SUCCESS:
        this.snackBarIcon = icon === '__default__' ? 'ic_haken_mit_kreis_24' : icon;
        this.snackBar.openFromTemplate(this.templateSuccess, config);
        break;
      case SnackbarType.ERROR:
        this.snackBarIcon = icon === '__default__' ? 'ic_warnschild_24' : icon;
        this.snackBar.openFromTemplate(this.templateError, config);
        break;
      default:
        this.snackBar.openFromTemplate(this.templateNeutral, config);
    }
  }
}
