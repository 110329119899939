import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const buttonCancelCheckout: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'checkout-cancel',
    config: {
      type: ButtonType.BASIC,
      label: 'Abbrechen',
    },
  },
};
export const buttonFinishCheckout: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'checkout-finish',
    config: {
      type: ButtonType.FLAT,
      label: 'Beratung abschließen',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};
