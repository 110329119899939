<aside *ngIf="show === recommendationShowEnum.side" class="d-flex overflow-hidden app-height">
  <section class="available-side d-flex flex-column" [class.side-footer-margin]="useMargin">
    <div>
      <div class="rec-header">
        <h2 class="mt-2 mb-3 ml-1 mr-2 flex-grow-1">Weitere Lösungen</h2>
      </div>
      <mat-expansion-panel
        *ngFor="let composition of recCompositions"
        [expanded]="true"
        class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      >
        <mat-expansion-panel-header expandedHeight="4rem" collapsedHeight="4rem"
          ><mat-panel-title
            ><strong class="font-weight-bold">{{ composition }}</strong></mat-panel-title
          >
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div *ngFor="let rec of recByComposition(composition)">
            <div class="d-flex align-items-center justify-content-between subtopic-deselect-container" tabindex="0">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <h4 class="mat-body-2 font-weight-bold composition-title mb-0">{{ rec.compositionName }}</h4>
                  <div class="rec-button">
                    <mat-checkbox [checked]="recContains(rec)" (click)="onRecToggle(rec)" [color]="'grey'"
                      ><span class="label-text">{{ rec.name }}</span>

                      <kf-icon
                        *ngIf="
                          rec.type === recommendationTypeEnum.variant ||
                          rec.type === recommendationTypeEnum.main ||
                          rec.type === recommendationTypeEnum.additional
                        "
                        class="flex-shrink-0 subtopic-icon ml-2"
                        name="ic_allgemeine_karte_24"
                        aria-hidden="true"
                        kfTooltip="Produkt"
                        kfTooltipPosition="after"
                        kfTooltipShow="hover"
                      ></kf-icon>

                      <kf-icon
                        *ngIf="rec.type === recommendationTypeEnum.task"
                        class="flex-shrink-0 subtopic-icon ml-2"
                        name="ic_person_mit_kreis_24"
                        aria-hidden="true"
                        kfTooltip="Maßnahme"
                        kfTooltipPosition="after"
                        kfTooltipShow="hover"
                      ></kf-icon>

                      <kf-icon
                        *ngIf="rec.type === recommendationTypeEnum.transition"
                        class="flex-shrink-0 subtopic-icon ml-2"
                        name="ic_personen_paar_24"
                        aria-hidden="true"
                        kfTooltip="Expertengespräch"
                        kfTooltipPosition="after"
                        kfTooltipShow="hover"
                      ></kf-icon>
                    </mat-checkbox>
                  </div>
                </div>
                <div class="rec-badge">
                  <div *ngIf="rec.recommended" class="tag-info tag-neutral">
                    <div class="tag-info-text">Empfohlen</div>
                  </div>
                </div>
              </div>

              <button
                class="info-button"
                (click)="showRecommendationDetails(rec, recContains(rec))"
                aria-label="Details anzeigen"
                [kfTooltip]="'Details zu: ' + rec.name"
                kfTooltipPosition="after"
                kfTooltipShow="hover"
                mat-icon-button
              >
                <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </section>

  <div class="line-left overflow-auto" [class.side-footer-margin]="useMargin">
    <side-recommendations
      class="ml-3"
      [recommendationGroupData]="recommendationGroupData"
      [sideOpen]="true"
    ></side-recommendations>
  </div>
</aside>

<side-task-indiv
  *ngIf="show === recommendationShowEnum.task"
  [inputValues]="{ taskId: '-2', subtopicId: this.subtopicId, sideOpen: true }"
></side-task-indiv>
<side-transition-indiv
  *ngIf="show === recommendationShowEnum.transition"
  [inputValues]="{ transitionId: '-2', subtopicId: this.subtopicId, sideOpen: true }"
></side-transition-indiv>
<side-product-details
  *ngIf="show === recommendationShowEnum.infoProduct"
  [inputValues]="details"
></side-product-details>
<side-task *ngIf="show === recommendationShowEnum.infoTask" [inputValues]="details"></side-task>
<side-transition *ngIf="show === recommendationShowEnum.infoTransition" [inputValues]="details"></side-transition>
