import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RoutingPathMain } from '@enums';
import { AuthService } from '@modules/auth';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  private authService: AuthService;

  constructor(private router: Router, private injector: Injector) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.handleAuth(state);
  }

  private async handleAuth(state: RouterStateSnapshot) {
    const auth = this.injector.get<AuthService>(AuthService);
    if (auth.jwtToken) {
      return true;
    } else {
      return auth
        .getSession()
        .then(result => {
          if (result?.isValid()) {
            return true;
          }
          if (state.url === '/welcome') {
            return true;
          }
          return this.notAuth();
        })
        .catch(e => {
          return this.notAuth();
        });
    }
  }

  private notAuth(): boolean {
    this.router.navigate([RoutingPathMain.Login]);
    throwError('not authenticated');
    return false;
  }
}
