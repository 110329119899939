<div class="d-flex flex-column h-100 align-items-start" [attr.data-html2canvas-ignore]="isScreenshotting || null">
  <marginals-header class="w-100"></marginals-header>

  @if (!questionCollectionError) {
    <div class="d-flex w-100" [ngClass]="useMargin ? 'height-default' : 'height-module'">
      <!-- ------- -->
      <!-- CONTENT -->
      <!-- ------- -->
      <ng-template *ngTemplateOutlet="mainTemplate"> </ng-template>
      <!-- ------- -->
      <!-- ------- -->
      <!-- ------- -->
    </div>
  } @else {
    <mat-error class="w-100"
      ><kf-input-error message="Ein Fehler ist aufgetreten (keine Themen ausgewählt?)"></kf-input-error
    ></mat-error>
  }
</div>

<!-- ------------------------------------------------ -->
<!-- ---------------- MAIN TEMPLATES ---------------- -->
<!-- ------------------------------------------------ -->
<ng-template #mainTemplate>
  <!-- --- LEFT CONTENT --- -->
  <!-- NO LEFT CONTENT ANYMORE -->

  <!-- --- MAIN CONTENT --- -->
  <div
    #scrollable
    (scroll)="onScroll($event)"
    id="mainContent"
    class="position-relative d-flex flex-column flex-scroll main-content no-scrollbar"
    [class.main-content-rec-open]="recPanelState === panelStates.open"
  >
    <!-- empty div needed to properly position scroll indicator for very short question groups-->
    <div>
      <ng-template *ngTemplateOutlet="topContentTemplate"> </ng-template>

      @if (questionGroupData?.headline) {
        <header class="mx-4 px-4">
          <kf-headline
            tabindex="-1"
            [headline]="questionGroupData.headline"
            [showBackButton]="false"
            [resetTopSpacing]="true"
          ></kf-headline
          ><!-- @Todo: after we drop the breadcrumbs, the topic can be added: [overline]="questionGroupData.topic" -->
        </header>
      }

      <div class="mb-4 mx-4 px-4 mt-2">
        <div id="questionGroupContainer" class="d-flex flex-column">
          <div class="d-flex flex-column">
            <ng-template *ngTemplateOutlet="leftContentTemplate"> </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-indicator" [style.z-index]="matDrawerBackdropVisible ? 1 : 10" aria-live="assertive">
      <span [attr.hidden]="!showOverflowIndicator" class="invisible">Inhalte wurden verändert</span>
    </div>
  </div>

  <!-- --- RIGHT CONTENT --- -->
  <div class="position-relative">
    <div id="rightContent" class="h-100 d-flex right-content">
      <ng-template *ngTemplateOutlet="rightContentTemplate"></ng-template>

      <button
        [attr.data-html2canvas-ignore]="true"
        type="button"
        id="rightContentTrigger"
        class="panel-handle panel-handle--right right-content-trigger position-absolute"
        (click)="onRecPanelTrigger('toggle')"
        (keyup)="onRecPanelTrigger('toggle', $event)"
        [style.z-index]="matDrawerBackdropVisible ? 1 : 101"
        [attr.data-testcafe]="'screenConsultation-div-rightContentTrigger'"
        [attr.aria-label]="tooltip"
        [kfTooltip]="tooltip"
        kfTooltipPosition="before"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
      >
        <span class="sr-only">
          {{ recPanelState === 'closed' ? 'Rechtes Panel öffnen' : 'Rechtes Panel schließen' }}
        </span>

        @if (recPanelState === 'closed') {
          <kf-icon class="kf-color-primary" name="ic_chevron_links_24"></kf-icon>
        } @else if (recPanelState === 'open') {
          <kf-icon class="kf-color-primary" name="ic_chevron_rechts_24"></kf-icon>
        }

        @if (currentContextMode === 'main') {
          <kf-icon
            [class.show]="recommendationsAvailable && recPanelState === 'closed'"
            class="icon recommendation-info-icon"
            name="ic_infozeichen_mit_kreis_24"
          ></kf-icon>
        }
      </button>
    </div>
  </div>
</ng-template>

<ng-template #topContentTemplate>
  <div class="mt-4 mx-4 px-4 header-content">
    <div class="d-flex align-self-start align-items-center mt-2" style="min-height: 1.25rem">
      @if (questionGroupData) {
        <div [debugId]="questionGroupData?.subtopicId" innerHtml="{{ questionGroupData?.topic }}"></div>
        <kf-icon class="mx-1" name="ic_chevron_rechts_24"></kf-icon>
        <div class="mr-3" innerHtml="{{ questionGroupData?.subtopic }}"></div>
        @for (items of helperArray(questionGroupData?.totalCollections).fill(1); track i; let i = $index) {
          @if (i < questionGroupData?.currentCollection) {
            <ng-template *ngTemplateOutlet="topicDone"></ng-template>
          } @else if (i === questionGroupData?.currentCollection) {
            <ng-template *ngTemplateOutlet="topicCurrent"></ng-template>
          } @else if (i > questionGroupData?.currentCollection) {
            <ng-template *ngTemplateOutlet="topicOpen"></ng-template>
          }
        }
      }
    </div>
  </div>
</ng-template>

<ng-template #leftContentTemplate>
  <div class="d-flex flex-column flex-grow-1 pb-4 position-relative">
    @if (questionGroupData) {
      @for (question of questionData; track question; let i = $index; let first = $first; let last = $last) {
        @if (first) {
          @if (showDescriptionTile) {
            <kf-tile [interactive]="false" class="mb2rem">
              <!-- ------ PROGRESS BAR ------ -->
              <progress-tracker [total]="totalSteps" [current]="0"></progress-tracker>

              @if (questionGroupData.description) {
                <div class="mt-3 pr-5" [innerHTML]="questionGroupData.description | safeHtml"></div>
              }
              @if (imageData?.length > 1) {
                <div class="mt-3 ml-1 mr-1">
                  <bgzv-image
                    [imageMulti]="imageData"
                    [width]="'100%'"
                    [height]="'300px'"
                    [enlargeable]="true"
                    [enlargeOptions]="{ endWidth: '100%', endHeight: '50vh' }"
                  ></bgzv-image>
                </div>
              } @else if (imageData?.length === 1) {
                <div class="mt-3">
                  <bgzv-image
                    [image]="imageData[0]"
                    [width]="'50%'"
                    [height]="'200px'"
                    [behavior]="questionGroupData?.imageDefaultSize"
                  ></bgzv-image>
                </div>
              }
            </kf-tile>
          }
          @if (showOverviewTile) {
            <kf-tile [interactive]="false" class="mb2rem">
              <!-- ------ PROGRESS BAR ------ -->
              <progress-tracker [total]="totalSteps" [current]="showDescriptionTile ? 1 : 0"></progress-tracker>
              <h2>{{ questionGroupData.subtopicOverview.caption }}</h2>
              <mat-form-field [id]="questionGroupData.subtopicId" class="notranslate">
                <mat-label>Ihre Eingabe</mat-label>
                <div class="kf-field-char-counter" aria-hidden="true">
                  {{ subtopicOverviewTextArea.value?.length || 0 }}/1000
                </div>
                <textarea
                  #subtopicOverviewTextArea
                  ngDefaultControl
                  matInput
                  cdkTextareaAutosize
                  maxLength="1000"
                  autocomplete="off"
                  [attr.aria-label]="questionGroupData.subtopicOverview.caption + ' Ihre Eingabe'"
                  [value]="subtopicOverviewTextValue"
                  (change)="sendSubtopicOverviewTextRequest(subtopicOverviewTextArea.value)"
                ></textarea>
              </mat-form-field>
            </kf-tile>
          }
        }
        <kf-tile [interactive]="false" [class.mb2rem]="!last">
          <div>
            <progress-tracker [total]="totalSteps" [current]="i + extraSteps"></progress-tracker>
            <!-- ------ HEADER OF QUESTION ------ -->
            <div class="d-flex justify-content-start mb-3">
              <h2 class="question-header" [debugId]="question.id" [innerHTML]="getQuestionName(question)"></h2>
              @if (hasQuestionDocuments(question)) {
                <button
                  class="ml-2"
                  [attr.data-testcafe]="'screenConsultation-documents-' + getTestcafeId(question.label, question.id)"
                  [matMenuTriggerFor]="menu"
                  aria-label="Dokumente"
                  [kfTooltip]="(question.medias.length > 1 ? 'Dokumente' : 'Dokument') + ' anzeigen'"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                  mat-icon-button
                >
                  <kf-icon name="ic_datei_24"></kf-icon>
                </button>
              }
              <mat-menu #menu="matMenu">
                <div class="d-flex flex-column">
                  @for (document of question.medias; track document; let last = $last) {
                    @if (document.type !== 'IMAGE') {
                      <button mat-menu-item (click)="showDocument(document, $event)">
                        {{ document.name + ' (' + document.type + ')' }}
                      </button>
                    }
                  }
                </div>
              </mat-menu>
              @if (question.connectedToDataFieldGroups && question.type !== questionEnum.dataFieldGroup) {
                <button
                  class="ml-2"
                  [attr.data-testcafe]="'screenConsultation-datafields-' + getTestcafeId(question.label, question.id)"
                  (click)="onOpenQuestionDataFields(question)"
                  aria-label="Weitere Informationen"
                  kfTooltip="Detailinformationen anzeigen"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                  mat-icon-button
                >
                  <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
                </button>
              }
            </div>
            @if (question.description) {
              <div [innerHTML]="question.description | safeHtml" class="mb-3"></div>
            }
            @if (question.label) {
              <div [innerHTML]="question.label | safeHtml" class="mb-3"></div>
            }
            <!-- ------ IMAGE OF QUESTION ------ -->
            @if (hasQuestionImages(question) === 1) {
              <bgzv-image
                [image]="getQuestionImages(question)[0]"
                [height]="'300px'"
                [behavior]="question.imageDefaultSize"
              ></bgzv-image>
            } @else if (hasQuestionImages(question) > 1) {
              <bgzv-image
                [imageMulti]="getQuestionImages(question)"
                [height]="'300px'"
                [behavior]="question.imageDefaultSize"
              ></bgzv-image>
            }
            @if (!isQuestionPaired(question)) {
              @if (question.type === questionEnum.dataFieldGroup) {
                <div class="question-dataFieldGroup">
                  <ng-template *ngTemplateOutlet="dataFieldGroup; context: { $implicit: question }"> </ng-template>
                </div>
              } @else if (
                question.type === questionEnum.single ||
                question.type === questionEnum.multi ||
                question.type === questionEnum.singleImage ||
                question.type === questionEnum.multiImage
              ) {
                <ng-template *ngTemplateOutlet="selectQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (
                question.type === questionEnum.slider ||
                question.type === questionEnum.expSlider ||
                question.type === questionEnum.connectedSlider
              ) {
                <ng-template *ngTemplateOutlet="sliderQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.number) {
                <ng-template *ngTemplateOutlet="numberQuestion; context: { $implicit: question }"> </ng-template>
                <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.toggle) {
                <ng-template *ngTemplateOutlet="toggleQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.segments) {
                <ng-template *ngTemplateOutlet="segmentQuestion; context: { $implicit: question }"> </ng-template>
                <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.text) {
                <ng-template *ngTemplateOutlet="textQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.tool) {
                <ng-template *ngTemplateOutlet="toolQuestion; context: { $implicit: question }"> </ng-template>
              }
            }
            @if (isQuestionPaired(question)) {
              <div class="d-flex flex-column mt-3 kf-color-grey-600 p-4">
                <div class="font-weight-bold">
                  {{ getPairedQuestionAnswer(question).length > 1 ? 'Antworten' : 'Antwort' }}
                </div>
                <div class="d-flex">
                  {{ getPairedQuestionAnswer(question).join(' | ') }}
                </div>
              </div>
            }
            @if (!isQuestionPaired(question) && getOtherAnswerLabel(question) > 0) {
              <button
                mat-button
                color="primary"
                class="mt-2 prev-answer-button"
                [attr.data-testcafe]="'prevAnswer-button-' + question.id"
                (click)="onClickOtherAnswerDialog($event, question)"
              >
                <kf-icon name="ic_historie_24"></kf-icon>
                <span [attr.data-testcafe]="'prevAnswer-answer-' + getOtherAnswerLabel(question)">
                  Vorherige Antworten: {{ getOtherAnswerLabel(question) }}
                </span>
              </button>
            }
          </div>
          <!-- linked questions -->
          @if (isQuestionShown(question) && question.syncedQuestions?.length > 0) {
            <div>
              <button
                mat-button
                class="my-3"
                color="primary"
                [attr.data-testcafe]="'syncedQuestion-button-' + question.id"
                (click)="changeSyncQuestionState(question.id)"
              >
                <kf-icon name="ic_ringe_24"></kf-icon>
                <span>Verknüpfte Fragen {{ showSyncedQuestions(question.id) ? 'ausblenden' : 'anzeigen' }}</span>
              </button>
              <div
                class="synced-questions"
                [class.expanded]="showSyncedQuestions(question.id)"
                [attr.data-testcafe]="'syncedQuestion-answer-' + question.id"
              >
                <synced-questions [syncedQuestions]="question.syncedQuestions"></synced-questions>
              </div>
            </div>
          }
        </kf-tile>
        @if (last) {
          <div></div>
        }
      } @empty {
        <!-- Usecase: show questionGroup without question but with description and media, currently just used for NHK-expert content stream -->
        @if (questionGroupData.description || imageData?.length >= 1) {
          <kf-tile [interactive]="false" class="mb2rem">
            @if (questionGroupData.description) {
              <div class="mt-3 pr-5" [innerHTML]="questionGroupData.description | safeHtml"></div>
            }
            @if (imageData?.length > 1) {
              <div class="mt-3 ml-1 mr-1">
                <bgzv-image
                  [imageMulti]="imageData"
                  [width]="'100%'"
                  [height]="'300px'"
                  [enlargeable]="true"
                  [enlargeOptions]="{ endWidth: '100%', endHeight: '50vh' }"
                ></bgzv-image>
              </div>
            } @else if (imageData?.length === 1) {
              <div class="mt-3">
                <bgzv-image
                  [image]="imageData[0]"
                  [width]="'50%'"
                  [height]="'200px'"
                  [behavior]="questionGroupData?.imageDefaultSize"
                ></bgzv-image>
              </div>
            }
          </kf-tile>
        }
        @if (
          questionGroupData.subtopicOverview &&
          questionGroupData.currentCollection === 0 &&
          currentContextMode === 'main'
        ) {
          <kf-tile [interactive]="false" class="mb2rem">
            <h2>{{ questionGroupData.subtopicOverview.caption }}</h2>
            <mat-form-field [id]="questionGroupData.subtopicId" class="notranslate">
              <mat-label>Ihre Eingabe</mat-label>
              <div class="kf-field-char-counter" aria-hidden="true">
                {{ subtopicOverviewTextArea.value?.length || 0 }}/1000
              </div>
              <textarea
                #subtopicOverviewTextArea
                ngDefaultControl
                matInput
                cdkTextareaAutosize
                maxLength="1000"
                autocomplete="off"
                [attr.aria-label]="questionGroupData.subtopicOverview.caption + ' Ihre Eingabe'"
                [value]="subtopicOverviewTextValue"
                (change)="sendSubtopicOverviewTextRequest(subtopicOverviewTextArea.value)"
              ></textarea>
            </mat-form-field>
          </kf-tile>
        }
      }
    } @else {
      <div class="d-flex flex-center w-100 h-100">
        <mat-progress-spinner [color]="'primary'" mode="indeterminate" [diameter]="90"></mat-progress-spinner>
      </div>
    }
  </div>
</ng-template>

<ng-template #rightContentTemplate>
  <div
    class="d-flex flex-column flex-grow-1 flex-scroll position-relative no-scrollbar kf-bg"
    style="padding: 1.5rem 1.5rem 6rem"
  >
    @if (currentContextMode === 'main') {
      @if (questionGroupData) {
        <ng-template *ngTemplateOutlet="recommendations"></ng-template>
      } @else {
        <div class="d-flex flex-center w-100 h-100">
          <mat-progress-spinner [color]="'primary'" mode="indeterminate" [diameter]="90"></mat-progress-spinner>
        </div>
      }
    } @else if (currentContextMode === 'prep') {
      <h2 class="mb-3 ml-1 mr-2">Lösungen</h2>
      <kf-message-box
        type="simple"
        state="neutral"
        title="Hinweis"
        description="Im Status &#8222;Vorbereitung&#8220; können noch keine Lösungen ausgewählt werden."
        iconName="ic_infozeichen_mit_kreis"
      >
      </kf-message-box>
      <p></p>
    }
  </div>
</ng-template>

<!-- ------------------------------------------------ -->
<!-- -------------- QUESTION TEMPLATES -------------- -->
<!-- ------------------------------------------------ -->

<!-- ---------- QUESTION TEMPLATE [TOOL] --------- -->
<ng-template #toolQuestion let-question>
  @if (question?.tool) {
    <div [id]="question.id">
      <kbm-tool-container
        [toolConfiguration]="question?.tool"
        [hasNavigation]="true"
        [overline]="'Toolbox'"
      ></kbm-tool-container>
    </div>
  } @else {
    Für die Nutzung des Werkzeuges in dieser Frage fehlt Ihnen die nötige Kompetenz. Bitte wenden Sie sich an Ihren
    Content-Admin.
  }
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TEXT-MULTILINE] --------- -->
<ng-template #textQuestion let-question>
  <mat-form-field [id]="question.id" class="notranslate">
    <mat-label>Ihre Eingabe</mat-label>
    <div class="kf-field-char-counter" aria-hidden="true">{{ textArea.value?.length || 0 }}/1000</div>
    <textarea
      #textArea
      ngDefaultControl
      matInput
      maxLength="1000"
      cdkTextareaAutosize
      autocomplete="off"
      [value]="getTextAnswer(question)"
      [attr.aria-label]="'text-question-' + question.id"
      (focused)="[sendTempTextRequest(), setFocusElement(textArea.value)]"
      (click)="sendTempTextRequest()"
      (change)="[setTempTextAnswer(question.id, textArea.value, 'text', question.answers, -1), sendTempTextRequest()]"
    ></textarea>
  </mat-form-field>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [DataFieldGroup] --------- -->
<ng-template #dataFieldGroup let-question>
  <side-profile
    [id]="question.id"
    [inputValues]="{
      itemId: question?.id,
      isQuestionDataField: question?.connectedToDataFieldGroups,
      isQuestionField: true,
    }"
  ></side-profile>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SELECT] --------- -->
<ng-template #selectQuestion let-question>
  <answer-select
    [id]="question.id"
    [question]="question"
    [selectAnswerData]="selectAnswerData"
    (answerSelected)="answerSelect(question, $event)"
  ></answer-select>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SLIDER / SLIDER-EXP] --------- -->
<ng-template #sliderQuestion let-question>
  <answer-slider
    [id]="question.id"
    [min]="numericalAnswerMinMax.get(question.id).min"
    [max]="numericalAnswerMinMax.get(question.id).max"
    [question]="question"
    [sliderPropertiesMap]="sliderPropertiesMap"
    [numericalAnswerData]="numericalAnswerData"
    [questionAnswer]="true"
    (valueSelected)="answerSelectSlider(question, $event)"
  ></answer-slider>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [NUMBER] --------- -->
<ng-template #numberQuestion let-question>
  <answer-number-input
    [id]="question.id"
    [testcafeData]="getTestcafeAnswerId(question.name)"
    [defaultNumber]="numericalAnswerData.get(question.id).value || 0"
    [stepValue]="question.stepValue || 1"
    [minValue]="numericalAnswerMinMax.get(question.id).min"
    [maxValue]="numericalAnswerMinMax.get(question.id).max"
    [keyboardInput]="true"
    (currentNumberChanged)="answerSelectNumeric(question.answers, question.id, $event)"
  >
  </answer-number-input>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TOGGLE] --------- -->
<ng-template #toggleQuestion let-question>
  @if (!question.label) {
    <div class="mb-2"></div>
  }
  @if (question.answers.length > 0) {
    <mat-slide-toggle
      #slidetoggle
      color="primary"
      labelPosition="after"
      [id]="question.id"
      [checked]="singleAnswerData.get(question.id) === question.answers[0].id"
      [answered]="singleAnswerData.get(question.id) !== zeroUUID"
      (change)="answerSelectSingle(question.answers, question.id, $event.checked ? 0 : 1, $event)"
    >
      {{ slidetoggle.checked ? question.answers[0].label : question.answers[1].label }}
    </mat-slide-toggle>
  }
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SEGMENT] --------- -->
<ng-template #segmentQuestion let-question>
  <answer-segmented-radio
    [id]="question.id"
    [question]="question"
    [elements]="segmentedAnswerData.get(question.id)"
    (selectChange)="answerSelectSegment(question.answers, question.id, $event)"
    (focused)="setFocusElement($event, question.id)"
  ></answer-segmented-radio>
</ng-template>

<ng-template #textField let-question let-answerId="answerId">
  @if (showTextFieldData(question, answerId)) {
    <div class="mt-3">
      <mat-form-field class="notranslate">
        <mat-label>{{ getTextFieldData(question, answerId, 'textFieldName') }} (optional)</mat-label>
        <input
          #textInputItem
          matInput
          type="text"
          autocomplete="off"
          [value]="getTextFieldData(question, answerId, 'givenAnswers')"
          (click)="sendTempTextRequest()"
          (change)="setTempTextAnswer(question.id, textInputItem.value, 'text-input')"
          (focused)="[sendTempTextRequest(), setFocusElement($event)]"
        />
        <mat-error>
          @if (question.id?.invalid) {
            <kf-input-error
              [message]="getFieldErrorMessage(question.id, getTextFieldData(question, answerId, 'textFieldName'))"
            >
            </kf-input-error>
          }
        </mat-error>
      </mat-form-field>
    </div>
  }
</ng-template>

<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!-- -------------------------------------------------- -->
<!-- -------------- RIGHT SIDE TEMPLATES -------------- -->
<!-- -------------------------------------------------- -->

<!-- ---------- RIGHT CONTENT TEMPLATES (MAIN) --------- -->
<ng-template #recommendations>
  <side-recommendations
    [subtopicId]="questionGroupData?.subtopicId"
    [collectionId]="questionGroupData?.collectionId"
    [questionGroupData]="questionGroupData"
  ></side-recommendations>
</ng-template>

<!-- ---------- RIGHT CONTENT TEMPLATES (PREP) --------- -->
<!--
<ng-template #prepRightContent>
  --- DEPRECATED code due to prelim deactivation
  --- Left in for potential reactivation
  @if (questionGroupData?.availableForPreliminary) {
    <div style="width: 80%">
      <h2 class="mb-3 mt-4">Vorbefragung</h2>
      <div class="mb-4">
        {{
          questionGroupData?.availableForPreliminary
            ? 'Mit der Vorbefragung haben Sie die Möglichkeit, Ihrem Kunden vorab Fragen zur Beantwortung zuzusenden. Klicken Sie unten, um die Fragen auf dieser Seite der Vorbefragung hinzuzufügen.'
            : 'Diese Fragengruppe kann leider nicht dem Vorbefragungskatalog hinzugefügt werden.'
        }}
      </div>
      @if (!requestIsPending) {
        @if (!isInPrep) {
          <button
            [attr.data-testcafe]="'screenConsultation-button-addToPrep'"
            [disabled]="addToPrepDisabledState || isLoading"
            mat-flat-button
            color="primary"
            (click)="onAddToPrepClicked($event)"
          >
            <span>Zur Vorbefragung hinzufügen</span>
          </button>
        }
        @if (isInPrep) {
          <div class="d-flex">
            <div class="tag-info tag-positive">
              <div class="tag-info-text">Zur Vorbefragung hinzugefügt</div>
            </div>
            <button
              class="ml-2"
              mat-icon-button
              [attr.data-testcafe]="'screenConsultation-button-addToPrep'"
              [disabled]="addToPrepDisabledState || isLoading"
              color="primary"
              (click)="onAddToPrepClicked($event)"
              aria-label="Aus der Vorbefragung entfernen"
            >
              <kf-icon name="ic_loeschen_24"></kf-icon>
            </button>
          </div>
        }
      } @else {
        <div class="request-pending-solid-button">
          {{ !isInPrep ? 'Zur Vorbefragung hinzufügen' : 'Zur Vorbefragung hinzugefügt' }}
          <mat-progress-spinner
            mode="indeterminate"
            [style.margin-left]="'0.75rem'"
            [diameter]="24"
          ></mat-progress-spinner>
        </div>
      }
    </div>
  }
</ng-template>
  -->

<!-- ---------- LOADING SCREEN --------- -->

<!-- ---------- ERROR SCREEN --------- -->

<ng-template #topicDone>
  <svg
    role="img"
    aria-label="dot done"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="dot"
  >
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find('--color-primary-500')"
      [attr.fill]="color.find('--color-primary-500')"
    />
  </svg>
</ng-template>

<ng-template #topicCurrent>
  <svg
    role="img"
    aria-label="dot current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    transform="rotate(180 0 0)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="dot"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99967 13.4163V6.58301C8.1127 6.58301 6.58301 8.1127 6.58301 9.99967C6.58301 11.8866 8.1127 13.4163 9.99967 13.4163ZM15.4163 9.99967C15.4163 12.9912 12.9912 15.4163 9.99967 15.4163C7.00813 15.4163 4.58301 12.9912 4.58301 9.99967C4.58301 7.00813 7.00813 4.58301 9.99967 4.58301C12.9912 4.58301 15.4163 7.00813 15.4163 9.99967Z"
      [attr.fill]="color.find('--color-primary-500')"
    />
  </svg>
</ng-template>

<ng-template #topicOpen>
  <svg
    role="img"
    aria-label="dot open"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="dot"
  >
    <circle cx="9.99967" cy="9.99967" r="4.41667" stroke-width="2" [attr.stroke]="color.find('--color-grey-500')" />
  </svg>
</ng-template>
