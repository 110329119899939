import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaterialModule } from '@app/material_module';
import { PanelStateEnum } from '@enums';
import { ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';

@Component({
  selector: 'side-content-element-datepick',
  imports: [MaterialModule],
  templateUrl: './side-content-element-datepick.component.html',
  styleUrl: './side-content-element-datepick.component.scss',
})
export class SideContentElementDatepickComponent implements OnInit {
  @Output() changePanelState: EventEmitter<PanelStateEnum> = new EventEmitter<PanelStateEnum>();

  public appointmentDate: Date = null;
  public minimumDate: Date = new Date();
  public readonly panelStateEnum = PanelStateEnum;

  constructor(
    private contentElementService: ContentElementService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.contentElementService.appointmentDate.subscribe(x => (this.appointmentDate = x));
    this.minimumDate.setDate(this.minimumDate.getDate() + 1);
  }

  public onDateSelect(event: Date): void {
    this.appointmentDate = event;
  }

  public confirmDate(): void {
    this.contentElementService.appointmentDate.next(this.appointmentDate);
    this.changePanelState.emit(PanelStateEnum.assign);
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
