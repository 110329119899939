<table [attr.data-testcafe]="'profileDataField-table-' + dataFieldGroup.groupName">
  <tr>
    @for (heading of headerRow; track heading) {
      <th>{{ heading }}</th>
    }
  </tr>
  @for (i of dataRowsIndexes; track i; let idx = $index) {
    <tr>
      @for (dataField of dataFieldGroup.dataFields; track dataField) {
        @if (dataField.type === dataFieldTypeEnum.price) {
          <td [attr.data-testcafe]="getTestCafeValue(dataField, idx)" markNegativePrice>
            {{ getValueMethod(dataField, idx) | thousandsDivider }}
          </td>
        } @else {
          <td [attr.data-testcafe]="getTestCafeValue(dataField, idx)" markNegativePrice>
            {{ getValueMethod(dataField, idx) }}
          </td>
        }
      }
    </tr>
  }
</table>
