import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConsultationSummaryTaskItem } from '@domain/app/consultation.domain';
import { color } from 'bgzv-frontend-library';
import moment from 'moment';

@Component({
  selector: 'result-tasks',
  templateUrl: './result-tasks.component.html',
  styleUrls: ['./result-tasks.component.scss'],
})
export class ResultTasksComponent implements OnInit {
  @Input() taskData: ConsultationSummaryTaskItem[];
  @ViewChild('tasksPanel') tasksPanel: MatExpansionPanel;

  public panelOpenState: boolean;
  public activeTaskIndex: number = 0;

  readonly color = color;

  constructor() {}

  ngOnInit(): void {
    this.taskData = this.taskData.sort((a, b) => {
      if (moment(a.appointmentDate).isBefore(b.appointmentDate)) {
        return -1;
      }
      return 1;
    });
  }

  public openTask(index: number): void {
    this.activeTaskIndex = index;

    this.tasksPanel.open();
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }
}
