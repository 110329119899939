import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { tinyLicenseKey } from '@utils/settings';

@Component({
  selector: 'tiny-editor',
  imports: [EditorComponent, FormsModule],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
  templateUrl: './tiny-editor.component.html',
  styleUrl: './tiny-editor.component.scss',
})
export class TinyEditorComponent {
  @Input() editorConfig: EditorComponent['init'] = {};
  @Input() editorText: string = '';
  @Output() editorValue: EventEmitter<string> = new EventEmitter<string>();

  public emitEditorValue(): void {
    this.editorValue.emit(this.editorText);
  }
  get tinyLicenseKey(): string {
    return tinyLicenseKey;
  }
}
