<div class="d-flex flex-column h-100 align-items-start" [attr.data-html2canvas-ignore]="isScreenshotting || null">
  <marginals-header class="w-100"></marginals-header>

  <ng-container *ngIf="!questionCollectionError; else errorDisplay">
    <div class="d-flex w-100" [ngClass]="useMargin ? 'height-default' : 'height-module'">
      <!-- ------- -->
      <!-- CONTENT -->
      <!-- ------- -->
      <ng-template *ngTemplateOutlet="mainTemplate"> </ng-template>
      <!-- ------- -->
      <!-- ------- -->
      <!-- ------- -->
    </div>
  </ng-container>
</div>

<!-- ------------------------------------------------ -->
<!-- ---------------- MAIN TEMPLATES ---------------- -->
<!-- ------------------------------------------------ -->
<ng-template #mainTemplate>
  <!-- --- LEFT CONTENT --- -->
  <!-- NO LEFT CONTENT ANYMORE -->

  <!-- --- MAIN CONTENT --- -->
  <div
    #scrollable
    (scroll)="onScroll($event)"
    id="mainContent"
    class="position-relative d-flex flex-column flex-scroll main-content no-scrollbar"
    [class.main-content-rec-open]="recPanelState === panelStates.open"
  >
    <ng-template *ngTemplateOutlet="topContentTemplate"> </ng-template>

    @if (questionGroupData?.headline) {
      <div class="mx-4 px-4">
        <kf-headline
          [resetTopSpacing]="true"
          [headline]="questionGroupData.headline"
          [showBackButton]="false"
        ></kf-headline
        ><!-- @Todo: after we drop the breadcrumbs, the topic can be added: [overline]="questionGroupData.topic" -->
      </div>
    }

    <div class="mb-4 mx-4 px-4 mt-2">
      <div id="questionGroupContainer" class="d-flex flex-column">
        <div class="d-flex flex-column">
          <ng-template *ngTemplateOutlet="leftContentTemplate"> </ng-template>
        </div>
      </div>
    </div>

    <div class="overflow-indicator" aria-live="assertive">
      <span [attr.hidden]="!showMoreButton" class="invisible">Inhalte wurden verändert</span>
    </div>
  </div>

  <!-- --- RIGHT CONTENT --- -->
  <div class="position-relative">
    <div id="rightContent" class="h-100 d-flex right-content">
      <ng-template *ngTemplateOutlet="rightContentTemplate"></ng-template>

      <button
        [attr.data-html2canvas-ignore]="true"
        type="button"
        id="rightContentTrigger"
        class="panel-handle panel-handle--right right-content-trigger position-absolute"
        (click)="onRecPanelTrigger('toggle')"
        (keyup)="onRecPanelTrigger('toggle', $event)"
        [attr.data-testcafe]="'screenConsultation-div-rightContentTrigger'"
        [kfTooltip]="
          recommendationsAvailable && recPanelState === 'closed'
            ? 'Empfehlungen können angezeigt werden'
            : !recommendationsAvailable && recPanelState === 'closed'
              ? 'Panel öffnen'
              : 'Panel schließen'
        "
        kfTooltipPosition="before"
        kfTooltipShow="hover_focus"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
      >
        <span class="sr-only">
          {{ recPanelState === 'closed' ? 'Rechtes Panel öffnen' : 'Rechtes Panel schließen' }}
        </span>

        <kf-icon *ngIf="recPanelState === 'closed'" name="ic_chevron_links_24"></kf-icon>
        <kf-icon *ngIf="recPanelState !== 'closed'" name="ic_chevron_rechts_24"></kf-icon>

        <kf-icon
          *ngIf="currentContextMode === 'main'"
          [class.show]="recommendationsAvailable && recPanelState === 'closed'"
          class="icon recommendation-info-icon"
          name="ic_infozeichen_mit_kreis_24"
        ></kf-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #topContentTemplate>
  <div class="mt-4 mx-4 px-4 header-content">
    <div class="d-flex align-self-start align-items-center mt-2" style="min-height: 1.25rem">
      <ng-container *ngIf="questionGroupData">
        <div [debugId]="questionGroupData?.subtopicId" innerHtml="{{ questionGroupData?.topic }}"></div>
        <kf-icon class="mx-1" name="ic_chevron_rechts_24"></kf-icon>
        <div class="mr-3" innerHtml="{{ questionGroupData?.subtopic }}"></div>

        @for (items of helperArray(questionGroupData?.totalCollections).fill(1); track i; let i = $index) {
          <ng-container *ngIf="i < questionGroupData?.currentCollection">
            <ng-template *ngTemplateOutlet="topicDone"></ng-template>
          </ng-container>
          <ng-container *ngIf="i === questionGroupData?.currentCollection">
            <ng-template *ngTemplateOutlet="topicCurrent"></ng-template>
          </ng-container>
          <ng-container *ngIf="i > questionGroupData?.currentCollection">
            <ng-template *ngTemplateOutlet="topicOpen"></ng-template>
          </ng-container>
        }
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #leftContentTemplate>
  <div class="d-flex flex-column flex-grow-1 pb-4 position-relative">
    <ng-container *ngIf="questionGroupData; else loading">
      <kf-tile [interactive]="false" *ngIf="questionGroupData.description || imageData?.length >= 1" class="mb2rem">
        <div
          *ngIf="questionGroupData.description"
          class="mt-3 pr-5"
          [innerHTML]="questionGroupData.description | safeHtml"
        ></div>
        <div class="mt-3 ml-1 mr-1" *ngIf="imageData?.length > 1">
          <bgzv-image
            [imageMulti]="imageData"
            [width]="'100%'"
            [height]="'300px'"
            [enlargeable]="true"
            [enlargeOptions]="{ endWidth: '100%', endHeight: '50vh' }"
          ></bgzv-image>
        </div>
        <div class="mt-3" *ngIf="imageData?.length === 1">
          <bgzv-image
            [image]="imageData[0]"
            [showIcon]="true"
            [width]="'50%'"
            [height]="'200px'"
            [behavior]="questionGroupData?.imageDefaultSize"
          ></bgzv-image>
        </div>
      </kf-tile>

      @for (question of questionData; track question; let i = $index; let first = $first; let last = $last) {
        <kf-tile [interactive]="false" [class.mb2rem]="!last">
          <div>
            <!-- ------ HEADER OF QUESTION ------ -->
            <div class="d-flex justify-content-start mb-3">
              <h3
                [debugId]="question.id"
                [innerHTML]="
                  (isDebugInQuestionHeadingShown(question) ? 'DEBUG ' : '') + question.type ===
                  questionEnum.connectedSlider
                    ? getSliderQuestionHeader(question.name)
                    : (question.name | safeHtml)
                "
                class="mat-body-1 font-weight-bold d-flex py-2"
              ></h3>
              <button
                *ngIf="hasQuestionDocuments(question)"
                class="ml-2"
                [attr.data-testcafe]="'screenConsultation-documents-' + getTestcafeId(question.label, question.id)"
                [matMenuTriggerFor]="menu"
                aria-label="Dokumente"
                [kfTooltip]="(question.medias.length > 1 ? 'Dokumente' : 'Dokument') + ' anzeigen'"
                kfTooltipPosition="after"
                kfTooltipShow="hover_focus"
                mat-icon-button
              >
                <kf-icon name="ic_datei_24"></kf-icon>
              </button>

              <mat-menu #menu="matMenu">
                <div class="d-flex flex-column">
                  @for (document of question.medias; track document; let last = $last) {
                    <button *ngIf="document.type !== 'IMAGE'" mat-menu-item (click)="showDocument(document, $event)">
                      {{ document.name + ' (' + document.type + ')' }}
                    </button>
                  }
                </div>
              </mat-menu>

              <button
                *ngIf="question.connectedToDataFieldGroups && question.type !== questionEnum.dataFieldGroup"
                class="ml-2"
                [attr.data-testcafe]="'screenConsultation-datafields-' + getTestcafeId(question.label, question.id)"
                (click)="onOpenQuestionDataFields(question)"
                aria-label="Weitere Informationen"
                kfTooltip="Detailinformationen anzeigen"
                kfTooltipPosition="after"
                kfTooltipShow="hover_focus"
                mat-icon-button
              >
                <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
              </button>
            </div>
            <div *ngIf="question.label" [innerHTML]="question.label | safeHtml" class="mb-3"></div>

            <!-- ------ IMAGE OF QUESTION ------ -->
            <ng-container *ngIf="hasQuestionImages(question) === 1">
              <bgzv-image
                [image]="getQuestionImages(question)[0]"
                [height]="'300px'"
                [behavior]="question.imageDefaultSize"
              ></bgzv-image>
            </ng-container>
            <ng-container *ngIf="hasQuestionImages(question) > 1">
              <bgzv-image
                [imageMulti]="getQuestionImages(question)"
                [height]="'300px'"
                [behavior]="question.imageDefaultSize"
              ></bgzv-image>
            </ng-container>

            @if (!isQuestionPaired(question)) {
              @if (question.type === questionEnum.dataFieldGroup) {
                <div class="question-dataFieldGroup">
                  <ng-template *ngTemplateOutlet="dataFieldGroup; context: { $implicit: question }"> </ng-template>
                </div>
              } @else if (
                question.type === questionEnum.single ||
                question.type === questionEnum.multi ||
                question.type === questionEnum.singleImage ||
                question.type === questionEnum.multiImage
              ) {
                <ng-template *ngTemplateOutlet="selectQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (
                question.type === questionEnum.slider ||
                question.type === questionEnum.expSlider ||
                question.type === questionEnum.connectedSlider
              ) {
                <ng-template *ngTemplateOutlet="sliderQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.number) {
                <ng-template *ngTemplateOutlet="numberQuestion; context: { $implicit: question }"> </ng-template>
                <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.toggle) {
                <ng-template *ngTemplateOutlet="toggleQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.segments) {
                <ng-template *ngTemplateOutlet="segmentQuestion; context: { $implicit: question }"> </ng-template>
                <ng-template *ngTemplateOutlet="textField; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.text) {
                <ng-template *ngTemplateOutlet="textQuestion; context: { $implicit: question }"> </ng-template>
              } @else if (question.type === questionEnum.tool) {
                <ng-template *ngTemplateOutlet="toolQuestion; context: { $implicit: question }"> </ng-template>
              }
            }

            <ng-container *ngIf="isQuestionPaired(question)">
              <div
                class="d-flex flex-column mt-3"
                [style.backgroundColor]="color.find(color.name.Neutral100)"
                style="padding: 1.5rem"
              >
                <div class="font-weight-bold">
                  {{ getPairedQuestionAnswer(question).length > 1 ? 'Antworten' : 'Antwort' }}
                </div>
                <div class="d-flex">
                  {{ getPairedQuestionAnswer(question).join(' | ') }}
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="getOtherAnswerLabel(otherAnswersMap.get(question.id)) > 0 && !isQuestionPaired(question)"
            >
              <button
                mat-button
                color="primary"
                class="mt-2 prev-answer-button"
                [attr.data-testcafe]="'prevAnswer-button-' + question.id"
                (click)="
                  [onClickOtherAnswerDialog($event, otherAnswersMap.get(question.id), question), resetFocusElement()]
                "
              >
                <kf-icon name="ic_historie_24"></kf-icon>
                <span
                  [attr.data-testcafe]="'prevAnswer-answer-' + getOtherAnswerLabel(otherAnswersMap.get(question.id))"
                >
                  Vorherige Antworten: {{ getOtherAnswerLabel(otherAnswersMap.get(question.id)) }}
                </span>
              </button>
            </ng-container>
          </div>

          <!-- linked questions -->
          <ng-container
            *ngIf="isQuestionShown(question) && question.syncedQuestions && question.syncedQuestions.length > 0"
          >
            <div>
              <button
                class="my-3"
                [attr.data-testcafe]="'syncedQuestion-button-' + question.id"
                mat-button
                color="primary"
                (click)="changeSyncQuestionState(question.id)"
              >
                <kf-icon name="ic_ringe_24"></kf-icon>
                <span>Verknüpfte Fragen {{ showSyncedQuestions(question.id) ? 'ausblenden' : 'anzeigen' }}</span>
              </button>
              <div
                class="synced-questions"
                [class.expanded]="showSyncedQuestions(question.id)"
                [attr.data-testcafe]="'syncedQuestion-answer-' + question.id"
              >
                <synced-questions [syncedQuestions]="question.syncedQuestions"></synced-questions>
              </div>
            </div>
          </ng-container>
        </kf-tile>

        <div *ngIf="last"></div>
      }
    </ng-container>
  </div>
</ng-template>

<ng-template #rightContentTemplate>
  <div
    class="d-flex flex-column flex-grow-1 flex-scroll position-relative no-scrollbar bg-white"
    style="padding: 1.5rem 1.5rem 3rem"
  >
    <ng-container *ngIf="questionGroupData && questionGroupData?.template !== questionTemplateEnum.default">
      <ng-container
        *ngIf="questionGroupData && questionGroupData?.template === questionTemplateEnum.products; else loading"
      >
        <ng-container *ngIf="currentContextMode === 'main'">
          <ng-template *ngTemplateOutlet="recommendations"></ng-template>
        </ng-container> </ng-container
    ></ng-container>
    <ng-container *ngIf="currentContextMode === 'prep'">
      <ng-template *ngTemplateOutlet="prepRightContent"> </ng-template>
    </ng-container>
    <div style="min-height: 8rem"></div>
  </div>
</ng-template>

<!-- ------------------------------------------------ -->
<!-- -------------- QUESTION TEMPLATES -------------- -->
<!-- ------------------------------------------------ -->

<!-- ---------- QUESTION TEMPLATE [TOOL] --------- -->
<ng-template #toolQuestion let-question>
  <div [id]="question.id" *ngIf="question?.tool; else noCompetence">
    <kbm-tool-container
      [toolConfiguration]="question?.tool"
      [hasNavigation]="true"
      [overline]="'Toolbox'"
    ></kbm-tool-container>
  </div>
  <ng-template #noCompetence>
    Für die Nutzung des Werkzeuges in dieser Frage fehlt Ihnen die nötige Kompetenz. Bitte wenden Sie sich an Ihren
    Content-Admin.
  </ng-template>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TEXT-MULTILINE] --------- -->
<ng-template #textQuestion let-question>
  <mat-form-field [id]="question.id" class="notranslate">
    <mat-label>Ihre Eingabe</mat-label>
    <div class="kf-field-char-counter" aria-hidden="true">{{ textArea.value?.length || 0 }}/1000</div>
    <textarea
      #textArea
      ngDefaultControl
      matInput
      maxLength="1000"
      cdkTextareaAutosize
      type="text-input"
      [value]="getTextAnswer(question)"
      (focused)="[sendTempTextRequest(), setFocusElement(textArea.value)]"
      (click)="sendTempTextRequest()"
      (change)="[setTempTextAnswer(question.id, textArea.value, 'text', question.answers, -1), sendTempTextRequest()]"
    ></textarea>
  </mat-form-field>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [DataFieldGroup] --------- -->
<ng-template #dataFieldGroup let-question>
  <side-profile
    [id]="question.id"
    [inputValues]="{
      itemId: question?.id,
      isQuestionDataField: question?.connectedToDataFieldGroups,
      isQuestionField: true
    }"
  ></side-profile>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SELECT] --------- -->
<ng-template #selectQuestion let-question>
  <answer-select
    [id]="question.id"
    [question]="question"
    [selectAnswerData]="selectAnswerData"
    (answerSelected)="answerSelect(question, $event)"
  ></answer-select>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SLIDER / SLIDER-EXP] --------- -->
<ng-template #sliderQuestion let-question>
  <answer-slider
    [id]="question.id"
    [min]="numericalAnswerMinMax.get(question.id).min"
    [max]="numericalAnswerMinMax.get(question.id).max"
    [question]="question"
    [sliderPropertiesMap]="sliderPropertiesMap"
    [numericalAnswerData]="numericalAnswerData"
    [questionAnswer]="true"
    (valueSelected)="answerSelectSlider(question, $event)"
  ></answer-slider>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [NUMBER] --------- -->
<ng-template #numberQuestion let-question>
  <vr-answer-number-input
    [id]="question.id"
    [testcafeData]="getTestcafeAnswerId(question.name)"
    [defaultNumber]="numericalAnswerData.get(question.id).value || 0"
    [stepValue]="question.stepValue || 1"
    [minValue]="numericalAnswerMinMax.get(question.id).min"
    [maxValue]="numericalAnswerMinMax.get(question.id).max"
    [keyboardInput]="true"
    (currentNumberChanged)="answerSelectNumeric(question.answers, question.id, $event)"
  >
  </vr-answer-number-input>
</ng-template>

<!-- ---------- QUESTION TEMPLATE [TOGGLE] --------- -->
<ng-template #toggleQuestion let-question>
  <div *ngIf="!question.label" class="mb-2"></div>
  <mat-slide-toggle
    [id]="question.id"
    *ngIf="question.answers.length > 0"
    #slidetoggle
    (change)="answerSelectSingle(question.answers, question.id, $event.checked ? 0 : 1, $event)"
    color="primary"
    [checked]="singleAnswerData.get(question.id) === question.answers[0].id"
    [answered]="singleAnswerData.get(question.id) !== zeroUUID"
    labelPosition="before"
    >{{ slidetoggle.checked ? question.answers[0].label : question.answers[1].label }}</mat-slide-toggle
  >
</ng-template>

<!-- ---------- QUESTION TEMPLATE [SEGMENT] --------- -->
<ng-template #segmentQuestion let-question>
  <answer-segmented-radio
    [id]="question.id"
    [question]="question"
    [elements]="segmentedAnswerData.get(question.id)"
    (selectChange)="answerSelectSegment(question.answers, question.id, $event)"
    (focused)="setFocusElement($event, question.id)"
  ></answer-segmented-radio>
</ng-template>

<ng-template #textField let-question let-answerId="answerId">
  <div class="mt-3" *ngIf="showTextFieldData(question, answerId)">
    <mat-form-field class="notranslate">
      <mat-label>{{ getTextFieldData(question, answerId, 'textFieldName') }} (optional)</mat-label>
      <input
        #textInputItem
        matInput
        type="text"
        [value]="getTextFieldData(question, answerId, 'givenAnswers')"
        (change)="setTempTextAnswer(question.id, textInputItem.value, 'text-input')"
        (focused)="[sendTempTextRequest(), setFocusElement($event)]"
        (click)="sendTempTextRequest()"
      />
      <mat-error>
        <kf-input-error
          *ngIf="question.id?.invalid"
          [message]="getFieldErrorMessage(question.id, getTextFieldData(question, answerId, 'textFieldName'))"
        >
        </kf-input-error>
      </mat-error>
    </mat-form-field>
  </div>
</ng-template>

<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!--  -->
<!-- -------------------------------------------------- -->
<!-- -------------- RIGHT SIDE TEMPLATES -------------- -->
<!-- -------------------------------------------------- -->

<!-- ---------- RIGHT CONTENT TEMPLATES (MAIN) --------- -->
<ng-template #recommendations>
  <side-recommendations
    [collectionId]="questionGroupData?.collectionId"
    [questionGroupData]="questionGroupData"
  ></side-recommendations>
</ng-template>

<!-- ---------- RIGHT CONTENT TEMPLATES (PREP) --------- -->
<ng-template #prepRightContent>
  <div style="width: 80%" *ngIf="questionGroupData?.availableForPreliminary">
    <h2 class="mb-3 mt-4">Vorbefragung</h2>
    <div class="mb-4">
      {{
        questionGroupData?.availableForPreliminary
          ? 'Mit der Vorbefragung haben Sie die Möglichkeit, Ihrem Kunden vorab Fragen zur Beantwortung zuzusenden. Klicken Sie unten, um die Fragen auf dieser Seite der Vorbefragung hinzuzufügen.'
          : 'Diese Fragengruppe kann leider nicht dem Vorbefragungskatalog hinzugefügt werden.'
      }}
    </div>
    <ng-container *ngIf="!requestIsPending; else requestPendingButton">
      <button
        [attr.data-testcafe]="'screenConsultation-button-addToPrep'"
        [disabled]="addToPrepDisabledState || isLoading"
        *ngIf="!isInPrep"
        mat-flat-button
        color="primary"
        (click)="onAddToPrepClicked($event)"
      >
        <span>Zur Vorbefragung hinzufügen</span>
      </button>

      <div class="d-flex" *ngIf="isInPrep">
        <div class="tag-info tag-positive">
          <div class="tag-info-text">Zur Vorbefragung hinzugefügt</div>
        </div>
        <button
          class="ml-2"
          *ngIf="isInPrep"
          mat-icon-button
          [attr.data-testcafe]="'screenConsultation-button-addToPrep'"
          [disabled]="addToPrepDisabledState || isLoading"
          color="primary"
          (click)="onAddToPrepClicked($event)"
          aria-label="Aus der Vorbefragung entfernen"
        >
          <kf-icon name="ic_loeschen_24"></kf-icon>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #requestPendingButton>
  <div class="request-pending-solid-button">
    {{ !isInPrep ? 'Zur Vorbefragung hinzufügen' : 'Zur Vorbefragung hinzugefügt' }}
    <mat-progress-spinner mode="indeterminate" [style.margin-left]="'0.75rem'" [diameter]="24"></mat-progress-spinner>
  </div>
</ng-template>

<!-- ------ RECOMMENDATION COMPONENT TEMPLATES ------ -->
<ng-template #emptyArea let-text="text">
  <div class="position-absolute kf-bg-grey-100 w-100 empty-basket-area">
    <div class="d-flex justify-content-center align-items-center h-100 empty-basket-border">
      <div class="empty-basket-icon"></div>
      <div [style.letter-spacing.rem]="0.3">{{ text }}</div>
    </div>
  </div>
</ng-template>

<!-- ---------- LOADING SCREEN --------- -->
<ng-template #loading>
  <div class="d-flex flex-center w-100 h-100">
    <mat-progress-spinner [color]="'primary'" mode="indeterminate" [diameter]="90"></mat-progress-spinner>
  </div>
</ng-template>

<!-- ---------- ERROR SCREEN --------- -->
<ng-template #errorDisplay>
  <mat-error class="w-100"
    ><kf-input-error message="Ein Fehler ist aufgetreten (keine Themen ausgewählt?)"></kf-input-error
  ></mat-error>
</ng-template>

<ng-template #topicDone>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="dot">
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find(color.name.BrandPrimary)"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #topicCurrent>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    transform="rotate(180 0 0)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="dot"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99967 13.4163V6.58301C8.1127 6.58301 6.58301 8.1127 6.58301 9.99967C6.58301 11.8866 8.1127 13.4163 9.99967 13.4163ZM15.4163 9.99967C15.4163 12.9912 12.9912 15.4163 9.99967 15.4163C7.00813 15.4163 4.58301 12.9912 4.58301 9.99967C4.58301 7.00813 7.00813 4.58301 9.99967 4.58301C12.9912 4.58301 15.4163 7.00813 15.4163 9.99967Z"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #topicOpen>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="dot">
    <circle cx="9.99967" cy="9.99967" r="4.41667" stroke-width="2" [attr.stroke]="color.find(color.name.Neutral500)" />
  </svg>
</ng-template>
