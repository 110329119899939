<mat-expansion-panel
  #outerPanel
  class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
  [hideToggle]="true"
  [expanded]="options.startExpanded || (displayType === 'progress' && !options.isGreyed && !surpressExpansion)"
  [disabled]="options.isDisabled"
>
  <mat-expansion-panel-header
    [collapsedHeight]="options.headerHeight"
    [expandedHeight]="options.headerHeight"
    class="d-flex align-items-center flex-grow-1 w-100"
    [style.paddingLeft]="options.mainMarginLeft"
  >
    <div *ngIf="!options.singleTopic" class="d-flex flex-center outer-panel-icon">
      <kf-icon name="ic_chevron_rechts_24" [ngClass]="{ open: outerPanel.expanded }" class="panel-icon"></kf-icon>
    </div>
    <div
      class="d-flex align-items-center text-truncate"
      style="width: 90%"
      [attr.data-testcafe]="'itemMenu-expansionPanelHeader-' + data?.name"
    >
      <div class="d-flex align-items-center pl-1">
        <ng-container *ngIf="mode === 'main'">
          <ng-template
            *ngTemplateOutlet="
              data.status === status.done ? iconDone : data.status === status.inProgress ? iconProgress : iconOpen
            "
          ></ng-template>
        </ng-container>
      </div>
      <span class="font-weight-bold">{{ useIndex ? index + 1 + '. ' : '' }}{{ data?.title || data?.name }}</span>
    </div>
  </mat-expansion-panel-header>
  <div [style.width]="'90%'">
    <!-- ----------------------- INNER ACCORDION ----------------------- -->
    <ng-template *ngTemplateOutlet="innerAccordion; context: { $implicit: data.subtopics }"></ng-template>
  </div>
  <!-- --------------------- INNER ACCORDION END --------------------- -->
  <div *ngIf="outerPanel.expanded" [style.marginBottom]="options.mainMarginBottom"></div>
</mat-expansion-panel>

<!-- ------------------------ INNER ACCORDION TEMPLATE ------------------------ -->
<ng-template #innerAccordion let-innerItems>
  <mat-accordion [multi]="true" [displayMode]="'flat'">
    <div *ngFor="let innerItem of innerItems; index as i">
      <ng-container *ngIf="!onlySelected || innerItem.selected">
        <!-- ------ SUBTOPIC ITEMS ------ -->
        <mat-expansion-panel
          #innerPanel
          [hideToggle]="true"
          class="w-100 ml-4 mat-elevation-z0 rounded-0"
          [expanded]="options.startExpanded"
          [disabled]="options.enableSubtopicJump"
          [expanded]="setColor(innerItem) !== color.find(color.name.Neutral500) && !surpressExpansion"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'3rem'"
            [expandedHeight]="'3rem'"
            class="d-flex align-items-center flex-grow-1 w-100"
            [style.paddingLeft]="options.mainMarginLeft"
          >
            <div
              class="d-flex flex-center inner-panel-icon"
              (click)="options.enableSubtopicJump ? (innerPanel.expanded = !innerPanel.expanded) : null"
            >
              <kf-icon name="ic_chevron_rechts_24" class="panel-icon" [class.open]="innerPanel.expanded"></kf-icon>
            </div>

            <div class="d-flex" [class.align-items-center]="displayType !== 'progress'">
              <div *ngIf="displayType === 'select' || displayType === 'select-prep'">
                <mat-checkbox
                  class="mb-0"
                  #box
                  id="{{ innerItem.id + '-' + i }}"
                  [attr.data-testcafe]="'itemMenu-checkbox-' + innerItem.id + '-' + getTestcafeId(innerItem.name)"
                  [checked]="displayType !== 'select-prep' ? innerItem.selected : innerItem.showInPreliminary"
                  (click)="$event.stopPropagation()"
                  [disabled]="isDisabled"
                  (change)="handleInnerItemSelect($event.checked, innerItem)"
                  [color]="'grey'"
                >
                  <span class="font-weight-bold">{{ innerItem?.title || innerItem?.name }}</span>
                </mat-checkbox>
              </div>
              <div *ngIf="displayType !== 'select' && displayType !== 'select-prep'">
                <span class="font-weight-bold">{{ innerItem?.title || innerItem?.name }}</span>
              </div>
              <div *ngIf="displayType === 'progress' && mode === 'main'" class="pl-1">
                <ng-container *ngIf="mode === 'main'">
                  <ng-template
                    *ngTemplateOutlet="
                      innerItem.status === status.done
                        ? iconDone
                        : innerItem.status === status.inProgress
                          ? iconProgress
                          : iconOpen
                    "
                  ></ng-template>
                </ng-container>
              </div>

              <div *ngIf="displayType === 'check'" class="pl-1">
                <kf-icon *ngIf="mode === 'main'" name="ic_haken_24" class="color-success"></kf-icon>

                <kf-icon
                  *ngIf="mode === 'prep' && hasPrelimSelectedChildren(innerItem, 'questionGroups')"
                  name="ic_haken_24"
                  class="color-success"
                ></kf-icon>

                <kf-icon
                  *ngIf="mode === 'prep' && !hasPrelimSelectedChildren(innerItem, 'questionGroups')"
                  name="ic_schliessen_24"
                  class="color-warn"
                ></kf-icon>
              </div>
            </div>
          </mat-expansion-panel-header>
          <!-- ------ QUESTIONGROUP ITEMS ------ -->
          <ng-container *ngIf="displayType !== 'select-prep' && displayType !== 'check'">
            <ng-container *ngFor="let groupItem of innerItem.questionGroups">
              <ng-template
                *ngTemplateOutlet="
                  questionGroupItems;
                  context: { $implicit: groupItem, subId: innerItem.id, groupId: groupItem.id }
                "
              ></ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="displayType === 'select-prep' || displayType === 'check'">
            <ng-template
              *ngTemplateOutlet="questionGroupItemsWithQuestions; context: { $implicit: innerItem.questionGroups }"
            ></ng-template>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </div>
  </mat-accordion>
</ng-template>

<ng-template #questionGroupItems let-groupItem let-subId="subId" let-groupId="groupId">
  <div class="d-flex align-items-center mb-3" [style.marginLeft]="options.groupIndent">
    <div *ngIf="displayType === 'select-prep'">
      <mat-checkbox
        class="mb-0"
        #boxgroup
        id="{{ subId + '-' + groupId }}"
        [checked]="groupItem.showInPreliminary"
        [disabled]="isDisabled"
        [class.disabled]="isDisabled"
        (click)="$event.stopPropagation()"
        (change)="handleQuestionGroupSelect($event.checked, groupItem)"
        [color]="'grey'"
        ><span>{{ groupItem?.title || groupItem?.name }}</span></mat-checkbox
      >
    </div>
    <div *ngIf="displayType !== 'select-prep'">
      <span>{{ groupItem?.title || groupItem?.name }}</span>
    </div>

    <ng-container *ngIf="displayType === 'check'">
      <div>
        <kf-icon *ngIf="mode === 'main'" name="ic_haken_24" class="color-success"></kf-icon>

        <kf-icon
          *ngIf="mode === 'prep' && groupItem.showInPreliminary"
          name="ic_haken_24"
          class="color-success"
        ></kf-icon>

        <kf-icon
          *ngIf="mode === 'prep' && !groupItem.showInPreliminary"
          name="ic_schliessen_24"
          class="color-warn"
        ></kf-icon>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #questionGroupItemsWithQuestions let-innerItems>
  <mat-accordion [multi]="true" [displayMode]="'flat'">
    <div *ngFor="let innerItem of innerItems; index as i">
      <!-- ------ SUBTOPIC ITEMS ------ -->
      <mat-expansion-panel
        #innerPanel
        [hideToggle]="true"
        class="w-100 ml-4 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
        [expanded]="options.startExpanded"
        [disabled]="options.enableSubtopicJump"
        [expanded]="setColor(innerItem) !== color.find(color.name.Neutral500) && !surpressExpansion"
      >
        <mat-expansion-panel-header
          [collapsedHeight]="'3rem'"
          [expandedHeight]="'3rem'"
          class="d-flex align-items-center flex-grow-1 w-100"
          [style.paddingLeft]="options.mainMarginLeft"
        >
          <div
            class="d-flex flex-center inner-panel-icon"
            (click)="options.enableSubtopicJump ? (innerPanel.expanded = !innerPanel.expanded) : null"
          >
            <kf-icon name="ic_chevron_rechts_24" class="panel-icon" [class.open]="innerPanel.expanded"></kf-icon>
          </div>
          <div class="d-flex" [class.align-items-center]="displayType !== 'progress'">
            <div *ngIf="displayType === 'select' || displayType === 'select-prep'">
              <mat-checkbox
                [indeterminate]="isIndeterminate(innerItems)"
                class="mb-0"
                #box
                id="{{ innerItem.id + '-' + i }}"
                [checked]="displayType !== 'select-prep' ? innerItem.selected : innerItem.showInPreliminary"
                (click)="$event.stopPropagation()"
                [disabled]="isDisabled"
                (change)="handleQuestionGroupSelect($event.checked, innerItem)"
                [color]="'grey'"
                ><span>{{ innerItem?.title || innerItem?.name }}</span></mat-checkbox
              >
            </div>
            <div *ngIf="displayType !== 'select' && displayType !== 'select-prep'">
              <span>{{ innerItem?.title || innerItem?.name }}</span>
            </div>
            <div *ngIf="displayType === 'progress' && mode === 'main'" class="pl-1">
              <ng-container *ngIf="mode === 'main'">
                <ng-template
                  *ngTemplateOutlet="
                    innerItem.status === status.done
                      ? iconDone
                      : innerItem.status === status.inProgress
                        ? iconProgress
                        : iconOpen
                  "
                ></ng-template>
              </ng-container>
            </div>

            <div *ngIf="displayType === 'check'" class="pl-1">
              <kf-icon *ngIf="mode === 'main'" name="ic_haken_24" class="color-success"></kf-icon>

              <kf-icon
                *ngIf="mode === 'prep' && hasPrelimSelectedChildren(innerItem, 'questions')"
                name="ic_haken_24"
                class="color-success"
              ></kf-icon>

              <kf-icon
                *ngIf="mode === 'prep' && !hasPrelimSelectedChildren(innerItem, 'questions')"
                name="ic_schliessen_24"
                class="color-warn"
              ></kf-icon>
            </div>
          </div>
        </mat-expansion-panel-header>
        <!-- ------ QUESTION ITEMS ------ -->
        <ng-container *ngFor="let questionItem of innerItem.questions">
          <ng-template
            *ngTemplateOutlet="
              questionItems;
              context: { $implicit: questionItem, qgId: innerItem.id, qId: questionItem.id }
            "
          ></ng-template>
        </ng-container>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</ng-template>

<ng-template #questionItems let-questionItem let-qId="qId" let-qgId="qgId">
  <div class="d-flex align-items-center mb-3" [style.marginLeft]="options.groupIndent">
    <ng-container *ngIf="displayType === 'select-prep'">
      <div>
        <mat-checkbox
          class="mb-0"
          #boxgroup
          id="{{ qId + '-' + qgId }}"
          [checked]="questionItem.showInPreliminary"
          [disabled]="isDisabled"
          [class.disabled]="isDisabled"
          (click)="$event.stopPropagation()"
          (change)="handleQuestionSelect($event.checked, questionItem)"
          [color]="'grey'"
        ></mat-checkbox>
      </div>
    </ng-container>
    <div
      class="mr-4 ml-2"
      [style.cursor]="options.groupCursor"
      [style.color]="setColor(questionItem)"
      [class.checked]="displayType === 'select'"
      [class.pointer-events-none]="isDisabled"
      [attr.data-testcafe]="'itemMenu-div-question-' + questionItem?.id + '-' + getTestcafeId(questionItem?.name)"
      (click)="displayType === 'select-prep' && setGroupCheckBox(qId + '-' + qgId, questionItem)"
    >
      {{ questionItem?.title || questionItem?.name }}
    </div>
    <ng-container *ngIf="displayType === 'check'">
      <div>
        <kf-icon *ngIf="mode === 'main'" name="ic_haken_24" class="color-success"></kf-icon>

        <kf-icon
          *ngIf="mode === 'prep' && questionItem.showInPreliminary"
          name="ic_haken_24"
          class="color-success"
        ></kf-icon>

        <kf-icon
          name="ic_schliessen_24"
          *ngIf="mode === 'prep' && !questionItem.showInPreliminary"
          class="color-warn"
        ></kf-icon>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #iconOpen>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00024" r="5" stroke-width="2" [attr.stroke]="color.find(color.name.Neutral500)" />
    <path
      d="M 11.25,6.00 C 11.25,8.90 8.90,11.25 6.00,11.25 3.10,11.25 0.75,8.90 0.75,6.00 0.75,3.10 3.10,0.75 6.00,0.75 6.00,0.75 8.90,0.75 11.25,6.00 Z"
    />
  </svg>
</ng-template>
<ng-template #iconProgress>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00024" r="5" stroke-width="2" [attr.stroke]="color.find(color.name.BrandPrimary)" />
    <path
      d="M12 6.00024C12 2.68654 9.31371 0.000244141 6 0.000244141V6.00024H12Z"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>
<ng-template #iconDone>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="6" [attr.fill]="color.find(color.name.Success)" />
  </svg>
</ng-template>
