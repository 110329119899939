<aside class="d-flex flex-column w-100 flex-scroll kf-bg pt-4 px-5" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <header class="d-flex flex-column mb-4">
    <h2>Konten vergleichen</h2>
  </header>
  <div class="px-2">
    @if (!variantCalculator) {
      <div class="w-100 mt-5">
        <mat-progress-spinner
          [color]="'primary'"
          mode="indeterminate"
          class="m-auto"
          [diameter]="90"
        ></mat-progress-spinner>
      </div>
    } @else {
      <div class="d-flex justify-content-center">
        <button
          class="overlay-compare-accounts__products-gallery-button"
          [ngClass]="galleryMayMoveLeft ? 'button-active' : 'button-inactive'"
          [attr.data-testcafe]="'overlayCompareAccounts-button-leftClick'"
          (click)="onGalleryLeftClick()"
          aria-label="Nach Links scrollen"
          [disabled]="!galleryMayMoveLeft"
        >
          <kf-icon name="ic_pfeil_links_24"></kf-icon>
        </button>
        <div
          #productsGalleryScrollContainer
          class="d-flex overlay-compare-accounts__products-gallery-scroll-container"
          [style]="'--product-variant-gallery-items: ' + galleryItems"
        >
          @for (product of variantCalculator.products; track product) {
            <product-variant-card
              [product]="product"
              [recommended]="recommendation === product.productId"
              [calculatedPrice]="prices && prices[product.productId]"
              (toggleInCart)="variantCalculator.toggleInCart(product, subtopicId)"
              [inCart]="productsInCart && productsInCart.indexOf(product.productId) !== -1"
            ></product-variant-card>
          }
        </div>
        <button
          class="overlay-compare-accounts__products-gallery-button"
          [ngClass]="galleryMayMoveRight ? 'button-active' : 'button-inactive'"
          [attr.data-testcafe]="'overlayCompareAccounts-button-rightClick'"
          (click)="onGalleryRightClick()"
          aria-label="Nach Rechts scrollen"
          [disabled]="!galleryMayMoveRight"
        >
          <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
        </button>
      </div>

      <compare-accounts-inputs
        [inputs]="variantCalculator"
        [isLoading]="isLoading"
        (valueChange)="variantCalculator.setInputValues($event)"
      ></compare-accounts-inputs>
    }
  </div>
</aside>

<button
  [attr.data-testcafe]="'overlayCompareAccounts-button-close'"
  class="kf-popup-close"
  mat-icon-button
  (click)="close()"
  aria-label="Dialog schließen"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>
