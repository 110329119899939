import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegmentGroup } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { IApiEndpointService, INavigationService } from '@de.fiduciagad.kundenportal/ui-context';
import { AppDimensions } from '@domain/app/dimensions.domain';
import { ConsultationStatusEnum, PanelStates, RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { PortalContextProvider } from '@services/portal-context.provider';
import { QueryService } from '@services/query-service/query.service';
import { TopicService } from '@services/topic-service/topic-service';
import { BehaviorSubject, lastValueFrom, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private _routerSub: Subscription;

  private _assetPath: string;
  private _prepToggleMode: string;
  private _consultationStatusChanged: boolean;
  private _customerMessage = new BehaviorSubject<string>('');
  private _skipSelection = false;

  private _dialogSub: Subscription;
  private _dialogRef = null;

  private dStart = null;
  private dEnd = null;

  public context = new BehaviorSubject<string>(undefined);
  public appDimension = new BehaviorSubject<AppDimensions>(undefined);
  public lastQuestionGroupId = new BehaviorSubject<string>('-1');
  public focusModeChange = new BehaviorSubject<boolean>(false);

  public isFocusMode: boolean = false;
  public isManagedFooter: boolean = false;
  public isManagedPanel: boolean = false;

  public recPanelState: PanelStates = PanelStates.open;
  public agendaPanelManual: boolean = false;
  public recPanelManual: boolean = false;

  public currentUrl: string;
  public previousUrl: string;
  public overlayWasClosed: boolean = false;
  public appPath: string;

  public isConsulationModuleContext: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private dialogService: DialogService,
    private clientService: ClientService,
    private configService: ConfigService,
    private queryService: QueryService,
    private topicService: TopicService,
    private actionService: ActionService,
    private router: Router,
    private portalContextProvider: PortalContextProvider
  ) {
    this.init();
  }

  private init() {
    this._routerSub = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (this.currentUrl && this.currentUrl.includes('(overlay:') === false) {
          this.previousUrl = this.currentUrl;
        }
        this.overlayWasClosed = this.currentUrl?.includes('(overlay:') || false;
        this.currentUrl = val.url.length && val.url[0] === '/' ? val.url.slice(1) : val.url;

        this.setContext(this.currentUrl);
      }
    });

    // get initial url
    if (environment.platform === 'vp') {
      this.currentUrl =
        this.router.url.length && this.router.url[0] === '/' ? this.router.url.slice(1) : this.router.url;
      this.setContext(this.currentUrl);
    }

    if (environment.platform === 'vp' && !environment.isLocal) {
      const portalNavService = this.portalContextProvider.portalContext?.getNavigationService() as INavigationService;
      const portalBasePath = portalNavService?.getPortalBasePath();

      if (portalBasePath?.includes('/bws')) {
        const endpointService =
          this.portalContextProvider.portalContext?.getApiEndpointService() as IApiEndpointService;
        this.assetPath = endpointService.getServiceUrl('bgzv-frontend-main', '');
        this.appPath = endpointService.getServiceUrl('bgzv-frontend-main', '');
        console.log(`%c [bgzv-frontend-main] CONTEXT SERVICE ASSET PATH IN BWS`, 'color: #0066cc', this.assetPath);
      }
    } else {
      this.assetPath = '';
      this.appPath = '';
    }
  }

  public setAppDimensions(dimensionObject: AppDimensions) {
    this.appDimension.next(dimensionObject);
  }

  private setContext(location) {
    this.context.next(location);
  }

  public closeOverlay(outletName: string = 'overlay'): Promise<boolean> {
    const currentUrlTree = this.router.parseUrl(this.router.routerState.snapshot.url);
    const newRootSegment = this.recursivelyClearOutlet(currentUrlTree.root, outletName);
    currentUrlTree.root = newRootSegment;
    return this.router.navigateByUrl(currentUrlTree);
  }

  public toggleFocusMode(mode: 'sticky' | 'normal' | null = null): boolean {
    const el = document.querySelector('bgzv-frontend-main') as HTMLElement;

    if (!el) {
      return false;
    }

    const content = el.querySelector('#bgzv-frontend-main') as HTMLElement;

    if (content?.classList.contains('focus-mode-active')) {
      this.focusModeOff();
    } else {
      content.scrollIntoView({ behavior: 'smooth' });
      this.isFocusMode = true;
      const timerSub = timer(250).subscribe(() => {
        if (this.hasBWSHeader) {
          // regular BWS
          document.getElementsByTagName('bws-header')[0]?.classList.toggle('d-none');
          document.getElementsByClassName('sliding__panel-container')[0]?.classList.toggle('sliding__focus-mode-fix');

          // BWS opened through BAP application
          document.getElementsByTagName('bws-minimal-header')[0]?.classList.toggle('d-none');
        } else {
          // Handling for the rare case that bws-header and bws-minimal-header don't exist
          document.getElementsByClassName('bws-header-container')[0]?.classList.toggle('d-none');
          document.getElementsByTagName('bws-customer-context-bar')[0]?.classList.toggle('d-none');

          // Outdated handling for OKP Portal Header, left in "just in case"
          document.getElementsByTagName('lib-portal-header')[0]?.classList.toggle('d-none');
          document.getElementsByTagName('lib-portal-footer')[0]?.classList.toggle('d-none');
          document.getElementsByTagName('navigation-component')[0]?.classList.toggle('d-none');
        }

        if (content.classList.contains('focus-mode-active') && this.isConsulationModuleContext) {
          // TODO: Check if this correct in all cases!!
          content.classList.remove('focus-mode-active');
        } else if (this.isFocusMode) {
          content.classList.toggle('focus-mode-active');
        }
        timerSub.unsubscribe();
      });
    }

    this.emitFocusModeChange(this.isFocusMode);
    return this.isFocusMode;
  }

  public focusModeOff() {
    const el = document.querySelector('bgzv-frontend-main') as HTMLElement;

    if (!el) {
      return false;
    }

    const content = el.querySelector('#bgzv-frontend-main') as HTMLElement;

    if (this.hasBWSHeader) {
      // regular BWS
      document.getElementsByTagName('bws-header')[0]?.classList.remove('d-none');
      document.getElementsByClassName('sliding__panel-container')[0]?.classList.remove('sliding__focus-mode-fix');

      // BWS opened through BAP application
      document.getElementsByTagName('bws-minimal-header')[0]?.classList.remove('d-none');
    } else {
      // Handling for the rare case that bws-header and bws-minimal-header don't exist
      document.getElementsByClassName('bws-header-container')[0]?.classList.remove('d-none');
      document.getElementsByTagName('bws-customer-context-bar')[0]?.classList.remove('d-none');

      // Outdated handling for OKP Portal Header, left in "just in case"
      document.getElementsByTagName('lib-portal-header')[0]?.classList.remove('d-none');
      document.getElementsByTagName('lib-portal-footer')[0]?.classList.remove('d-none');
      document.getElementsByTagName('navigation-component')[0]?.classList.remove('d-none');
    }
    content?.classList.remove('focus-mode-active');
    content.scrollIntoView();
    this.isFocusMode = false;
    return true;
  }

  public async toggleContextMode(): Promise<void> {
    const numSelectedConsultationTopics = this.topicService.consultationSelectedSubtopicIds().length;
    if (numSelectedConsultationTopics === 0 && !this.clientService.prepDataSent) {
      const data = {
        copyText: 'Bitte Beratungsthemen auswählen.',
        confirmText: 'Schließen',
        context: 'noTopic',
        showClose: false,
      } as DialogConfirmData;

      this._dialogRef = this.dialogService.openDialogConfirm(data);

      this._dialogSub = this._dialogRef.afterClosed().subscribe(result => {
        this._dialogRef = null;
      });
    } else if (
      ((this.clientService.consultationStatus === ConsultationStatusEnum.created ||
        this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation) &&
        !this.clientService.prepDataSent) ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
    ) {
      /*
      ** DEPRECATED code due to prelim deactivation
      ** Left in for potential reactivation
      const copyText =
        this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
          ? 'Hiermit wird die Vorbereitung beendet. Beratung starten?'
          : 'Ohne Vorbereitung fortfahren?';
      */
      const copyText = 'Hiermit wird die Vorbereitung beendet. Beratung starten?';
      const data = {
        copyText: copyText,
        confirmText: ' Ja ',
        denyText: 'Nein',
      } as DialogConfirmData;

      this._dialogRef = this.dialogService.openDialogConfirm(data);
      this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
        if (result?.confirmed === true) {
          await this.setConsultationStatus(ConsultationStatusEnum.mainConsultation);
          this.lastQuestionGroupId.next('-1');
          this.prepToggleMode = 'main';
          if (this.configService.startingPointMainConsultation) {
            this.router.navigate([RoutingPathMain.Consultation]);
          }

          setTimeout(() => {
            if (this.currentMode !== this.prepToggleMode) {
              this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.bankHubId}`]);
            }
          }, 350);
        }
        this._dialogRef = null;
      });
    }
  }

  public async jumpToSubtopic(subtopicId: string): Promise<void> {
    const questionId = this.topicService.getFirstQuestionGroupIdBySubtopicId(subtopicId)?.id || '-1';

    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {
      if (this.currentMode === 'prep' && this.currentMainContext !== RoutingPathPrep.Consultation) {
        return this.router.navigate([RoutingPathPrep.Consultation], {
          state: { questionGroupId: questionId },
        });
      } else if (this.currentMode === 'main' && this.currentMainContext !== RoutingPathMain.Consultation) {
        return this.router.navigate([RoutingPathMain.Consultation], {
          state: { questionGroupId: questionId },
        });
      } else {
        return this.actionService.setAction({
          target: 'consultation',
          action: 'jump',
          options: { questionGroupId: questionId },
        });
      }
    });
  }

  public emitFocusModeChange(value: boolean) {
    this.focusModeChange.next(value);
  }

  public measureStartupTime(state: 'start' | 'stop') {
    if (state === 'start') {
      this.dStart = new Date().getTime();
    } else if (this.dStart && state === 'stop') {
      this.dEnd = new Date().getTime();
      console.log(`%c [bgzv-frontend-main] - startup took [${this.dEnd - this.dStart}]ms`, 'color: #db91db');
      this.dStart = null;
      this.dEnd = null;
    }
  }

  private recursivelyClearOutlet(root: UrlSegmentGroup, outletName: string): UrlSegmentGroup {
    const newChildren = {};
    for (const [childOutlet, child] of Object.entries(root.children)) {
      if (childOutlet !== outletName) {
        const newChild = this.recursivelyClearOutlet(child, outletName);
        newChildren[childOutlet] = newChild;
      }
    }
    return new UrlSegmentGroup(root.segments, newChildren);
  }

  private async setConsultationStatus(status: ConsultationStatusEnum): Promise<void> {
    await lastValueFrom(
      this.queryService.putConsultationStatus(this.clientService.consultationId, { consultationStatus: status })
    );
    this.clientService.consultationStatus = status;
  }

  get skipSelection() {
    return this._skipSelection;
  }
  set skipSelection(value: boolean) {
    this._skipSelection = value;
  }

  get currentMainContext() {
    if (!this.activeRoute.children?.length) {
      return null;
    }

    const routeObject = this.activeRoute.children.find(x => x.outlet === 'primary').snapshot;

    if (routeObject) {
      return `${routeObject.routeConfig.path}`;
    } else {
      return null;
    }
  }

  get currentOverlayContext() {
    const routeObject = this.activeRoute.children.find(x => x.outlet === 'overlay');

    if (routeObject) {
      return routeObject.snapshot.routeConfig.path;
    } else {
      return null;
    }
  }

  get currentMode(): 'prep' | 'main' {
    return this.currentUrl?.includes('prep/') ? 'prep' : 'main';
  }

  get previousContext() {
    return this.previousUrl;
  }

  get currentContext() {
    return this.currentUrl;
  }

  get hasPortalHeader(): boolean {
    if (window.location.pathname?.includes('/bws')) {
      return document.getElementsByClassName('bws-header-container').length > 0;
    } else {
      return document.getElementsByTagName('lib-portal-header').length > 0;
    }
  }

  get kbmFooterHeight(): number {
    const kbmFooter = document.querySelector('.footer-content');
    return kbmFooter?.clientHeight || 0;
  }

  get hasBWSHeader(): boolean {
    return window.location.pathname?.includes('/bws')
      ? document.getElementsByTagName('bws-header').length > 0 ||
          document.getElementsByTagName('bws-minimal-header').length > 0
      : false;
  }

  get showStickyButton(): boolean {
    return this.hasPortalHeader;
  }

  get isIPad(): boolean {
    return !!(
      navigator.userAgent.match(/(iPad)/) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  }

  get assetPath(): string {
    const a = this._assetPath || '';
    return a ? `${a}/assets` : `/assets`;
  }
  set assetPath(value: string) {
    this._assetPath = value;
  }

  public get prepToggleMode(): string {
    return this._prepToggleMode;
  }
  public set prepToggleMode(value: string) {
    this._prepToggleMode = value;
  }

  public get consultationStatusChanged(): boolean {
    return this._consultationStatusChanged;
  }
  public set consultationStatusChanged(value: boolean) {
    this._consultationStatusChanged = value;
  }

  public get customerMessage(): string {
    return this._customerMessage.value;
  }
  public set customerMessage(customerMessage: string) {
    this._customerMessage.next(customerMessage);
  }

  unsubscribe() {
    this._routerSub?.unsubscribe();
  }
}
