<section class="border mt-4">
  @if (data?.length > 0) {
    <!-- outer panel -->
    <mat-expansion-panel
      #contentPanel
      class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      collapsedHeight="*"
      expandedHeight="*"
      [hideToggle]="false"
      [class.is--closed]="!panelOpenState"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="text-nowrap" [attr.data-testcafe]="'screenResult-panel-header'">
          <strong>{{ title[type] }}</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        @for (item of data; track item.name; let index = $index) {
          <mat-expansion-panel
            class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
            [expanded]="activeIndex === index"
          >
            <mat-expansion-panel-header class="align-items-center py-3">
              <div class="d-flex flex-column w-100">
                <mat-panel-title
                  class="row justify-content-between mt-2"
                  [attr.data-testcafe]="'screenResult-panel-' + getTestcafeId(item.name)"
                >
                  <div class="col-sm-8 order-2 order-sm-1 mb-2">
                    <strong>{{ item.itemReferenceName || item.name }}</strong>
                  </div>
                  <!-- email sent -->
                  @if (item.mailSent) {
                    <div class="col-sm-4 d-flex justify-content-sm-end order-1 order-sm-2">
                      <div class="tag-info tag-positive mr-3 mb-2">
                        <div class="tag-info-text">E-Mail versendet</div>
                      </div>
                    </div>
                  }
                </mat-panel-title>

                <!-- appointment -->
                <div class="row align-items-center">
                  <div class="col-sm-6 mt-3">
                    <small>Fälligkeit am:</small>
                    @if (item.appointmentDate) {
                      <p class="d-flex align-items-center">
                        <kf-icon name="ic_kalender_termin_24"></kf-icon>
                        <span
                          class="pl-2"
                          [attr.data-testcafe]="'screenResult-panel-themaAppointmentDate-' + getTestcafeId(item.name)"
                        >
                          {{ item.appointmentDate | date: 'dd. MMMM YYYY' }}
                        </span>
                      </p>
                    } @else {
                      <span>Keine Fälligkeit</span>
                    }
                  </div>
                  <div class="col-sm-6 mt-3">
                    <small class="">Verantwortlich:</small>
                    <p class="d-flex align-items-center">
                      <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
                      <span class="pl-2" [attr.data-testcafe]="'screenResult-div-costumerContact'">
                        {{ getName(item.customerContact) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>

            @if (item.individualNote || item.dataFieldGroups?.length > 0) {
              @if (item.individualNote) {
                <div class="col-12" [attr.data-testcafe]="'screenResult-div-themaNote'">
                  <h5 class="font-weight-bold mb-1">{{ notes[type] }}</h5>
                  {{ item.individualNote }}
                </div>

                @if (item.dataFieldGroups?.length > 0) {
                  @for (dataFieldGroup of item.dataFieldGroups; track dataFieldGroup.name) {
                    <div class="col-12">
                      <p class="font-weight-bold mb-2">{{ dataFieldGroup.name }}</p>
                      @for (dataField of dataFieldGroup.dataFields; track dataField) {
                        <dl class="mb-0 w-100">
                          <dt class="font-weight-normal mb-0 w-25 dataField-term">{{ dataField.name }}</dt>
                          <dd class="pl-2 mb-0 w-75 dataField-value">{{ dataField.value ?? '-' }}</dd>
                        </dl>
                      }
                    </div>
                  }
                }
              }
            }
          </mat-expansion-panel>
        }
      </div>
    </mat-expansion-panel>
  } @else {
    <div class="pl-4 pr-3 pt-3">
      <strong>{{ title[type] }}</strong>
      <p class="pl-3">{{ noItems[type] }}</p>
    </div>
  }
</section>
