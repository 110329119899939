import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { StorageService } from '@services/storage-service/storage.service';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';

const poolData = {
  UserPoolId: environment.cognitoUserPoolId,
  ClientId: environment.cognitoClientId,
};

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public currentAuthState = new BehaviorSubject<any>(null);
  public currentErrorState = new BehaviorSubject<any>(null);

  public headers = new HttpHeaders();

  private _authState: string;
  private _jwtToken: string;
  private _userObject: CognitoUser;

  public userPool = new CognitoUserPool(poolData);
  public keyPrefix = `CognitoIdentityServiceProvider.${this.userPool.getClientId()}`;

  constructor(
    private clientService: ClientService,
    private storageService: StorageService
  ) {}

  ngOnDestroy(): void {}

  login(email: string, password: string, newPassword?: string): Promise<any> {
    const self = this;

    const authenticationData = {
      Username: email.toLowerCase(),
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email.toLowerCase(),
      Pool: this.userPool,
    };

    this.userObject = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      self.userObject.authenticateUser(authenticationDetails, {
        onSuccess: function (session) {
          self.userObject.setSignInUserSession(session);

          self.jwtToken = session.getIdToken().getJwtToken();
          self.clientService.bankExternalId = session.getIdToken().decodePayload().sub;
          self.clientService.bankConsultEmail = session.getIdToken().decodePayload().email;

          self.authState = 'signedIn';
          self.currentAuthState.next(self.authState);
          resolve(session);
        },
        onFailure: function (err) {
          self.currentErrorState.next(err);
          if (err.code === 'PasswordResetRequiredException') {
            self.authState = 'requireResetPassword';
            self.currentAuthState.next(self.authState);
          }
          reject(err);
        },
        newPasswordRequired(userAttributes, requiredAttributes) {
          if (newPassword) {
            self.userObject.completeNewPasswordChallenge(newPassword, {}, this);
          } else {
            self.authState = 'requireNewPassword';
            self.currentAuthState.next(self.authState);
            reject({ authState: 'requireNewPassword' });
          }
        },
      });
    });
  }

  resetPassword(email: string, code: string, password: string): Promise<any> {
    const self = this;

    const userData = {
      Username: email.toLowerCase(),
      Pool: this.userPool,
    };

    this.userObject = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      this.userObject.confirmPassword(code, password, {
        onSuccess: function () {
          self.currentAuthState.next('defaultLogin');
          resolve(null);
        },
        onFailure: function (err) {
          self.currentErrorState.next(err);
          reject(err);
        },
      });
    });
  }

  logout() {
    // remove the entries from localStorage to be sure
    this.storageService.purge();
    this.userObject?.signOut();
    this.jwtToken = '';
    this.authState = 'signedOut';
    this.currentAuthState.next(this.authState);
  }

  getSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = this.userPool?.getCurrentUser();
      if (!user) {
        this.authState = 'signedOut';
        this.currentAuthState.next(this.authState);
        resolve(null);
      }

      user.getSession((err, session) => {
        if (session) {
          this.jwtToken = session.getIdToken().getJwtToken();
          this.clientService.bankExternalId = session.getIdToken().decodePayload().sub;
          this.clientService.bankConsultEmail = session.getIdToken().decodePayload().email;

          this.authState = 'signedIn';
          this.currentAuthState.next(this.authState);
          resolve(session);
        } else {
          this.authState = 'signedOut';
          this.currentAuthState.next(this.authState);
          resolve(null);
        }
      });
    });
  }

  public get authState(): string {
    return this._authState;
  }
  public set authState(value: string) {
    this._authState = value;
  }

  public get jwtToken(): string {
    return this._jwtToken;
  }
  public set jwtToken(value: string) {
    this._jwtToken = value;
  }

  public get userObject(): CognitoUser {
    return this._userObject;
  }
  public set userObject(value: CognitoUser) {
    this._userObject = value;
  }
}
