import { Injectable } from '@angular/core';
import { fileAccept } from '@components/documents-upload/documents-upload.component';
import { EnvironmentLookupService } from '@de.fiduciagad.kundenportal/environment-lookup';
import { ConsultationDocumentsResponse, MediaItem } from '@domain/app/media.domain';
import { MediaTypeEnum } from '@enums';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { MediaService } from '@services/media-service/media.service';
import { IpadPdfViewerService } from '@services/pdf-viewer-service/ipad-pdf-viewer-service';
import { QueryService } from '@services/query-service/query.service';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  public currentDocuments = new BehaviorSubject<ConsultationDocumentsResponse[]>([]);
  public currentDocument = new BehaviorSubject<ConsultationDocumentsResponse | undefined>(undefined);
  public currentDocumentFile = new BehaviorSubject<Blob | undefined>(undefined);
  public uploadPending = new BehaviorSubject<boolean>(false);
  public errorOccured = new BehaviorSubject<boolean>(false);
  public fetchingDocumentsPending = new BehaviorSubject<boolean>(false);
  public fetchingDocumentPending = new BehaviorSubject<boolean>(false);
  public errorMessage = new BehaviorSubject<string>('');
  public hasDocuments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public latestFileGshId: number | undefined = undefined;
  public readonly fileSizeLimit: number = 5000000;

  private latestFileName: string = '';
  private notAllowed = [];

  dialogResponse: any;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService,
    private ipadViewerService: IpadPdfViewerService,
    private environmentLookupService: EnvironmentLookupService,
    private mediaService: MediaService
  ) {
    this.currentDocument.subscribe(newDocument =>
      newDocument ? this.setCurrentFile(newDocument) : this.currentDocumentFile.next(undefined)
    );
  }

  private setCurrentFile(newDocument: ConsultationDocumentsResponse): void {
    this.fetchingDocumentPending.next(true);
    this.queryService
      .getBankBankHubDocument(newDocument.gshId, this.clientService.customerId)
      .pipe(
        finalize(() => {
          this.fetchingDocumentPending.next(false);
        })
      )
      .subscribe(data => {
        const blob = new Blob([data], {
          type: this.getFileType(newDocument.filename),
        });
        this.currentDocumentFile.next(blob);
      });
  }

  private getFileType(fileName: string) {
    if (fileName.toLowerCase().includes('.pdf')) {
      return 'application/pdf';
    } else {
      const fileNameSplit = fileName.split('.');
      const typeSuffix = fileNameSplit[fileNameSplit.length - 1];

      return `image/${typeSuffix}`;
    }
  }

  private getNextDocument(document: ConsultationDocumentsResponse): number | undefined {
    const currentDocuments = this.currentDocuments.getValue();
    if (currentDocuments.length === 1) {
      return undefined;
    }
    const index = currentDocuments.indexOf(document);
    if (index + 1 < currentDocuments.length) {
      return currentDocuments[index + 1].gshId;
    } else if (index + 1 > currentDocuments.length - 1) {
      return currentDocuments[index - 1].gshId;
    } else {
      return undefined;
    }
  }

  public uploadDocuments(files: FileList) {
    this.uploadPending.next(true);
    this.notAllowed = this.notAllowed.splice(0, this.notAllowed.length);

    // Create Upload Requests
    const fileArray = Array.from(files);

    // check allowedTypes
    const allowedTypes = fileAccept.split(',').map(value => {
      return `${value === '.pdf' ? 'application' : 'image'}/${value.substring(1)}`;
    });

    for (let i = 0; i < fileArray.length; i++) {
      if (!allowedTypes.includes(fileArray[i].type)) {
        this.notAllowed.push(fileArray[i].name);
        fileArray.splice(i, 1);
      }
    }

    if (this.notAllowed.length > 0) {
      this.uploadPending.next(true);
      this.errorOccured.next(true);
    }

    // check allowed file size
    const tooBigFileExists = fileArray.find(x => x.size > this.fileSizeLimit);
    if (tooBigFileExists) {
      fileArray.splice(fileArray.indexOf(tooBigFileExists), 1);

      this.uploadPending.next(true);
      this.errorOccured.next(true);
    }

    if (fileArray.length > 0) {
      this.latestFileName = fileArray[fileArray.length - 1].name;
      environment.platform === 'aws' ? this.uploadAws(fileArray) : this.uploadVp(fileArray);

      this.errorMessage.next('');
      this.uploadPending.next(false);
      this.errorOccured.next(false);
    } else {
      this.errorMessage.next('');
      this.uploadPending.next(false);
      this.errorOccured.next(false);
    }
  }

  public fetchDocumentList(setLatestDocument = false): void {
    this.fetchingDocumentsPending.next(true);
    this.queryService
      .getConsultationDocuments(this.clientService.consultationId)
      .pipe(
        finalize(() => {
          this.fetchingDocumentsPending.next(false);
        })
      )
      .subscribe(currentDocuments => {
        this.currentDocuments.next(this.sortDocumentList(currentDocuments));
        if (setLatestDocument) {
          const nextDocument = currentDocuments.find(document => document?.gshId === this.latestFileGshId);
          this.currentDocument.next(nextDocument);
          this.latestFileGshId = undefined;
        }
        if (!isEmpty(currentDocuments)) {
          this.hasDocuments.next(true);
        } else {
          this.hasDocuments.next(false);
        }
      });
  }

  public deselectDocument(): void {
    const currentDocument = this.currentDocument.getValue();
    this.queryService
      .deleteSelectDocumentsForConsultation(this.clientService.consultationId, currentDocument.gshId)
      .pipe(
        tap(() => {
          this.fetchingDocumentsPending.next(false);
          this.queryService
            .deleteSelectDocumentsForCustomer(this.clientService.customerId, currentDocument.gshId)
            .subscribe();
        })
      )
      .subscribe(() => {
        this.latestFileGshId = this.getNextDocument(currentDocument);
        this.fetchDocumentList(true);
      });
  }

  public setNextDocument(direction: 'next' | 'previous') {
    const currentDocument = this.currentDocument.getValue();
    const currentDocuments = this.currentDocuments.getValue();
    let nextIndex = currentDocuments.indexOf(currentDocument);
    direction === 'next' ? nextIndex++ : nextIndex--;
    this.currentDocument.next(currentDocuments[nextIndex]);
  }

  public async showDocument(document: MediaItem): Promise<void> {
    const isIPad = this.environmentLookupService?.isInsideNativeShell();

    if (
      document.type !== MediaTypeEnum.image &&
      document.type !== MediaTypeEnum.link &&
      !document.url.includes('https')
    ) {
      document.url = await this.mediaService.getMediaContent(document.url, true);
    }

    if (environment.platform === 'vp' && isIPad && document.type === MediaTypeEnum.pdf) {
      this.ipadViewerService.showIpadPdfFromUrl(document);
    } else {
      window.open(document.url, '_blank');
    }
  }

  private sortDocumentList(documents) {
    return documents.sort((a, b) => (a.filename.toLowerCase() < b.filename.toLowerCase() ? -1 : 1));
  }

  private uploadAws(fileArray: File[]) {
    const fileRequests = fileArray.map(file =>
      this.queryService.postUploadDocument(this.clientService.customerId, file)
    );
    this.selectDocuments(fileRequests);
  }

  private uploadVp(fileArray: File[]) {
    const ticketRequests = fileArray.map(() => this.queryService.getUNDTicket());

    forkJoin(ticketRequests)
      .pipe(
        map(ticketResponses => {
          return ticketResponses.map((ticket, index) => {
            const formData = new FormData();
            formData.append('file', fileArray[index]);
            formData.append('ticket', new Blob([JSON.stringify(ticket)], { type: 'application/json' }));
            return this.queryService.postUNDUploadFile(formData);
          });
        })
      )
      .subscribe(uploadRequests => {
        forkJoin(uploadRequests)
          .pipe(
            map(uploadResponses =>
              uploadResponses.map(uploadResponse =>
                this.queryService.postUNDDataToBackend(uploadResponse, this.clientService.customerId)
              )
            )
          )
          .subscribe(dataToBackendRequests => {
            this.selectDocuments(dataToBackendRequests);
          });
      });
  }

  private selectDocuments(selectRequest: Observable<any>[]) {
    forkJoin(selectRequest)
      .pipe(
        map(fileRequests => {
          const selectRequests = fileRequests.map(fileInfo => {
            // New selection Item for
            const gshInfo = {
              id: fileInfo.id,
              filename: fileInfo.filename,
              gshId: fileInfo.internalId,
              type: fileInfo.type,
            } as ConsultationDocumentsResponse;
            return this.queryService.postSelectDocumentForConsultation(this.clientService.consultationId, gshInfo);
          });

          this.latestFileGshId = fileRequests.find(x => x.filename === this.latestFileName)?.internalId;

          forkJoin(selectRequests)
            .pipe(
              finalize(() => {
                this.uploadPending.next(false);
                this.errorMessage.next('');
              })
            )
            .subscribe(x => {
              this.fetchDocumentList(true);
            });
        })
      )
      .subscribe();
  }
}
