import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  importIsOngoing = new BehaviorSubject<boolean>(false);
  refreshRequest = new ReplaySubject<void>();
  importHasError = new BehaviorSubject<boolean>(false);

  constructor() {}

  startImport() {
    this.importIsOngoing.next(true);
  }
  finishImport() {
    this.importIsOngoing.next(false);
  }

  requestRefresh() {
    this.refreshRequest.next();
  }

  setImportError() {
    this.importHasError.next(true);
  }

  resetImportError() {
    this.importHasError.next(false);
  }
}
