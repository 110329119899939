<table [attr.data-testcafe]="'profileDataField-table-' + dataFieldGroup.groupName">
  <tr>
    <th *ngFor="let heading of headerRow">{{ heading }}</th>
  </tr>
  <tr *ngFor="let i of dataRowsIndexes; let idx = index">
    <ng-container *ngFor="let dataField of dataFieldGroup.dataFields">
      <td
        *ngIf="dataField.type === dataFieldTypeEnum.price"
        [attr.data-testcafe]="
          'profileDataField-table-' +
          dataField.name +
          '-' +
          dataFieldGroup.groupName +
          '-' +
          getValueMethod(dataField, idx) +
          '-' +
          [i]
        "
        markNegativePrice
      >
        {{ getValueMethod(dataField, idx) | thousandsDivider }}
      </td>
      <td
        *ngIf="dataField.type !== dataFieldTypeEnum.price"
        [attr.data-testcafe]="
          'profileDataField-table-' +
          dataField.name +
          '-' +
          dataFieldGroup.groupName +
          '-' +
          getValueMethod(dataField, idx) +
          '-' +
          [i]
        "
        markNegativePrice
      >
        {{ getValueMethod(dataField, idx) }}
      </td>
    </ng-container>
  </tr>
</table>
