import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KfThemeModule } from '@app/kf-theme_module';
import { ContextService } from '@services/context-service/context.service';

@Component({
  selector: 'item-counter',
  templateUrl: './item-counter.component.html',
  styleUrl: './item-counter.component.scss',
  imports: [KfThemeModule],
})
export class ItemCounterComponent implements OnInit {
  @Input() id: string;
  @Input() value: number = 0;
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 100;
  @Input() showTooltip: boolean = false;
  @Input() testcafeData: string;

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  public strValue: string;
  private numValue: number;

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {
    this.numValue = this.value;
    this.setValue(this.value);
  }

  /**
   * increase or decrease the value
   * @param dir - direction to increase or decrease the value
   */
  public setStep(dir: 1 | -1): void {
    const value = this.numValue + dir;
    this.setValue(value);
  }

  /**
   * check if the value is in given range and set the new value
   * if old value does not equal new value emit the new value
   * @param value - new value to set
   */
  public setValue(value: number): void {
    value = this.maxValue !== undefined ? Math.min(value, this.maxValue) : value;
    value = this.minValue !== undefined ? Math.max(value, this.minValue) : value;
    this.strValue = value.toString();
    if (value !== this.numValue) {
      this.numValue = value;
      this.valueChange.emit(value);
    }
  }

  /** generate id for testcafe
   * @param item - item name
   * @returns testcafe id
   */
  public testcafeId(item: string): string {
    return `item-counter-${item}-${this.testcafeData}`;
  }

  get disabledMin(): boolean {
    return this.numValue <= this.minValue;
  }

  get disabledMax(): boolean {
    return this.numValue >= this.maxValue;
  }

  /** display minus- or delete-icon depending on value */
  get iconNameMinus(): string {
    return this.numValue > 1 ? 'ic_minuszeichen_24' : 'ic_loeschen_24';
  }

  get tooltipMinus(): string {
    if (this.disabledMin) {
      return '';
    }
    if (this.numValue === 1) {
      return 'löschen';
    }
    return (this.label || 'Anzahl') + ' verringern';
  }

  get tooltipPlus(): string {
    if (this.disabledMax) return '';
    return (this.label || 'Anzahl') + ' erhöhen';
  }

  get isIPad(): boolean {
    return this.contextService.isIPad;
  }
}
