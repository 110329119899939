<div class="kf-popup" #dialogContainer>
  <div class="kf-popup-header">
    <button
      class="kf-popup-close"
      mat-icon-button
      matDialogClose
      aria-label="Dialog schließen"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover_focus"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>
  </div>
  <div class="kf-popup-container">
    <div *ngIf="showCustomerAnswers">
      <ng-template *ngTemplateOutlet="ans; context: { label: 'Kunde', answers: customerAnswers }"></ng-template>
    </div>

    <div *ngIf="showConsultantAnswers" [class.mt-2]="showConsultantMargin">
      <ng-template
        *ngTemplateOutlet="ans; context: { label: 'Berater-Vorbereitung', answers: consultantAnswers }"
      ></ng-template>
    </div>

    <div *ngIf="showHistoryAnswers" [class.mt-2]="showHistoryMargin">
      <ng-template *ngTemplateOutlet="ans; context: { label: 'Vorherige Antwort', answers: historyAnswers }">
      </ng-template>
    </div>
  </div>
</div>

<ng-template #ans let-label="label" let-answers="answers">
  <div class="d-flex align-items-center pt-2 pb-3">
    <kf-icon name="ic_person_mit_kreis_24" class="mr-2"></kf-icon>
    <div class="font-weight-bold ml-2 mat-small">
      {{ label }}
    </div>
  </div>
  <div class="d-flex justify-content-between mt-3 mx-4 mb-4">
    <div class="d-flex flex-column">
      <ng-container *ngFor="let answer of answers; let last = last">
        <div *ngIf="showAnswerLabel" [attr.data-testcafe]="'prevAnswer-div-answer-' + answer.label.slice(0, 10)">
          {{ answer.label }}
        </div>
        <div
          *ngIf="answer?.note"
          class="mat-small line-clamp-2 mt-1"
          [attr.data-testcafe]="'prevAnswer-div-answer-' + answer.note.slice(0, 10)"
          [style.maxWidth.rem]="24"
          [class.mb-4]="!last"
        >
          {{ answer.note }}
        </div>
        <div
          *ngIf="answer?.value"
          class="mat-small line-clamp-2 mt-1"
          [attr.data-testcafe]="'prevAnswer-div-answer-' + answer.value.slice(0, 10)"
          [style.maxWidth.rem]="24"
          [class.mb-4]="!last"
        >
          {{ answer.value }}
        </div>
      </ng-container>
    </div>

    <button
      [attr.data-testcafe]="'prevAnswer-button-answerTake'"
      mat-stroked-button
      color="primary"
      (click)="onTakeAnswers(label)"
    >
      <span>Übernehmen</span>
    </button>
  </div>
</ng-template>
