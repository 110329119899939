import { NgModule } from '@angular/core';
import { DebugIdDirective } from './directives/debug-id.directive';
import { HandleENumbersDirective } from './directives/handle-e-numbers.directive';
import { IntersectionObserverDirective } from './directives/intersection.directive';
import { MarkNegativePriceDirective } from './directives/mark-negative-price.directive';
import { PageReferencePipe } from './pipes/page-reference.pipe';
import { PaymentIntervalPipe, TaxCalculationPipe } from './pipes/paymentPipe.pipe';
import { ReplacementPipe } from './pipes/replacement.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { ThousandsDividerPipe } from './pipes/thousandsDivider.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';

@NgModule({
  declarations: [
    // Directives
    DebugIdDirective,
    HandleENumbersDirective,
    IntersectionObserverDirective,
    MarkNegativePriceDirective,
    // Pipes
    PageReferencePipe,
    PaymentIntervalPipe,
    ReplacementPipe,
    SafeHtmlPipe,
    TaxCalculationPipe,
    StripHtmlPipe,
    TruncateTextPipe,
    ThousandsDividerPipe,
  ],
  imports: [],
  exports: [
    // Directives
    DebugIdDirective,
    HandleENumbersDirective,
    IntersectionObserverDirective,
    MarkNegativePriceDirective,
    // Pipes
    PageReferencePipe,
    PaymentIntervalPipe,
    ReplacementPipe,
    SafeHtmlPipe,
    TaxCalculationPipe,
    StripHtmlPipe,
    TruncateTextPipe,
    ThousandsDividerPipe,
  ],
  providers: [
    DebugIdDirective,
    IntersectionObserverDirective,
    PageReferencePipe,
    PaymentIntervalPipe,
    ReplacementPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    TruncateTextPipe,
    ThousandsDividerPipe,
  ],
})
export class BgzvUtilsModule {}
