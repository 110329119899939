@if (!inNotesOverlay) {
  <!-- when not inside the notes overlay, we can place the focus trap directive -->
  <div
    class="d-flex flex-column w-100 h-100 overlay"
    [class.drawing-overlay]="isDrawingNote && !isCustomNote"
    [class.text-overlay]="isTextNote"
    (window:resize)="onResize($event)"
  >
    <ng-template *ngTemplateOutlet="contents"></ng-template>
    <ng-template *ngTemplateOutlet="singleCloseButton"></ng-template>
  </div>
} @else {
  <!-- when inside the notes overlay, the focus trap directive cannot be placed -->
  <div
    class="d-flex flex-column w-100 h-100 overlay"
    [class.drawing-overlay]="isDrawingNote && !isCustomNote"
    [class.text-overlay]="isTextNote"
    (window:resize)="onResize($event)"
  >
    <ng-template *ngTemplateOutlet="contents"></ng-template>
    <ng-template *ngTemplateOutlet="singleCloseButton"></ng-template>
  </div>
}

<!-- BUTTON to allow editing a drawing -->
<button
  type="button"
  class="position-absolute edit-drawing-button"
  [class.d-none]="!screenshot || isCustomNote || isTextNote || (!this.isCustomNote && !this.inNotesOverlay)"
  (click)="
    pageReference === NoteContextEnum.topicSelection
      ? jumpToTopicOverview(type)
      : questionGroupId && jumpToQuestion(questionGroupId)
  "
  aria-label="Screenshot im Kontext überarbeiten"
  kfTooltip="Screenshot im Kontext überarbeiten"
  kfTooltipPosition="below"
  kfTooltipShow="hover"
>
  <img #screenshotCanvas alt="" [src]="screenshot" class="w-100 screenshotCanvas" />
  <span class="sr-only">Zeichnung bearbeiten</span>
</button>

<ng-template #contents>
  @if (!isCustomNote && isTextNote) {
    <ng-template *ngTemplateOutlet="noteTitle"></ng-template>
  }

  @if (isCustomNote && inNotesOverlay && (isTextNote || isDrawingNote)) {
    <ng-template *ngTemplateOutlet="noteTitleEditable"></ng-template>
  }

  <!-- DRAW CURSOR & CANVAS -->
  <div
    #customCursor
    id="bgzv-custom-cursor"
    [class.d-none]="!showCustomCursor || isTextNote || (!isCustomNote && inNotesOverlay)"
    class="position-absolute cursor-dot custom-cursor-props pen-size-s"
    style="top: 0; left: 0"
  ></div>

  <div [class.d-none]="presetNoteType === null" class="w-100 h-100 editor-area">
    <canvas
      #canvas
      [class.d-none]="isTextNote || drawingInfo === undefined || (!isCustomNote && inNotesOverlay)"
      (pointerenter)="getInputType($event)"
      (touchstart)="drawingInfo && touchStart($event)"
      (touchmove)="drawingInfo && touchMove($event)"
      (touchend)="drawingInfo && touchEnd($event)"
      (touchcancel)="drawingInfo && touchCancel($event)"
      (mouseup)="drawingInfo && mouseUp($event)"
      (mousedown)="drawingInfo && mouseDown($event)"
      (mousemove)="drawingInfo && mouseMove($event)"
      (mouseleave)="drawingInfo && mouseLeave($event)"
      (mousewheel)="drawingInfo && wheel($event)"
      (wheel)="drawingInfo && wheel($event)"
      [class.cursor-none]="!showCursor"
      cdkFocusInitial
    >
    </canvas>

    <div
      class="text-editor-container"
      [class.text-editor-container--sidebar]="inNotesOverlay && !sidebarCollapsed"
      [class.text-editor-container--sidebar-collapsed]="inNotesOverlay && sidebarCollapsed"
      [class.d-none]="isDrawingNote || !isTextNote"
    >
      <ckeditor
        matInput
        (input)="saved = false"
        [editor]="Editor"
        [config]="EditorConfig"
        [ngModel]="text"
        (ngModelChange)="text = $event ? $event : null"
        data="text"
        #ckeditor
        (change)="onTextEditorChange($event)"
      ></ckeditor>
      <div class="text-right mat-small text-editor-counter" [style.color]="textCounterColor">
        @if (showTextLengthError) {
          <span class="text-length-error">
            <kf-icon name="ic_warnschild_24" class="mr-2"></kf-icon>Ihr Text überschreitet die verfügbare
            Zeichenanzahl!</span
          >
        } @else {
          <span>Noch {{ maxLength - textLength }} Zeichen verfügbar.</span>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template *ngTemplateOutlet="loader"></ng-template>

<ng-template #noteTitle>
  <div
    class="d-flex w-100 align-items-start text-editor-header"
    [class.text-editor-header--sidebar-collapsed]="inNotesOverlay && sidebarCollapsed"
    [class.text-editor-header--sidebar]="inNotesOverlay && !sidebarCollapsed"
    [attr.data-html2canvas-ignore]="true"
  >
    <div class="d-flex align-items-center w-100 note-title">
      <span class="note-title_name w-100">{{ displayName }}</span>
    </div>
  </div>
</ng-template>

<ng-template #noteTitleEditable>
  <div
    class="d-flex w-100 align-items-start text-editor-header"
    [class.text-editor-header--sidebar-collapsed]="inNotesOverlay && sidebarCollapsed"
    [class.text-editor-header--sidebar]="inNotesOverlay && !sidebarCollapsed"
    [attr.data-html2canvas-ignore]="true"
  >
    <div class="d-flex align-items-center w-100 note-title">
      <div class="d-flex w-100 position-relative">
        <mat-form-field class="notranslate">
          <mat-label>Titel</mat-label>
          <input
            #input
            matInput
            id="note-title__input"
            type="text"
            placeholder="Titel für diese Notiz"
            autocomplete="off"
            [value]="displayName"
            [maxLength]="100"
            [attr.data-testcafe]="'overlayNotes-input-title'"
            (input)="saved = false"
            (keyup)="setDisplayName($event)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #singleCloseButton>
  <button
    [attr.data-html2canvas-ignore]="true"
    [attr.data-testcafe]="'overlayNotes-button-NotesClose'"
    class="kf-popup-close"
    mat-icon-button
    (click)="close()"
    aria-label="Dialog schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</ng-template>

<ng-template #loader>
  @if (isScreenshotting) {
    <div class="position-absolute d-flex flex-center loader" [attr.data-html2canvas-ignore]="true">
      <mat-progress-spinner color="primary" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
    </div>
  }
</ng-template>
