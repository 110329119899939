import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigService } from '@services/config-service/config.service';

@Directive({
  selector: '[debugId]',
  standalone: false,
})
export class DebugIdDirective implements OnInit, OnChanges {
  @Input('debugId') id: number | string;

  private regexp = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);

  constructor(
    private el: ElementRef,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    if (this.configService.isDebugMode && !!this.id) {
      if (this.regexp.test(this.id.toString())) {
        const truncated = this.id.toString().split('-')[4].slice(-6);
        this.el.nativeElement.innerHTML = `[${truncated}] ${this.el.nativeElement.innerHTML}` || `[${truncated}]`;
      } else {
        this.el.nativeElement.innerHTML = `[${this.id}] ${this.el.nativeElement.innerHTML}` || `[${this.id}]`;
      }
    }
  }

  ngOnChanges(event: SimpleChanges) {
    if (this.configService.isDebugMode && this.id) {
      if (event.id.previousValue !== undefined) {
        this.id = event.id.currentValue;
        const content = this.el.nativeElement.innerHTML;

        if (this.regexp.test(this.id.toString())) {
          const truncated = this.id.toString().split('-')[4].slice(-6);
          if (!!content.match(this.regexp)) {
            this.el.nativeElement.innerHTML = `[${truncated}]`;
          } else {
            this.el.nativeElement.innerHTML = `[${truncated}] ${content}` || `[${truncated}]`;
          }
        } else if (content.match(/\[[0-9]+\]/) !== null) {
          this.el.nativeElement.innerHTML = content.replace(/\[([0-9]+)\]/, `[${this.id}]`);
        } else {
          this.el.nativeElement.innerHTML = `[${this.id}] ${content}` || `[${this.id}]`;
        }
      }
    }
  }
}
