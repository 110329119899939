import { Component, Input } from '@angular/core';
import { Profile } from '@domain/app/profile';
import { DataFieldTypeEnum } from '@enums';
import { getValue } from '@marginals/side-profile/side-profile.component';
import ProfileDataFieldGroupList = Profile.ProfileDataFieldGroupList;

@Component({
  selector: 'profile-data-field-group-list',
  templateUrl: './profile-data-field-group-list.component.html',
  styleUrls: ['./profile-data-field-group-list.component.scss'],
  standalone: false,
})
export class ProfileDataFieldGroupListComponent {
  @Input() dataFieldGroup: ProfileDataFieldGroupList;

  public getValueMethod = getValue;
  public dataFieldTypeEnum = DataFieldTypeEnum;
}
