<aside class="side-chat">
  <!-- You should not see this -->
  <div class="mt-5 mx-4 mb-3">Diese Funktion steht derzeit nicht zur Verfügung.</div>
  <!--
  --- DEPRECATED code due to prelim deactivation
  --- Left in for potential reactivation
  @if (customerData) {
    <section class="d-flex w-100 align-items-center mb-4">
      <div class="flex-grow-1">
        <h2>
          Ihr Kunde: <strong>{{ customerData.corporationName }}</strong>
        </h2>
      </div>
    </section>
  }
  @if (!loading) {
    @if (!loadingMessages) {
      @if (currentMessages.length !== 0) {
        <div #chatFrame (scroll)="onChatScroll()" class="d-flex flex-column overflow-auto chat-frame" tabindex="-1">
          <div class="d-flex justify-content-center mt-2" [style.height]="'1.5rem'">
            @if (moreToShow) {
              <button
                [disabled]="loading"
                [attr.data-testcafe]="'sideChat-button-moreToShow'"
                mat-button
                color="primary"
                (click)="onMoreClick()"
              >
                <span>Vorherige Nachrichten</span>
                <kf-icon name="ic_pfeil_oben_24"></kf-icon>
              </button>
            }
          </div>
          <div class="d-flex flex-grow-1"></div>
          @for (message of currentMessages; track message) {
            <div class="d-flex mt-4" [class.justify-content-end]="isConsultant(message.sender)">
              <div
                class="bubble"
                [class.consultant]="isConsultant(message.sender)"
                [class.customer]="!isConsultant(message.sender)"
                [attr.data-testcafe]="getTestcafeId(message.createdAt, message.text)"
              >
                <i class="mat-small d-flex time-stamp">
                  {{ timestamp(message.createdAt) }}
                </i>
                {{ message.text }}
              </div>
            </div>
          }
          @if (!isAtBottom) {
            <div class="scroll-button">
              <button
                [attr.data-testcafe]="'sideChat-button-scroll'"
                mat-button
                color="primary"
                (click)="scrollToBottom()"
              >
                <span>Letzte Nachrichten</span>
                <kf-icon name="ic_pfeil_unten_24"></kf-icon>
              </button>
            </div>
          }
        </div>
      } @else {
        <div class="d-flex flex-grow-1 w-100 justify-content-center align-items-center">
          Keine Nachrichten vorhanden
        </div>
      }
    } @else {
      <div class="d-flex w-100 h-75 justify-content-center align-items-center flex-grow-1">
        <mat-spinner></mat-spinner>
      </div>
    }
    <div class="flex-grow-1"></div>
    <mat-form-field>
      <mat-label>Ihre Nachricht</mat-label>
      <textarea
        autofocus
        [ngModel]="message"
        (ngModelChange)="message = $event ? $event : null"
        class="w-100 mt-4 px-3 message-input"
        [attr.data-testcafe]="'sideChat-textarea-messageToCustomer'"
        matInput
      ></textarea>
    </mat-form-field>
    <div class="mt-4 button-row" #ref [class.footer-margin]="useMargin">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideChat-button-answerCustomer'"
          [disabled]="isStringEmptyOrSpaces(message) || messageSending"
          mat-flat-button
          color="primary"
          (click)="sendMessage()"
        >
          <span>Nachricht absenden</span>
          <kf-icon name="ic_senden_24"></kf-icon>
        </button>
        <button
          [attr.data-testcafe]="'sideChat-button-update'"
          [disabled]="loading"
          mat-stroked-button
          color="primary"
          (click)="getMessageData()"
        >
          <span>Aktualisieren</span>
          <kf-icon name="ic_aktualisieren_24"></kf-icon>
        </button>
      </div>
    </div>
  } @else {
    <div class="d-flex w-100 h-75 justify-content-center align-items-center">
      <mat-spinner></mat-spinner>
    </div>
  }
  -->
  <button
    [attr.data-testcafe]="'sideChat-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
    (click)="onClose()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>
