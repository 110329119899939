<div>
  <mat-tab-group (selectedTabChange)="onTabButtonSelected($event.index)" #tabs>
    <mat-tab [label]="'Produkte (' + productCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        @if (productCompositionCount > 0) {
          @for (cartItem of cartData; track cartItem; let last = $last) {
            @if (showProductsInCart(cartItem)) {
              <item-composition-main
                [class.composition-divider]="!last"
                [class.pb-5]="!last"
                intersectionObserver
                [intersectionRoot]="rootDiv"
                [intersectionRootMargin]="'-40% 0px -50%'"
                [intersectionThreshold]="0"
                (visibilityChange)="onVisibilityChanged(cartItem, $event)"
                id="main_{{ cartItem.mainProduct.id }}"
                [compositionMedia]="cartItem.media"
                [mainData]="cartItem.mainProduct || {}"
                [benefitData]="cartItem.benefitsTypes || []"
                [compositionId]="cartItem.id"
                (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
                (showExtraProducts)="showExtraProductDetails($event)"
                (showDataFields)="showDataFields(cartItem.id, $event)"
                (quantitySet)="setProductQuantity(cartItem.id, $event)"
              >
              </item-composition-main>
            }
          }
        } @else {
          <ng-template *ngTemplateOutlet="noItemSummary"></ng-template>
        }
      </div>
    </mat-tab>
    <mat-tab [label]="'Maßnahmen (' + taskCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        @if (taskCompositionCount > 0) {
          @for (cartItem of cartData; track cartItem.id; let index = $index; let first = $first) {
            @if (cartItem.mainProduct && cartItem.tasks && cartItem.tasks.length > 0) {
              <div id="main_{{ cartItem.id }}" [class.pt-4]="!first" class="main-section">
                <ng-template *ngTemplateOutlet="task; context: { data: cartItem }"></ng-template>
              </div>
            }
            @if (!cartItem.mainProduct) {
              <div class="sub-section">
                <ng-template *ngTemplateOutlet="task; context: { data: cartItem }"></ng-template>
              </div>
            }
          }
        } @else {
          <ng-template *ngTemplateOutlet="noItemSummary"></ng-template>
        }

        <ng-template #task let-cartItem="data">
          @for (cartTask of cartItem.tasks; track cartTask.id; let first = $first) {
            <item-composition-task
              [mainData]="cartItem || null"
              [compositionId]="cartItem.id"
              [compositionMedia]="cartItem.media"
              [taskData]="cartTask"
              (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
              (showTask)="showTask(cartItem.id, cartItem.name, $event)"
              (taskAssigned)="assignedTask(cartItem.id, $event)"
              [showMainDetails]="first"
            >
            </item-composition-task>
          }
        </ng-template>

        @if (individualTasks && individualTasks.length > 0) {
          <div id="main_individualTasks" class="individual-section">
            @for (cartTask of individualTasks; track cartTask; let first = $first) {
              <item-composition-task
                [mainData]="null"
                [compositionId]="'-1'"
                [taskData]="cartTask"
                (showDetails)="(null)"
                (showTask)="showTaskIndiv($event.elementId)"
                [isIndividualItem]="true"
                [showIndividualGroupHeading]="first"
              >
              </item-composition-task>
            }
          </div>
        }
      </div>
    </mat-tab>
    <mat-tab [label]="'Expertengespräche (' + transitionCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        @if (transitionCompositionCount > 0) {
          @for (cartItem of cartData; track cartItem; let first = $first) {
            @if (cartItem.mainProduct && cartItem.transitions && cartItem.transitions.length > 0) {
              <div id="main_{{ cartItem.id }}" class="main-section">
                <ng-template *ngTemplateOutlet="transition; context: { data: cartItem }"></ng-template>
              </div>
            }
            @if (!cartItem.mainProduct) {
              <div class="sub-section">
                <ng-template *ngTemplateOutlet="transition; context: { data: cartItem }"></ng-template>
              </div>
            }
          }
        } @else {
          <ng-template *ngTemplateOutlet="noItemSummary"></ng-template>
        }

        <ng-template #transition let-cartItem="data">
          @for (cartTransition of cartItem.transitions; track cartTransition; let first = $first) {
            <item-composition-transition
              [mainData]="cartItem || null"
              [compositionId]="cartItem.id"
              [compositionMedia]="cartItem.media"
              [transitionData]="cartTransition"
              (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
              (showTransition)="showTransition(cartItem.id, cartItem.name, $event)"
              (transitionAssigned)="assignedTransition(cartItem.id, $event)"
              id="main_{{ cartItem.id }}"
              [showMainDetails]="first"
            >
            </item-composition-transition>
          }
        </ng-template>

        @if (individualTransitions?.length) {
          <div id="main_individualTransitions" class="individual-section">
            @for (cartTransition of individualTransitions; track cartTransition; let first = $first) {
              <item-composition-transition
                [mainData]="null"
                [compositionId]="'-1'"
                [transitionData]="cartTransition"
                (showDetails)="(null)"
                (showTransition)="showTransitionIndiv($event.elementId)"
                [class.pt-4]="!first"
                [isIndividualItem]="true"
                [showIndividualGroupHeading]="first"
              >
              </item-composition-transition>
            }
          </div>
        }
      </div>
    </mat-tab>
    <mat-tab [label]="'Themen (' + contentElementCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        @if (contentElementCompositionCount > 0) {
          @for (cartItem of contentElements; track cartItem; let first = $first) {
            <div id="main_{{ cartItem.id }}" [class.pt-4]="!first" class="main-section">
              <item-composition-content-element
                [contentElement]="cartItem"
                (showDetails)="showContentElementDetails($event)"
                (assignContentElement)="showContentElementAssignmentPanel($event)"
                (contentElementChanged)="updateContentElement()"
              >
              </item-composition-content-element>
            </div>
          }
        } @else {
          <ng-template *ngTemplateOutlet="noItemSummary"></ng-template>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noItemSummary>
  @if (
    (currentTab === tab.tasks && individualTasks?.length === 0 && taskCount === 0) ||
    (currentTab === tab.products && productCount === 0) ||
    (currentTab === tab.contentElements && contentElementCount === 0) ||
    (currentTab === tab.transitions && individualTransitions?.length === 0 && transitionCount === 0)
  ) {
    <div class="empty-recommendation-icon mb-4"></div>
    <h2 class="w-50">Für diesen Bereich wurden keine Empfehlungen ausgesprochen.</h2>
  }
</ng-template>

<ng-template #noSummaryData>
  <div class="empty-recommendation-icon mb-4"></div>
  <h2 class="w-50">Für dieses Thema wurden keine Empfehlungen ausgesprochen.</h2>
</ng-template>
