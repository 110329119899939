import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { ClientService } from '@services/client-service/client.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private clientService: ClientService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.platform === 'aws') {
      if (this.authService.authState === 'signedIn' && this.authService.jwtToken && request.url !== '/ping') {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.jwtToken}`,
            'instance-id': String(this.clientService.instanceId),
          },
        });
      }
    }

    return next.handle(request);
  }
}
