import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { ConsultationSummaryThemaItem } from '@domain/app/consultation.domain';
import { getContactName } from '@utils/helpers/formatContact';
import moment from 'moment';

@Component({
  selector: 'result-display',
  templateUrl: './result-display.component.html',
  styleUrl: './result-display.component.scss',
  imports: [MatExpansionModule, KfThemeModule, CommonModule, MaterialModule],
})
export class ResultDisplayComponent implements OnInit {
  @Input() data: ConsultationSummaryThemaItem[];
  @Input() type: 'task' | 'thema' = 'task';
  @ViewChild('contentPanel') contentPanel: MatExpansionPanel;

  public getName = getContactName;
  public panelOpenState: boolean;
  public activeIndex: number = 0;
  public title = {
    task: 'Maßnahmen',
    thema: 'Themenempfehlungen',
  };
  public notes = {
    task: 'Notizen zur Maßnahme',
    thema: 'Notizen zur Themenempfehlung',
  };
  public noItems = {
    task: 'Für diese Beratung wurden keine Maßnahmen beschlossen.',
    thema: 'Für diese Beratung wurden keine weiterführenden Themenempfehlungen ausgewählt.',
  };

  ngOnInit(): void {
    this.data?.sort((a, b) => (moment(a.appointmentDate).isBefore(b.appointmentDate) ? -1 : 1));
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }
}
