<aside class="h-100 bg-white overflow-hidden checkout-container-width">
  <div class="d-flex flex-column w-100 app-height" tabindex="-1">
    <header class="d-flex w-100 justify-content-between align-items-center side-header">
      <div class="flex-grow-1" #header [innerHtml]="getHeader()"></div>
    </header>

    <section class="side-container" *ngIf="dataFieldData">
      <item-datafield-form
        #formItem
        [dataFieldData]="dataFieldData"
        [inCheckout]="true"
        [disableFields]="requestInProgress"
        [productData]="{ id: inputValues.elementId, compositionId: inputValues.compositionId }"
        [dataFieldElementType]="this.inputValues.type"
        (dataFieldAdded)="addDataField($event)"
        (dataFieldRemoved)="removeDataField($event)"
        (valueChanged)="handleDataFormValueChange($event)"
        [hasError]="inputValues.hasError"
      >
      </item-datafield-form>
    </section>

    <footer
      class="w-100 border-top align-items-center side-footer"
      [class.side-footer-margin]="useMargin"
      *ngIf="dataFieldData"
    >
      <div class="h-100 button-row">
        <div class="button-row-content">
          <button
            [attr.data-testcafe]="'sideCheckout-button-completeData'"
            mat-flat-button
            color="primary"
            (click)="onDataSaved()"
          >
            <span>Speichern</span>
          </button>
        </div>
      </div>
    </footer>
  </div>

  <button
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="onClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>
