<article class="position-relative">
  <mat-checkbox
    [indeterminate]="numberOfSelectedSubtopics > 0 && numberOfSelectedSubtopics < numberOfAvailableSubtopics"
    [checked]="numberOfSelectedSubtopics === numberOfAvailableSubtopics"
    class="position-absolute mr-3 select-all-control"
    (change)="onSelectAll(topicItem)"
    [color]="'grey'"
  >
    <ng-container *ngIf="shouldSelectAll(topicItem); else deselectAll">
      <ng-container *ngTemplateOutlet="selectAll"></ng-container>
    </ng-container>
  </mat-checkbox>

  <mat-expansion-panel
    class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
    [expanded]="isTopicExpanded"
    (opened)="onTopicExpanded(topicItem.id, true)"
    (closed)="onTopicExpanded(topicItem.id, false)"
  >
    <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem">
      <div *ngIf="!!topicItem" class="expansion-panel-header-container">
        <div>
          <div *ngIf="!getMediaUrl(topicItem.icon?.id)" class="topic-icon mr-2" aria-hidden="true"></div>
          <bgzv-image
            class="topic-icon mr-2"
            [alt]=""
            [src]="getMediaUrl(topicItem.icon?.id)"
            aria-hidden="true"
          ></bgzv-image>
        </div>
        <strong
          class="m-0 font-weight-bold"
          [attr.data-testcafe]="'titleElement-' + getTestcafeId(topicItem.title) + '-' + topicItem.id"
        >
          {{ topicItem.title }}
        </strong>
        <div
          class="item-topic-header-expand-container"
          [class]="numberOfSelectedSubtopics === 0 ? 'justify-content-end' : 'justify-content-between'"
        >
          <div *ngIf="numberOfSelectedSubtopics > 0" class="tag-info tag-neutral ml-2">
            <div class="tag-info-text">
              {{ numberOfSelectedSubtopics }}<span class="sr-only">Unterthemen gewählt</span>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <ul class="list-unstyled mb-0" *ngIf="topicItem.subtopics">
      <ng-container *ngFor="let subtopic of topicItem.subtopics; let last = last">
        <li>
          <mat-checkbox
            class="mx-2"
            *ngIf="!loading.has(subtopic.id) && !loading.has(topicItem.id)"
            [class.icon-select-grayed]="grayed"
            [attr.data-testcafe]="getTestcafeId(subtopic.title) + '-' + subtopic.id"
            [checked]="subtopic.selected"
            [color]="'grey'"
            (change)="subtopic.selected ? onSubtopicDeselected(subtopic) : onSubtopicSelected(subtopic)"
            ><span class="subtopic-title">{{ subtopic.title }}</span>
          </mat-checkbox>
          <mat-divider *ngIf="!last" class="mt-2"></mat-divider>
        </li>
      </ng-container>
    </ul>
  </mat-expansion-panel>
</article>

<ng-template #deselectAll>
  <span *ngIf="!loading.has(topicItem.id)" class="select-all-button"> Alles abwählen </span>
</ng-template>

<ng-template #selectAll>
  <span *ngIf="!loading.has(topicItem.id)" class="select-all-button"> Alle auswählen </span>
</ng-template>
