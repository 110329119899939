import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import {
  CheckoutCompositionItem,
  CheckoutContentElementItem,
  CheckoutDataFieldGeneralItem,
  CheckoutDataItem,
  CheckoutProductItem,
  CheckoutTaskItem,
  CheckoutTotalsItem,
  CheckoutTransitionItem,
} from '@domain/app/checkout.domain';
import { ContractStatusResponseItem } from '@domain/app/consultation.domain';
import {
  ContractStatusEnum,
  DataFieldElementTypeEnum,
  DataFieldStatusEnum,
  MediaTypeEnum,
  RoutingPathMain,
} from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { ContractService } from '@services/contract-service/contract.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { baseConfig, buttonAgenda, buttonCancelCheckout, buttonFinishCheckout } from '@utils/footer-config';
import { color } from '@utils/helpers/color';
import { formatContact } from '@utils/helpers/formatContact';
import { setInitialFocus } from '@utils/helpers/setInitialFocus';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'screen-checkout',
  templateUrl: './screen-checkout.component.html',
  styleUrls: ['./screen-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScreenCheckoutComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroySubs = new Subject<void>();
  private contractStatusResponse: ContractStatusResponseItem[];
  private finishedCheckout = false;
  private sidePanelClosed = false;
  private footerConfig: FooterConfig = baseConfig;
  private context: string = '';
  private unfinishedItems: (
    | CheckoutProductItem
    | CheckoutTaskItem
    | CheckoutTransitionItem
    | CheckoutContentElementItem
  )[] = [];
  private selectedOrder: boolean = false;

  public generalGroup: CheckoutDataFieldGeneralItem;
  public checkoutCompositions: CheckoutCompositionItem[];
  public checkoutTotals: CheckoutTotalsItem[];
  public contentElements: CheckoutContentElementItem[];

  public isLoading: boolean = false;
  public checkoutFinished: boolean = false;

  public consultationId = this.clientService.consultationId;

  public formatContact = formatContact;

  readonly color = color;
  readonly dataFieldStatus = DataFieldStatusEnum;
  readonly contractStatus = ContractStatusEnum;

  constructor(
    private router: Router,
    private actionService: ActionService,
    private clientService: ClientService,
    private rightSideNavService: RightSidenavService,
    private loadingService: LoadingService,
    private queryService: QueryService,
    private mediaService: MediaService,
    private dialogService: DialogService,
    private configService: ConfigService,
    private contextService: ContextService,
    private contractService: ContractService,
    private footerService: SharedFooterService,
    private snackBarService: SnackBarTemplatesService,
    private chg: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(isLoading => {
      this.isLoading = isLoading;
      this.footerButtonsFactory();
      this.chg.detectChanges();
      if (!isLoading && this.sidePanelClosed) {
        this.requestCheckoutData();
        this.sidePanelClosed = false;
      }
    });

    this.doAction('footer', 'is-checkout-finished', { finished: false });
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action?.target === 'checkout') {
        if (action.action === 'poll-contract-status') {
          // check polling status
          this.contractService.pollingResult.subscribe(data => {
            this.contractStatusResponse = data;
            this.chg.detectChanges();
          });
        } else if (action.action === 'refresh-checkout') {
          this.requestCheckoutData();
        }
      }
    });

    // get initial contract status for all products
    this.queryService.getContractStatus(this.consultationId)?.subscribe(data => {
      this.contractStatusResponse = data;
      let polling: boolean = false;
      data.forEach(x => {
        if (this.getContractPending({ id: x.elementId })) {
          polling = true;
        }
      });
      if (polling) {
        this.contractService.startContractStatusPolling();
        this.contractService.pollingResult.subscribe(pollingData => {
          this.contractStatusResponse = pollingData;
          polling = false;
        });
      }
    });

    this.rightSideNavService.closed.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (['checkout', 'task', 'task-indiv', 'transition', 'transition-indiv', 'content-element'].includes(context)) {
        if (this.isLoading || this.rightSideNavService.forcedClose) {
          this.sidePanelClosed = true;
        } else {
          this.requestCheckoutData();
        }
      }
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });

    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (context !== null && context !== undefined) {
        this.context = context;
      }
    });
    this.requestCheckoutData();
  }

  ngAfterViewInit(): void {
    setInitialFocus('kf-headline');
  }

  ngOnDestroy(): void {
    this.contractService._stopPolling();
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onOpenDataFields(item: any, compositionId: number, type: DataFieldElementTypeEnum): void {
    this.doAction('main', 'checkout', {
      elementId: item.id,
      compositionId,
      type,
      hasError: this.hasError('all'),
    });
  }

  public onOpenNotes(): void {
    this.doAction('main', 'notes');
  }

  /// ------------------------------------------------ ///
  /// --------------- FOOTER HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.isLoading) {
        return;
      }

      if (event.id === 'checkout-finish') {
        this.onSelectOrder(null);
      } else if (event.id === 'checkout-cancel') {
        this.cancelCheckout();
      }
    }
  }

  private footerButtonsFactory(): void {
    if (!this.footerConfig) {
      return;
    }

    const currentFinish = cloneDeep(buttonFinishCheckout);
    const currentCancel = cloneDeep(buttonCancelCheckout);
    currentFinish.inputs.config.label = this.buttonCheckoutLabel;
    currentFinish.inputs.config.disabled = this.isLoading;
    currentCancel.inputs.config.disabled = this.isLoading;

    const currentConfig = cloneDeep(this.footerConfig);
    currentConfig.right.elements = [currentCancel, currentFinish];
    if (!this.footerService.isManagedFooter) {
      currentConfig.left.elements = [buttonAgenda];
    }
    this.footerService.submitFooterConfig(currentConfig);
  }

  // --------------------------------------------- //
  // ---------- PUBLIC HELPER FUNCTIONS ---------- //
  // --------------------------------------------- //
  public formatTaskDate(date: string): string {
    return date ? moment(new Date(date)).format('LL') : '';
  }

  public formatTransitionDate(date: string): string {
    return date ? moment(new Date(date)).format('LLL') : '';
  }

  public formatPrice(price: number, quantity: number = 1): string {
    const value = price * quantity;

    return this.currencyPipe.transform(value, 'EUR', 'symbol', null, 'de');
  }

  public getTestcafeId(name = ''): string {
    return `${name?.replace(/ /g, '')}`;
  }

  public openTaskAssignment(compositionId: number, compositionTitle: string, item: any): void {
    if (compositionId) {
      this.doAction('main', 'task', {
        compositionId: compositionId,
        compositionTitle: compositionTitle,
        taskId: item.id,
        skipSelection: true,
        selected: true,
        hasError: this.hasError(item.id),
      });
    } else {
      this.doAction('main', 'task-indiv', {
        taskId: item.id,
        skipSelection: true,
        selected: true,
        hasError: this.hasError(item.id),
        subtopicId: '-1',
      });
    }
  }

  public openTransitionAssignment(compositionId: number, compositionTitle: string, item: any): void {
    if (compositionId) {
      this.doAction('main', 'transition', {
        compositionId: compositionId,
        compositionTitle: compositionTitle,
        transitionId: item.id,
        skipSelection: true,
        selected: true,
        hasError: this.hasError(item.id),
      });
    } else {
      this.doAction('main', 'transition-indiv', {
        transitionId: item.id,
        skipSelection: true,
        selected: true,
        hasError: this.hasError(item.id),
        subtopicId: '-1',
      });
    }
  }

  public openTopicAssignment(item: any): void {
    this.doAction('main', 'content-element', {
      contentElementId: item.id,
      selected: true,
      openPanelState: 'ASSIGN',
      hasError: this.hasError(item.id),
    });
  }

  // --------------------------------------------- //
  // ------------- CONTRACTS HANDLING ------------ //
  // --------------------------------------------- //

  public getContractStatus(product: CheckoutProductItem): ContractStatusEnum {
    // returns lowest contract status for a product
    // used to determine which contract icons have to be shown
    return this.contractService.getContractStatus(product, this.contractStatusResponse);
  }

  public getContractPDFViewable(product: CheckoutProductItem): boolean {
    // should return true if status is on preview or finalized, so a PDF can be viewed
    // finalized should never be the case here since we can't reach that in checkout
    const status: ContractStatusEnum = this.getContractStatus(product);

    return status === this.contractStatus.preview;
  }

  public getContractError(product: CheckoutProductItem): boolean {
    // should return true if lowest contract status is error
    let status: ContractStatusEnum = this.getContractStatus(product);
    return status === this.contractStatus.error && status !== undefined;
  }

  public getContractPending(product): boolean {
    // should return true if status is generate_preview
    // generate_finalized does not need to be considered since we can't reach that in checkout
    let status: ContractStatusEnum = this.getContractStatus(product);
    return status === ContractStatusEnum.generatePreview && status !== undefined;
  }

  public getHasContractForm(id: string): boolean {
    return !!this.contractStatusResponse?.find(x => x.elementId === id);
  }

  // --------------------------------------------- //
  // ------------- PRIVATE FUNCTIONS ------------- //
  // --------------------------------------------- //

  public onSelectOrder(event: Event): void {
    this.selectedOrder = true;
    const showCheckoutDialog = this.configService.getSettingsValue('checkoutDialog');

    if (showCheckoutDialog && (showCheckoutDialog === 'true' || showCheckoutDialog === '1')) {
      const denyButtonText = this.configService.getSettingsValue('buttonLabelCheckoutFinishAbort') || 'Abbrechen';
      const confirmButtonText = this.configService.getSettingsValue('buttonLabelCheckoutFinishConfirm') || 'Bestellen';
      const data = {
        copyText: `Wenn Sie jetzt "${confirmButtonText}" drücken, wird die Beratung beendet und Ihre Bestellung in Auftrag gegeben. Sie können auf der nächsten Seite ein Protokoll mit allen wichtigen Ergebnissen des Gesprächs herunterladen.`,
        denyText: denyButtonText,
        confirmText: confirmButtonText,
        context: this?.context,
      } as DialogConfirmData;

      let dialogRef = this.dialogService.openDialogConfirm(data);

      dialogRef.afterClosed().subscribe(result => {
        if (result?.confirmed === true) {
          this.finishedCheckout = true;
          this.requestCheckoutData();
        } else {
          dialogRef = null;
        }
      });
    } else {
      this.finishedCheckout = true;
      this.requestCheckoutData();
    }
  }

  public getTooltip(product: CheckoutProductItem) {
    return `Fehler: Vertragsinformationen zu ${product.name} können nicht angezeigt werden.`;
  }

  public getTestCafeData(status: DataFieldStatusEnum): string {
    switch (status) {
      case this.dataFieldStatus.notComplete:
        return 'checkoutScreen-button-costumerData';
      case this.dataFieldStatus.mandatoryDone:
        return 'checkoutScreen-button-completeData';
      case this.dataFieldStatus.complete:
        return 'checkoutScreen-button-done';
      default:
        return '';
    }
  }

  public hasError(id: string): boolean {
    if (!this.selectedOrder || this.contractService.pollingActive) {
      return false;
    }

    if (id === 'all') {
      return this.notComplete || this.unfinishedItems.length > 0;
    } else if (id === 'general') {
      return this.notComplete;
    } else {
      return !!this.unfinishedItems.find(x => x.id === id);
    }
  }

  private checkForErrors() {
    const compositions = this.checkoutCompositions;
    const tasks = compositions.flatMap(x => [...x.tasks]);
    const products = compositions.flatMap(x => [...x.products]);
    const transitions = compositions.flatMap(x => [...x.transitions]);
    const items = [...products, ...tasks, ...transitions, ...this.contentElements];

    this.unfinishedItems = [
      ...tasks.filter(x => !x.customerContact),
      ...transitions.filter(x => !x.expertContact),
      ...items.filter(x => x.dataFieldStatus === DataFieldStatusEnum.notComplete),
      ...this.contentElements.filter(x => !x.customerContact),
    ];
  }

  private confirmOrder(): void {
    if (!this.hasError('all')) {
      this.checkoutFinished = true;
      this.doAction('footer', 'is-checkout-finished', { finished: true });
      this.router.navigate([RoutingPathMain.Result]);
      return;
    }

    if (!this.contractService.pollingActive) {
      this.chg.detectChanges();

      const elementToScrollTo =
        this.generalGroup.dataFieldStatus === DataFieldStatusEnum.notComplete
          ? document.querySelector(`#checkout_general`)
          : document.querySelector(`#checkout_${this.unfinishedItems[0].id}`);

      this.onScrollToFirstError(elementToScrollTo);

      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: 'Bitte fehlende Daten ergänzen!',
      });
    } else {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: 'Vertragsvorschauen werden generiert. Bitte warten!',
      });
    }
  }

  private cancelCheckout(): void {
    this.router
      .navigate([`${this.contextService.currentMode}/agenda/${this.clientService.bankHubId}`])
      .then(() => this.doAction('overlay-main', 'cart'));
  }

  private requestCheckoutData(): void {
    this.queryService
      .getCheckoutData(this.clientService.consultationId)
      .pipe(takeUntil(this.destroySubs))
      .subscribe(async data => {
        await this.rewriteMediaContent(data);
        this.generalGroup = data.general;
        this.checkoutCompositions = data.compositions;
        this.checkoutTotals = data.totals;
        this.contentElements = data.themasCheckout.themas;

        this.checkForErrors();
        this.footerButtonsFactory();
        this.chg.detectChanges();

        if (this.finishedCheckout) {
          this.confirmOrder();
          this.finishedCheckout = false;
        }
      });
  }

  private onScrollToFirstError(elem: Element): void {
    elem.scrollIntoView({
      behavior: 'smooth',
    });
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private async rewriteMediaContent(data: CheckoutDataItem): Promise<void> {
    await Promise.all(
      data.compositions.map(async composition => {
        if (!!composition?.media && composition?.media.type === MediaTypeEnum.image) {
          composition.media.url = await this.mediaService.getMediaContent(composition.media.url);
        }
      })
    );
  }

  get hasCompositions(): boolean {
    return this.checkoutCompositions?.length > 0;
  }

  get hasContentElements(): boolean {
    return this.contentElements?.length > 0;
  }

  get buttonCheckoutLabel(): string {
    const setting = this.configService.getSettingsValue('buttonLabelCheckoutFinish');
    return setting ?? (this.hasCompositions ? 'Zahlungspflichtig bestellen' : 'Beratung abschließen');
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get notComplete(): boolean {
    return this.generalGroup?.dataFieldStatus === DataFieldStatusEnum.notComplete;
  }
}
