import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
  standalone: false,
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, length: number): any {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (value?.length <= length) {
      return value;
    }

    regex.test(value);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(value);
      offset++;
    }

    return value?.substr(0, length + offset * 5) + '\u2026';
  }
}
