<ng-container *ngIf="syncedQuestions && syncedQuestions.length > 0">
  <div class="sync-question-container">
    <div *ngFor="let syncedQuestion of syncedQuestions">
      {{ splitSyncedQuestionDisplayName(syncedQuestion.questionDisplayName) }}
      <div class="ml-3 mt-2" *ngFor="let answer of syncedQuestion.answers">
        <ng-container *ngTemplateOutlet="answerType; context: { $implicit: answer.answeredBy }"></ng-container>
        <ul>
          <li
            *ngFor="let answerValue of answer.values"
            [attr.data-testcafe]="getTestcafeId(answer.answeredBy, displayAnswerValue(answerValue))"
          >
            {{ displayAnswerValue(answerValue) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #answerType let-answeredBy>
  <div class="d-flex align-items-center">
    <kf-icon name="ic_personen_paar_24" class="mr-2" *ngIf="isAnsweredByMainConsultation(answeredBy)"></kf-icon>
    <kf-icon name="ic_personen_mit_kreis_24" class="mr-2" *ngIf="!isAnsweredByMainConsultation(answeredBy)"></kf-icon>

    <div class="mat-small">
      {{ getAnswerTypeName(answeredBy) }}
    </div>
  </div>
</ng-template>
