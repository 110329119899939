@if (!managedFooter) {
  <div class="sliding__page-container">
    <div
      class="sliding__panel-container"
      [class.sliding__focus-mode-fix]="platform === 'aws'"
      [ngClass]="'sliding__panel-container--' + panelState"
    >
      <kbm-side-panel
        [toolConfig]="managedFooter ? null : panelToolConfig()"
        [panelConfig]="managedFooter ? panelConfig() : null"
        [attr.hidden]="panelOpened() === false ? 'true' : null"
        (action)="onPanelAction($event)"
      >
      </kbm-side-panel>
    </div>
    <div
      class="sliding__content-container"
      [ngClass]="{
        'sliding__content-container--shrunk': panelOpened(),
        'sliding__content-container--full': !panelOpened(),
      }"
    >
      <ng-container *ngTemplateOutlet="bgzvContentTemplate"></ng-container>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="bgzvContentTemplate"></ng-container>
}

<ng-template #bgzvContentTemplate>
  <div
    id="bgzv-frontend-main"
    class="w-100 d-flex flex-column flex-nowrap"
    [class.snap]="!isEntryPoint"
    [class.is-aws]="isAWS"
  >
    <div id="bgzv-content" class="agenda-closed" [class.bgzv-frontend-main-content]="!isEntryPoint">
      <mat-sidenav-container
        [hasBackdrop]="true"
        (backdropClick)="onSideNavClose()"
        [class.app-height]="!isEntryPoint"
        autosize
      >
        <mat-sidenav #leftSidenav [autoFocus]="true" mode="over" [position]="'start'" class="sidenav-topper">
        </mat-sidenav>
        <mat-sidenav #rightSidenav [autoFocus]="true" mode="over" [position]="'end'" class="sidenav-topper">
          @switch (rightSidenavService.context) {
            @case ('profile') {
              <side-profile [inputValues]="profileInputValues"></side-profile>
            }
            @case ('chat') {
              <side-chat></side-chat>
            }
            @case ('toggle') {
              <side-consultation-toggle></side-consultation-toggle>
            }
            @case ('recommendations') {
              <side-recommendations-details [inputValues]="groupData"></side-recommendations-details>
            }
            @case ('checkout') {
              <side-checkout [inputValues]="checkoutInputValues"></side-checkout>
            }
            @case ('product') {
              <side-product-details [inputValues]="productDetailInputValues"></side-product-details>
            }
            @case ('productExtras') {
              <side-product-extras [inputValues]="productExtraInputValues"></side-product-extras>
            }
            @case ('content-element') {
              <side-content-element [inputValues]="contentElementInputValues"></side-content-element>
            }
            @case ('task') {
              <side-task [inputValues]="taskInputValues"></side-task>
            }
            @case ('task-indiv') {
              <side-task-indiv [inputValues]="taskInputValues"></side-task-indiv>
            }
            @case ('transition') {
              <side-transition [inputValues]="transitionInputValues"></side-transition>
            }
            @case ('transition-indiv') {
              <side-transition-indiv [inputValues]="transitionInputValues"></side-transition-indiv>
            }
            @case ('notes') {
              <side-notes></side-notes>
            }
          }
        </mat-sidenav>

        <mat-sidenav-content>
          @if (initActive) {
            <global-loader [message]="initMessage"></global-loader>
          }

          @if (isEntryPoint) {
            <screen-entry></screen-entry>
          } @else {
            <div class="position-relative" [class.parent]="!isConsultation">
              @if (dismissOnClick) {
                <div (click)="dismissOnClick && rightSidenavService.close()" class="grey-topper"></div>
              }
              @if (!initActive) {
                <div class="d-flex" [class.app-height]="!isEntryPoint" [excludeFromTabNavigation]="overlayActive">
                  <router-outlet></router-outlet>
                </div>
              }
              <div [hidden]="!overlayActive" class="overlay-topper" [class.app-height]="!isEntryPoint">
                <router-outlet name="overlay"></router-outlet>
              </div>
              @if (isOffline) {
                <div class="offline-topper"></div>
              }
            </div>
          }
        </mat-sidenav-content>
      </mat-sidenav-container>

      @if (!this.managedFooter) {
        <kbm-shared-footer
          [position]="footerPosition"
          [footerConfiguration]="footerConfig"
          (actionDispatched)="onFooterAction($event)"
        ></kbm-shared-footer>
      }
    </div>
  </div>
</ng-template>

<snackbar-templates></snackbar-templates>
