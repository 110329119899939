import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExpandedTopic, SubtopicOverviewItem, TopicOverviewItem } from '@domain/app/topic.domain';
import { LoadingService } from '@services/loading-service/loading.service';
import { TopicService } from '@services/topic-service/topic-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'item-topic-available',
  templateUrl: './item-topic-available.component.html',
  styleUrls: ['./item-topic-available.component.scss'],
  standalone: false,
})
export class ItemTopicAvailableComponent implements OnInit, OnDestroy {
  @Input() topicItem: TopicOverviewItem;
  @Input() grayed: boolean = false;

  @Output() topicSelected = new EventEmitter<TopicOverviewItem>();
  @Output() topicDeselect = new EventEmitter<TopicOverviewItem>();
  @Output() subtopicSelected = new EventEmitter<SubtopicOverviewItem>();
  @Output() subtopicDeselected = new EventEmitter<SubtopicOverviewItem>();
  @Output() topicExpanded = new EventEmitter<ExpandedTopic>();
  @Input() isTopicExpanded: boolean;
  @Input() isLast: boolean = false;

  private sub: Subscription;
  private externalLoading = false;

  public loading: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    private loadingService: LoadingService,
    private topicService: TopicService
  ) {}

  ngOnInit(): void {
    this.sub = this.loadingService.isLoading.subscribe(loading => {
      this.externalLoading = loading;
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnChange(changes: SimpleChanges) {}

  public onSelectAll(topic: TopicOverviewItem): void {
    if (topic.subtopics.every(sub => sub.selected)) {
      this.topicDeselect.emit(topic);
    } else {
      this.topicSelected.emit(topic);
    }
  }

  public shouldSelectAll(topic: TopicOverviewItem): boolean {
    return !topic.subtopics.every(sub => sub.selected);
  }

  public onSubtopicSelected(subtopic: SubtopicOverviewItem): void {
    this.subtopicSelected.emit(subtopic);
  }

  public onTopicExpanded(topicId, isExpanded) {
    this.topicExpanded.emit({ [topicId]: isExpanded });
  }

  public onSubtopicDeselected(subtopic: SubtopicOverviewItem): void {
    this.subtopicDeselected.emit(subtopic);
  }

  public subtopicsAvailable(subtopics: SubtopicOverviewItem[]): boolean {
    return subtopics.some(x => !x.selected);
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public getMediaUrl(id: string): string {
    return this.topicService.topicMediaIcons.get(id) || '';
  }

  get numberOfSelectedSubtopics(): number {
    return this.topicItem?.subtopics.filter(st => st.selected).length;
  }

  get numberOfAvailableSubtopics(): number {
    return this.topicItem?.subtopics.length;
  }
}
