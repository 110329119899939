<div *ngIf="!fetchingDocumentsPending; else loading" class="px-3 mt-3 documents-menu">
  <div *ngIf="customerDocumentList?.length > 0" class="mt-3">
    <div class="font-weight-bold ml-3 mb-2">Kunde</div>
    <ng-container *ngFor="let document of customerDocumentList">
      <div
        class="d-flex align-items-center cursor-pointer justify-content-between item"
        [class.item--selected]="currentDocument === document"
        (click)="currentDocument !== document ? setCurrentDocument(document) : deselectDocument()"
        role="button"
        tabindex="0"
      >
        <div
          class="d-flex justify-content-start align-items-center item-button"
          [attr.data-testcafe]="getTestcafeId(document.filename)"
        >
          <kf-icon class="mr-2" [name]="isPdf(document.filename) ? 'ic_pdf_24' : 'ic_annotation_bild_24'"></kf-icon>
          <div class="text-truncate no-select item-name">{{ document.filename }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="consultantDocumentList?.length > 0" class="mt-3">
    <div class="font-weight-bold ml-3 mb-2">Berater</div>
    <ng-container *ngFor="let document of consultantDocumentList">
      <div
        class="d-flex align-items-center cursor-pointer justify-content-between item"
        [class.item--selected]="currentDocument === document"
        (click)="currentDocument !== document ? setCurrentDocument(document) : deselectDocument()"
        role="button"
        tabindex="0"
      >
        <div
          class="d-flex justify-content-start align-items-center item-button"
          [attr.data-testcafe]="getTestcafeId(document.filename)"
        >
          <kf-icon class="mr-2" [name]="isPdf(document.filename) ? 'ic_pdf_24' : 'ic_annotation_bild_24'"></kf-icon>
          <div class="text-truncate no-select item-name">{{ document.filename }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="mt-5">
    <mat-progress-spinner
      [color]="'primary'"
      mode="indeterminate"
      class="m-auto"
      [diameter]="40"
    ></mat-progress-spinner>
  </div>
</ng-template>
