import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '@domain/app/profile';
import ProfileGroupDTO = Profile.ProfileGroupDTO;

const mapping: Readonly<Record<ProfileGroupDTO['category'], string>> = {
  GENERIC: 'Stammdaten',
  BUSINESS: 'Betriebswirtschaftliche Daten',
  ACCOUNTS: 'Kontoinformationen',
  PRODUCT_USAGE: 'Lösungsnutzung',
};

@Pipe({
  name: 'profileGroupName',
  standalone: false,
})
export class ProfileGroupNamePipe implements PipeTransform {
  transform(value: ProfileGroupDTO['category']): string {
    return mapping[value];
  }
}
