<div class="d-flex flex-column flex-scroll w-100 ml-3 mt-3">
  <div class="d-flex justify-content-between">
    <h3 class="font-weight-bold mb-2 text-truncate">
      @if (
        allNotes?.notes.length > 0 || allNotes?.notesByPageReference?.length > 0 || allNotes?.notesByTopic.length > 0
      ) {
        Notizen
      } @else {
        Keine Notizen
      }
    </h3>
  </div>
  @if (allNotes) {
    <div class="mb-4">
      @for (note of allNotes.notes; track note) {
        <div class="d-flex align-items-center">
          <mat-checkbox
            kfFocus
            class="mb-2"
            [checked]="note.includeInResult"
            [attr.data-testcafe]="'sideNotesMenu-checkbox-note-' + note.name"
            (change)="handleIncludeExcludeNote(note, note.id, note.includeInResult)"
            [color]="'grey'"
            [aria-label]="truncateText(note.name, 20) + ' id: ' + note.id"
          ></mat-checkbox>
          <button
            class="mb-2 ml-1"
            [attr.data-testcafe]="getTestcafeId(note, 'note')"
            mat-button
            color="primary"
            (click)="onOpenNote(note, note.id)"
          >
            <kf-icon
              [name]="note.type === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"
            ></kf-icon>
            <span>{{ truncateText(note.name, 20) }}</span>
          </button>
        </div>
      }
      @for (note of allNotes.notesByPageReference; track note) {
        <div class="d-flex align-items-center">
          <mat-checkbox
            kfFocus
            class="mb-2"
            [checked]="note.noteIncludeInResult"
            [attr.data-testcafe]="'sideNotesMenu-checkbox-note-Agenda'"
            (change)="handleIncludeExcludeNote(note, note.noteId, note.noteIncludeInResult)"
            [color]="'grey'"
            [aria-label]="truncateText(topicOverviewNote?.pageReference | pageReference, 25) + ' id: ' + note.noteId"
          >
          </mat-checkbox>
          @if (filterPageReference() === noteContextEnum.topicSelection) {
            <button
              class="mb-2 ml-1"
              [attr.data-testcafe]="getTestcafeId(note, 'note')"
              mat-button
              color="primary"
              (click)="onOpenNote(note, note.noteId)"
            >
              <kf-icon
                [name]="note.noteType === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"
              ></kf-icon>
              <span>{{ truncateText(topicOverviewNote?.pageReference | pageReference, 25) }}</span>
            </button>
          }
        </div>
      }
      @for (topic of allNotes.notesByTopic; track topic) {
        @for (note of topic.notes; track note) {
          <div class="d-flex align-items-center">
            <mat-checkbox
              kfFocus
              class="mb-2"
              [checked]="note.noteIncludeInResult"
              [attr.data-testcafe]="'sideNotesMenu-checkbox-note-' + note.questionGroupName"
              (change)="handleIncludeExcludeNote(note, note.noteId, note.noteIncludeInResult)"
              [color]="'grey'"
              [aria-label]="truncateText(note.questionGroupName, 25) + ' id: ' + note.noteId"
            >
            </mat-checkbox>
            <button
              class="mb-2 ml-1"
              [attr.data-testcafe]="getTestcafeId(note, 'note')"
              mat-button
              color="primary"
              (click)="onOpenNote(note, note.noteId)"
            >
              <kf-icon
                [name]="note.noteType === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"
              ></kf-icon>
              <span>{{ truncateText(note.questionGroupName, 25) }}</span>
            </button>
          </div>
        }
      }
    </div>
  }

  <div class="d-flex justify-content-between">
    <h3 class="font-weight-bold mb-2 text-truncate">
      @if (consultantDocuments?.length > 0) {
        Dokumente
      } @else {
        Keine Dokumente
      }
    </h3>
  </div>
  <ng-container>
    @for (document of consultantDocuments; track document) {
      <div class="d-flex align-items-center">
        <mat-checkbox
          kfFocus
          class="mb-2"
          [checked]="document.includeInResult"
          [attr.data-testcafe]="'sideNotesMenu-checkbox-document-' + document.filename"
          (change)="handleIncludeExcludeDocument(document)"
          [color]="'grey'"
          [aria-label]="truncateText(document.filename, 20) + ' id: ' + document.id"
        >
        </mat-checkbox>

        <button
          class="mb-2 ml-1"
          [attr.data-testcafe]="getTestcafeId(document, 'document')"
          mat-button
          color="primary"
          (click)="onOpenDocument(document)"
        >
          <kf-icon [name]="document.filename.includes('.pdf') ? 'ic_pdf_24' : 'ic_annotation_bild_24'"></kf-icon>
          <span>{{ truncateText(document.filename, 20) }}</span>
        </button>
      </div>
    }
  </ng-container>
</div>
