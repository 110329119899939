<div
  class="d-flex align-items-center justify-content-between subtopic-deselect-container pl-3"
  cdkDragLockAxis="y"
  cdkDrag
  role="listitem"
  tabindex="0"
  draggable="true"
>
  @if (!loading) {
    @if (getMediaUrl(subtopicItem.icon?.id)) {
      <bgzv-image
        class="flex-shrink-0 subtopic-icon mx-2 align-self-center"
        [alt]=""
        [src]="getMediaUrl(subtopicItem.icon?.id)"
        aria-hidden="true"
        width="24px"
        height="24px"
      ></bgzv-image>
    }
    <span class="flex-grow-1">{{ truncateText(subtopicItem.title, 135) }}</span>
  } @else {
    <mat-spinner [diameter]="24"></mat-spinner>
  }

  @if (!getMediaUrl(subtopicItem.icon?.id)) {
    <div class="subtopic-icon mx-2" aria-hidden="true"></div>
  }

  <div class="move-handler" cdkDragHandle tabindex="-1">
    <kf-icon class="drag-icon-margin" name="ic_bewegen_24"></kf-icon>
  </div>
</div>
