import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

/// -------------- PREP START BUTTONS -------------- ///
export const buttonStartPrep: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-start-consult',
    config: {
      type: ButtonType.FLAT,
      label: 'Vorbereitung starten',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};
export const buttonContinuePrep: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-continue-consult',
    config: {
      type: ButtonType.FLAT,
      label: 'Vorbereitung fortsetzen',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};

/// -------------- MAIN START BUTTONS -------------- ///
export const buttonStartMain: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-start-consult',
    config: {
      type: ButtonType.FLAT,
      label: 'Thema starten',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};
export const buttonContinueMain: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'agenda-continue-consult',
    config: {
      type: ButtonType.FLAT,
      label: 'Thema fortsetzen',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};
