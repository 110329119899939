<div class="overflow-auto vh-100">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="w-100 d-flex justify-content-between align-items-baseline">
          <kf-headline
            [headline]="'Beratungsergebnisse'"
            [showBackButton]="showResultSummary"
            (onBackButtonClick)="goBackFromSummary()"
          ></kf-headline>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-4">
    @if (!!data || !!summaryData) {
      <ng-template *ngTemplateOutlet="content"></ng-template>
    } @else {
      <mat-progress-spinner
        [color]="'primary'"
        mode="indeterminate"
        class="m-auto"
        [diameter]="90"
      ></mat-progress-spinner>
    }
    <div style="height: 64px"></div>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #content>
  <div class="row">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <aside>
          <result-header
            [customerData]="data?.consultationSummary.customer || summaryData?.consultationSummaryDTO.customer"
            [consultationData]="
              data?.consultationSummary.consultation || summaryData?.consultationSummaryDTO.consultation
            "
          >
          </result-header>
          <div class="button-row mt-4">
            <div class="button-row-content">
              <!-- notes and docs -->
              <button
                *ngIf="
                  data?.consultationSummary.notesAndDocumentsPdfCreated ||
                  summaryData?.consultationSummaryDTO.notesAndDocumentsPdfCreated
                "
                [attr.data-testcafe]="'screenResult-button-notes-and-documents-' + getTestcafeId('NotizenUndDokumente')"
                mat-stroked-button
                color="primary"
                (click)="openNotesAndDocumentsDocument()"
              >
                <span>Dokumente und Notizen</span>
                <kf-icon name="ic_herunterladen_24"></kf-icon>
              </button>

              <!-- whole consultation result-->
              <button
                *ngIf="showResult || showResultSummary"
                (click)="openConfirmationDocument()"
                [attr.data-testcafe]="'screenResult-button-downloadDocuments'"
                mat-flat-button
                color="primary"
              >
                <span>Beratungsergebnis herunterladen</span>
                <kf-icon name="ic_herunterladen_24"></kf-icon>
              </button>
            </div>
          </div>
        </aside>
      </kf-tile>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <h2>Beratungsinhalte</h2>
        <section>
          <div class="mt-4">
            <result-subtopics
              [subtopicData]="data?.consultationSummary.subtopics || summaryData?.consultationSummaryDTO.subtopics"
            ></result-subtopics>
          </div>
        </section>
      </kf-tile>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <section>
          <h2>Lösungen</h2>
          <div class="mt-4">
            <result-products
              [productData]="data?.consultationSummary.products || summaryData?.consultationSummaryDTO.products"
              [customerData]="data?.consultationSummary.customer || summaryData?.consultationSummaryDTO.customer"
              [contractStatus]="contractStatus"
            ></result-products>
          </div>
        </section>
        <section>
          <div class="mt-4">
            <result-tasks
              [taskData]="data?.consultationSummary.tasks || summaryData?.consultationSummaryDTO.tasks"
            ></result-tasks>
          </div>
        </section>
        <section>
          <div class="mt-4">
            <result-transitions
              [transitionData]="
                data?.consultationSummary.transitions || summaryData?.consultationSummaryDTO.transitions
              "
            >
            </result-transitions>
          </div>
        </section>
      </kf-tile>
    </div>
  </div>
</ng-template>
