<div class="d-flex flex-column h-100 w-100 bg-white">
  <marginals-header class="w-100"></marginals-header>
  <div class="d-flex h-100 flex-scroll">
    <section-topics
      [step]="step"
      [isPrelim]="false"
      [maximumBubbles]="maximumBubbles"
      *ngIf="step === 0 || (step === 2 && isMainMode)"
      [data]="consultationSubtopics"
      [profileData]="profileData"
      [updateInProgress]="updateInProgress"
      [expandedTopics]="expandedTopics"
      [headline]="headline"
      (subtopicSelected)="onSubtopicSelected($event, 'consultation')"
      (subtopicDeselected)="onSubtopicDeselected($event, 'consultation')"
      (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'consultation')"
      (topicSelected)="onTopicSelected($event)"
      (topicDeselect)="onTopicDeselect($event, 'consultation')"
      (expandTopic)="onTopicExpanded($event)"
    ></section-topics>
    <section-topics
      *ngIf="step === 1 && isPrepMode && consultantSubtopics.available.length > 0"
      [isPrelim]="false"
      [step]="step"
      [data]="consultantSubtopics"
      [profileData]="profileData"
      [updateInProgress]="updateInProgress"
      [expandedTopics]="expandedTopics"
      [maximumBubbles]="maximumBubbles"
      [headline]="headline"
      (subtopicSelected)="onSubtopicSelected($event, 'consultant')"
      (subtopicDeselected)="onSubtopicDeselected($event, 'consultant')"
      (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'consultant')"
      (topicSelected)="onTopicSelected($event)"
      (topicDeselect)="onTopicDeselect($event, 'consultant')"
      (expandTopic)="onTopicExpanded($event)"
    ></section-topics>
    <section-topics
      *ngIf="step === 2 && isPrepMode && customerSubtopics.available.length > 0"
      [isPrelim]="step === 2 && isPrepMode"
      [data]="customerSubtopics"
      [step]="step"
      [profileData]="profileData"
      [updateInProgress]="updateInProgress"
      [expandedTopics]="expandedTopics"
      [maximumBubbles]="maximumBubbles"
      [headline]="headline"
      (subtopicSelected)="onSubtopicSelected($event, 'customer')"
      (subtopicDeselected)="onSubtopicDeselected($event, 'customer')"
      (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'customer')"
      (topicSelected)="onTopicSelected($event)"
      (topicDeselect)="onTopicDeselect($event, 'customer')"
      (expandTopic)="onTopicExpanded($event)"
    ></section-topics>
  </div>
</div>
