<div class="position-relative flex-column h-100 background-default">
  <aside class="d-flex flex-column w-100 justify-content-start overflow-hidden agenda-edit-height">
    <!-- header -->
    <header class="pt-3 agenda-edit-header align-items-center">
      <h1 class="my-3">Themenauswahl</h1>
    </header>

    <!-- content -->
    <div class="d-flex agenda-edit-container">
      <section class="available-side">
        <kf-tile [interactive]="false" class="static-height" tabindex="-1">
          <h2 class="mb-3 mr-2 font-weight-bold">Verfügbare Themen</h2>

          <div class="d-flex">
            <div class="d-flex search-header-container">
              <div class="w-100 search-box-container" role="search">
                <mat-form-field>
                  <mat-label>Suche nach verfügbaren Themen</mat-label>
                  <input
                    id="sideAgenda-input-search"
                    [attr.data-testcafe]="'SideAgenda-input-search'"
                    aria-label="Suche nach verfügbaren Themen"
                    type="search"
                    [placeholder]="'Sucheingaben'"
                    [(ngModel)]="searchQuery"
                    (input)="handleSearchData()"
                    matInput
                  />
                  @if (searchQuery.length > 0) {
                    <button
                      (click)="searchQuery = ''"
                      aria-label="Sucheingaben löschen"
                      disableRipple
                      mat-icon-button
                      matSuffix
                    >
                      <kf-icon name="ic_clear_24"></kf-icon>
                    </button>
                  } @else {
                    <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
                      <kf-icon name="ic_search_24"></kf-icon>
                    </button>
                  }
                </mat-form-field>
              </div>
            </div>
            <div class="search-header-container-right"></div>
          </div>

          @for (topic of searchedData?.matching; track topic; let last = $last) {
            <item-topic-available
              [topicItem]="topic"
              [isTopicExpanded]="!!searchTopicId && getIsExpandedTopic(searchTopicId)"
              [display]="'available'"
              (subtopicSelected)="onSubtopicSelected(topic, $event)"
              (topicSelected)="onTopicSelected($event)"
              (subtopicDeselected)="onSubtopicDeselected($event)"
              (topicDeselect)="onTopicDeselect($event)"
              (topicExpanded)="onTopicExpanded($event)"
              [isLast]="last"
            ></item-topic-available>
          }
          @if (searchedData?.matching?.length === 0 || !isGrayed) {
            @for (topic of availableConsultationSubtopics; track topic; let last = $last) {
              <item-topic-available
                [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
                [topicItem]="topic"
                [display]="'available'"
                [grayed]="isGrayed"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (topicSelected)="onTopicSelected($event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (topicExpanded)="onTopicExpanded($event)"
                [isLast]="last"
              ></item-topic-available>
            }
            @if (showDivider && availableConsultantSubtopics?.length > 0) {
              <hr class="my-4 horizontal-line" />
            }
            @for (topic of availableConsultantSubtopics; track topic; let last = $last) {
              <item-topic-available
                [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
                [topicItem]="topic"
                [display]="'available'"
                [grayed]="isGrayed"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (topicSelected)="onTopicSelected($event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (topicExpanded)="onTopicExpanded($event)"
                [isLast]="last"
              ></item-topic-available>
            }
            @if (consultationStatus === consultationStatusEnum.inPreparation) {
              @if (showDivider && availableCustomerSubtopics?.length > 0) {
                <hr class="my-4 horizontal-line" />
              }
              @for (topic of availableCustomerSubtopics; track topic; let last = $last) {
                <item-topic-available
                  [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
                  [topicItem]="topic"
                  [display]="'available'"
                  [grayed]="isGrayed"
                  (subtopicSelected)="onSubtopicSelected(topic, $event)"
                  (topicSelected)="onTopicSelected($event)"
                  (subtopicDeselected)="onSubtopicDeselected($event)"
                  (topicDeselect)="onTopicDeselect($event)"
                  (topicExpanded)="onTopicExpanded($event)"
                  [isLast]="last"
                ></item-topic-available>
              }
            }
          }
          @if (searchedData?.matching?.length !== 0) {
            @if (isGrayed) {
              <hr class="my-4 horizontal-line" />
            }
            @for (topic of searchedData.remainder; track topic; let last = $last) {
              <item-topic-available
                [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
                [topicItem]="topic"
                [display]="'available'"
                [grayed]="isGrayed"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (topicSelected)="onTopicSelected($event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (topicExpanded)="onTopicExpanded($event)"
                [isLast]="last"
              ></item-topic-available>
            }
          }
        </kf-tile>
      </section>

      <section class="selected-side">
        <kf-tile [interactive]="false" class="static-height" tabindex="-1">
          @if (anySubtopicSelected) {
            <p class="d-flex align-items-center">
              <kf-icon name="ic_infozeichen_mit_kreis_24" aria-hidden="true" class="mr-2"></kf-icon>
              <span class="align-left">Bitte wählen Sie aus den verfügbaren Themen aus!</span>
            </p>
          }

          @if (anyConsultationSubtopicsSelected) {
            <div class="selected-topics-container">
              <div
                cdkDropList
                class="pl-1"
                [cdkDropListData]="selectedConsultationSubtopics"
                (cdkDropListDropped)="drop($event, 'consultation')"
              >
                @for (subtopic of selectedConsultationSubtopics; track subtopic; let i = $index) {
                  @if (i === 0) {
                    <div class="d-flex align-items-center" style="gap: 1rem">
                      <h2 class="font-weight-bold">Ausgewählte Themen</h2>
                      <div class="tag-info tag-neutral mb-3">
                        <div class="tag-info-text">
                          {{ selectedConsultationSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                        </div>
                      </div>
                    </div>
                  }
                  <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
                  </item-subtopic-selected>
                }
              </div>
            </div>
          }

          @if (anyConsultantSubtopicsSelected) {
            <div class="selected-topics-container">
              <div
                cdkDropList
                class="pl-1"
                [cdkDropListData]="selectedConsultantSubtopics"
                (cdkDropListDropped)="drop($event, 'consultant')"
              >
                @for (subtopic of selectedConsultantSubtopics; track subtopic; let i = $index) {
                  @if (i === 0) {
                    <div class="d-flex align-items-center" style="gap: 1rem">
                      <h2 class="font-weight-bold">Vorbereitung Berater</h2>
                      <div class="tag-info tag-neutral mb-3">
                        <div class="tag-info-text">
                          {{ selectedConsultantSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                        </div>
                      </div>
                    </div>
                  }
                  <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
                  </item-subtopic-selected>
                }
              </div>
            </div>
          }

          @if (anyCustomerSubtopicsSelected) {
            <div class="selected-topics-container">
              <div
                cdkDropList
                class="pl-1"
                [cdkDropListData]="selectedCustomerSubtopics"
                (cdkDropListDropped)="drop($event, 'customer')"
              >
                @for (subtopic of selectedCustomerSubtopics; track subtopic; let i = $index) {
                  @if (i === 0) {
                    <div class="d-flex align-items-center" style="gap: 1rem">
                      <h2 class="font-weight-bold">Vorbereitung Kunde</h2>
                      <div class="tag-info tag-neutral mb-3">
                        <div class="tag-info-text">
                          {{ selectedCustomerSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                        </div>
                      </div>
                    </div>
                  }
                  <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
                  </item-subtopic-selected>
                }
              </div>
            </div>
          }
        </kf-tile>
      </section>
    </div>
  </aside>
</div>

<ng-template #magnifier> </ng-template>
