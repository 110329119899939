import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';

@Directive({
  selector: '[dragAndDropUpload]',
  standalone: false,
})
export class DndDirective {
  @Output() fileDropped = new EventEmitter<File>();

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) ondDrop(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.fileOver = false;

    if (ev.dataTransfer.files.length > 1) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message:
          'Ein gleichzeitiges Hochladen mehrerer Dateien ist nicht möglich. Bitte wählen sie eine einzelne Datei aus.',
      });
    } else if (ev.dataTransfer.files.length === 1) {
      const file = ev.dataTransfer.files[0];
      if (file) {
        this.fileDropped.emit(file);
      }
    }
  }
  constructor(private snackBarService: SnackBarTemplatesService) {}
}
