import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[excludeFromTabNavigation]',
  standalone: true,
})
export class ExcludeFromTabNavigationDirective implements OnChanges {
  @Input() excludeFromTabNavigation: boolean;

  constructor(private elem: ElementRef) {}

  ngOnChanges() {
    let rootElem = this.elem.nativeElement;

    if (!this.excludeFromTabNavigation) {
      const focusableElements = rootElem.querySelectorAll('[prevTabIndexZero]');

      this.removeExclusion(rootElem, rootElem.getAttribute('prevTabIndexZero') === 'true');

      if (focusableElements.length > 0) {
        focusableElements.forEach((element: HTMLElement) => {
          this.removeExclusion(element, element.getAttribute('prevTabIndexZero') === 'true');
        });
      }
    } else {
      const focusableElements = rootElem.querySelectorAll(
        'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      this.setExclusion(rootElem, rootElem.getAttribute('tabindex') === '0');

      focusableElements.forEach((element: HTMLElement) => {
        this.setExclusion(element, element.getAttribute('tabindex') === '0');
      });
    }
  }

  private setExclusion(element: HTMLElement, tabIndexZero: boolean) {
    element.setAttribute('tabindex', '-1');
    element.setAttribute('prevTabIndexZero', tabIndexZero.toString());
  }

  private removeExclusion(element: HTMLElement, prevTabIndexZero: boolean) {
    prevTabIndexZero ? element.setAttribute('tabindex', '0') : element.removeAttribute('tabindex');
    element.removeAttribute('prevTabIndexZero');
  }
}
