import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { GetConsultationResponseItem, PostCreateConsultationRequestItem } from '@domain/app/consultation.domain';
import { ConsultForm, OptionData } from '@domain/app/forms.domain';
import { RoutingPathMain, RoutingPathPrep } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { QueryService } from '@services/query-service/query.service';
import { FormType, color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import 'moment/locale/de';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'overlay-consultation-new',
  templateUrl: './overlay-consultation-new.component.html',
  styleUrls: ['./overlay-consultation-new.component.scss'],
})
export class OverlayConsultationNewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dateinput', { static: false }) dateInput;

  private _consultantSub: Subscription;
  private _instanceIdsSub: Subscription;

  readonly formType = FormType;
  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly moment = moment;

  public consultants: OptionData[] = [];
  private currentConsultantId: string;

  public currentDate: Date = new Date();

  public instanceIds = [] as OptionData[];
  public submitted = false;
  public editMode = false;

  public newConsultForm: FormGroup<ConsultForm>;
  public bankHubId = '';
  public instanceId;
  public userId;

  public bg2Theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: this.color.find(color.name.Neutral100),
      buttonColor: this.color.find(color.name.BrandPrimary),
    },
    dial: {
      dialBackgroundColor: this.color.find(color.name.BrandPrimary),
    },
    clockFace: {
      clockFaceBackgroundColor: this.color.find(color.name.Neutral200),
      clockHandColor: this.color.find(color.name.BrandPrimary),
      clockFaceTimeInactiveColor: this.color.find(color.name.Information),
    },
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private actionService: ActionService,
    private queryService: QueryService,
    public clientService: ClientService,
    private readonly formValidationService: FormValidationService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;

      if (stateObj && stateObj.edit) {
        this.editMode = stateObj.edit;
      }
      if (stateObj && stateObj.bankHubId) {
        this.bankHubId = stateObj.bankHubId;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.dateInput) {
      this.dateInput.input.nativeElement.value = this.moment(moment()).format('L');
    }
  }

  ngOnInit(): void {
    this._consultantSub = this.queryService.getConsultants().subscribe(consultants => {
      this.consultants = consultants.map(x => ({ label: x.firstName + ' ' + x.lastName, value: x.id.toString() }));
      this.currentConsultantId = consultants
        .find(consultant => consultant.mail === this.clientService.bankConsultEmail)
        ?.id.toString();

      if (this.editMode && this.bankHubId !== '') {
        this.queryService.getConsultationById(this.bankHubId).subscribe(data => {
          this.clientService.consultationId = data.id;
          this.clientService.bankHubId = data.id;
          this.clientService.instanceId = data.instanceId;
          this.clientService.instanceVersion = data.contentVersion;
          this.clientService.consultationStatus = data.status;
          this.clientService.consultationDate = data.appointmentDate;
          this.clientService.consultationTime = data.appointmentTime;
          this.clientService.consultationName = data.name;

          this.setDropDownPreselection(data.userId);
          this.formDataFactory(data);
        });
      } else {
        this.setDropDownPreselection(this.currentConsultantId);
        this.formDataFactory();
      }
    });

    this._instanceIdsSub = this.queryService.getInstances().subscribe(instanceIds => {
      this.instanceIds = instanceIds
        .filter(y => y.live)
        .map(x => ({ label: x.name, value: x.id.toString(), checked: false }));
      this.instanceId = instanceIds.find(x => x.id === this.clientService.instanceId);
    });
  }

  ngOnDestroy(): void {
    this._consultantSub?.unsubscribe();
    this._instanceIdsSub?.unsubscribe();
  }

  public onCancel() {
    this.doAction('overlay-main', 'close');
  }

  private setDropDownPreselection(userId) {
    this.consultants.find(x => x.value === userId.toString()).checked = true;
  }

  public setInstanceId(event: MatSelectChange) {
    const value = event.value;
    value && (this.clientService.instanceId = value);
  }

  public selectTopicCompile() {
    this.submitted = true;

    if (!this.newConsultForm.valid) {
      return;
    }

    const formData = {
      customerNumber: this.clientService.customerNumber,
      name: this.newConsultForm.controls.title.value,
      appointmentDate: moment(this.newConsultForm.controls.date.value).format('YYYY-MM-DD'),
      appointmentTime: this.newConsultForm.controls.time.value,
      instanceId: this.clientService.instanceId,
    } as PostCreateConsultationRequestItem;

    if (!this.editMode) {
      this.queryService
        .postCreateConsultation(formData)
        .pipe(
          mergeMap(postData => {
            if (postData?.id) {
              this.clientService.consultationId = postData.id;
              return this.queryService.getConsultationsByCustomerId(postData.customerId);
            } else {
              return of(null);
            }
          })
        )
        .subscribe(data => {
          if (data) {
            const newConsultation = data.find(x => x.id === this.clientService.consultationId);
            this.clientService.bankHubId = newConsultation.id;
            this.clientService.consultationStatus = newConsultation.status;
            this.clientService.instanceVersion = newConsultation.contentVersion;
            this.clientService.consultationDate = newConsultation.appointmentDate;
            this.clientService.consultationTime = newConsultation.appointmentTime;
            this.clientService.consultationName = newConsultation.name;
            this.proceedFromConsultation();
          }
        });
    } else if (this.editMode) {
      const newFormData = formData;
      delete newFormData.customerNumber;
      this.queryService.putConsultation(this.bankHubId, formData).subscribe(data => this.proceedFromConsultation());
    }
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  private formDataFactory(data: GetConsultationResponseItem = null) {
    const formDate = data
      ? moment(data.appointmentDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    // round time to the nearest 5 minutes to avoid error with ngx timepicker
    const timeObj = data ? moment(data.appointmentTime, 'HH:mm:ss') : moment();
    timeObj.add(5 - (timeObj.minute() % 5), 'minutes');
    const formTime = timeObj.format('HH:mm');

    this.newConsultForm = this.formBuilder.group({
      date: [formDate, Validators.required],
      time: [formTime, Validators.required],
      userId: [!this.editMode ? this.currentConsultantId : data?.userId.toString(), Validators.required],
      title: [{ value: data?.name, disabled: false }, Validators.required],
      instanceId: [data?.userId.toString(), Validators.required],
    });
  }

  private async proceedFromConsultation(): Promise<void> {
    await this.doAction('overlay-main', 'close');
    const mode = this.clientService.consultationStatusType;
    const route = mode === 'prep' ? RoutingPathPrep : RoutingPathMain;
    this.router.navigate([route.TopicSelection], { state: { newConsultation: this.editMode } });
  }

  private async doAction(target: string = '', action: string = '', options?: any): Promise<void> {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    await this.actionService.setAction(data);
  }

  get newConsultFormControls() {
    return this.newConsultForm.controls;
  }
}
