import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { AnswerNumberInputComponent } from '@components/answers/answer-number-input/answer-number-input.component';
import { AnswerSelectComponent } from '@components/answers/answer-select/answer-select.component';
import { BgzvComponentsModule } from '@components/components.module';
import { SharedFooterComponent } from '@de.fiduciagad.kbm/shared-footer-lib';
import { ToolsModule } from '@de.fiduciagad.kbm/tools';
import { KFImgModule } from '@de.fiduciagad.kundenportal/kf-theme/image';
import { RoutingPathMain, RoutingPathPrep } from '@enums';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { AuthGuard } from '@modules/auth';
import { ScreenAgendaComponent } from '@screens/screen-agenda/screen-agenda.component';
import { ScreenCheckoutComponent } from '@screens/screen-checkout/screen-checkout.component';
import { ScreenClientConsultationsComponent } from '@screens/screen-client-consultations/screen-client-consultations.component';
import { ScreenClientListComponent } from '@screens/screen-client-list/screen-client-list.component';
import { ScreenConsultationComponent } from '@screens/screen-consultation/screen-consultation.component';
import { ConsultationOverviewComponent } from '@screens/screen-entry/consultation-overview/consultation-overview.component';
import { ScreenEntryComponent } from '@screens/screen-entry/screen-entry.component';
import { ScreenErrorComponent } from '@screens/screen-error/screen-error.component';
import { ScreenFinishComponent } from '@screens/screen-finish/screen-finish.component';
import { ScreenInitComponent } from '@screens/screen-init/screen-init.component';
import { ScreenLoginComponent } from '@screens/screen-login/screen-login.component';
import { ResultHeaderComponent } from '@screens/screen-result/result-header/result-header.component';
import { ResultProductsComponent } from '@screens/screen-result/result-products/result-products.component';
import { ResultSubtopicsComponent } from '@screens/screen-result/result-subtopics/result-subtopics.component';
import { ResultTransitionsComponent } from '@screens/screen-result/result-transitions/result-transitions.component';
import { ScreenResultComponent } from '@screens/screen-result/screen-result.component';
import { ScreenSummaryComponent } from '@screens/screen-summary/screen-summary.component';
import { ScreenTopicSelectComponent } from '@screens/screen-topic-select/screen-topic-select.component';
import { SectionTopicsComponent } from '@screens/screen-topic-select/section-topics/section-topics.component';
import { ShortProfileComponent } from '@screens/screen-topic-select/section-topics/short-profile/short-profile.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { ResultDisplayComponent } from './screen-result/result-display/result-display.component';

export const screenRoutes: Routes = [
  // main paths
  { path: RoutingPathMain.Login, component: ScreenLoginComponent },
  { path: RoutingPathMain.Init, component: ScreenInitComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Default, redirectTo: RoutingPathMain.Welcome, pathMatch: 'full' },
  { path: RoutingPathMain.Welcome, component: ScreenEntryComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Error, component: ScreenErrorComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.ClientList, component: ScreenClientListComponent, canActivate: [AuthGuard] },
  {
    path: RoutingPathMain.ClientConsultations,
    component: ScreenClientConsultationsComponent,
    canActivate: [AuthGuard],
  },
  { path: RoutingPathMain.TopicSelection, component: ScreenTopicSelectComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Agenda, component: ScreenAgendaComponent, canActivate: [AuthGuard] },
  { path: `${RoutingPathMain.Agenda}/:id`, component: ScreenAgendaComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Consultation, component: ScreenConsultationComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.TopicSummary, component: ScreenSummaryComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Checkout, component: ScreenCheckoutComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Result, component: ScreenResultComponent, canActivate: [AuthGuard] },
  { path: `${RoutingPathMain.ResultSummary}/:id`, component: ScreenResultComponent, canActivate: [AuthGuard] },
  { path: RoutingPathMain.Finish, component: ScreenFinishComponent, canActivate: [AuthGuard] },

  // prep paths
  { path: RoutingPathPrep.TopicSelection, component: ScreenTopicSelectComponent, canActivate: [AuthGuard] },
  { path: RoutingPathPrep.Agenda, component: ScreenAgendaComponent, canActivate: [AuthGuard] },
  { path: `${RoutingPathPrep.Agenda}/:id`, component: ScreenAgendaComponent, canActivate: [AuthGuard] },
  { path: RoutingPathPrep.Consultation, component: ScreenConsultationComponent, canActivate: [AuthGuard] },
  { path: RoutingPathPrep.TopicSummary, component: ScreenSummaryComponent, canActivate: [AuthGuard] },
];

@NgModule({
  declarations: [
    ScreenCheckoutComponent,
    ScreenClientListComponent,
    ScreenClientConsultationsComponent,
    ScreenConsultationComponent,
    ScreenErrorComponent,
    ScreenResultComponent,
    ScreenFinishComponent,
    ScreenLoginComponent,
    ScreenSummaryComponent,
    ScreenAgendaComponent,
    ScreenTopicSelectComponent,
    SectionTopicsComponent,
    ScreenEntryComponent,
    ShortProfileComponent,
    ConsultationOverviewComponent,
    ResultTransitionsComponent,
    ResultSubtopicsComponent,
    ResultProductsComponent,
    ResultHeaderComponent,
    ScreenInitComponent,
  ],
  exports: [RouterModule, ScreenEntryComponent, ScreenInitComponent, ResultDisplayComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    KfThemeModule,
    ReactiveFormsModule,
    KFImgModule,
    ToolsModule,
    BgzvComponentsModule,
    BgzvMarginalsModule,
    BgzvUtilsModule,
    SharedFooterComponent,
    AnswerSelectComponent,
    ResultDisplayComponent,
    AnswerNumberInputComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BgzvScreensModule {}
