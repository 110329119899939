import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PostCreateConsultationRequestItem } from '@domain/app/consultation.domain';
import { TopicSelectionResponse } from '@domain/app/topic.domain';
import { GetHubConsultationResponseItem } from '@domain/hub/consultation.domain';
import { CustomerResponseItem } from '@domain/hub/customer.domain';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment.prod';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import moment from 'moment';
import { Observable, Subject, forkJoin, lastValueFrom, of } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InitService implements OnDestroy {
  public appointmentId: string;
  public customerNumber: string;
  public dataInstanceId: string;

  public loadingActive: boolean = false;
  public loadingMessage: string = 'Bitte haben Sie einen Moment Geduld.';

  private subAlive: Subject<void> = new Subject<void>();

  // public directEntry = false;

  constructor(
    private clientService: ClientService,
    private queryService: QueryService,
    private router: Router,
    private contextService: ContextService,
    private configService: ConfigService,
    private noteService: NoteService,
    private documentService: DocumentService
  ) {}

  ngOnDestroy(): void {
    this.loadingActive = false;
    this.subAlive.next();
    this.subAlive.complete();
  }

  /**
   * checks if there are values for:
   * dataInstanceId
   * customerNumber
   * appointmentId
   *
   * If an appointmentId is available, open it
   * If dataInstanceId and customerNumber are available, create a new consultation
   * If only dataInstanceId or customerNumber are available, redirect to error screen
   * Else: redirect to error screen
   * @returns void
   */
  public isAdHocConsultation(): void {
    if (this.dataInstanceId !== null && this.customerNumber !== null) {
      this.loadingActive = true;
      if (this.appointmentId !== null) {
        console.log(`%c [bgzv-frontend-main] - open Consultation`, 'color: #db91db');
        this.openConsultation();
        return;
      } else {
        this.createAdhocConsultation();
        return;
      }
    } else if (
      (this.dataInstanceId && this.customerNumber === null) ||
      (this.dataInstanceId === null && this.customerNumber)
    ) {
      this.router.navigate([RoutingPathMain.Error], {
        state: { reason: 'missingDataForAdhocConsultation' },
      });

      return;
    }

    // navigate to welcome if coming e.g. from login screen
    this.router.navigate([RoutingPathMain.Welcome]);
  }

  private createAdhocConsultation() {
    moment.locale('de');
    const formData = {
      customerNumber: this.customerNumber,
      instanceId: this.dataInstanceId,
      name: `Neue Beratung - ${moment().format('dddd, DD. MMMM YYYY, HH:mm')}`,
      appointmentDate: moment().format('YYYY-MM-DD'),
      appointmentTime: moment().format('HH:mm'),
    } as PostCreateConsultationRequestItem;

    if (this.appointmentId) {
      formData.appointmentId = this.appointmentId;
    }

    console.log(`%c [bgzv-frontend-main] - Creating new consultation`, 'color: #db91db');

    this.queryService
      .postCreateConsultation(formData)
      .pipe(
        mergeMap(postData => {
          if (postData?.id) {
            console.log(`%c [bgzv-frontend-main] - New consultation created!`, 'color: #db91db');
            this.clientService.consultationId = postData.id;
            return this.queryService.getConsultationsByCustomerId(postData.customerId);
          } else {
            return of(null);
          }
        }),
        catchError(error => {
          this.router.navigate([`${RoutingPathMain.Error}`], { state: { reason: 'invalidInstance' } });
          return of(null);
        })
      )
      .pipe(takeUntil(this.subAlive))
      .subscribe(
        data => {
          if (data) {
            const newConsultation = data.find(x => x.id === this.clientService.consultationId);

            this.clientService.bankHubId = newConsultation.id;
            this.clientService.instanceId = newConsultation.instanceId;
            this.clientService.consultationStatus = newConsultation.status;
            this.clientService.instanceVersion = newConsultation.contentVersion;
            this.clientService.consultationDate = newConsultation.appointmentDate;
            this.clientService.consultationTime = newConsultation.appointmentTime;
            this.clientService.consultationName = newConsultation.name;
            this.clientService.customerId = newConsultation.customerId;

            this.contextService.skipSelection = false;

            setTimeout(() => {
              this.startConsultation();
            }, 250);
          }
        },
        error => {
          if (error.status === 400 && error.error?.message.startsWith('Data instance is not LIVE')) {
            this.router.navigate([`${RoutingPathMain.Error}`], { state: { reason: 'invalidInstance' } });
          }
        }
      );
  }

  private openConsultation(): void {
    console.log(`%c [bgzv-frontend-main] - Trying to open existing consultation`, 'color: #db91db');

    this.clientService.appointmentUUID = this.appointmentId;

    this.queryService
      .getConsultationByUrlParams(this.dataInstanceId, this.customerNumber, this.appointmentId)
      .subscribe(
        responseData => {
          this.contextService.setAppDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
          });

          // status 204 means there is no consultation, and we need to create a new one
          if (responseData.status === 204) {
            this.loadingActive = false;
            console.log(`%c [bgzv-frontend-main] - Consultation doesn't exist, make new one`, 'color: #db91db');
            this.createAdhocConsultation();
          } else if (responseData.status === 200) {
            this.handleConsultationDataResponse(responseData.body).subscribe(
              ([customerData, topicData]: [CustomerResponseItem, TopicSelectionResponse]) => {
                this.startConsultation();
              },
              error => {
                console.log(`error forkJoin`, error);
              }
            );
          }
        },
        error => {
          console.log(`error getConsultationByUrlParams`, error);
        }
      );
  }

  private async setConsulationStatus(status: ConsultationStatusEnum, send = true): Promise<void> {
    this.clientService.consultationStatus = status;

    if (send) {
      await lastValueFrom(
        this.queryService.putConsultationStatus(this.clientService.consultationId, { consultationStatus: status })
      );
    } else {
      return;
    }
  }

  /**
   * start a consultation skipping the topic selection
   */
  private async startConsultation(): Promise<void> {
    this.loadingActive = false;

    if (this.isDoneStatus) {
      console.log(`%c [bgzv-frontend-main] - Consultation is finished, redirect to summary screen`, 'color: #db91db');
      this.contextService.measureStartupTime('stop');
      this.router.navigate([`${RoutingPathMain.ResultSummary}/${this.clientService.consultationId}`], {
        state: { origin: 'module' },
      });
    } else if (this.configService.noPrepMode) {
      if (this.clientService.consultationStatus !== ConsultationStatusEnum.mainConsultation) {
        await this.setConsulationStatus(ConsultationStatusEnum.mainConsultation);
      }

      this.contextService.lastQuestionGroupId.next('-1');
      this.contextService.prepToggleMode = 'main';

      this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.bankHubId}`]);
    } else if (this.configService.quickstartMode) {
      if (this.clientService.consultationStatus !== ConsultationStatusEnum.mainConsultation) {
        await this.setConsulationStatus(ConsultationStatusEnum.mainConsultation);
      }

      this.contextService.lastQuestionGroupId.next('-1');
      this.contextService.prepToggleMode = 'main';

      this.router.navigate([RoutingPathMain.Consultation]);
    } else {
      this.contextService.lastQuestionGroupId.next('-1');
      if (this.clientService.consultationStatus === ConsultationStatusEnum.created) {
        await this.setConsulationStatus(ConsultationStatusEnum.inPreparation);
        this.router.navigate([`${RoutingPathPrep.TopicSelection}`]);
      } else if (this.clientService.consultationStatus === ConsultationStatusEnum.mainConsultation) {
        this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.consultationId}`]);
      } else if (
        this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation ||
        this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent ||
        this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone
      ) {
        this.router.navigate([`${RoutingPathPrep.Agenda}/${this.clientService.consultationId}`]);
      }
    }
  }

  private handleConsultationDataResponse(data: GetHubConsultationResponseItem): Observable<[any, any]> {
    this.clientService.bankHubId = data.id;
    this.clientService.consultationId = data.id;
    this.clientService.instanceId = data.instanceId;
    this.clientService.instanceVersion = data.contentVersion;
    this.clientService.consultationStatus = data.status;
    this.clientService.consultationDate = data.appointmentDate;
    this.clientService.consultationTime = data.appointmentTime;
    this.clientService.consultationName = data.name;

    if (environment.platform === 'vp') {
      this.queryService
        .getConsultationByConsultant()
        .pipe(takeUntil(this.subAlive))
        .subscribe(x => {
          this.clientService.appointmentUUID = x.customers
            .flatMap(x => x.consultations)
            .find(x => x.id === this.clientService.consultationId)?.appointmentId;
        });
    }

    if (!this.isDoneStatus) {
      if (data.id) {
        this.noteService.getAllNotes();
        this.documentService.fetchDocumentList();
      }
      this.handleTestStatus();
      this.clientService.setSolutionBasketCount();
    } else if (this.isDoneStatus) {
      return forkJoin([of(null), of(null)]);
    }

    const r1 = this.queryService.getCustomerById(data.customerId).pipe(catchError(() => of(null)));
    const r2 = this.queryService.getTopicDataByConsultationId(data.id).pipe(catchError(() => of(null)));

    return forkJoin([r1, r2]);
  }

  private handleTestStatus(): void {
    if (!this.clientService.fetchedTestStatus && this.clientService.consultationId) {
      this.queryService
        .getIsTestConsultation(this.clientService.consultationId)
        .pipe(takeUntil(this.subAlive))
        .subscribe(x => {
          this.clientService.isTestConsultation = x;
          this.clientService.fetchedTestStatus = true;
        });
    }
  }

  get isPrepStatus(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.created ||
      this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone
    );
  }

  get isDoneStatus(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.checkoutDone ||
      this.clientService.consultationStatus === ConsultationStatusEnum.archived
    );
  }

  get isMainStatus(): boolean {
    return !this.isPrepStatus && !this.isDoneStatus;
  }
}
