import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { DataFieldFormValueChange } from '@components/item-datafield-form/item-datafield-form.component';
import { CheckoutDataFieldCompositionItem, CheckoutDataFieldUpdateRequest } from '@domain/app/checkout.domain';
import { CustomerContactRequestItem, CustomerContactResponseItem, ExpertContactItem } from '@domain/app/contact.domain';
import { ContactForm, OptionData, TransitionForm } from '@domain/app/forms.domain';
import { MediaItem } from '@domain/app/media.domain';
import { MainProductRecommendationItem } from '@domain/app/product.domain';
import {
  AssignTransitionRequest,
  DeselectTransitionItem,
  SelectTransitionItem,
  TransitionResponse,
} from '@domain/app/transition.domain';
import {
  DataFieldElementTypeEnum,
  DataFieldStatusEnum,
  DataFieldTypeEnum,
  ExpertContactTypeEnum,
  MediaTypeEnum,
  RoutingPathMain,
  RoutingPathOverlay,
} from '@enums';
import {
  Appointment,
  BadgeData,
  TaskAndTransitionInput,
  TaskAndTransitionStateEnum,
  formEmailPattern,
} from '@marginals/transition-task-utils';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { getContactSuffix } from '@utils/helpers/getContactSuffix';
import { trimDropdownLabel } from '@utils/helpers/trimDropdownLabel';
import { formOfAddress } from '@utils/settings';
import { FormType, color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import 'moment/locale/de';
import { NgxMaterialTimepickerComponent, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'side-transition',
  templateUrl: './side-transition.component.html',
  styleUrls: ['./side-transition.component.scss'],
})
export class SideTransitionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputValues: TaskAndTransitionInput;
  @Output() closed = new EventEmitter(null);

  private _animationSub: Subscription;
  private _actionSub: Subscription;
  private _dialogSub: Subscription;
  private _dialogRef = null;

  private allExpertsList: Array<ExpertContactItem> = [];
  private transitionPutDeselectObj: DeselectTransitionItem;

  public dataFieldElementTypeEnum = DataFieldElementTypeEnum;

  public transitionComment = '';

  public transitionData: TransitionResponse;
  public dataFieldData: CheckoutDataFieldCompositionItem;
  public DataFieldStatusEnum = DataFieldStatusEnum;
  public badgeData: BadgeData;
  public transitionForm: FormGroup<TransitionForm>;

  public expertList: OptionData[] = [];
  public contactsList: OptionData[] = [];
  public existingCustomerContacts: CustomerContactResponseItem[];
  public customerContact: CustomerContactRequestItem;
  public transitionPutSelectObj: SelectTransitionItem;

  public selectedExpertContact: { id: string; type: ExpertContactTypeEnum };

  public mediaTypeEnum = MediaTypeEnum;
  public transitionState = TaskAndTransitionStateEnum;
  public state: TaskAndTransitionStateEnum = TaskAndTransitionStateEnum.default;

  public appointmentIndex = 0;
  public appointmentArray: Appointment[] = [];
  public tempAppointment: Appointment;

  public contactsFormOfAddress = formOfAddress;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly formType = FormType;

  public moment = moment;
  public fromSelectionMode = false;
  public isNewTransition = true;
  public requestInProgress = false;

  public minimumDate: Date;
  public mainData: MainProductRecommendationItem;

  public documentCount;
  public documents = null;
  public areFormsValid = false;

  public sendEmailChecked = false;
  public selectionMode;
  public selected = false;

  bg2Theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: this.color.find(color.name.Neutral100),
      buttonColor: this.color.find(color.name.BrandPrimary),
    },
    dial: {
      dialBackgroundColor: this.color.find(color.name.BrandPrimary),
    },
    clockFace: {
      clockFaceBackgroundColor: this.color.find(color.name.Neutral200),
      clockHandColor: this.color.find(color.name.BrandPrimary),
      clockFaceTimeInactiveColor: this.color.find(color.name.Information),
    },
  };

  public contactForm: FormGroup<ContactForm>;
  public contactSubmitted = false;
  readonly formEmailPattern = formEmailPattern;

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private dialogService: DialogService,
    private clientService: ClientService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService,
    private formBuilder: FormBuilder,
    private documentService: DocumentService,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputValues']) {
      this.mainData = this.inputValues.mainData;
      if (this.inputValues['useBackToList']) {
        this.queryTransitionData();
      }
    }
  }

  ngOnInit(): void {
    this.documentCount = 0;
    this.minimumDate = moment({}).toDate();

    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'side-transition') {
      }
      if (action.action === 'document-info') {
        this.documentCount = action.options.documents.length;
        this.documents = action.options.documents;
      }
    });

    if (this.inputValues.sideOpen) {
      this.queryTransitionData();
    } else {
      this._animationSub = this.rightSidenavService.instance._animationStarted.subscribe(x => {
        if (x.fromState === 'void' && x.toState === 'open') {
          this.queryTransitionData();
          this._animationSub.unsubscribe();
        }
      });
    }

    this.contactForm = this.formBuilder.group({
      formOfAddress: new FormControl<string | null>('', Validators.required),
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.formEmailPattern)])
      ),
    });

    this.transitionForm = this.formBuilder.group({
      customerContact: new FormControl<string | null>('', Validators.required),
      expertContact: new FormControl<string | null>('', Validators.required),
      individualNote: new FormControl<string | null>(''),
    });

    this.transitionPutSelectObj = {
      compositionId: this.inputValues.compositionId,
      transitionId: this.inputValues.transitionId,
      subtopicId: this.inputValues.subtopicId,
    } as SelectTransitionItem;

    this.transitionPutDeselectObj = {
      compositionId: this.inputValues.compositionId,
      transitionId: this.inputValues.transitionId,
      target: this.inputValues.target || 'RECOMMENDED',
    } as DeselectTransitionItem;

    this.selectionMode = this.inputValues.skipSelection ? false : true;

    // activate validator for expert contact when called from checkout with error
    if (this.inputValues.hasError) {
      this.transitionFormControls.expertContact.markAsTouched();
    }
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  ngOnDestroy(): void {
    this._actionSub?.unsubscribe();
  }

  public async showDocument(document: MediaItem, event: Event): Promise<void> {
    event.preventDefault();
    this.documentService.showDocument(document);
  }

  public handleClose(): void {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.closed.emit();
      if (!this.inputValues['useBackToList']) {
        this.rightSidenavService.close();
      }
    }
    this._dialogSub?.unsubscribe();
  }

  public async onBadgeClicked(type, id, event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    this.state = TaskAndTransitionStateEnum.default;
    await this.doAction('main', 'product', {
      compositionId: this.inputValues.compositionId,
      productId: this.mainData.id,
      hideButton: this.mainData.variants.length > 0,
      keepNavOpen: true,
    });
  }

  public selectTransition() {
    this.queryService
      .putSelectTransition(this.clientService.consultationId, this.transitionPutSelectObj)
      .subscribe(x => {
        this.queryTransitionData();
        this.notifyMainComponents();
      });
  }

  public deselectTransition() {
    this.queryService
      .putDeselectTransition(this.clientService.consultationId, this.transitionPutDeselectObj)
      .subscribe(x => {
        this.selected = false;
        this.notifyMainComponents();
      });
  }

  public async toggleContextMode(): Promise<void> {
    const data = {
      copyText: `Möchten Sie das Expertengespräch "${this.transitionData?.name}" aus dem Lösungskorb entfernen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this._dialogRef = this.dialogService.openDialogConfirm(data);
    this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.deselectTransition();

        setTimeout(() => {
          this.handleClose();
        }, 350);
      }
      this._dialogRef = null;
    });
  }

  public toggleSelectionMode() {
    this.selectionMode = !this.selectionMode;
  }

  public isInvalidDate(appointent: Appointment): boolean {
    return new Date(appointent.appointmentStart) <= new Date();
  }

  /**
   * opens DateTimePicker component and prevents opening to component multiple times
   * @param ref
   * @param event
   */
  public openDatepicker(ref: NgxMaterialTimepickerComponent, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    ref.open();
  }

  get contactFormControls() {
    return this.contactForm.controls;
  }

  get transitionFormControls() {
    return this.transitionForm.controls;
  }

  get hasInvalidAppointment(): boolean {
    if (this.appointmentArray && this.appointmentArray.length > 0) {
      return this.appointmentArray.some(x => new Date(x.appointmentStart) <= new Date());
    } else {
      return false;
    }
  }

  // --------------------------------------------- //
  // ---------- DEFAULT TEMPLATE FUNCTIONS ------- //
  // --------------------------------------------- //

  public onExpertSelected(event: MatSelectChange) {
    const id = event.value;
    const selectedExpert = this.allExpertsList.find(x => x.id === id);
    this.selectedExpertContact = { id: id, type: selectedExpert.contactType };
    this.expertList.forEach(x => {
      x.checked = x.value === id;
    });
  }

  public onContactSelected(event: MatSelectChange) {
    const id = event.value;
    if (id.toString().includes('___')) {
      const name = id.split('___')[1].split('###');
      const contact = this.clientService.sessionContacts.find(x => x.firstName === name[0] && x.lastName === name[1]);
      this.customerContact = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
      } as CustomerContactRequestItem;
    } else {
      const contact = this.existingCustomerContacts.find(x => x.id === id);
      this.customerContact = {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
      } as CustomerContactRequestItem;
    }
    this.contactsList.forEach(x => {
      x.checked = x.value === id;
    });
  }

  public onDatepickSelected(index: number) {
    this.appointmentIndex = index;
    this.tempAppointment = {} as Appointment;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickUpdate(index: number) {
    this.appointmentIndex = index;
    this.tempAppointment = this.appointmentArray[index] as Appointment;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickDeleted(index) {
    this.appointmentArray.splice(index, 1);
  }

  public onCustomerDataSelected(fromSelectionMode?: boolean) {
    this.fromSelectionMode = fromSelectionMode;
    this.state = TaskAndTransitionStateEnum.customer;
    this.selectionMode = false;
  }

  public onTransitionCommentAdded(transitionComment: string) {
    this.transitionComment = transitionComment;
  }

  public getExpertContact(expertContacts): string {
    const contact = expertContacts.find(x => x.selected);
    return `${contact.firstName} ${contact.lastName}`;
  }

  public onFinalizeTransition() {
    const sendObj = {} as AssignTransitionRequest;

    sendObj.appointmentDates = this.appointmentArray.map(a => {
      return { appointmentStart: a.appointmentStart, appointmentEnd: a.appointmentEnd };
    });

    if (this.selectedExpertContact) {
      sendObj.expertContact = this.selectedExpertContact;
    }

    if (this.customerContact) {
      sendObj.customerContact = this.customerContact;

      // no id = new contact -> no need for it in the session contacts
      if (!this.customerContact.id) {
        this.clientService.removeSessionContact(this.customerContact);
      }
    }

    sendObj.individualNote = this.transitionComment;
    sendObj.transitionId = this.inputValues.transitionId;
    sendObj.compositionId = this.inputValues.compositionId;

    this.queryService.putAssignTransition(this.clientService.consultationId, sendObj).subscribe(x => {
      this.handleClose();
      this.notifyMainComponents();
    });
  }

  // --------------------------------------------- //
  // -------- CUSTOMER TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onContactAdded() {
    this.customerContact = {
      formOfAddress: this.contactFormControls.formOfAddress.value,
      firstName: this.contactFormControls.firstName.value,
      lastName: this.contactFormControls.lastName.value,
      email: this.contactFormControls.email.value,
    } as CustomerContactRequestItem;

    this.clientService.addSessionContact(this.customerContact);

    this.contactsList.every(x => (x.checked = false));
    const name = `${this.customerContact.formOfAddress} ${this.customerContact.firstName} ${this.customerContact.lastName}`;
    const value = `___${this.customerContact.firstName}###${this.customerContact.lastName}`;
    this.customerContact.newCustomerId = value;

    const listItem = {
      label: trimDropdownLabel(name, 58),
      value: value,
      checked: false,
    } as OptionData;

    const contactExists = this.contactsList.find(x => x.label === listItem.label);

    if (!contactExists) {
      listItem.checked = true;
      if (!this.contactsList.find(contact => contact.value === listItem.value)) {
        this.contactsList.push(listItem);
      }
      this.contactForm.reset();
      this.transitionFormControls.customerContact.patchValue(this.customerContact.newCustomerId);
    }

    this.state = TaskAndTransitionStateEnum.default;
  }

  public onContactCancelled(): void {
    this.state = TaskAndTransitionStateEnum.default;
  }

  public onDataCancelled(): void {
    this.state = TaskAndTransitionStateEnum.default;
    if (this.fromSelectionMode) {
      if (!this.inputValues.skipSelection) {
        this.selectionMode = true;
      }
      this.fromSelectionMode = false;
    }
    this.queryTransitionData();
  }

  // --------------------------------------------- //
  // -------- DATEPICK TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onDateSelect(event: Date) {
    this.tempAppointment.timeDateString = event;
  }

  public onStartTimeSelect(setTime) {
    this.tempAppointment.timeStartString = setTime;

    const startTime = this.tempAppointment.timeStartString.split(':').map(Number);
    if (startTime[0] === 23) {
      this.tempAppointment.timeEndString = '23:55';
    } else {
      startTime[0] += 1;
      this.tempAppointment.timeEndString = startTime.map(x => x.toString().padStart(2, '0')).join(':');
    }
  }

  public onEndTimeSelect(setTime): void {
    this.tempAppointment.timeEndString = setTime;
  }

  public onAppointmentDateConfirmed(): void {
    const dayObject = moment(this.tempAppointment.timeDateString);

    const startObject = moment(this.tempAppointment.timeDateString);
    const startTime = this.tempAppointment.timeStartString.split(':').map(Number);
    const startDate = startObject.hour(startTime[0]).minute(startTime[1]);

    const endObject = moment(this.tempAppointment.timeDateString);
    const endTime = this.tempAppointment.timeEndString.split(':').map(Number);
    const endDate = endObject.hour(endTime[0]).minute(endTime[1]);
    this.tempAppointment.appointmentStart = moment(startDate).format('YYYY-MM-DDTHH:mm:SS');
    this.tempAppointment.appointmentEnd = moment(endDate).format('YYYY-MM-DDTHH:mm:SS');
    this.tempAppointment.appointmentString = `${dayObject.format('dddd, DD MMMM YYYY')}, ${
      this.tempAppointment.timeStartString
    } - ${this.tempAppointment.timeEndString} Uhr`;

    // new appointment
    if (this.appointmentIndex === this.appointmentArray.length) {
      this.appointmentArray.push(this.tempAppointment);
    }
    // update existing appointment
    if (this.appointmentIndex < this.appointmentArray.length) {
      this.appointmentArray[this.appointmentIndex] = this.tempAppointment;
    }

    this.state = TaskAndTransitionStateEnum.default;
  }

  public getEndTimeMin(startTime) {
    return moment(startTime, 'HH:mm').add(5, 'minutes').format('HH:mm');
  }

  public handleBack() {
    if (
      (this.state === this.transitionState.default && this.selectionMode) ||
      this.state === this.transitionState.customer
    ) {
      this.onDataCancelled();
      if (this.inputValues.sideOpen) {
        this.doAction('recommendation-extra', 'details-closed');
      }
    } else if (this.state === this.transitionState.default && !this.selectionMode) {
      this.selectionMode = true;
    } else {
      this.state = this.transitionState.default;
      if (this.inputValues.sideOpen) {
        this.doAction('recommendation-extra', 'details-closed');
      }
    }
  }

  public handleDataFormValueChange(event: DataFieldFormValueChange) {
    // send this to the backend, and request new data;
    const sendObj = {
      dataFieldValueId: event.item.dataFieldValueId,
      value: event.changedValue || '',
    } as CheckoutDataFieldUpdateRequest;

    this.requestInProgress = true;

    this.queryService
      .putCheckoutDataFieldData(this.clientService.consultationId, sendObj, event.item.elementType)
      .subscribe(
        x => {
          if (
            event.item.dataFieldType === DataFieldTypeEnum.checkbox ||
            event.item.dataFieldType === DataFieldTypeEnum.dropdown ||
            event.item.dataFieldType === DataFieldTypeEnum.radio
          ) {
            this.queryDataFieldData();
          } else {
            this.requestInProgress = false;
          }
        },
        err => (this.requestInProgress = false)
      );
  }

  get notValidAppointment() {
    const s = this.tempAppointment.timeStartString;
    const e = this.tempAppointment.timeEndString;
    if (this.tempAppointment.timeDateString && s && e) {
      return moment(s, 'HH:mm').isAfter(moment(e, 'HH:mm'));
    } else {
      return true;
    }
  }

  private async doAction(target: string = '', action: string = '', options?: any): Promise<void> {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    await this.actionService.setAction(data);
  }

  private notifyMainComponents() {
    this.clientService.setSolutionBasketCount();
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Cart) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  private queryTransitionData() {
    this.queryService
      .getTransitionById(
        this.clientService.consultationId,
        this.inputValues.compositionId,
        this.inputValues.transitionId
      )
      .subscribe(data => {
        this.transitionData = data;
        this.isNewTransition = data.expertContact?.selected ? false : true;
        this.documents = this.transitionData.medias.filter(x => x.type !== this.mediaTypeEnum.image);
        this.documentCount = this.documents.length;
        this.transitionComment = data.individualNote;
        this.queryExpertData();
        this.appointmentDateFactory();

        this.queryDataFieldData();
        this.selected = this.transitionData.selected;
      });

    this.badgeData = {
      id: -1,
      type: 'product',
      label: this.inputValues.compositionTitle || '',
      bgColor: color.find(color.name.InformationLight),
      textColor: color.find(color.name.BrandPrimary),
    };
  }

  private queryDataFieldData(): void {
    this.queryService
      .getCheckoutDataFieldData(
        this.clientService.consultationId,
        DataFieldElementTypeEnum.transitions,
        this.inputValues.compositionId,
        this.transitionData.id
      )
      .pipe(finalize(() => (this.requestInProgress = false)))
      .subscribe(data => {
        this.dataFieldData = data;
      });
  }

  private queryExpertData(): void {
    this.queryService.getTransitionExperts(this.clientService.consultationId, this.transitionData.id).subscribe(x => {
      this.expertDropdownFactory(x.experts);
      this.contactsDropdownFactory(x.customers);
    });
  }

  private expertDropdownFactory(expertList: ExpertContactItem[]) {
    this.expertList = [];
    this.allExpertsList = expertList;

    if (!expertList?.length) {
      return;
    }

    for (const expert of expertList) {
      const listItem: OptionData = { label: '', value: '' };
      const name = `${expert.formOfAddress} ${expert.firstName} ${expert.lastName}`;

      if (expert.contactType === ExpertContactTypeEnum.expertContact) {
        const department = expert.department ? `, ${expert.department}` : '';
        const position = expert.position ? `, ${expert.position}` : '';
        listItem.label = `${name}${position}${department}`;
      } else if (expert.contactType === ExpertContactTypeEnum.customerContact) {
        listItem.label = `${name} (Berater)`;
      }
      listItem.label = trimDropdownLabel(listItem.label, 58);
      listItem.value = `${expert.id}`;
      listItem.checked = this.transitionData?.expertContact?.id === expert.id || expert.defaultExpert === true || false;
      if (listItem.checked) {
        this.selectedExpertContact = { id: expert.id, type: expert.contactType };
        this.transitionForm.controls.expertContact.setValue(expert.id);
      }
      this.expertList.push(listItem);
    }

    if (!this.selectedExpertContact) {
      this.selectedExpertContact = undefined;
      return;
    }
  }

  private contactsDropdownFactory(customerList, newContact = null) {
    this.existingCustomerContacts = customerList;
    const currentConsultantId = this.existingCustomerContacts
      .find(consultant => consultant.email === this.clientService.bankConsultEmail)
      ?.id.toString();
    const custId = this.transitionData.customerContact
      ? this.transitionData.customerContact.id
      : currentConsultantId || -1;

    for (const contact of this.existingCustomerContacts) {
      const consultantSuffix = getContactSuffix(contact.customerContactType);
      const contactExists = this.contactsList.find(
        x => x.label.search(`${contact.firstName} ${contact.lastName}${consultantSuffix}`) !== -1
      );

      if (!contactExists) {
        const listItem = {} as OptionData;
        listItem.label = `${contact.formOfAddress ? contact.formOfAddress : ''} ${contact.firstName} ${contact.lastName}${consultantSuffix}`;
        listItem.label = trimDropdownLabel(listItem.label, 58);
        listItem.value = String(contact.id);
        listItem.checked = custId === contact.id;
        if (!this.contactsList.find(contact => contact.value === listItem.value)) {
          this.contactsList.push(listItem);
        }

        if (custId === contact.id) {
          this.customerContact = {} as CustomerContactRequestItem;
          this.customerContact.id = contact.id;
          this.customerContact.formOfAddress = contact.formOfAddress;
          this.customerContact.firstName = contact.firstName;
          this.customerContact.lastName = contact.lastName;
          this.customerContact.email = contact.email;
          this.transitionForm.controls.customerContact.setValue(contact.id);
        }

        if (listItem.checked) {
          this.transitionForm.controls.customerContact.setValue(this.customerContact.id);
        }
      }
    }

    this.clientService.sessionContacts.forEach(contact => {
      const listItem = {} as OptionData;
      listItem.label = contact.firstName + ' ' + contact.lastName;
      listItem.label = trimDropdownLabel(listItem.label, 58);
      listItem.value = '___' + contact.firstName + '###' + contact.lastName;
      listItem.checked = false;

      if (!this.contactsList.find(contact => contact.value === listItem.value)) {
        this.contactsList.push(listItem);
      }
    });
  }

  private appointmentDateFactory() {
    // eslint-disable-next-line guard-for-in
    for (const index in this.transitionData.appointments) {
      const a = {} as Appointment;
      const d = moment(this.transitionData.appointments[index].appointmentStart).format('dddd, DD MMMM YYYY');

      a.appointmentStart = this.transitionData.appointments[index].appointmentStart;
      a.appointmentEnd = this.transitionData.appointments[index].appointmentEnd;

      a.timeStartString = moment(a.appointmentStart).format('HH:mm');
      a.timeEndString = moment(a.appointmentEnd).format('HH:mm');
      a.timeDateString = moment(a.appointmentStart).toDate();

      a.appointmentString = `${d}, ${a.timeStartString} - ${a.timeEndString} Uhr`;

      this.appointmentArray.push(a);
    }
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get showBackButton() {
    const a = this.state === this.transitionState.default;
    const b = this.selectionMode;
    const c = this.inputValues['useBackToList'] || false;
    const d = this.inputValues.skipSelection;
    const e = this.inputValues.sideOpen;

    return !a || (c && !d) || (!b && !d) || e;
  }

  get showCloseButton(): boolean {
    return !this.showBackButton;
  }

  get buttonDisabled() {
    if (!this.selectedExpertContact?.id || !this.customerContact || this.hasInvalidAppointment) {
      return true;
    } else {
      return false;
    }
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get hasDataFieldData(): boolean {
    return !!this.dataFieldData?.dataFieldGroups && this.dataFieldData.dataFieldStatus !== DataFieldStatusEnum.none;
  }
}
