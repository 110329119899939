import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { MediaAllowedCheckItem } from '@domain/app/media.domain';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss'],
  standalone: false,
})
export class DocumentsUploadComponent implements OnInit, OnDestroy {
  @Input() compact = false;
  @Input() hidden = false;
  @Input() fileAccept = '.pdf,.png,.jpeg,.jpg,.gif';
  @Input() fileSizeLimit = 5000000;
  @Input() fileTypeName = 'Dokumente';
  @Input() useCustomUpload = false;

  @Output() uploadReady = new EventEmitter<File>();

  public errorMessage = '';

  public snackBarMessage: string;

  private errorMessageSub: Subscription;
  private notAllowed: MediaAllowedCheckItem = { fileTooBig: false, fileTypeUnsupported: false };

  constructor(
    private documentService: DocumentService,
    private contextService: ContextService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  ngOnInit(): void {
    this.errorMessageSub = this.documentService.errorMessage.subscribe(
      errorMessage => (this.errorMessage = errorMessage)
    );
  }

  ngOnDestroy(): void {
    this.documentService.errorMessage.next('');
    this.errorMessageSub?.unsubscribe();
  }

  public resetPath(event: any): void {
    this.documentService.errorOccured.next(false);
    this.documentService.errorMessage.next('');
    event.target.value = null;
  }

  public triggerUploadDialog(): void {
    const input = document.getElementById('bgzv-document-upload') as HTMLInputElement;
    if (!!input) {
      input.click();
    }
  }

  public extractFileFromEvent(event): void {
    this.checkBeforeUpload(event.target.files[0]);
  }

  public checkBeforeUpload(file): void {
    let errorOccured = false;
    this.notAllowed = { fileTooBig: false, fileTypeUnsupported: false };
    const allowedTypes = this.fileAccept.split(',').map(value => {
      return `${value === '.pdf' ? 'application' : 'image'}/${value.substring(1)}`;
    });

    if (!allowedTypes.includes(file.type)) {
      this.notAllowed.fileTypeUnsupported = true;
    }
    if (file.size > this.fileSizeLimit) {
      this.notAllowed.fileTooBig = true;
    }

    // check for error cases, open snackbar with different messages
    if (this.notAllowed.fileTooBig && this.notAllowed.fileTypeUnsupported) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: 'Die Datei entspricht nicht den Bedingungen und kann daher nicht hochgeladen werden.',
      });
      errorOccured = true;
    } else if (this.notAllowed.fileTooBig && !this.notAllowed.fileTypeUnsupported) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: `Die Datei ${file.name} ist zu groß. Dateien dürfen maximal ${this.maxFileSize} groß sein.`,
      });
      errorOccured = true;
    } else if (!this.notAllowed.fileTooBig && this.notAllowed.fileTypeUnsupported) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: `Die Datei "${file.name}" entspricht nicht dem erlaubten Format. Bitte beachten Sie, dass nur folgende Dateitypen unterstützt werden: ${this.fileAccept}`,
      });
      errorOccured = true;
    }

    // if no error occured, proceed with upload
    if (!errorOccured) {
      if (this.useCustomUpload) {
        this.uploadReady.emit(file);
      } else {
        this.documentService.uploadDocument(file);
      }
    }
  }

  get maxFileSize(): string {
    let size = this.fileSizeLimit / 1000000;

    if (size < 1) {
      return `${this.fileSizeLimit / 1000}kb`;
    } else {
      return `${size}Mb`;
    }
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
