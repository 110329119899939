import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

/// ----------------- DOCUMENTS BUTTONS ---------------- ///

export const buttonDocumentsActive: ElementConfig = {
  type: ComponentType.MENU_BUTTON,
  inputs: {
    owner: 'bgzv',
    config: {
      type: ButtonType.BASIC,
      icon: 'ic_datei_praesentation_24',
      tooltip: 'Beratermappe öffnen',
      badgeLabel: '1',
    },
    children: [],
  },
};

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const buttonNext: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'consultation-next-group',
    config: {
      type: ButtonType.FLAT,
      label: 'Weiter',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};

export const buttonPrev: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'consultation-prev-group',
    config: {
      type: ButtonType.STROKED,
      icon: 'ic_pfeil_links_24',
    },
  },
};

// New functionality - 'Abbrechen' button is not used anymore
export const buttonCancel: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'consultation-cancel',
    config: {
      type: ButtonType.BASIC,
      label: 'Abbrechen',
    },
  },
};
