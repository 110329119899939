<div class="py-4 pr-4" tabindex="0">
  <div *ngIf="logoReference" class="side-profile__customer-logo">
    <img class="image-content" [src]="logoReference" alt="" />
  </div>
  <ng-container *ngIf="baseData.length > 0">
    <div class="mt-5">
      <h2>Beratung</h2>
      <ng-container *ngTemplateOutlet="profileElements; context: { $implicit: baseData }"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="extendedData.length > 0">
    <div class="mt-5">
      <h2>Weitere Kundendaten</h2>
      <ng-container *ngTemplateOutlet="profileElements; context: { $implicit: extendedData }"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #profileElements let-data>
  <div *ngFor="let element of data; let last = last" class="d-flex" [class.mb-3]="!last">
    <div class="w-50">{{ element.label }}</div>
    <div
      *ngIf="element?.type !== dataFieldTypeEnum.price"
      class="w-50"
      [attr.data-testcafe]="getTestcafeId(element.label)"
      handleENumbers
    >
      {{ element.value }}
    </div>
    <div
      *ngIf="element?.type === dataFieldTypeEnum.price"
      class="w-50"
      [attr.data-testcafe]="getTestcafeId(element.label)"
      handleENumbers
    >
      {{ element.value | thousandsDivider }}
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="w-100 d-flex flex-column mt-5">
    <mat-progress-spinner
      [color]="'primary'"
      mode="indeterminate"
      class="mx-auto"
      [diameter]="90"
    ></mat-progress-spinner>
    <p class="w-100 text-center py-3">Profildaten werden geladen &hellip;</p>
  </div>
</ng-template>
