import { DataFieldElementTypeEnum, DataFieldStatusEnum, DataFieldTypeEnum, PaymentInterval, VatEnum } from '@enums';
import { MediaItem } from './media.domain';

export interface CheckoutDataItem {
  general: CheckoutDataFieldGeneralItem;
  compositions: CheckoutCompositionItem[];
  totals: CheckoutTotalsItem[];
  themasCheckout: CheckoutThemas;
}

export interface CheckoutThemas {
  name: string;
  themas: CheckoutContentElementItem[];
}

export interface CheckoutDataFieldGeneralItem {
  dataFieldStatus: DataFieldStatusEnum | string;
}

export interface CheckoutCompositionItem {
  id: string;
  name: string;
  products: CheckoutProductItem[];
  media: MediaItem;
  tasks: CheckoutTaskItem[];
  transitions: CheckoutTransitionItem[];
}

export interface CheckoutTotalsItem {
  type: PaymentInterval | string;
  netPrice: number;
  grossPrice: number;
}

export interface CheckoutProductItem {
  id: string;
  name: string;
  quantityVisibility: boolean;
  selectedQuantity: number;
  fixCost?: CheckoutPriceItem;
  runningCost?: CheckoutPriceItem;
  dataFieldStatus: DataFieldStatusEnum | string;
}

export interface CheckoutTaskItem {
  id: number;
  name: string;
  appointmentDate?: string;
  customerContact: CheckoutCustomerContactItem;
  dataFieldStatus: DataFieldStatusEnum | string;
}

export interface CheckoutTransitionItem {
  id: number;
  name: string;
  appointments: CheckoutAppointmentItem[];
  customerContact: CheckoutCustomerContactItem;
  expertContact: CheckoutExpertContactItem;
  dataFieldStatus: DataFieldStatusEnum | string;
}

export interface CheckoutContentElementItem {
  id: number;
  name: string;
  appointmentDate?: string;
  customerContact?: CheckoutCustomerContactItem;
  dataFieldStatus: DataFieldStatusEnum | string;
}

export interface CheckoutPriceItem {
  netPrice: number;
  grossPrice: number;
  paymentInterval: PaymentInterval | string;
  vat: VatEnum | string;
  footnote?: string;
}

export interface CheckoutAppointmentItem {
  appointmentStart: string;
  appointmentEnd: string;
}

export interface CheckoutCustomerContactItem {
  id: number;
  firstName: string;
  lastName: string;
}

export interface CheckoutExpertContactItem {
  id: number;
  firstName: string;
  lastName: string;
}

// Checkout DataFields

export interface CheckoutDataFieldCompositionItem {
  compositionId?: string;
  compositionName?: string;
  elementName?: string;
  dataFieldStatus?: DataFieldStatusEnum | string;
  dataFieldGroups: CheckoutDataFieldGroupItem[];
}

export interface CheckoutDataFieldGroupItem {
  dataFieldGroupId: string;
  dataFields: CheckoutDataFieldItem[];
  general: boolean;
  multiplied?: boolean;
  name: string;
  description?: string;
  ordinal?: number;
  contractIncluded?: boolean;
  hasContractForm?: boolean;
}

export interface CheckoutDataFieldItem {
  dataFieldValueId: string;
  name: string;
  value?: string;
  elementId?: number;
  elementType: DataFieldElementTypeEnum | string;
  dataFieldType: DataFieldTypeEnum | string;
  readonly?: boolean;
  mandatory: boolean;
  defaultValue?: string;
  description?: string;
  jsonAnswerOptions?: string;
  jsonValidations?: string;
  valueValidations?: Validation[];
}

export enum OffsetUnit {
  days = 'DAYS',
  months = 'MONTHS',
  years = 'YEARS',
}

export type Validation = DateValidation; // custom defined validations can be added here

export interface DateValidation {
  type: 'PAST_OFFSET_MAX' | 'FUTURE';
  offset?: number;
  offsetUnit?: OffsetUnit;
  validationMessage?: string;
}

export interface CheckoutDataFieldUpdateRequest {
  dataFieldValueId?: string;
  value: string;
}
