<aside class="overlay-agenda-edit">
  <!-- header -->
  <header class="agenda-edit-header">
    <h1 class="my-3">Themenauswahl</h1>
  </header>

  <!-- content -->
  <div class="agenda-edit-container">
    <section class="available-side">
      <kf-tile [interactive]="false" class="static-height" tabindex="-1">
        <h2 class="mb-3 mr-2 font-weight-bold">Auswahl</h2>

        <div class="d-flex search-header-container">
          <div class="w-100 search-box-container" role="search">
            <mat-form-field>
              <mat-label>Suche nach verfügbaren Themen</mat-label>
              <input
                matInput
                id="sideAgenda-input-search"
                type="search"
                aria-label="Suche nach verfügbaren Themen"
                autocomplete="off"
                [ngModel]="searchQuery"
                [placeholder]="'Sucheingaben'"
                [attr.data-testcafe]="'SideAgenda-input-search'"
                (input)="handleSearchData()"
                (ngModelChange)="searchQuery = $event ? $event : null"
              />
              @if (searchQuery.length > 0) {
                <button
                  matSuffix
                  disableRipple
                  mat-icon-button
                  aria-label="Sucheingaben löschen"
                  (click)="searchQuery = ''"
                >
                  <kf-icon name="ic_clear_24"></kf-icon>
                </button>
              } @else {
                <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
                  <kf-icon name="ic_search_24"></kf-icon>
                </button>
              }
            </mat-form-field>
          </div>
        </div>

        @for (topic of searchedData?.matching; track topic; let last = $last) {
          <ng-template *ngTemplateOutlet="itemTopic; context: { last, topic, isGrayed: false }"></ng-template>
        }
        @if (searchedData?.matching?.length === 0 || !isGrayed) {
          @for (topic of availableConsultationSubtopics; track topic; let last = $last) {
            <ng-template *ngTemplateOutlet="itemTopic; context: { last, topic, isGrayed }"></ng-template>
          }
          @if (showDivider && availableConsultantSubtopics?.length > 0) {
            <hr class="my-4 horizontal-line" />
          }
          @for (topic of availableConsultantSubtopics; track topic; let last = $last) {
            <ng-template *ngTemplateOutlet="itemTopic; context: { last, topic, isGrayed }"></ng-template>
          }
          @if (consultationStatus === consultationStatusEnum.inPreparation) {
            @if (showDivider && availableCustomerSubtopics?.length > 0) {
              <hr class="my-4 horizontal-line" />
            }
            @for (topic of availableCustomerSubtopics; track topic.id; let last = $last) {
              <ng-template *ngTemplateOutlet="itemTopic; context: { last, topic, isGrayed }"></ng-template>
            }
          }
        }
        @if (searchedData?.matching?.length !== 0) {
          @if (isGrayed) {
            <hr class="my-4 horizontal-line" />
          }
          @for (topic of searchedData.remainder; track topic; let last = $last) {
            <ng-template *ngTemplateOutlet="itemTopic; context: { last, topic, isGrayed }"></ng-template>
          }
        }
      </kf-tile>
    </section>

    <section class="selected-side">
      <kf-tile [interactive]="false" class="static-height" tabindex="-1">
        @if (noSubtopicSelected) {
          <p class="d-flex align-items-center">
            <kf-icon name="ic_infozeichen_mit_kreis_24" aria-hidden="true" class="mr-2"></kf-icon>
            <span class="align-left">Bitte wählen Sie aus den verfügbaren Themen aus!</span>
          </p>
        }

        @if (anyConsultationSubtopicsSelected) {
          <div class="selected-topics-container">
            <div
              cdkDropList
              role="list"
              class="pl-1"
              [cdkDropListData]="selectedConsultationSubtopics"
              (cdkDropListDropped)="drop($event, 'consultation')"
            >
              <div class="d-flex align-items-center mb-3">
                <h2 class="font-weight-bold m-0">Reihenfolge</h2>
                <kf-icon
                  class="mx-2"
                  name="ic_infozeichen_mit_kreis_24"
                  kfTooltip="Reihenfolge per Drag & Drop anpassen"
                  kfTooltipShow="hover"
                  kfTooltipPosition="after"
                ></kf-icon>
              </div>

              @for (subtopic of selectedConsultationSubtopics; track subtopic; let i = $index) {
                @if (i === 0) {
                  <div class="section-header">
                    <h3 class="mb-0">Ausgewählte Themen</h3>
                    <div class="tag-info tag-neutral">
                      <div class="tag-info-text">
                        {{ selectedConsultationSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                      </div>
                    </div>
                  </div>
                }
                <item-subtopic-selected [subtopicItem]="subtopic"> </item-subtopic-selected>
              }
            </div>
          </div>
        }

        @if (anyConsultantSubtopicsSelected) {
          <div class="selected-topics-container">
            <div
              cdkDropList
              role="list"
              class="pl-1"
              [cdkDropListData]="selectedConsultantSubtopics"
              (cdkDropListDropped)="drop($event, 'consultant')"
            >
              @for (subtopic of selectedConsultantSubtopics; track subtopic; let i = $index) {
                @if (i === 0) {
                  <div class="section-header">
                    <h3 class="mb-0">Vorbereitung Berater</h3>
                    <div class="tag-info tag-neutral">
                      <div class="tag-info-text">
                        {{ selectedConsultantSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                      </div>
                    </div>
                  </div>
                }
                <item-subtopic-selected [subtopicItem]="subtopic"> </item-subtopic-selected>
              }
            </div>
          </div>
        }

        @if (anyCustomerSubtopicsSelected) {
          <div class="selected-topics-container">
            <div
              cdkDropList
              role="list"
              class="pl-1"
              [cdkDropListData]="selectedCustomerSubtopics"
              (cdkDropListDropped)="drop($event, 'customer')"
            >
              @for (subtopic of selectedCustomerSubtopics; track subtopic; let i = $index) {
                @if (i === 0) {
                  <div class="section-header">
                    <h3 class="mb-0">Vorbereitung Kunde</h3>
                    <div class="tag-info tag-neutral">
                      <div class="tag-info-text">
                        {{ selectedCustomerSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                      </div>
                    </div>
                  </div>
                }
                <item-subtopic-selected [subtopicItem]="subtopic"></item-subtopic-selected>
              }
            </div>
          </div>
        }
      </kf-tile>
    </section>
  </div>
  <div class="overflow-indicator" aria-live="assertive"></div>
</aside>

<ng-template #itemTopic let-last="last" let-topic="topic" let-isGrayed="isGrayed">
  <item-topic-available
    [isLast]="last"
    [grayed]="isGrayed"
    [topicItem]="topic"
    [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
    (topicSelected)="onTopicSelected($event)"
    (topicDeselect)="onTopicDeselect($event)"
    (topicExpanded)="onTopicExpanded($event)"
    (subtopicSelected)="onSubtopicSelected(topic, $event)"
    (subtopicDeselected)="onSubtopicDeselected($event)"
  ></item-topic-available>
</ng-template>
