import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ConsultationDocumentsResponse } from '@domain/app/media.domain';
import { Notes } from '@domain/notes';
import { DocumentUploadedByEnum, NoteContextEnum, NoteTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import { Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'side-notes-menu',
  templateUrl: './side-notes-menu.component.html',
  styleUrls: ['./side-notes-menu.component.scss'],
  standalone: false,
})
export class SideNotesMenuComponent implements OnInit, OnDestroy {
  @Output() numberOfElements = new EventEmitter<number>();
  @Output() numberOfSelectedElements = new EventEmitter<number>();
  @Output() note = new EventEmitter<any>();
  @Output() document = new EventEmitter<number>();
  @Output() type = new EventEmitter<NoteTypeEnum>();

  private destroySubs = new Subject<void>();

  public allNotes: Notes.GetAllNotesResponse | undefined = undefined;
  public consultantDocuments: ConsultationDocumentsResponse[] = [];
  public topicOverviewNote: Notes.NoteByPageReference | undefined;
  public consultationId: string;
  public selected: number;
  public selectedNote: string;
  public selectedDocument: number;
  public noteContextEnum = NoteContextEnum;
  public isLoading: boolean = false;

  readonly noteTypeEnum = NoteTypeEnum;

  constructor(
    private readonly noteService: NoteService,
    private readonly documentService: DocumentService,
    private readonly queryService: QueryService,
    private readonly clientService: ClientService,
    private readonly loadingService: LoadingService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.consultationId = this.clientService.consultationId;

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.isLoading = loading;
      this.changeDetector.detectChanges();
    });

    this.noteService.getAllNotes();
    this.documentService.fetchDocumentList();

    combineLatest([this.documentService.currentDocuments, this.noteService.currentNotes])
      .pipe(
        map(([documents, notes]: [ConsultationDocumentsResponse[], Notes.GetAllNotesResponse]) => {
          let num = 0;
          let selected = 0;
          if (documents.length > 0) {
            this.consultantDocuments = documents.filter(x => x.uploadedBy === DocumentUploadedByEnum.consultant);
            num = this.consultantDocuments.length;
            selected = this.consultantDocuments.filter(x => x.includeInResult === true).length;
          }
          if (notes) {
            this.allNotes = notes;
            num =
              num +
              notes.notes.length +
              notes.notesByPageReference.length +
              notes.notesByTopic.flatMap(x => x.notes).length;
            this.selected =
              selected +
              notes.notes.filter(x => x.includeInResult).length +
              notes.notesByPageReference.filter(x => x.noteIncludeInResult === true).length +
              notes.notesByTopic.flatMap(x => x.notes).filter(x => x.noteIncludeInResult === true).length;
          }
          this.numberOfElements.emit(num);
          this.numberOfSelectedElements.emit(this.selected);
        })
      )
      .subscribe();

    combineLatest;
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
    this.noteService.currentNote.next(null);
    this.documentService.currentDocument.next(undefined);
    this.documentService.currentDocuments.next([]);
  }

  public filterPageReference() {
    this.topicOverviewNote = this.allNotes.notesByPageReference.filter(
      x => x.pageReference === NoteContextEnum.topicSelection
    )[0];
    return this.topicOverviewNote.pageReference;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  public getTestcafeId(data: any, type: string): string {
    if (type === 'note') {
      if (data.name) {
        return `sideNotesMenu-button-note-${data.name?.replace(/ /g, '')}`;
      } else if (data.pageReference && data.pageReference === NoteContextEnum.topicSelection) {
        return `sideNotesMenu-button-note-${data.pageReference?.replace(/ /g, '')}`;
      } else if (data.questionGroupName) {
        return `sideNotesMenu-button-note-${data.questionGroupName?.replace(/ /g, '')}`;
      }
      return '';
    } else {
      return `sideNotesMenu-button-document-${data.filename?.replace(/ /g, '')}`;
    }
  }

  public async onOpenNote(note: any, id: string): Promise<void> {
    this.noteService.presetNoteType = note?.type || note?.noteType;
    this.selectedNote = id;
    this.selectedDocument = -1;
    this.documentService.currentDocument.next(undefined);
    if (this.noteService.currentNote && id === this.noteService.currentNote?.getValue()?.id) {
      note.includeInResult !== undefined
        ? this.handleIncludeExcludeNote(note, id, note.includeInResult)
        : this.handleIncludeExcludeNote(note, id, note.noteIncludeInResult);
    } else {
      await this.noteService.getSingleNote(id);
      this.note.emit(note);
    }
  }

  public onOpenDocument(document): void {
    this.selectedDocument = document.id;
    this.selectedNote = '-1';
    this.noteService.currentNote.next(undefined);
    if (this.documentService.currentDocument && document.id === this.documentService.currentDocument?.getValue()?.id) {
      this.handleIncludeExcludeDocument(document);
    } else {
      this.document.emit(document);
    }
  }

  public handleIncludeExcludeNote(note, noteId, includeInResult): void {
    if (includeInResult === true) {
      this.queryService.patchExcludeNote(this.consultationId, noteId).subscribe(() => {
        if (note.includeInResult !== undefined) {
          note.includeInResult = !includeInResult;
        } else {
          note.noteIncludeInResult = !includeInResult;
        }
        this.selected -= 1;
        this.numberOfSelectedElements.emit(this.selected);
      });
    } else {
      this.queryService.patchIncludeNote(this.consultationId, noteId).subscribe(() => {
        if (note.includeInResult !== undefined) {
          note.includeInResult = !includeInResult;
        } else {
          note.noteIncludeInResult = !includeInResult;
        }
        this.selected += 1;
        this.numberOfSelectedElements.emit(this.selected);
      });
    }
  }

  public handleIncludeExcludeDocument(document): void {
    if (document.includeInResult === true) {
      this.queryService.patchExcludeDocument(this.consultationId, document.id).subscribe(() => {
        document.includeInResult = !document.includeInResult;
        this.selected -= 1;
        this.numberOfSelectedElements.emit(this.selected);
      });
    } else {
      this.queryService.patchIncludeDocument(this.consultationId, document.id).subscribe(() => {
        document.includeInResult = !document.includeInResult;
        this.selected += 1;
        this.numberOfSelectedElements.emit(this.selected);
      });
    }
  }
}
