<div class="d-flex flex-column h-100 align-items-center kf-bg">
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100 flex-center">
    <!-- ------- -->
    <!-- CONTENT -->
    <!-- ------- -->
    <div class="d-flex flex-center">
      <img
        style="min-width: 30rem"
        src="{{ assetPath }}/images/logo-bg2-standard.svg"
        alt="BankingGuide 2.0 - Digitale Beratungsmodule"
      />
    </div>
    @if (loginHeader) {
      <kf-headline
        tabindex="-1"
        [headline]="loginHeader"
        [showBackButton]="false"
        [resetTopSpacing]="true"
      ></kf-headline>
    }
    @if (loginMessage) {
      <p class="d-flex flex-center mb-3">
        {{ loginMessage }}
      </p>
    }

    @if (loginState === 'defaultLogin') {
      <ng-template *ngTemplateOutlet="loginFormTemplate; context: { form: loginForm }"></ng-template>
    } @else if (loginState === 'requireNewPassword') {
      <ng-template *ngTemplateOutlet="passwordChangeFormTemplate; context: { form: passwordChangeForm }"></ng-template>
    } @else if (loginState === 'requireResetPassword') {
      <ng-template *ngTemplateOutlet="passwordResetFormTemplate; context: { form: passwordResetForm }"></ng-template>
    }

    <p class="mat-small contact-description-box">
      Bei Interesse an einer Demonstration des BankingGuides wenden Sie sich an Ihre Ansprechpartner der DZ BANK,
      Atruvia, BMS Group oder an
      <a href="mailto:kontakt@bankingguide.de?subject=Demonstration des BankingGuide">kontakt&#64;bankingguide.de</a>
    </p>
    <!-- ------- -->
    <!-- ------- -->
    <!-- ------- -->
  </div>
</div>

<!-- -------------------- LOGIN TEMPLATE -------------------- -->

<ng-template #loginFormTemplate let-form="form">
  <form [formGroup]="form" class="form-width" (ngSubmit)="handleLogin()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" autocomplete="off" formControlName="username" />
        @if (submitted && !!formUsername.errors) {
          <mat-error>
            @if (!!formUsername.errors.required) {
              <kf-input-error [message]="'Benutzername ist notwendig'"> </kf-input-error>
            }
          </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Passwort</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          matInput
          minlength="8"
          autocomplete="off"
          formControlName="password"
          (keydown)="handleKeydown($event)"
        />
        @if (submitted && !!formPassword.errors) {
          <mat-error>
            @if (!!formPassword.errors.required) {
              <kf-input-error [message]="'Passwort ist notwendig'"> </kf-input-error>
            }
            @if (!!formPassword.errors.minlength) {
              <kf-input-error [message]="'Passwort muss mindestens 8 Zeichen enthalten'"> </kf-input-error>
            }
          </mat-error>
        }
        <ng-container>
          @if (!!formPassword.value && showPassword) {
            <button
              (click)="togglePassword()"
              class="infield-button"
              aria-label="Passwort verbergen"
              kfTooltip="Passwort verbergen"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              mat-icon-button
            >
              <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
            </button>
          }

          @if ((!!formPassword.value || formPassword.value === '') && !showPassword) {
            <button
              (click)="togglePassword()"
              class="infield-button"
              iconName="ic_auge_geschlossen_24"
              aria-label="Passwort anzeigen"
              kfTooltip="Passwort anzeigen"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              mat-icon-button
            >
              <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
            </button>
          }
        </ng-container>
      </mat-form-field>
    </div>

    <!-- error message -->
    @if (error) {
      <div class="w-100 alert alert-danger mb-3">{{ error }}</div>
    }

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [attr.data-testcafe]="'loginscreen-button-login'" [disabled]="loading" mat-flat-button color="primary">
        @if (loading) {
          <mat-spinner [diameter]="24" color="primary"></mat-spinner>
        } @else {
          <kf-icon name="ic_anmelden_24"></kf-icon>
        }
        <span>Anmelden</span>
      </button>
    </div>
    <div class="w-100 d-flex justify-content-end mt-3">
      <button mat-button color="primary" (click)="loginState = 'requireResetPassword'; loginMessage = ''">
        <span>Passwort zurücksetzen</span>
      </button>
    </div>
  </form>
</ng-template>

<!-- -------------------- PASSWORD SET TEMPLATE -------------------- -->

<ng-template #passwordChangeFormTemplate let-form="form">
  <p>Bitte ändern Sie ihr temporäres Passwort</p>
  <p class="mat-small mb-4">(mindestens 8 Zeichen)</p>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordChange()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" autocomplete="off" formControlName="username" />
        @if (submitted && !!formUsername.errors) {
          <mat-error>
            @if (!!formUsername.errors.required) {
              <kf-input-error [message]="'Benutzername ist notwendig'"> </kf-input-error>
            }
          </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Neues Passwort</mat-label>
        <input
          matInput
          minlength="8"
          autocomplete="off"
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
        />
        @if (submitted && !!formChangePassword.errors) {
          <mat-error>
            @if (!!formChangePassword.errors.required) {
              <kf-input-error [message]="'Passwort ist notwendig'"> </kf-input-error>
            }
            @if (!!formChangePassword.errors.minlength) {
              <kf-input-error [message]="'Passwort muss mindestens 8 Zeichen enthalten'"> </kf-input-error>
            }
          </mat-error>
        }
        @if (!!formChangePassword.errors) {
          @if (!!formChangePassword.value && showPassword) {
            <button
              (click)="togglePassword()"
              class="infield-button"
              aria-label="Passwort verbergen"
              kfTooltip="Passwort verbergen"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              mat-icon-button
            >
              <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
            </button>
          }
          @if ((!!formChangePassword.value && !showPassword) || (formChangePassword.value === '' && !showPassword)) {
            <button
              (click)="togglePassword()"
              class="infield-button"
              aria-label="Passwort anzeigen"
              kfTooltip="Passwort anzeigen"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              mat-icon-button
            >
              <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
            </button>
          }
        }
      </mat-form-field>
    </div>

    <!-- error message -->
    @if (error) {
      <div class="w-100 alert alert-danger mb-3">{{ error }}</div>
    }

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [disabled]="loading" mat-flat-button color="primary">
        @if (loading) {
          <mat-spinner [diameter]="24" color="primary"></mat-spinner>
        }
        <span>Passwort ändern</span>
      </button>
    </div>
  </form>
</ng-template>

<!-- -------------------- PASSWORD CHANGE TEMPLATE -------------------- -->

<ng-template #passwordResetFormTemplate let-form="form">
  <p class="mb-2">
    Sollten Sie Ihr Passwort vergessen haben wenden, sie sich an das Team BankingGuide<br />um Ihr Passwort
    zurückzusetzen -
    <a href="mailto:pilotierung.bankingguide@bms-consulting.de?subject=Bitte Passwort zurücksetzen"
      >pilotierung.bankingguide&#64;bms-consulting.de</a
    >
  </p>
  <p>
    Falls sie schon einen Verifizierungscode erhalten haben, geben Sie den Verifizierungscode und Ihr neues Passwort
    ein.
  </p>
  <p class="mat-small mb-4">(mindestens 8 Zeichen)</p>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordReset()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" autocomplete="off" formControlName="username" />
        @if (submitted && !!formResetUsername.errors) {
          <mat-error>
            @if (!!formResetUsername.errors.required) {
              <kf-input-error [message]="'Benutzername ist notwendig'"> </kf-input-error>
            }
          </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- code -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Verifizierungscode</mat-label>
        <input type="text" matInput formControlName="code" autocomplete="off" />
        @if (submitted && !!formCode.errors) {
          <mat-error>
            @if (!!formCode.errors.required) {
              <kf-input-error [message]="'Code ist notwendig'"> </kf-input-error>
            }
          </mat-error>
        }
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Passwort</mat-label>
        <input
          matInput
          minlength="8"
          autocomplete="off"
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
        />
        @if (submitted && !!formResetPassword.errors) {
          <mat-error>
            @if (!!formResetPassword.errors.required) {
              <kf-input-error [message]="'Passwort ist notwendig'"> </kf-input-error>
            }
            @if (!!formResetPassword.errors.minlength) {
              <kf-input-error [message]="'Passwort muss mindestens 8 Zeichen enthalten'"> </kf-input-error>
            }
          </mat-error>
        }
      </mat-form-field>

      @if (!!formResetPassword.value && showPassword) {
        <button
          (click)="togglePassword()"
          class="infield-button"
          aria-label="Passwort verbergen"
          kfTooltip="Passwort verbergen"
          kfTooltipPosition="after"
          kfTooltipShow="hover"
          mat-icon-button
        >
          <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
        </button>
      }

      @if ((!!formResetPassword.value || formResetPassword.value === '') && !showPassword) {
        <button
          (click)="togglePassword()"
          class="infield-button"
          aria-label="Passwort anzeigen"
          kfTooltip="Passwort anzeigen"
          kfTooltipPosition="after"
          kfTooltipShow="hover"
          mat-icon-button
        >
          <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
        </button>
      }

      @if (submitted && !!formResetPassword.errors) {
        <div class="invalid-feedback">
          @if (!!formResetPassword.errors.required) {
            <div>Passwort ist notwending</div>
          }
          @if (!!formResetPassword.errors.minlength) {
            <div>Passwort muss mindestens 8 Zeichen enthalten</div>
          }
        </div>
      }
    </div>

    <!-- error message -->
    @if (error) {
      <div class="w-100 alert alert-danger mb-3">{{ error }}</div>
    }

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [disabled]="loading" mat-flat-button color="primary">
        @if (loading) {
          <mat-spinner [diameter]="24" color="primary"></mat-spinner>
        }
        <span>Passwort erneuern</span>
      </button>
    </div>
    <div class="w-100 d-flex justify-content-end mt-3">
      <button mat-button color="primary" (click)="loginState = 'defaultLogin'; loginMessage = ''">
        <span>Zum Login</span>
      </button>
    </div>
  </form>
</ng-template>
