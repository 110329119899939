import { MediaTypeEnum } from '@enums';

export function getDocumentTypeAsText(documentType: MediaTypeEnum | ''): string {
  let type = 'Dokument';

  switch (documentType) {
    case MediaTypeEnum.image:
      type = 'Bild';
      break;

    case MediaTypeEnum.pdf:
      type = 'PDF';
      break;

    case MediaTypeEnum.video:
      type = 'Video';
      break;

    case MediaTypeEnum.link:
      type = 'Link';
      break;

    case MediaTypeEnum.docImage:
      type = 'Bild';
      break;

    case MediaTypeEnum.logo:
      type = 'Logo';
      break;
  }

  return `(${type})`;
}

export function getDocumentIcon(
  documentType: MediaTypeEnum | '',
  size?: 12 | 16 | 24 | 32 | 36 | 40 | 44 | 48 | 64 | 96
): string {
  let iconSize = size || 24;
  let icon = 'ic_datei';

  switch (documentType) {
    case MediaTypeEnum.image:
      icon = 'ic_annotation_bild';
      break;

    case MediaTypeEnum.pdf:
      icon = 'ic_pdf';
      break;

    case MediaTypeEnum.video:
      icon = 'ic_abspielen_im_kreis';
      break;

    case MediaTypeEnum.link:
      icon = 'ic_verknuepfung';
      break;

    case MediaTypeEnum.docImage:
      icon = 'ic_annotation_bild';
      break;

    case MediaTypeEnum.logo:
      icon = 'ic_placeholder';
      break;
  }

  return `${icon}_${iconSize}`;
}
