import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor() {}

  // field error handling
  public getFieldErrorMessage(field: FormControl, name?: string) {
    let errorMessage = '';

    if (field.hasError('required')) {
      switch (name.toLowerCase()) {
        case 'email':
          errorMessage = 'E-Mail-Adresse wird benötigt.';
          break;
        case 'firstname':
          errorMessage = 'Vorname wird benötigt.';
          break;
        case 'lastname':
          errorMessage = 'Nachname wird benötigt.';
          break;
        case 'name':
          errorMessage = 'Titel wird benötigt.';
          break;
        case 'corporationname':
          errorMessage = 'Name des Unternehmens wird benötigt.';
          break;
        case 'account':
          errorMessage = 'Kunden-Nr. wird benötigt.';
          break;
        case 'userid':
          errorMessage = 'Name des Beraters wird benötigt.';
          break;
        case 'customercontact':
          errorMessage = 'Name des Ansprechpartners wird benötigt.';
          break;
        default:
          errorMessage = 'Angabe wird benötigt.';
          break;
      }
    } else if (field.hasError('pattern') && name.toLowerCase() === 'email') {
      errorMessage = 'Keine gültige E-Mail-Adresse.';
    } else if (field.hasError('pattern')) {
      errorMessage = 'Ungültiges Eingabeformat.';
    }

    return errorMessage;
  }
}
