import { Injectable } from '@angular/core';
import { SearchOverlay } from '@domain/app/search-overlay';
import { QuestionGroupOverviewItem, SubtopicOverviewItem, TopicOverviewItem } from '@domain/app/topic.domain';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import GetCompositionSearchResponse = SearchOverlay.GetCompositionSearchResponse;

export interface QuestionWorldData {
  topics: TopicOverviewItem[];
  isQuestionGroupInSearchResult: (questionGroup: QuestionGroupOverviewItem) => boolean;
  topicHasSearchResults: (topic: TopicOverviewItem) => boolean;
  subtopicHasSearchResults: (subtopic: SubtopicOverviewItem) => boolean;
}

export type ProductWorldData = Array<ProductWorldItem>;

export interface ProductWorldItem {
  compositionId: number;
  compositionName: string;
  id: number;
  mainName: string;
  name: string;
  type: 'task' | 'transition' | 'product';
}

@Injectable()
export class SearchService {
  private readonly _searchQuery = new BehaviorSubject<string | undefined>(undefined);
  private readonly _getTopicsResponse = new BehaviorSubject<TopicOverviewItem[] | undefined>(undefined);
  private readonly _getCompositionResponse = new BehaviorSubject<GetCompositionSearchResponse | undefined>(undefined);

  public searchQueryValue = '';
  public selectionType: 'selected' | 'available' | 'all' = 'selected';

  constructor(
    private readonly queryService: QueryService,
    private readonly clientService: ClientService
  ) {}

  fetchData(): Observable<TopicOverviewItem[]> {
    return forkJoin([
      this.queryService.getSearchOverlayTopics(this.clientService.consultationId),
      this.queryService.getSearchOverlayCompositions(this.clientService.consultationId),
    ]).pipe(
      tap(([topics, compositions]) => {
        this._getTopicsResponse.next(topics);
        this._getCompositionResponse.next(compositions as GetCompositionSearchResponse);
      }),
      map(x => x[0])
    );
  }

  get questionWorldData(): Observable<QuestionWorldData> {
    return combineLatest([this._getTopicsResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.getTopicsResponseToQuestionWorldData(response, query);
      })
    );
  }

  get productWorldData(): Observable<ProductWorldData> {
    return combineLatest([this._getCompositionResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.getCompositionResponseToProductWorldData(response, query || 'favorite');
      })
    );
  }

  set searchQuery(value: string | undefined) {
    this._searchQuery.next(value.trim());
    this.searchQueryValue = value.trim();
  }

  private getTopicsResponseToQuestionWorldData(
    response: TopicOverviewItem[],
    query: string | undefined
  ): QuestionWorldData {
    this.searchQueryValue = query;
    if (!query) {
      return {
        topics: response.filter(topic => {
          if (this.selectionType === 'selected') {
            return topic.selected;
          } else if (this.selectionType === 'available') {
            return !topic.selected;
          }
          return topic;
        }),
        isQuestionGroupInSearchResult: () => true,
        topicHasSearchResults: () => true,
        subtopicHasSearchResults: () => true,
      };
    }

    const isQuestionGroupInSearchResult = (questionGroup: QuestionGroupOverviewItem) =>
      this.questionGroupAppliesToSearchQuery(questionGroup, query);

    const subtopicHasSearchResults = (subtopic: SubtopicOverviewItem) =>
      subtopic.title.toLowerCase().includes(query.toLowerCase()) ||
      subtopic.questionGroups.some(isQuestionGroupInSearchResult);

    const topicHasSearchResults = (topic: TopicOverviewItem) =>
      topic.title.toLowerCase().includes(query.toLowerCase()) || topic.subtopics.some(subtopicHasSearchResults);

    return {
      topics: response,
      isQuestionGroupInSearchResult,
      topicHasSearchResults,
      subtopicHasSearchResults,
    };
  }

  private questionGroupAppliesToSearchQuery(questionGroup: QuestionGroupOverviewItem, query: string): boolean {
    const tags = questionGroup.tags?.some(tag => this.tagAppliesToSearchQuery(tag, query));
    const name = this.nameAppliesToSearchQuery(questionGroup.title, query);
    return tags || name;
  }

  private getCompositionResponseToProductWorldData(
    response: GetCompositionSearchResponse,
    query: string | undefined
  ): ProductWorldData {
    const toCompositionElements = (composition: GetCompositionSearchResponse[0]) =>
      [
        composition.mainProduct && composition.mainProduct.variants.length === 0
          ? {
              ...composition.mainProduct,
              type: 'product',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              mainProductName: composition.mainProduct.name,
              id: composition.mainProduct.id,
            }
          : undefined,
        ...(composition.mainProduct && composition.mainProduct.variants.length > 0
          ? composition.mainProduct.variants.map(variant => ({
              ...variant,
              type: 'product',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              mainProductName: composition.mainProduct.name,
              id: variant.id,
            }))
          : []),
        ...(composition.transitions.length > 0
          ? composition.transitions.map(transition => ({
              ...transition,
              type: 'transition',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              mainProductName: composition.mainProduct?.name || composition.name,
              id: transition.id,
            }))
          : []),
        ...(composition.tasks?.length > 0
          ? composition.tasks?.map(task => ({
              ...task,
              type: 'task',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              mainProductName: composition.mainProduct?.name || composition.name,
              id: task.id,
            }))
          : []),
      ].filter(element => !!element);

    const appliesToSearchQuery = (compositionElement): boolean => {
      if (!query) {
        return compositionElement.tags ? compositionElement.tags.indexOf('favourite') !== -1 : false;
      }
      return (
        (compositionElement.tags && compositionElement.tags.some(tag => this.tagAppliesToSearchQuery(tag, query))) ||
        this.nameAppliesToSearchQuery(compositionElement.name, query) ||
        this.nameAppliesToSearchQuery(compositionElement.compositionName, query)
      );
    };

    return response
      .map(composition => toCompositionElements(composition))
      .flatMap(compositionElements => compositionElements)
      .filter(compositionElement => appliesToSearchQuery(compositionElement))
      .map(compositionElement => ({
        name: compositionElement.name,
        type: compositionElement.type as ProductWorldData[0]['type'],
        id: compositionElement.id,
        mainName: compositionElement.mainProductName,
        compositionId: compositionElement.compositionId,
        compositionName: compositionElement.compositionName,
      }));
  }

  private nameAppliesToSearchQuery(name: string, query: string) {
    return name.toLowerCase().includes(query.toLowerCase());
  }

  private tagAppliesToSearchQuery(tag: string, query: string) {
    return tag.toLowerCase().includes(query.toLowerCase());
  }
}
