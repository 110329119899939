import { CdkDropList } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeselectProductItem, SelectProductItem } from '@domain/app/product.domain';
import { QuestionGroupResponse, QuestionItem } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse, RecommendationItem } from '@domain/app/recommendation.domain';
import { DeselectTaskItem, SelectTaskItem } from '@domain/app/task.domain';
import { DeselectTransitionItem, SelectTransitionItem } from '@domain/app/transition.domain';
import { ConsultationStatusEnum, QuestionEnum, RecommendationTypeEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const sortCriteria = {
  MAIN_PRODUCT: 1,
  VARIANT_PRODUCT: 2,
  TASK: 3,
  TRANSITION: 4,
  ADDITIONAL_PRODUCT: 5,
};

@Component({
  selector: 'side-recommendations',
  templateUrl: './side-recommendations.component.html',
  styleUrls: ['./side-recommendations.component.scss'],
})
export class SideRecommendationsComponent implements OnInit {
  @Input() collectionId: string;
  @Input() recommendationGroupData: QuestionGroupRecommendationResponse;
  @Input() questionGroupData: QuestionGroupResponse;
  @Input() sideOpen: boolean;

  @Output() closed = new EventEmitter(null);

  public recommendationsAvailable = false;

  public questionData: QuestionItem[];
  public other: CdkDropList<RecommendationItem[]>;
  public selected: CdkDropList<RecommendationItem[]>;
  public recommended: CdkDropList<RecommendationItem[]>;
  public requestIsPending = false;
  public isLoading = false;

  public isInPrep = false;

  private destroySubs = new Subject<void>();
  private selectedAmount = -1;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly recommendationTypeEnum = RecommendationTypeEnum;

  constructor(
    private chg: ChangeDetectorRef,
    private queryService: QueryService,
    private clientService: ClientService,
    private actionService: ActionService,
    private rightSidenavService: RightSidenavService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action?.target === 'side-recommendation') {
        if (action?.action === 'get-recommendation') {
          this.requestRecommendedItems(this.collectionId);
        }
        if (action?.action === 'close') {
          this.sideOpen = false;
          this.requestRecommendedItems(this.collectionId);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.collectionId) {
      this.collectionId = changes.collectionId.currentValue;
      this.selectedAmount = -1;
      this.requestRecommendedItems(this.collectionId);
    }
  }

  public onRecDeselected(item: RecommendationItem) {
    switch (item.type) {
      case RecommendationTypeEnum.task: {
        this.deselectTask(item);
        break;
      }
      case RecommendationTypeEnum.transition: {
        this.deselectTransition(item);
        break;
      }
      default: {
        this.deselectProduct(item);
      }
    }

    this.clientService.numSolutions -= 1;
    this.recommendationGroupData.selected = this.recommendationGroupData.selected.filter(x => x !== item);
    if (item.recommended) {
      this.recommendationGroupData.recommended.push(item);
    } else {
      this.recommendationGroupData.other.push(item);
    }
  }

  public onRecSelected(item: RecommendationItem) {
    switch (item.type) {
      case RecommendationTypeEnum.task: {
        this.selectTask(item);
        break;
      }
      case RecommendationTypeEnum.transition: {
        this.selectTransition(item);
        break;
      }
      default: {
        this.selectProduct(item);
      }
    }

    this.clientService.numSolutions += 1;
    this.recommendationGroupData.recommended = this.recommendationGroupData.recommended.filter(x => x !== item);
    this.recommendationGroupData.selected.push(item);
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public openIndividualTasks(): void {
    this.sideOpen
      ? this.doAction('recommendation-extra', 'task-indiv')
      : this.doAction('main', 'task-indiv', { taskId: '-1', subtopicId: this.subtopicId });
  }

  public openIndividualTransitions(): void {
    this.sideOpen
      ? this.doAction('recommendation-extra', 'transition-indiv')
      : this.doAction('main', 'transition-indiv', { transitionId: '-1', subtopicId: this.subtopicId });
  }

  public openExtra(): void {
    this.doAction('main', 'recommendations', {
      recommendationGroupData: this.recommendationGroupData,
      questionGroupData: this.questionGroupData,
    });
  }

  public compareAccountsButtonText(): string | undefined {
    return this.questionGroupData?.questions.find(
      question =>
        question.type === QuestionEnum.variantCalculator &&
        (question.showQuestion === undefined || question.showQuestion)
    )?.name;
  }

  public openCompareAccounts(): void {
    this.actionService
      .setAction({
        target: 'overlay-main',
        action: 'compare-accounts',
        options: { accountType: this.questionGroupData.typeAdditionalInfo, subtopicId: this.subtopicId },
      })
      .then(() => {});
  }

  public showRecommendationDetails(item: RecommendationItem, selected: boolean, target?: string) {
    if (this.sideOpen) {
      if (item.type.includes(RecommendationTypeEnum.product)) {
        this.doAction('recommendation-extra', 'product-details', {
          compositionId: item.compositionId,
          productId: item.id,
          sideOpen: true,
        });
      } else if (item.type === RecommendationTypeEnum.task) {
        this.doAction('recommendation-extra', 'task-details', {
          compositionId: item.compositionId,
          compositionTitle: item.name,
          taskId: item.id,
          target: target,
          selected: selected,
          subtopicId: this.subtopicId,
          sideOpen: true,
        });
      } else if (item.type === RecommendationTypeEnum.transition) {
        this.doAction('recommendation-extra', 'transition-details', {
          compositionId: item.compositionId,
          compositionTitle: item.name,
          transitionId: item.id,
          target: target,
          selected: selected,
          subtopicId: this.subtopicId,
          sideOpen: true,
        });
      }
    } else {
      if (item.type.includes(RecommendationTypeEnum.product)) {
        this.doAction('main', 'product', { compositionId: item.compositionId, productId: item.id });
      } else if (item.type === RecommendationTypeEnum.task) {
        this.doAction('main', 'task', {
          compositionId: item.compositionId,
          compositionTitle: item.name,
          taskId: item.id,
          target: target,
          selected: selected,
          subtopicId: this.subtopicId,
        });
      } else if (item.type === RecommendationTypeEnum.transition) {
        this.doAction('main', 'transition', {
          compositionId: item.compositionId,
          compositionTitle: item.name,
          transitionId: item.id,
          target: target,
          selected: selected,
          subtopicId: this.subtopicId,
        });
      }
    }
  }

  public handleClose() {
    this.closed.emit();
    this.rightSidenavService.close();
  }

  private requestRecommendedItems(questionGroupId): void {
    this.queryService.getRecommendedProducts(this.clientService.consultationId, questionGroupId).subscribe(recData => {
      if (this.configService.autoAddRec) {
        if (this.selectedAmount === -1) {
          this.selectedAmount = recData.selected.length;
        }
        if (recData.selected.length !== this.selectedAmount) {
          const diff = recData.selected.length - this.selectedAmount;
          this.selectedAmount = recData.selected.length;
          this.clientService.numSolutions += diff;
        }
      }

      this.recommendationGroupData = recData;
      this.recommendationsAvailable = this.recommendationGroupData.recommended.length > 0;

      // eslint-disable-next-line guard-for-in
      for (const key in this.recommendationGroupData) {
        for (const recItem of this.recommendationGroupData[key]) {
          recItem.origin = key;
        }
      }

      this.chg.detectChanges();
      if (this.recommendationsAvailable) {
        this.doAction('consultation', 'recommendations-available');
      } else {
        this.doAction('consultation', 'recommendations-unavailable');
      }
    });
  }

  private selectProduct(item: RecommendationItem): void {
    const sendObj = {} as SelectProductItem;
    sendObj.compositionId = item.compositionId;
    sendObj.productId = item.id;
    sendObj.subtopicId = this.subtopicId;
    sendObj.quantity = 1;

    this.queryService.putSelectProduct(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectProduct(item: RecommendationItem): void {
    const sendObj = {} as DeselectProductItem;
    sendObj.compositionId = item.compositionId;
    sendObj.productId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectProduct(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private selectTask(item: RecommendationItem): void {
    const sendObj = {} as SelectTaskItem;
    sendObj.compositionId = item.compositionId;
    sendObj.taskId = item.id;
    sendObj.subtopicId = this.subtopicId;

    this.queryService.putSelectTask(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectTask(item: RecommendationItem): void {
    const sendObj = {} as DeselectTaskItem;
    sendObj.compositionId = item.compositionId;
    sendObj.taskId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectTask(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private selectTransition(item: RecommendationItem): void {
    const sendObj = {} as SelectTransitionItem;
    sendObj.compositionId = item.compositionId;
    sendObj.transitionId = item.id;
    sendObj.subtopicId = this.subtopicId;

    this.queryService.putSelectTransition(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectTransition(item: RecommendationItem): void {
    const sendObj = {} as DeselectTransitionItem;
    sendObj.compositionId = item.compositionId;
    sendObj.transitionId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectTransition(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  get recDataSelected() {
    return this.recommendationGroupData?.selected || [];
  }
  get isSelectedEmpty() {
    return this.recDataSelected.length === 0;
  }
  get recDataRecommended() {
    return this.recommendationGroupData?.recommended || [];
  }
  get isRecommendedEmpty() {
    return this.recDataRecommended.length === 0;
  }
  get recDataOther() {
    return this.recommendationGroupData.other || [];
  }
  get subtopicId() {
    return this.questionGroupData?.subtopicId;
  }

  get addToPrepDisabledState(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone ||
      this.questionGroupData?.availableForPreliminary === false
    );
  }

  get questionCollectionId() {
    return this.questionGroupData.collectionId;
  }
}
