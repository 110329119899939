@switch (group.template) {
  @case ('PLAIN') {
    @for (section of group.dataFieldSections; track section; let last = $last) {
      <div class="data-field" [class.divider]="!last">
        @for (dataField of section.dataFields; track dataField) {
          <strong>{{ dataField.name }}: </strong>
          <span [attr.data-testcafe]="getTestCafeId(dataField.name)" handleENumbers markNegativePrice>
            {{ getValue(dataField) }}
          </span>
        }
      </div>
    }
  }

  @case ('TABLE') {
    <table [attr.data-testcafe]="getTestCafeId(group.groupName)">
      <tr>
        @for (heading of headerRow; track heading) {
          <th>{{ heading }}</th>
        }
      </tr>
      @for (section of group.dataFieldSections; track section; let idx = $index) {
        <tr>
          @for (dataField of section.dataFields; track dataField) {
            <td markNegativePrice [attr.data-testcafe]="getTestCafeDetails(dataField, idx)">
              {{ getValue(dataField) }}
            </td>
          }
        </tr>
      }
    </table>
  }

  @case ('LIST') {
    @for (section of group.dataFieldSections; track section; let last = $last) {
      <ul [class.divider]="!last">
        @for (dataField of section.dataFields; track dataField) {
          <li markNegativePrice>{{ getValue(dataField) }}</li>
        }
      </ul>
    }
  }
}
