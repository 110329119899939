import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import {
  CheckoutDataFieldCompositionItem,
  CheckoutDataFieldGroupItem,
  CheckoutDataFieldItem,
} from '@domain/app/checkout.domain';
import { ContractStatusResponseItem } from '@domain/app/consultation.domain';
import { ProductResponse } from '@domain/app/product.domain';
import { ContractPreviewStateEnum, ContractStatusEnum, DataFieldElementTypeEnum, DataFieldTypeEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContractService } from '@services/contract-service/contract.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { isEmpty, isEqual } from 'lodash-es';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface DataFieldFormValueChange {
  item?: CheckoutDataFieldItem;
  changedValue?: number | string | boolean;
  valid: boolean;
}

interface ItemDatafieldFormGroup {
  [propName: string]: FormControl<ItemDatafieldType>;
}

type ItemDatafieldFormArray = FormArray<FormGroup<ItemDatafieldFormGroup>>;
type ItemDatafieldType = number | string | boolean | Date | null;

@Component({
  selector: 'item-datafield-form',
  templateUrl: './item-datafield-form.component.html',
  styleUrls: ['./item-datafield-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ItemDatafieldFormComponent implements OnInit, OnChanges {
  @Input() public set dataFieldData(value: CheckoutDataFieldCompositionItem) {
    if (value) {
      this._dataFieldData = value;
      this.createFormGroup();
      this.chg.detectChanges();
      this._dataFieldGroupsMultiplied = this.dataFieldData?.dataFieldGroups.filter(x => x.multiplied);
      this.productHasContract = this.dataFieldData?.dataFieldGroups.some(x => x.hasContractForm);
      this.setMultipliedDataFieldGroupsIndices(this.ordinals);
    }
  }

  @Input() productData: ProductResponse;
  @Input() dataFieldElementType: DataFieldElementTypeEnum = null;
  @Input() disableFields: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() inCheckout: boolean = false;
  @Input() hasError: boolean = false;
  @Output() valueChanged = new EventEmitter<DataFieldFormValueChange>();
  @Output() dataFieldAdded = new EventEmitter<CheckoutDataFieldGroupItem>();
  @Output() dataFieldRemoved = new EventEmitter<CheckoutDataFieldGroupItem>();

  public dataFieldFormArray: ItemDatafieldFormArray;
  public consultationId = this.clientService.consultationId;
  public isLoading = null;
  public productStatus: ContractStatusResponseItem[] = [];
  public isPolling: { ordinal: number; status: ContractStatusEnum }[] = [];
  public productHasContract: boolean = false;
  public multipliedDataFieldGroupsIndices: Map<number, number[]> = new Map();

  private destroySubs = new Subject<void>();
  private _dataFieldData: CheckoutDataFieldCompositionItem;
  private panelOpen: Array<boolean> = [];
  private _dataFieldsByType: Map<string, Set<string>> = new Map();
  private _dataFieldsToDisable: Set<string> = new Set();
  private _dataFieldGroupsMultiplied: CheckoutDataFieldGroupItem[] = [];

  readonly dataFieldType = DataFieldTypeEnum;
  readonly contractStatusEnum = ContractStatusEnum;

  constructor(
    private chg: ChangeDetectorRef,
    private queryService: QueryService,
    private clientService: ClientService,
    private loadingService: LoadingService,
    private actionService: ActionService,
    private contractService: ContractService,
    private snackBarService: SnackBarTemplatesService,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      if (loading !== this.isLoading) {
        this.isLoading = loading;
        this.chg.detectChanges();
      }
    });

    this.contractService.putRequested.pipe(takeUntil(this.destroySubs)).subscribe(async response => {
      await this.contractService
        .getSingleProductContractData(
          this.clientService.consultationId,
          this.dataFieldData?.dataFieldGroups,
          this.productData
        )
        .then(data => (this.productStatus = data));

      this.chg.detectChanges();
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.dataFieldData) {
      this.dataFieldData = changes.dataFieldData.currentValue;

      if (this.productHasContract) {
        this.productStatus = await this.contractService.getSingleProductContractData(
          this.clientService.consultationId,
          this.dataFieldData.dataFieldGroups,
          this.productData
        );
      }

      this.productStatus.find(x => {
        if (this.getLowestContractsStatus(x, x.ordinal) === ContractStatusEnum.generatePreview) {
          this.startContractStatusPolling(this.productData);
        }
        if (this.getLowestContractsStatus(x, x.ordinal) === ContractStatusEnum.error) {
          // if there's an error, we alert the user
          this.snackBarService.openSnackBar({
            type: SnackbarType.ALERT,
            message: 'Fehler bei der Erstellung der Vertragsdokumente.',
          });
        }
      });
    }

    if (changes.disableFields) {
      this._dataFieldsToDisable.forEach(dataField => {
        this.dataFieldFormArray.controls.forEach(formGroup =>
          this.disableFields ? formGroup.get(dataField)?.disable() : formGroup.get(dataField)?.enable()
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (!this.inCheckout && this.contractService.pollingActive) {
      this.contractService._stopPolling();
    }
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  // --------------------------------------------- //

  // needed to parse the jsonAnswerOptions of some dataFields
  public optionDataFactory(item?: CheckoutDataFieldItem): string[] {
    const options = [];
    if (item.jsonAnswerOptions) {
      for (let option in JSON.parse(item.jsonAnswerOptions)) {
        options.push(JSON.parse(item.jsonAnswerOptions)[option]);
      }
    }
    return options;
  }

  public async handleSelectionChange(event: MatSelectChange, item: CheckoutDataFieldItem): Promise<void> {
    const changedValue = event.value;

    this.handleValueChange(changedValue, item);
  }

  public async handleValueChange(changedValue: number | string | boolean, item: CheckoutDataFieldItem): Promise<void> {
    if (item.dataFieldType === DataFieldTypeEnum.date) {
      changedValue = this.formValidationService.formatDate(changedValue as string);
    }
    const valid = this.isValidForm;
    this.valueChanged.emit({ changedValue, item, valid });
  }

  /** create Date object from date string */
  public toDate(value: ItemDatafieldType): Date {
    if (value && typeof value === 'string') {
      return new Date(value);
    } else {
      return null;
    }
  }

  /** format date from YYYY-MM-DD to DD.MM.YYYY */
  public formatDate(value: string): string {
    if (!value) return 'Kein Datum verfügbar';
    return moment(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
  }

  public getFormGroup(index): FormGroup<ItemDatafieldFormGroup> {
    return this.dataFieldFormArray.controls[index] as FormGroup<ItemDatafieldFormGroup>;
  }

  public onAddDataField(group: CheckoutDataFieldGroupItem): void {
    this.dataFieldAdded.emit(group);
  }

  public onRemoveDataField(group: CheckoutDataFieldGroupItem): void {
    this.dataFieldRemoved.emit(group);
  }

  public getTestcafeLabel(group: any, item: any) {
    const groupName = group.name?.replace(/ /g, '');
    const groupId = group.dataFieldGroupId;
    const itemName = item.name?.replace(/ /g, '');
    const itemId = item.dataFieldValueId;
    return `dataFieldGroup-${groupName}-${groupId}-dataField-${itemName}-${itemId}`;
  }

  public generateContractPreview(product: ProductResponse, group: CheckoutDataFieldGroupItem): void {
    if (!(this.isLoading || this.groupHasError(group) || this.contractPolling(group))) {
      this.contractService.pollingMap.set(group, product);
      const item = this.contractService.generateSingleStatusRequestItem(product, group);
      this.contractService.generateContractPreview(item);
      this.startContractStatusPolling(product, group);
    }
  }

  public startContractStatusPolling(product: ProductResponse, group?: CheckoutDataFieldGroupItem) {
    this.contractService.startContractStatusPolling();

    //needed to empty data, so buttons are disabled every time and not just the first time
    this.contractService.pollingResult.next([]);

    this.contractService.pollingResult.subscribe(data => {
      this.productStatus = data;
      let contractItems = data?.find(x => x.elementId === product.id);
      if (contractItems && isEmpty(contractItems)) {
        let index = this.isPolling.findIndex(x => x.ordinal === contractItems.ordinal);

        if (index && this.isPolling[index]?.status) {
          this.isPolling[index].status = this.contractService.getLowestContractStatus(contractItems?.contracts);
        } else {
          this.isPolling.push({
            ordinal: contractItems[0].ordinal,
            status: this.contractService.getLowestContractStatus(contractItems?.contracts),
          });
        }
        if (this.isPolling.every(x => x?.status !== ContractStatusEnum.generatePreview)) {
          this.isPolling = [];
        }
      }
      if (
        data.find(x => x.elementId === product.id && x.ordinal === group.ordinal)?.contracts[0].status ===
        ContractStatusEnum.preview
      ) {
        this.contractService.pollingMap.delete(group);
      }

      this.chg.detectChanges();
    });
    if (this.inCheckout) {
      // Start polling on checkout
      this.doAction('checkout', 'poll-contract-status');
    }
  }

  public getIsProductInstancePollingFinished(item): boolean {
    if (isEmpty(this.isPolling)) {
      return true;
    }
    return this.isPolling?.find(x => x.ordinal === item.ordinal)?.status === ContractStatusEnum.preview;
  }

  public mandatoryDataFieldsIncomplete(index: number): boolean {
    let invalid = true;
    const fields = this.dataFieldData.dataFieldGroups[index].dataFields.filter(x => x.mandatory) || [];

    if (fields.length === 0) {
      return false;
    }

    for (let field of fields) {
      invalid = !this.getFormGroup(index).get(field.dataFieldValueId).valid;
      if (invalid) {
        break;
      }
    }
    return invalid;
  }

  public getDataFieldsComplete(group: CheckoutDataFieldGroupItem): boolean {
    return this.productStatus?.find(x => x?.ordinal === group.ordinal)?.dataFieldsComplete || false;
  }

  public getUpdateNeeded(groups: CheckoutDataFieldGroupItem[]): boolean {
    let updateNeeded = false;
    for (let group of groups) {
      if (this.productStatus?.find(x => x?.ordinal === group.ordinal)?.updateNeeded) {
        updateNeeded = true;
        break;
      }
    }
    return updateNeeded;
  }

  public openContractPreviews(elementId: string, group: any): void {
    if (
      this.getLowestContractsStatus({ id: elementId }, group.ordinal) !==
      (this.contractStatusEnum.error || this.contractStatusEnum.none || this.contractStatusEnum.generatePreview)
    ) {
      const documentIds = this.productStatus
        .filter(x => x.ordinal === group.ordinal && x.elementId === elementId)
        .flatMap(x => x.contracts)
        .map(x => x.documentId);
      this.contractService.openContractPDF(documentIds, this.clientService.customerId);
    } else {
      console.log('%c [bgzv-frontend-main] PREVIEWS HAVE NOT BEEN GENERATED', 'color: #0066cc');
    }
  }

  public getHasContractForm(item): boolean {
    return item?.hasContractForm || false;
  }

  public getContractIncluded(group: CheckoutDataFieldGroupItem): boolean {
    return group.contractIncluded || false;
  }

  public async toggleIncludeExcludeContract(
    checked: boolean,
    group: CheckoutDataFieldGroupItem,
    product: ProductResponse
  ) {
    const _product = this.contractService.generateSingleStatusRequestItem(product, group);
    const groupIndex = this.dataFieldData?.dataFieldGroups.findIndex(x => isEqual(x, group));

    if (checked && !group.contractIncluded) {
      this.queryService.putIncludeContract(this.clientService.consultationId, _product).subscribe(x => {
        this.queryService.getContractStatus(this.clientService.consultationId).subscribe(data => {
          this.productStatus = data;
        });
      });
      this.dataFieldData.dataFieldGroups[groupIndex].contractIncluded = true;
    } else if (!checked && group.contractIncluded) {
      this.queryService.putExcludeContract(this.clientService.consultationId, _product).subscribe(x => {
        this.queryService.getContractStatus(this.clientService.consultationId).subscribe(data => {
          this.productStatus = data;
        });
      });
      this.dataFieldData.dataFieldGroups[groupIndex].contractIncluded = false;
    }
    this.chg.detectChanges();
  }

  public getLowestContractsStatus(item: ProductResponse, ordinal: number): ContractStatusEnum {
    return this.contractService.getLowestContractStatus(
      this.productStatus?.find(x => x?.elementId === item.id && x?.ordinal === ordinal)?.contracts
    );
  }

  public showContractCheckbox(group: CheckoutDataFieldGroupItem, item: ProductResponse): boolean {
    return group?.hasContractForm;
  }

  public getContract(i: number) {
    i = i === 0 ? 1 : i;
    return this.dataFieldData?.dataFieldGroups.filter(x => x.ordinal === i && x.multiplied && x.hasContractForm) || [];
  }

  public getIndex(item: CheckoutDataFieldGroupItem): number {
    return this.dataFieldData?.dataFieldGroups.findIndex(x => x === item);
  }

  public getContractPreviewState(
    product: ProductResponse,
    group: CheckoutDataFieldGroupItem
  ): ContractPreviewStateEnum {
    const status = this.getLowestContractsStatus(product, group.ordinal);
    if (
      status !== ContractStatusEnum.generatePreview &&
      status !== ContractStatusEnum.error &&
      status !== ContractStatusEnum.preview &&
      !this.contractPolling(group)
    ) {
      return ContractPreviewStateEnum.available;
    }
    if (
      this.getLowestContractsStatus(product, group.ordinal) === ContractStatusEnum.generatePreview ||
      !this.getIsProductInstancePollingFinished(group) ||
      this.contractPolling(group)
    ) {
      return ContractPreviewStateEnum.in_process;
    }
    if (
      this.getLowestContractsStatus(product, group.ordinal) === ContractStatusEnum.preview &&
      this.getIsProductInstancePollingFinished(group)
    ) {
      return ContractPreviewStateEnum.finished;
    }
    return ContractPreviewStateEnum.unknown;
  }

  public disabled(groups: CheckoutDataFieldGroupItem[]) {
    for (let group of groups) {
      if (this.isLoading || this.groupHasError(group) || this.contractPolling(group)) {
        return true;
      }
    }

    return false;
  }

  /** check for hidden field in group */
  public hasVisibleFields(fields: CheckoutDataFieldItem[]): boolean {
    return !!fields?.find(x => x.dataFieldType !== DataFieldTypeEnum.hidden);
  }

  /** provide min date for date picker if defined in validation */
  public getMinDate(item: CheckoutDataFieldItem): Date {
    return this.formValidationService.getMinDate(item);
  }

  /** get the field's value; date needs formatting  */
  public getValue(formGroup: FormGroup, item: CheckoutDataFieldItem) {
    const isDate = item.dataFieldType === DataFieldTypeEnum.date;
    const value = formGroup.value[item.dataFieldValueId];
    return isDate ? this.formatDate(value) : value;
  }

  /** check field type is number or price */
  public isNumber(item: CheckoutDataFieldItem): boolean {
    const type = item.dataFieldType as DataFieldTypeEnum;
    const { number, price } = DataFieldTypeEnum;
    return [number, price].includes(type);
  }

  /**
   * check field type is readonly
   * applicable for text, longText, number, date, price
   */
  public isReadonly(item: CheckoutDataFieldItem): boolean {
    const type = item.dataFieldType as DataFieldTypeEnum;
    const { text, longText, number, date, price } = DataFieldTypeEnum;
    return [text, longText, number, date, price].includes(type) && item.readonly;
  }

  public get pollingActive(): boolean {
    return this.contractService.pollingActive;
  }

  public get dataFieldData(): CheckoutDataFieldCompositionItem {
    return this._dataFieldData;
  }

  public get showAddDataFieldButton(): boolean {
    const groups = this.dataFieldData?.dataFieldGroups;
    return !!groups[0].multiplied && this.dataFieldElementType === DataFieldElementTypeEnum.tasks;
  }

  public get showRemoveDataFieldButton(): boolean {
    const groups = this.dataFieldData?.dataFieldGroups;
    return !!groups[0].multiplied && groups.length >= 2 && this.dataFieldElementType === DataFieldElementTypeEnum.tasks;
  }

  public get isValidForm(): boolean {
    return this.dataFieldFormArray?.valid || false;
  }

  public get allFieldsFilledAndValid(): boolean {
    const v = this.dataFieldFormArray?.value.reduce((a, b) => {
      const q = Object.keys(b).some(x => b[x] === null || b[x] === '');
      q && a.push(q);
      return a;
    }, []);

    return v.length === 0;
  }

  public get productHasMultipliedFields(): boolean {
    return this._dataFieldGroupsMultiplied.length > 0;
  }

  public get ordinals(): Set<number> {
    let result = new Set<number>();
    this._dataFieldGroupsMultiplied.forEach(x => result.add(x.ordinal));
    return result;
  }

  /**
   * Map indices of dataFieldGroups to corresponding ordinal / contract.
   * These indices also correspond to indices of dataFieldFormArray controls.
   *
   * @param ordinal - ordinal specifying the contract
   * @returns list of indices of dataFieldGroups
   */
  public setMultipliedDataFieldGroupsIndices(ordinals: Set<number>): void {
    let result = new Map<number, number[]>();
    for (let ordinal of ordinals) {
      if (this.dataFieldData?.dataFieldGroups) {
        result.set(
          ordinal,
          this.dataFieldData.dataFieldGroups
            .map((element, index): number => {
              if (element.ordinal === ordinal && element.multiplied) {
                return index;
              } else {
                return -1;
              }
            })
            .filter(index => index !== -1)
        );
      }
    }
    this.multipliedDataFieldGroupsIndices = result;
  }

  // Expansion panel

  /**
   * set the panel state for specific group
   * @param group - data field group
   * @param state - panel is open or closed
   */
  public setPanelOpen(group: CheckoutDataFieldGroupItem, state: boolean): void {
    const id = `groupOrdinal_${group.ordinal}`;
    this.panelOpen[id] = state;
  }

  /**
   * get the panel state for specific group
   * @param group - data field group
   * @returns panel is open or closed
   */
  public isPanelOpen(group: CheckoutDataFieldGroupItem): boolean {
    const id = `groupOrdinal_${group.ordinal}`;
    return this.panelOpen[id];
  }

  public getFormControlFieldLabel(item: CheckoutDataFieldItem): string {
    const label = item.name || 'Ihre Eingabe';
    return item.mandatory ? label : `${label} (optional)`;
  }

  /** field error handling:
   * - check field validity to show error message
   **/
  public fieldHasError(formGroup: FormGroup<ItemDatafieldFormGroup>, item: CheckoutDataFieldItem): boolean {
    const control = formGroup.controls[item.dataFieldValueId];
    if (item.readonly && control?.invalid) {
      control.markAsTouched();
      this.valueChanged.emit({ valid: false });
    }
    return control?.invalid;
  }

  public getFieldErrorMessage(formGroup: FormGroup<ItemDatafieldFormGroup>, item: CheckoutDataFieldItem): string {
    const field: FormControl = formGroup.controls[item.dataFieldValueId];
    return this.formValidationService.getFieldErrorMessage(field, item.name);
  }

  // group error handling
  public groupHasError(group: CheckoutDataFieldGroupItem): boolean {
    return this.mandatoryDataFieldsIncomplete(this.getIndex(group));
  }

  public showGroupError(item: CheckoutDataFieldGroupItem | CheckoutDataFieldGroupItem[]): boolean {
    if (Array.isArray(item)) {
      return item.some(group => this.groupHasError(group) && !this.isPanelOpen(group));
    } else {
      return this.groupHasError(item) && !this.isPanelOpen(item);
    }
  }

  public contractPolling(item): boolean {
    return this.contractService.pollingMap.has(item);
  }

  // --------------------------------------------- //
  private createFormGroup() {
    const allGroups = this.dataFieldData?.dataFieldGroups;
    const formGroups = new FormArray<FormGroup<ItemDatafieldFormGroup>>([]) as ItemDatafieldFormArray;

    allGroups.forEach(group => {
      const formControls = new FormGroup<ItemDatafieldFormGroup>({});
      // mandatory fields have to come first, sort order of fields has to be defined in configurator
      group.dataFields.forEach(field => {
        const name = `${field.dataFieldValueId}`;
        formControls.addControl(name, this.createFormControl(field));
      });
      formGroups.push(formControls);
    });

    this.dataFieldFormArray = formGroups;

    this.setDataFieldsToDisable(DataFieldTypeEnum.checkbox, DataFieldTypeEnum.dropdown);
  }

  private createFormControl(item: CheckoutDataFieldItem): FormControl<ItemDatafieldType> {
    const validator = [];
    const value = this.getFormControlValue(item);

    if (item?.mandatory) {
      validator.push(Validators.required);
    }
    if (item?.jsonValidations) {
      validator.push(Validators.pattern(new RegExp(item.jsonValidations)));
    }
    if (item?.valueValidations) {
      for (const validation of item?.valueValidations) {
        validator.push(this.formValidationService.validate(validation));
      }
    }
    const control = new FormControl<ItemDatafieldType>({ value, disabled: this.disableFields }, validator);

    // activate validator for control when called from checkout with error
    if (this.hasError) control.markAsTouched();

    return control;
  }

  private getFormControlValue(item: CheckoutDataFieldItem): ItemDatafieldType {
    const noValue = item.dataFieldType === DataFieldTypeEnum.checkbox ? false : null;
    const value = item?.value || item?.defaultValue || noValue;

    if (typeof value === 'string') {
      if (item.dataFieldType === DataFieldTypeEnum.number) {
        return parseFloat(value);
      } else if (item.dataFieldType === DataFieldTypeEnum.checkbox) {
        return value === 'true'; // return true for 'true' else return false
      } else if (item.dataFieldType === DataFieldTypeEnum.radio) {
        return ['Ja', 'true', 'Nein', 'false'].includes(value) ? value : null;
      }
    }

    return value;
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  /**
   * organises dataFieldValueIds by their dataFieldType and fills _dataFieldsToDisable with dataFields of type(s) args
   *
   * @param args - dataFieldTypes to disable
   */
  private setDataFieldsToDisable(...args: string[]): void {
    this.dataFieldData?.dataFieldGroups.forEach(group => {
      group.dataFields.forEach(field => {
        const type = field.dataFieldType;

        if (!this._dataFieldsByType.has(type)) {
          this._dataFieldsByType.set(type, new Set([field.dataFieldValueId]));
        } else {
          this._dataFieldsByType.get(type).add(field.dataFieldValueId);
        }
      });
    });

    args.forEach(type => {
      if (!this._dataFieldsByType.has(type)) {
        return;
      }
      this._dataFieldsToDisable = new Set([...this._dataFieldsToDisable, ...this._dataFieldsByType.get(type)]);
    });
  }
}
