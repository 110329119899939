@if (!isQuestionField) {
  <aside class="position-relative app-height side-profile" tabindex="0" role="complementary">
    @if (userProfile) {
      @if (!useQuestionDataFields) {
        <ng-template *ngTemplateOutlet="header"></ng-template>
        <ng-template *ngTemplateOutlet="content"></ng-template>
        <ng-template *ngTemplateOutlet="footer"></ng-template>
      }
    }
    @if (useQuestionDataFields) {
      <ng-template *ngTemplateOutlet="headerQuestionDataFields"></ng-template>
      <ng-template *ngTemplateOutlet="contentQuestionDataFields"></ng-template>
    }
    @if (!editModeActive) {
      <button
        mat-icon-button
        class="kf-popup-close"
        aria-label="Panel schließen"
        kfTooltip="Klicken oder ESC drücken, um zu schließen"
        kfTooltipShow="hover"
        kfTooltipPosition="before"
        [attr.data-testcafe]="'sideProfile-button-close'"
        (click)="onClose()"
      >
        <kf-icon name="ic_schliessen_24"></kf-icon>
      </button>
    }
  </aside>
} @else if (useQuestionDataFields) {
  <ng-template *ngTemplateOutlet="contentQuestionDataFields"></ng-template>
}

<!-- CONTENT questionDataFields -->
<ng-template #contentQuestionDataFields>
  <div class="side-container pr-4">
    @if (questionDataFields?.length < 1) {
      <p>Es sind keine Daten vorhanden.</p>
    } @else {
      @for (questionDataField of questionDataFields; track questionDataField.id) {
        <div class="mat-small mb-4 pb-2">
          @if (hasVisibleFields(questionDataField.dataFields)) {
            @if (questionDataField.showGroupName) {
              <h3
                class="mat-body-1 font-weight-bold mb-3"
                [attr.data-testcafe]="'sideProfile-header-' + questionDataField.groupName"
              >
                {{ questionDataField.groupName }}
              </h3>
              <div
                [class.description]="questionDataField.groupDescription"
                [innerHtml]="questionDataField.groupDescription | safeHtml"
              ></div>
            }
            @switch (questionDataField.template) {
              @case ('PLAIN') {
                <profile-data-field-group-plain [dataFieldGroup]="questionDataField"> </profile-data-field-group-plain>
              }
              @case ('TABLE') {
                <profile-data-field-group-table [dataFieldGroup]="questionDataField"> </profile-data-field-group-table>
              }
              @case ('LIST') {
                <profile-data-field-group-list [dataFieldGroup]="questionDataField"> </profile-data-field-group-list>
              }
            }
          }
        </div>
      }
    }
  </div>
</ng-template>

<!-- CONTENT -->
<ng-template #content>
  <div class="side-container">
    @if (editModeActive) {
      <side-profile-editor
        #profileEditor
        [userProfileData]="userProfile"
        (formValid)="setFormValid($event)"
        (editorClosed)="onCloseEditor($event)"
      ></side-profile-editor>
    } @else {
      @for (profileGroup of userProfile.profileGroups; track profileGroup; let i = $index) {
        <mat-expansion-panel
          #profileGroupPanel
          class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
          displayMode="flat"
          [id]="'profileGroup' + i"
          [expanded]="isPanelOpen('profileGroup' + i)"
          (opened)="panelOpen['profileGroup' + i] = true"
          (closed)="panelOpen['profileGroup' + i] = false"
        >
          <mat-expansion-panel-header>
            <div class="d-flex">
              <mat-panel-title
                [attr.data-testcafe]="
                  'sideProfile-matHeader-' + getTestcafeId(profileGroup.category | profileGroupName)
                "
              >
                <strong class="font-weight-bold">{{ profileGroup.category | profileGroupName }}</strong>
              </mat-panel-title>
              <div class="tag-info tag-neutral ml-3">
                <div class="tag-info-text">
                  {{ displayableDataFieldGroups(profileGroup)?.length }}
                  {{ displayableDataFieldGroups(profileGroup)?.length === 1 ? 'Datensatz' : 'Datensätze' }}
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
          @for (dataFieldGroup of displayableDataFieldGroups(profileGroup); track dataFieldGroup; let j = $index) {
            @if (hasVisibleFields(dataFieldGroup.dataFields)) {
              <mat-expansion-panel
                class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
                displayMode="flat"
                [id]="'dataFieldGroup' + i + j"
                [expanded]="i === 0 && j === 0"
                [ngClass]="isPanelOpen('dataFieldGroup' + i + j) ? 'mat-panel-opened' : null"
                (opened)="panelOpen['dataFieldGroup' + i + j] = true"
                (closed)="panelOpen['dataFieldGroup' + i + j] = false"
              >
                <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem">
                  <mat-panel-title>
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center justify-content-start">
                        @if (dataFieldGroup.showGroupName) {
                          <strong
                            class="mr-2 font-weight-bold"
                            [attr.data-testcafe]="'sideProfile-header-' + dataFieldGroup.groupName"
                          >
                            {{ dataFieldGroup.groupName }}
                          </strong>
                        }
                        @if (['TABLE', 'LIST', 'PLAIN'].includes(dataFieldGroup.template)) {
                          <div class="tag-info tag-neutral mb-1 ml-3">
                            <div class="tag-info-text">
                              {{
                                dataFieldGroup.template === 'PLAIN'
                                  ? dataFieldGroup?.dataFields.length
                                  : dataFieldGroup?.dataFields[0].values.length
                              }}<span class="sr-only">{{
                                dataFieldGroup?.dataFields[0].values.length === 1 ? 'Eintrag' : 'Einträge'
                              }}</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  [class.description]="dataFieldGroup.groupDescription"
                  [innerHTML]="dataFieldGroup.groupDescription | safeHtml"
                ></div>
                @switch (dataFieldGroup.template) {
                  @case ('PLAIN') {
                    <profile-data-field-group-plain [dataFieldGroup]="dataFieldGroup"> </profile-data-field-group-plain>
                  }
                  @case ('TABLE') {
                    <profile-data-field-group-table [dataFieldGroup]="dataFieldGroup"> </profile-data-field-group-table>
                  }
                  @case ('LIST') {
                    <profile-data-field-group-list [dataFieldGroup]="dataFieldGroup"> </profile-data-field-group-list>
                  }
                }
              </mat-expansion-panel>
            }
          }
          @if (profileGroup.category === 'GENERIC') {
            <div class="mat-panel-nested-level-1">
              <div class="mat-expansion-panel-body">
                <div class="mb-0">
                  <p class="mb-0 mr-2"><strong>Vorsteuerabzugsberechtigt:</strong></p>
                  <p class="m-0" [attr.data-testcafe]="'sideProfile-span-vatDiscount'">
                    {{ getBooleanText(userProfile.vatDiscount) }}
                  </p>
                </div>
              </div>
            </div>
          }
        </mat-expansion-panel>
      }

      <!-- content from an active consultation -->
      @if (userProfile.answers.length > 0) {
        <mat-expansion-panel
          #answeredQuestionsPanel
          id="answers"
          class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
          displayMode="flat"
          [expanded]="isPanelOpen('answers')"
        >
          <mat-expansion-panel-header>
            <div class="d-flex">
              <strong class="font-weight-bold">Bisherige Antworten</strong>
              <span class="ml-2 chip-counter"
                >{{ userProfile.answers.length }} {{ userProfile.answers.length === 1 ? 'Angabe' : 'Angaben' }}</span
              >
            </div>
          </mat-expansion-panel-header>
          @for (answer of userProfile.answers; track answer; let first = $first) {
            <dl class="p-3 m-0 border-bottom" [class.border-top]="first">
              <dt class="mb-0">{{ answer.label }}</dt>
              <dd>
                <ol class="m-0">
                  @for (givenAnswer of answer.givenAnswers; track givenAnswer) {
                    <li markNegativePrice>{{ givenAnswer }}</li>
                  }
                </ol>
              </dd>
            </dl>
          }
        </mat-expansion-panel>
      }
    }
  </div>
</ng-template>

<!-- HEADER -->
<ng-template #headerQuestionDataFields>
  <header class="side-header side-header-low" tabindex="-1">
    <div class="d-flex justify-content-between align-items-center mt-4">
      <div class="d-flex flex-grow-1 align-items-center">
        <h2 class="w-100 mb-0">Weitere Informationen</h2>
      </div>
    </div>
  </header>
</ng-template>

<ng-template #header>
  <header class="side-header" tabindex="-1">
    <div class="d-flex justify-content-between align-items-center mt-4">
      @if (userProfile.website) {
        <a [href]="userProfile.website" target="_blank" class="d-flex side-profile__customer-logo">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
        </a>
      } @else {
        <div class="d-flex side-profile__customer-logo">
          <ng-container *ngTemplateOutlet="logo"></ng-container>
        </div>
      }

      <div class="d-flex flex-grow-1 align-items-center">
        <h2
          class="w-100 ml-4 mb-0"
          [attr.data-testcafe]="'sideProfile-div-' + getTestcafeId(userProfile.corporationName)"
        >
          {{ userProfile.corporationName }}
        </h2>
      </div>
    </div>

    <div class="d-flex anchor-buttons" [class.invisible]="editModeActive || updateInProgress">
      @for (profileGroup of userProfile.profileGroups; track profileGroup; let i = $index) {
        <button mat-stroked-button color="primary" (click)="setPanelOpen('profileGroup' + i)">
          <span>{{ profileGroup.category | profileGroupName }}</span>
        </button>
      }
      @if (userProfile.answers.length > 0) {
        <button mat-stroked-button color="primary" (click)="setPanelOpen('answers')">
          <span>Bisherige Antworten</span>
        </button>
      }
    </div>
  </header>
</ng-template>

<!-- FOOTER -->
<ng-template #footer>
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content">
        @if (editModeActive) {
          <ng-container *ngTemplateOutlet="editorOff"></ng-container>
        } @else {
          <ng-container *ngTemplateOutlet="editorOn"></ng-container>
        }

        @if (lastUpdated) {
          <div class="text-right" [attr.data-testacfe]="'sideProfile-div-stand:' + lastUpdated.slice(0, 10)">
            <p class="m-0 py-0 pl-4">Stand: {{ lastUpdated }}</p>
          </div>
        }
      </div>
    </div>
  </footer>
</ng-template>

<!-- LOGO -->
<ng-template #logo>
  <img src="{{ userProfile.logoReference || customerLogo }}" [alt]="userProfile.corporationName" />
</ng-template>

<!-- BUTTONS EDITOR ON -->
<ng-template #editorOn>
  <button
    mat-stroked-button
    color="primary"
    [disabled]="updateInProgress"
    [attr.data-testcafe]="'sideProfile-button-edit'"
    (click)="editModeActive = true"
  >
    <span>Bearbeiten</span>
  </button>
  @if (!isTestConsultation) {
    <button mat-flat-button color="primary" (click)="startUpdate()" [disabled]="updateInProgress">
      @if (updateInProgress) {
        <mat-spinner [diameter]="24" color="primary"></mat-spinner>
      } @else {
        <span>Aktualisieren</span>
      }
    </button>
  }
</ng-template>

<!-- BUTTONS EDITOR OFF  -->
<ng-template #editorOff>
  <button
    mat-stroked-button
    color="primary"
    [attr.data-testcafe]="'sideProfile-button-closeEditor'"
    (click)="onCloseEditor()"
  >
    <span>Abbrechen</span>
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!formValid"
    [attr.data-testcafe]="'sideProfile-button-saveEditor'"
    (click)="onSave()"
  >
    @if (updateInProgress) {
      <mat-spinner [diameter]="24" color="primary"></mat-spinner>
    } @else {
      <span>Speichern</span>
    }
  </button>
</ng-template>
