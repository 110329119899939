import { Injectable } from '@angular/core';
import { Notes } from '@domain/notes';
import { NoteContextEnum, NoteOriginEnum, NoteTypeEnum } from '@enums';
import { NoteUpdatedResult } from '@screens/screen-consultation/screen-consultation.interfaces';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { TopicService } from '@services/topic-service/topic-service';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import CreateNoteRequest = Notes.CreateNoteRequest;
import UpdateNoteRequest = Notes.UpdateNoteRequest;

export interface NoteSendObj {
  drawing?: any;
  text?: string;
  type: NoteTypeEnum;
  viewProperties?: string;
}

export interface CustomNoteSendObj extends NoteSendObj {
  displayName?: string;
  text?: string;
}

export interface QuestionGroupNoteSendObj extends NoteSendObj {
  questionGroupId?: string;
}

export interface TopicOverviewNoteSendObj extends NoteSendObj {
  type: NoteTypeEnum;
}

export interface UpdateNoteSendObj extends NoteSendObj {
  displayName?: string;
  drawingBase64?: string;
  text?: string;
}

export interface DeleteSubject {
  id: string;
  pageReference: NoteContextEnum.topicSelection | NoteContextEnum.questionGroup | NoteContextEnum.noteManagement;
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(
    private queryService: QueryService,
    private clientService: ClientService,
    private topicService: TopicService,
    private readonly contextService: ContextService
  ) {}

  public currentContextNoteId: string = '-1';

  public currentNote: BehaviorSubject<Notes.GetNoteResponse | null> = new BehaviorSubject<Notes.GetNoteResponse | null>(
    null
  );

  public currentNotes: BehaviorSubject<Notes.GetAllNotesResponse | null> =
    new BehaviorSubject<Notes.GetAllNotesResponse | null>(null);

  public requestNoteChange: BehaviorSubject<string> = new BehaviorSubject<string>('-1');

  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasNotes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public screenshotting: boolean = false;

  public currentQuestionGroupId: string = '-1';

  public pageReference:
    | NoteContextEnum.topicSelection
    | NoteContextEnum.questionGroup
    | NoteContextEnum.noteManagement = NoteContextEnum.topicSelection;

  public presetNoteType: NoteTypeEnum.drawing | NoteTypeEnum.text | null;

  public noteUpdated: Subject<NoteUpdatedResult | null> = new Subject();

  public noteDeleted: Subject<boolean | null> = new Subject();

  public largestNoteNumber: number = 0;

  public getAllNotes(): void {
    this.queryService.getNotes(this.clientService.consultationId).subscribe(notes => {
      this.currentNotes.next(notes);
      this.determineLargestNoteNumber(notes);
      if (!(isEmpty(notes.notes) && isEmpty(notes.notesByPageReference) && isEmpty(notes.notesByTopic))) {
        this.hasNotes.next(true);
      } else {
        this.hasNotes.next(false);
      }
      notes.notes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      notes.notesByTopic.forEach(topic => {
        topic.notes.sort((a, b) => (a.questionGroupName.toLowerCase() > b.questionGroupName.toLowerCase() ? 1 : -1));
      });
      notes.notesByPageReference.sort((a, b) =>
        a.pageReference.toLowerCase() > b.pageReference.toLowerCase() ? 1 : -1
      );
    });
  }

  public async getNoteByContext(inNotesOverlay: boolean): Promise<void> {
    if (inNotesOverlay && this.currentContextNoteId == '-1') {
      if (this.currentNote.getValue() !== null && this.currentNote.getValue().type === this.presetNoteType) {
        this.getSingleNote(this.currentNote.getValue().id, inNotesOverlay);
      } else {
        return;
      }
    } else if (this.currentContextNoteId !== '-1') {
      await this.getSingleNote(this.currentContextNoteId, inNotesOverlay);
    } else {
      this.createNote(inNotesOverlay, this.presetNoteType);
    }
  }

  /**
   * Returns a promise that either returns a note and provides it for editing
   * or creates a new one
   * @param {number} id
   * @param {boolean} inNotesOverlay
   */
  public async getSingleNote(id: string, inNotesOverlay: boolean = null): Promise<void> {
    const note = await lastValueFrom(this.queryService.getNote(this.clientService.consultationId, id));
    if (
      note &&
      (note.origin as NoteOriginEnum) !== NoteOriginEnum.consultation &&
      this.contextService.currentMode === 'main' &&
      this.pageReference === NoteContextEnum.questionGroup &&
      !inNotesOverlay
    ) {
      this.createNote(inNotesOverlay, this.presetNoteType);
    } else if ((!!note && note.type === this.presetNoteType && !inNotesOverlay) || inNotesOverlay) {
      this.requestNoteChange.next('-1');
      this.currentNote.next(note);

      this.presetNoteType = note.type;
    } else if ((note.type !== this.presetNoteType && !inNotesOverlay) || !note) {
      this.createNote(inNotesOverlay, this.presetNoteType);
    }
  }

  public createNote(inNotesOverlay: boolean, presetNoteType: NoteTypeEnum.drawing | NoteTypeEnum.text): void {
    if (this.pageReference === NoteContextEnum.topicSelection) {
      presetNoteType === NoteTypeEnum.drawing
        ? this.createNoteForTopicOverview({ drawing: '[]', type: NoteTypeEnum.drawing }, inNotesOverlay)
        : this.createNoteForTopicOverview({ text: '', type: NoteTypeEnum.text }, inNotesOverlay);
    } else {
      presetNoteType === NoteTypeEnum.drawing
        ? this.createNoteByQuestionGroupId(
            { drawing: '[]', type: NoteTypeEnum.drawing, questionGroupId: this.currentQuestionGroupId },
            inNotesOverlay
          )
        : this.createNoteByQuestionGroupId(
            { text: '', type: NoteTypeEnum.text, questionGroupId: this.currentQuestionGroupId },
            inNotesOverlay
          );
    }
  }

  public createCustomNote(req: CustomNoteSendObj): void {
    const sendObj = {} as CreateNoteRequest;
    sendObj.displayName = req.displayName || `Notiz ${this.largestNoteNumber + 1}`;
    sendObj.type = req.type;
    if (req.type === NoteTypeEnum.drawing) {
      sendObj.drawing = req.drawing;
    } else if (req.type === NoteTypeEnum.text) {
      sendObj.text = req.text;
    }
    sendObj.pageReference = NoteContextEnum.noteManagement;
    this.postCreateNote(sendObj, true);
  }

  // TOPIC OVERVIEW NOTES
  public createNoteForTopicOverview(req: TopicOverviewNoteSendObj, inNotesOverlay: boolean): void {
    if (this.presetNoteType === NoteTypeEnum.drawing) {
      this.createDrawingNoteForTopicOverview(req, inNotesOverlay);
    } else {
      this.createTextNoteForTopicOverview(req, inNotesOverlay);
    }
  }

  public createDrawingNoteForTopicOverview(req: TopicOverviewNoteSendObj, inNotesOverlay: boolean): void {
    const sendObj = {} as CreateNoteRequest;
    sendObj.pageReference = NoteContextEnum.topicSelection;
    sendObj.drawing = req.drawing;
    sendObj.type = req.type;
    sendObj.viewProperties = req.viewProperties;

    this.postCreateNote(sendObj, inNotesOverlay);
  }

  public createTextNoteForTopicOverview(req: TopicOverviewNoteSendObj, inNotesOverlay: boolean): void {
    const sendObj = {} as CreateNoteRequest;
    sendObj.pageReference = NoteContextEnum.topicSelection;
    sendObj.text = req.text;
    sendObj.type = req.type;

    this.postCreateNote(sendObj, inNotesOverlay);
  }

  // QUESTION GROUP NOTES
  public createNoteByQuestionGroupId(req: QuestionGroupNoteSendObj, inNotesOverlay: boolean): void {
    if (this.presetNoteType === NoteTypeEnum.drawing) {
      this.createDrawingNoteByQuestionGroupId(req, inNotesOverlay);
    } else {
      this.createTextNoteByQuestionGroupId(req, inNotesOverlay);
    }
  }

  public createDrawingNoteByQuestionGroupId(req: QuestionGroupNoteSendObj, inNotesOverlay: boolean): void {
    const sendObj = {} as CreateNoteRequest;
    sendObj.pageReference = NoteContextEnum.questionGroup;
    sendObj.drawing = req.drawing;
    sendObj.type = req.type;
    sendObj.viewProperties = req.viewProperties;
    sendObj.questionGroupId = req.questionGroupId;
    this.postCreateNote(sendObj, inNotesOverlay);
  }

  public createTextNoteByQuestionGroupId(req: QuestionGroupNoteSendObj, inNotesOverlay: boolean): void {
    const sendObj = {} as CreateNoteRequest;
    sendObj.pageReference = NoteContextEnum.questionGroup;
    sendObj.text = req.text;
    sendObj.type = req.type;
    sendObj.questionGroupId = req.questionGroupId;
    this.postCreateNote(sendObj, inNotesOverlay);
  }

  public async deleteNote(inNotesOverlay: boolean = true): Promise<boolean> {
    const note = this.currentNote.getValue();
    const formerCurrentContextNoteId = this.currentContextNoteId;

    try {
      await lastValueFrom(this.queryService.deleteNote(this.clientService.consultationId, note.id));
      this.currentNote.next(null);
      if (note.pageReference !== NoteContextEnum.noteManagement) {
        this.currentContextNoteId = '-1';
      }

      if (inNotesOverlay) {
        this.filterDeleteElement({ id: note.id, pageReference: note.pageReference });
        this.isLargestNumber(note.displayName) && this.determineLargestNoteNumber(this.currentNotes.getValue());
      }

      const d = this.currentNotes.getValue()?.notes;
      const e = this.currentNotes.getValue()?.notesByPageReference;
      const f = this.currentNotes.getValue()?.notesByTopic;
      this.noteDeleted.next(note.id === formerCurrentContextNoteId);
      if (isEmpty(d) && isEmpty(e) && isEmpty(f)) {
        this.hasNotes.next(false);
        this.currentNote.next(null);
        this.presetNoteType = null;
      }

      if (inNotesOverlay && d && d.length > 0) {
        await this.getSingleNote(d[0].id, inNotesOverlay);
      } else if (inNotesOverlay && e && e.length > 0) {
        await this.getSingleNote(e[0].noteId, inNotesOverlay);
      } else if (inNotesOverlay && f && f.length > 0) {
        await this.getSingleNote(f[0].notes[0].noteId, inNotesOverlay);
      }

      return true;
    } catch {
      return false;
    }
  }

  public deleteNoteById(ids: string[]) {
    ids.forEach(async id => {
      await lastValueFrom(this.queryService.deleteNote(this.clientService.consultationId, id));
    });
  }

  public updateNote(req: UpdateNoteSendObj, inNotesOverlay: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      const sendObj = {} as UpdateNoteRequest;
      if (inNotesOverlay) {
        sendObj.displayName = req.displayName;
        if (req.type === NoteTypeEnum.text) {
          sendObj.text = req.text;
        }
      }
      if (req.type === NoteTypeEnum.drawing) {
        sendObj.drawing = req.drawing;
        sendObj.drawingBase64 = req.drawingBase64;
        sendObj.viewProperties = req.viewProperties;
      }
      if (req.type === NoteTypeEnum.text) {
        sendObj.text = req.text;
      }
      this.queryService
        .patchUpdateNote(this.clientService.consultationId, this.currentNote.getValue().id, sendObj)
        .subscribe(
          () => {
            if (inNotesOverlay) {
              const notes = this.currentNotes.getValue();
              const updatedNote = notes.notes.find(x => x.id === this.currentNote.getValue().id);
              if (this.currentNote.getValue().pageReference === NoteContextEnum.noteManagement) {
                updatedNote.name = req.displayName;
              }
              this.currentNotes.next({ ...notes });
              if (this.requestNoteChange.getValue() !== '-1' && this.requestNoteChange.getValue() !== null) {
                this.getSingleNote(this.requestNoteChange.getValue());
              }
            }
            resolve();
          },
          () => reject()
        );
    });
  }

  public compareNotesToTopics(topicIds: string[]) {
    this.getAllNotes();
    const currentNotes = this.currentNotes.getValue();
    const questionGroupIds = [
      ...new Set(currentNotes.notesByTopic.flatMap(x => x.notes.flatMap(y => y.questionGroupId))),
    ];
    questionGroupIds.forEach(questionGroupId => {
      if (!topicIds.includes(this.topicService.selectedSubTopicDataByQuestionId(questionGroupId)?.id)) {
        this.deleteNoteById(
          currentNotes.notesByTopic
            .flatMap(x => x.notes.filter(y => y.questionGroupId === questionGroupId))
            .map(z => z.noteId)
        );
      }
    });
  }

  private isLargestNumber(displayName: string): boolean {
    if (!displayName.includes('Notiz')) {
      return false;
    }
    const number = Number(/\w+\s(\d+)$/.exec(displayName)?.[1]);
    return number === this.largestNoteNumber;
  }

  private determineLargestNoteNumber(notes: Notes.GetAllNotesResponse) {
    this.largestNoteNumber = notes.notes
      .filter(note => note.name.includes('Notiz'))
      .map(note => /\w+\s(\d+)$/.exec(note.name)?.[1])
      .map(noteNumber => Number(noteNumber))
      .filter(noteNumber => !!noteNumber)
      .reduce((previousValue, currentValue) => Math.max(currentValue, previousValue), 0);
  }

  private postCreateNote(sendObj: Notes.CreateNoteRequest, inNotesOverlay: boolean): void {
    this.queryService.postCreateNote(this.clientService.consultationId, sendObj).subscribe(note => {
      this.currentNote.next(note);
      if (!inNotesOverlay) {
        this.currentContextNoteId = note.id;
      }
      if (sendObj.pageReference !== NoteContextEnum.noteManagement) {
        this.noteUpdated.next({ id: note.id, type: note.type });
      }
      this.addNewElement(note);
      this.largestNoteNumber++;
    });
  }

  private addNewElement(postData: Notes.GetNoteResponse) {
    const allNotes = this.currentNotes.getValue();
    if (postData && postData?.pageReference === NoteContextEnum.noteManagement) {
      this.currentNotes.next({
        ...allNotes,
        notes: [...allNotes.notes, { id: postData.id, name: postData.displayName, type: postData.type }],
      });
    }
    this.hasNotes.next(true);
  }

  private filterDeleteElement(deleteData: DeleteSubject): void {
    this.getAllNotes();

    const allNotes = this.currentNotes.getValue();

    if (deleteData.pageReference === NoteContextEnum.noteManagement) {
      this.currentNotes.next({
        ...allNotes,
        notes: allNotes.notes.filter(x => x.id !== deleteData.id),
      });
    } else if (deleteData.pageReference === NoteContextEnum.topicSelection) {
      this.currentNotes.next({
        ...allNotes,
        notesByPageReference: allNotes.notesByPageReference.filter(x => x.noteId !== deleteData.id),
      });
    } else if (deleteData.pageReference === NoteContextEnum.questionGroup) {
      this.currentNotes.next({
        ...allNotes,
        notesByTopic: allNotes.notesByTopic.reduce((prev, current) => {
          const newNotes = current.notes.filter(x => x.noteId !== deleteData.id);
          if (newNotes.length > 0) {
            prev = [
              ...prev,
              {
                topicName: current.topicName,
                notes: newNotes,
              },
            ];
          }
          return prev;
        }, []),
      });
    }
  }
}
