import { Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { KF_THEME_BASE_PATH } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { ENVIRONMENT_DEFAULTS } from './defaults';

export const environment = {
  ...ENVIRONMENT_DEFAULTS,
  name: 'production',
  production: true,
  enableOnlinePing: true,
  enableDebug: false,
  enableLogging: true,
  apiConsultationAppUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:8004/',
  apiBankHubUrl: 'http://ec2-52-57-6-109.eu-central-1.compute.amazonaws.com:9004/',

  // bgzv_prod
  cognitoUserPoolId: 'eu-central-1_1vOp60m5K',
  cognitoClientId: '6rd4q8g7qq6s9758jj0tkb5b38',

  get consultationOrigin() {
    return `https://${ENVIRONMENT_DEFAULTS.consultationOriginSuffix}`;
  },

  get preliminaryTarget() {
    return `https://${ENVIRONMENT_DEFAULTS.preliminaryTargetSuffix}`;
  },
};

export const ENVIRONMENT_SPECIFIC_IMPORTS = [];

export const ENVIRONMENT_SPECIFIC_PROVIDERS = [{ provide: KF_THEME_BASE_PATH, useValue: '/' }];

export function ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(component: any, injector: Injector = null) {
  return createCustomElement(component, { injector: injector });
}
