import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ActionService } from './action-service/action.service';
import { CacheService } from './cache-service/cache.service';
import { CheckoutService } from './checkout-service/checkout.service';
import { ContextService } from './context-service/context.service';
import { ContractService } from './contract-service/contract.service';
import { DialogService } from './dialog-service/dialog.service';
import { LoadingService } from './loading-service/loading.service';
import { MainMenuService } from './main-menu-service/main-menu.service';
import { IpadPdfViewerService } from './pdf-viewer-service/ipad-pdf-viewer-service';
import { ProductVariantCalculatorService } from './product-variant-calculator/product-variant-calculator.service';
import { PwaService } from './pwa-service/pwa.service';
import { QueryService } from './query-service/query.service';
import { SearchService } from './search-service/search.service';
import { LeftSidenavService, RightSidenavService } from './side-service/sidenav.service';
import { StorageService } from './storage-service/storage.service';

@NgModule({
  providers: [
    ActionService,
    CacheService,
    CheckoutService,
    ContractService,
    ContextService,
    DialogService,
    LoadingService,
    MainMenuService,
    LeftSidenavService,
    RightSidenavService,
    ProductVariantCalculatorService,
    PwaService,
    QueryService,
    SearchService,
    StorageService,
    IpadPdfViewerService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class BgzvServicesModule {}
