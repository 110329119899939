import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import {
  DataFieldFormValueChange,
  ItemDatafieldFormComponent,
} from '@components/item-datafield-form/item-datafield-form.component';
import {
  CheckoutDataFieldCompositionItem,
  CheckoutDataFieldGroupItem,
  CheckoutDataFieldUpdateRequest,
} from '@domain/app/checkout.domain';
import { CustomerContactRequestItem, CustomerContactResponseItem } from '@domain/app/contact.domain';
import { PutNewTaskDataFieldGroupRequest } from '@domain/app/datafield.domain';
import { ContactForm, OptionData, TaskForm } from '@domain/app/forms.domain';
import { MainProductRecommendationItem } from '@domain/app/product.domain';
import { AssignTaskItem, DeselectTaskItem, SelectTaskItem, TaskResponse } from '@domain/app/task.domain';
import {
  DataFieldElementTypeEnum,
  DataFieldStatusEnum,
  DataFieldTypeEnum,
  PriorityEnum,
  RoutingPathMain,
  RoutingPathOverlay,
} from '@enums';
import {
  BadgeData,
  TaskAndTransitionInput,
  TaskAndTransitionStateEnum,
  formEmailPattern,
} from '@marginals/transition-task-utils';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color } from '@utils/helpers/color';
import { getContactExtended, getContactId, getContactName } from '@utils/helpers/formatContact';
import { getBenefitIcon } from '@utils/helpers/mediaType';
import { trimDropdownLabel } from '@utils/helpers/trimDropdownLabel';
import moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'side-task',
  templateUrl: './side-task.component.html',
  styleUrls: ['./side-task.component.scss'],
  standalone: false,
})
export class SideTaskComponent implements OnInit, OnChanges {
  @Input() inputValues: TaskAndTransitionInput;
  @Output() closed = new EventEmitter(null);

  @ViewChild('formItem') formComponent: ItemDatafieldFormComponent;

  private _animationSub: Subscription;
  private _dialogSub: Subscription;
  private _dialogRef = null;

  public taskData: TaskResponse;
  public dataFieldData: CheckoutDataFieldCompositionItem;
  public DataFieldStatusEnum = DataFieldStatusEnum;
  public badgeData: BadgeData;

  public contactsList: OptionData[] = [];
  public existingCustomerContacts: CustomerContactResponseItem[];
  public customerContact: CustomerContactRequestItem;
  public dataFieldElementTypeEnum = DataFieldElementTypeEnum;
  public taskSendObj: AssignTaskItem;
  private taskPutSelectObj: SelectTaskItem;
  private taskPutDeselectObj: DeselectTaskItem;

  public contactForm: FormGroup<ContactForm>;
  public taskForm: FormGroup<TaskForm>;
  public contactSubmitted = false;
  public isNewTask = true;

  public taskState = TaskAndTransitionStateEnum;
  public state: TaskAndTransitionStateEnum = TaskAndTransitionStateEnum.default;

  public minimumDate: Date;
  public appointmentDate: Date;
  private madeSelection: boolean;
  private previousDate: Date;
  public appointmentDateString: string;
  public appointmentTime;
  public selectedAppointmentTime;
  public fromSelectionMode = false;
  public requestInProgress = false;

  public selectionMode;
  public selected;
  public mainData: MainProductRecommendationItem;

  readonly color = color;
  readonly moment = moment;
  readonly formEmailPattern = formEmailPattern;
  readonly getBenefitIcon = getBenefitIcon;

  public bg2Theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: this.color.find('--color-grey-100'),
      buttonColor: this.color.find('--color-primary-500'),
    },
    dial: {
      dialBackgroundColor: this.color.find('--color-primary-500'),
    },
    clockFace: {
      clockFaceBackgroundColor: this.color.find('--color-grey-200'),
      clockHandColor: this.color.find('--color-primary-500'),
      clockFaceTimeInactiveColor: this.color.find('--color-grey-500'),
    },
  };
  areFormsValid: any;

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService,
    private chg: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputValues']) {
      this.mainData = this.inputValues.mainData;
      if (this.inputValues['useBackToList']) {
        this.queryTaskData();
      }
    }
  }

  ngOnInit(): void {
    this.minimumDate = moment({}).add(1, 'day').toDate();

    if (this.inputValues.sideOpen) {
      this.queryTaskData();
    } else {
      this._animationSub = this.rightSidenavService.instance.openedStart.subscribe(() => {
        this.queryTaskData();
        this._animationSub.unsubscribe();
      });
    }

    this.taskPutSelectObj = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.taskId,
      subtopicId: this.inputValues.subtopicId,
    } as SelectTaskItem;

    this.taskPutDeselectObj = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.taskId,
      target: this.inputValues.target || 'RECOMMENDED',
    } as DeselectTaskItem;

    this.selectionMode = !this.inputValues.skipSelection; // ? false : true;

    this.taskSendObj = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.taskId,
      notifyViaEmail: false,
      customerContact: {} as CustomerContactRequestItem,
      priority: PriorityEnum.normal,
    } as AssignTaskItem;

    this.contactForm = this.formBuilder.group({
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.formEmailPattern)])
      ),
    });

    this.taskForm = this.formBuilder.group({
      customerContact: new FormControl<string | null>(this.taskSendObj.customerContact?.id, Validators.required),
      individualNote: new FormControl<string | null>(''),
    });

    // activate validator for customer contact when called from checkout with error
    if (this.inputValues.hasError) {
      this.taskFormControls.customerContact.markAsTouched();
    }
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public handleClose() {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.closed.emit();
      this.rightSidenavService.close();
    }
    this._dialogSub?.unsubscribe();
  }

  public handleBack() {
    if ((this.state === this.taskState.default && this.selectionMode) || this.state === this.taskState.customer) {
      this.state = TaskAndTransitionStateEnum.default;
      this.selectionMode = true;
      if (this.inputValues.sideOpen) {
        this.doAction('recommendation-extra', 'details-closed');
      }
    } else if (this.state === this.taskState.datepick) {
      this.onAbortAppointment();
    } else if (this.state === this.taskState.default && !this.selectionMode) {
      this.selectionMode = true;
    } else {
      this.state = this.taskState.default;
      if (this.inputValues.sideOpen) {
        this.doAction('recommendation-extra', 'details-closed');
      }
    }
  }

  public onBadgeClicked(type, id, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.doAction('main', 'product', {
      compositionId: this.inputValues.compositionId,
      productId: this.mainData.id,
      hideButton: this.mainData.variants.length > 0,
      keepNavOpen: true,
    });
  }

  public selectTask() {
    this.queryService.putSelectTask(this.clientService.consultationId, this.taskPutSelectObj).subscribe(x => {
      this.queryTaskData();
      this.notifyMainComponents();
    });
  }

  public deselectTask() {
    this.contactsList.every(x => (x.checked = false));
    if (this.customerContact) {
      this.customerContact.firstName = null;
      this.customerContact.lastName = null;
    }

    this.queryService.putDeselectTask(this.clientService.consultationId, this.taskPutDeselectObj).subscribe(x => {
      this.selected = false;
      this.notifyMainComponents();
    });
  }

  public async toggleContextMode(): Promise<void> {
    const data = {
      copyText: `Möchten Sie die Maßnahme "${this.taskData.name}" aus dem Lösungskorb entfernen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this._dialogRef = this.dialogService.openDialogConfirm(data);
    this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.deselectTask();

        setTimeout(() => {
          this.handleClose();
        }, 350);
      }
      this._dialogRef = null;
    });
  }

  public toggleSelectionMode() {
    this.selectionMode = !this.selectionMode;
  }

  public onContactSelected(event: MatSelectChange) {
    const id = event.value;

    if (!!id === false) {
      return;
    }
    if (id.includes('___')) {
      const name = id.split('___')[1].split('###');
      const contact = this.clientService.sessionContacts.find(x => x.firstName === name[0] && x.lastName === name[1]);
      this.customerContact = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
      } as CustomerContactRequestItem;
    } else {
      const contact =
        this.existingCustomerContacts.find(x => x.id === id) ||
        this.existingCustomerContacts.find(x => x.expertContactId === id);

      const newCustomerContact = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        id: contact?.id,
        expertContactId: contact?.expertContactId,
      } as CustomerContactRequestItem;

      this.customerContact = newCustomerContact;
    }

    this.contactsList.forEach(x => {
      x.checked = x.value === id;
    });

    this.taskSendObj.customerContact = this.customerContact;
  }

  public onCommentAdded(comment: string) {
    this.taskSendObj.individualNote = comment;
  }

  public onDatepickSelected() {
    this.madeSelection = false;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickUpdate() {
    this.madeSelection = false;
    this.previousDate = this.appointmentDate;
    this.onDateSelect(this.appointmentDate);
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickDeleted() {
    this.appointmentDateString = this.appointmentDate = this.appointmentTime = this.taskSendObj.appointmentDate = null;
  }

  public onEmailNotificationSelected(value) {
    this.taskSendObj.notifyViaEmail = value;
  }

  public onFinalizeTask(close: boolean = false) {
    if (!this.customerContact?.id) {
      this.clientService.removeSessionContact(this.customerContact);
    }

    if (this.taskSendObj.customerContact.newCustomerId) {
      delete this.taskSendObj.customerContact.newCustomerId;
    }

    this.queryService.putAssignTask(this.clientService.consultationId, this.taskSendObj).subscribe(x => {
      close && this.handleClose();
      this.notifyMainComponents();
    });
  }

  public handleDataFormValueChange(event: DataFieldFormValueChange) {
    // send this to the backend, and request new data;
    const sendObj = {
      dataFieldValueId: event.item.dataFieldValueId,
      value: event.changedValue || '',
    } as CheckoutDataFieldUpdateRequest;

    this.requestInProgress = true;
    this.queryService
      .putCheckoutDataFieldData(this.clientService.consultationId, sendObj, event.item.elementType)
      .subscribe(
        x => {
          if (
            event.item.dataFieldType === DataFieldTypeEnum.checkbox ||
            event.item.dataFieldType === DataFieldTypeEnum.dropdown ||
            event.item.dataFieldType === DataFieldTypeEnum.radio
          ) {
            this.queryDataFieldData();
          } else {
            this.requestInProgress = false;
          }
        },
        err => (this.requestInProgress = false)
      );
  }

  public handleAddedDataField(data: CheckoutDataFieldGroupItem): void {
    const sendObj: PutNewTaskDataFieldGroupRequest = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.taskId,
      dataFieldGroupId: data.dataFieldGroupId,
    };
    this.requestInProgress = true;

    this.queryService.putAddNewTaskDataFieldGroup(this.clientService.consultationId, sendObj).subscribe(x => {
      this.queryDataFieldData();
    });
  }

  public handleRemovedDataField(data: CheckoutDataFieldGroupItem): void {
    const sendObj: PutNewTaskDataFieldGroupRequest = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.taskId,
      dataFieldGroupId: data.dataFieldGroupId,
    };
    this.requestInProgress = true;

    this.queryService.putRemoveTaskDataFieldGroup(this.clientService.consultationId, sendObj).subscribe(x => {
      this.queryDataFieldData();
    });
  }

  // ---
  // ---
  // ---
  // ---
  // ---
  // ---

  public onDateSelect(event: Date) {
    this.madeSelection = true;
    this.appointmentDate = event;
    this.selectedAppointmentTime = moment(this.appointmentDate || {});
  }

  public onAppointmentConfirmed() {
    const dayString = moment(this.selectedAppointmentTime).format('DD. MMMM YYYY');
    this.appointmentDateString = dayString;
    this.state = TaskAndTransitionStateEnum.default;
    this.appointmentDate = this.selectedAppointmentTime;

    this.taskSendObj.appointmentDate = moment(this.selectedAppointmentTime).format('YYYY-MM-DD');
  }

  public onAbortAppointment() {
    if (this.madeSelection) {
      this.appointmentDate = this.previousDate || null;
      if (this.appointmentDate) {
        this.selectedAppointmentTime = moment(this.appointmentDate || {});
        this.taskSendObj.appointmentDate = moment(this.selectedAppointmentTime).format('YYYY-MM-DD');
      } else {
        this.selectedAppointmentTime = this.taskSendObj.appointmentDate = null;
      }
    }
    this.state = this.taskState.default;
  }

  // --------------------------------------------- //
  // -------- CUSTOMER TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onContactAdded() {
    this.customerContact = {
      firstName: this.contactFormControls.firstName.value,
      lastName: this.contactFormControls.lastName.value,
      email: this.contactFormControls.email.value,
    } as CustomerContactRequestItem;

    this.clientService.addSessionContact(this.customerContact);

    this.contactsList.every(x => (x.checked = false));
    const name = getContactName(this.customerContact);
    const value = getContactId(this.customerContact);
    this.customerContact.newCustomerId = value;

    const listItem = {
      label: trimDropdownLabel(name, 58),
      value: value,
      checked: false,
    } as OptionData;

    const contactExists = this.contactsList.find(x => x.label === listItem.label);

    if (!contactExists) {
      listItem.checked = true;
      if (!this.contactsList.find(contact => contact.value === listItem.value)) {
        this.contactsList.push(listItem);
      }
      this.contactForm.reset();
      this.taskFormControls.customerContact.patchValue(this.customerContact.newCustomerId);
    }

    this.taskSendObj.customerContact = this.customerContact;

    this.state = TaskAndTransitionStateEnum.default;
  }

  public onContactCancelled() {
    this.state = TaskAndTransitionStateEnum.default;
  }

  public onDataEntered() {
    this.state = TaskAndTransitionStateEnum.default;
  }

  public onDataCancelled() {
    this.state = TaskAndTransitionStateEnum.default;
    if (this.fromSelectionMode) {
      if (!this.inputValues.skipSelection) {
        this.selectionMode = true;
      }
      this.fromSelectionMode = false;
    } else if (!this.fromSelectionMode && this.inputValues['useBackToList']) {
      this.closed.emit();
    }

    if (this.customerContact?.firstName && this.customerContact?.lastName) {
      this.onFinalizeTask();
    }
    this.queryTaskData(true);
  }

  public onCustomerDataSelected(fromSelectionMode?: boolean) {
    this.fromSelectionMode = fromSelectionMode;
    this.state = TaskAndTransitionStateEnum.customer;
    this.selectionMode = false;
  }

  // ---
  // ---
  // ---
  // ---
  // ---
  // ---

  private notifyMainComponents() {
    this.clientService.setSolutionBasketCount();
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Cart) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  private queryTaskData(exclude: boolean = false) {
    this.queryService
      .getTaskById(this.clientService.consultationId, this.inputValues.compositionId, this.inputValues.taskId)
      .subscribe(data => {
        this.taskData = data;
        this.isNewTask = !data?.customerContact;
        this.contactsDropdownFactory();
        this.appointmentDateFactory();

        if (!exclude) {
          this.taskSendObj.notifyViaEmail = this.taskData.notifyViaEmail;
          this.taskSendObj.individualNote = this.taskData.individualNote;
          this.taskSendObj.customerContact = {
            id: this.taskData.customerContact?.id,
            firstName: this.taskData.customerContact?.firstName,
            lastName: this.taskData.customerContact?.lastName,
            email: this.taskData.customerContact?.email,
          } as CustomerContactRequestItem;
        }

        // fills the dropdown with either a previously selected contact or a default (configured in admin)
        this.taskFormControls.customerContact.patchValue(this.taskData.customerContact?.id);

        this.queryDataFieldData();
        this.selected = this.taskData.selected;
      });

    this.badgeData = {
      id: -1,
      type: 'product',
      label: this.inputValues.compositionTitle || '',
      bgColor: color.find('--color-grey-200'),
      textColor: color.find('--color-primary-500'),
    };
  }

  private queryDataFieldData(): void {
    this.queryService
      .getCheckoutDataFieldData(
        this.clientService.consultationId,
        DataFieldElementTypeEnum.tasks,
        this.inputValues.compositionId,
        this.taskData.id
      )
      .pipe(finalize(() => (this.requestInProgress = false)))
      .subscribe(data => {
        this.dataFieldData = data;
      });
  }

  private contactsDropdownFactory(newContact = null) {
    this.queryService
      .getCustomerContactsForTask(this.clientService.consultationId, this.inputValues.taskId)
      .subscribe(data => {
        this.existingCustomerContacts = data;
        const custId = this.taskData.customerContact
          ? this.taskData.customerContact?.id || this.taskData.customerContact?.expertContactId
          : -1;

        for (const contact of this.existingCustomerContacts) {
          const contactExtended = getContactExtended(contact);
          const contactExists = this.contactsList.find(x => x.label.search(contactExtended) !== -1);

          if (!contactExists) {
            const listItem = {} as OptionData;
            listItem.label = contactExtended;
            listItem.label = trimDropdownLabel(listItem.label, 58);
            listItem.value = String(contact?.id || contact?.expertContactId);
            listItem.checked = custId === contact.id;

            if (!this.contactsList.find(contact => contact.value === listItem.value)) {
              this.contactsList.push(listItem);
            }

            if (custId === contact.id) {
              this.customerContact = {} as CustomerContactRequestItem;
              this.customerContact.id = contact.id;
              this.customerContact.firstName = contact.firstName;
              this.customerContact.lastName = contact.lastName;
              this.customerContact.email = contact.email;
            }
          }
        }

        this.clientService.sessionContacts.forEach(contact => {
          const listItem = {} as OptionData;
          listItem.label = getContactName(contact);
          listItem.value = getContactId(contact);
          listItem.checked = false;

          if (!this.contactsList.find(contact => contact.value === listItem.value)) {
            this.contactsList.push(listItem);
          }
        });
      });
  }

  private appointmentDateFactory() {
    this.appointmentDate = null;
    if (this.taskData.appointmentDate) {
      const dayString = moment(this.taskData.appointmentDate).format('DD. MMMM YYYY');
      this.appointmentDateString = dayString;
      this.appointmentDate = moment(this.taskData.appointmentDate).toDate();
      this.taskSendObj.appointmentDate = moment(this.appointmentDate).format('YYYY-MM-DD');
    }
  }

  get contactFormControls() {
    return this.contactForm.controls;
  }

  get taskFormControls() {
    return this.taskForm.controls;
  }

  get testcafeButtonTask() {
    return this.isNewTask ? 'sideTask-button-changeTask' : 'sideTask-button-createTask';
  }

  get testcafeButtonBack() {
    return this.inputValues['useBackToList'] ? 'sideTask-button-back' : 'sideTask-button-close';
  }

  get hasError(): boolean {
    return this.inputValues.hasError && this.dataFieldData?.dataFieldStatus === DataFieldStatusEnum.notComplete;
  }

  get isInvalidDate(): boolean {
    return this.taskSendObj?.appointmentDate ? new Date(this.taskSendObj.appointmentDate) <= new Date() : false;
  }

  get showBackButton(): boolean {
    const a = this.state === this.taskState.default;
    const b = this.selectionMode;
    const c = this.inputValues['useBackToList'] || false;
    const d = this.inputValues.skipSelection;
    const e = this.inputValues.sideOpen;

    return !a || (c && !d) || (!b && !d) || e;
  }

  get showCloseButton(): boolean {
    return !this.showBackButton;
  }

  get notifyMailStatus(): boolean {
    if (this.taskSendObj) {
      return this.taskSendObj?.notifyViaEmail || false;
    } else if (this.taskData) {
      return this.taskData?.notifyViaEmail || false;
    }
    return false;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get buttonDisabled() {
    return (!this.taskData.customerContact && !this.customerContact) || this.isInvalidDate;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get hasDataFieldData(): boolean {
    return !!this.dataFieldData?.dataFieldGroups && this.dataFieldData.dataFieldStatus !== DataFieldStatusEnum.none;
  }

  get contactName(): string {
    return getContactName(this.taskData.customerContact);
  }
}
