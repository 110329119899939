<ng-template #alert>
  <div class="kf-notification-content" kfNotification kfNotificationStyle="Alert">
    <kf-icon name="ic_ausrufezeichen_mit_kreis_24"></kf-icon>
    <span class="kf-notification-text"
      >Sie sind gerade offline. Bitte stellen Sie wieder eine Internetverbindung her.</span
    >
  </div>
</ng-template>

<!-- Temporary - Neutral Notification -->
<ng-template #snackBarNeutral>
  <div class="kf-notification-content" kfNotification>
    <kf-icon [name]="snackBarIcon"></kf-icon>
    <span class="kf-notification-text">{{ snackBarMessage }}</span>
  </div>
</ng-template>

<!-- Temporary - Alert Notification -->
<ng-template #snackBarAlert>
  <div class="kf-notification-content" kfNotification kfNotificationStyle="Alert">
    <kf-icon [name]="snackBarIcon"></kf-icon>
    <span class="kf-notification-text">{{ snackBarMessage }}</span>
  </div>
</ng-template>

<!-- Temporary - Success Notification -->
<ng-template #snackBarSuccess>
  <div class="kf-notification-content" kfNotification kfNotificationStyle="Success">
    <kf-icon [name]="snackBarIcon"></kf-icon>
    <span class="kf-notification-text">{{ snackBarMessage }}</span>
  </div>
</ng-template>

<!-- Temporary - Error Notification -->
<ng-template #snackBarError>
  <div class="kf-notification-content" kfNotification kfNotificationStyle="Error">
    <kf-icon [name]="snackBarIcon"></kf-icon>
    <span class="kf-notification-text">{{ snackBarMessage }}</span>
  </div>
</ng-template>
