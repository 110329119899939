import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { FooterAction } from '@de.fiduciagad.kbm/shared-footer-lib';
import { ConsultationDocumentsResponse } from '@domain/app/media.domain';
import { DocumentUploadedByEnum } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { color } from '@utils/helpers/color';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'documents-viewer',
  templateUrl: './documents-viewer.component.html',
  styleUrls: ['./documents-viewer.component.scss'],
  standalone: false,
})
export class DocumentsViewerComponent implements OnInit {
  public errorMessage: string = '';

  public imagePreviewSrc: SafeUrl;
  public pdfPreview: SafeUrl;

  public uploadPending: boolean = false;
  public fetchingDocumentPending: boolean = false;
  public documentsUploadedByEnum = DocumentUploadedByEnum;
  public currentDocumentUploadedBy: DocumentUploadedByEnum;

  private destroySubs = new Subject<void>();
  private documentsList: ConsultationDocumentsResponse[] = [];
  private loading: boolean = false;

  public loadingFileIsDone: boolean;

  readonly color = color;

  @Input() inCheckout: boolean = false;

  @ViewChild('input') fileInput;
  @ViewChild('imagePreview') imagePreview;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp') && this.previewMode && this.checkIfBackwardsPossible) {
      this.onPreviousDocumentClick();
    } else if (
      (event.key === 'ArrowRight' || event.key === 'ArrowDown') &&
      this.previewMode &&
      this.checkIfForwardPossible
    ) {
      this.onNextDocumentClick();
    }
  }

  constructor(
    private documentService: DocumentService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private actionService: ActionService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
    });

    this.documentService.currentDocumentFile.pipe(takeUntil(this.destroySubs)).subscribe(currentDocumentFile => {
      currentDocumentFile ? this.prepareFile(currentDocumentFile) : this.resetViewer();
    });

    this.documentService.currentDocuments
      .pipe(takeUntil(this.destroySubs))
      .subscribe(currentDocuments => (this.documentsList = currentDocuments));

    this.documentService.uploadPending.pipe(takeUntil(this.destroySubs)).subscribe(uploadPending => {
      uploadPending === true && (this.uploadPending = uploadPending);
    });

    this.documentService.fetchingDocumentPending
      .pipe(takeUntil(this.destroySubs))
      .subscribe(fetchingDocumentPending => {
        fetchingDocumentPending === true && (this.fetchingDocumentPending = fetchingDocumentPending);
      });

    this.documentService.currentDocument.pipe(takeUntil(this.destroySubs)).subscribe(currentDocument => {
      if (currentDocument) {
        this.currentDocumentUploadedBy = currentDocument.uploadedBy;
      }
    });

    this.documentService.errorOccured.subscribe(errorOccured => {
      if (errorOccured === true) {
        this.uploadPending = false;
        this.documentService.errorOccured.next(false);
      }
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }
      if (event.id === 'document-delete') {
        this.onDeleteClick(null);
      } else if (event.id === 'document-prev' && this.checkIfBackwardsPossible) {
        this.onPreviousDocumentClick();
      } else if (event.id === 'document-next' && this.checkIfForwardPossible) {
        this.onNextDocumentClick();
      }
    }
  }

  private prepareFile(file: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(file); //FileStream response from .NET core backend
    reader.onload = () => {
      if (this.isPdf(file)) {
        this.pdfPreview = reader.result;
        this.imagePreviewSrc = '';
        this.fetchingDocumentPending = false;
      } else {
        this.imagePreviewSrc = this.sanitizer.bypassSecurityTrustUrl(`${reader.result}`);
        this.pdfPreview = '';
        this.fetchingDocumentPending = false;
      }
      this.uploadPending = false;
    };
  }

  private isPdf(currentDocumentFile: Blob) {
    return currentDocumentFile.type === 'application/pdf';
  }

  private resetViewer(): void {
    this.imagePreviewSrc = '';
    this.pdfPreview = '';
  }

  public onCloseClick() {
    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then();
  }

  public onDeleteClick(ev: Event) {
    const data = {
      copyText: `Möchten Sie die Datei ${this.documentService.currentDocument.getValue().filename} endgültig löschen?`,
      denyText: 'Nein',
      confirmText: 'Ja',
      context: 'Dokumente',
    } as DialogConfirmData;

    let dialogRef = this.dialogService.openDialogConfirm(data);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirmed === true) {
        this.documentService.deselectDocument();
      } else {
        dialogRef = null;
      }
    });
  }

  public onNextDocumentClick() {
    this.documentService.setNextDocument('next');
  }

  public onPreviousDocumentClick() {
    this.documentService.setNextDocument('previous');
  }

  get previewMode() {
    return this.imagePreviewSrc || this.pdfPreview;
  }

  get checkIfForwardPossible() {
    const currentDocument = this.documentService.currentDocument?.getValue();
    if (currentDocument) {
      return this.documentsList.indexOf(currentDocument) + 1 < this.documentsList.length;
    } else {
      return false;
    }
  }
  get checkIfBackwardsPossible() {
    const currentDocument = this.documentService.currentDocument?.getValue();
    if (currentDocument) {
      return this.documentsList.indexOf(currentDocument) - 1 >= 0;
    } else {
      return false;
    }
  }
}
