import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

/// ------------------------------------------------ ///
/// ---------------- RIGHT BUTTONS ----------------- ///
/// ------------------------------------------------ ///

export const summaryButtonContinue: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'summary-continue',
    config: {
      type: ButtonType.FLAT,
      label: 'Weiter',
      icon: 'ic_pfeil_rechts_24',
      iconPosition: 'after',
    },
  },
};

export const summaryButtonBack: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'summary-back',
    config: {
      type: ButtonType.STROKED,
      icon: 'ic_pfeil_links_24',
    },
  },
};
