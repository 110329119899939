import { Pipe, PipeTransform } from '@angular/core';
import { Notes } from '@domain/notes';
import PageReference = Notes.PageReference;

@Pipe({
  name: 'pageReference',
})
export class PageReferencePipe implements PipeTransform {
  transform(value: PageReference): string {
    return 'Agenda';
  }
}
