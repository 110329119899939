import { LocationStrategy } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ActivatedRoute, ExtraOptions, Router, RouterModule, Routes } from '@angular/router';
import { overlayRoutes } from '@overlays/overlay.module';
import { screenRoutes } from '@screens/screens.module';
import { StorageService } from '@services/storage-service/storage.service';
import { CustomLocationStrategy } from './custom-location-strategy';

@Component({
  selector: 'redirector',
  template: '',
})
export class Redirector {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {
    storageService.remove('bgzvHubId');
    router.navigate(['error'], { state: { reason: 'invalidUrl' } });
  }
}

const routes: Routes = [
  ...screenRoutes,
  ...overlayRoutes,
  { path: 'gk4w4h', component: Redirector, pathMatch: 'full' },
  { path: '**', redirectTo: 'gk4w4h' },
];

const options: ExtraOptions = { useHash: true };

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  providers: [{ provide: LocationStrategy, useClass: CustomLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
