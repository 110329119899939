import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CustomerContactRequestItem, CustomerContactResponseItem } from '@domain/app/contact.domain';
import { ContactForm, OptionData, TaskFormIndividual } from '@domain/app/forms.domain';
import { AssignIndividualTaskItem, TaskResponse } from '@domain/app/task.domain';
import { PriorityEnum, RoutingPathMain, RoutingPathOverlay } from '@enums';
import {
  BadgeData,
  TaskAndTransitionInput,
  TaskAndTransitionStateEnum,
  formEmailPattern,
} from '@marginals/transition-task-utils';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color } from '@utils/helpers/color';
import { getContactSuffix } from '@utils/helpers/getContactSuffix';
import { trimDropdownLabel } from '@utils/helpers/trimDropdownLabel';
import { formOfAddress } from '@utils/settings';
import moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'side-task-indiv',
  templateUrl: './side-task-indiv.component.html',
  styleUrls: ['./side-task-indiv.component.scss'],
  standalone: false,
})
export class SideTaskIndivComponent implements OnInit {
  @Input() inputValues: TaskAndTransitionInput;

  private _animationSub: Subscription;

  public taskData: TaskResponse;
  public badgeData: BadgeData;

  public contactsList: OptionData[] = [];
  public existingCustomerContacts: CustomerContactResponseItem[];
  public customerContact: CustomerContactRequestItem;
  public taskSendObj: AssignIndividualTaskItem;

  public contactForm: FormGroup<ContactForm>;
  public taskForm: FormGroup<TaskFormIndividual>;
  public dateForm: FormGroup;
  public contactSubmitted = false;
  readonly formEmailPattern = formEmailPattern;

  public taskState = TaskAndTransitionStateEnum;
  public state = TaskAndTransitionStateEnum.default;

  public contactsFormOfAddress = formOfAddress;

  readonly moment = moment;
  readonly color = color;

  public minimumDate: Date;
  public appointmentDate: Date = null;
  public appointmentDateString: string;
  public appointmentTime;
  public selectedAppointmentTime;
  private madeSelection: any;
  private previousDate: Date;
  public bg2Theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: this.color.find('--color-grey-100'),
      buttonColor: this.color.find('--color-primary-500'),
    },
    dial: {
      dialBackgroundColor: this.color.find('--color-primary-500'),
    },
    clockFace: {
      clockFaceBackgroundColor: this.color.find('--color-grey-200'),
      clockHandColor: this.color.find('--color-primary-500'),
      clockFaceTimeInactiveColor: this.color.find('--color-grey-500'),
    },
  };

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService,
    private formBuilder: FormBuilder,
    private readonly formValidationService: FormValidationService
  ) {}

  ngOnInit(): void {
    this.minimumDate = moment({}).add(1, 'day').toDate();

    this.taskSendObj = {
      name: null,
      notifyViaEmail: false,
      individualNote: '',
      priority: PriorityEnum.normal,
      customerContact: {} as CustomerContactRequestItem,
    } as AssignIndividualTaskItem;

    if (this.inputValues && this.inputValues?.subtopicId !== '-1') {
      this.taskSendObj.subtopicId = this.inputValues?.subtopicId || '-1';
    }

    this.rightSidenavService.open();
    this._animationSub = this.rightSidenavService.instance.openedStart.subscribe(() => {
      this.queryTaskData();
      this._animationSub.unsubscribe();
    });

    if (this.inputValues.taskId === '-2') {
      // taskId has to be -1 here, we track where we come from with -2
      this.inputValues.taskId = '-1';
      this.queryTaskData();
    }

    this.contactForm = this.formBuilder.group({
      formOfAddress: new FormControl<string | null>('', Validators.required),
      firstName: new FormControl<string | null>('', Validators.required),
      lastName: new FormControl<string | null>('', Validators.required),
      email: new FormControl<string | null>(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.formEmailPattern)])
      ),
    });

    this.taskForm = this.formBuilder.group({
      name: new FormControl<string | null>('', Validators.required),
      customerContact: new FormControl<string | null>('', Validators.required),
      individualNote: new FormControl<string | null>(''),
    });
    this.dateForm = this.formBuilder.group({ date: new FormControl<string | null>('') });
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public handleClose() {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.rightSidenavService.close();
    }
  }

  public onBadgeClicked(type, id, event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  get contactFormControls() {
    return this.contactForm.controls;
  }

  get taskFormControls() {
    return this.taskForm.controls;
  }

  // ---
  // ---
  // ---
  // ---
  // ---
  // ---

  public onContactSelected(event: MatSelectChange) {
    const id = event.value;
    if (!!id === false) {
      return;
    }
    if (id.includes('___')) {
      const name = id.split('___')[1].split('###');
      const contact = this.clientService.sessionContacts.find(x => x.firstName === name[0] && x.lastName === name[1]);
      this.customerContact = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        formOfAddress: contact.formOfAddress,
      } as CustomerContactRequestItem;
    } else {
      const contact = this.existingCustomerContacts.find(x => (x.id || x.expertContactId) === id);
      this.customerContact = {
        id: contact?.id,
        expertContactId: contact?.expertContactId,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        formOfAddress: contact.formOfAddress,
      } as CustomerContactRequestItem;
    }
    this.contactsList.forEach(x => {
      x.checked = x.value === id;
    });

    this.taskSendObj.customerContact = this.customerContact;
  }

  public onTaskName(taskName: string) {
    this.taskSendObj.name = taskName;
  }

  public onNoteAdded(individualNote: string) {
    this.taskSendObj.individualNote = individualNote;
  }

  public onDatepickSelected() {
    this.madeSelection = false;
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickUpdate() {
    this.madeSelection = false;
    this.previousDate = this.appointmentDate;
    this.onDateSelect(this.appointmentDate);
    this.state = TaskAndTransitionStateEnum.datepick;
  }

  public onDatepickDeleted() {
    this.appointmentDateString = null;
    this.appointmentDate = null;
    this.appointmentTime = null;
    this.taskSendObj.appointmentDate = null;
  }

  public onEmailNotificationSelected(value) {
    this.taskSendObj.notifyViaEmail = value;
  }

  public onAbortAppointment() {
    if (this.madeSelection) {
      this.appointmentDate = this.previousDate || null;
      this.selectedAppointmentTime = moment(this.appointmentDate || {});
    }
    this.state = this.taskState.default;
  }

  public onFinalizeTask() {
    if (!this.customerContact.id || !this.customerContact.expertContactId) {
      this.clientService.removeSessionContact(this.customerContact);
    }

    this.queryService.putAssignIndividualTask(this.clientService.consultationId, this.taskSendObj).subscribe(x => {
      this.handleClose();
      this.notifyMainComponents();
    });
  }

  // ---
  // ---
  // ---
  // ---
  // ---
  // ---

  public onTaskCommentAdded(taskComment: string) {
    this.taskSendObj.individualNote = taskComment;
  }

  public onDateSelect(event: any) {
    this.madeSelection = true;
    this.appointmentDate = event;
    this.selectedAppointmentTime = moment(this.appointmentDate || {});
  }

  public onAppointmentConfirmed() {
    const dayString = moment(this.selectedAppointmentTime).format('DD. MMMM YYYY');
    this.appointmentDateString = dayString;
    this.state = TaskAndTransitionStateEnum.default;

    this.taskSendObj.appointmentDate = moment(this.selectedAppointmentTime).format('YYYY-MM-DD');
  }

  public onDateDirectSelect(event: any) {
    const dayString = moment(event).format('DD. MMMM YYYY');
    this.appointmentDateString = dayString;
    this.taskSendObj.appointmentDate = moment(event).format('YYYY-MM-DD');
  }

  // --------------------------------------------- //
  // -------- CUSTOMER TEMPLATE FUNCTIONS -------- //
  // --------------------------------------------- //

  public onContactAdded() {
    this.customerContact = {} as CustomerContactRequestItem;
    this.customerContact.formOfAddress = this.contactFormControls.formOfAddress.value;
    this.customerContact.firstName = this.contactFormControls.firstName.value;
    this.customerContact.lastName = this.contactFormControls.lastName.value;
    this.customerContact.email = this.contactFormControls.email.value;

    this.clientService.addSessionContact(this.customerContact);

    this.contactsList.every(x => (x.checked = false));
    const listItem = {} as OptionData;
    listItem.label = `${this.customerContact.formOfAddress} ${this.customerContact.firstName} ${this.customerContact.lastName}`;
    listItem.label = trimDropdownLabel(listItem.label, 58);
    const value = '___' + this.customerContact.firstName + '###' + this.customerContact.lastName;
    listItem.value = value;
    listItem.checked = false;
    this.customerContact.newCustomerId = value;

    const contactExists = this.contactsList.find(x => x.label === listItem.label);

    if (!contactExists) {
      listItem.checked = true;
      this.contactsList.push(listItem);
      this.contactForm.reset();
      this.taskFormControls.customerContact.patchValue(this.customerContact.newCustomerId);
    }

    this.taskSendObj.customerContact = this.customerContact;
    this.goToDefault();
  }

  public goToDefault(): void {
    this.state = TaskAndTransitionStateEnum.default;
    this.handleTempDate();
  }

  public resetDate(): void {
    this.taskSendObj.appointmentDate = null;
    this.dateForm.get('date').setValue(null);
  }

  get customerValue(): string {
    return this.customerContact ? '___' + this.customerContact.firstName + '###' + this.customerContact.lastName : '';
  }

  get notifyMailStatus() {
    if (this.taskSendObj) {
      return this.taskSendObj?.notifyViaEmail || false;
    } else if (this.taskData) {
      return this.taskData?.notifyViaEmail || false;
    }
    return false;
  }

  get isInvalidDate(): boolean {
    return this.taskSendObj?.appointmentDate ? new Date(this.taskSendObj.appointmentDate) <= new Date() : false;
  }

  // ---
  // ---
  // ---
  // ---
  // ---
  // ---

  private notifyMainComponents() {
    this.clientService.setSolutionBasketCount();
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Cart) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  private handleTempDate() {
    if (this.taskSendObj?.appointmentDate) {
      this.dateForm.get('date').setValue(this.taskSendObj?.appointmentDate);
    } else {
      this.dateForm.get('date').setValue(this.taskData?.appointmentDate);
    }
  }

  private queryTaskData() {
    if (!this.inputValues.taskId || this.inputValues.taskId === '-1') {
      this.contactsDropdownFactory();
      return;
    }

    this.queryService
      .getIndividualTaskById(this.clientService.consultationId, this.inputValues.taskId)
      .subscribe(data => {
        this.taskData = data;

        Object.assign(this.taskSendObj, data);

        this.contactsDropdownFactory();
        this.appointmentDateFactory();

        this.taskFormControls.name.setValue(this.taskSendObj.name);
        this.taskFormControls.customerContact.setValue(this.taskSendObj.customerContact.id);
      });

    this.badgeData = {
      id: -1,
      type: 'product',
      label: '',
      bgColor: color.find('--color-grey-200'),
      textColor: color.find('--color-primary-500'),
    };
  }

  private contactsDropdownFactory(newContact = null) {
    this.queryService.getCustomerContacts(this.clientService.consultationId).subscribe(data => {
      this.existingCustomerContacts = data;
      let custId;

      switch (this.taskData?.customerContact.customerContactType) {
        case 'CONSULTANT': {
          custId = this.taskData.customerContact.id;
          break;
        }
        case 'EXPERT': {
          custId = this.taskData.customerContact.expertContactId;
          break;
        }
        default: {
          custId = '-1';
        }
      }
      for (const contact of this.existingCustomerContacts) {
        const consultantSuffix = getContactSuffix(contact.customerContactType);
        const contactExists = this.contactsList.find(
          x => x.label.search(`${contact.firstName} ${contact.lastName}${consultantSuffix}`) !== -1
        );

        if (!contactExists) {
          const listItem = {
            label: `${contact.formOfAddress ? contact.formOfAddress : ''} ${contact.firstName} ${contact.lastName}${consultantSuffix}`,
            value: contact.id || contact.expertContactId,
            checked: custId === contact.id,
          } as OptionData;

          listItem.label = trimDropdownLabel(listItem.label, 58);
          this.contactsList.push(listItem);

          if (custId === (contact?.id || contact?.expertContactId)) {
            this.customerContact = {} as CustomerContactRequestItem;
            this.customerContact.id = contact?.id;
            this.customerContact.expertContactId = contact?.expertContactId;
            this.customerContact.formOfAddress = contact.formOfAddress;
            this.customerContact.firstName = contact.firstName;
            this.customerContact.lastName = contact.lastName;
            this.customerContact.email = contact.email;
          }

          if (listItem.checked) {
            this.taskFormControls.customerContact.setValue(this.customerContact.id);
          }
        }
      }

      const listItem = {} as OptionData;
      this.clientService.sessionContacts.forEach(contact => {
        listItem.label = contact.firstName + ' ' + contact.lastName;
        listItem.value = '___' + contact.firstName + '###' + contact.lastName;
        listItem.checked = false;

        this.contactsList.push(listItem);
      });
    });
  }

  private appointmentDateFactory() {
    this.appointmentDate = null;
    if (this.taskData.appointmentDate) {
      const dayString = moment(this.taskData.appointmentDate).format('DD MMMM YYYY');
      this.appointmentDateString = dayString;
      this.appointmentDate = moment(this.taskData.appointmentDate).toDate();

      this.dateForm.get('date').setValue(this.taskData.appointmentDate);
    }
  }

  get testcafeAttribute() {
    const hasTask = this.inputValues.taskId && this.inputValues.taskId !== '-1';
    return hasTask ? 'sideTaskIndiv-button-changeTask' : 'sideTaskIndiv-button-createTask';
  }

  get buttonDisabled() {
    if (!this.customerContact || this.taskSendObj.name === null || this.taskSendObj.name === '' || this.isInvalidDate) {
      return true;
    } else {
      return false;
    }
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
