<mat-radio-group
  [attr.aria-label]="'Bitte wählen Sie Ihre Antwort zu: ' + question.name + ' aus'"
  name="segment_{{ question.id }}"
  class="d-flex flex-wrap segmented-radio-group m-n2"
>
  @for (radio of elements; track radio) {
    <mat-radio-button
      [value]="radio.id"
      [disabled]="loading"
      [id]="radio.id + ''"
      [checked]="radio.checked"
      color="primary"
      (change)="valueChanged($event)"
      (focus)="handleFocus($event, radio)"
      (keyup)="handleKeyup($event, label)"
    >
      <span #label>{{ radio.label }}</span>
    </mat-radio-button>
  }
</mat-radio-group>
