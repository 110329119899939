import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { PaymentInterval, VatEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';

@Pipe({
  name: 'tax',
  standalone: false,
})
export class TaxCalculationPipe implements PipeTransform {
  hasVatDiscount = false;

  constructor(
    private currencyPipe: CurrencyPipe,
    private clientService: ClientService
  ) {
    this.hasVatDiscount = this.clientService.hasVatDiscount;
    this.clientService.vatDiscountChanged.subscribe(newVatValue => {
      this.hasVatDiscount = newVatValue;
    });
  }

  public transform(price: number, taxType: VatEnum = null, formatted = true, formatLang = 'de') {
    let result = price;
    if (taxType === VatEnum.vat0) {
      result = price;
    } else if (taxType === VatEnum.vat7) {
      result = price * 1.07;
    } else if (taxType === VatEnum.vat19) {
      result = price * 1.19;
    } else if (taxType === VatEnum.vat19optional) {
      const rate = this.hasVatDiscount ? 1.19 : 1;
      result = price * rate;
    } else if (taxType === VatEnum.vatSaving) {
      result = price;
    } else {
      result = price;
    }

    return formatted ? this.currencyPipe.transform(result.toFixed(2), 'EUR', '', '1.2-2', formatLang) + ' €' : result;
  }
}

@Pipe({
  name: 'payInterval',
  standalone: false,
})
export class PaymentIntervalPipe implements PipeTransform {
  constructor() {}

  transform(interval: PaymentInterval) {
    if (interval === PaymentInterval.once) {
      return 'Einmalig';
    } else if (interval === PaymentInterval.fixed) {
      return 'Festpreis';
    } else if (interval === PaymentInterval.expense) {
      return 'Nach Aufwand';
    } else if (interval === PaymentInterval.monthly) {
      return 'Monatlich';
    } else if (interval === PaymentInterval.annually) {
      return 'Jährlich';
    } else if (interval === PaymentInterval.customOffer) {
      return 'Individuelles Angebot';
    }
    return '';
  }
}
