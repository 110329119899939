<div class="d-flex flex-column h-100 w-100 kf-bg">
  <marginals-header tabindex="-1" class="w-100"></marginals-header>
  <div class="d-flex h-100 flex-scroll">
    @if (step === 0 || (step === 2 && isMainMode)) {
      <section-topics
        [step]="step"
        [data]="consultationSubtopics"
        [isPrelim]="false"
        [headline]="headline"
        [profileData]="profileData"
        [maximumBubbles]="maximumBubbles"
        [expandedTopics]="expandedTopics"
        [updateInProgress]="updateInProgress"
        (expandTopic)="onTopicExpanded($event)"
        (topicSelected)="onTopicSelected($event)"
        (topicDeselect)="onTopicDeselect($event, 'consultation')"
        (subtopicSelected)="onSubtopicSelected($event, 'consultation')"
        (subtopicDeselected)="onSubtopicDeselected($event, 'consultation')"
        (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'consultation')"
      ></section-topics>
    }
    @if (step === 1 && isPrepMode && consultantSubtopics.available.length > 0) {
      <section-topics
        [step]="step"
        [data]="consultantSubtopics"
        [isPrelim]="false"
        [headline]="headline"
        [profileData]="profileData"
        [maximumBubbles]="maximumBubbles"
        [expandedTopics]="expandedTopics"
        [updateInProgress]="updateInProgress"
        (expandTopic)="onTopicExpanded($event)"
        (topicSelected)="onTopicSelected($event)"
        (topicDeselect)="onTopicDeselect($event, 'consultant')"
        (subtopicSelected)="onSubtopicSelected($event, 'consultant')"
        (subtopicDeselected)="onSubtopicDeselected($event, 'consultant')"
        (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'consultant')"
      ></section-topics>
    }
    @if (step === 2 && isPrepMode && customerSubtopics.available.length > 0) {
      <section-topics
        [step]="step"
        [data]="customerSubtopics"
        [headline]="headline"
        [isPrelim]="step === 2 && isPrepMode"
        [profileData]="profileData"
        [expandedTopics]="expandedTopics"
        [maximumBubbles]="maximumBubbles"
        [updateInProgress]="updateInProgress"
        (expandTopic)="onTopicExpanded($event)"
        (topicSelected)="onTopicSelected($event)"
        (topicDeselect)="onTopicDeselect($event, 'customer')"
        (subtopicSelected)="onSubtopicSelected($event, 'customer')"
        (subtopicDeselected)="onSubtopicDeselected($event, 'customer')"
        (subtopicsReordered)="onSelectedSubtopicsReordered($event, 'customer')"
      ></section-topics>
    }
  </div>
</div>
