import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { NumToStrPipe, StrToNumPipe } from '@utils/pipes/numberPipe.pipe';

@Component({
  selector: 'answer-number-input',
  templateUrl: './answer-number-input.component.html',
  styleUrl: './answer-number-input.component.scss',
  imports: [CommonModule, KfThemeModule, MaterialModule, MatInputModule, ReactiveFormsModule],
})
export class AnswerNumberInputComponent implements OnInit {
  @Input() id: string = '';
  @Input() unit: string = '';
  @Input() label: string = 'Ihre Eingabe';
  @Input() value: number | undefined;
  @Input() disabled: boolean = false;
  @Input() minValue: number = -10000000000000;
  @Input() maxValue: number = 10000000000000;
  @Input() stepValue: number = 0;
  @Input() maxDecimals: number = 2;
  @Input() showTooltip: boolean = false;
  @Input() testcafeData: string;
  @Input() formControlName: string = 'numberInput';
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('input') input!: ElementRef;

  public formGroup: FormGroup = new FormGroup({
    [this.formControlName]: new FormControl<string>(''),
  });
  private funcKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Enter'];
  private numKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  private mathKeys = ['-', ','];
  private strValue: string = '';
  private numValue: number = 0;

  constructor(
    private strToNumPipe: StrToNumPipe,
    private numToStrPipe: NumToStrPipe
  ) {}

  ngOnInit(): void {
    if (this.value !== undefined) {
      this.numValue = this.value;
      this.setValue(this.numValue);
    }
  }
  /**
   * filter the keyboard input:
   * - handle arrow keys to increase or decrease the value
   * - check the input length before and after the decimal separator
   * - allow only numbers, function keys and math keys
   * - allow '-' at the first position only
   * - avoid duplicates of math keys
   * @param keyboard event - pressed key (onkeydown)
   * @returns true if the key is allowed
   */
  public checkKey(event: KeyboardEvent): boolean {
    const key = event.key;
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      this.setStep(key === 'ArrowUp' ? 1 : -1);
      return true;
    }
    const input = this.input.nativeElement as HTMLInputElement;
    const tooLong = this.numKeys.includes(key) && this.checkInputLength(input, key);
    const allowedKey = [...this.funcKeys, ...this.numKeys, ...this.mathKeys].includes(key);
    const duplicateKey = this.mathKeys.find(x => key === x && input.value.includes(x));
    const wrongPosition = key === '-' && input.selectionStart > 0;
    return !tooLong && allowedKey && !wrongPosition && !duplicateKey;
  }

  /**
   * handle input change: check if the input is valid and update the value
   * @param event - input event (onchange)
   */
  public handleChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const invalidInput = ['-', ',', '-,'];
    if (invalidInput.includes(input.value)) {
      this.setValue(0);
    } else {
      const value = this.strToNumPipe.transform(input.value);
      this.setValue(value);
    }
  }

  /**
   * increase or decrease the value by the step
   * @param dir - direction to increase or decrease the value
   */
  public setStep(dir: 1 | -1): void {
    const value = this.numValue + dir * this.stepValue;
    this.setValue(value);
  }

  /**
   * check if the value is in given range and set the new value
   * if old value equals new value reset strValue
   * @param value - new value to set
   */
  public setValue(value: number): void {
    value = Math.min(value, this.maxValue);
    value = Math.max(value, this.minValue);

    this.numValue = value;
    this.strValue = this.numToStrPipe.transform(value, this.maxDecimals);
    this.formGroup.get(this.formControlName).setValue(this.strValue);
    this.valueChange.emit(this.numValue);
  }

  /** generate id for testcafe
   * @param item - item name
   * @returns testcafe id
   */
  public testcafeId(item: string): string {
    return `item-counter-${item}-${this.testcafeData}`;
  }

  public onSubmit(): boolean {
    return false;
  }

  /**
   * check input length before or after the decimal separator depending on the cursor position
   * @param input - input field as HTMLInputElement
   * @param key - pressed key as string
   * @returns true if the concerned input part is too long
   */
  private checkInputLength(input: HTMLInputElement, key: string): boolean {
    const position = input.selectionStart;
    const value = input.value + key;
    const parts = value.replace('-', '').split(',');
    const before = parts[0].length;
    const after = parts[1]?.length;

    return position <= before // before or after the decimal separator
      ? before > this.maxValue?.toString().length
      : after > this.maxDecimals;
  }

  get disabledMin(): boolean {
    return this.numValue <= this.minValue;
  }

  get disabledMax(): boolean {
    return this.numValue >= this.maxValue;
  }

  get tooltipMinus(): string {
    return this.disabledMin ? '' : 'Wert verringern';
  }

  get tooltipPlus(): string {
    return this.disabledMax ? '' : 'Wert erhöhen';
  }
}
