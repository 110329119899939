<aside
  *ngIf="!isQuestionField"
  class="position-relative app-height side-profile"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  cdkFocusInitial
  tabindex="0"
>
  <ng-container *ngIf="userProfile">
    <ng-container *ngIf="!useQuestionDataFields">
      <ng-template *ngTemplateOutlet="header"></ng-template>
      <ng-template *ngTemplateOutlet="content"></ng-template>
      <ng-template *ngTemplateOutlet="footer"></ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="useQuestionDataFields">
    <ng-template *ngTemplateOutlet="headerQuestionDataFields"></ng-template>
    <ng-template *ngTemplateOutlet="contentQuestionDataFields"></ng-template>
  </ng-container>

  <button
    *ngIf="!editModeActive"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="onClose()"
    [attr.data-testcafe]="'sideProfile-button-close'"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<ng-container *ngIf="isQuestionField && useQuestionDataFields">
  <ng-template *ngTemplateOutlet="contentQuestionDataFields"></ng-template>
</ng-container>

<!-- CONTENT questionDataFields -->
<ng-template #contentQuestionDataFields>
  <div class="side-container pr-4">
    <ng-container *ngIf="questionDataFields?.length < 1">
      <p>Es sind keine Daten vorhanden.</p>
    </ng-container>

    <ng-container *ngIf="questionDataFields?.length > 0">
      <div *ngFor="let questionDataField of questionDataFields" class="mat-small mb-4 pb-2">
        <h3
          *ngIf="questionDataField.showGroupName"
          class="mat-body-1 font-weight-bold mb-3"
          [attr.data-testcafe]="'sideProfile-header-' + questionDataField.groupName"
        >
          {{ questionDataField.groupName }}
        </h3>
        <profile-data-field-group-plain
          *ngIf="questionDataField.template === 'PLAIN'"
          [dataFieldGroup]="questionDataField"
        >
        </profile-data-field-group-plain>
        <profile-data-field-group-table
          *ngIf="questionDataField.template === 'TABLE'"
          [dataFieldGroup]="questionDataField"
        >
        </profile-data-field-group-table>
        <profile-data-field-group-list
          *ngIf="questionDataField.template === 'LIST'"
          [dataFieldGroup]="questionDataField"
        >
        </profile-data-field-group-list>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- CONTENT -->
<ng-template #content>
  <div class="side-container">
    <ng-container *ngIf="editModeActive">
      <side-profile-editor
        #profileEditor
        [userProfileData]="userProfile"
        (editorClosed)="onCloseEditor($event)"
        (formValid)="setFormValid($event)"
      ></side-profile-editor>
    </ng-container>

    <ng-container *ngIf="!editModeActive">
      <mat-expansion-panel
        class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
        *ngFor="let profileGroup of userProfile.profileGroups; index as i"
        #profileGroupPanel
        (opened)="panelOpen['profileGroup' + i] = true"
        (closed)="panelOpen['profileGroup' + i] = false"
        [id]="'profileGroup' + i"
        [expanded]="isPanelOpen('profileGroup' + i)"
        displayMode="'flat'"
      >
        <mat-expansion-panel-header>
          <div class="d-flex">
            <mat-panel-title
              [attr.data-testcafe]="'sideProfile-matHeader-' + getTestcafeId(profileGroup.category | profileGroupName)"
            >
              <strong class="font-weight-bold">{{ profileGroup.category | profileGroupName }}</strong>
            </mat-panel-title>
            <div class="tag-info tag-neutral ml-3">
              <div class="tag-info-text">
                {{ displayableDataFieldGroups(profileGroup)?.length }}
                {{ displayableDataFieldGroups(profileGroup)?.length === 1 ? 'Datensatz' : 'Datensätze' }}
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let dataFieldGroup of displayableDataFieldGroups(profileGroup); index as j">
          <mat-expansion-panel
            class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
            (opened)="panelOpen['dataFieldGroup' + i + j] = true"
            (closed)="panelOpen['dataFieldGroup' + i + j] = false"
            [id]="'dataFieldGroup' + i + j"
            [expanded]="i === 0 && j === 0"
            displayMode="'flat'"
            [ngClass]="isPanelOpen('dataFieldGroup' + i + j) ? 'mat-panel-opened' : null"
          >
            <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem">
              <mat-panel-title>
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center justify-content-start">
                    <strong
                      *ngIf="dataFieldGroup.showGroupName"
                      class="mr-2 font-weight-bold"
                      [attr.data-testcafe]="'sideProfile-header-' + dataFieldGroup.groupName"
                    >
                      {{ dataFieldGroup.groupName }}
                    </strong>
                    <ng-container *ngIf="['TABLE', 'LIST', 'PLAIN'].includes(dataFieldGroup.template)">
                      <div class="tag-info tag-neutral mb-1 ml-3">
                        <div class="tag-info-text">
                          {{
                            dataFieldGroup.template === 'PLAIN'
                              ? dataFieldGroup?.dataFields.length
                              : dataFieldGroup?.dataFields[0].values.length
                          }}<span class="sr-only">{{
                            dataFieldGroup?.dataFields[0].values.length === 1 ? 'Eintrag' : 'Einträge'
                          }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <profile-data-field-group-plain
              *ngIf="dataFieldGroup.template === 'PLAIN'"
              [dataFieldGroup]="dataFieldGroup"
            >
            </profile-data-field-group-plain>
            <profile-data-field-group-table
              *ngIf="dataFieldGroup.template === 'TABLE'"
              [dataFieldGroup]="dataFieldGroup"
            >
            </profile-data-field-group-table>
            <profile-data-field-group-list *ngIf="dataFieldGroup.template === 'LIST'" [dataFieldGroup]="dataFieldGroup">
            </profile-data-field-group-list>
          </mat-expansion-panel>
        </ng-container>

        <ng-container *ngIf="profileGroup.category === 'GENERIC'">
          <div class="mat-panel-nested-level-1">
            <div class="mat-expansion-panel-body">
              <div class="mb-0">
                <p class="mb-0 mr-2"><strong>Vorsteuerabzugsberechtigt:</strong></p>
                <p class="m-0" [attr.data-testcafe]="'sideProfile-span-vatDiscount'">
                  {{ getBooleanText(userProfile.vatDiscount) }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <!-- content from an active consultation -->
      <ng-container *ngIf="userProfile.answers.length > 0">
        <mat-expansion-panel
          class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
          #answeredQuestionsPanel
          id="answers"
          [expanded]="isPanelOpen('answers')"
          displayMode="'flat'"
        >
          <mat-expansion-panel-header>
            <div class="d-flex">
              <strong class="font-weight-bold">Bisherige Antworten</strong>
              <span class="ml-2 chip-counter"
                >{{ userProfile.answers.length }} {{ userProfile.answers.length === 1 ? 'Angabe' : 'Angaben' }}</span
              >
            </div>
          </mat-expansion-panel-header>

          <dl
            class="p-3 m-0 border-bottom"
            [class.border-top]="first"
            *ngFor="let answer of userProfile.answers; first as first"
          >
            <dt class="mb-0">{{ answer.label }}</dt>
            <dd>
              <ol class="m-0">
                <li *ngFor="let givenAnswer of answer.givenAnswers" markNegativePrice>{{ givenAnswer }}</li>
              </ol>
            </dd>
          </dl>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- HEADER -->
<ng-template #headerQuestionDataFields>
  <header class="side-header side-header-low" tabindex="-1">
    <div class="d-flex justify-content-between align-items-center mt-4">
      <div class="d-flex flex-grow-1 align-items-center">
        <h2 class="w-100 mb-0">Weitere Informationen</h2>
      </div>
    </div>
  </header>
</ng-template>

<ng-template #header>
  <header class="side-header" tabindex="-1">
    <div class="d-flex justify-content-between align-items-center mt-4">
      <a
        *ngIf="userProfile.website"
        [href]="userProfile.website"
        target="_blank"
        class="d-flex side-profile__customer-logo"
      >
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </a>
      <div *ngIf="!userProfile.website" class="d-flex side-profile__customer-logo">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>

      <div class="d-flex flex-grow-1 align-items-center">
        <h2
          class="w-100 ml-4 mb-0"
          [attr.data-testcafe]="'sideProfile-div-' + getTestcafeId(userProfile.corporationName)"
        >
          {{ userProfile.corporationName }}
        </h2>
      </div>
    </div>

    <div class="d-flex anchor-buttons" [class.invisible]="editModeActive || updateInProgress">
      <ng-container *ngFor="let profileGroup of userProfile.profileGroups; index as i">
        <button mat-stroked-button color="primary" (click)="setPanelOpen('profileGroup' + i)">
          <span>{{ profileGroup.category | profileGroupName }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="userProfile.answers.length > 0">
        <button mat-stroked-button color="primary" (click)="setPanelOpen('answers')">
          <span>Bisherige Antworten</span>
        </button>
      </ng-container>
    </div>
  </header>
</ng-template>

<!-- FOOTER -->
<ng-template #footer>
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content">
        <ng-container *ngTemplateOutlet="editorOn"></ng-container>
        <ng-container *ngTemplateOutlet="editorOff"></ng-container>

        <div
          *ngIf="lastUpdated"
          class="text-right"
          [attr.data-testacfe]="'sideProfile-div-stand:' + lastUpdated.slice(0, 10)"
        >
          <p class="m-0 py-0 pl-4">Stand: {{ lastUpdated }}</p>
        </div>
      </div>
    </div>
  </footer>
</ng-template>

<!-- LOGO -->
<ng-template #logo>
  <img src="{{ userProfile.logoReference || customerLogo }}" [alt]="userProfile.corporationName" />
</ng-template>

<!-- BUTTONS EDITOR ON -->
<ng-template #editorOn>
  <ng-container *ngIf="!editModeActive">
    <button
      [attr.data-testcafe]="'sideProfile-button-edit'"
      mat-stroked-button
      color="primary"
      (click)="editModeActive = true"
      [disabled]="updateInProgress"
    >
      <span>Bearbeiten</span>
    </button>
    <button
      *ngIf="!isTestConsultation"
      mat-flat-button
      color="primary"
      (click)="startUpdate()"
      [disabled]="updateInProgress"
    >
      <mat-spinner [diameter]="24" color="primary" *ngIf="updateInProgress"></mat-spinner>
      <span *ngIf="!updateInProgress">Aktualisieren</span>
    </button>
  </ng-container>
</ng-template>

<!-- BUTTONS EDITOR OFF  -->
<ng-template #editorOff>
  <ng-container *ngIf="editModeActive">
    <button
      [attr.data-testcafe]="'sideProfile-button-closeEditor'"
      mat-stroked-button
      color="primary"
      (click)="onCloseEditor()"
    >
      <span>Abbrechen</span>
    </button>
    <button
      [attr.data-testcafe]="'sideProfile-button-saveEditor'"
      [disabled]="!formValid"
      mat-flat-button
      color="primary"
      (click)="onSave()"
    >
      <span *ngIf="!updateInProgress">Speichern</span>
      <mat-spinner [diameter]="24" color="primary" *ngIf="updateInProgress"></mat-spinner>
    </button>
  </ng-container>
</ng-template>
