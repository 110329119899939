import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrl: './progress-tracker.component.scss',
})
export class ProgressTrackerComponent {
  @Input() current: number;
  @Input()
  set total(value: number) {
    this.steps = [...Array(value).keys()];
  }

  public steps: number[];
}
