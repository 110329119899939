import { VariantCalculator } from '@domain/app/variant-calculator';
import { VariantCalculatorProductDetails } from '@services/product-variant-calculator/product-variant-calculator';
import VariantCalculatorItemDTO = VariantCalculator.VariantCalculatorItemDTO;

export function calculateProductPrices(
  products: VariantCalculatorProductDetails[],
  dimensionValues: { [inputId: number]: number | boolean }
): { [productId: string]: number } {
  return Object.fromEntries(
    products.map(product => [
      product.productId,
      calculateProductPrice(product, dimensionValues) + product.productBasePrice,
    ])
  );
}

export function calculateProductPrice(
  product: VariantCalculatorProductDetails,
  dimensionValues: { [inputId: number]: number | boolean }
): number {
  return product.items
    .map(dimension => calculateDimensionPrice(dimension, dimensionValues[dimension.inputId]))
    .reduce((x, y) => x + y, 0);
}

export function calculateDimensionPrice(dimension: VariantCalculatorItemDTO, dimensionValue: number | boolean): number {
  if (typeof dimensionValue === 'number') {
    const itemsToPay = Math.max(dimensionValue - dimension.freeItems, 0);
    const price = dimension.itemPrice * itemsToPay;
    return price;
  } else {
    const price = dimensionValue ? dimension.itemPrice : 0;
    return price;
  }
}
