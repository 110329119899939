import { Injectable } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FgiPdfViewerService } from '@de.fiduciagad.kundenportal/fgi-pdf-viewer';
import { MediaItem } from '@domain/app/media.domain';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class IpadPdfViewerService {
  constructor(
    private pdfViewerService: FgiPdfViewerService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  public showIpadPdfFromUrl(document: MediaItem): void {
    this.pdfViewerService.showPdfFromUrl(this.getMediaUrl(document.url), document.name).subscribe(
      () => {},
      error => this.showError(error)
    );
  }

  public async showIpadPdfFromData(blob: Blob, documentName: string): Promise<void> {
    const base64 = (await this.blobToBase64(blob)) as string;
    const data = base64.split(',')[1];

    this.pdfViewerService.showPdfFromBase64(data, documentName).subscribe(
      () => {},
      error => this.showError(error)
    );
  }

  public blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  private showError(error) {
    console.log(`%c [bgzv-frontend-main] - ${error}`, 'color: #ff3300');
    this.snackBarService.openSnackBar({
      type: SnackbarType.ERROR,
      message: `Folgender Fehler ist aufgetreten: ${error}.`,
    });
  }

  private getMediaUrl(originalUrl: string): string {
    if (environment.platform === 'vp' && originalUrl.includes('bgzv-service-nginx')) {
      const host = `${window.location.protocol}//${window.location.host}`;
      return `${host}/bws/serviceproxy${originalUrl}`;
    }

    return originalUrl || '';
  }
}
