import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubtopicOverviewItem } from '@domain/app/topic.domain';
import { LoadingService } from '@services/loading-service/loading.service';
import { TopicService } from '@services/topic-service/topic-service';
import { color } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'item-subtopic-selected',
  templateUrl: './item-subtopic-selected.component.html',
  styleUrls: ['./item-subtopic-selected.component.scss'],
})
export class ItemSubtopicSelectedComponent implements OnInit {
  @Input() subtopicItem: SubtopicOverviewItem;
  @Output() subtopicDeselected = new EventEmitter<SubtopicOverviewItem>();

  private sub: Subscription;
  private externalLoading = false;

  public loading = false;

  readonly color = color;

  constructor(
    private loadingService: LoadingService,
    private topicService: TopicService
  ) {}

  ngOnInit(): void {
    this.sub = this.loadingService.isLoading.subscribe(loading => {
      this.externalLoading = loading;
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  public onSubtopicDeselected(): void {
    this.subtopicDeselected.emit(this.subtopicItem);
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  public getMediaUrl(id: string): string {
    return this.topicService.topicMediaIcons.get(id) || '';
  }
}
