@if (consultants.length > 0) {
  <div class="d-flex flex-column flex-scroll w-100 h-100 align-items-center bg-white">
    <div class="d-flex flex-column mb-3 position-relative" style="margin-top: 7.5rem; width: 500px">
      <div class="d-flex justify-content-between w-100">
        @if (!editMode) {
          <h2 class="mb-5 mr-5">
            Neue Beratung {{ clientService.customerName ? 'bei ' + clientService.customerName : '' }}
          </h2>
        } @else {
          <h2 class="mb-5 mr-5">
            Beratung {{ clientService.customerName ? 'bei ' + clientService.customerName : '' }} bearbeiten
          </h2>
        }
      </div>
      @if (newConsultForm) {
        <ng-container>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <mat-form-field class="d-flex flex-column" [formGroup]="newConsultForm">
                <mat-label>Bitte wählen Sie ein Datum</mat-label>
                <input
                  #dateField
                  formControlName="date"
                  [matDatepicker]="picker"
                  [min]="currentDate"
                  matInput
                  readonly
                  tabindex="-1"
                  color="primary"
                  placeholder="dd.mm.yyyy"
                  aria-describedby="date-format-hint"
                />
                @if (newConsultForm.controls['date'].invalid) {
                  <mat-error>
                    <kf-input-error [message]="'Datum darf nicht in der Vergangenheit liegen'"> </kf-input-error>
                  </mat-error>
                }
                <mat-datepicker-toggle [for]="picker" matSuffix aria-label="Datum wählen">
                  <kf-icon name="ic_calendar_24" matDatepickerToggleIcon></kf-icon>
                </mat-datepicker-toggle>
                <mat-datepicker kfDatepickerPositioning #picker></mat-datepicker>
              </mat-form-field>
              <div class="d-flex-flex-column">
                <mat-form-field class="notranslate" [formGroup]="newConsultForm">
                  <mat-label>Uhrzeit</mat-label>
                  <input
                    [attr.data-testcafe]="'datepicker-input-endTime'"
                    [format]="24"
                    [ngxTimepicker]="startTime"
                    [value]="''"
                    matInput
                    placeholder="SS:MM"
                    readonly
                    type="text"
                    formControlName="time"
                  />
                  @if (newConsultFormControls.time.invalid) {
                    <mat-error>
                      <kf-input-error [message]="getFieldErrorMessage(newConsultFormControls.time, 'time')">
                      </kf-input-error>
                    </mat-error>
                  }
                </mat-form-field>

                <ngx-material-timepicker
                  #startTime
                  [theme]="bg2Theme"
                  [minutesGap]="5"
                  [format]="24"
                  [confirmBtnTmpl]="timeConfirmButton"
                  [cancelBtnTmpl]="timeCancelButton"
                ></ngx-material-timepicker>
              </div>
            </div>

            <mat-form-field required [formGroup]="newConsultForm">
              <mat-label>Name des Beraters</mat-label>
              <mat-select
                #selectUserId
                ngDefaultControl
                kfSelectMdc
                formControlName="userId"
                placeholder="Name des Beraters auswählen"
              >
                @for (option of consultants; track option.value) {
                  <mat-option [value]="option.value">{{ option.label }}</mat-option>
                }
              </mat-select>
              @if (newConsultFormControls.userId.invalid) {
                <mat-error>
                  <kf-input-error [message]="getFieldErrorMessage(newConsultFormControls.userId, 'userId')">
                  </kf-input-error>
                </mat-error>
              }
            </mat-form-field>

            <mat-form-field class="notranslate" [formGroup]="newConsultForm">
              <mat-label>Titel der Beratung</mat-label>
              <input
                [attr.data-testcafe]="'overlayConsultationNew-textfield-titelOfConsultation'"
                ngDefaultControl
                formControlName="title"
                matInput
                type="text"
              />
              @if (newConsultFormControls.title.invalid) {
                <mat-error>
                  <kf-input-error [message]="getFieldErrorMessage(newConsultFormControls.title, 'title')">
                  </kf-input-error>
                </mat-error>
              }
            </mat-form-field>

            @if (!editMode) {
              <ng-template *ngTemplateOutlet="selectInstance"></ng-template>
            }
            @if (editMode) {
              <ng-template *ngTemplateOutlet="fixedInstance"></ng-template>
            }

            <div class="pb-5"></div>
            <button
              [attr.data-testcafe]="'overlayConsultationNew-button-chooseTopics'"
              class="mb-5"
              mat-flat-button
              color="primary"
              (click)="selectTopicCompile()"
            >
              <span>{{ 'Beratungsthemen ' + (editMode ? 'anpassen' : 'wählen') }}</span>
              <kf-icon name="ic_pfeil-rechts_24"></kf-icon>
            </button>
          </div>
        </ng-container>
      }
    </div>
  </div>
}

<button
  class="kf-popup-close"
  mat-icon-button
  (click)="onCancel()"
  aria-label="Abbrechen"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>

<!-- TEMPLATES -->
<ng-template #selectInstance>
  <mat-form-field required [formGroup]="newConsultForm">
    <mat-label>Art der Beratung</mat-label>
    <mat-select
      #selectInstanceId
      kfSelectMdc
      ngDefaultControl
      formControlName="instanceId"
      (selectionChange)="setInstanceId($event)"
      placeholder="Bitte auswählen"
    >
      @for (option of instanceIds; track option.value) {
        <mat-option [value]="option.value">{{ option.label }}</mat-option>
      }
    </mat-select>
    @if (newConsultFormControls.instanceId.invalid) {
      <mat-error>
        <kf-input-error [message]="getFieldErrorMessage(newConsultFormControls.instanceId, 'title')"> </kf-input-error>
      </mat-error>
    }
  </mat-form-field>
</ng-template>

<ng-template #fixedInstance>
  @if (instanceId) {
    <mat-form-field class="notranslate" [formGroup]="newConsultForm">
      <mat-label>Art der Beratung</mat-label>
      <input ngDefaultControl formControlName="title" matInput type="text" [value]="instanceId.name" />
      @if (newConsultFormControls.title.invalid) {
        <mat-error>
          <kf-input-error
            [message]="
              getFieldErrorMessage(newConsultFormControls.title, 'title') !== ''
                ? getFieldErrorMessage(newConsultFormControls.title, 'title')
                : 'Fehler'
            "
          >
          </kf-input-error>
        </mat-error>
      }
    </mat-form-field>
  }
</ng-template>

<ng-template #timeCancelButton>
  <button class="time-button">Abbrechen</button>
</ng-template>
<ng-template #timeConfirmButton>
  <button class="time-button">OK</button>
</ng-template>
