import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { InitService } from '@services/init-service/init.service';

@Component({
  selector: 'screen-init',
  templateUrl: './screen-init.component.html',
  styleUrls: ['./screen-init.component.scss'],
})
export class ScreenInitComponent implements OnInit {
  private subAlive: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private initService: InitService
  ) {}

  public ngOnInit(): void {
    this.getQueryParams();
  }

  ngOnDestroy(): void {
    this.subAlive.next();
    this.subAlive.complete();
  }

  private getQueryParams() {
    this.route.queryParamMap.pipe(takeUntil(this.subAlive)).subscribe(params => {
      const dataInstanceId = params.get('dataInstanceId') || null;
      const customerNumber = params.get('customerNumber') || null;
      const appointmentId = params.get('appointmentId') || null;

      if (!!dataInstanceId && !!customerNumber) {
        this.initService.dataInstanceId = dataInstanceId;
        this.initService.customerNumber = customerNumber;
        this.initService.appointmentId = appointmentId;
        this.initService.isAdHocConsultation();
      }
    });
  }

  get initMessage(): string {
    return this.initService.loadingMessage;
  }

  get initActive(): boolean {
    return this.initService.loadingActive;
  }
}
