import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { EnvironmentLookupService } from '@de.fiduciagad.kundenportal/environment-lookup';
import { ConsultationDocumentsResponse, MediaItem } from '@domain/app/media.domain';
import { UNDFileUploadRequestItem } from '@domain/hub/undDomain';
import { MediaTypeEnum } from '@enums';
import { environment } from '@environment/environment';
import { Base64ConvertService } from '@services/base64convert-service/base64convert-service';
import { ClientService } from '@services/client-service/client.service';
import { MediaService } from '@services/media-service/media.service';
import { IpadPdfViewerService } from '@services/pdf-viewer-service/ipad-pdf-viewer-service';
import { QueryService } from '@services/query-service/query.service';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  public currentDocuments = new BehaviorSubject<ConsultationDocumentsResponse[]>([]);
  public currentDocument = new BehaviorSubject<ConsultationDocumentsResponse | undefined>(undefined);
  public currentDocumentFile = new BehaviorSubject<Blob | undefined>(undefined);
  public uploadPending = new BehaviorSubject<boolean>(false);
  public errorOccured = new BehaviorSubject<boolean>(false);
  public fetchingDocumentsPending = new BehaviorSubject<boolean>(false);
  public fetchingDocumentPending = new BehaviorSubject<boolean>(false);
  public errorMessage = new BehaviorSubject<string>('');
  public hasDocuments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public latestFileGshId: number | undefined = undefined;

  private latestFileName: string = '';
  private isChromium = this.platform.BLINK;

  dialogResponse: any;

  constructor(
    private base64ConvertService: Base64ConvertService,
    private queryService: QueryService,
    private clientService: ClientService,
    private ipadViewerService: IpadPdfViewerService,
    private environmentLookupService: EnvironmentLookupService,
    private mediaService: MediaService,
    private platform: Platform
  ) {
    this.currentDocument.subscribe(newDocument =>
      newDocument ? this.setCurrentFile(newDocument) : this.currentDocumentFile.next(undefined)
    );
  }

  private setCurrentFile(newDocument: ConsultationDocumentsResponse): void {
    this.fetchingDocumentPending.next(true);
    this.queryService
      .getBankBankHubDocument(newDocument.gshId, this.clientService.customerId)
      .pipe(
        finalize(() => {
          this.fetchingDocumentPending.next(false);
        })
      )
      .subscribe(data => {
        const blob = new Blob([data], { type: this.getFileType(newDocument.filename) });
        this.currentDocumentFile.next(blob);
      });
  }

  private getFileType(fileName: string) {
    if (fileName.toLowerCase().includes('.pdf')) {
      return 'application/pdf';
    } else {
      const fileNameSplit = fileName.split('.');
      const typeSuffix = fileNameSplit[fileNameSplit.length - 1];

      return `image/${typeSuffix}`;
    }
  }

  private getNextDocument(document: ConsultationDocumentsResponse): number | undefined {
    const currentDocuments = this.currentDocuments.getValue();
    if (currentDocuments.length === 1) {
      return undefined;
    }
    const index = currentDocuments.indexOf(document);
    if (index + 1 < currentDocuments.length) {
      return currentDocuments[index + 1].gshId;
    } else if (index + 1 > currentDocuments.length - 1) {
      return currentDocuments[index - 1].gshId;
    } else {
      return undefined;
    }
  }

  public uploadDocument(file: File): void {
    this.uploadPending.next(true);

    if (file) {
      this.latestFileName = file.name;
      environment.platform === 'aws' ? this.uploadAWS(file) : this.uploadBWS(file);

      this.errorMessage.next('');
      this.uploadPending.next(false);
      this.errorOccured.next(false);
    } else {
      this.errorMessage.next('');
      this.uploadPending.next(false);
      this.errorOccured.next(false);
    }
  }

  public fetchDocumentList(setLatestDocument = false): void {
    this.fetchingDocumentsPending.next(true);
    this.queryService
      .getConsultationDocuments(this.clientService.consultationId)
      .pipe(
        finalize(() => {
          this.fetchingDocumentsPending.next(false);
        })
      )
      .subscribe(currentDocuments => {
        this.currentDocuments.next(this.sortDocumentList(currentDocuments));
        if (setLatestDocument) {
          const nextDocument = currentDocuments.find(document => document?.gshId === this.latestFileGshId);
          this.currentDocument.next(nextDocument);
          this.latestFileGshId = undefined;
        }
        if (!isEmpty(currentDocuments)) {
          this.hasDocuments.next(true);
        } else {
          this.hasDocuments.next(false);
        }
      });
  }

  public deselectDocument(): void {
    const currentDocument = this.currentDocument.getValue();
    this.queryService
      .deleteSelectDocumentsForConsultation(this.clientService.consultationId, currentDocument.gshId)
      .pipe(
        tap(() => {
          this.fetchingDocumentsPending.next(false);
          this.queryService
            .deleteSelectDocumentsForCustomer(this.clientService.customerId, currentDocument.gshId)
            .subscribe();
        })
      )
      .subscribe(() => {
        this.latestFileGshId = this.getNextDocument(currentDocument);
        this.fetchDocumentList(true);
      });
  }

  public setNextDocument(direction: 'next' | 'previous') {
    const currentDocument = this.currentDocument.getValue();
    const currentDocuments = this.currentDocuments.getValue();
    let nextIndex = currentDocuments.indexOf(currentDocument);
    direction === 'next' ? nextIndex++ : nextIndex--;
    this.currentDocument.next(currentDocuments[nextIndex]);
  }

  public async showDocument(document: MediaItem): Promise<void> {
    const isIPad = this.environmentLookupService?.isInsideNativeShell();
    const mediaItem: MediaItem = Object.assign({}, document);

    if (!document.url.includes('https')) {
      mediaItem.url = await this.mediaService.getMediaContent(document.url, true, this.isChromium);
    }

    if (environment.platform === 'vp' && isIPad && document.type === MediaTypeEnum.pdf) {
      this.ipadViewerService.showIpadPdfFromUrl(mediaItem);
    } else {
      window.open(mediaItem.url, '_blank');
    }
  }

  private sortDocumentList(documents) {
    return documents.sort((a, b) => (a.filename.toLowerCase() < b.filename.toLowerCase() ? -1 : 1));
  }

  private uploadAWS(file: File) {
    const fileRequest = this.queryService.postUploadDocument(this.clientService.customerId, file);
    this.selectDocument(fileRequest);
  }

  private uploadBWS(file: File) {
    this.base64ConvertService.convertToBase64(file).then(base64String => {
      this.queryService.getUNDTicket().subscribe(ticket => {
        const _file: UNDFileUploadRequestItem = {
          filename: this.latestFileName,
          base64File: base64String.split('base64,')[1],
          contentType: file.type,
        };
        return this.queryService.postUNDUploadFile(_file, ticket.ticketId).subscribe(uploadResponse => {
          this.selectDocument(this.queryService.postUNDDataToBackend(uploadResponse, this.clientService.customerId));
        });
      });
    });
  }

  private selectDocument(selectRequest: Observable<any>) {
    selectRequest
      .pipe(
        finalize(() => {
          this.uploadPending.next(false);
          this.errorMessage.next('');
        })
      )
      .subscribe(file => {
        const gshInfo = {
          id: file.id,
          filename: file.filename,
          gshId: file.internalId,
          type: file.type,
        } as ConsultationDocumentsResponse;
        this.latestFileGshId = file.internalId;
        return this.queryService
          .postSelectDocumentForConsultation(this.clientService.consultationId, gshInfo)
          .subscribe(() => {
            this.fetchDocumentList(true);
          });
      });
  }
}
