<form [formGroup]="selectFormGroup">
  @if (question.type === questionEnum.single || question.type === questionEnum.singleImage) {
    <!-- SINGLE-SELECT -->
    <mat-radio-group
      [attr.aria-label]="'Bitte wählen Sie Ihre Antwort zu: ' + question.name + ' aus'"
      class="select-grid mb-3"
    >
      @for (answer of question.answers; track answer; let index = $index) {
        <div class="w-100 d-flex mb-3 flex-column">
          <div class="d-flex">
            <div
              attr.aria-labelledby="answer-label-{{ index }}"
              tabindex="0"
              class="d-flex select-tile"
              [ngClass]="{
                'select-tile--checked': answer.id === currentlySelectedSingleId
              }"
              (click)="selectAnswerSingle(answer.id)"
              (keydown.enter)="selectAnswerSingle(answer.id)"
            >
              <mat-radio-button
                class="align-self-center"
                [value]="answer.id"
                [checked]="answer.id === currentlySelectedSingleId"
                tabIndex="-1"
              >
              </mat-radio-button>
              <mat-divider class="mx-2" [vertical]="true"></mat-divider>
              @if (question.type === questionEnum.singleImage) {
                <bgzv-image
                  class="align-self-center mx-3"
                  [src]="answerImages.get(answer.id)"
                  [alt]="answer.media?.name || ''"
                  width="175px"
                  height="100px"
                ></bgzv-image>
              }
              <div class="answer-label">
                <span id="answer-label-{{ index }}" class="ml-2">{{ answer.label }}</span>
              </div>
            </div>
            @if (answer.tooltip) {
              <ng-template *ngTemplateOutlet="infoIcon; context: { description: answer.tooltip }"></ng-template>
            }
          </div>
          @if (answer.hasTextField && currentlySelectedSingleId === answer.id) {
            <mat-form-field class="mt-2">
              <mat-label>Notizen</mat-label>
              <textarea
                #note
                matInput
                formControlName="{{ answer.id }}"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="4"
                (change)="selectAnswerSingle(currentlySelectedSingleId, true)"
              ></textarea>
            </mat-form-field>
          }
        </div>
      }
    </mat-radio-group>
  } @else if (question.type === questionEnum.multi || question.type === questionEnum.multiImage) {
    <!-- MULTI-SELECT -->
    <ul class="pl-0 select-grid" [attr.aria-label]="'Bitte wählen Sie Ihre Antwort zu: ' + question.name + ' aus'">
      @for (answer of question.answers; track answer; let index = $index) {
        <li class="mb-0 select-grid-element">
          <div class="w-100 d-flex mb-3">
            <div
              tabindex="0"
              attr.aria-labelledby="answer-label-{{ index }}"
              class="d-flex select-tile"
              [ngClass]="{
                'select-tile--checked': multiIdIsSelected.get(answer.id)
              }"
              (click)="selectAnswerMulti(answer)"
              (keydown.enter)="selectAnswerMulti(answer)"
            >
              <mat-checkbox
                class="align-self-center mb-0 mr-2"
                [checked]="multiIdIsSelected.get(answer.id)"
                tabIndex="-1"
              ></mat-checkbox>
              <mat-divider class="mx-2" [vertical]="true"></mat-divider>
              @if (question.type === questionEnum.multiImage) {
                <bgzv-image
                  class="align-self-center mx-3"
                  [src]="answerImages.get(answer.id)"
                  [alt]="answer.media?.name || ''"
                  width="175px"
                  height="100px"
                ></bgzv-image>
              }
              <div class="answer-label">
                <span id="answer-label-{{ index }}" class="ml-2">{{ answer.label }}</span>
              </div>
            </div>
            @if (answer.tooltip) {
              <ng-template *ngTemplateOutlet="infoIcon; context: { description: answer.tooltip }"></ng-template>
            }
          </div>
          @if (answer.hasTextField && multiIdIsSelected.get(answer.id)) {
            <mat-form-field class="mb-3">
              <mat-label>Notizen</mat-label>
              <textarea
                #note
                matInput
                formControlName="{{ answer.id }}"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="4"
                (change)="selectAnswerMulti(answer, true)"
              ></textarea>
            </mat-form-field>
          }
        </li>
      }
    </ul>
  }
</form>

<ng-template #infoIcon let-description="description">
  <mat-hint class="answer-info">
    <kf-icon
      [kfTooltip]="description"
      class="answer-info-icon"
      alt="Details"
      kfTooltipPosition="after"
      kfTooltipShow="click"
      name="ic_infozeichen-mit-kreis_24"
      role="button"
      tabindex="0"
    ></kf-icon>
  </mat-hint>
</ng-template>
