import { Injectable, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftSidenavService implements OnDestroy {
  private _animationSub: Subscription;
  private _sidenavInstance: MatSidenav;

  private _context = '';
  private _origContext = [];

  public opened: Subject<string> = new Subject<string>();
  public closed: Subject<string> = new Subject<string>();

  public forcedClose = false;

  constructor() {}

  ngOnDestroy(): void {
    this._animationSub?.unsubscribe();
  }

  public setSidenav(sidenav: MatSidenav) {
    this._sidenavInstance = sidenav;
    this._animationSub = this._sidenavInstance._animationEnd.subscribe(instance => {
      // reset original state on close, if 'useOnce' was used
      if (instance.fromState === 'open' && instance.toState === 'void' && this.origContext.length > 0) {
        this.context = this.origContext[0];
        this.origContext = [];
      }
    });
  }

  public open(noEmit = false): Promise<any> {
    if (!noEmit) {
      this.emitOpened();
    }
    const promise = new Promise<void>((resolve, reject) => {
      this._sidenavInstance.open().then(() => resolve());
    });
    return promise;
  }

  public close(noEmit = false): Promise<any> {
    if (!noEmit) {
      this.emitClosed();
    }
    const promise = new Promise<void>((resolve, reject) => {
      this._sidenavInstance.close().then(x => {
        resolve(null);
      });
    });
    return promise;
  }

  public toggle(): Promise<any> {
    const promise = new Promise<void>((resolve, reject) => {
      this._sidenavInstance.toggle().then(() => resolve(null));
    });
    return promise;
  }

  public use(context: string) {
    this.context = context;
  }

  public useOnce(tempContext: string) {
    if (this.context !== tempContext) {
      this.origContext.push(this.context);
      this.context = tempContext;
    }
  }

  public emitOpened() {
    this.opened.next(this.context);
  }

  public emitClosed() {
    this.closed.next(this.context);
  }

  public set context(context: string) {
    this._context = context;
  }

  public get context() {
    return this._context;
  }

  public get instance() {
    return this._sidenavInstance;
  }

  public get origContext() {
    return this._origContext;
  }
  public set origContext(value) {
    this._origContext = value;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RightSidenavService extends LeftSidenavService {}
