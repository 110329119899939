import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { AnswerSegmentedRadioComponent } from '@components/answers/answer-segmented-radio/answer-segmented-radio.component';
import { BgzvImageComponent } from '@components/bgzv-image/bgzv-image.component';
import { DialogConfirmComponent } from '@components/dialog-confirm/dialog-confirm.component';
import { DialogOtherAnswersComponent } from '@components/dialog-other-answers/dialog-other-answers.component';
import { DocumentsUploadComponent } from '@components/documents-upload/documents-upload.component';
import { DocumentsViewerComponent } from '@components/documents-viewer/documents-viewer.component';
import { ItemCartComponent } from '@components/item-cart/item-cart.component';
import { ItemCompositionMainComponent } from '@components/item-composition-main/item-composition-main.component';
import { ItemCompositionTaskComponent } from '@components/item-composition-task/item-composition-task.component';
import { ItemCompositionTransitionComponent } from '@components/item-composition-transition/item-composition-transition.component';
import { ItemDatafieldFormComponent } from '@components/item-datafield-form/item-datafield-form.component';
import { ItemMenuStructureComponent } from '@components/item-menu-structure/item-menu-structure.component';
import { ItemSideDatafieldButtonComponent } from '@components/item-side-datafield-button/item-side-datafield-button.component';
import { ItemStickyButtonComponent } from '@components/item-sticky-button/item-sticky-button.component';
import { ItemSubtopicSelectedComponent } from '@components/item-subtopic-selected/item-subtopic-selected.component';
import { ItemTopicAvailableComponent } from '@components/item-topic-available/item-topic-available.component';
import { SyncedQuestionsComponent } from '@components/synced-questions/synced-questions.component';
import { KFImgModule } from '@de.fiduciagad.kundenportal/kf-theme/image';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AnswerSliderComponent } from './answers/answer-slider/answer-slider.component';
import { DndDirective } from './documents-upload/dnd.directive';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    BgzvImageComponent,
    DialogConfirmComponent,
    DialogOtherAnswersComponent,
    DocumentsViewerComponent,
    DocumentsUploadComponent,
    ItemCartComponent,
    ItemCompositionMainComponent,
    ItemCompositionTaskComponent,
    ItemCompositionTransitionComponent,
    ItemDatafieldFormComponent,
    ItemMenuStructureComponent,
    ItemSideDatafieldButtonComponent,
    ItemStickyButtonComponent,
    ItemSubtopicSelectedComponent,
    ItemTopicAvailableComponent,
    SyncedQuestionsComponent,
    DndDirective,
    GlobalLoaderComponent,
    AnswerSegmentedRadioComponent,
  ],
  exports: [
    BgzvImageComponent,
    DialogConfirmComponent,
    DialogOtherAnswersComponent,
    DocumentsViewerComponent,
    DocumentsUploadComponent,
    ItemCartComponent,
    ItemCompositionMainComponent,
    ItemCompositionTaskComponent,
    ItemCompositionTransitionComponent,
    ItemDatafieldFormComponent,
    ItemMenuStructureComponent,
    ItemSideDatafieldButtonComponent,
    ItemStickyButtonComponent,
    ItemTopicAvailableComponent,
    ItemSubtopicSelectedComponent,
    SyncedQuestionsComponent,
    DndDirective,
    GlobalLoaderComponent,
    AnswerSegmentedRadioComponent,
    AnswerSliderComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    BgzvLibraryModule,
    BgzvUtilsModule,
    KFImgModule,
    KfThemeModule,
    PdfViewerModule,
    AnswerSliderComponent,
  ],
  providers: [],
})
export class BgzvComponentsModule {}
